import reduxPromiseMiddleware from 'redux-promise-middleware';
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { setJourney, setOriginalJourneyId, setSocketId } from './redux/actions';
import { initState } from './shared/actions/actions/common.actions';

import reducer from './reducer';

import { loadState, saveState } from './shared/utils/local-storage';

const enableLogger = false;
let middleware;

if (enableLogger) {
  middleware = applyMiddleware(reduxPromiseMiddleware, thunk, logger);
} else {
  middleware = applyMiddleware(reduxPromiseMiddleware, thunk);
}
// UGLY HACK to get react to work on ie9
global.requestAnimationFrame = callback => {
  setTimeout(callback, 0);
};

require('core-js/stable');
require('regenerator-runtime/runtime');
const shortid = require('shortid');

// we get the state that is stored in the local storage
const persistedState = loadState();

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

// create the redux store from the reducer and initial state we got from local storage
const store = createStore(reducer, persistedState, composeEnhancers(middleware));

const state = store.getState();
const frontendVersion = process.env.REACT_APP_FRONTEND_VERSION;
if (!state.common.version || state.common.version < frontendVersion) {
  store.dispatch(initState());
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

// every time we dispatch an action in Redux, we save the state in local storage
store.subscribe(() => saveState(store.getState()));

// window.location.pathname (path of the url)
const { pathname } = window.location;

// split by / to get each part of the pathname
const splitedPathNameArray = pathname.split('/');
// this will be played once at the start of the application. It sends the current URL into
// the reducer to set the user journey. It will be ignored if the URL is not a valid journey
store.dispatch(setJourney(splitedPathNameArray[1]));

const paramLinkId = splitedPathNameArray[2];
// test if the url have an id in the path (mongo id or uuid)
if (
  paramLinkId &&
  (paramLinkId.match(/^[a-f0-9]{24}$/) ||
    paramLinkId.match(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/))
) {
  // if thats the case, we store the id inside redux as investment link id
  store.dispatch(setOriginalJourneyId(paramLinkId));
}

// generate a uuid for the connection with socket.io if there is not already one in local storage
if (!store.getState().default.socketId) store.dispatch(setSocketId(shortid.generate()));

export default store;
