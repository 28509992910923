import * as React from 'react';
import PropTypes from 'prop-types';

const CaNordEstSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101135,31.018839 
	C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
	C367.080994,31.011070 199.130249,31.011070 31.158733,31.011070 
	C31.158733,61.734035 31.158733,91.854843 31.022226,122.902939 
	C31.067513,128.944077 31.112801,134.985229 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
	C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
	C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
	C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
	C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
	C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
	C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.158733,121.975655 
	C31.158733,91.854843 31.158733,61.734035 31.158733,31.011070 
	C199.130249,31.011070 367.080994,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
	C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
	C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
	C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
	C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
	C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
	C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
	C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
	C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
	C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
	C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
	C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
	C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
	C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
	C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
	C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
	C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
	C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
	C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
	C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396376,141.702835 
	C33.838200,153.473358 39.009472,164.324631 48.020218,172.170303 
	C68.104706,189.657974 92.682571,192.806717 117.854553,189.595917 
	C132.444336,187.734909 147.099899,183.871750 160.863541,178.640717 
	C181.619507,170.752182 201.621338,160.879379 222.249176,152.051575 
	C226.404083,161.530212 230.330017,170.776566 234.067825,180.098358 
	C235.717758,184.213211 238.190018,187.730011 242.735703,187.852341 
	C254.987137,188.182007 267.253296,187.965073 280.266327,187.965073 
	C277.519806,181.940796 275.127045,176.566132 272.629791,171.240448 
	C266.818390,158.846909 260.956604,146.476974 255.422760,133.933273 
	C258.574646,132.158020 261.474579,130.636993 264.253937,128.920670 
	C277.769043,120.574715 291.265320,112.197884 304.717865,103.751709 
	C305.681458,103.146713 306.875549,101.908936 306.891052,100.948097 
	C307.050537,91.061440 306.988922,81.171219 306.988922,70.489708 
	C296.578278,76.319153 287.084869,81.751724 277.481567,86.982521 
	C265.708588,93.395126 253.843628,99.638916 241.986084,105.585068 
	C241.263474,103.362656 240.683777,101.460434 239.865601,99.667007 
	C232.732422,84.030983 225.390778,68.486588 218.534088,52.730873 
	C217.173096,49.603519 215.653046,48.890915 212.680298,48.954121 
	C205.852615,49.099297 198.897064,49.818752 192.216293,48.817684 
	C180.767303,47.102131 173.603333,50.980350 170.338684,62.147644 
	C169.846237,63.832115 168.466995,65.240997 167.611221,66.838036 
	C154.957672,90.451729 142.307709,114.067383 129.701248,137.706223 
	C127.811172,141.250351 126.146080,144.914444 124.037666,149.215057 
	C140.419083,148.287491 154.974106,144.862228 167.890030,136.003174 
	C168.787979,135.387268 169.653351,134.493301 170.129028,133.528946 
	C173.848495,125.988388 177.500351,118.413940 181.111938,110.820869 
	C185.599594,101.385948 190.028702,91.923180 194.797913,81.804504 
	C195.739883,84.022636 196.409958,85.486656 196.990402,86.985413 
	C201.626892,98.957397 206.246429,110.935951 210.512726,122.962891 
	C193.228043,130.975784 176.711258,140.032166 159.251099,146.568512 
	C144.202393,152.202103 128.228088,155.762115 112.380005,158.725235 
	C101.272659,160.801971 89.658447,161.131088 78.330147,160.694672 
	C71.734169,160.440536 64.982750,157.753891 58.806850,155.019745 
	C53.446743,152.646759 52.397503,147.631500 53.604774,141.687393 
	C55.497131,132.370193 60.241547,124.435577 66.203499,117.513847 
	C83.444855,97.496941 105.090759,84.243423 130.842972,78.080048 
	C138.385468,76.274872 146.140762,75.358833 154.024979,73.996132 
	C153.025970,70.294739 151.905991,66.550430 151.009995,62.753269 
	C147.623413,48.401260 138.627167,43.034054 124.134453,46.355984 
	C108.544136,49.929497 93.716507,55.284294 79.939613,63.514091 
	C56.832531,77.317398 38.691162,95.204948 31.158733,121.975655 
M375.479370,166.979568 
	C385.144012,178.273041 394.778015,189.593063 404.514771,200.824020 
	C405.643066,202.125488 407.268890,203.678558 408.789398,203.826447 
	C413.015106,204.237442 417.306854,203.969086 421.720337,203.969086 
	C421.720337,182.392502 421.720337,161.489639 421.720337,140.395035 
	C416.741211,140.395035 412.046234,140.395035 406.792847,140.395035 
	C406.792847,154.554138 406.792847,168.327881 406.792847,182.764053 
	C395.971527,170.396667 384.992004,159.246246 375.770935,146.793732 
	C370.102478,139.138763 363.840393,139.535553 356.304138,140.452454 
	C356.304138,161.658066 356.304138,182.561203 356.304138,203.579895 
	C360.976990,203.579895 365.339203,203.579895 370.241028,203.579895 
	C370.241028,189.336105 370.241028,175.504410 370.241028,160.276123 
	C372.413757,163.110245 373.698273,164.785767 375.479370,166.979568 
M581.529236,183.103485 
	C580.798035,182.143738 580.066833,181.183990 579.293030,180.168304 
	C582.381226,178.672348 585.116150,177.526230 587.678406,176.075302 
	C598.588074,169.897614 599.488159,152.547699 588.773804,146.140137 
	C583.713135,143.113693 577.568176,140.750046 571.761047,140.357819 
	C559.053101,139.499512 546.247131,140.092117 533.437439,140.092117 
	C533.437439,161.686417 533.437439,182.711609 533.437439,203.621826 
	C538.129578,203.621826 542.374207,203.621826 547.120544,203.621826 
	C547.120544,196.334137 547.120544,189.416031 547.120544,182.086334 
	C554.780334,182.507217 562.614014,179.420700 567.924622,187.381821 
	C569.930908,190.389420 572.201599,193.249771 574.597107,195.960480 
	C577.071472,198.760361 579.443726,202.739624 582.580139,203.609909 
	C587.274414,204.912445 592.597656,203.948410 598.903320,203.948410 
	C592.641418,196.492844 587.269470,190.096863 581.529236,183.103485 
M430.000000,96.496445 
	C430.000000,101.582939 430.000000,106.669426 430.000000,111.627045 
	C435.115234,111.627045 439.361664,111.627045 444.111969,111.627045 
	C444.111969,104.343544 444.111969,97.423889 444.111969,90.000076 
	C448.404419,90.000076 452.228119,89.711258 455.977692,90.113434 
	C458.071503,90.338013 460.511169,91.232498 461.970795,92.664932 
	C465.013855,95.651222 467.397552,99.295944 470.261047,102.482437 
	C473.201569,105.754707 475.877380,110.263306 479.603668,111.574364 
	C484.105347,113.158249 489.589569,111.949661 495.854004,111.949661 
	C488.679199,103.552856 482.375793,96.175858 475.865845,88.557159 
	C478.873901,87.260315 481.103363,86.543633 483.085419,85.398651 
	C488.956055,82.007393 492.912292,77.452858 493.077423,70.093033 
	C493.254181,62.215576 490.820007,55.860455 483.292358,52.843472 
	C478.343506,50.860050 472.942871,49.214260 467.674225,48.976677 
	C455.309998,48.419132 442.903564,48.798252 430.426727,48.798252 
	C430.371674,48.925613 430.134125,49.219524 430.133026,49.514320 
	C430.076019,64.842186 430.039093,80.170120 430.000000,96.496445 
M1014.846252,81.289177 
	C1023.937378,69.182167 1018.852234,53.272850 1002.343018,50.035084 
	C996.232971,48.836784 989.937012,48.302006 983.704102,48.082233 
	C974.622437,47.762012 965.521057,48.000000 956.448486,48.000000 
	C956.448486,69.722595 956.448486,90.738197 956.448486,111.614517 
	C961.147156,111.614517 965.390503,111.614517 970.130859,111.614517 
	C970.130859,104.321976 970.130859,97.407074 970.130859,90.000076 
	C974.416626,90.000076 978.238892,89.711052 981.986816,90.113556 
	C984.078552,90.338188 986.514832,91.235428 987.973511,92.667282 
	C991.013794,95.651596 993.397156,99.291740 996.256897,102.477112 
	C999.194580,105.749222 1001.863464,110.263084 1005.586182,111.572906 
	C1010.089722,113.157494 1015.576172,111.948570 1021.846191,111.948570 
	C1014.682007,103.561066 1008.377136,96.179596 1002.317322,89.085014 
	C1006.776184,86.389366 1010.556152,84.104172 1014.846252,81.289177 
M594.507202,48.000000 
	C590.095337,48.000000 585.683472,48.000000 581.327087,48.000000 
	C581.327087,69.736221 581.327087,90.623123 581.327087,111.940628 
	C593.587769,111.940628 605.568115,112.548111 617.454102,111.749985 
	C627.104187,111.101997 636.542358,107.703300 642.998047,100.217819 
	C656.102051,85.023544 651.955811,59.745865 630.246887,51.935505 
	C618.955994,47.873325 607.232544,47.994904 594.507202,48.000000 
M1217.025635,69.652130 
	C1214.295898,65.582771 1212.387451,60.431839 1208.696167,57.628860 
	C1191.472290,44.550163 1172.858887,43.662819 1154.083496,54.069775 
	C1135.727661,64.244194 1131.736450,91.748505 1152.753540,105.604515 
	C1168.476196,115.970047 1185.758057,115.985146 1202.674072,107.100372 
	C1216.016479,100.092621 1222.787720,83.189178 1217.025635,69.652130 
M682.899658,176.953766 
	C684.558655,158.249298 675.557251,149.162140 664.735840,144.531982 
	C659.677063,142.367508 654.032654,140.800980 648.571045,140.509232 
	C637.211121,139.902374 625.796082,140.325821 614.444092,140.325821 
	C614.444092,161.741547 614.444092,182.744232 614.444092,202.976654 
	C627.638916,202.976654 640.507996,204.087341 653.095032,202.678146 
	C666.908691,201.131653 679.227112,194.391525 682.899658,176.953766 
M514.604858,159.184021 
	C513.238586,157.124390 512.037598,154.929565 510.480499,153.026031 
	C494.978790,134.075409 457.328461,134.103333 442.254364,155.089798 
	C433.713043,166.981216 436.368225,186.376297 448.147736,195.130997 
	C459.865570,203.839890 472.655731,206.583511 486.888275,204.015030 
	C507.013428,200.383133 523.338196,183.803360 514.604858,159.184021 
M834.989197,193.494095 
	C825.670837,192.729797 816.983093,189.995605 809.086243,184.579941 
	C806.554871,188.191940 804.340881,191.351135 801.912781,194.815872 
	C811.035156,199.772476 819.980896,202.925812 829.847534,204.294693 
	C840.757690,205.808350 850.938965,204.615311 860.615845,199.649429 
	C866.995789,196.375443 870.519409,191.204514 870.064148,183.576828 
	C869.659119,176.791031 865.576599,172.872360 859.997253,170.666428 
	C853.910828,168.259964 847.465027,166.727814 841.120972,165.018372 
	C835.564880,163.521286 829.862427,162.533188 824.375854,160.832428 
	C822.676575,160.305649 820.276489,158.111954 820.351807,156.805786 
	C820.441284,155.256165 822.604248,153.110214 824.316650,152.517609 
	C828.009705,151.239548 831.968140,150.264526 835.855957,150.108093 
	C844.332886,149.767014 852.056335,152.604492 859.381958,156.763458 
	C860.440674,157.364487 862.861816,157.367188 863.460205,156.650833 
	C865.530823,154.171997 867.074707,151.253098 869.031250,148.166885 
	C860.979858,143.674316 853.008118,140.830322 844.088623,139.692078 
	C833.173157,138.299103 823.012329,139.387039 813.507568,144.878342 
	C808.402283,147.827896 805.011963,152.024261 804.939819,158.445480 
	C804.868347,164.803680 808.062500,168.864304 813.509277,171.092957 
	C819.121765,173.389450 824.938721,175.243774 830.770386,176.931747 
	C837.403870,178.851776 844.214050,180.174316 850.808472,182.207275 
	C852.472961,182.720398 854.603149,184.888535 854.697754,186.409470 
	C854.786743,187.838165 852.724426,190.319412 851.161987,190.762711 
	C846.152100,192.184052 840.935120,192.875778 834.989197,193.494095 
M920.089417,60.096886 
	C922.098328,61.239231 924.239258,62.201401 926.091370,63.557869 
	C931.496155,67.516273 932.519592,61.904133 934.637207,59.497746 
	C937.272034,56.503613 934.755310,54.959633 932.208069,53.605988 
	C916.129028,45.061111 900.010559,44.941902 883.978882,53.553997 
	C873.851624,58.994267 868.349915,67.615479 868.041748,79.166389 
	C867.709167,91.632942 872.842529,101.529533 883.919861,107.319389 
	C901.641479,116.582039 919.359253,114.578186 936.635437,105.598640 
	C937.965149,104.907486 939.771973,103.406059 939.808960,102.232430 
	C940.079712,93.640167 939.954956,85.035439 939.954956,76.287170 
	C928.722717,76.287170 918.144531,76.287170 907.405151,76.287170 
	C907.405151,79.943619 907.405151,83.304581 907.405151,87.170609 
	C913.723145,87.170609 919.644043,87.170609 925.475891,87.170609 
	C926.979309,97.790405 926.892151,98.221565 917.415161,100.946938 
	C910.433472,102.954712 903.251953,102.966927 896.524719,99.935318 
	C888.768738,96.440102 883.729248,90.406876 883.108521,81.736931 
	C882.551208,73.951263 885.851013,67.563347 892.367798,63.163200 
	C900.744629,57.507141 909.799561,56.637691 920.089417,60.096886 
M776.487427,140.000000 
	C761.413879,140.000000 746.340393,140.000000 731.014160,140.000000 
	C731.014160,156.512146 730.901855,172.162445 731.122253,187.808044 
	C731.159973,190.487579 732.213989,193.361801 733.497131,195.774506 
	C736.950500,202.267456 743.375000,203.738571 749.895691,203.908569 
	C762.049011,204.225403 774.216675,204.010818 786.378174,203.977615 
	C787.480347,203.974609 788.581787,203.682861 789.671265,203.526962 
	C789.671265,199.972153 789.671265,196.724335 789.671265,193.000000 
	C787.553467,193.000000 785.759583,193.000046 783.965637,192.999985 
	C774.136353,192.999664 764.306335,193.068756 754.478149,192.964951 
	C750.520752,192.923157 746.572021,192.228806 746.099487,187.232315 
	C745.761597,183.660248 746.033813,180.030487 746.033813,175.943329 
	C759.268127,175.943329 771.852234,175.943329 784.600464,175.943329 
	C784.600464,172.066544 784.600464,168.706207 784.600464,164.841522 
	C771.626282,164.841522 759.043457,164.841522 746.451904,164.841522 
	C746.451904,159.958771 746.451904,155.593750 746.451904,150.740448 
	C760.815002,150.740448 774.731506,150.740448 788.675659,150.740448 
	C788.675659,146.981094 788.675659,143.733459 788.675659,140.000000 
	C784.736572,140.000000 781.108459,140.000000 776.487427,140.000000 
M540.508057,101.000000 
	C536.675598,100.999519 532.758423,101.481346 529.040588,100.828209 
	C526.581055,100.396118 523.088318,98.819679 522.384644,96.901901 
	C520.982605,93.080765 521.183350,88.671494 520.655457,83.904221 
	C534.643372,83.904221 547.562744,83.904221 560.577515,83.904221 
	C560.577515,80.356415 560.577515,77.328445 560.577515,73.795570 
	C547.245300,73.795570 534.327942,73.795570 521.299500,73.795570 
	C521.299500,68.700874 521.299500,64.122162 521.299500,58.863136 
	C535.958984,58.863136 550.335693,58.863136 564.646667,58.863136 
	C564.646667,54.924427 564.646667,51.675587 564.646667,48.290237 
	C545.383484,48.290237 526.474731,48.290237 507.250397,48.290237 
	C507.158142,50.182201 507.012665,51.803867 507.010468,53.425732 
	C506.992004,66.922562 506.986572,80.419441 507.004120,93.916275 
	C507.018921,105.297943 513.811951,111.986313 525.348145,111.997505 
	C537.012085,112.008827 548.676086,112.000000 560.340027,112.000000 
	C562.100220,112.000000 563.860474,112.000000 565.684265,112.000000 
	C565.684265,107.999313 565.684265,104.751945 565.684265,101.000000 
	C557.419861,101.000000 549.462585,101.000000 540.508057,101.000000 
M847.881653,77.643532 
	C844.026794,70.325325 839.606812,63.237938 836.493896,55.616692 
	C833.953918,49.398174 830.428711,46.668152 823.870056,47.991714 
	C823.550049,48.056286 823.197754,48.047253 822.873901,47.992783 
	C819.174438,47.370518 817.373108,49.139866 815.714050,52.421055 
	C806.831055,69.989456 797.634949,87.399353 788.595276,104.888931 
	C787.526672,106.956322 786.752502,109.175865 785.757874,111.523003 
	C800.246094,113.230026 800.291016,113.249062 805.524475,100.896919 
	C806.679504,98.170670 808.105225,96.751617 811.384277,96.913048 
	C817.530212,97.215614 823.794006,97.672157 829.840698,96.841522 
	C838.775879,95.614120 844.161438,98.720779 846.779419,107.298721 
	C847.303528,109.016121 848.863403,111.550430 850.167603,111.694115 
	C854.964233,112.222588 859.854248,111.903816 865.645447,111.903816 
	C859.435974,100.018402 853.776672,89.186012 847.881653,77.643532 
M394.553070,47.993313 
	C382.396637,45.765167 371.328552,48.710709 361.163971,55.330582 
	C344.453003,66.213882 341.910675,92.192513 361.606384,105.630310 
	C377.553070,116.510239 401.270416,115.806389 417.738037,103.526199 
	C416.488678,101.839630 414.948334,100.287941 414.041870,98.427422 
	C412.121124,94.485184 410.048279,94.751244 406.702850,96.997009 
	C398.380280,102.583961 389.109192,103.318336 379.567749,100.941025 
	C367.655212,97.972954 360.523682,85.534271 363.935669,74.592842 
	C368.622681,59.562637 388.615936,53.173656 404.064026,62.165745 
	C411.644196,66.578026 409.578430,65.388992 414.695740,59.538979 
	C416.482941,57.495876 415.985229,55.273651 413.514984,54.325691 
	C407.556580,52.039112 401.469879,50.086708 394.553070,47.993313 
M1111.718506,101.651970 
	C1107.736816,101.766617 1103.675903,102.436844 1099.789795,101.881882 
	C1091.330322,100.673805 1084.613525,96.544746 1080.809692,88.482658 
	C1077.458008,81.378525 1078.406494,74.814148 1082.803467,68.420555 
	C1089.192627,59.129990 1105.010620,55.169777 1115.778564,60.373165 
	C1119.164062,62.009098 1122.523804,63.697845 1125.973511,65.400955 
	C1128.274414,62.210438 1130.235107,59.491596 1132.422852,56.457981 
	C1121.517578,49.488697 1110.605469,46.309532 1098.189941,47.857059 
	C1087.835327,49.147713 1078.800171,52.324379 1071.345703,60.030792 
	C1060.630127,71.108414 1061.363159,93.401993 1074.894531,103.648979 
	C1087.708130,113.352287 1101.746582,114.796982 1116.647095,111.524078 
	C1122.633423,110.209183 1128.526367,107.868347 1133.244263,103.119629 
	C1131.037598,100.165848 1129.094116,97.564468 1127.213501,95.047165 
	C1122.096924,97.188683 1117.277710,99.205734 1111.718506,101.651970 
M295.985382,38.513973 
	C290.105347,41.724567 284.356995,45.211407 278.318542,48.089642 
	C264.728394,54.567402 252.665390,64.446632 236.707855,66.764473 
	C238.172623,70.825409 239.597168,74.781311 241.026245,78.735573 
	C244.349579,87.931107 249.830475,90.475754 258.606812,86.011948 
	C273.907745,78.229630 289.008453,70.050117 304.097839,61.861584 
	C305.423523,61.142178 306.820526,59.153553 306.867859,57.711239 
	C307.133789,49.607342 306.996033,41.490196 306.996033,32.471149 
	C303.079620,34.586533 299.840912,36.335854 295.985382,38.513973 
M706.501709,48.000000 
	C701.084351,48.000000 695.666931,48.000000 690.367493,48.000000 
	C690.367493,52.110477 690.367493,55.356586 690.367493,59.096588 
	C698.306458,59.096588 705.889832,59.096588 714.138062,59.096588 
	C714.138062,76.959961 714.138062,94.323456 714.138062,111.652725 
	C719.399841,111.652725 723.977295,111.652725 729.228271,111.652725 
	C729.228271,93.952660 729.228271,76.591660 729.228271,58.737747 
	C737.490356,58.737747 745.075012,58.737747 752.673828,58.737747 
	C752.673828,54.976212 752.673828,51.729095 752.673828,48.000000 
	C737.401733,48.000000 722.451538,48.000000 706.501709,48.000000 
M917.000000,195.445374 
	C917.000000,180.712067 917.000000,165.978775 917.000000,150.751007 
	C925.470886,150.751007 933.056580,150.751007 940.681641,150.751007 
	C940.681641,146.992310 940.681641,143.745377 940.681641,140.420563 
	C920.003052,140.420563 899.645569,140.420563 879.361938,140.420563 
	C879.361938,144.094086 879.361938,147.342194 879.361938,151.086395 
	C887.297668,151.086395 894.880798,151.086395 903.129639,151.086395 
	C903.129639,168.950821 903.129639,186.318130 903.129639,203.655334 
	C908.059875,203.655334 912.304932,203.655334 917.000000,203.655334 
	C917.000000,201.023758 917.000000,198.723618 917.000000,195.445374 
M1252.500122,112.000000 
	C1263.583374,112.000000 1274.666626,112.000000 1285.625488,112.000000 
	C1285.625488,107.884369 1285.625488,104.634956 1285.625488,100.884659 
	C1272.674194,100.884659 1260.087524,100.884659 1246.825439,100.884659 
	C1246.825439,83.006828 1246.825439,65.634857 1246.825439,48.375092 
	C1241.547729,48.375092 1236.967896,48.375092 1232.316284,48.375092 
	C1232.316284,69.663506 1232.316284,90.575386 1232.316284,112.000000 
	C1238.914917,112.000000 1245.207642,112.000000 1252.500122,112.000000 
M1035.000000,99.488464 
	C1035.000000,103.563538 1035.000000,107.638611 1035.000000,111.648499 
	C1040.418457,111.648499 1044.991089,111.648499 1049.579590,111.648499 
	C1049.579590,90.295967 1049.579590,69.297707 1049.579590,48.351601 
	C1044.579346,48.351601 1040.007690,48.351601 1035.000000,48.351601 
	C1035.000000,65.296654 1035.000000,81.893951 1035.000000,99.488464 
M678.000000,76.500000 
	C678.000000,67.099350 678.000000,57.698704 678.000000,48.343231 
	C672.932434,48.343231 668.692322,48.343231 664.410767,48.343231 
	C664.410767,69.686836 664.410767,90.684578 664.410767,111.657188 
	C669.066711,111.657188 673.306885,111.657188 678.000000,111.657188 
	C678.000000,100.046783 678.000000,88.773392 678.000000,76.500000 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M31.090481,122.439301 
	C38.691162,95.204948 56.832531,77.317398 79.939613,63.514091 
	C93.716507,55.284294 108.544136,49.929497 124.134453,46.355984 
	C138.627167,43.034054 147.623413,48.401260 151.009995,62.753269 
	C151.905991,66.550430 153.025970,70.294739 154.024979,73.996132 
	C146.140762,75.358833 138.385468,76.274872 130.842972,78.080048 
	C105.090759,84.243423 83.444855,97.496941 66.203499,117.513847 
	C60.241547,124.435577 55.497131,132.370193 53.604774,141.687393 
	C52.397503,147.631500 53.446743,152.646759 58.806850,155.019745 
	C64.982750,157.753891 71.734169,160.440536 78.330147,160.694672 
	C89.658447,161.131088 101.272659,160.801971 112.380005,158.725235 
	C128.228088,155.762115 144.202393,152.202103 159.251099,146.568512 
	C176.711258,140.032166 193.228043,130.975784 210.856308,122.871262 
	C221.711685,117.138062 231.865051,111.546875 242.018433,105.955688 
	C253.843628,99.638916 265.708588,93.395126 277.481567,86.982521 
	C287.084869,81.751724 296.578278,76.319153 306.988922,70.489708 
	C306.988922,81.171219 307.050537,91.061440 306.891052,100.948097 
	C306.875549,101.908936 305.681458,103.146713 304.717865,103.751709 
	C291.265320,112.197884 277.769043,120.574715 264.253937,128.920670 
	C261.474579,130.636993 258.574646,132.158020 255.040039,133.905914 
	C253.554413,134.208954 252.666992,134.201691 251.977585,134.567551 
	C242.534286,139.578979 233.106567,144.620346 223.717804,149.732910 
	C222.965286,150.142700 222.527649,151.130676 221.943695,151.849945 
	C201.621338,160.879379 181.619507,170.752182 160.863541,178.640717 
	C147.099899,183.871750 132.444336,187.734909 117.854553,189.595917 
	C92.682571,192.806717 68.104706,189.657974 48.020218,172.170303 
	C39.009472,164.324631 33.838200,153.473358 31.469837,141.240967 
	C31.112801,134.985229 31.067513,128.944077 31.090481,122.439301 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910889,111.579178 
	C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
	C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
	C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
	C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
	C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
	C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
	C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
	C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
	C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
	C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
	C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
	C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
	C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
	C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.986084,105.585060 
	C231.865051,111.546875 221.711685,117.138062 211.214722,122.820877 
	C206.246429,110.935951 201.626892,98.957397 196.990402,86.985413 
	C196.409958,85.486656 195.739883,84.022636 194.797913,81.804504 
	C190.028702,91.923180 185.599594,101.385948 181.111938,110.820869 
	C177.500351,118.413940 173.848495,125.988388 170.129028,133.528946 
	C169.653351,134.493301 168.787979,135.387268 167.890030,136.003174 
	C154.974106,144.862228 140.419083,148.287491 124.037666,149.215057 
	C126.146080,144.914444 127.811172,141.250351 129.701248,137.706223 
	C142.307709,114.067383 154.957672,90.451729 167.611221,66.838036 
	C168.466995,65.240997 169.846237,63.832115 170.338684,62.147644 
	C173.603333,50.980350 180.767303,47.102131 192.216293,48.817684 
	C198.897064,49.818752 205.852615,49.099297 212.680298,48.954121 
	C215.653046,48.890915 217.173096,49.603519 218.534088,52.730873 
	C225.390778,68.486588 232.732422,84.030983 239.865601,99.667007 
	C240.683777,101.460434 241.263474,103.362656 241.986084,105.585060 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M375.231079,166.720428 
	C373.698273,164.785767 372.413757,163.110245 370.241028,160.276123 
	C370.241028,175.504410 370.241028,189.336105 370.241028,203.579895 
	C365.339203,203.579895 360.976990,203.579895 356.304138,203.579895 
	C356.304138,182.561203 356.304138,161.658066 356.304138,140.452454 
	C363.840393,139.535553 370.102478,139.138763 375.770935,146.793732 
	C384.992004,159.246246 395.971527,170.396667 406.792847,182.764053 
	C406.792847,168.327881 406.792847,154.554138 406.792847,140.395035 
	C412.046234,140.395035 416.741211,140.395035 421.720337,140.395035 
	C421.720337,161.489639 421.720337,182.392502 421.720337,203.969086 
	C417.306854,203.969086 413.015106,204.237442 408.789398,203.826447 
	C407.268890,203.678558 405.643066,202.125488 404.514771,200.824020 
	C394.778015,189.593063 385.144012,178.273041 375.231079,166.720428 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M581.713379,183.402191 
	C587.269470,190.096863 592.641418,196.492844 598.903320,203.948410 
	C592.597656,203.948410 587.274414,204.912445 582.580139,203.609909 
	C579.443726,202.739624 577.071472,198.760361 574.597107,195.960480 
	C572.201599,193.249771 569.930908,190.389420 567.924622,187.381821 
	C562.614014,179.420700 554.780334,182.507217 547.120544,182.086334 
	C547.120544,189.416031 547.120544,196.334137 547.120544,203.621826 
	C542.374207,203.621826 538.129578,203.621826 533.437439,203.621826 
	C533.437439,182.711609 533.437439,161.686417 533.437439,140.092117 
	C546.247131,140.092117 559.053101,139.499512 571.761047,140.357819 
	C577.568176,140.750046 583.713135,143.113693 588.773804,146.140137 
	C599.488159,152.547699 598.588074,169.897614 587.678406,176.075302 
	C585.116150,177.526230 582.381226,178.672348 579.293030,180.168304 
	C580.066833,181.183990 580.798035,182.143738 581.713379,183.402191 
M551.665283,151.000137 
	C550.231079,151.111572 548.796875,151.223007 547.310425,151.338501 
	C547.310425,157.994583 547.310425,164.236115 547.310425,170.949112 
	C555.228821,170.949112 562.868591,171.313416 570.455078,170.833878 
	C576.527954,170.449997 580.788086,166.114838 580.995850,161.419693 
	C581.216553,156.429672 578.435425,153.164490 571.424561,152.084732 
	C565.251221,151.133987 558.903137,151.317429 551.665283,151.000137 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M430.000000,95.997246 
	C430.039093,80.170120 430.076019,64.842186 430.133026,49.514320 
	C430.134125,49.219524 430.371674,48.925613 430.426727,48.798252 
	C442.903564,48.798252 455.309998,48.419132 467.674225,48.976677 
	C472.942871,49.214260 478.343506,50.860050 483.292358,52.843472 
	C490.820007,55.860455 493.254181,62.215576 493.077423,70.093033 
	C492.912292,77.452858 488.956055,82.007393 483.085419,85.398651 
	C481.103363,86.543633 478.873901,87.260315 475.865845,88.557159 
	C482.375793,96.175858 488.679199,103.552856 495.854004,111.949661 
	C489.589569,111.949661 484.105347,113.158249 479.603668,111.574364 
	C475.877380,110.263306 473.201569,105.754707 470.261047,102.482437 
	C467.397552,99.295944 465.013855,95.651222 461.970795,92.664932 
	C460.511169,91.232498 458.071503,90.338013 455.977692,90.113434 
	C452.228119,89.711258 448.404419,90.000076 444.111969,90.000076 
	C444.111969,97.423889 444.111969,104.343544 444.111969,111.627045 
	C439.361664,111.627045 435.115234,111.627045 430.000000,111.627045 
	C430.000000,106.669426 430.000000,101.582939 430.000000,95.997246 
M444.008209,76.236343 
	C445.190735,77.156776 446.326996,78.791222 447.563934,78.871368 
	C452.363922,79.182419 457.199738,79.115051 462.014740,78.958458 
	C465.119446,78.857491 468.409821,78.911400 471.258392,77.892921 
	C474.823242,76.618347 477.848938,74.253098 478.026947,69.734612 
	C478.203003,65.266617 476.026672,62.423256 472.036072,61.081673 
	C469.431366,60.206017 466.624878,59.607391 463.887360,59.514221 
	C457.354736,59.291885 450.809418,59.442497 444.001099,59.442497 
	C444.001099,65.001976 444.001099,70.143028 444.008209,76.236343 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1014.591187,81.554077 
	C1010.556152,84.104172 1006.776184,86.389366 1002.317322,89.085014 
	C1008.377136,96.179596 1014.682007,103.561066 1021.846191,111.948570 
	C1015.576172,111.948570 1010.089722,113.157494 1005.586182,111.572906 
	C1001.863464,110.263084 999.194580,105.749222 996.256897,102.477112 
	C993.397156,99.291740 991.013794,95.651596 987.973511,92.667282 
	C986.514832,91.235428 984.078552,90.338188 981.986816,90.113556 
	C978.238892,89.711052 974.416626,90.000076 970.130859,90.000076 
	C970.130859,97.407074 970.130859,104.321976 970.130859,111.614517 
	C965.390503,111.614517 961.147156,111.614517 956.448486,111.614517 
	C956.448486,90.738197 956.448486,69.722595 956.448486,48.000000 
	C965.521057,48.000000 974.622437,47.762012 983.704102,48.082233 
	C989.937012,48.302006 996.232971,48.836784 1002.343018,50.035084 
	C1018.852234,53.272850 1023.937378,69.182167 1014.591187,81.554077 
M1003.997070,68.657852 
	C1003.903137,63.505108 1000.178345,60.906139 996.137695,60.354717 
	C987.697754,59.202942 979.113098,59.111668 970.414001,58.573997 
	C970.414001,65.972504 970.414001,72.331673 970.414001,78.989304 
	C978.465881,78.989304 986.114380,79.187477 993.746765,78.922676 
	C999.235229,78.732254 1003.413147,74.564453 1003.997070,68.657852 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M595.004944,48.000000 
	C607.232544,47.994904 618.955994,47.873325 630.246887,51.935505 
	C651.955811,59.745865 656.102051,85.023544 642.998047,100.217819 
	C636.542358,107.703300 627.104187,111.101997 617.454102,111.749985 
	C605.568115,112.548111 593.587769,111.940628 581.327087,111.940628 
	C581.327087,90.623123 581.327087,69.736221 581.327087,48.000000 
	C585.683472,48.000000 590.095337,48.000000 595.004944,48.000000 
M633.195435,71.554062 
	C631.334778,69.322403 629.817383,66.631729 627.553833,64.938675 
	C617.796143,57.640194 606.487122,59.102184 595.299377,59.085857 
	C595.299377,73.342323 595.299377,86.897949 595.299377,101.880188 
	C604.328735,100.887115 613.177002,100.899101 621.472839,98.764458 
	C633.133789,95.763901 638.283081,83.303947 633.195435,71.554062 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1217.106445,70.043411 
	C1222.787720,83.189178 1216.016479,100.092621 1202.674072,107.100372 
	C1185.758057,115.985146 1168.476196,115.970047 1152.753540,105.604515 
	C1131.736450,91.748505 1135.727661,64.244194 1154.083496,54.069775 
	C1172.858887,43.662819 1191.472290,44.550163 1208.696167,57.628860 
	C1212.387451,60.431839 1214.295898,65.582771 1217.106445,70.043411 
M1161.565674,63.961445 
	C1153.107178,72.397606 1151.499390,82.266548 1157.115234,91.279366 
	C1164.750610,103.533348 1185.597900,105.668144 1196.639893,96.035339 
	C1209.532593,84.787743 1203.413940,66.085022 1191.616455,61.052464 
	C1180.969360,56.510674 1171.538086,57.663677 1161.565674,63.961445 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M682.759644,177.367340 
	C679.227112,194.391525 666.908691,201.131653 653.095032,202.678146 
	C640.507996,204.087341 627.638916,202.976654 614.444092,202.976654 
	C614.444092,182.744232 614.444092,161.741547 614.444092,140.325821 
	C625.796082,140.325821 637.211121,139.902374 648.571045,140.509232 
	C654.032654,140.800980 659.677063,142.367508 664.735840,144.531982 
	C675.557251,149.162140 684.558655,158.249298 682.759644,177.367340 
M666.633484,179.765671 
	C669.353943,170.640808 667.510986,161.742737 661.812927,157.457108 
	C651.618958,149.790054 639.959656,150.629257 628.360596,151.332352 
	C628.360596,165.457016 628.360596,179.018295 628.360596,192.987976 
	C634.009338,192.987976 639.365540,193.506592 644.581482,192.871902 
	C653.399719,191.798859 661.826416,189.475571 666.633484,179.765671 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M514.753540,159.543243 
	C523.338196,183.803360 507.013428,200.383133 486.888275,204.015030 
	C472.655731,206.583511 459.865570,203.839890 448.147736,195.130997 
	C436.368225,186.376297 433.713043,166.981216 442.254364,155.089798 
	C457.328461,134.103333 494.978790,134.075409 510.480499,153.026031 
	C512.037598,154.929565 513.238586,157.124390 514.753540,159.543243 
M498.923981,183.214478 
	C502.477509,177.359909 503.578339,171.022614 500.804901,164.763519 
	C494.225830,149.915955 475.803345,145.275131 461.826355,154.524841 
	C454.635742,159.283447 451.147339,166.229614 452.268188,174.358307 
	C453.421722,182.724060 458.218414,189.223862 466.866669,192.092056 
	C479.252686,196.199875 489.929626,194.056412 498.923981,183.214478 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M835.393677,193.671570 
	C840.935120,192.875778 846.152100,192.184052 851.161987,190.762711 
	C852.724426,190.319412 854.786743,187.838165 854.697754,186.409470 
	C854.603149,184.888535 852.472961,182.720398 850.808472,182.207275 
	C844.214050,180.174316 837.403870,178.851776 830.770386,176.931747 
	C824.938721,175.243774 819.121765,173.389450 813.509277,171.092957 
	C808.062500,168.864304 804.868347,164.803680 804.939819,158.445480 
	C805.011963,152.024261 808.402283,147.827896 813.507568,144.878342 
	C823.012329,139.387039 833.173157,138.299103 844.088623,139.692078 
	C853.008118,140.830322 860.979858,143.674316 869.031250,148.166885 
	C867.074707,151.253098 865.530823,154.171997 863.460205,156.650833 
	C862.861816,157.367188 860.440674,157.364487 859.381958,156.763458 
	C852.056335,152.604492 844.332886,149.767014 835.855957,150.108093 
	C831.968140,150.264526 828.009705,151.239548 824.316650,152.517609 
	C822.604248,153.110214 820.441284,155.256165 820.351807,156.805786 
	C820.276489,158.111954 822.676575,160.305649 824.375854,160.832428 
	C829.862427,162.533188 835.564880,163.521286 841.120972,165.018372 
	C847.465027,166.727814 853.910828,168.259964 859.997253,170.666428 
	C865.576599,172.872360 869.659119,176.791031 870.064148,183.576828 
	C870.519409,191.204514 866.995789,196.375443 860.615845,199.649429 
	C850.938965,204.615311 840.757690,205.808350 829.847534,204.294693 
	C819.980896,202.925812 811.035156,199.772476 801.912781,194.815872 
	C804.340881,191.351135 806.554871,188.191940 809.086243,184.579941 
	C816.983093,189.995605 825.670837,192.729797 835.393677,193.671570 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M919.693848,60.031830 
	C909.799561,56.637691 900.744629,57.507141 892.367798,63.163200 
	C885.851013,67.563347 882.551208,73.951263 883.108521,81.736931 
	C883.729248,90.406876 888.768738,96.440102 896.524719,99.935318 
	C903.251953,102.966927 910.433472,102.954712 917.415161,100.946938 
	C926.892151,98.221565 926.979309,97.790405 925.475891,87.170609 
	C919.644043,87.170609 913.723145,87.170609 907.405151,87.170609 
	C907.405151,83.304581 907.405151,79.943619 907.405151,76.287170 
	C918.144531,76.287170 928.722717,76.287170 939.954956,76.287170 
	C939.954956,85.035439 940.079712,93.640167 939.808960,102.232430 
	C939.771973,103.406059 937.965149,104.907486 936.635437,105.598640 
	C919.359253,114.578186 901.641479,116.582039 883.919861,107.319389 
	C872.842529,101.529533 867.709167,91.632942 868.041748,79.166389 
	C868.349915,67.615479 873.851624,58.994267 883.978882,53.553997 
	C900.010559,44.941902 916.129028,45.061111 932.208069,53.605988 
	C934.755310,54.959633 937.272034,56.503613 934.637207,59.497746 
	C932.519592,61.904133 931.496155,67.516273 926.091370,63.557869 
	C924.239258,62.201401 922.098328,61.239231 919.693848,60.031830 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M776.983887,140.000000 
	C781.108459,140.000000 784.736572,140.000000 788.675659,140.000000 
	C788.675659,143.733459 788.675659,146.981094 788.675659,150.740448 
	C774.731506,150.740448 760.815002,150.740448 746.451904,150.740448 
	C746.451904,155.593750 746.451904,159.958771 746.451904,164.841522 
	C759.043457,164.841522 771.626282,164.841522 784.600464,164.841522 
	C784.600464,168.706207 784.600464,172.066544 784.600464,175.943329 
	C771.852234,175.943329 759.268127,175.943329 746.033813,175.943329 
	C746.033813,180.030487 745.761597,183.660248 746.099487,187.232315 
	C746.572021,192.228806 750.520752,192.923157 754.478149,192.964951 
	C764.306335,193.068756 774.136353,192.999664 783.965637,192.999985 
	C785.759583,193.000046 787.553467,193.000000 789.671265,193.000000 
	C789.671265,196.724335 789.671265,199.972153 789.671265,203.526962 
	C788.581787,203.682861 787.480347,203.974609 786.378174,203.977615 
	C774.216675,204.010818 762.049011,204.225403 749.895691,203.908569 
	C743.375000,203.738571 736.950500,202.267456 733.497131,195.774506 
	C732.213989,193.361801 731.159973,190.487579 731.122253,187.808044 
	C730.901855,172.162445 731.014160,156.512146 731.014160,140.000000 
	C746.340393,140.000000 761.413879,140.000000 776.983887,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M541.006653,101.000000 
	C549.462585,101.000000 557.419861,101.000000 565.684265,101.000000 
	C565.684265,104.751945 565.684265,107.999313 565.684265,112.000000 
	C563.860474,112.000000 562.100220,112.000000 560.340027,112.000000 
	C548.676086,112.000000 537.012085,112.008827 525.348145,111.997505 
	C513.811951,111.986313 507.018921,105.297943 507.004120,93.916275 
	C506.986572,80.419441 506.992004,66.922562 507.010468,53.425732 
	C507.012665,51.803867 507.158142,50.182201 507.250397,48.290237 
	C526.474731,48.290237 545.383484,48.290237 564.646667,48.290237 
	C564.646667,51.675587 564.646667,54.924427 564.646667,58.863136 
	C550.335693,58.863136 535.958984,58.863136 521.299500,58.863136 
	C521.299500,64.122162 521.299500,68.700874 521.299500,73.795570 
	C534.327942,73.795570 547.245300,73.795570 560.577515,73.795570 
	C560.577515,77.328445 560.577515,80.356415 560.577515,83.904221 
	C547.562744,83.904221 534.643372,83.904221 520.655457,83.904221 
	C521.183350,88.671494 520.982605,93.080765 522.384644,96.901901 
	C523.088318,98.819679 526.581055,100.396118 529.040588,100.828209 
	C532.758423,101.481346 536.675598,100.999519 541.006653,101.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M847.999512,77.998581 
	C853.776672,89.186012 859.435974,100.018402 865.645447,111.903816 
	C859.854248,111.903816 854.964233,112.222588 850.167603,111.694115 
	C848.863403,111.550430 847.303528,109.016121 846.779419,107.298721 
	C844.161438,98.720779 838.775879,95.614120 829.840698,96.841522 
	C823.794006,97.672157 817.530212,97.215614 811.384277,96.913048 
	C808.105225,96.751617 806.679504,98.170670 805.524475,100.896919 
	C800.291016,113.249062 800.246094,113.230026 785.757874,111.523003 
	C786.752502,109.175865 787.526672,106.956322 788.595276,104.888931 
	C797.634949,87.399353 806.831055,69.989456 815.714050,52.421055 
	C817.373108,49.139866 819.174438,47.370518 822.873901,47.992783 
	C823.197754,48.047253 823.550049,48.056286 823.870056,47.991714 
	C830.428711,46.668152 833.953918,49.398174 836.493896,55.616692 
	C839.606812,63.237938 844.026794,70.325325 847.999512,77.998581 
M815.490295,82.015289 
	C815.056335,83.181511 814.622375,84.347733 814.088257,85.783272 
	C821.448120,85.783272 828.328369,85.783272 835.841675,85.783272 
	C831.992249,78.057625 828.354187,70.756142 824.375122,62.770222 
	C821.345642,69.394844 818.612854,75.370735 815.490295,82.015289 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249161,152.051559 
	C222.527649,151.130676 222.965286,150.142700 223.717804,149.732910 
	C233.106567,144.620346 242.534286,139.578979 251.977585,134.567551 
	C252.666992,134.201691 253.554413,134.208954 254.732513,134.070114 
	C260.956604,146.476974 266.818390,158.846909 272.629791,171.240448 
	C275.127045,176.566132 277.519806,181.940796 280.266327,187.965073 
	C267.253296,187.965073 254.987137,188.182007 242.735703,187.852341 
	C238.190018,187.730011 235.717758,184.213211 234.067825,180.098358 
	C230.330017,170.776566 226.404083,161.530212 222.249161,152.051559 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M394.994019,47.996429 
	C401.469879,50.086708 407.556580,52.039112 413.514984,54.325691 
	C415.985229,55.273651 416.482941,57.495876 414.695740,59.538979 
	C409.578430,65.388992 411.644196,66.578026 404.064026,62.165745 
	C388.615936,53.173656 368.622681,59.562637 363.935669,74.592842 
	C360.523682,85.534271 367.655212,97.972954 379.567749,100.941025 
	C389.109192,103.318336 398.380280,102.583961 406.702850,96.997009 
	C410.048279,94.751244 412.121124,94.485184 414.041870,98.427422 
	C414.948334,100.287941 416.488678,101.839630 417.738037,103.526199 
	C401.270416,115.806389 377.553070,116.510239 361.606384,105.630310 
	C341.910675,92.192513 344.453003,66.213882 361.163971,55.330582 
	C371.328552,48.710709 382.396637,45.765167 394.994019,47.996429 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1112.088501,101.437378 
	C1117.277710,99.205734 1122.096924,97.188683 1127.213501,95.047165 
	C1129.094116,97.564468 1131.037598,100.165848 1133.244263,103.119629 
	C1128.526367,107.868347 1122.633423,110.209183 1116.647095,111.524078 
	C1101.746582,114.796982 1087.708130,113.352287 1074.894531,103.648979 
	C1061.363159,93.401993 1060.630127,71.108414 1071.345703,60.030792 
	C1078.800171,52.324379 1087.835327,49.147713 1098.189941,47.857059 
	C1110.605469,46.309532 1121.517578,49.488697 1132.422852,56.457981 
	C1130.235107,59.491596 1128.274414,62.210438 1125.973511,65.400955 
	C1122.523804,63.697845 1119.164062,62.009098 1115.778564,60.373165 
	C1105.010620,55.169777 1089.192627,59.129990 1082.803467,68.420555 
	C1078.406494,74.814148 1077.458008,81.378525 1080.809692,88.482658 
	C1084.613525,96.544746 1091.330322,100.673805 1099.789795,101.881882 
	C1103.675903,102.436844 1107.736816,101.766617 1112.088501,101.437378 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M296.293823,38.299576 
	C299.840912,36.335854 303.079620,34.586533 306.996033,32.471149 
	C306.996033,41.490196 307.133789,49.607342 306.867859,57.711239 
	C306.820526,59.153553 305.423523,61.142178 304.097839,61.861584 
	C289.008453,70.050117 273.907745,78.229630 258.606812,86.011948 
	C249.830475,90.475754 244.349579,87.931107 241.026245,78.735573 
	C239.597168,74.781311 238.172623,70.825409 236.707855,66.764473 
	C252.665390,64.446632 264.728394,54.567402 278.318542,48.089642 
	C284.356995,45.211407 290.105347,41.724567 296.293823,38.299576 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M707.001465,48.000000 
	C722.451538,48.000000 737.401733,48.000000 752.673828,48.000000 
	C752.673828,51.729095 752.673828,54.976212 752.673828,58.737747 
	C745.075012,58.737747 737.490356,58.737747 729.228271,58.737747 
	C729.228271,76.591660 729.228271,93.952660 729.228271,111.652725 
	C723.977295,111.652725 719.399841,111.652725 714.138062,111.652725 
	C714.138062,94.323456 714.138062,76.959961 714.138062,59.096588 
	C705.889832,59.096588 698.306458,59.096588 690.367493,59.096588 
	C690.367493,55.356586 690.367493,52.110477 690.367493,48.000000 
	C695.666931,48.000000 701.084351,48.000000 707.001465,48.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M917.000000,195.934418 
	C917.000000,198.723618 917.000000,201.023758 917.000000,203.655334 
	C912.304932,203.655334 908.059875,203.655334 903.129639,203.655334 
	C903.129639,186.318130 903.129639,168.950821 903.129639,151.086395 
	C894.880798,151.086395 887.297668,151.086395 879.361938,151.086395 
	C879.361938,147.342194 879.361938,144.094086 879.361938,140.420563 
	C899.645569,140.420563 920.003052,140.420563 940.681641,140.420563 
	C940.681641,143.745377 940.681641,146.992310 940.681641,150.751007 
	C933.056580,150.751007 925.470886,150.751007 917.000000,150.751007 
	C917.000000,165.978775 917.000000,180.712067 917.000000,195.934418 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1252.000244,112.000000 
	C1245.207642,112.000000 1238.914917,112.000000 1232.316284,112.000000 
	C1232.316284,90.575386 1232.316284,69.663506 1232.316284,48.375092 
	C1236.967896,48.375092 1241.547729,48.375092 1246.825439,48.375092 
	C1246.825439,65.634857 1246.825439,83.006828 1246.825439,100.884659 
	C1260.087524,100.884659 1272.674194,100.884659 1285.625488,100.884659 
	C1285.625488,104.634956 1285.625488,107.884369 1285.625488,112.000000 
	C1274.666626,112.000000 1263.583374,112.000000 1252.000244,112.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1035.000000,98.989853 
	C1035.000000,81.893951 1035.000000,65.296654 1035.000000,48.351601 
	C1040.007690,48.351601 1044.579346,48.351601 1049.579590,48.351601 
	C1049.579590,69.297707 1049.579590,90.295967 1049.579590,111.648499 
	C1044.991089,111.648499 1040.418457,111.648499 1035.000000,111.648499 
	C1035.000000,107.638611 1035.000000,103.563538 1035.000000,98.989853 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,77.000000 
	C678.000000,88.773392 678.000000,100.046783 678.000000,111.657188 
	C673.306885,111.657188 669.066711,111.657188 664.410767,111.657188 
	C664.410767,90.684578 664.410767,69.686836 664.410767,48.343231 
	C668.692322,48.343231 672.932434,48.343231 678.000000,48.343231 
	C678.000000,57.698704 678.000000,67.099350 678.000000,77.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M552.148804,151.000061 
	C558.903137,151.317429 565.251221,151.133987 571.424561,152.084732 
	C578.435425,153.164490 581.216553,156.429672 580.995850,161.419693 
	C580.788086,166.114838 576.527954,170.449997 570.455078,170.833878 
	C562.868591,171.313416 555.228821,170.949112 547.310425,170.949112 
	C547.310425,164.236115 547.310425,157.994583 547.310425,151.338501 
	C548.796875,151.223007 550.231079,151.111572 552.148804,151.000061 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M444.004639,75.760208 
	C444.001099,70.143028 444.001099,65.001976 444.001099,59.442497 
	C450.809418,59.442497 457.354736,59.291885 463.887360,59.514221 
	C466.624878,59.607391 469.431366,60.206017 472.036072,61.081673 
	C476.026672,62.423256 478.203003,65.266617 478.026947,69.734612 
	C477.848938,74.253098 474.823242,76.618347 471.258392,77.892921 
	C468.409821,78.911400 465.119446,78.857491 462.014740,78.958458 
	C457.199738,79.115051 452.363922,79.182419 447.563934,78.871368 
	C446.326996,78.791222 445.190735,77.156776 444.004639,75.760208 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1003.998169,69.059845 
	C1003.413147,74.564453 999.235229,78.732254 993.746765,78.922676 
	C986.114380,79.187477 978.465881,78.989304 970.414001,78.989304 
	C970.414001,72.331673 970.414001,65.972504 970.414001,58.573997 
	C979.113098,59.111668 987.697754,59.202942 996.137695,60.354717 
	C1000.178345,60.906139 1003.903137,63.505108 1003.998169,69.059845 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M633.417725,71.883041 
	C638.283081,83.303947 633.133789,95.763901 621.472839,98.764458 
	C613.177002,100.899101 604.328735,100.887115 595.299377,101.880188 
	C595.299377,86.897949 595.299377,73.342323 595.299377,59.085857 
	C606.487122,59.102184 617.796143,57.640194 627.553833,64.938675 
	C629.817383,66.631729 631.334778,69.322403 633.417725,71.883041 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1161.839478,63.713966 
	C1171.538086,57.663677 1180.969360,56.510674 1191.616455,61.052464 
	C1203.413940,66.085022 1209.532593,84.787743 1196.639893,96.035339 
	C1185.597900,105.668144 1164.750610,103.533348 1157.115234,91.279366 
	C1151.499390,82.266548 1153.107178,72.397606 1161.839478,63.713966 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M666.417480,180.095886 
	C661.826416,189.475571 653.399719,191.798859 644.581482,192.871902 
	C639.365540,193.506592 634.009338,192.987976 628.360596,192.987976 
	C628.360596,179.018295 628.360596,165.457016 628.360596,151.332352 
	C639.959656,150.629257 651.618958,149.790054 661.812927,157.457108 
	C667.510986,161.742737 669.353943,170.640808 666.417480,180.095886 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M498.740234,183.536133 
	C489.929626,194.056412 479.252686,196.199875 466.866669,192.092056 
	C458.218414,189.223862 453.421722,182.724060 452.268188,174.358307 
	C451.147339,166.229614 454.635742,159.283447 461.826355,154.524841 
	C475.803345,145.275131 494.225830,149.915955 500.804901,164.763519 
	C503.578339,171.022614 502.477509,177.359909 498.740234,183.536133 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M815.685181,81.680954 
	C818.612854,75.370735 821.345642,69.394844 824.375122,62.770222 
	C828.354187,70.756142 831.992249,78.057625 835.841675,85.783272 
	C828.328369,85.783272 821.448120,85.783272 814.088257,85.783272 
	C814.622375,84.347733 815.056335,83.181511 815.685181,81.680954 
z"
      />
    </svg>
  );
};

CaNordEstSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaNordEstSvg;
