import * as React from 'react';
import PropTypes from 'prop-types';

const CaHauteLoireSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1503 255"
      enableBackground="new 0 0 1503 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1473.840576,204.035202 
	C1473.840576,211.170746 1473.840576,218.306305 1473.840576,225.988754 
	C1078.829834,225.988754 683.893860,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018951 31.007444,168.484467 31.396381,141.702820 
	C33.838264,153.473465 39.009602,164.324814 48.020470,172.170547 
	C68.105293,189.658325 92.683495,192.806610 117.855659,189.595779 
	C132.445618,187.734772 147.101349,183.871613 160.865128,178.640488 
	C181.620605,170.752075 201.621841,160.879211 222.249161,152.051453 
	C226.401337,161.514511 230.315979,170.748550 234.069580,180.047592 
	C236.375305,185.759766 239.398315,187.983429 245.655029,187.995209 
	C255.290085,188.013351 264.925201,187.999985 274.560303,188.000000 
	C276.264221,188.000000 277.968170,188.000000 280.448944,188.000000 
	C273.319214,173.013260 266.732849,159.006454 259.977203,145.081772 
	C258.278778,141.580994 256.037781,138.343430 254.174698,134.660690 
	C257.614868,132.538422 261.016510,130.886856 264.222443,128.916809 
	C277.757782,120.599281 291.248199,112.208153 304.705109,103.764450 
	C305.670959,103.158440 306.874115,101.924675 306.889679,100.965324 
	C307.049896,91.074623 306.988037,81.180328 306.988037,70.491653 
	C296.584625,76.315933 287.090088,81.747597 277.486145,86.978424 
	C265.710999,93.391823 253.844376,99.637215 241.985367,105.584625 
	C241.263489,103.362724 240.682526,101.461868 239.866486,99.667946 
	C232.820511,84.178627 225.572601,68.778030 218.782349,53.177982 
	C217.308487,49.791862 215.599670,48.869457 212.196671,48.933273 
	C202.206451,49.120617 192.205673,49.177055 182.219223,48.907486 
	C178.543015,48.808254 176.520935,50.102062 174.869690,53.248089 
	C166.877426,68.475082 158.741241,83.626740 150.619904,98.785721 
	C143.750565,111.607780 136.803360,124.388168 129.952667,137.220108 
	C127.974380,140.925568 126.210533,144.745529 124.010025,149.197540 
	C137.952606,148.485031 150.455215,145.990158 161.864899,139.552277 
	C165.181381,137.680954 169.092316,135.363968 170.698364,132.214767 
	C178.496216,116.924477 185.596039,101.278458 192.972198,85.772278 
	C193.447876,84.772285 194.168213,83.888672 195.304291,82.132172 
	C200.780655,96.478523 205.825912,109.695511 210.512726,122.962891 
	C193.228073,130.975769 176.711182,140.031555 159.251282,146.568542 
	C144.205704,152.201584 128.234818,155.762192 112.389915,158.725021 
	C101.282639,160.801971 89.668510,161.130295 78.340096,160.694962 
	C71.743446,160.441452 64.991112,157.758682 58.814152,155.026505 
	C53.454960,152.656006 52.399544,147.626175 53.600117,141.696182 
	C55.595718,131.839310 60.955166,123.733871 67.251579,116.439537 
	C84.207069,96.796776 105.713844,84.255226 130.845535,78.086937 
	C138.374527,76.239037 146.141006,75.358864 154.025040,73.996208 
	C153.025635,70.293289 151.905869,66.549538 151.009964,62.752960 
	C147.623291,48.401169 138.626923,43.034237 124.134201,46.356049 
	C108.543839,49.929440 93.716331,55.284443 79.939484,63.514210 
	C56.832458,77.317467 38.691158,95.205025 31.158731,121.975655 
	C31.158731,91.854843 31.158731,61.734039 31.158731,31.011070 
	C199.130249,31.011070 367.080994,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161100 
	C552.855103,31.107401 554.683533,31.006689 556.512024,31.006647 
	C860.376099,30.999716 1164.240234,31.000000 1468.104248,31.000000 
	C1469.904907,31.000000 1471.705688,31.000000 1473.988770,31.000000 
	C1473.988770,84.877258 1473.988770,138.459610 1473.456665,192.515274 
	C1461.104736,192.992142 1449.285034,192.981781 1437.465210,193.006226 
	C1432.801147,193.015854 1429.766846,190.789307 1429.119141,186.217712 
	C1428.665161,183.013321 1429.031494,179.692688 1429.031494,175.775360 
	C1442.712891,175.775360 1455.744629,175.775360 1468.613281,175.775360 
	C1468.613281,171.849960 1468.613281,168.605698 1468.613281,164.876862 
	C1455.326416,164.876862 1442.417725,164.876862 1429.424805,164.876862 
	C1429.424805,160.006805 1429.424805,155.648895 1429.424805,150.802719 
	C1443.752319,150.802719 1457.661133,150.802719 1471.716309,150.802719 
	C1471.716309,147.059448 1471.716309,143.815262 1471.716309,140.380524 
	C1452.750610,140.380524 1434.068604,140.380524 1415.000000,140.380524 
	C1415.000000,155.382523 1415.475342,170.027313 1414.859619,184.626099 
	C1414.299561,197.904236 1422.322876,203.978226 1434.385986,203.997803 
	C1447.537476,204.019150 1460.689087,204.023468 1473.840576,204.035202 
M599.997009,162.245667 
	C600.988342,148.792160 588.636597,141.592117 579.535828,141.084137 
	C567.443298,140.409149 555.317627,140.323868 543.205566,140.008453 
	C540.943054,139.949524 538.677551,140.000000 536.299316,140.000000 
	C536.299316,161.633820 536.299316,182.543594 536.299316,203.582870 
	C541.302185,203.582870 546.000000,203.582870 551.199707,203.582870 
	C551.199707,196.251450 551.199707,189.331390 551.199707,182.006866 
	C556.009216,182.006866 560.320007,181.858139 564.609375,182.096375 
	C565.917053,182.169006 567.482788,183.019104 568.382141,184.010437 
	C571.148010,187.059265 573.785889,190.247757 576.226501,193.563736 
	C583.633728,203.627640 591.129822,206.493988 601.984619,203.003876 
	C595.583679,195.609558 589.254944,188.298706 582.363464,180.337723 
	C591.393860,177.299484 598.552429,172.917038 599.997009,162.245667 
M1370.467773,186.970276 
	C1374.778687,191.974075 1379.068604,196.996140 1383.421509,201.963120 
	C1384.118530,202.758530 1385.076172,203.847809 1385.948242,203.880951 
	C1390.838135,204.066818 1395.738647,203.970474 1402.170166,203.970474 
	C1394.862305,195.350586 1388.548340,187.903030 1381.917480,180.081711 
	C1383.489136,179.506760 1384.520508,179.105957 1385.567261,178.750183 
	C1394.123169,175.842239 1399.976318,169.735519 1399.121704,159.044327 
	C1398.480713,151.024689 1394.601440,145.921997 1385.190674,142.567810 
	C1375.057861,138.956223 1364.574097,140.207184 1354.192627,140.019409 
	C1348.267944,139.912231 1342.339722,140.000000 1336.437988,140.000000 
	C1336.437988,161.723312 1336.437988,182.731049 1336.437988,203.626801 
	C1341.124512,203.626801 1345.366699,203.626801 1350.102173,203.626801 
	C1350.102173,196.350296 1350.102173,189.437195 1350.102173,182.626709 
	C1361.409424,180.713272 1365.152344,181.468430 1370.467773,186.970276 
M492.999786,71.201111 
	C494.682159,56.337032 483.045990,50.121090 472.302826,49.185722 
	C459.608032,48.080429 446.795166,48.320404 434.032410,48.024464 
	C432.778229,47.995384 431.516388,48.296429 430.329254,48.435883 
	C430.329254,69.734550 430.329254,90.625641 430.329254,111.558647 
	C435.027222,111.558647 439.388611,111.558647 444.237976,111.558647 
	C444.237976,104.208321 444.237976,97.294456 444.237976,90.000137 
	C448.536255,90.000137 452.371490,89.700935 456.127167,90.118126 
	C458.209015,90.349380 460.613739,91.318130 462.071564,92.763039 
	C465.096741,95.761345 467.496185,99.379181 470.323578,102.592125 
	C473.135376,105.787376 475.649536,110.336365 479.210968,111.510132 
	C483.905090,113.057198 489.493591,111.890450 495.845795,111.890450 
	C488.681244,103.554619 482.385590,96.229729 476.020111,88.823616 
	C489.760010,81.306946 491.683777,79.382950 492.999786,71.201111 
M970.000000,101.492249 
	C970.000000,97.746040 970.000000,93.999840 970.000000,90.000061 
	C974.526917,90.000061 978.225403,89.667465 981.824524,90.121994 
	C984.064758,90.404900 986.632568,91.387230 988.209900,92.922340 
	C991.262146,95.892845 993.606262,99.575424 996.453247,102.776642 
	C999.276062,105.950661 1001.863159,110.372696 1005.447937,111.570175 
	C1010.022583,113.098312 1015.494385,111.940689 1021.953186,111.940689 
	C1017.857666,107.265923 1014.616882,103.634880 1011.453918,99.937225 
	C1008.298462,96.248314 1005.221008,92.492630 1001.926819,88.547630 
	C1004.889343,87.239159 1007.258179,86.432838 1009.384827,85.206558 
	C1014.768738,82.102005 1018.422424,77.842560 1019.049622,71.239014 
	C1020.440979,56.590462 1009.718811,50.258507 998.286865,49.197712 
	C985.585876,48.019154 972.746521,48.319904 959.966309,48.025341 
	C958.715576,47.996513 957.457031,48.309139 956.384583,48.440857 
	C956.384583,69.806610 956.384583,90.718803 956.384583,111.673843 
	C961.021790,111.673843 965.268066,111.673843 970.000000,111.673843 
	C970.000000,108.393677 970.000000,105.432884 970.000000,101.492249 
M632.163635,107.888824 
	C635.821716,105.366669 640.241577,103.487915 643.007935,100.212616 
	C655.978271,84.856094 651.995728,59.443520 630.131775,52.062950 
	C624.601135,50.195969 618.584351,49.230125 612.738770,48.953209 
	C602.371765,48.462112 591.964783,48.815414 581.390137,48.815414 
	C581.390137,69.598442 581.390137,90.616707 581.390137,112.000000 
	C591.579346,112.000000 601.415833,112.489395 611.175110,111.832382 
	C617.972412,111.374786 624.663757,109.344238 632.163635,107.888824 
M1153.735107,54.095310 
	C1152.576050,55.032433 1151.463745,56.035553 1150.250610,56.896599 
	C1137.102295,66.229088 1135.242554,87.545532 1145.936157,99.570244 
	C1160.210083,115.621017 1187.797852,116.911423 1204.266846,106.174805 
	C1214.886108,99.251839 1220.285645,89.331703 1218.853027,76.711800 
	C1217.601562,65.687790 1211.413574,57.588181 1201.272217,52.645481 
	C1185.423340,44.921043 1169.836426,45.422249 1153.735107,54.095310 
M440.994690,204.887619 
	C453.322418,205.527740 465.035858,203.903046 475.144501,195.962051 
	C490.575043,183.840302 491.578888,161.534332 475.925385,148.649902 
	C460.948364,136.322266 436.079742,135.828308 420.033417,147.278732 
	C404.939545,158.049515 402.482483,179.300034 415.167908,192.731018 
	C422.019104,199.984863 430.723267,202.955780 440.994690,204.887619 
M1215.494873,192.995255 
	C1217.629395,194.809341 1219.531006,197.047058 1221.936035,198.368835 
	C1237.681396,207.022003 1253.626831,207.404343 1269.706909,199.115082 
	C1280.525024,193.538345 1286.209717,184.230759 1286.766968,172.604492 
	C1287.301880,161.440659 1281.597778,152.087738 1272.314575,146.373169 
	C1256.556274,136.672546 1239.781128,136.184952 1223.294922,144.964462 
	C1203.861328,155.313568 1201.376953,179.442154 1215.494873,192.995255 
M888.755859,51.080509 
	C887.869202,51.533840 886.992920,52.009121 886.094238,52.437218 
	C873.531250,58.421585 867.367432,68.515938 868.102905,82.263939 
	C868.792053,95.146080 875.628662,104.280807 887.561890,109.153358 
	C904.469849,116.057198 920.890808,113.963120 936.722778,105.535378 
	C938.035583,104.836563 939.784607,103.312073 939.821350,102.124504 
	C940.087036,93.540306 939.963806,84.944069 939.963806,76.325714 
	C928.642700,76.325714 918.063049,76.325714 907.445190,76.325714 
	C907.445190,80.027107 907.445190,83.386429 907.445190,87.247887 
	C913.807556,87.247887 919.728577,87.247887 925.570068,87.247887 
	C926.689087,96.849457 926.286377,98.041298 921.112244,99.874687 
	C913.069763,102.724426 904.923218,103.368378 896.891968,100.030579 
	C888.956787,96.732712 883.946960,90.708107 883.131592,82.118378 
	C882.374268,74.139641 885.716980,67.455734 892.476868,63.122551 
	C903.578491,56.006245 914.859497,56.343964 925.976318,63.403637 
	C929.959106,65.932899 931.501770,65.150467 934.905762,59.314503 
	C937.017029,55.694904 934.257324,54.712593 931.928833,53.458244 
	C918.188843,46.056450 904.035156,45.277546 888.755859,51.080509 
M729.179199,203.950394 
	C729.786133,202.298309 730.875610,200.658249 730.917297,198.992050 
	C731.106262,191.420349 731.000000,183.841278 731.000000,176.351898 
	C743.757935,176.351898 755.660461,176.351898 768.059326,176.351898 
	C768.059326,185.604324 768.059326,194.517380 768.059326,203.606689 
	C773.261658,203.606689 777.952209,203.606689 782.693726,203.606689 
	C782.693726,182.318588 782.693726,161.425232 782.693726,140.418427 
	C777.687317,140.418427 772.993103,140.418427 767.806763,140.418427 
	C767.806763,148.743988 767.806763,156.656052 767.806763,164.547699 
	C755.276733,164.547699 743.257324,164.547699 730.742798,164.547699 
	C730.742798,156.186813 730.742798,148.274841 730.742798,140.317230 
	C725.657593,140.317230 721.082092,140.317230 716.406616,140.317230 
	C716.406616,161.648575 716.406616,182.660217 716.406616,203.991776 
	C720.618225,203.991776 724.430542,203.991776 729.179199,203.950394 
M1030.000000,156.501953 
	C1030.000000,166.164383 1029.993774,175.826797 1030.001709,185.489227 
	C1030.011353,197.322815 1036.557251,203.934128 1048.433350,203.987915 
	C1060.427856,204.042236 1072.422852,204.011276 1084.417603,203.984772 
	C1085.847656,203.981613 1087.277344,203.771820 1088.657349,203.661789 
	C1088.657349,199.944427 1088.657349,196.696228 1088.657349,193.000000 
	C1086.687988,193.000000 1085.057373,193.000015 1083.426880,193.000000 
	C1073.597778,192.999939 1063.756348,193.275284 1053.947266,192.832153 
	C1050.999390,192.698990 1046.578491,191.298828 1045.587402,189.186462 
	C1043.839722,185.461639 1044.209595,180.743240 1043.618286,175.818893 
	C1057.735352,175.818893 1070.651001,175.818893 1083.715820,175.818893 
	C1083.715820,172.061844 1083.715820,168.816055 1083.715820,164.890564 
	C1070.408203,164.890564 1057.366699,164.890564 1044.336792,164.890564 
	C1044.336792,159.954117 1044.336792,155.708862 1044.336792,150.789017 
	C1059.030884,150.789017 1073.400269,150.789017 1087.612549,150.789017 
	C1087.612549,146.847122 1087.612549,143.603241 1087.612549,140.319672 
	C1068.317505,140.319672 1049.411499,140.319672 1030.000000,140.319672 
	C1030.000000,145.587585 1030.000000,150.545578 1030.000000,156.501953 
M629.790588,186.518005 
	C629.474854,183.166504 629.159058,179.814987 628.789795,175.895569 
	C642.651001,175.895569 655.570496,175.895569 668.573120,175.895569 
	C668.573120,172.013016 668.573120,168.651901 668.573120,164.785034 
	C655.235046,164.785034 642.316711,164.785034 629.305481,164.785034 
	C629.305481,160.022171 629.305481,155.775833 629.305481,150.849762 
	C643.972656,150.849762 658.348938,150.849762 672.639038,150.849762 
	C672.639038,146.909790 672.639038,143.660721 672.639038,140.299301 
	C653.367004,140.299301 634.457214,140.299301 615.241638,140.299301 
	C615.152283,142.203873 615.012329,143.827698 615.010071,145.451706 
	C614.992126,158.616257 614.983765,171.780884 615.004700,184.945435 
	C615.024414,197.361298 621.590576,203.967957 633.941650,203.994583 
	C645.606384,204.019730 657.271301,204.008713 668.936096,203.989655 
	C670.529114,203.987061 672.121826,203.845871 673.649292,203.772018 
	C673.649292,199.929855 673.649292,196.680878 673.649292,193.000000 
	C671.508911,193.000000 669.706055,193.000076 667.903137,193.000000 
	C658.071350,192.999664 648.235962,193.153564 638.409729,192.919617 
	C634.705505,192.831436 630.459778,192.688477 629.790588,186.518005 
M560.086731,74.236122 
	C547.171143,74.236122 534.255493,74.236122 521.337769,74.236122 
	C521.337769,68.622070 521.337769,64.042961 521.337769,58.958176 
	C535.918091,58.958176 550.171509,58.958176 564.603821,58.958176 
	C564.603821,55.075336 564.603821,51.714062 564.603821,48.000000 
	C554.733459,48.000000 545.254944,48.000000 535.776428,48.000000 
	C526.337463,48.000000 516.898438,48.000000 507.000000,48.000000 
	C507.000000,50.298981 507.000000,51.928547 507.000000,53.558113 
	C507.000000,67.057838 506.983124,80.557587 507.005280,94.057274 
	C507.023468,105.136620 513.887512,111.976845 524.995422,111.995445 
	C536.995178,112.015533 548.994934,112.009064 560.994690,111.988892 
	C562.583374,111.986221 564.171814,111.834427 565.627441,111.758614 
	C565.627441,107.887993 565.627441,104.638542 565.627441,101.000000 
	C563.623962,101.000000 561.982910,101.000015 560.341797,101.000008 
	C550.175354,100.999947 539.998962,101.243301 529.849243,100.831131 
	C527.265015,100.726189 523.301208,99.121002 522.534851,97.188507 
	C520.992371,93.298622 521.212646,88.709656 520.631836,83.783524 
	C534.774597,83.783524 547.695251,83.783524 560.745789,83.783524 
	C560.745789,80.475845 560.745789,77.676720 560.086731,74.236122 
M1352.748779,48.887321 
	C1351.677979,48.592529 1350.607910,48.044220 1349.536133,48.040844 
	C1332.712524,47.987877 1315.888916,47.996525 1299.065186,48.029305 
	C1298.134033,48.031116 1297.203369,48.366840 1296.000000,48.599922 
	C1296.000000,64.460365 1295.993164,80.100922 1296.002441,95.741470 
	C1296.008057,105.164017 1302.758911,111.945869 1312.279663,111.987114 
	C1324.439209,112.039780 1336.599121,111.999962 1348.758789,112.000000 
	C1350.693115,112.000008 1352.627441,112.000000 1354.715332,112.000000 
	C1354.715332,108.059608 1354.715332,104.814575 1354.715332,101.253990 
	C1352.838135,101.160995 1351.213745,101.014084 1349.588989,101.010818 
	C1339.094971,100.989693 1328.600830,101.023087 1318.106934,100.990013 
	C1312.923340,100.973679 1310.570679,99.103073 1310.090454,94.189217 
	C1309.774292,90.953880 1310.030640,87.662605 1310.030640,83.917145 
	C1323.631104,83.917145 1336.547241,83.917145 1349.587891,83.917145 
	C1349.587891,80.375336 1349.587891,77.348122 1349.587891,73.820030 
	C1336.269287,73.820030 1323.355713,73.820030 1310.282959,73.820030 
	C1310.282959,68.732521 1310.282959,64.155418 1310.282959,58.900780 
	C1324.588257,58.900780 1338.628784,58.900780 1352.947998,58.900780 
	C1352.947998,55.416203 1352.947998,52.603397 1352.748779,48.887321 
M802.121399,187.635040 
	C799.379700,192.827606 796.638062,198.020157 793.790161,203.413986 
	C807.390808,205.483047 807.842102,205.261185 813.341614,194.270538 
	C813.564392,193.825409 813.866089,193.406433 814.011658,192.938171 
	C815.008850,189.729950 817.145813,188.761993 820.452087,188.928284 
	C825.768860,189.195694 831.219543,189.688797 836.415649,188.854156 
	C846.692139,187.203476 853.117920,190.729034 856.047974,200.805435 
	C856.414795,202.067001 858.072876,203.757843 859.235107,203.842453 
	C863.979675,204.187790 868.764038,203.986389 874.095459,203.986389 
	C873.579651,202.597336 873.383423,201.833145 873.029541,201.150696 
	C864.115845,183.960632 854.866150,166.934174 846.413269,149.520889 
	C842.962708,142.412552 838.943604,138.553696 830.637939,139.842316 
	C827.723389,140.294510 826.012634,140.801636 824.610901,143.622421 
	C817.390198,158.153290 809.900146,172.550323 802.121399,187.635040 
M838.484375,59.977524 
	C836.944824,56.859852 835.434509,53.727142 833.846680,50.634251 
	C833.407227,49.778381 832.779175,48.393288 832.166260,48.353630 
	C828.030396,48.086010 823.770386,47.554123 819.773926,48.301769 
	C817.974487,48.638405 816.312195,51.522339 815.228638,53.586876 
	C805.932312,71.300232 796.774109,89.086128 787.606567,106.866806 
	C786.890869,108.254906 786.417053,109.767693 785.783508,111.340057 
	C798.963013,113.695717 799.947510,113.220085 805.369446,102.205551 
	C806.162415,100.594658 806.893799,98.953476 807.806152,97.000000 
	C814.654358,97.000000 821.725464,97.741783 828.573914,96.823318 
	C838.447937,95.499092 844.540771,98.698502 847.271667,108.411774 
	C847.668213,109.822304 849.538208,111.685074 850.868774,111.808968 
	C855.407654,112.231606 860.010132,111.970764 865.133972,111.970764 
	C864.506104,110.344391 864.244690,109.415382 863.809387,108.576561 
	C855.509644,92.582275 847.189270,76.598648 838.484375,59.977524 
M944.951721,141.833038 
	C940.642334,138.008408 935.864441,140.818298 931.000000,140.094803 
	C931.000000,152.518463 931.014893,164.171097 930.983032,175.823578 
	C930.978577,177.472290 930.994080,179.185913 930.578491,180.757980 
	C928.141602,189.975845 919.463684,195.237213 908.975891,193.983292 
	C899.678833,192.871750 893.173828,185.451385 893.031799,175.441254 
	C892.889954,165.445709 893.012390,155.446548 892.988647,145.449051 
	C892.984497,143.703339 892.830505,141.957993 892.754517,140.392609 
	C887.841125,140.392609 883.596497,140.392609 879.000000,140.392609 
	C879.000000,152.923477 878.849243,165.067719 879.045898,177.206329 
	C879.214722,187.620514 884.111572,195.623398 893.191528,200.504364 
	C903.071716,205.815475 913.661011,206.151047 924.430542,203.258301 
	C936.998596,199.882431 945.747437,187.995590 945.110840,175.258102 
	C944.570862,164.454285 944.994629,153.602280 944.951721,141.833038 
M1064.000000,79.548584 
	C1063.528320,89.077583 1066.925049,96.948456 1074.229370,103.071762 
	C1086.515259,113.371086 1100.840332,114.811035 1115.790894,111.769089 
	C1122.096802,110.486046 1128.336060,108.093506 1133.237183,103.081398 
	C1131.552979,100.872887 1129.866699,99.194939 1128.834961,97.181549 
	C1127.360718,94.304573 1125.461914,94.831039 1123.602173,96.224174 
	C1116.543945,101.511917 1108.504883,102.480919 1100.046143,102.013222 
	C1090.027832,101.459312 1079.186768,91.310837 1079.000000,82.195305 
	C1078.691040,67.111099 1093.659790,54.761139 1108.418701,58.381401 
	C1114.411255,59.851322 1120.094360,62.582920 1126.296265,64.880493 
	C1128.155029,62.337505 1130.148071,59.610691 1132.431030,56.487331 
	C1116.157715,45.794689 1099.430298,44.388268 1082.335938,52.046036 
	C1071.238525,57.017338 1064.268799,65.735992 1064.000000,79.548584 
M392.862366,58.423466 
	C397.412811,60.205788 402.190704,61.567204 406.458282,63.873070 
	C412.270233,67.013405 412.733093,61.378204 414.817535,59.059063 
	C417.383728,56.203899 414.445557,54.831242 412.230927,53.646023 
	C395.809692,44.857731 379.368988,44.941006 363.152893,53.994610 
	C344.639557,64.330780 341.216431,91.582588 361.651154,105.698769 
	C377.627960,116.735481 401.622406,115.381172 417.734253,103.494270 
	C416.467316,101.797394 414.960693,100.224998 413.983063,98.373306 
	C412.191315,94.979614 410.531128,94.507446 407.084167,96.829544 
	C398.665070,102.501190 389.222473,103.404022 379.491791,100.934570 
	C367.421448,97.871368 360.213623,85.055710 364.049774,74.029892 
	C367.678253,63.601055 379.078400,57.124905 392.862366,58.423466 
M281.018311,74.482590 
	C288.784912,70.292595 296.597900,66.184357 304.270813,61.829273 
	C305.498627,61.132389 306.832397,59.351471 306.870209,58.035847 
	C307.106903,49.800171 306.989746,41.554321 306.989746,32.375687 
	C296.914917,37.914627 287.827057,43.347073 278.360626,48.011108 
	C264.849701,54.667839 252.715607,64.435822 236.542053,66.756660 
	C238.610794,71.988075 240.384705,77.218048 242.720764,82.183556 
	C245.342590,87.756500 249.534073,89.610390 255.313599,87.120132 
	C263.845886,83.443794 272.055786,79.019241 281.018311,74.482590 
M692.776123,58.992714 
	C699.709717,58.992714 706.643250,58.992714 714.065002,58.992714 
	C714.065002,76.943649 714.065002,94.188889 714.065002,111.597061 
	C719.273987,111.597061 723.970093,111.597061 729.165771,111.597061 
	C729.165771,93.953995 729.165771,76.708557 729.165771,58.784477 
	C737.366150,58.784477 745.076477,58.784477 752.609009,58.784477 
	C752.609009,54.844105 752.609009,51.598629 752.609009,48.321091 
	C731.642883,48.321091 711.069397,48.321091 689.188293,48.321091 
	C690.167236,52.244061 691.004456,55.599140 692.776123,58.992714 
M995.000000,175.500000 
	C995.000000,167.420364 995.000000,159.340744 995.000000,150.783218 
	C1003.436646,150.783218 1011.020630,150.783218 1018.698853,150.783218 
	C1018.698853,147.026596 1018.698853,143.780853 1018.698853,140.402008 
	C998.039490,140.402008 977.683533,140.402008 957.342407,140.402008 
	C957.342407,144.056213 957.342407,147.303864 957.342407,151.226807 
	C965.375488,151.226807 973.085266,151.226807 981.260254,151.226807 
	C981.260254,169.153015 981.260254,186.394165 981.260254,203.677551 
	C986.017395,203.677551 990.261414,203.677551 995.000000,203.677551 
	C995.000000,194.409470 995.000000,185.454742 995.000000,175.500000 
M1280.365723,112.000000 
	C1282.121094,112.000000 1283.876465,112.000000 1285.685059,112.000000 
	C1285.685059,107.992340 1285.685059,104.749069 1285.685059,100.851501 
	C1272.693726,100.851501 1260.001953,100.851501 1246.857666,100.851501 
	C1246.857666,82.967651 1246.857666,65.742058 1246.857666,48.418205 
	C1241.679932,48.418205 1236.991211,48.418205 1232.314087,48.418205 
	C1232.314087,69.719872 1232.314087,90.601753 1232.314087,112.000000 
	C1248.214478,112.000000 1263.806519,112.000000 1280.365723,112.000000 
M1172.765015,192.991806 
	C1169.101685,193.142303 1170.036743,190.386429 1170.029419,188.443298 
	C1169.975098,174.138519 1170.008423,159.833435 1169.989258,145.528488 
	C1169.986938,143.776123 1169.837891,142.023956 1169.760620,140.352341 
	C1164.581177,140.352341 1160.007935,140.352341 1155.426270,140.352341 
	C1155.426270,161.708511 1155.426270,182.709457 1155.426270,203.671112 
	C1173.410522,203.671112 1190.966309,203.671112 1208.558472,203.671112 
	C1208.558472,199.974915 1208.558472,196.617966 1208.558472,192.998886 
	C1196.651978,192.998886 1185.184937,192.998886 1172.765015,192.991806 
M409.093201,193.239700 
	C396.194763,193.239700 383.296356,193.239700 369.750427,193.239700 
	C369.750427,174.923950 369.750427,157.578369 369.750427,140.385986 
	C364.844238,140.385986 360.604675,140.385986 356.286438,140.385986 
	C356.286438,161.657684 356.286438,182.542206 356.286438,203.633148 
	C374.249634,203.633148 391.924774,203.633148 409.750763,203.633148 
	C409.750763,200.142303 409.750763,197.016266 409.093201,193.239700 
M1043.458008,112.000000 
	C1045.536621,112.000000 1047.615112,112.000000 1049.686279,112.000000 
	C1049.686279,90.283882 1049.686279,69.400108 1049.686279,48.422497 
	C1044.674194,48.422497 1039.981934,48.422497 1035.320801,48.422497 
	C1035.320801,69.730476 1035.320801,90.614349 1035.320801,112.000000 
	C1037.915527,112.000000 1040.207764,112.000000 1043.458008,112.000000 
M1317.000000,162.500031 
	C1317.000000,155.094345 1317.000000,147.688660 1317.000000,140.347626 
	C1311.589355,140.347626 1307.016846,140.347626 1302.416016,140.347626 
	C1302.416016,161.695877 1302.416016,182.693802 1302.416016,203.652771 
	C1307.409790,203.652771 1311.982300,203.652771 1317.000000,203.652771 
	C1317.000000,190.040375 1317.000000,176.770203 1317.000000,162.500031 
M503.000000,193.474411 
	C503.000000,196.885330 503.000000,200.296249 503.000000,203.651306 
	C508.078613,203.651306 512.318726,203.651306 516.582886,203.651306 
	C516.582886,182.301453 516.582886,161.303009 516.582886,140.349365 
	C511.919983,140.349365 507.679901,140.349365 503.000000,140.349365 
	C503.000000,157.956726 503.000000,175.218552 503.000000,193.474411 
M678.000000,103.445374 
	C678.000000,85.066139 678.000000,66.686913 678.000000,48.344765 
	C672.930237,48.344765 668.689636,48.344765 664.415527,48.344765 
	C664.415527,69.692207 664.415527,90.692169 664.415527,111.651642 
	C669.077026,111.651642 673.317383,111.651642 678.000000,111.651642 
	C678.000000,109.013191 678.000000,106.718338 678.000000,103.445374 
M1105.667969,166.000137 
	C1104.234985,166.109863 1102.802124,166.219604 1101.298706,166.334747 
	C1101.298706,170.313202 1101.298706,173.886230 1101.298706,177.626251 
	C1114.603394,177.626251 1127.606689,177.626251 1140.695801,177.626251 
	C1140.695801,173.675919 1140.695801,170.102585 1140.695801,166.000000 
	C1129.145020,166.000000 1117.890015,166.000000 1105.667969,166.000137 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M31.090477,122.439293 
	C38.691158,95.205025 56.832458,77.317467 79.939484,63.514210 
	C93.716331,55.284443 108.543839,49.929440 124.134201,46.356049 
	C138.626923,43.034237 147.623291,48.401169 151.009964,62.752960 
	C151.905869,66.549538 153.025635,70.293289 154.025040,73.996208 
	C146.141006,75.358864 138.374527,76.239037 130.845535,78.086937 
	C105.713844,84.255226 84.207069,96.796776 67.251579,116.439537 
	C60.955166,123.733871 55.595718,131.839310 53.600117,141.696182 
	C52.399544,147.626175 53.454960,152.656006 58.814152,155.026505 
	C64.991112,157.758682 71.743446,160.441452 78.340096,160.694962 
	C89.668510,161.130295 101.282639,160.801971 112.389915,158.725021 
	C128.234818,155.762192 144.205704,152.201584 159.251282,146.568542 
	C176.711182,140.031555 193.228073,130.975769 210.856522,122.871155 
	C220.742050,117.964195 229.938873,113.224998 239.090973,108.400970 
	C240.190613,107.821350 241.047760,106.781677 242.017303,105.955231 
	C253.844376,99.637215 265.710999,93.391823 277.486145,86.978424 
	C287.090088,81.747597 296.584625,76.315933 306.988037,70.491653 
	C306.988037,81.180328 307.049896,91.074623 306.889679,100.965324 
	C306.874115,101.924675 305.670959,103.158440 304.705109,103.764450 
	C291.248199,112.208153 277.757782,120.599281 264.222443,128.916809 
	C261.016510,130.886856 257.614868,132.538422 253.828384,134.802795 
	C243.180252,140.233139 233.000412,145.183914 222.854919,150.204056 
	C222.392853,150.432693 222.240356,151.286972 221.943665,151.849854 
	C201.621841,160.879211 181.620605,170.752075 160.865128,178.640488 
	C147.101349,183.871613 132.445618,187.734772 117.855659,189.595779 
	C92.683495,192.806610 68.105293,189.658325 48.020470,172.170547 
	C39.009602,164.324814 33.838264,153.473465 31.469843,141.240967 
	C31.112801,134.985214 31.067513,128.944077 31.090477,122.439293 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1473.910889,203.579193 
	C1460.689087,204.023468 1447.537476,204.019150 1434.385986,203.997803 
	C1422.322876,203.978226 1414.299561,197.904236 1414.859619,184.626099 
	C1415.475342,170.027313 1415.000000,155.382523 1415.000000,140.380524 
	C1434.068604,140.380524 1452.750610,140.380524 1471.716309,140.380524 
	C1471.716309,143.815262 1471.716309,147.059448 1471.716309,150.802719 
	C1457.661133,150.802719 1443.752319,150.802719 1429.424805,150.802719 
	C1429.424805,155.648895 1429.424805,160.006805 1429.424805,164.876862 
	C1442.417725,164.876862 1455.326416,164.876862 1468.613281,164.876862 
	C1468.613281,168.605698 1468.613281,171.849960 1468.613281,175.775360 
	C1455.744629,175.775360 1442.712891,175.775360 1429.031494,175.775360 
	C1429.031494,179.692688 1428.665161,183.013321 1429.119141,186.217712 
	C1429.766846,190.789307 1432.801147,193.015854 1437.465210,193.006226 
	C1449.285034,192.981781 1461.104736,192.992142 1473.382568,192.976471 
	C1473.887573,196.350647 1473.934448,199.736908 1473.910889,203.579193 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.985367,105.584625 
	C241.047760,106.781677 240.190613,107.821350 239.090973,108.400970 
	C229.938873,113.224998 220.742050,117.964195 211.214966,122.820747 
	C205.825912,109.695511 200.780655,96.478523 195.304291,82.132172 
	C194.168213,83.888672 193.447876,84.772285 192.972198,85.772278 
	C185.596039,101.278458 178.496216,116.924477 170.698364,132.214767 
	C169.092316,135.363968 165.181381,137.680954 161.864899,139.552277 
	C150.455215,145.990158 137.952606,148.485031 124.010025,149.197540 
	C126.210533,144.745529 127.974380,140.925568 129.952667,137.220108 
	C136.803360,124.388168 143.750565,111.607780 150.619904,98.785721 
	C158.741241,83.626740 166.877426,68.475082 174.869690,53.248089 
	C176.520935,50.102062 178.543015,48.808254 182.219223,48.907486 
	C192.205673,49.177055 202.206451,49.120617 212.196671,48.933273 
	C215.599670,48.869457 217.308487,49.791862 218.782349,53.177982 
	C225.572601,68.778030 232.820511,84.178627 239.866486,99.667946 
	C240.682526,101.461868 241.263489,103.362724 241.985367,105.584625 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M599.989136,162.670227 
	C598.552429,172.917038 591.393860,177.299484 582.363464,180.337723 
	C589.254944,188.298706 595.583679,195.609558 601.984619,203.003876 
	C591.129822,206.493988 583.633728,203.627640 576.226501,193.563736 
	C573.785889,190.247757 571.148010,187.059265 568.382141,184.010437 
	C567.482788,183.019104 565.917053,182.169006 564.609375,182.096375 
	C560.320007,181.858139 556.009216,182.006866 551.199707,182.006866 
	C551.199707,189.331390 551.199707,196.251450 551.199707,203.582870 
	C546.000000,203.582870 541.302185,203.582870 536.299316,203.582870 
	C536.299316,182.543594 536.299316,161.633820 536.299316,140.000000 
	C538.677551,140.000000 540.943054,139.949524 543.205566,140.008453 
	C555.317627,140.323868 567.443298,140.409149 579.535828,141.084137 
	C588.636597,141.592117 600.988342,148.792160 599.989136,162.670227 
M551.048035,152.817978 
	C551.048035,158.736603 551.048035,164.655212 551.048035,170.981140 
	C559.140137,170.981140 566.599182,171.258469 574.026794,170.894135 
	C580.060303,170.598175 584.590576,166.721756 584.813843,161.820343 
	C585.009644,157.523117 582.877869,154.130905 578.505920,152.855637 
	C569.517883,150.233871 560.397766,150.275772 551.048035,152.817978 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1370.208252,186.707123 
	C1365.152344,181.468430 1361.409424,180.713272 1350.102173,182.626709 
	C1350.102173,189.437195 1350.102173,196.350296 1350.102173,203.626801 
	C1345.366699,203.626801 1341.124512,203.626801 1336.437988,203.626801 
	C1336.437988,182.731049 1336.437988,161.723312 1336.437988,140.000000 
	C1342.339722,140.000000 1348.267944,139.912231 1354.192627,140.019409 
	C1364.574097,140.207184 1375.057861,138.956223 1385.190674,142.567810 
	C1394.601440,145.921997 1398.480713,151.024689 1399.121704,159.044327 
	C1399.976318,169.735519 1394.123169,175.842239 1385.567261,178.750183 
	C1384.520508,179.105957 1383.489136,179.506760 1381.917480,180.081711 
	C1388.548340,187.903030 1394.862305,195.350586 1402.170166,203.970474 
	C1395.738647,203.970474 1390.838135,204.066818 1385.948242,203.880951 
	C1385.076172,203.847809 1384.118530,202.758530 1383.421509,201.963120 
	C1379.068604,196.996140 1374.778687,191.974075 1370.208252,186.707123 
M1368.375244,171.000000 
	C1370.206543,170.996445 1372.051758,171.136337 1373.866577,170.961594 
	C1378.691895,170.496994 1383.728271,166.082321 1383.997925,162.228745 
	C1384.389160,156.637497 1381.923340,153.014938 1375.504150,152.187103 
	C1367.230957,151.120178 1358.825195,151.081985 1350.290283,150.589996 
	C1350.290283,157.954834 1350.290283,164.194916 1350.290283,171.000000 
	C1356.181641,171.000000 1361.795166,171.000000 1368.375244,171.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M492.999084,71.637665 
	C491.683777,79.382950 489.760010,81.306946 476.020111,88.823616 
	C482.385590,96.229729 488.681244,103.554619 495.845795,111.890450 
	C489.493591,111.890450 483.905090,113.057198 479.210968,111.510132 
	C475.649536,110.336365 473.135376,105.787376 470.323578,102.592125 
	C467.496185,99.379181 465.096741,95.761345 462.071564,92.763039 
	C460.613739,91.318130 458.209015,90.349380 456.127167,90.118126 
	C452.371490,89.700935 448.536255,90.000137 444.237976,90.000137 
	C444.237976,97.294456 444.237976,104.208321 444.237976,111.558647 
	C439.388611,111.558647 435.027222,111.558647 430.329254,111.558647 
	C430.329254,90.625641 430.329254,69.734550 430.329254,48.435883 
	C431.516388,48.296429 432.778229,47.995384 434.032410,48.024464 
	C446.795166,48.320404 459.608032,48.080429 472.302826,49.185722 
	C483.045990,50.121090 494.682159,56.337032 492.999084,71.637665 
M463.344482,78.999992 
	C465.006042,78.986511 466.688110,79.135338 468.325317,78.928726 
	C473.001404,78.338608 477.712616,74.104713 477.995911,70.401787 
	C478.378357,65.402946 476.313354,61.500565 470.840607,60.638454 
	C462.154663,59.270176 453.273071,59.143932 444.292480,58.478790 
	C444.292480,65.975235 444.292480,72.207802 444.292480,79.000000 
	C450.510956,79.000000 456.446442,79.000000 463.344482,78.999992 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M970.000000,101.982170 
	C970.000000,105.432884 970.000000,108.393677 970.000000,111.673843 
	C965.268066,111.673843 961.021790,111.673843 956.384583,111.673843 
	C956.384583,90.718803 956.384583,69.806610 956.384583,48.440857 
	C957.457031,48.309139 958.715576,47.996513 959.966309,48.025341 
	C972.746521,48.319904 985.585876,48.019154 998.286865,49.197712 
	C1009.718811,50.258507 1020.440979,56.590462 1019.049622,71.239014 
	C1018.422424,77.842560 1014.768738,82.102005 1009.384827,85.206558 
	C1007.258179,86.432838 1004.889343,87.239159 1001.926819,88.547630 
	C1005.221008,92.492630 1008.298462,96.248314 1011.453918,99.937225 
	C1014.616882,103.634880 1017.857666,107.265923 1021.953186,111.940689 
	C1015.494385,111.940689 1010.022583,113.098312 1005.447937,111.570175 
	C1001.863159,110.372696 999.276062,105.950661 996.453247,102.776642 
	C993.606262,99.575424 991.262146,95.892845 988.209900,92.922340 
	C986.632568,91.387230 984.064758,90.404900 981.824524,90.121994 
	C978.225403,89.667465 974.526917,90.000061 970.000000,90.000061 
	C970.000000,93.999840 970.000000,97.746040 970.000000,101.982170 
M996.671387,60.319763 
	C987.980774,59.723228 979.290100,59.126690 970.421021,58.517906 
	C970.421021,65.976700 970.421021,72.341080 970.421021,78.988159 
	C978.490662,78.988159 986.148499,79.168854 993.792664,78.923759 
	C998.775330,78.764000 1003.484863,74.854317 1003.969116,71.018456 
	C1004.628662,65.794403 1002.301758,62.415699 996.671387,60.319763 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M631.783081,107.952591 
	C624.663757,109.344238 617.972412,111.374786 611.175110,111.832382 
	C601.415833,112.489395 591.579346,112.000000 581.390137,112.000000 
	C581.390137,90.616707 581.390137,69.598442 581.390137,48.815414 
	C591.964783,48.815414 602.371765,48.462112 612.738770,48.953209 
	C618.584351,49.230125 624.601135,50.195969 630.131775,52.062950 
	C651.995728,59.443520 655.978271,84.856094 643.007935,100.212616 
	C640.241577,103.487915 635.821716,105.366669 631.783081,107.952591 
M632.561340,70.152336 
	C630.898560,68.378685 629.465942,66.288223 627.534241,64.884720 
	C617.701599,57.740818 606.451965,59.065056 595.320251,59.104160 
	C595.320251,73.382179 595.320251,86.939224 595.320251,101.842751 
	C604.221191,100.887360 612.854004,100.801689 621.058777,98.884476 
	C633.598022,95.954460 638.619812,82.461060 632.561340,70.152336 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1154.082764,53.976959 
	C1169.836426,45.422249 1185.423340,44.921043 1201.272217,52.645481 
	C1211.413574,57.588181 1217.601562,65.687790 1218.853027,76.711800 
	C1220.285645,89.331703 1214.886108,99.251839 1204.266846,106.174805 
	C1187.797852,116.911423 1160.210083,115.621017 1145.936157,99.570244 
	C1135.242554,87.545532 1137.102295,66.229088 1150.250610,56.896599 
	C1151.463745,56.035553 1152.576050,55.032433 1154.082764,53.976959 
M1189.450439,100.191345 
	C1207.001953,93.046165 1207.906372,74.235802 1196.884155,64.608322 
	C1189.709839,58.341763 1180.775391,56.421150 1171.386597,59.110191 
	C1163.170898,61.463207 1156.833984,66.237579 1154.619141,74.925606 
	C1152.623901,82.752274 1154.739746,89.540916 1160.653442,95.187431 
	C1168.900757,103.062073 1178.531616,103.338577 1189.450439,100.191345 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M440.573212,204.735229 
	C430.723267,202.955780 422.019104,199.984863 415.167908,192.731018 
	C402.482483,179.300034 404.939545,158.049515 420.033417,147.278732 
	C436.079742,135.828308 460.948364,136.322266 475.925385,148.649902 
	C491.578888,161.534332 490.575043,183.840302 475.144501,195.962051 
	C465.035858,203.903046 453.322418,205.527740 440.573212,204.735229 
M427.478546,158.019318 
	C422.358429,163.558395 420.801178,169.994446 422.676239,177.270340 
	C427.375092,195.503357 452.859741,198.781799 465.031372,187.583313 
	C474.656464,178.727768 474.652283,164.016434 463.854065,155.531540 
	C453.167297,147.134186 438.258057,148.162582 427.478546,158.019318 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1215.242554,192.742096 
	C1201.376953,179.442154 1203.861328,155.313568 1223.294922,144.964462 
	C1239.781128,136.184952 1256.556274,136.672546 1272.314575,146.373169 
	C1281.597778,152.087738 1287.301880,161.440659 1286.766968,172.604492 
	C1286.209717,184.230759 1280.525024,193.538345 1269.706909,199.115082 
	C1253.626831,207.404343 1237.681396,207.022003 1221.936035,198.368835 
	C1219.531006,197.047058 1217.629395,194.809341 1215.242554,192.742096 
M1222.968994,179.310547 
	C1227.851929,193.169678 1247.289551,197.586334 1259.880249,190.808472 
	C1271.965698,184.302704 1275.500244,169.234299 1266.906860,158.972702 
	C1260.983398,151.899353 1252.820557,149.294876 1243.789917,150.058487 
	C1230.651001,151.169495 1217.423706,163.132263 1222.968994,179.310547 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M889.123840,50.957390 
	C904.035156,45.277546 918.188843,46.056450 931.928833,53.458244 
	C934.257324,54.712593 937.017029,55.694904 934.905762,59.314503 
	C931.501770,65.150467 929.959106,65.932899 925.976318,63.403637 
	C914.859497,56.343964 903.578491,56.006245 892.476868,63.122551 
	C885.716980,67.455734 882.374268,74.139641 883.131592,82.118378 
	C883.946960,90.708107 888.956787,96.732712 896.891968,100.030579 
	C904.923218,103.368378 913.069763,102.724426 921.112244,99.874687 
	C926.286377,98.041298 926.689087,96.849457 925.570068,87.247887 
	C919.728577,87.247887 913.807556,87.247887 907.445190,87.247887 
	C907.445190,83.386429 907.445190,80.027107 907.445190,76.325714 
	C918.063049,76.325714 928.642700,76.325714 939.963806,76.325714 
	C939.963806,84.944069 940.087036,93.540306 939.821350,102.124504 
	C939.784607,103.312073 938.035583,104.836563 936.722778,105.535378 
	C920.890808,113.963120 904.469849,116.057198 887.561890,109.153358 
	C875.628662,104.280807 868.792053,95.146080 868.102905,82.263939 
	C867.367432,68.515938 873.531250,58.421585 886.094238,52.437218 
	C886.992920,52.009121 887.869202,51.533840 889.123840,50.957390 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M728.710999,203.971085 
	C724.430542,203.991776 720.618225,203.991776 716.406616,203.991776 
	C716.406616,182.660217 716.406616,161.648575 716.406616,140.317230 
	C721.082092,140.317230 725.657593,140.317230 730.742798,140.317230 
	C730.742798,148.274841 730.742798,156.186813 730.742798,164.547699 
	C743.257324,164.547699 755.276733,164.547699 767.806763,164.547699 
	C767.806763,156.656052 767.806763,148.743988 767.806763,140.418427 
	C772.993103,140.418427 777.687317,140.418427 782.693726,140.418427 
	C782.693726,161.425232 782.693726,182.318588 782.693726,203.606689 
	C777.952209,203.606689 773.261658,203.606689 768.059326,203.606689 
	C768.059326,194.517380 768.059326,185.604324 768.059326,176.351898 
	C755.660461,176.351898 743.757935,176.351898 731.000000,176.351898 
	C731.000000,183.841278 731.106262,191.420349 730.917297,198.992050 
	C730.875610,200.658249 729.786133,202.298309 728.710999,203.971085 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1030.000000,156.002747 
	C1030.000000,150.545578 1030.000000,145.587585 1030.000000,140.319672 
	C1049.411499,140.319672 1068.317505,140.319672 1087.612549,140.319672 
	C1087.612549,143.603241 1087.612549,146.847122 1087.612549,150.789017 
	C1073.400269,150.789017 1059.030884,150.789017 1044.336792,150.789017 
	C1044.336792,155.708862 1044.336792,159.954117 1044.336792,164.890564 
	C1057.366699,164.890564 1070.408203,164.890564 1083.715820,164.890564 
	C1083.715820,168.816055 1083.715820,172.061844 1083.715820,175.818893 
	C1070.651001,175.818893 1057.735352,175.818893 1043.618286,175.818893 
	C1044.209595,180.743240 1043.839722,185.461639 1045.587402,189.186462 
	C1046.578491,191.298828 1050.999390,192.698990 1053.947266,192.832153 
	C1063.756348,193.275284 1073.597778,192.999939 1083.426880,193.000000 
	C1085.057373,193.000015 1086.687988,193.000000 1088.657349,193.000000 
	C1088.657349,196.696228 1088.657349,199.944427 1088.657349,203.661789 
	C1087.277344,203.771820 1085.847656,203.981613 1084.417603,203.984772 
	C1072.422852,204.011276 1060.427856,204.042236 1048.433350,203.987915 
	C1036.557251,203.934128 1030.011353,197.322815 1030.001709,185.489227 
	C1029.993774,175.826797 1030.000000,166.164383 1030.000000,156.002747 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M629.868591,186.933838 
	C630.459778,192.688477 634.705505,192.831436 638.409729,192.919617 
	C648.235962,193.153564 658.071350,192.999664 667.903137,193.000000 
	C669.706055,193.000076 671.508911,193.000000 673.649292,193.000000 
	C673.649292,196.680878 673.649292,199.929855 673.649292,203.772018 
	C672.121826,203.845871 670.529114,203.987061 668.936096,203.989655 
	C657.271301,204.008713 645.606384,204.019730 633.941650,203.994583 
	C621.590576,203.967957 615.024414,197.361298 615.004700,184.945435 
	C614.983765,171.780884 614.992126,158.616257 615.010071,145.451706 
	C615.012329,143.827698 615.152283,142.203873 615.241638,140.299301 
	C634.457214,140.299301 653.367004,140.299301 672.639038,140.299301 
	C672.639038,143.660721 672.639038,146.909790 672.639038,150.849762 
	C658.348938,150.849762 643.972656,150.849762 629.305481,150.849762 
	C629.305481,155.775833 629.305481,160.022171 629.305481,164.785034 
	C642.316711,164.785034 655.235046,164.785034 668.573120,164.785034 
	C668.573120,168.651901 668.573120,172.013016 668.573120,175.895569 
	C655.570496,175.895569 642.651001,175.895569 628.789795,175.895569 
	C629.159058,179.814987 629.474854,183.166504 629.868591,186.933838 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M560.416260,74.556854 
	C560.745789,77.676720 560.745789,80.475845 560.745789,83.783524 
	C547.695251,83.783524 534.774597,83.783524 520.631836,83.783524 
	C521.212646,88.709656 520.992371,93.298622 522.534851,97.188507 
	C523.301208,99.121002 527.265015,100.726189 529.849243,100.831131 
	C539.998962,101.243301 550.175354,100.999947 560.341797,101.000008 
	C561.982910,101.000015 563.623962,101.000000 565.627441,101.000000 
	C565.627441,104.638542 565.627441,107.887993 565.627441,111.758614 
	C564.171814,111.834427 562.583374,111.986221 560.994690,111.988892 
	C548.994934,112.009064 536.995178,112.015533 524.995422,111.995445 
	C513.887512,111.976845 507.023468,105.136620 507.005280,94.057274 
	C506.983124,80.557587 507.000000,67.057838 507.000000,53.558113 
	C507.000000,51.928547 507.000000,50.298981 507.000000,48.000000 
	C516.898438,48.000000 526.337463,48.000000 535.776428,48.000000 
	C545.254944,48.000000 554.733459,48.000000 564.603821,48.000000 
	C564.603821,51.714062 564.603821,55.075336 564.603821,58.958176 
	C550.171509,58.958176 535.918091,58.958176 521.337769,58.958176 
	C521.337769,64.042961 521.337769,68.622070 521.337769,74.236122 
	C534.255493,74.236122 547.171143,74.236122 560.416260,74.556854 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1352.848389,49.338955 
	C1352.947998,52.603397 1352.947998,55.416203 1352.947998,58.900780 
	C1338.628784,58.900780 1324.588257,58.900780 1310.282959,58.900780 
	C1310.282959,64.155418 1310.282959,68.732521 1310.282959,73.820030 
	C1323.355713,73.820030 1336.269287,73.820030 1349.587891,73.820030 
	C1349.587891,77.348122 1349.587891,80.375336 1349.587891,83.917145 
	C1336.547241,83.917145 1323.631104,83.917145 1310.030640,83.917145 
	C1310.030640,87.662605 1309.774292,90.953880 1310.090454,94.189217 
	C1310.570679,99.103073 1312.923340,100.973679 1318.106934,100.990013 
	C1328.600830,101.023087 1339.094971,100.989693 1349.588989,101.010818 
	C1351.213745,101.014084 1352.838135,101.160995 1354.715332,101.253990 
	C1354.715332,104.814575 1354.715332,108.059608 1354.715332,112.000000 
	C1352.627441,112.000000 1350.693115,112.000008 1348.758789,112.000000 
	C1336.599121,111.999962 1324.439209,112.039780 1312.279663,111.987114 
	C1302.758911,111.945869 1296.008057,105.164017 1296.002441,95.741470 
	C1295.993164,80.100922 1296.000000,64.460365 1296.000000,48.599922 
	C1297.203369,48.366840 1298.134033,48.031116 1299.065186,48.029305 
	C1315.888916,47.996525 1332.712524,47.987877 1349.536133,48.040844 
	C1350.607910,48.044220 1351.677979,48.592529 1352.848389,49.338955 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M802.312744,187.314545 
	C809.900146,172.550323 817.390198,158.153290 824.610901,143.622421 
	C826.012634,140.801636 827.723389,140.294510 830.637939,139.842316 
	C838.943604,138.553696 842.962708,142.412552 846.413269,149.520889 
	C854.866150,166.934174 864.115845,183.960632 873.029541,201.150696 
	C873.383423,201.833145 873.579651,202.597336 874.095459,203.986389 
	C868.764038,203.986389 863.979675,204.187790 859.235107,203.842453 
	C858.072876,203.757843 856.414795,202.067001 856.047974,200.805435 
	C853.117920,190.729034 846.692139,187.203476 836.415649,188.854156 
	C831.219543,189.688797 825.768860,189.195694 820.452087,188.928284 
	C817.145813,188.761993 815.008850,189.729950 814.011658,192.938171 
	C813.866089,193.406433 813.564392,193.825409 813.341614,194.270538 
	C807.842102,205.261185 807.390808,205.483047 793.790161,203.413986 
	C796.638062,198.020157 799.379700,192.827606 802.312744,187.314545 
M829.631042,178.000000 
	C834.318481,178.000000 839.005920,178.000000 844.503296,178.000000 
	C840.458923,169.970490 836.947998,163.000137 832.975220,155.112915 
	C828.969360,163.136444 825.474548,170.136261 821.548462,178.000000 
	C824.497009,178.000000 826.589844,178.000000 829.631042,178.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M838.678833,60.295151 
	C847.189270,76.598648 855.509644,92.582275 863.809387,108.576561 
	C864.244690,109.415382 864.506104,110.344391 865.133972,111.970764 
	C860.010132,111.970764 855.407654,112.231606 850.868774,111.808968 
	C849.538208,111.685074 847.668213,109.822304 847.271667,108.411774 
	C844.540771,98.698502 838.447937,95.499092 828.573914,96.823318 
	C821.725464,97.741783 814.654358,97.000000 807.806152,97.000000 
	C806.893799,98.953476 806.162415,100.594658 805.369446,102.205551 
	C799.947510,113.220085 798.963013,113.695717 785.783508,111.340057 
	C786.417053,109.767693 786.890869,108.254906 787.606567,106.866806 
	C796.774109,89.086128 805.932312,71.300232 815.228638,53.586876 
	C816.312195,51.522339 817.974487,48.638405 819.773926,48.301769 
	C823.770386,47.554123 828.030396,48.086010 832.166260,48.353630 
	C832.779175,48.393288 833.407227,49.778381 833.846680,50.634251 
	C835.434509,53.727142 836.944824,56.859852 838.678833,60.295151 
M814.557190,85.658379 
	C821.440125,85.658379 828.323059,85.658379 835.776428,85.658379 
	C831.993774,78.062309 828.354919,70.755035 824.381104,62.775021 
	C820.810547,70.577591 817.529236,77.748024 814.557190,85.658379 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M944.971863,142.302322 
	C944.994629,153.602280 944.570862,164.454285 945.110840,175.258102 
	C945.747437,187.995590 936.998596,199.882431 924.430542,203.258301 
	C913.661011,206.151047 903.071716,205.815475 893.191528,200.504364 
	C884.111572,195.623398 879.214722,187.620514 879.045898,177.206329 
	C878.849243,165.067719 879.000000,152.923477 879.000000,140.392609 
	C883.596497,140.392609 887.841125,140.392609 892.754517,140.392609 
	C892.830505,141.957993 892.984497,143.703339 892.988647,145.449051 
	C893.012390,155.446548 892.889954,165.445709 893.031799,175.441254 
	C893.173828,185.451385 899.678833,192.871750 908.975891,193.983292 
	C919.463684,195.237213 928.141602,189.975845 930.578491,180.757980 
	C930.994080,179.185913 930.978577,177.472290 930.983032,175.823578 
	C931.014893,164.171097 931.000000,152.518463 931.000000,140.094803 
	C935.864441,140.818298 940.642334,138.008408 944.971863,142.302322 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249161,152.051453 
	C222.240356,151.286972 222.392853,150.432693 222.854919,150.204056 
	C233.000412,145.183914 243.180252,140.233139 253.699829,135.126999 
	C256.037781,138.343430 258.278778,141.580994 259.977203,145.081772 
	C266.732849,159.006454 273.319214,173.013260 280.448944,188.000000 
	C277.968170,188.000000 276.264221,188.000000 274.560303,188.000000 
	C264.925201,187.999985 255.290085,188.013351 245.655029,187.995209 
	C239.398315,187.983429 236.375305,185.759766 234.069580,180.047592 
	C230.315979,170.748550 226.401337,161.514511 222.249161,152.051453 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1064.000000,79.086327 
	C1064.268799,65.735992 1071.238525,57.017338 1082.335938,52.046036 
	C1099.430298,44.388268 1116.157715,45.794689 1132.431030,56.487331 
	C1130.148071,59.610691 1128.155029,62.337505 1126.296265,64.880493 
	C1120.094360,62.582920 1114.411255,59.851322 1108.418701,58.381401 
	C1093.659790,54.761139 1078.691040,67.111099 1079.000000,82.195305 
	C1079.186768,91.310837 1090.027832,101.459312 1100.046143,102.013222 
	C1108.504883,102.480919 1116.543945,101.511917 1123.602173,96.224174 
	C1125.461914,94.831039 1127.360718,94.304573 1128.834961,97.181549 
	C1129.866699,99.194939 1131.552979,100.872887 1133.237183,103.081398 
	C1128.336060,108.093506 1122.096802,110.486046 1115.790894,111.769089 
	C1100.840332,114.811035 1086.515259,113.371086 1074.229370,103.071762 
	C1066.925049,96.948456 1063.528320,89.077583 1064.000000,79.086327 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M392.450745,58.270790 
	C379.078400,57.124905 367.678253,63.601055 364.049774,74.029892 
	C360.213623,85.055710 367.421448,97.871368 379.491791,100.934570 
	C389.222473,103.404022 398.665070,102.501190 407.084167,96.829544 
	C410.531128,94.507446 412.191315,94.979614 413.983063,98.373306 
	C414.960693,100.224998 416.467316,101.797394 417.734253,103.494270 
	C401.622406,115.381172 377.627960,116.735481 361.651154,105.698769 
	C341.216431,91.582588 344.639557,64.330780 363.152893,53.994610 
	C379.368988,44.941006 395.809692,44.857731 412.230927,53.646023 
	C414.445557,54.831242 417.383728,56.203899 414.817535,59.059063 
	C412.733093,61.378204 412.270233,67.013405 406.458282,63.873070 
	C402.190704,61.567204 397.412811,60.205788 392.450745,58.270790 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M280.710632,74.697952 
	C272.055786,79.019241 263.845886,83.443794 255.313599,87.120132 
	C249.534073,89.610390 245.342590,87.756500 242.720764,82.183556 
	C240.384705,77.218048 238.610794,71.988075 236.542053,66.756660 
	C252.715607,64.435822 264.849701,54.667839 278.360626,48.011108 
	C287.827057,43.347073 296.914917,37.914627 306.989746,32.375687 
	C306.989746,41.554321 307.106903,49.800171 306.870209,58.035847 
	C306.832397,59.351471 305.498627,61.132389 304.270813,61.829273 
	C296.597900,66.184357 288.784912,70.292595 280.710632,74.697952 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M692.308899,58.973465 
	C691.004456,55.599140 690.167236,52.244061 689.188293,48.321091 
	C711.069397,48.321091 731.642883,48.321091 752.609009,48.321091 
	C752.609009,51.598629 752.609009,54.844105 752.609009,58.784477 
	C745.076477,58.784477 737.366150,58.784477 729.165771,58.784477 
	C729.165771,76.708557 729.165771,93.953995 729.165771,111.597061 
	C723.970093,111.597061 719.273987,111.597061 714.065002,111.597061 
	C714.065002,94.188889 714.065002,76.943649 714.065002,58.992714 
	C706.643250,58.992714 699.709717,58.992714 692.308899,58.973465 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M995.000000,176.000000 
	C995.000000,185.454742 995.000000,194.409470 995.000000,203.677551 
	C990.261414,203.677551 986.017395,203.677551 981.260254,203.677551 
	C981.260254,186.394165 981.260254,169.153015 981.260254,151.226807 
	C973.085266,151.226807 965.375488,151.226807 957.342407,151.226807 
	C957.342407,147.303864 957.342407,144.056213 957.342407,140.402008 
	C977.683533,140.402008 998.039490,140.402008 1018.698853,140.402008 
	C1018.698853,143.780853 1018.698853,147.026596 1018.698853,150.783218 
	C1011.020630,150.783218 1003.436646,150.783218 995.000000,150.783218 
	C995.000000,159.340744 995.000000,167.420364 995.000000,176.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1279.882080,112.000000 
	C1263.806519,112.000000 1248.214478,112.000000 1232.314087,112.000000 
	C1232.314087,90.601753 1232.314087,69.719872 1232.314087,48.418205 
	C1236.991211,48.418205 1241.679932,48.418205 1246.857666,48.418205 
	C1246.857666,65.742058 1246.857666,82.967651 1246.857666,100.851501 
	C1260.001953,100.851501 1272.693726,100.851501 1285.685059,100.851501 
	C1285.685059,104.749069 1285.685059,107.992340 1285.685059,112.000000 
	C1283.876465,112.000000 1282.121094,112.000000 1279.882080,112.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1173.241455,192.995346 
	C1185.184937,192.998886 1196.651978,192.998886 1208.558472,192.998886 
	C1208.558472,196.617966 1208.558472,199.974915 1208.558472,203.671112 
	C1190.966309,203.671112 1173.410522,203.671112 1155.426270,203.671112 
	C1155.426270,182.709457 1155.426270,161.708511 1155.426270,140.352341 
	C1160.007935,140.352341 1164.581177,140.352341 1169.760620,140.352341 
	C1169.837891,142.023956 1169.986938,143.776123 1169.989258,145.528488 
	C1170.008423,159.833435 1169.975098,174.138519 1170.029419,188.443298 
	C1170.036743,190.386429 1169.101685,193.142303 1173.241455,192.995346 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M409.421997,193.564972 
	C409.750763,197.016266 409.750763,200.142303 409.750763,203.633148 
	C391.924774,203.633148 374.249634,203.633148 356.286438,203.633148 
	C356.286438,182.542206 356.286438,161.657684 356.286438,140.385986 
	C360.604675,140.385986 364.844238,140.385986 369.750427,140.385986 
	C369.750427,157.578369 369.750427,174.923950 369.750427,193.239700 
	C383.296356,193.239700 396.194763,193.239700 409.421997,193.564972 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1042.979004,112.000000 
	C1040.207764,112.000000 1037.915527,112.000000 1035.320801,112.000000 
	C1035.320801,90.614349 1035.320801,69.730476 1035.320801,48.422497 
	C1039.981934,48.422497 1044.674194,48.422497 1049.686279,48.422497 
	C1049.686279,69.400108 1049.686279,90.283882 1049.686279,112.000000 
	C1047.615112,112.000000 1045.536621,112.000000 1042.979004,112.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1317.000000,163.000031 
	C1317.000000,176.770203 1317.000000,190.040375 1317.000000,203.652771 
	C1311.982300,203.652771 1307.409790,203.652771 1302.416016,203.652771 
	C1302.416016,182.693802 1302.416016,161.695877 1302.416016,140.347626 
	C1307.016846,140.347626 1311.589355,140.347626 1317.000000,140.347626 
	C1317.000000,147.688660 1317.000000,155.094345 1317.000000,163.000031 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M503.000000,192.977386 
	C503.000000,175.218552 503.000000,157.956726 503.000000,140.349365 
	C507.679901,140.349365 511.919983,140.349365 516.582886,140.349365 
	C516.582886,161.303009 516.582886,182.301453 516.582886,203.651306 
	C512.318726,203.651306 508.078613,203.651306 503.000000,203.651306 
	C503.000000,200.296249 503.000000,196.885330 503.000000,192.977386 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,103.934433 
	C678.000000,106.718338 678.000000,109.013191 678.000000,111.651642 
	C673.317383,111.651642 669.077026,111.651642 664.415527,111.651642 
	C664.415527,90.692169 664.415527,69.692207 664.415527,48.344765 
	C668.689636,48.344765 672.930237,48.344765 678.000000,48.344765 
	C678.000000,66.686913 678.000000,85.066139 678.000000,103.934433 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1106.151489,166.000061 
	C1117.890015,166.000000 1129.145020,166.000000 1140.695801,166.000000 
	C1140.695801,170.102585 1140.695801,173.675919 1140.695801,177.626251 
	C1127.606689,177.626251 1114.603394,177.626251 1101.298706,177.626251 
	C1101.298706,173.886230 1101.298706,170.313202 1101.298706,166.334747 
	C1102.802124,166.219604 1104.234985,166.109863 1106.151489,166.000061 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M551.144592,152.360779 
	C560.397766,150.275772 569.517883,150.233871 578.505920,152.855637 
	C582.877869,154.130905 585.009644,157.523117 584.813843,161.820343 
	C584.590576,166.721756 580.060303,170.598175 574.026794,170.894135 
	C566.599182,171.258469 559.140137,170.981140 551.048035,170.981140 
	C551.048035,164.655212 551.048035,158.736603 551.144592,152.360779 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1367.891968,171.000000 
	C1361.795166,171.000000 1356.181641,171.000000 1350.290283,171.000000 
	C1350.290283,164.194916 1350.290283,157.954834 1350.290283,150.589996 
	C1358.825195,151.081985 1367.230957,151.120178 1375.504150,152.187103 
	C1381.923340,153.014938 1384.389160,156.637497 1383.997925,162.228745 
	C1383.728271,166.082321 1378.691895,170.496994 1373.866577,170.961594 
	C1372.051758,171.136337 1370.206543,170.996445 1367.891968,171.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M462.863220,79.000000 
	C456.446442,79.000000 450.510956,79.000000 444.292480,79.000000 
	C444.292480,72.207802 444.292480,65.975235 444.292480,58.478790 
	C453.273071,59.143932 462.154663,59.270176 470.840607,60.638454 
	C476.313354,61.500565 478.378357,65.402946 477.995911,70.401787 
	C477.712616,74.104713 473.001404,78.338608 468.325317,78.928726 
	C466.688110,79.135338 465.006042,78.986511 462.863220,79.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M997.015625,60.540512 
	C1002.301758,62.415699 1004.628662,65.794403 1003.969116,71.018456 
	C1003.484863,74.854317 998.775330,78.764000 993.792664,78.923759 
	C986.148499,79.168854 978.490662,78.988159 970.421021,78.988159 
	C970.421021,72.341080 970.421021,65.976700 970.421021,58.517906 
	C979.290100,59.126690 987.980774,59.723228 997.015625,60.540512 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M632.749756,70.490662 
	C638.619812,82.461060 633.598022,95.954460 621.058777,98.884476 
	C612.854004,100.801689 604.221191,100.887360 595.320251,101.842751 
	C595.320251,86.939224 595.320251,73.382179 595.320251,59.104160 
	C606.451965,59.065056 617.701599,57.740818 627.534241,64.884720 
	C629.465942,66.288223 630.898560,68.378685 632.749756,70.490662 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1189.115967,100.407364 
	C1178.531616,103.338577 1168.900757,103.062073 1160.653442,95.187431 
	C1154.739746,89.540916 1152.623901,82.752274 1154.619141,74.925606 
	C1156.833984,66.237579 1163.170898,61.463207 1171.386597,59.110191 
	C1180.775391,56.421150 1189.709839,58.341763 1196.884155,64.608322 
	C1207.906372,74.235802 1207.001953,93.046165 1189.115967,100.407364 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M427.738098,157.760895 
	C438.258057,148.162582 453.167297,147.134186 463.854065,155.531540 
	C474.652283,164.016434 474.656464,178.727768 465.031372,187.583313 
	C452.859741,198.781799 427.375092,195.503357 422.676239,177.270340 
	C420.801178,169.994446 422.358429,163.558395 427.738098,157.760895 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1222.888184,178.923447 
	C1217.423706,163.132263 1230.651001,151.169495 1243.789917,150.058487 
	C1252.820557,149.294876 1260.983398,151.899353 1266.906860,158.972702 
	C1275.500244,169.234299 1271.965698,184.302704 1259.880249,190.808472 
	C1247.289551,197.586334 1227.851929,193.169678 1222.888184,178.923447 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M829.156860,178.000000 
	C826.589844,178.000000 824.497009,178.000000 821.548462,178.000000 
	C825.474548,170.136261 828.969360,163.136444 832.975220,155.112915 
	C836.947998,163.000137 840.458923,169.970490 844.503296,178.000000 
	C839.005920,178.000000 834.318481,178.000000 829.156860,178.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M814.402588,85.288422 
	C817.529236,77.748024 820.810547,70.577591 824.381104,62.775021 
	C828.354919,70.755035 831.993774,78.062309 835.776428,85.658379 
	C828.323059,85.658379 821.440125,85.658379 814.402588,85.288422 
z"
      />
    </svg>
  );
};

CaHauteLoireSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaHauteLoireSvg;
