import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// Components
import LoginForm from './LoginForm';
import Footer from '../../../components/Footer/Footer';
import CatalizrSquareLogo from '../../../components/AssetPictos/components/iconCatalizSquare/index';
import Banner from '../../../components/Banner';

// Utils
import { getSubdomain } from '../../../shared/utils/helper';

import '../styles.scss';

const Login = props => {
  useEffect(() => {
    localStorage.removeItem('state');
    const init = async () => {
      const {
        initView,
        setUserEmailLogin,
        detectAdvisor,
        checkAccountIsActive,
        setJourney,
        initJourney,
        logout,
        location,
        displayRedirectedAccessDenied,
        displayRedirectedUnavailable,
        history,
        getAllBanners,
      } = props;
      await logout();
      await initView(location, history);
      getAllBanners();
      if (detectAdvisor && location.search !== '') {
        let email = location.search.replace('?email=', '');
        setUserEmailLogin(email);
        checkAccountIsActive(email);
      }
      if (initJourney) {
        setJourney('');
      }
      const values = queryString.parse(location.search);
      if (values.redirect) {
        displayRedirectedAccessDenied();
      }
      if (values.unavailable) {
        displayRedirectedUnavailable();
      }
    };
    init();
  }, []);

  const { login, banners } = props;
  const bannerItems =
    banners.length > 0
      ? banners.map((banner, index) => {
          return (
            <Banner
              key={`banners${index}`}
              bannerCriticality={banner.criticality}
              bannerMessage={banner.message}
              bannerTitle={banner.title}
            ></Banner>
          );
        })
      : '';

  return (
    <div className="login__main">
      {bannerItems}
      {/* POTENTIAL INFORMATION MESSAGE */}
      {login.generalErrorMessage ? (
        <div
          className="login__redirect-feedback alert-danger"
          data-testid="login__redirect-feedback"
        >
          {login.generalErrorMessage}
        </div>
      ) : null}
      {/* LOGO AND TITLE */}
      <div className="login__logo">
        <CatalizrSquareLogo width="100" height="100" alt="Catalizr logo" title="Catalizr logo" />
        <h1>Catalizr</h1>
      </div>

      {!getSubdomain() ? (
        <div className="login__wrong-url">
          <p>
            Nous sommes ravis que vous connaissiez et souhaitiez utiliser notre service. Nous
            espérons qu’il sera à la hauteur de vos exigences !
          </p>
          <p>
            Afin de vous connecter, nous vous remercions de suivre le lien reçu dans l’un de nos
            emails. A défaut, n’hésitez pas à contacter votre Conseiller clientèle ou notre support
            (support@catalizr.eu).
          </p>
        </div>
      ) : (
        <LoginForm {...props} />
      )}
      <Footer />
    </div>
  );
};

Login.propTypes = {
  // PROPERTIES
  bank: PropTypes.shape({
    logo: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
  journey: PropTypes.string.isRequired,
  login: PropTypes.shape({
    newUrl: PropTypes.string.isRequired,
    generalErrorMessage: PropTypes.string,
  }).isRequired,
  // provided by react-router to interact the browser history API
  history: PropTypes.instanceOf(Object).isRequired,

  // ACTIONS
  initView: PropTypes.func.isRequired,
  displayRedirectedAccessDenied: PropTypes.func.isRequired,
  displayRedirectedUnavailable: PropTypes.func.isRequired,
  setAccountIsLoading: PropTypes.func.isRequired,
  setResetPasswordIsLoading: PropTypes.func.isRequired,
  setUserEmailLogin: PropTypes.func.isRequired,

  detectAdvisor: PropTypes.func.isRequired,
  checkAccountIsActive: PropTypes.func.isRequired,
  setJourney: PropTypes.func.isRequired,
  initJourney: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  getAllBanners: PropTypes.func.isRequired,
  banners: PropTypes.array,
};

export default Login;
