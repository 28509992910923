import * as React from 'react';
import PropTypes from 'prop-types';

const CaNormandieSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101135,31.018839 
	C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
	C367.080994,31.011070 199.130249,31.011070 31.158731,31.011070 
	C31.158731,61.734028 31.158731,91.854836 31.022224,122.902924 
	C31.067513,128.944077 31.112801,134.985214 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
	C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
	C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
	C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
	C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
	C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
	C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.158731,121.975639 
	C31.158731,91.854836 31.158731,61.734028 31.158731,31.011070 
	C199.130249,31.011070 367.080994,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
	C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
	C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
	C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
	C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
	C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
	C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
	C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
	C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
	C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
	C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
	C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
	C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
	C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
	C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
	C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
	C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
	C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
	C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
	C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396376,141.702835 
	C36.009918,163.400696 49.314964,177.513397 69.920898,184.997040 
	C82.482719,189.559204 95.508400,191.447754 108.911942,190.012558 
	C116.419746,189.208664 124.077576,189.175293 131.426880,187.640808 
	C152.807175,183.176788 172.817673,174.658432 192.637253,165.720596 
	C202.778717,161.147202 212.872711,156.468552 223.012543,152.225418 
	C227.012543,162.371368 231.077652,172.094345 234.918030,181.905289 
	C236.377472,185.633621 239.288757,187.741180 242.748764,187.835632 
	C254.996826,188.169983 267.259583,187.965897 280.268219,187.965897 
	C277.518127,181.935516 275.124420,176.561356 272.627167,171.235748 
	C266.816376,158.843887 260.955688,146.475433 255.422760,133.933289 
	C258.574829,132.157990 261.474976,130.636978 264.254456,128.920517 
	C277.770264,120.573830 291.267181,112.196266 304.720306,103.749207 
	C305.683533,103.144386 306.875824,101.905647 306.891357,100.944397 
	C307.050720,91.058205 306.989105,81.168449 306.989105,70.488319 
	C296.574432,76.321167 287.078918,81.752884 277.476379,86.988243 
	C265.702301,93.407532 253.838638,99.662552 241.984283,105.615341 
	C241.267868,103.389931 240.693832,101.486244 239.876175,99.693695 
	C232.746063,84.062508 225.410416,68.521744 218.550171,52.773621 
	C217.202179,49.679165 215.730209,48.912895 212.728897,48.950974 
	C202.570984,49.079845 192.406006,49.171692 182.253265,48.908886 
	C178.595932,48.814217 176.538559,50.064487 174.882355,53.215729 
	C166.804977,68.584396 158.584976,83.878265 150.385406,99.182510 
	C143.594818,111.856941 136.733093,124.493347 129.966644,137.180603 
	C127.982674,140.900589 126.216621,144.736801 124.021698,149.186722 
	C137.916611,148.512161 150.376190,145.928085 161.847809,139.612183 
	C164.802444,137.985443 168.355682,136.228836 169.923965,133.536011 
	C174.142441,126.292755 177.480637,118.532150 181.087753,110.938675 
	C185.587692,101.465660 190.007050,91.954369 194.779572,81.780991 
	C200.461746,95.681091 205.866150,108.901680 211.044342,122.402802 
	C210.219299,122.951889 209.617554,123.214180 209.022034,123.489899 
	C191.018967,131.824921 173.367401,141.071960 154.895294,148.189499 
	C141.742218,153.257553 127.632759,156.348801 113.675346,158.670242 
	C101.696220,160.662659 89.292488,161.159515 77.145172,160.657318 
	C70.637619,160.388306 64.138557,156.941345 57.892036,154.318008 
	C53.301071,152.389954 52.641037,147.695496 53.181637,143.408020 
	C53.622162,139.914246 54.870789,136.371918 56.454041,133.196640 
	C68.386719,109.265068 88.524773,94.421478 112.345169,84.132912 
	C125.458626,78.468895 139.294983,74.988319 154.239716,74.721825 
	C153.102036,70.654640 151.923447,66.941681 151.030594,63.161232 
	C147.511917,48.262974 138.844070,43.005257 124.071472,46.382454 
	C108.481628,49.946491 93.670235,55.311726 79.904053,63.539467 
	C56.815536,77.338959 38.683601,95.219208 31.158731,121.975639 
M646.118347,190.328522 
	C647.372742,190.325256 649.209595,190.849258 649.776184,190.223358 
	C651.750732,188.042206 653.272034,185.453354 654.990112,183.036850 
	C660.353882,175.492538 665.728943,167.956223 671.099609,160.416779 
	C671.463623,160.564713 671.827698,160.712646 672.191711,160.860596 
	C672.191711,175.088684 672.191711,189.316788 672.191711,203.541290 
	C677.384033,203.541290 682.080505,203.541290 686.613159,203.541290 
	C686.613159,182.175583 686.613159,161.270645 686.613159,140.506744 
	C677.522217,138.956940 670.137756,139.171997 665.189270,148.856277 
	C661.159302,156.742920 654.866882,163.473480 649.177673,171.264450 
	C648.395569,170.179291 647.905029,169.503464 647.419495,168.824036 
	C641.285645,160.241318 635.207275,151.617798 628.961914,143.117050 
	C627.990295,141.794571 626.329712,140.297104 624.861328,140.151047 
	C620.786377,139.745667 616.644714,140.010452 612.405396,140.010452 
	C612.405396,161.625641 612.405396,182.650391 612.405396,203.653503 
	C616.399231,203.653503 619.977966,203.653503 624.073303,203.653503 
	C624.073303,189.129105 624.073303,174.940964 624.073303,160.752838 
	C624.408081,160.641296 624.742859,160.529755 625.077637,160.418213 
	C632.010010,170.161163 638.942444,179.904114 646.118347,190.328522 
M393.987366,188.484650 
	C397.408386,192.508301 400.770569,196.584381 404.283478,200.526138 
	C405.457123,201.843002 406.933807,203.643753 408.414490,203.791138 
	C412.790985,204.226761 417.238739,203.946869 421.676819,203.946869 
	C421.676819,182.304001 421.676819,161.400650 421.676819,140.438873 
	C416.649017,140.438873 411.951202,140.438873 406.918701,140.438873 
	C406.918701,154.601395 406.918701,168.329559 406.918701,182.939865 
	C396.145721,170.587418 385.846039,159.014816 375.826965,147.204132 
	C369.421814,139.653610 365.542816,138.160370 356.348022,140.830353 
	C356.348022,161.747620 356.348022,182.651413 356.348022,203.713043 
	C360.942413,203.713043 365.186768,203.713043 369.932495,203.713043 
	C369.932495,189.560852 369.932495,175.685440 369.932495,161.810013 
	C370.275330,161.659790 370.618164,161.509567 370.960999,161.359344 
	C378.467712,170.233231 385.974396,179.107132 393.987366,188.484650 
M803.000000,165.085327 
	C803.124573,163.940292 803.249146,162.795258 803.459900,160.857895 
	C805.406372,163.267303 806.711243,164.952988 808.089233,166.576630 
	C815.572327,175.393906 823.165649,184.119553 830.539734,193.026840 
	C836.561401,200.300537 842.354065,207.728119 853.719971,203.045380 
	C853.719971,182.406921 853.719971,161.496262 853.719971,140.398254 
	C849.069763,140.398254 844.708740,140.398254 839.799683,140.398254 
	C839.799683,154.627777 839.799683,168.467926 839.799683,183.043793 
	C828.233765,169.779419 817.178284,157.304398 806.380127,144.610474 
	C802.394897,139.925522 796.247437,138.357574 788.390381,140.859467 
	C788.390381,161.818634 788.390381,182.730103 788.390381,203.668213 
	C793.364380,203.668213 797.943542,203.668213 803.000000,203.668213 
	C803.000000,190.881012 803.000000,178.420258 803.000000,165.085327 
M923.240601,199.920959 
	C923.828308,199.614532 924.405762,199.286148 925.005310,199.004898 
	C936.440552,193.640442 942.723999,184.249969 942.699890,172.032196 
	C942.676025,159.918854 936.832092,150.078186 925.132935,145.186127 
	C919.909851,143.002060 914.163879,141.258774 908.565247,140.950989 
	C896.898254,140.309555 885.171326,140.756973 873.421692,140.756973 
	C873.421692,161.688919 873.421692,182.694702 873.421692,204.000000 
	C883.311096,204.000000 892.813477,204.475998 902.240906,203.840363 
	C909.047668,203.381409 915.754822,201.444305 923.240601,199.920959 
M561.451416,140.000000 
	C551.378967,140.000000 541.306458,140.000000 531.368774,140.000000 
	C531.368774,161.792755 531.368774,182.695602 531.368774,203.692596 
	C535.986511,203.692596 540.230408,203.692596 545.154297,203.692596 
	C545.154297,196.362595 545.154297,189.316177 545.154297,182.005783 
	C550.320740,182.005783 554.805847,181.925507 559.283752,182.069229 
	C560.252686,182.100327 561.434326,182.714203 562.111267,183.439346 
	C565.839111,187.432907 570.302551,191.061951 572.907166,195.715210 
	C578.397278,205.523590 586.816895,204.434174 596.828003,203.568604 
	C589.879272,195.371613 583.561157,187.918564 576.921387,180.086075 
	C579.050415,179.277588 580.764343,178.774902 582.344971,178.000198 
	C588.365540,175.049438 593.214722,171.108673 594.007080,163.813049 
	C594.955627,155.079407 592.747681,147.389465 584.035889,144.257492 
	C577.207214,141.802521 569.660461,141.344955 561.451416,140.000000 
M464.973541,96.450516 
	C466.905396,98.708527 468.759033,101.039772 470.788086,103.206757 
	C473.575806,106.183975 476.035706,110.504082 479.472931,111.583107 
	C484.094879,113.034058 489.514923,111.942886 495.842834,111.942886 
	C488.693939,103.580643 482.379059,96.194016 475.824982,88.527534 
	C478.283203,87.507011 479.970062,86.860718 481.612366,86.116196 
	C492.477203,81.190750 496.441406,70.257423 490.858948,59.769283 
	C486.990143,52.500721 479.814545,49.728142 472.344452,49.173531 
	C459.621124,48.228893 446.820068,48.320766 434.050568,48.024265 
	C432.794525,47.995102 431.530853,48.293453 430.335205,48.432297 
	C430.335205,69.731064 430.335205,90.629959 430.335205,111.550102 
	C435.046600,111.550102 439.408508,111.550102 444.258789,111.550102 
	C444.258789,104.188507 444.258789,97.271812 444.258789,90.000000 
	C447.903473,90.000000 451.079498,90.302086 454.175079,89.931686 
	C459.266174,89.322525 462.346100,91.690926 464.973541,96.450516 
M956.000000,101.474396 
	C956.000000,104.890343 956.000000,108.306297 956.000000,111.634384 
	C961.100220,111.634384 965.346680,111.634384 970.097534,111.634384 
	C970.097534,104.360168 970.097534,97.438957 970.097534,90.000504 
	C974.554260,90.000504 978.538208,89.731712 982.458313,90.115227 
	C984.384705,90.303688 986.622192,91.276535 987.966919,92.637451 
	C990.968323,95.675156 993.387634,99.278152 996.249634,102.466316 
	C999.189697,105.741341 1001.858887,110.260269 1005.584412,111.572449 
	C1010.088562,113.158836 1015.577820,111.948448 1021.847473,111.948448 
	C1014.680420,103.556679 1008.378174,96.177444 1001.860229,88.545738 
	C1004.854614,87.261902 1007.087769,86.552872 1009.069214,85.408226 
	C1014.936523,82.018791 1018.913452,77.475876 1019.076721,70.111618 
	C1019.251221,62.241581 1016.840759,55.872730 1009.307922,52.853203 
	C1004.359192,50.869572 998.959106,49.221817 993.690796,48.984138 
	C981.325256,48.426270 968.917542,48.805595 956.000000,48.805595 
	C956.000000,65.906227 956.000000,83.193291 956.000000,101.474396 
M645.516907,96.906372 
	C655.930420,79.597946 649.151062,57.754539 629.019470,51.621792 
	C623.409302,49.912743 617.464722,48.778275 611.618042,48.485062 
	C601.570740,47.981182 591.479858,48.345707 581.294434,48.345707 
	C581.294434,69.637436 581.294434,90.540001 581.294434,111.998970 
	C592.858887,111.998970 604.138672,112.070412 615.416321,111.945488 
	C618.025391,111.916595 620.686707,111.445137 623.221252,110.783691 
	C631.806335,108.543198 639.686768,105.051971 645.516907,96.906372 
M1205.006348,105.558182 
	C1207.374390,103.500473 1210.113770,101.741539 1212.049072,99.335732 
	C1223.146973,85.540100 1221.230469,67.665421 1208.629517,57.623932 
	C1191.924316,44.311932 1173.257202,43.790928 1154.457642,53.865906 
	C1136.017090,63.748470 1131.435059,91.737801 1152.829224,105.639702 
	C1169.306152,116.346466 1187.161743,116.078453 1205.006348,105.558182 
M449.022095,196.523926 
	C465.428955,207.153717 482.466431,207.993744 499.693481,199.026245 
	C519.097717,188.925400 521.793640,163.614014 506.723846,150.130508 
	C499.927032,144.049118 492.098206,141.026581 483.238586,139.506607 
	C470.330261,137.292007 449.982697,142.289612 441.353333,155.264145 
	C432.000214,169.326828 435.819244,186.559143 449.022095,196.523926 
M875.500610,100.927361 
	C878.510193,103.219002 881.242554,106.077515 884.575745,107.707436 
	C902.120300,116.286629 919.450073,114.457466 936.391846,105.755692 
	C937.840088,105.011841 939.766663,103.333061 939.813354,102.029221 
	C940.120239,93.462181 939.971558,84.878815 939.971558,76.336777 
	C928.603027,76.336777 918.030945,76.336777 907.452515,76.336777 
	C907.452515,80.043388 907.452515,83.402443 907.452515,87.255402 
	C913.818604,87.255402 919.735596,87.255402 925.975830,87.255402 
	C925.292175,92.079056 928.513062,97.420204 921.607971,99.746834 
	C913.352234,102.528549 905.137085,103.405708 896.932861,100.039207 
	C888.985229,96.777962 883.964233,90.737968 883.149231,82.184685 
	C882.395142,74.270340 885.579956,67.559494 892.392395,63.159355 
	C903.313477,56.105419 914.458557,56.358620 925.482788,63.019321 
	C930.234680,65.890335 931.134644,65.616936 934.822083,59.431419 
	C937.394958,55.115536 933.585144,54.360710 931.166016,53.094997 
	C921.563721,48.071114 911.483643,46.219910 900.611694,47.903442 
	C891.859924,49.258659 883.930664,52.269455 877.454712,58.169563 
	C866.550903,68.103912 863.762207,85.888046 875.500610,100.927361 
M1017.508789,151.000000 
	C1027.251709,151.000000 1036.994751,151.000000 1046.636475,151.000000 
	C1046.636475,146.901016 1046.636475,143.653793 1046.636475,140.285156 
	C1027.706665,140.285156 1009.141296,140.285156 990.556152,140.285156 
	C990.332947,140.996658 990.064819,141.453979 990.063843,141.911896 
	C990.028870,157.729050 989.895081,173.547653 990.067505,189.362839 
	C990.135559,195.611298 995.111023,201.563751 1001.359985,202.840683 
	C1005.688354,203.725143 1010.210144,203.894287 1014.651001,203.951630 
	C1025.919922,204.097183 1037.191895,204.000000 1048.587402,204.000000 
	C1048.587402,200.037415 1048.587402,196.678436 1048.587402,192.999710 
	C1036.373657,192.999710 1024.569092,192.937027 1012.765808,193.032028 
	C1008.342224,193.067627 1005.101685,191.217590 1004.268738,186.991623 
	C1003.590454,183.550507 1004.130859,179.869171 1004.130859,175.810410 
	C1017.745544,175.810410 1030.655884,175.810410 1043.717651,175.810410 
	C1043.717651,172.063034 1043.717651,168.818573 1043.717651,164.902039 
	C1030.417969,164.902039 1017.381714,164.902039 1004.324585,164.902039 
	C1004.324585,159.978149 1004.324585,155.733978 1004.324585,151.000000 
	C1008.594727,151.000000 1012.553589,151.000000 1017.508789,151.000000 
M532.671997,101.000000 
	C523.555786,102.135979 519.569702,98.414803 521.718262,83.839943 
	C534.648254,83.839943 547.694824,83.839943 560.633484,83.839943 
	C560.633484,80.228722 560.633484,77.315506 560.633484,73.901917 
	C547.358154,73.901917 534.438965,73.901917 521.423889,73.901917 
	C521.423889,68.683838 521.423889,63.985497 521.423889,58.789806 
	C536.094055,58.789806 550.346619,58.789806 565.000000,58.789806 
	C565.000000,56.649296 565.032898,55.008865 564.990723,53.370373 
	C564.949036,51.751881 564.831360,50.135345 564.741882,48.418228 
	C545.368225,48.418228 526.339844,48.418228 507.000153,48.418228 
	C507.000153,64.150703 506.956360,79.466942 507.015686,94.782791 
	C507.054535,104.803406 514.197021,111.927399 524.218018,111.986366 
	C534.549622,112.047157 544.881958,111.999962 555.213928,112.000000 
	C558.658447,112.000015 562.103027,112.000000 565.549561,112.000000 
	C565.549561,107.966141 565.549561,104.604637 565.549561,101.000000 
	C554.610352,101.000000 544.119690,101.000000 532.671997,101.000000 
M750.881775,151.643417 
	C748.081665,143.491547 743.802795,137.516373 733.763428,139.990875 
	C733.449341,140.068283 733.027222,139.880157 732.780029,140.026428 
	C731.617554,140.714264 730.120056,141.225708 729.437622,142.259323 
	C727.625671,145.003464 726.093567,147.943985 724.589478,150.877853 
	C716.591980,166.478775 708.639343,182.102707 700.664673,197.715347 
	C699.739258,199.527176 698.773682,201.318542 697.741699,203.280975 
	C710.459900,205.748138 711.930054,205.063522 717.104675,194.691711 
	C717.474243,193.951019 717.913086,193.235687 718.197754,192.463593 
	C719.197388,189.752045 721.031799,188.826248 723.953918,188.933990 
	C729.935120,189.154495 736.028198,189.683182 741.896729,188.841171 
	C750.878967,187.552383 756.157471,190.828995 758.797668,199.331299 
	C759.329285,201.043121 760.892395,203.561340 762.198975,203.704254 
	C767.005127,204.229935 771.903503,203.912857 778.005859,203.912857 
	C768.636353,185.946518 759.876831,169.149963 750.881775,151.643417 
M834.370728,97.000000 
	C839.787598,95.882385 843.111816,98.040070 844.746399,103.307259 
	C845.375793,105.335289 846.506775,107.237473 847.608582,109.077599 
	C848.245850,110.141815 849.238586,111.725052 850.143372,111.775131 
	C854.982178,112.043007 859.843689,111.901482 865.279236,111.901482 
	C864.094360,109.372078 863.387390,107.715210 862.560547,106.120506 
	C853.963562,89.539970 844.968872,73.150078 836.928955,56.304268 
	C833.852722,49.858742 830.452881,46.954117 823.188293,47.804970 
	C819.466309,48.240902 817.398376,49.021198 815.624756,52.546913 
	C806.785706,70.117905 797.577820,87.503220 788.536011,104.972626 
	C787.509949,106.955147 786.711426,109.055466 785.762207,111.203568 
	C798.774719,114.069176 800.798523,113.033157 805.151733,101.738350 
	C805.329712,101.276558 805.672791,100.874817 805.827332,100.408150 
	C806.649841,97.925049 808.097351,96.835686 810.949829,96.922981 
	C818.429321,97.151901 825.920532,97.000092 834.370728,97.000000 
M402.224335,61.074203 
	C403.485016,61.862530 404.743195,62.654945 406.006897,63.438412 
	C409.974731,65.898376 411.558716,65.102905 414.919769,59.273087 
	C417.024933,55.621639 414.190216,54.656769 411.887360,53.445412 
	C395.771606,44.968128 379.703247,45.106239 363.655304,53.703941 
	C344.973480,63.712738 340.809601,91.377419 361.616547,105.630493 
	C377.636200,116.604225 401.447571,115.736076 417.675293,103.559273 
	C416.672516,102.099236 415.518677,100.706024 414.681030,99.143295 
	C412.553925,95.174942 410.443573,94.524551 406.198303,97.180481 
	C395.771332,103.703789 384.669250,104.164307 373.681274,98.259972 
	C360.873810,91.377968 359.502838,73.347031 370.763916,64.527084 
	C380.378296,56.996868 390.574799,56.496971 402.224335,61.074203 
M1125.908569,52.438042 
	C1116.813110,48.845207 1107.704102,46.340652 1097.605103,47.844189 
	C1087.280640,49.381313 1078.215942,52.587238 1070.951050,60.467667 
	C1060.563232,71.735405 1061.655029,93.362602 1074.573853,103.413231 
	C1087.274414,113.293961 1101.378906,114.787544 1116.283081,111.631287 
	C1122.386963,110.338646 1128.541626,108.064690 1133.214233,103.060951 
	C1131.533691,100.855492 1129.845459,99.174690 1128.813354,97.158066 
	C1127.323730,94.247528 1125.405396,94.873001 1123.573242,96.244682 
	C1116.505371,101.535904 1108.406006,102.895950 1100.006348,101.914948 
	C1085.442749,100.214043 1073.945679,86.299805 1080.853394,71.525436 
	C1085.105835,62.430096 1098.746704,55.805183 1108.486206,58.397205 
	C1114.461914,59.987587 1120.165527,62.600529 1126.314209,64.873650 
	C1128.189453,62.297432 1130.172607,59.572857 1132.346313,56.586559 
	C1130.113037,55.146957 1128.329468,53.997311 1125.908569,52.438042 
M239.633575,66.002281 
	C237.482376,65.871185 236.429001,66.517990 237.400238,68.883240 
	C238.636002,71.892647 239.804443,74.931313 240.932617,77.982979 
	C244.834976,88.538765 249.860718,90.749863 259.750153,85.602592 
	C274.537201,77.906189 289.188385,69.946320 303.813538,61.945255 
	C305.179810,61.197788 306.802765,59.382595 306.849915,58.013000 
	C307.133148,49.786274 306.990509,41.544891 306.990509,32.386459 
	C301.928406,35.184158 297.689850,37.534515 293.444000,39.871696 
	C283.861603,45.146477 274.424866,50.714203 264.643829,55.591019 
	C256.808655,59.497635 248.553497,62.561962 239.633575,66.002281 
M729.000000,69.525589 
	C729.000000,66.111481 729.000000,62.697380 729.000000,58.793892 
	C737.425232,58.793892 745.009155,58.793892 752.704102,58.793892 
	C752.704102,55.037266 752.704102,51.791496 752.704102,48.397362 
	C731.716614,48.397362 711.027100,48.397362 690.337708,48.397362 
	C690.337708,52.046780 690.337708,55.294365 690.337708,59.216236 
	C698.366577,59.216236 706.076660,59.216236 714.255371,59.216236 
	C714.255371,77.142097 714.255371,94.384537 714.255371,111.681396 
	C719.341064,111.681396 723.918518,111.681396 729.000000,111.681396 
	C729.000000,97.751953 729.000000,84.135796 729.000000,69.525589 
M1237.592285,48.000011 
	C1235.835205,48.078373 1234.078125,48.156734 1232.295410,48.236237 
	C1232.295410,69.680069 1232.295410,90.562668 1232.295410,111.626488 
	C1250.265991,111.626488 1267.939209,111.626488 1285.691650,111.626488 
	C1285.691650,108.005997 1285.691650,104.762810 1285.691650,100.863670 
	C1272.706909,100.863670 1260.014282,100.863670 1246.868164,100.863670 
	C1246.868164,82.978653 1246.868164,65.752037 1246.868164,48.000000 
	C1243.787964,48.000000 1241.166992,48.000000 1237.592285,48.000011 
M1035.000000,102.459869 
	C1035.000000,105.539894 1035.000000,108.619911 1035.000000,111.655785 
	C1040.404053,111.655785 1044.976318,111.655785 1049.585449,111.655785 
	C1049.585449,90.309761 1049.585449,69.310623 1049.585449,48.346115 
	C1044.593018,48.346115 1040.020142,48.346115 1035.000000,48.346115 
	C1035.000000,66.283218 1035.000000,83.877953 1035.000000,102.459869 
M964.500610,204.000000 
	C966.908813,204.000000 969.317017,204.000000 971.671448,204.000000 
	C971.671448,182.254288 971.671448,161.369949 971.671448,140.437592 
	C966.639526,140.437592 961.948853,140.437592 957.336304,140.437592 
	C957.336304,161.761185 957.336304,182.645584 957.336304,204.000000 
	C959.627136,204.000000 961.586548,204.000000 964.500610,204.000000 
M667.688965,48.001194 
	C663.797119,47.423576 663.970886,49.972160 663.977173,52.631439 
	C664.020569,70.930977 663.992065,89.230675 664.015991,107.530281 
	C664.017883,108.948936 664.243652,110.367294 664.351562,111.624588 
	C669.130432,111.624588 673.371887,111.624588 677.546143,111.624588 
	C677.546143,90.244789 677.546143,69.243607 677.546143,48.000145 
	C674.272400,48.000145 671.451416,48.000145 667.688965,48.001194 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M31.090477,122.439285 
	C38.683601,95.219208 56.815536,77.338959 79.904053,63.539467 
	C93.670235,55.311726 108.481628,49.946491 124.071472,46.382454 
	C138.844070,43.005257 147.511917,48.262974 151.030594,63.161232 
	C151.923447,66.941681 153.102036,70.654640 154.239716,74.721825 
	C139.294983,74.988319 125.458626,78.468895 112.345169,84.132912 
	C88.524773,94.421478 68.386719,109.265068 56.454041,133.196640 
	C54.870789,136.371918 53.622162,139.914246 53.181637,143.408020 
	C52.641037,147.695496 53.301071,152.389954 57.892036,154.318008 
	C64.138557,156.941345 70.637619,160.388306 77.145172,160.657318 
	C89.292488,161.159515 101.696220,160.662659 113.675346,158.670242 
	C127.632759,156.348801 141.742218,153.257553 154.895294,148.189499 
	C173.367401,141.071960 191.018967,131.824921 209.022034,123.489899 
	C209.617554,123.214180 210.219299,122.951889 211.401520,122.368256 
	C221.994492,116.698311 232.004105,111.343452 242.013702,105.988594 
	C253.838638,99.662552 265.702301,93.407532 277.476379,86.988243 
	C287.078918,81.752884 296.574432,76.321167 306.989105,70.488319 
	C306.989105,81.168449 307.050720,91.058205 306.891357,100.944397 
	C306.875824,101.905647 305.683533,103.144386 304.720306,103.749207 
	C291.267181,112.196266 277.770264,120.573830 264.254456,128.920517 
	C261.474976,130.636978 258.574829,132.157990 255.040039,133.905914 
	C253.853775,134.080215 253.276596,133.964294 252.873688,134.177597 
	C243.359451,139.214478 233.848343,144.258118 224.397034,149.411209 
	C223.695114,149.793915 223.447174,151.009232 222.988174,151.837463 
	C212.872711,156.468552 202.778717,161.147202 192.637253,165.720596 
	C172.817673,174.658432 152.807175,183.176788 131.426880,187.640808 
	C124.077576,189.175293 116.419746,189.208664 108.911942,190.012558 
	C95.508400,191.447754 82.482719,189.559204 69.920898,184.997040 
	C49.314964,177.513397 36.009918,163.400696 31.469837,141.240967 
	C31.112801,134.985214 31.067513,128.944077 31.090477,122.439285 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910889,111.579178 
	C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
	C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
	C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
	C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
	C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
	C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
	C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
	C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
	C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
	C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
	C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
	C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
	C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
	C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.984283,105.615341 
	C232.004105,111.343452 221.994492,116.698311 211.627716,122.087715 
	C205.866150,108.901680 200.461746,95.681091 194.779572,81.780991 
	C190.007050,91.954369 185.587692,101.465660 181.087753,110.938675 
	C177.480637,118.532150 174.142441,126.292755 169.923965,133.536011 
	C168.355682,136.228836 164.802444,137.985443 161.847809,139.612183 
	C150.376190,145.928085 137.916611,148.512161 124.021698,149.186722 
	C126.216621,144.736801 127.982674,140.900589 129.966644,137.180603 
	C136.733093,124.493347 143.594818,111.856941 150.385406,99.182510 
	C158.584976,83.878265 166.804977,68.584396 174.882355,53.215729 
	C176.538559,50.064487 178.595932,48.814217 182.253265,48.908886 
	C192.406006,49.171692 202.570984,49.079845 212.728897,48.950974 
	C215.730209,48.912895 217.202179,49.679165 218.550171,52.773621 
	C225.410416,68.521744 232.746063,84.062508 239.876175,99.693695 
	C240.693832,101.486244 241.267868,103.389931 241.984283,105.615341 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M645.996582,189.987793 
	C638.942444,179.904114 632.010010,170.161163 625.077637,160.418213 
	C624.742859,160.529755 624.408081,160.641296 624.073303,160.752838 
	C624.073303,174.940964 624.073303,189.129105 624.073303,203.653503 
	C619.977966,203.653503 616.399231,203.653503 612.405396,203.653503 
	C612.405396,182.650391 612.405396,161.625641 612.405396,140.010452 
	C616.644714,140.010452 620.786377,139.745667 624.861328,140.151047 
	C626.329712,140.297104 627.990295,141.794571 628.961914,143.117050 
	C635.207275,151.617798 641.285645,160.241318 647.419495,168.824036 
	C647.905029,169.503464 648.395569,170.179291 649.177673,171.264450 
	C654.866882,163.473480 661.159302,156.742920 665.189270,148.856277 
	C670.137756,139.171997 677.522217,138.956940 686.613159,140.506744 
	C686.613159,161.270645 686.613159,182.175583 686.613159,203.541290 
	C682.080505,203.541290 677.384033,203.541290 672.191711,203.541290 
	C672.191711,189.316788 672.191711,175.088684 672.191711,160.860596 
	C671.827698,160.712646 671.463623,160.564713 671.099609,160.416779 
	C665.728943,167.956223 660.353882,175.492538 654.990112,183.036850 
	C653.272034,185.453354 651.750732,188.042206 649.776184,190.223358 
	C649.209595,190.849258 647.372742,190.325256 645.996582,189.987793 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M393.734253,188.232834 
	C385.974396,179.107132 378.467712,170.233231 370.960999,161.359344 
	C370.618164,161.509567 370.275330,161.659790 369.932495,161.810013 
	C369.932495,175.685440 369.932495,189.560852 369.932495,203.713043 
	C365.186768,203.713043 360.942413,203.713043 356.348022,203.713043 
	C356.348022,182.651413 356.348022,161.747620 356.348022,140.830353 
	C365.542816,138.160370 369.421814,139.653610 375.826965,147.204132 
	C385.846039,159.014816 396.145721,170.587418 406.918701,182.939865 
	C406.918701,168.329559 406.918701,154.601395 406.918701,140.438873 
	C411.951202,140.438873 416.649017,140.438873 421.676819,140.438873 
	C421.676819,161.400650 421.676819,182.304001 421.676819,203.946869 
	C417.238739,203.946869 412.790985,204.226761 408.414490,203.791138 
	C406.933807,203.643753 405.457123,201.843002 404.283478,200.526138 
	C400.770569,196.584381 397.408386,192.508301 393.734253,188.232834 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M803.000000,165.522415 
	C803.000000,178.420258 803.000000,190.881012 803.000000,203.668213 
	C797.943542,203.668213 793.364380,203.668213 788.390381,203.668213 
	C788.390381,182.730103 788.390381,161.818634 788.390381,140.859467 
	C796.247437,138.357574 802.394897,139.925522 806.380127,144.610474 
	C817.178284,157.304398 828.233765,169.779419 839.799683,183.043793 
	C839.799683,168.467926 839.799683,154.627777 839.799683,140.398254 
	C844.708740,140.398254 849.069763,140.398254 853.719971,140.398254 
	C853.719971,161.496262 853.719971,182.406921 853.719971,203.045380 
	C842.354065,207.728119 836.561401,200.300537 830.539734,193.026840 
	C823.165649,184.119553 815.572327,175.393906 808.089233,166.576630 
	C806.711243,164.952988 805.406372,163.267303 803.459900,160.857895 
	C803.249146,162.795258 803.124573,163.940292 803.000000,165.522415 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M922.873535,200.043701 
	C915.754822,201.444305 909.047668,203.381409 902.240906,203.840363 
	C892.813477,204.475998 883.311096,204.000000 873.421692,204.000000 
	C873.421692,182.694702 873.421692,161.688919 873.421692,140.756973 
	C885.171326,140.756973 896.898254,140.309555 908.565247,140.950989 
	C914.163879,141.258774 919.909851,143.002060 925.132935,145.186127 
	C936.832092,150.078186 942.676025,159.918854 942.699890,172.032196 
	C942.723999,184.249969 936.440552,193.640442 925.005310,199.004898 
	C924.405762,199.286148 923.828308,199.614532 922.873535,200.043701 
M888.000000,173.499603 
	C888.000000,179.902878 888.000000,186.306152 888.000000,193.491821 
	C896.092407,192.936508 903.460266,193.165756 910.505249,191.767853 
	C920.052795,189.873367 925.954285,183.641663 927.685913,173.710495 
	C928.687500,167.966553 924.646851,159.880905 918.100830,155.504242 
	C908.857544,149.324203 898.568909,151.234695 888.000000,151.157867 
	C888.000000,158.614044 888.000000,165.556992 888.000000,173.499603 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M561.942078,140.000000 
	C569.660461,141.344955 577.207214,141.802521 584.035889,144.257492 
	C592.747681,147.389465 594.955627,155.079407 594.007080,163.813049 
	C593.214722,171.108673 588.365540,175.049438 582.344971,178.000198 
	C580.764343,178.774902 579.050415,179.277588 576.921387,180.086075 
	C583.561157,187.918564 589.879272,195.371613 596.828003,203.568604 
	C586.816895,204.434174 578.397278,205.523590 572.907166,195.715210 
	C570.302551,191.061951 565.839111,187.432907 562.111267,183.439346 
	C561.434326,182.714203 560.252686,182.100327 559.283752,182.069229 
	C554.805847,181.925507 550.320740,182.005783 545.154297,182.005783 
	C545.154297,189.316177 545.154297,196.362595 545.154297,203.692596 
	C540.230408,203.692596 535.986511,203.692596 531.368774,203.692596 
	C531.368774,182.695602 531.368774,161.792755 531.368774,140.000000 
	C541.306458,140.000000 551.378967,140.000000 561.942078,140.000000 
M554.528870,151.000000 
	C551.449585,151.000000 548.370300,151.000000 545.348145,151.000000 
	C545.348145,158.081360 545.348145,164.320282 545.348145,170.979675 
	C553.142883,170.979675 560.598999,171.079834 568.048645,170.905045 
	C569.780762,170.864410 571.588562,170.086990 573.191589,169.301758 
	C576.994080,167.439163 579.566589,164.812302 579.056824,160.003418 
	C578.595337,155.649048 575.942810,153.354980 572.173523,152.715744 
	C566.680115,151.784149 561.070374,151.538239 554.528870,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M464.730957,96.182663 
	C462.346100,91.690926 459.266174,89.322525 454.175079,89.931686 
	C451.079498,90.302086 447.903473,90.000000 444.258789,90.000000 
	C444.258789,97.271812 444.258789,104.188507 444.258789,111.550102 
	C439.408508,111.550102 435.046600,111.550102 430.335205,111.550102 
	C430.335205,90.629959 430.335205,69.731064 430.335205,48.432297 
	C431.530853,48.293453 432.794525,47.995102 434.050568,48.024265 
	C446.820068,48.320766 459.621124,48.228893 472.344452,49.173531 
	C479.814545,49.728142 486.990143,52.500721 490.858948,59.769283 
	C496.441406,70.257423 492.477203,81.190750 481.612366,86.116196 
	C479.970062,86.860718 478.283203,87.507011 475.824982,88.527534 
	C482.379059,96.194016 488.693939,103.580643 495.842834,111.942886 
	C489.514923,111.942886 484.094879,113.034058 479.472931,111.583107 
	C476.035706,110.504082 473.575806,106.183975 470.788086,103.206757 
	C468.759033,101.039772 466.905396,98.708527 464.730957,96.182663 
M472.843384,61.394958 
	C463.568634,58.104576 454.030609,59.046410 444.427673,59.147236 
	C444.427673,66.001076 444.427673,72.360649 444.427673,78.988754 
	C452.498688,78.988754 460.149902,79.157532 467.789001,78.926430 
	C472.487579,78.784294 476.277039,76.006546 477.707397,71.841171 
	C479.023346,68.008972 478.028809,63.855194 472.843384,61.394958 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M956.000000,100.977371 
	C956.000000,83.193291 956.000000,65.906227 956.000000,48.805595 
	C968.917542,48.805595 981.325256,48.426270 993.690796,48.984138 
	C998.959106,49.221817 1004.359192,50.869572 1009.307922,52.853203 
	C1016.840759,55.872730 1019.251221,62.241581 1019.076721,70.111618 
	C1018.913452,77.475876 1014.936523,82.018791 1009.069214,85.408226 
	C1007.087769,86.552872 1004.854614,87.261902 1001.860229,88.545738 
	C1008.378174,96.177444 1014.680420,103.556679 1021.847473,111.948448 
	C1015.577820,111.948448 1010.088562,113.158836 1005.584412,111.572449 
	C1001.858887,110.260269 999.189697,105.741341 996.249634,102.466316 
	C993.387634,99.278152 990.968323,95.675156 987.966919,92.637451 
	C986.622192,91.276535 984.384705,90.303688 982.458313,90.115227 
	C978.538208,89.731712 974.554260,90.000504 970.097534,90.000504 
	C970.097534,97.438957 970.097534,104.360168 970.097534,111.634384 
	C965.346680,111.634384 961.100220,111.634384 956.000000,111.634384 
	C956.000000,108.306297 956.000000,104.890343 956.000000,100.977371 
M987.419250,79.000000 
	C989.579346,78.995789 991.752563,79.142250 993.896912,78.956238 
	C998.477966,78.558884 1003.547485,74.387070 1003.989258,70.834564 
	C1004.620422,65.758354 1002.370972,61.475452 996.787476,60.604149 
	C988.104919,59.249260 979.226685,59.148869 970.312317,58.508198 
	C970.312317,66.017960 970.312317,72.250107 970.312317,79.000000 
	C975.890503,79.000000 981.165833,79.000000 987.419250,79.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M645.273560,97.183868 
	C639.686768,105.051971 631.806335,108.543198 623.221252,110.783691 
	C620.686707,111.445137 618.025391,111.916595 615.416321,111.945488 
	C604.138672,112.070412 592.858887,111.998970 581.294434,111.998970 
	C581.294434,90.540001 581.294434,69.637436 581.294434,48.345707 
	C591.479858,48.345707 601.570740,47.981182 611.618042,48.485062 
	C617.464722,48.778275 623.409302,49.912743 629.019470,51.621792 
	C649.151062,57.754539 655.930420,79.597946 645.273560,97.183868 
M635.000000,80.460846 
	C635.487061,72.039108 631.581665,65.884712 624.121826,62.805210 
	C618.932800,60.663132 613.090942,59.892315 607.457336,59.108894 
	C603.466064,58.553864 599.335327,59.001373 595.327881,59.001373 
	C595.327881,73.413826 595.327881,86.963539 595.327881,101.896317 
	C604.386108,100.892265 613.162170,100.692749 621.545837,98.776405 
	C630.223450,96.792877 634.772400,89.863029 635.000000,80.460846 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1204.729004,105.797745 
	C1187.161743,116.078453 1169.306152,116.346466 1152.829224,105.639702 
	C1131.435059,91.737801 1136.017090,63.748470 1154.457642,53.865906 
	C1173.257202,43.790928 1191.924316,44.311932 1208.629517,57.623932 
	C1221.230469,67.665421 1223.146973,85.540100 1212.049072,99.335732 
	C1210.113770,101.741539 1207.374390,103.500473 1204.729004,105.797745 
M1189.450439,100.191345 
	C1207.001953,93.046165 1207.906372,74.235802 1196.884155,64.608322 
	C1189.709839,58.341763 1180.775391,56.421150 1171.386597,59.110191 
	C1163.170898,61.463207 1156.833984,66.237579 1154.619141,74.925606 
	C1152.623901,82.752274 1154.739746,89.540916 1160.653442,95.187431 
	C1168.900757,103.062073 1178.531616,103.338577 1189.450439,100.191345 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M448.760895,196.269501 
	C435.819244,186.559143 432.000214,169.326828 441.353333,155.264145 
	C449.982697,142.289612 470.330261,137.292007 483.238586,139.506607 
	C492.098206,141.026581 499.927032,144.049118 506.723846,150.130508 
	C521.793640,163.614014 519.097717,188.925400 499.693481,199.026245 
	C482.466431,207.993744 465.428955,207.153717 448.760895,196.269501 
M484.990112,151.091553 
	C478.248535,149.209351 471.616516,149.729202 465.092102,152.072876 
	C456.031219,155.327713 449.764984,166.657028 451.596008,175.329346 
	C454.733673,190.190521 468.644012,195.763947 481.045563,193.730133 
	C496.341095,191.221741 504.779572,177.725922 499.926483,164.421066 
	C497.507660,157.789825 491.924683,154.147369 484.990112,151.091553 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M875.306152,100.622223 
	C863.762207,85.888046 866.550903,68.103912 877.454712,58.169563 
	C883.930664,52.269455 891.859924,49.258659 900.611694,47.903442 
	C911.483643,46.219910 921.563721,48.071114 931.166016,53.094997 
	C933.585144,54.360710 937.394958,55.115536 934.822083,59.431419 
	C931.134644,65.616936 930.234680,65.890335 925.482788,63.019321 
	C914.458557,56.358620 903.313477,56.105419 892.392395,63.159355 
	C885.579956,67.559494 882.395142,74.270340 883.149231,82.184685 
	C883.964233,90.737968 888.985229,96.777962 896.932861,100.039207 
	C905.137085,103.405708 913.352234,102.528549 921.607971,99.746834 
	C928.513062,97.420204 925.292175,92.079056 925.975830,87.255402 
	C919.735596,87.255402 913.818604,87.255402 907.452515,87.255402 
	C907.452515,83.402443 907.452515,80.043388 907.452515,76.336777 
	C918.030945,76.336777 928.603027,76.336777 939.971558,76.336777 
	C939.971558,84.878815 940.120239,93.462181 939.813354,102.029221 
	C939.766663,103.333061 937.840088,105.011841 936.391846,105.755692 
	C919.450073,114.457466 902.120300,116.286629 884.575745,107.707436 
	C881.242554,106.077515 878.510193,103.219002 875.306152,100.622223 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1017.010620,151.000000 
	C1012.553589,151.000000 1008.594727,151.000000 1004.324585,151.000000 
	C1004.324585,155.733978 1004.324585,159.978149 1004.324585,164.902039 
	C1017.381714,164.902039 1030.417969,164.902039 1043.717651,164.902039 
	C1043.717651,168.818573 1043.717651,172.063034 1043.717651,175.810410 
	C1030.655884,175.810410 1017.745544,175.810410 1004.130859,175.810410 
	C1004.130859,179.869171 1003.590454,183.550507 1004.268738,186.991623 
	C1005.101685,191.217590 1008.342224,193.067627 1012.765808,193.032028 
	C1024.569092,192.937027 1036.373657,192.999710 1048.587402,192.999710 
	C1048.587402,196.678436 1048.587402,200.037415 1048.587402,204.000000 
	C1037.191895,204.000000 1025.919922,204.097183 1014.651001,203.951630 
	C1010.210144,203.894287 1005.688354,203.725143 1001.359985,202.840683 
	C995.111023,201.563751 990.135559,195.611298 990.067505,189.362839 
	C989.895081,173.547653 990.028870,157.729050 990.063843,141.911896 
	C990.064819,141.453979 990.332947,140.996658 990.556152,140.285156 
	C1009.141296,140.285156 1027.706665,140.285156 1046.636475,140.285156 
	C1046.636475,143.653793 1046.636475,146.901016 1046.636475,151.000000 
	C1036.994751,151.000000 1027.251709,151.000000 1017.010620,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M533.150513,101.000000 
	C544.119690,101.000000 554.610352,101.000000 565.549561,101.000000 
	C565.549561,104.604637 565.549561,107.966141 565.549561,112.000000 
	C562.103027,112.000000 558.658447,112.000015 555.213928,112.000000 
	C544.881958,111.999962 534.549622,112.047157 524.218018,111.986366 
	C514.197021,111.927399 507.054535,104.803406 507.015686,94.782791 
	C506.956360,79.466942 507.000153,64.150703 507.000153,48.418228 
	C526.339844,48.418228 545.368225,48.418228 564.741882,48.418228 
	C564.831360,50.135345 564.949036,51.751881 564.990723,53.370373 
	C565.032898,55.008865 565.000000,56.649296 565.000000,58.789806 
	C550.346619,58.789806 536.094055,58.789806 521.423889,58.789806 
	C521.423889,63.985497 521.423889,68.683838 521.423889,73.901917 
	C534.438965,73.901917 547.358154,73.901917 560.633484,73.901917 
	C560.633484,77.315506 560.633484,80.228722 560.633484,83.839943 
	C547.694824,83.839943 534.648254,83.839943 521.718262,83.839943 
	C519.569702,98.414803 523.555786,102.135979 533.150513,101.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M750.999512,151.998413 
	C759.876831,169.149963 768.636353,185.946518 778.005859,203.912857 
	C771.903503,203.912857 767.005127,204.229935 762.198975,203.704254 
	C760.892395,203.561340 759.329285,201.043121 758.797668,199.331299 
	C756.157471,190.828995 750.878967,187.552383 741.896729,188.841171 
	C736.028198,189.683182 729.935120,189.154495 723.953918,188.933990 
	C721.031799,188.826248 719.197388,189.752045 718.197754,192.463593 
	C717.913086,193.235687 717.474243,193.951019 717.104675,194.691711 
	C711.930054,205.063522 710.459900,205.748138 697.741699,203.280975 
	C698.773682,201.318542 699.739258,199.527176 700.664673,197.715347 
	C708.639343,182.102707 716.591980,166.478775 724.589478,150.877853 
	C726.093567,147.943985 727.625671,145.003464 729.437622,142.259323 
	C730.120056,141.225708 731.617554,140.714264 732.780029,140.026428 
	C733.027222,139.880157 733.449341,140.068283 733.763428,139.990875 
	C743.802795,137.516373 748.081665,143.491547 750.999512,151.998413 
M747.330078,177.067245 
	C743.955566,170.151428 740.580994,163.235596 736.787292,155.460693 
	C732.874023,163.318298 729.417786,170.258255 725.664307,177.794907 
	C733.358704,177.794907 740.245667,177.794907 747.330078,177.067245 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M833.888916,97.000000 
	C825.920532,97.000092 818.429321,97.151901 810.949829,96.922981 
	C808.097351,96.835686 806.649841,97.925049 805.827332,100.408150 
	C805.672791,100.874817 805.329712,101.276558 805.151733,101.738350 
	C800.798523,113.033157 798.774719,114.069176 785.762207,111.203568 
	C786.711426,109.055466 787.509949,106.955147 788.536011,104.972626 
	C797.577820,87.503220 806.785706,70.117905 815.624756,52.546913 
	C817.398376,49.021198 819.466309,48.240902 823.188293,47.804970 
	C830.452881,46.954117 833.852722,49.858742 836.928955,56.304268 
	C844.968872,73.150078 853.963562,89.539970 862.560547,106.120506 
	C863.387390,107.715210 864.094360,109.372078 865.279236,111.901482 
	C859.843689,111.901482 854.982178,112.043007 850.143372,111.775131 
	C849.238586,111.725052 848.245850,110.141815 847.608582,109.077599 
	C846.506775,107.237473 845.375793,105.335289 844.746399,103.307259 
	C843.111816,98.040070 839.787598,95.882385 833.888916,97.000000 
M831.117554,76.354225 
	C828.994995,72.076897 826.872437,67.799561 824.404846,62.827045 
	C820.724731,70.720222 817.315186,78.032990 813.712097,85.760963 
	C821.475769,85.760963 828.347107,85.760963 835.766785,85.760963 
	C834.214600,82.574120 832.856567,79.785881 831.117554,76.354225 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M223.012543,152.225433 
	C223.447174,151.009232 223.695114,149.793915 224.397034,149.411209 
	C233.848343,144.258118 243.359451,139.214478 252.873688,134.177597 
	C253.276596,133.964294 253.853775,134.080215 254.732513,134.070114 
	C260.955688,146.475433 266.816376,158.843887 272.627167,171.235748 
	C275.124420,176.561356 277.518127,181.935516 280.268219,187.965897 
	C267.259583,187.965897 254.996826,188.169983 242.748764,187.835632 
	C239.288757,187.741180 236.377472,185.633621 234.918030,181.905289 
	C231.077652,172.094345 227.012543,162.371368 223.012543,152.225433 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M401.854187,60.949802 
	C390.574799,56.496971 380.378296,56.996868 370.763916,64.527084 
	C359.502838,73.347031 360.873810,91.377968 373.681274,98.259972 
	C384.669250,104.164307 395.771332,103.703789 406.198303,97.180481 
	C410.443573,94.524551 412.553925,95.174942 414.681030,99.143295 
	C415.518677,100.706024 416.672516,102.099236 417.675293,103.559273 
	C401.447571,115.736076 377.636200,116.604225 361.616547,105.630493 
	C340.809601,91.377419 344.973480,63.712738 363.655304,53.703941 
	C379.703247,45.106239 395.771606,44.968128 411.887360,53.445412 
	C414.190216,54.656769 417.024933,55.621639 414.919769,59.273087 
	C411.558716,65.102905 409.974731,65.898376 406.006897,63.438412 
	C404.743195,62.654945 403.485016,61.862530 401.854187,60.949802 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1126.227295,52.642853 
	C1128.329468,53.997311 1130.113037,55.146957 1132.346313,56.586559 
	C1130.172607,59.572857 1128.189453,62.297432 1126.314209,64.873650 
	C1120.165527,62.600529 1114.461914,59.987587 1108.486206,58.397205 
	C1098.746704,55.805183 1085.105835,62.430096 1080.853394,71.525436 
	C1073.945679,86.299805 1085.442749,100.214043 1100.006348,101.914948 
	C1108.406006,102.895950 1116.505371,101.535904 1123.573242,96.244682 
	C1125.405396,94.873001 1127.323730,94.247528 1128.813354,97.158066 
	C1129.845459,99.174690 1131.533691,100.855492 1133.214233,103.060951 
	C1128.541626,108.064690 1122.386963,110.338646 1116.283081,111.631287 
	C1101.378906,114.787544 1087.274414,113.293961 1074.573853,103.413231 
	C1061.655029,93.362602 1060.563232,71.735405 1070.951050,60.467667 
	C1078.215942,52.587238 1087.280640,49.381313 1097.605103,47.844189 
	C1107.704102,46.340652 1116.813110,48.845207 1126.227295,52.642853 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M240.059158,66.001190 
	C248.553497,62.561962 256.808655,59.497635 264.643829,55.591019 
	C274.424866,50.714203 283.861603,45.146477 293.444000,39.871696 
	C297.689850,37.534515 301.928406,35.184158 306.990509,32.386459 
	C306.990509,41.544891 307.133148,49.786274 306.849915,58.013000 
	C306.802765,59.382595 305.179810,61.197788 303.813538,61.945255 
	C289.188385,69.946320 274.537201,77.906189 259.750153,85.602592 
	C249.860718,90.749863 244.834976,88.538765 240.932617,77.982979 
	C239.804443,74.931313 238.636002,71.892647 237.400238,68.883240 
	C236.429001,66.517990 237.482376,65.871185 240.059158,66.001190 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M729.000000,70.022614 
	C729.000000,84.135796 729.000000,97.751953 729.000000,111.681396 
	C723.918518,111.681396 719.341064,111.681396 714.255371,111.681396 
	C714.255371,94.384537 714.255371,77.142097 714.255371,59.216236 
	C706.076660,59.216236 698.366577,59.216236 690.337708,59.216236 
	C690.337708,55.294365 690.337708,52.046780 690.337708,48.397362 
	C711.027100,48.397362 731.716614,48.397362 752.704102,48.397362 
	C752.704102,51.791496 752.704102,55.037266 752.704102,58.793892 
	C745.009155,58.793892 737.425232,58.793892 729.000000,58.793892 
	C729.000000,62.697380 729.000000,66.111481 729.000000,70.022614 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1238.069092,48.000008 
	C1241.166992,48.000000 1243.787964,48.000000 1246.868164,48.000000 
	C1246.868164,65.752037 1246.868164,82.978653 1246.868164,100.863670 
	C1260.014282,100.863670 1272.706909,100.863670 1285.691650,100.863670 
	C1285.691650,104.762810 1285.691650,108.005997 1285.691650,111.626488 
	C1267.939209,111.626488 1250.265991,111.626488 1232.295410,111.626488 
	C1232.295410,90.562668 1232.295410,69.680069 1232.295410,48.236237 
	C1234.078125,48.156734 1235.835205,48.078373 1238.069092,48.000008 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1035.000000,101.966278 
	C1035.000000,83.877953 1035.000000,66.283218 1035.000000,48.346115 
	C1040.020142,48.346115 1044.593018,48.346115 1049.585449,48.346115 
	C1049.585449,69.310623 1049.585449,90.309761 1049.585449,111.655785 
	C1044.976318,111.655785 1040.404053,111.655785 1035.000000,111.655785 
	C1035.000000,108.619911 1035.000000,105.539894 1035.000000,101.966278 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M964.023254,204.000000 
	C961.586548,204.000000 959.627136,204.000000 957.336304,204.000000 
	C957.336304,182.645584 957.336304,161.761185 957.336304,140.437592 
	C961.948853,140.437592 966.639526,140.437592 971.671448,140.437592 
	C971.671448,161.369949 971.671448,182.254288 971.671448,204.000000 
	C969.317017,204.000000 966.908813,204.000000 964.023254,204.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M668.159668,48.000671 
	C671.451416,48.000145 674.272400,48.000145 677.546143,48.000145 
	C677.546143,69.243607 677.546143,90.244789 677.546143,111.624588 
	C673.371887,111.624588 669.130432,111.624588 664.351562,111.624588 
	C664.243652,110.367294 664.017883,108.948936 664.015991,107.530281 
	C663.992065,89.230675 664.020569,70.930977 663.977173,52.631439 
	C663.970886,49.972160 663.797119,47.423576 668.159668,48.000671 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M888.000000,172.999756 
	C888.000000,165.556992 888.000000,158.614044 888.000000,151.157867 
	C898.568909,151.234695 908.857544,149.324203 918.100830,155.504242 
	C924.646851,159.880905 928.687500,167.966553 927.685913,173.710495 
	C925.954285,183.641663 920.052795,189.873367 910.505249,191.767853 
	C903.460266,193.165756 896.092407,192.936508 888.000000,193.491821 
	C888.000000,186.306152 888.000000,179.902878 888.000000,172.999756 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M555.019531,151.000000 
	C561.070374,151.538239 566.680115,151.784149 572.173523,152.715744 
	C575.942810,153.354980 578.595337,155.649048 579.056824,160.003418 
	C579.566589,164.812302 576.994080,167.439163 573.191589,169.301758 
	C571.588562,170.086990 569.780762,170.864410 568.048645,170.905045 
	C560.598999,171.079834 553.142883,170.979675 545.348145,170.979675 
	C545.348145,164.320282 545.348145,158.081360 545.348145,151.000000 
	C548.370300,151.000000 551.449585,151.000000 555.019531,151.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M473.173615,61.613216 
	C478.028809,63.855194 479.023346,68.008972 477.707397,71.841171 
	C476.277039,76.006546 472.487579,78.784294 467.789001,78.926430 
	C460.149902,79.157532 452.498688,78.988754 444.427673,78.988754 
	C444.427673,72.360649 444.427673,66.001076 444.427673,59.147236 
	C454.030609,59.046410 463.568634,58.104576 473.173615,61.613216 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M986.930237,79.000000 
	C981.165833,79.000000 975.890503,79.000000 970.312317,79.000000 
	C970.312317,72.250107 970.312317,66.017960 970.312317,58.508198 
	C979.226685,59.148869 988.104919,59.249260 996.787476,60.604149 
	C1002.370972,61.475452 1004.620422,65.758354 1003.989258,70.834564 
	C1003.547485,74.387070 998.477966,78.558884 993.896912,78.956238 
	C991.752563,79.142250 989.579346,78.995789 986.930237,79.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M635.000000,80.907715 
	C634.772400,89.863029 630.223450,96.792877 621.545837,98.776405 
	C613.162170,100.692749 604.386108,100.892265 595.327881,101.896317 
	C595.327881,86.963539 595.327881,73.413826 595.327881,59.001373 
	C599.335327,59.001373 603.466064,58.553864 607.457336,59.108894 
	C613.090942,59.892315 618.932800,60.663132 624.121826,62.805210 
	C631.581665,65.884712 635.487061,72.039108 635.000000,80.907715 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1189.115967,100.407364 
	C1178.531616,103.338577 1168.900757,103.062073 1160.653442,95.187431 
	C1154.739746,89.540916 1152.623901,82.752274 1154.619141,74.925606 
	C1156.833984,66.237579 1163.170898,61.463207 1171.386597,59.110191 
	C1180.775391,56.421150 1189.709839,58.341763 1196.884155,64.608322 
	C1207.906372,74.235802 1207.001953,93.046165 1189.115967,100.407364 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M485.370300,151.226578 
	C491.924683,154.147369 497.507660,157.789825 499.926483,164.421066 
	C504.779572,177.725922 496.341095,191.221741 481.045563,193.730133 
	C468.644012,195.763947 454.733673,190.190521 451.596008,175.329346 
	C449.764984,166.657028 456.031219,155.327713 465.092102,152.072876 
	C471.616516,149.729202 478.248535,149.209351 485.370300,151.226578 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M747.231323,177.431076 
	C740.245667,177.794907 733.358704,177.794907 725.664307,177.794907 
	C729.417786,170.258255 732.874023,163.318298 736.787292,155.460693 
	C740.580994,163.235596 743.955566,170.151428 747.231323,177.431076 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M831.307983,76.675934 
	C832.856567,79.785881 834.214600,82.574120 835.766785,85.760963 
	C828.347107,85.760963 821.475769,85.760963 813.712097,85.760963 
	C817.315186,78.032990 820.724731,70.720222 824.404846,62.827045 
	C826.872437,67.799561 828.994995,72.076897 831.307983,76.675934 
z"
      />
    </svg>
  );
};

CaNormandieSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaNormandieSvg;
