import * as React from 'react';
import PropTypes from 'prop-types';

// the logo svg BforBank is expected, find below is a copy of logo CA

const BforBankSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      alt={alt}
      viewBox="0 0 1754 312"
      enableBackground="new 0 0 1754 312"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#FFFFFF"
        opacity="0.000000"
        stroke="none"
        d="
M1301.000000,313.000000 
   C867.385620,313.000000 434.271271,313.000000 1.078463,313.000000 
   C1.078463,209.064926 1.078463,105.129761 1.078463,1.097302 
   C585.666687,1.097302 1170.333374,1.097302 1754.972046,1.097302 
   C1754.972046,105.062355 1754.972046,209.031189 1754.972046,313.000000 
   C1603.796021,313.000000 1452.648071,313.000000 1301.000000,313.000000 
M697.088928,31.017366 
   C690.718018,31.065792 684.347107,31.114218 677.052673,31.011492 
   C461.760010,31.011492 246.467346,31.011492 31.162169,31.011492 
   C31.162169,71.407166 31.162169,111.196114 31.021221,151.910645 
   C31.068096,158.612442 31.114969,165.314240 31.011436,172.940598 
   C31.011436,209.587112 31.011436,246.233643 31.011436,283.020355 
   C32.668823,282.943848 33.822639,282.890594 35.905121,282.982819 
   C143.449814,282.988525 250.994492,282.994720 358.539185,282.993195 
   C359.700714,282.993195 360.862244,282.891571 362.947266,282.988495 
   C815.639343,282.992310 1268.331421,282.996368 1721.023560,282.989166 
   C1722.291138,282.989136 1723.558716,282.824615 1725.000000,282.724884 
   C1725.000000,269.263885 1725.014282,256.120636 1724.997437,242.977463 
   C1724.951050,206.992813 1724.891602,171.008163 1724.982788,134.102112 
   C1724.934326,129.726822 1724.885742,125.351532 1724.988525,120.052780 
   C1724.988525,90.460602 1724.988525,60.868416 1724.988525,31.000000 
   C1722.685669,31.000000 1720.882690,31.000000 1719.079712,31.000000 
   C1380.553711,31.000000 1042.027710,30.999741 703.501770,31.006760 
   C701.673401,31.006798 699.844971,31.109228 697.088928,31.017366 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.162169,150.985062 
   C31.162169,111.196114 31.162169,71.407166 31.162169,31.011492 
   C246.467346,31.011492 461.760010,31.011492 677.494446,31.430290 
   C674.914368,36.225174 671.892578,40.601261 668.164673,45.999836 
   C673.337219,45.999836 677.274719,46.293510 681.136963,45.881626 
   C683.210876,45.660469 685.605652,44.686924 687.071228,43.251858 
   C690.947998,39.455662 694.397217,35.222866 698.016602,31.163832 
   C699.844971,31.109228 701.673401,31.006798 703.501770,31.006760 
   C1042.027710,30.999741 1380.553711,31.000000 1719.079712,31.000000 
   C1720.882690,31.000000 1722.685669,31.000000 1724.988525,31.000000 
   C1724.988525,60.868416 1724.988525,90.460602 1724.454834,120.522171 
   C1708.259766,120.994324 1692.598145,120.964897 1676.936890,121.021286 
   C1672.739258,121.036392 1669.689575,119.367844 1669.017334,115.157097 
   C1668.250000,110.351440 1668.100342,105.447144 1667.617188,99.830635 
   C1685.324097,99.830635 1702.024414,99.830635 1718.621094,99.830635 
   C1718.621094,94.883118 1718.621094,90.639267 1718.621094,85.883667 
   C1701.670532,85.883667 1685.092285,85.883667 1668.440186,85.883667 
   C1668.440186,79.658813 1668.440186,73.964043 1668.440186,67.762253 
   C1687.120972,67.762253 1705.365967,67.762253 1723.675781,67.762253 
   C1723.675781,62.994442 1723.675781,58.750530 1723.675781,54.438793 
   C1698.993042,54.438793 1674.637085,54.438793 1650.015381,54.438793 
   C1650.015381,75.191818 1649.624878,95.517555 1650.168579,115.818260 
   C1650.472412,127.158836 1659.891724,134.986954 1671.355225,134.997665 
   C1689.182617,135.014328 1707.009888,135.015671 1724.837280,135.023514 
   C1724.891602,171.008163 1724.951050,206.992813 1724.997437,242.977463 
   C1725.014282,256.120636 1725.000000,269.263885 1725.000000,282.724884 
   C1723.558716,282.824615 1722.291138,282.989136 1721.023560,282.989166 
   C1268.331421,282.996368 815.639343,282.992310 362.477844,282.454895 
   C362.008453,272.356689 362.008453,262.792023 362.008453,253.448563 
   C252.476212,253.448563 143.870071,253.448563 34.976452,253.448563 
   C34.976452,263.542603 34.976452,273.189972 34.976452,282.837311 
   C33.822639,282.890594 32.668823,282.943848 31.011436,283.020355 
   C31.011436,246.233643 31.011436,209.587112 31.393986,172.688004 
   C32.180443,173.846939 32.630146,175.247192 32.981461,176.671692 
   C39.382915,202.628067 55.723209,219.516052 80.370773,229.010681 
   C100.925995,236.928848 122.206444,237.756149 143.600693,234.846970 
   C157.137726,233.006195 170.763290,230.453171 183.775635,226.385132 
   C216.501022,216.154221 247.467133,201.553986 278.127808,186.366241 
   C283.674500,199.786697 288.795288,213.005844 294.699493,225.865356 
   C296.079224,228.870483 300.010468,232.526169 302.947144,232.691238 
   C318.969086,233.591843 335.046967,233.498215 351.106079,233.713577 
   C351.313477,233.716354 351.526611,233.288895 351.959656,232.822601 
   C351.649384,231.911285 351.394775,230.804443 350.914490,229.806183 
   C342.099518,211.485184 333.227203,193.191711 324.447815,174.853745 
   C322.743439,171.293747 321.379272,167.570831 320.056061,163.628952 
   C323.848267,161.381760 327.535156,159.580170 331.021362,157.450043 
   C348.455750,146.797150 365.854126,136.084808 383.201538,125.291077 
   C384.397705,124.546814 385.855957,122.981438 385.874359,121.777763 
   C386.068481,109.078888 385.994476,96.375916 385.994476,82.770279 
   C378.243958,87.112976 371.274078,91.029411 364.293671,94.926941 
   C353.150635,101.148651 342.060333,107.468300 330.834869,113.537773 
   C321.646881,118.505623 312.294281,123.169014 303.005524,127.584923 
   C302.972504,126.870590 303.039215,126.498337 302.911835,126.212608 
   C292.546295,102.959518 282.205566,79.695038 271.673615,56.517441 
   C271.120148,55.299423 268.943054,54.126114 267.507721,54.108891 
   C252.845413,53.932922 238.178680,53.903923 223.517838,54.133091 
   C221.830734,54.159462 219.411896,55.593529 218.613480,57.068459 
   C205.217422,81.814934 192.064209,106.692657 178.769760,131.494431 
   C172.985703,142.285004 166.940186,152.935257 161.137817,163.716187 
   C157.789276,169.937820 154.688858,176.292984 151.186081,183.157715 
   C167.722900,182.588867 182.831390,179.008820 197.256500,172.366684 
   C203.670227,169.413437 209.611099,165.866562 212.854919,159.276718 
   C217.022141,150.811020 220.996567,142.248703 224.952209,133.680984 
   C230.685654,121.262695 236.327881,108.802315 242.365723,95.578636 
   C249.804642,114.019539 256.840118,131.460342 263.567688,149.069336 
   C247.772339,156.841034 232.527939,165.002930 216.724869,171.881271 
   C202.669846,177.998779 188.207214,183.391113 173.541122,187.839691 
   C155.352615,193.356720 136.652298,196.643295 117.598167,198.199326 
   C102.988503,199.392426 88.605019,199.878220 74.623672,194.932236 
   C68.144798,192.640305 60.647705,190.074814 60.283981,181.901321 
   C60.008881,175.719284 61.183266,168.974915 63.579575,163.264267 
   C71.067169,145.420609 84.443573,131.937653 99.444008,120.418442 
   C125.855400,100.136475 156.104004,89.309570 190.159546,86.685173 
   C187.339645,78.175842 185.174835,69.610306 181.647797,61.648201 
   C178.863953,55.363869 173.783600,50.361301 166.206635,50.107658 
   C160.789108,49.926304 155.174393,50.011871 149.931839,51.215794 
   C129.065964,56.007500 109.204727,63.493427 91.015648,75.000702 
   C75.182098,85.017754 60.753174,96.599937 49.579796,111.883781 
   C40.907986,123.745789 34.051163,136.419891 31.162169,150.985062 
M1291.000000,213.500000 
   C1291.000000,226.905258 1291.000000,240.310516 1291.000000,253.625275 
   C1297.780640,253.625275 1303.690674,253.625275 1310.129761,253.625275 
   C1310.129761,235.414856 1310.129761,217.571182 1310.129761,198.229874 
   C1318.728027,208.220520 1326.347656,217.084671 1333.979370,225.938354 
   C1341.425293,234.576538 1348.847778,243.235657 1356.383911,251.794510 
   C1357.284912,252.817719 1358.808105,253.828674 1360.087769,253.888412 
   C1365.192871,254.126740 1370.315674,253.986679 1375.603760,253.986679 
   C1375.603760,226.401764 1375.603760,199.384781 1375.603760,172.339050 
   C1369.627197,172.339050 1364.052490,172.339050 1357.954102,172.339050 
   C1357.954102,190.856155 1357.954102,209.042755 1357.954102,227.934616 
   C1356.789062,227.025299 1356.219971,226.713882 1355.825195,226.253220 
   C1350.128052,219.604874 1344.439941,212.948547 1338.778320,206.269897 
   C1331.623169,197.829605 1324.573730,189.297791 1317.312866,180.949844 
   C1314.550171,177.773499 1311.771973,173.415787 1308.208618,172.414108 
   C1303.014526,170.953979 1297.104736,172.039093 1291.000000,172.039093 
   C1291.000000,185.930450 1291.000000,199.215225 1291.000000,213.500000 
M1135.464844,172.991974 
   C1132.019775,172.661316 1128.577026,172.076065 1125.129272,172.045441 
   C1112.305542,171.931549 1099.480347,171.991119 1086.655640,172.010315 
   C1084.891846,172.012939 1083.128418,172.154800 1081.311035,172.234558 
   C1081.311035,199.666870 1081.311035,226.564056 1081.311035,253.570312 
   C1087.655762,253.570312 1093.682495,253.570312 1100.217651,253.570312 
   C1100.217651,244.232559 1100.217651,235.318039 1100.217651,226.007797 
   C1106.700562,226.007797 1112.668701,225.947662 1118.633301,226.064438 
   C1119.680054,226.084946 1121.043579,226.559769 1121.698120,227.313400 
   C1126.243042,232.546005 1130.579712,237.959076 1135.103882,243.210312 
   C1138.220093,246.827179 1140.979980,252.269806 1144.878906,253.345581 
   C1151.076172,255.055542 1158.091064,253.802032 1165.996826,253.802032 
   C1156.808105,243.049606 1148.566528,233.405426 1140.157227,223.564987 
   C1143.918579,221.819061 1147.394287,220.368423 1150.721313,218.632553 
   C1163.333984,212.051819 1166.142090,197.087265 1159.321777,185.937881 
   C1154.119019,177.432938 1145.422852,174.793198 1135.464844,172.991974 
M564.500122,54.000000 
   C557.416138,54.000000 550.332092,54.000000 543.383545,54.000000 
   C543.383545,81.457367 543.383545,108.033226 543.383545,134.670807 
   C549.681641,134.670807 555.591736,134.670807 562.198975,134.670807 
   C562.198975,125.316162 562.198975,116.266533 562.198975,107.000000 
   C566.892883,107.000000 570.957886,107.480881 574.860291,106.889282 
   C580.981201,105.961357 584.388550,109.376450 587.781677,113.462341 
   C593.059570,119.817696 598.444580,126.085526 603.874817,132.311447 
   C604.815491,133.389938 606.176208,134.781067 607.390015,134.828751 
   C613.784058,135.079849 620.193298,134.946381 627.859802,134.946381 
   C618.650391,124.285927 610.315674,114.638023 601.719788,104.687721 
   C604.575073,103.682312 606.874634,102.858963 609.183350,102.061783 
   C626.442383,96.102310 629.519226,74.376915 618.297424,63.701885 
   C611.540710,57.274456 603.395996,54.523605 594.492493,54.103275 
   C584.848206,53.647976 575.165771,54.000042 564.500122,54.000000 
M835.981262,240.517471 
   C843.819519,232.362839 847.164185,222.832230 847.210999,211.336136 
   C847.273682,195.959595 836.140076,182.219376 822.373108,176.986816 
   C812.848511,173.366669 802.958557,171.898209 792.784302,171.979095 
   C781.355652,172.069977 769.925659,172.000000 758.420410,172.000000 
   C758.420410,199.664948 758.420410,226.673141 758.420410,254.000000 
   C769.810913,254.000000 780.783875,254.045578 791.756287,253.989166 
   C807.574463,253.907852 822.682312,251.363220 835.981262,240.517471 
M1273.538696,129.038727 
   C1275.464600,131.005676 1277.226562,134.430359 1279.346558,134.668625 
   C1285.932861,135.408829 1292.655029,134.939590 1300.284302,134.939590 
   C1291.105591,124.267242 1282.728882,114.527489 1274.012695,104.392990 
   C1275.248047,104.175240 1275.853882,104.110023 1276.437622,103.959625 
   C1298.671631,98.231560 1301.650146,78.520927 1292.554077,65.519066 
   C1286.540405,56.923077 1276.604858,54.436100 1266.671509,54.127396 
   C1251.203125,53.646683 1235.708984,53.991447 1220.225708,54.017384 
   C1218.953003,54.019516 1217.680908,54.258587 1216.451294,54.383221 
   C1216.451294,81.395889 1216.451294,108.070702 1216.451294,134.608887 
   C1222.485474,134.608887 1228.061646,134.608887 1234.136597,134.608887 
   C1234.136597,125.315079 1234.136597,116.403069 1234.136597,107.000084 
   C1240.097656,107.000084 1245.582031,106.673660 1250.994873,107.153603 
   C1253.303467,107.358292 1256.005371,108.745949 1257.590942,110.462151 
   C1262.961182,116.274887 1267.912354,122.474907 1273.538696,129.038727 
M736.000000,113.499878 
   C736.000000,120.575729 736.000000,127.651581 736.000000,135.000000 
   C750.160522,135.000000 763.463989,135.117401 776.764343,134.965164 
   C788.730225,134.828186 799.783264,131.798492 809.807007,124.827927 
   C827.640076,112.426758 830.603943,84.393532 815.420166,68.921860 
   C805.753845,59.072178 793.544800,54.775265 780.203613,54.136944 
   C767.084778,53.509258 753.912354,53.990158 740.763489,54.014290 
   C739.322571,54.016933 737.881958,54.215225 736.000000,54.355709 
   C736.000000,73.957832 736.000000,93.228889 736.000000,113.499878 
M1538.325195,123.882324 
   C1540.531860,121.432632 1543.038574,119.190216 1544.896973,116.499931 
   C1557.717163,97.938522 1552.658081,76.487404 1537.050171,64.172592 
   C1521.509277,51.910641 1503.311279,49.433132 1484.488892,54.198227 
   C1468.356079,58.282444 1455.484741,66.934425 1450.429688,84.103615 
   C1446.157104,98.614990 1451.910889,115.394524 1463.677490,124.439995 
   C1476.707275,134.456497 1491.145142,137.729538 1507.328003,136.152908 
   C1518.762451,135.038910 1528.842529,131.426331 1538.325195,123.882324 
M1151.828247,66.003586 
   C1163.265869,64.698433 1173.066528,69.241768 1182.666626,75.422997 
   C1185.793457,71.327103 1188.547363,67.719780 1191.463013,63.900517 
   C1172.134033,51.253124 1152.153931,48.692844 1131.337769,56.477009 
   C1114.107666,62.920170 1100.017700,77.502266 1103.995972,101.294937 
   C1106.521851,116.401451 1115.717285,126.156563 1129.314941,131.867874 
   C1139.769165,136.258835 1150.723267,137.427505 1162.193726,136.179611 
   C1173.506958,134.948837 1183.729614,131.473801 1193.169312,125.372581 
   C1194.297852,124.643181 1195.821655,123.330276 1195.840820,122.264069 
   C1196.037720,111.336876 1195.961426,100.404770 1195.961426,89.449982 
   C1181.297363,89.449982 1167.275757,89.449982 1153.393555,89.449982 
   C1153.393555,93.815865 1153.393555,97.725555 1153.393555,102.144974 
   C1161.358887,102.144974 1168.943359,102.144974 1177.000000,102.144974 
   C1177.000000,105.952728 1176.772339,109.265900 1177.063354,112.532845 
   C1177.360229,115.863777 1176.086548,117.634499 1173.091919,119.038322 
   C1163.015503,123.761978 1152.735718,124.480919 1142.259399,120.922752 
   C1129.806152,116.693085 1122.641113,107.579628 1122.282349,94.727219 
   C1122.003418,84.734283 1126.620483,75.946472 1136.048950,71.130150 
   C1140.669678,68.769768 1145.942139,67.684990 1151.828247,66.003586 
M909.500000,254.000000 
   C918.915710,254.000000 928.331421,254.000000 937.613831,254.000000 
   C937.613831,248.869888 937.613831,244.625046 937.613831,240.000000 
   C935.422119,240.000000 933.614258,240.000031 931.806396,240.000015 
   C918.473328,239.999908 905.140137,240.036179 891.807251,239.982895 
   C885.973694,239.959579 881.517090,236.972778 881.129517,232.400391 
   C880.740967,227.815811 881.044189,223.172623 881.044189,217.867859 
   C898.284302,217.867859 914.988708,217.867859 931.635742,217.867859 
   C931.635742,212.912109 931.635742,208.668488 931.635742,203.907104 
   C914.696411,203.907104 898.114624,203.907104 881.432983,203.907104 
   C881.432983,197.675507 881.432983,191.980942 881.432983,185.770599 
   C899.776978,185.770599 917.693115,185.770599 935.675720,185.770599 
   C935.675720,180.995926 935.675720,176.751083 935.675720,172.446991 
   C910.987244,172.446991 886.625732,172.446991 863.012085,172.446991 
   C863.012085,193.835159 862.781555,214.775528 863.127075,235.706406 
   C863.262756,243.928284 871.019165,252.129929 879.173462,252.863724 
   C888.904236,253.739365 898.720703,253.663620 909.500000,254.000000 
M657.502014,134.223419 
   C658.951538,134.482178 660.400330,134.961624 661.850647,134.966003 
   C679.014038,135.017883 696.177734,135.007324 713.341248,134.978455 
   C714.444641,134.976593 715.547546,134.689209 716.670898,134.531799 
   C716.670898,129.985199 716.670898,125.740845 716.670898,121.000000 
   C714.553833,121.000000 712.760864,121.000008 710.967896,121.000000 
   C696.970398,120.999962 682.964050,121.261765 668.982544,120.789078 
   C666.023315,120.689026 661.388794,118.508987 660.608154,116.231049 
   C658.924988,111.319748 659.243713,105.722397 658.685730,99.794930 
   C676.755920,99.794930 693.669678,99.794930 710.689575,99.794930 
   C710.689575,95.023239 710.689575,90.779060 710.689575,85.840240 
   C693.354797,85.840240 676.319946,85.840240 659.375488,85.840240 
   C659.375488,79.553810 659.375488,73.976189 659.375488,67.888176 
   C677.991150,67.888176 696.238708,67.888176 714.559570,67.888176 
   C714.559570,62.994598 714.559570,58.631821 714.559570,54.381645 
   C689.876343,54.381645 665.635437,54.381645 641.031738,54.381645 
   C641.031738,74.563843 640.544373,94.383545 641.221252,114.163406 
   C641.604675,125.366066 647.642456,131.919815 657.502014,134.223419 
M1565.000000,213.368286 
   C1565.000000,210.420044 1565.000000,207.471786 1565.000000,203.853760 
   C1547.699829,203.853760 1531.001709,203.853760 1514.361816,203.853760 
   C1514.361816,197.579147 1514.361816,192.003098 1514.361816,185.924774 
   C1532.956787,185.924774 1551.197998,185.924774 1569.584961,185.924774 
   C1569.584961,181.042099 1569.584961,176.684921 1569.584961,172.344299 
   C1544.935913,172.344299 1520.703369,172.344299 1496.599365,172.344299 
   C1496.345215,172.983688 1496.122925,173.282593 1496.122192,173.582047 
   C1496.073486,193.566605 1495.731079,213.558167 1496.177734,233.532043 
   C1496.336060,240.616089 1499.356689,247.403122 1506.411499,250.509598 
   C1510.826416,252.453659 1515.876221,253.661133 1520.693359,253.847549 
   C1532.667603,254.310959 1544.671875,253.999985 1556.664062,254.000000 
   C1561.606934,254.000015 1566.549927,254.000000 1571.561768,254.000000 
   C1571.561768,248.996048 1571.561768,244.634842 1571.561768,239.999893 
   C1555.813354,239.999893 1540.501465,239.987411 1525.189697,240.007965 
   C1520.934570,240.013672 1516.661865,239.025848 1515.539185,234.669662 
   C1514.183838,229.410797 1514.170776,223.805984 1513.538452,217.999863 
   C1530.053589,217.999863 1545.202759,217.975998 1560.351440,218.022339 
   C1563.011108,218.030472 1565.568726,218.205536 1565.000000,213.368286 
M1243.001221,234.001541 
   C1244.503662,235.008179 1246.582520,235.678726 1247.401855,237.083740 
   C1249.987305,241.516464 1251.995361,246.282303 1254.463745,250.789352 
   C1255.168213,252.075623 1256.638184,253.767166 1257.827637,253.825058 
   C1264.063721,254.128525 1270.322266,253.966919 1277.151611,253.966919 
   C1276.508301,252.330460 1276.236084,251.405884 1275.801147,250.565521 
   C1265.898315,231.435226 1255.984741,212.310516 1246.056396,193.193497 
   C1242.927490,187.168945 1239.765015,181.161606 1236.587891,175.162277 
   C1234.731934,171.657608 1218.217163,170.322632 1215.834839,173.470016 
   C1215.536987,173.863342 1215.323730,174.323456 1215.091919,174.763474 
   C1211.848267,180.916519 1208.593384,187.063797 1205.370361,193.227600 
   C1197.771729,207.758545 1190.153076,222.279144 1182.618164,236.843002 
   C1179.844482,242.204071 1177.283447,247.675201 1174.182495,253.999542 
   C1180.038574,253.999542 1184.691406,254.307846 1189.267822,253.850708 
   C1191.149048,253.662781 1193.520142,252.241959 1194.527344,250.656906 
   C1197.006104,246.755676 1199.072754,242.536057 1200.825928,238.248611 
   C1202.169189,234.963852 1204.062500,233.848618 1207.602539,233.917023 
   C1219.096558,234.139099 1230.597656,234.000122 1243.001221,234.001541 
M617.878784,240.645676 
   C614.920349,235.100723 611.890198,229.592621 609.017090,224.003815 
   C601.144958,208.690628 593.348328,193.338684 585.490112,178.018341 
   C584.814880,176.701950 583.956604,175.469070 583.093445,174.261612 
   C581.332092,171.797501 565.097717,170.809952 563.087769,173.045197 
   C562.654480,173.527054 562.397278,174.174820 562.092651,174.763596 
   C557.073059,184.464935 552.034241,194.156479 547.048950,203.875473 
   C539.493103,218.605896 531.976746,233.356522 524.439453,248.096451 
   C523.586365,249.764786 522.707031,251.419708 521.484558,253.761475 
   C528.289551,253.761475 534.623535,255.025909 539.956177,253.262421 
   C542.908203,252.286209 544.203430,246.252731 546.208069,242.478958 
   C547.218628,240.576553 547.854126,238.384583 549.202820,236.780685 
   C550.283020,235.496140 552.170349,234.147842 553.722473,234.120239 
   C565.868225,233.904297 578.021851,233.885513 590.166260,234.136566 
   C591.803284,234.170395 594.036194,235.672745 594.900940,237.139175 
   C597.588135,241.695953 599.660645,246.610809 602.179199,251.274658 
   C602.787048,252.400330 604.165710,253.821487 605.240723,253.865448 
   C611.328003,254.114395 617.430725,253.985855 624.427551,253.985855 
   C622.060852,249.245117 620.091003,245.299149 617.878784,240.645676 
M1086.116577,108.342232 
   C1079.169678,94.768005 1072.172241,81.219345 1065.289917,67.612480 
   C1058.196045,53.587460 1058.240601,53.458950 1042.668213,54.139423 
   C1040.840820,54.219273 1038.278564,55.774178 1037.436523,57.372219 
   C1024.229736,82.435020 1011.244873,107.614807 998.254211,132.791031 
   C998.012207,133.260147 998.320679,134.013275 998.368713,134.590836 
   C1015.553284,136.662491 1017.931641,135.249466 1023.994934,119.515823 
   C1024.053955,119.362495 1024.007080,119.110374 1024.106201,119.039246 
   C1025.537842,118.013123 1026.970581,116.161507 1028.432617,116.134789 
   C1041.092896,115.903442 1053.761108,115.905556 1066.421753,116.126465 
   C1067.943237,116.153015 1070.025635,117.548668 1070.830322,118.910957 
   C1073.355469,123.186295 1075.234131,127.841469 1077.718994,132.143906 
   C1078.467285,133.439499 1080.339478,134.792450 1081.762939,134.867905 
   C1087.551758,135.174774 1093.366211,134.995590 1100.172974,134.995590 
   C1095.388794,125.901161 1090.939697,117.443855 1086.116577,108.342232 
M1466.900146,190.483093 
   C1468.643799,191.661606 1470.387329,192.840103 1472.233032,194.087555 
   C1475.287476,190.061600 1478.126221,186.320068 1480.965454,182.577896 
   C1462.123535,168.823685 1434.354736,166.926041 1414.588867,177.769073 
   C1399.538818,186.025116 1391.708984,198.634842 1393.094360,215.954712 
   C1394.296265,230.979813 1402.295776,241.772064 1415.810425,248.367981 
   C1435.813232,258.130615 1455.668335,257.303284 1475.347900,247.301498 
   C1482.616943,243.607040 1482.629639,243.638184 1478.144165,237.358139 
   C1477.666626,236.689545 1477.192627,236.011047 1476.795532,235.293564 
   C1474.813477,231.710739 1472.672974,231.815750 1469.307007,234.027664 
   C1457.535156,241.763657 1444.649902,243.513718 1431.373901,238.873749 
   C1420.375854,235.029892 1413.458252,227.102356 1412.064697,215.259491 
   C1410.890381,205.280472 1416.980103,193.657227 1427.929321,188.447037 
   C1441.136353,182.162430 1453.756714,183.353271 1466.900146,190.483093 
M1381.043823,73.455421 
   C1387.671021,68.239967 1395.294312,66.176292 1403.628418,65.960747 
   C1412.785522,65.723907 1420.695068,69.036736 1428.295288,73.690781 
   C1429.340942,74.331200 1431.932373,74.394615 1432.375000,73.746490 
   C1434.671875,70.382812 1436.513916,66.707077 1438.443726,63.102119 
   C1438.496094,63.004452 1437.792480,62.396183 1437.366089,62.165440 
   C1434.759033,60.755112 1432.212524,59.191689 1429.494385,58.035213 
   C1417.737671,53.033184 1405.616821,50.953529 1392.797119,52.935154 
   C1380.627197,54.816349 1369.358398,59.128311 1361.416870,68.391006 
   C1343.371094,89.438545 1350.493164,121.048027 1378.737549,132.044983 
   C1391.702393,137.092850 1405.038574,138.140091 1418.499390,134.847488 
   C1426.610718,132.863373 1434.522217,130.000107 1441.191040,124.125015 
   C1438.128540,120.246712 1435.295776,116.659241 1432.489014,113.104820 
   C1415.507690,122.395027 1412.611816,123.721565 1400.746094,122.870186 
   C1389.931030,122.094185 1380.601685,117.704834 1374.562988,108.075027 
   C1368.148560,97.846214 1369.764893,82.587715 1381.043823,73.455421 
M480.164368,121.590027 
   C479.703369,121.411400 479.251740,121.202126 478.780060,121.058601 
   C461.394684,115.768929 453.295563,99.061554 460.114868,83.587540 
   C466.198730,69.782394 482.219421,63.526505 497.908722,66.680367 
   C504.830292,68.071732 511.298767,71.716866 518.411194,74.510941 
   C519.637756,73.045013 521.432800,71.133125 522.961060,69.027596 
   C526.917175,63.577274 526.819641,63.636082 520.728760,60.316708 
   C507.503448,53.109173 493.493134,50.585045 478.631165,53.059116 
   C467.968872,54.834076 458.145111,58.868813 450.320251,66.324608 
   C432.401917,83.397758 434.230408,115.955101 460.320984,129.590775 
   C474.900818,137.210587 490.556946,138.634079 506.448914,134.616837 
   C514.204041,132.656448 521.822327,129.854156 528.102417,124.186234 
   C525.074768,120.304718 522.263489,116.700661 519.275757,112.870308 
   C507.652252,121.187607 495.153076,125.328621 480.164368,121.590027 
M469.498138,239.997162 
   C471.348297,243.705246 473.065826,247.489273 475.122040,251.079315 
   C475.821747,252.300949 477.342682,253.808197 478.542450,253.857071 
   C483.846222,254.073105 489.739777,255.155258 494.284576,253.235886 
   C497.286987,251.967926 498.362640,246.136780 500.299805,242.348114 
   C500.677185,241.610062 501.052307,240.870819 501.423248,240.129517 
   C511.806641,219.378906 522.193237,198.629883 532.565186,177.873581 
   C533.419800,176.163361 534.174683,174.403305 535.283203,172.000061 
   C529.836975,172.000061 525.200500,172.179321 520.586182,171.937225 
   C517.495361,171.775055 515.892944,172.834122 514.577148,175.705582 
   C507.276062,191.638977 499.763306,207.475647 492.279022,223.324661 
   C490.617004,226.844223 488.777985,230.280197 486.761566,234.266647 
   C485.593597,232.063950 484.759735,230.634140 484.056274,229.142822 
   C475.660217,211.342514 467.330383,193.510605 458.819000,175.765747 
   C458.081482,174.228119 456.242279,172.275208 454.805389,172.180786 
   C448.723328,171.781204 442.599884,172.011398 435.558105,172.011398 
   C447.007080,194.981949 458.063873,217.165695 469.498138,239.997162 
M1025.501953,186.000000 
   C1038.569458,186.000000 1051.636841,186.000000 1064.634888,186.000000 
   C1064.634888,180.905655 1064.634888,176.664368 1064.634888,172.459351 
   C1039.927856,172.459351 1015.588623,172.459351 991.369507,172.459351 
   C991.369507,199.807144 991.369507,226.694077 991.369507,253.691345 
   C997.314819,253.691345 1002.886597,253.691345 1009.146729,253.691345 
   C1009.146729,243.697189 1009.146729,233.998489 1009.146729,223.817322 
   C1025.736084,223.817322 1041.637939,223.817322 1057.714600,223.817322 
   C1057.714600,219.067673 1057.714600,214.827637 1057.714600,209.906586 
   C1041.418457,209.906586 1025.394165,209.906586 1009.325134,209.906586 
   C1009.325134,201.648026 1009.325134,194.077301 1009.325134,186.000000 
   C1014.595032,186.000000 1019.549316,186.000000 1025.501953,186.000000 
M307.103455,101.566017 
   C311.463837,104.851959 316.345703,105.051498 321.040222,102.973480 
   C329.528137,99.216331 337.950989,95.258507 346.144043,90.901352 
   C358.829651,84.155029 371.315826,77.031990 383.828400,69.965622 
   C384.784149,69.425873 385.882538,68.078369 385.895813,67.092613 
   C386.042786,56.173809 385.988342,45.252300 385.988342,33.921711 
   C384.547119,34.431747 383.605194,34.636147 382.787567,35.073193 
   C358.865417,47.860729 335.018311,60.791199 310.983337,73.362663 
   C306.897339,75.499847 302.014099,76.112793 296.458374,77.733566 
   C299.647705,85.084015 303.103638,93.048851 307.103455,101.566017 
M925.248779,68.920837 
   C927.313293,68.613892 929.374817,68.068497 931.442932,68.042114 
   C939.525879,67.938995 947.610901,68.000000 955.643005,68.000000 
   C955.643005,62.925362 955.643005,58.682606 955.643005,54.283836 
   C929.054565,54.283836 902.823730,54.283836 876.403748,54.283836 
   C876.403748,58.934212 876.403748,63.291313 876.403748,68.164505 
   C886.712830,68.164505 896.626770,68.164505 907.210388,68.164505 
   C907.210388,90.701775 907.210388,112.728149 907.210388,134.608490 
   C913.483093,134.608490 919.060852,134.608490 925.050720,134.608490 
   C925.050720,112.758018 925.050720,91.297005 925.248779,68.920837 
M689.499512,254.000000 
   C695.583435,254.000000 701.667358,254.000000 707.616943,254.000000 
   C707.616943,248.874542 707.616943,244.630539 707.616943,239.874359 
   C690.661499,239.874359 674.082764,239.874359 656.817322,239.874359 
   C656.817322,216.996231 656.817322,194.630341 656.817322,172.384583 
   C650.534424,172.384583 644.955505,172.384583 639.322510,172.384583 
   C639.322510,199.674973 639.322510,226.579575 639.322510,254.000000 
   C655.926331,254.000000 672.213013,254.000000 689.499512,254.000000 
M1569.007935,56.771191 
   C1569.007935,82.700592 1569.007935,108.629997 1569.007935,134.697998 
   C1592.292603,134.697998 1614.868530,134.697998 1637.574829,134.697998 
   C1637.574829,130.024948 1637.574829,125.665703 1637.574829,120.786911 
   C1620.573242,120.786911 1603.991577,120.786911 1586.896973,120.786911 
   C1586.896973,98.352394 1586.896973,76.438080 1586.896973,54.043530 
   C1581.256714,54.043530 1576.131836,53.978237 1571.012695,54.122841 
   C1570.342773,54.141769 1569.703247,55.237720 1569.007935,56.771191 
M842.248596,134.078644 
   C848.041199,134.483643 853.833801,134.888641 859.690369,135.298111 
   C859.690369,107.631721 859.690369,81.086433 859.690369,54.422340 
   C853.685059,54.422340 847.996460,54.422340 842.050659,54.422340 
   C842.050659,80.950249 842.050659,107.056908 842.248596,134.078644 
M1335.000000,93.500000 
   C1335.000000,80.441185 1335.000000,67.382370 1335.000000,54.346504 
   C1328.600098,54.346504 1323.030518,54.346504 1317.411865,54.346504 
   C1317.411865,81.351761 1317.411865,108.008507 1317.411865,134.653900 
   C1323.399170,134.653900 1328.968750,134.653900 1335.000000,134.653900 
   C1335.000000,121.042068 1335.000000,107.771034 1335.000000,93.500000 
z"
      />
      <path
        fill="#009597"
        opacity="1.000000"
        stroke="none"
        d="
M277.987640,186.033783 
   C247.467133,201.553986 216.501022,216.154221 183.775635,226.385132 
   C170.763290,230.453171 157.137726,233.006195 143.600693,234.846970 
   C122.206444,237.756149 100.925995,236.928848 80.370773,229.010681 
   C55.723209,219.516052 39.382915,202.628067 32.981461,176.671692 
   C32.630146,175.247192 32.180443,173.846939 31.469191,172.225723 
   C31.114969,165.314240 31.068096,158.612442 31.091694,151.447845 
   C34.051163,136.419891 40.907986,123.745789 49.579796,111.883781 
   C60.753174,96.599937 75.182098,85.017754 91.015648,75.000702 
   C109.204727,63.493427 129.065964,56.007500 149.931839,51.215794 
   C155.174393,50.011871 160.789108,49.926304 166.206635,50.107658 
   C173.783600,50.361301 178.863953,55.363869 181.647797,61.648201 
   C185.174835,69.610306 187.339645,78.175842 190.159546,86.685173 
   C156.104004,89.309570 125.855400,100.136475 99.444008,120.418442 
   C84.443573,131.937653 71.067169,145.420609 63.579575,163.264267 
   C61.183266,168.974915 60.008881,175.719284 60.283981,181.901321 
   C60.647705,190.074814 68.144798,192.640305 74.623672,194.932236 
   C88.605019,199.878220 102.988503,199.392426 117.598167,198.199326 
   C136.652298,196.643295 155.352615,193.356720 173.541122,187.839691 
   C188.207214,183.391113 202.669846,177.998779 216.724869,171.881271 
   C232.527939,165.002930 247.772339,156.841034 263.938843,149.075592 
   C276.067566,143.088440 287.538910,137.305054 298.941162,131.388519 
   C300.477173,130.591492 301.665375,129.124252 303.014648,127.967377 
   C312.294281,123.169014 321.646881,118.505623 330.834869,113.537773 
   C342.060333,107.468300 353.150635,101.148651 364.293671,94.926941 
   C371.274078,91.029411 378.243958,87.112976 385.994476,82.770279 
   C385.994476,96.375916 386.068481,109.078888 385.874359,121.777763 
   C385.855957,122.981438 384.397705,124.546814 383.201538,125.291077 
   C365.854126,136.084808 348.455750,146.797150 331.021362,157.450043 
   C327.535156,159.580170 323.848267,161.381760 319.688538,163.659821 
   C318.036896,164.339417 316.873627,164.547394 315.876007,165.070999 
   C303.911896,171.350357 291.964569,177.661850 280.043762,184.022827 
   C279.231873,184.456070 278.667236,185.352692 277.987640,186.033783 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M35.440788,282.910065 
   C34.976452,273.189972 34.976452,263.542603 34.976452,253.448563 
   C143.870071,253.448563 252.476212,253.448563 362.008453,253.448563 
   C362.008453,262.792023 362.008453,272.356689 362.016113,282.379333 
   C360.862244,282.891571 359.700714,282.993195 358.539185,282.993195 
   C250.994492,282.994720 143.449814,282.988525 35.440788,282.910065 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1724.910034,134.562805 
   C1707.009888,135.015671 1689.182617,135.014328 1671.355225,134.997665 
   C1659.891724,134.986954 1650.472412,127.158836 1650.168579,115.818260 
   C1649.624878,95.517555 1650.015381,75.191818 1650.015381,54.438793 
   C1674.637085,54.438793 1698.993042,54.438793 1723.675781,54.438793 
   C1723.675781,58.750530 1723.675781,62.994442 1723.675781,67.762253 
   C1705.365967,67.762253 1687.120972,67.762253 1668.440186,67.762253 
   C1668.440186,73.964043 1668.440186,79.658813 1668.440186,85.883667 
   C1685.092285,85.883667 1701.670532,85.883667 1718.621094,85.883667 
   C1718.621094,90.639267 1718.621094,94.883118 1718.621094,99.830635 
   C1702.024414,99.830635 1685.324097,99.830635 1667.617188,99.830635 
   C1668.100342,105.447144 1668.250000,110.351440 1669.017334,115.157097 
   C1669.689575,119.367844 1672.739258,121.036392 1676.936890,121.021286 
   C1692.598145,120.964897 1708.259766,120.994324 1724.379272,120.983902 
   C1724.885742,125.351532 1724.934326,129.726822 1724.910034,134.562805 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M697.552734,31.090595 
   C694.397217,35.222866 690.947998,39.455662 687.071228,43.251858 
   C685.605652,44.686924 683.210876,45.660469 681.136963,45.881626 
   C677.274719,46.293510 673.337219,45.999836 668.164673,45.999836 
   C671.892578,40.601261 674.914368,36.225174 677.956177,31.505867 
   C684.347107,31.114218 690.718018,31.065792 697.552734,31.090595 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M303.005524,127.584915 
   C301.665375,129.124252 300.477173,130.591492 298.941162,131.388519 
   C287.538910,137.305054 276.067566,143.088440 264.246765,148.907410 
   C256.840118,131.460342 249.804642,114.019539 242.365723,95.578636 
   C236.327881,108.802315 230.685654,121.262695 224.952209,133.680984 
   C220.996567,142.248703 217.022141,150.811020 212.854919,159.276718 
   C209.611099,165.866562 203.670227,169.413437 197.256500,172.366684 
   C182.831390,179.008820 167.722900,182.588867 151.186081,183.157715 
   C154.688858,176.292984 157.789276,169.937820 161.137817,163.716187 
   C166.940186,152.935257 172.985703,142.285004 178.769760,131.494431 
   C192.064209,106.692657 205.217422,81.814934 218.613480,57.068459 
   C219.411896,55.593529 221.830734,54.159462 223.517838,54.133091 
   C238.178680,53.903923 252.845413,53.932922 267.507721,54.108891 
   C268.943054,54.126114 271.120148,55.299423 271.673615,56.517441 
   C282.205566,79.695038 292.546295,102.959518 302.911835,126.212608 
   C303.039215,126.498337 302.972504,126.870590 303.005524,127.584915 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1291.000000,213.000000 
   C1291.000000,199.215225 1291.000000,185.930450 1291.000000,172.039093 
   C1297.104736,172.039093 1303.014526,170.953979 1308.208618,172.414108 
   C1311.771973,173.415787 1314.550171,177.773499 1317.312866,180.949844 
   C1324.573730,189.297791 1331.623169,197.829605 1338.778320,206.269897 
   C1344.439941,212.948547 1350.128052,219.604874 1355.825195,226.253220 
   C1356.219971,226.713882 1356.789062,227.025299 1357.954102,227.934616 
   C1357.954102,209.042755 1357.954102,190.856155 1357.954102,172.339050 
   C1364.052490,172.339050 1369.627197,172.339050 1375.603760,172.339050 
   C1375.603760,199.384781 1375.603760,226.401764 1375.603760,253.986679 
   C1370.315674,253.986679 1365.192871,254.126740 1360.087769,253.888412 
   C1358.808105,253.828674 1357.284912,252.817719 1356.383911,251.794510 
   C1348.847778,243.235657 1341.425293,234.576538 1333.979370,225.938354 
   C1326.347656,217.084671 1318.728027,208.220520 1310.129761,198.229874 
   C1310.129761,217.571182 1310.129761,235.414856 1310.129761,253.625275 
   C1303.690674,253.625275 1297.780640,253.625275 1291.000000,253.625275 
   C1291.000000,240.310516 1291.000000,226.905258 1291.000000,213.000000 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1135.902344,172.997559 
   C1145.422852,174.793198 1154.119019,177.432938 1159.321777,185.937881 
   C1166.142090,197.087265 1163.333984,212.051819 1150.721313,218.632553 
   C1147.394287,220.368423 1143.918579,221.819061 1140.157227,223.564987 
   C1148.566528,233.405426 1156.808105,243.049606 1165.996826,253.802032 
   C1158.091064,253.802032 1151.076172,255.055542 1144.878906,253.345581 
   C1140.979980,252.269806 1138.220093,246.827179 1135.103882,243.210312 
   C1130.579712,237.959076 1126.243042,232.546005 1121.698120,227.313400 
   C1121.043579,226.559769 1119.680054,226.084946 1118.633301,226.064438 
   C1112.668701,225.947662 1106.700562,226.007797 1100.217651,226.007797 
   C1100.217651,235.318039 1100.217651,244.232559 1100.217651,253.570312 
   C1093.682495,253.570312 1087.655762,253.570312 1081.311035,253.570312 
   C1081.311035,226.564056 1081.311035,199.666870 1081.311035,172.234558 
   C1083.128418,172.154800 1084.891846,172.012939 1086.655640,172.010315 
   C1099.480347,171.991119 1112.305542,171.931549 1125.129272,172.045441 
   C1128.577026,172.076065 1132.019775,172.661316 1135.902344,172.997559 
M1137.935425,208.542816 
   C1141.967285,206.372849 1144.076416,202.621017 1143.771851,198.432083 
   C1143.388428,193.155930 1140.163086,189.493912 1134.808716,187.821991 
   C1123.420166,184.265762 1111.887451,186.827652 1100.303955,185.902527 
   C1100.303955,194.981415 1100.303955,203.215225 1100.303955,212.000000 
   C1108.372437,212.000000 1116.169678,212.353775 1123.913696,211.864441 
   C1128.406372,211.580551 1132.813477,209.941483 1137.935425,208.542816 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M565.000061,54.000000 
   C575.165771,54.000042 584.848206,53.647976 594.492493,54.103275 
   C603.395996,54.523605 611.540710,57.274456 618.297424,63.701885 
   C629.519226,74.376915 626.442383,96.102310 609.183350,102.061783 
   C606.874634,102.858963 604.575073,103.682312 601.719788,104.687721 
   C610.315674,114.638023 618.650391,124.285927 627.859802,134.946381 
   C620.193298,134.946381 613.784058,135.079849 607.390015,134.828751 
   C606.176208,134.781067 604.815491,133.389938 603.874817,132.311447 
   C598.444580,126.085526 593.059570,119.817696 587.781677,113.462341 
   C584.388550,109.376450 580.981201,105.961357 574.860291,106.889282 
   C570.957886,107.480881 566.892883,107.000000 562.198975,107.000000 
   C562.198975,116.266533 562.198975,125.316162 562.198975,134.670807 
   C555.591736,134.670807 549.681641,134.670807 543.383545,134.670807 
   C543.383545,108.033226 543.383545,81.457367 543.383545,54.000000 
   C550.332092,54.000000 557.416138,54.000000 565.000061,54.000000 
M562.000000,82.485596 
   C562.000000,86.222076 562.000000,89.958565 562.000000,94.495224 
   C573.083923,93.949608 583.322815,93.732368 593.502808,92.841705 
   C600.080383,92.266235 605.964478,85.666893 605.962585,79.999977 
   C605.960876,75.091110 601.324890,69.528725 594.510498,69.000175 
   C583.897827,68.177025 573.174377,68.782455 562.000000,68.782455 
   C562.000000,72.936661 562.000000,77.216454 562.000000,82.485596 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M835.721924,240.766235 
   C822.682312,251.363220 807.574463,253.907852 791.756287,253.989166 
   C780.783875,254.045578 769.810913,254.000000 758.420410,254.000000 
   C758.420410,226.673141 758.420410,199.664948 758.420410,172.000000 
   C769.925659,172.000000 781.355652,172.069977 792.784302,171.979095 
   C802.958557,171.898209 812.848511,173.366669 822.373108,176.986816 
   C836.140076,182.219376 847.273682,195.959595 847.210999,211.336136 
   C847.164185,222.832230 843.819519,232.362839 835.721924,240.766235 
M827.997681,208.905457 
   C826.306091,198.800430 820.196899,192.555588 810.897644,188.843246 
   C800.000244,184.492950 788.752991,186.412292 777.390015,186.051468 
   C777.390015,204.255875 777.390015,221.937836 777.390015,239.995972 
   C784.577515,239.995972 791.408752,240.393005 798.178894,239.918259 
   C815.445068,238.707474 830.261475,228.569046 827.997681,208.905457 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1273.281128,128.782837 
   C1267.912354,122.474907 1262.961182,116.274887 1257.590942,110.462151 
   C1256.005371,108.745949 1253.303467,107.358292 1250.994873,107.153603 
   C1245.582031,106.673660 1240.097656,107.000084 1234.136597,107.000084 
   C1234.136597,116.403069 1234.136597,125.315079 1234.136597,134.608887 
   C1228.061646,134.608887 1222.485474,134.608887 1216.451294,134.608887 
   C1216.451294,108.070702 1216.451294,81.395889 1216.451294,54.383221 
   C1217.680908,54.258587 1218.953003,54.019516 1220.225708,54.017384 
   C1235.708984,53.991447 1251.203125,53.646683 1266.671509,54.127396 
   C1276.604858,54.436100 1286.540405,56.923077 1292.554077,65.519066 
   C1301.650146,78.520927 1298.671631,98.231560 1276.437622,103.959625 
   C1275.853882,104.110023 1275.248047,104.175240 1274.012695,104.392990 
   C1282.728882,114.527489 1291.105591,124.267242 1300.284302,134.939590 
   C1292.655029,134.939590 1285.932861,135.408829 1279.346558,134.668625 
   C1277.226562,134.430359 1275.464600,131.005676 1273.281128,128.782837 
M1252.492920,68.000000 
   C1246.416260,68.000000 1240.339478,68.000000 1234.365479,68.000000 
   C1234.365479,77.106934 1234.365479,85.342964 1234.365479,93.782295 
   C1236.188965,93.865616 1237.658569,93.987587 1239.128418,93.990509 
   C1245.784668,94.003754 1252.502441,94.531311 1259.076416,93.791397 
   C1263.826660,93.256744 1268.813477,91.762947 1272.936768,89.383507 
   C1280.422363,85.063866 1279.526978,74.599815 1271.975952,70.417961 
   C1266.051270,67.136765 1259.722168,68.221794 1252.492920,68.000000 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M736.000000,112.999916 
   C736.000000,93.228889 736.000000,73.957832 736.000000,54.355709 
   C737.881958,54.215225 739.322571,54.016933 740.763489,54.014290 
   C753.912354,53.990158 767.084778,53.509258 780.203613,54.136944 
   C793.544800,54.775265 805.753845,59.072178 815.420166,68.921860 
   C830.603943,84.393532 827.640076,112.426758 809.807007,124.827927 
   C799.783264,131.798492 788.730225,134.828186 776.764343,134.965164 
   C763.463989,135.117401 750.160522,135.000000 736.000000,135.000000 
   C736.000000,127.651581 736.000000,120.575729 736.000000,112.999916 
M754.000000,77.540520 
   C754.000000,91.931213 754.000000,106.321907 754.000000,121.000000 
   C761.503296,121.000000 768.219055,121.606880 774.769104,120.825310 
   C781.027283,120.078575 787.535278,118.783005 793.177734,116.124657 
   C802.131653,111.906136 806.284912,103.875061 805.948914,93.947868 
   C805.632568,84.599510 801.603699,77.488617 793.247009,72.526131 
   C780.788940,65.128159 767.476074,69.093063 754.000000,67.923607 
   C754.000000,71.302933 754.000000,73.928886 754.000000,77.540520 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1537.985718,124.005989 
   C1528.842529,131.426331 1518.762451,135.038910 1507.328003,136.152908 
   C1491.145142,137.729538 1476.707275,134.456497 1463.677490,124.439995 
   C1451.910889,115.394524 1446.157104,98.614990 1450.429688,84.103615 
   C1455.484741,66.934425 1468.356079,58.282444 1484.488892,54.198227 
   C1503.311279,49.433132 1521.509277,51.910641 1537.050171,64.172592 
   C1552.658081,76.487404 1557.717163,97.938522 1544.896973,116.499931 
   C1543.038574,119.190216 1540.531860,121.432632 1537.985718,124.005989 
M1518.848389,70.442528 
   C1507.858276,65.096703 1496.767334,64.629593 1485.244019,68.979652 
   C1469.907715,74.769119 1463.088745,95.025848 1472.172974,108.695076 
   C1481.301147,122.430122 1500.596313,126.910637 1516.400757,119.327301 
   C1526.236694,114.607780 1532.030884,106.988106 1532.853882,96.347382 
   C1533.701660,85.386078 1528.664795,76.882195 1518.848389,70.442528 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1151.373901,66.013237 
   C1145.942139,67.684990 1140.669678,68.769768 1136.048950,71.130150 
   C1126.620483,75.946472 1122.003418,84.734283 1122.282349,94.727219 
   C1122.641113,107.579628 1129.806152,116.693085 1142.259399,120.922752 
   C1152.735718,124.480919 1163.015503,123.761978 1173.091919,119.038322 
   C1176.086548,117.634499 1177.360229,115.863777 1177.063354,112.532845 
   C1176.772339,109.265900 1177.000000,105.952728 1177.000000,102.144974 
   C1168.943359,102.144974 1161.358887,102.144974 1153.393555,102.144974 
   C1153.393555,97.725555 1153.393555,93.815865 1153.393555,89.449982 
   C1167.275757,89.449982 1181.297363,89.449982 1195.961426,89.449982 
   C1195.961426,100.404770 1196.037720,111.336876 1195.840820,122.264069 
   C1195.821655,123.330276 1194.297852,124.643181 1193.169312,125.372581 
   C1183.729614,131.473801 1173.506958,134.948837 1162.193726,136.179611 
   C1150.723267,137.427505 1139.769165,136.258835 1129.314941,131.867874 
   C1115.717285,126.156563 1106.521851,116.401451 1103.995972,101.294937 
   C1100.017700,77.502266 1114.107666,62.920170 1131.337769,56.477009 
   C1152.153931,48.692844 1172.134033,51.253124 1191.463013,63.900517 
   C1188.547363,67.719780 1185.793457,71.327103 1182.666626,75.422997 
   C1173.066528,69.241768 1163.265869,64.698433 1151.373901,66.013237 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M909.000000,254.000000 
   C898.720703,253.663620 888.904236,253.739365 879.173462,252.863724 
   C871.019165,252.129929 863.262756,243.928284 863.127075,235.706406 
   C862.781555,214.775528 863.012085,193.835159 863.012085,172.446991 
   C886.625732,172.446991 910.987244,172.446991 935.675720,172.446991 
   C935.675720,176.751083 935.675720,180.995926 935.675720,185.770599 
   C917.693115,185.770599 899.776978,185.770599 881.432983,185.770599 
   C881.432983,191.980942 881.432983,197.675507 881.432983,203.907104 
   C898.114624,203.907104 914.696411,203.907104 931.635742,203.907104 
   C931.635742,208.668488 931.635742,212.912109 931.635742,217.867859 
   C914.988708,217.867859 898.284302,217.867859 881.044189,217.867859 
   C881.044189,223.172623 880.740967,227.815811 881.129517,232.400391 
   C881.517090,236.972778 885.973694,239.959579 891.807251,239.982895 
   C905.140137,240.036179 918.473328,239.999908 931.806396,240.000015 
   C933.614258,240.000031 935.422119,240.000000 937.613831,240.000000 
   C937.613831,244.625046 937.613831,248.869888 937.613831,254.000000 
   C928.331421,254.000000 918.915710,254.000000 909.000000,254.000000 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M657.088135,134.141663 
   C647.642456,131.919815 641.604675,125.366066 641.221252,114.163406 
   C640.544373,94.383545 641.031738,74.563843 641.031738,54.381645 
   C665.635437,54.381645 689.876343,54.381645 714.559570,54.381645 
   C714.559570,58.631821 714.559570,62.994598 714.559570,67.888176 
   C696.238708,67.888176 677.991150,67.888176 659.375488,67.888176 
   C659.375488,73.976189 659.375488,79.553810 659.375488,85.840240 
   C676.319946,85.840240 693.354797,85.840240 710.689575,85.840240 
   C710.689575,90.779060 710.689575,95.023239 710.689575,99.794930 
   C693.669678,99.794930 676.755920,99.794930 658.685730,99.794930 
   C659.243713,105.722397 658.924988,111.319748 660.608154,116.231049 
   C661.388794,118.508987 666.023315,120.689026 668.982544,120.789078 
   C682.964050,121.261765 696.970398,120.999962 710.967896,121.000000 
   C712.760864,121.000008 714.553833,121.000000 716.670898,121.000000 
   C716.670898,125.740845 716.670898,129.985199 716.670898,134.531799 
   C715.547546,134.689209 714.444641,134.976593 713.341248,134.978455 
   C696.177734,135.007324 679.014038,135.017883 661.850647,134.966003 
   C660.400330,134.961624 658.951538,134.482178 657.088135,134.141663 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1564.999268,213.837952 
   C1565.568726,218.205536 1563.011108,218.030472 1560.351440,218.022339 
   C1545.202759,217.975998 1530.053589,217.999863 1513.538452,217.999863 
   C1514.170776,223.805984 1514.183838,229.410797 1515.539185,234.669662 
   C1516.661865,239.025848 1520.934570,240.013672 1525.189697,240.007965 
   C1540.501465,239.987411 1555.813354,239.999893 1571.561768,239.999893 
   C1571.561768,244.634842 1571.561768,248.996048 1571.561768,254.000000 
   C1566.549927,254.000000 1561.606934,254.000015 1556.664062,254.000000 
   C1544.671875,253.999985 1532.667603,254.310959 1520.693359,253.847549 
   C1515.876221,253.661133 1510.826416,252.453659 1506.411499,250.509598 
   C1499.356689,247.403122 1496.336060,240.616089 1496.177734,233.532043 
   C1495.731079,213.558167 1496.073486,193.566605 1496.122192,173.582047 
   C1496.122925,173.282593 1496.345215,172.983688 1496.599365,172.344299 
   C1520.703369,172.344299 1544.935913,172.344299 1569.584961,172.344299 
   C1569.584961,176.684921 1569.584961,181.042099 1569.584961,185.924774 
   C1551.197998,185.924774 1532.956787,185.924774 1514.361816,185.924774 
   C1514.361816,192.003098 1514.361816,197.579147 1514.361816,203.853760 
   C1531.001709,203.853760 1547.699829,203.853760 1565.000000,203.853760 
   C1565.000000,207.471786 1565.000000,210.420044 1564.999268,213.837952 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1242.548584,234.000854 
   C1230.597656,234.000122 1219.096558,234.139099 1207.602539,233.917023 
   C1204.062500,233.848618 1202.169189,234.963852 1200.825928,238.248611 
   C1199.072754,242.536057 1197.006104,246.755676 1194.527344,250.656906 
   C1193.520142,252.241959 1191.149048,253.662781 1189.267822,253.850708 
   C1184.691406,254.307846 1180.038574,253.999542 1174.182495,253.999542 
   C1177.283447,247.675201 1179.844482,242.204071 1182.618164,236.843002 
   C1190.153076,222.279144 1197.771729,207.758545 1205.370361,193.227600 
   C1208.593384,187.063797 1211.848267,180.916519 1215.091919,174.763474 
   C1215.323730,174.323456 1215.536987,173.863342 1215.834839,173.470016 
   C1218.217163,170.322632 1234.731934,171.657608 1236.587891,175.162277 
   C1239.765015,181.161606 1242.927490,187.168945 1246.056396,193.193497 
   C1255.984741,212.310516 1265.898315,231.435226 1275.801147,250.565521 
   C1276.236084,251.405884 1276.508301,252.330460 1277.151611,253.966919 
   C1270.322266,253.966919 1264.063721,254.128525 1257.827637,253.825058 
   C1256.638184,253.767166 1255.168213,252.075623 1254.463745,250.789352 
   C1251.995361,246.282303 1249.987305,241.516464 1247.401855,237.083740 
   C1246.582520,235.678726 1244.503662,235.008179 1242.548584,234.000854 
M1227.470093,221.000000 
   C1231.195801,221.000000 1234.921387,221.000000 1239.482422,221.000000 
   C1234.236572,210.514130 1229.485229,201.016571 1224.475220,191.002151 
   C1219.415894,201.191757 1214.668823,210.752518 1209.580688,221.000000 
   C1215.869873,221.000000 1221.176147,221.000000 1227.470093,221.000000 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M617.999939,240.999420 
   C620.091003,245.299149 622.060852,249.245117 624.427551,253.985855 
   C617.430725,253.985855 611.328003,254.114395 605.240723,253.865448 
   C604.165710,253.821487 602.787048,252.400330 602.179199,251.274658 
   C599.660645,246.610809 597.588135,241.695953 594.900940,237.139175 
   C594.036194,235.672745 591.803284,234.170395 590.166260,234.136566 
   C578.021851,233.885513 565.868225,233.904297 553.722473,234.120239 
   C552.170349,234.147842 550.283020,235.496140 549.202820,236.780685 
   C547.854126,238.384583 547.218628,240.576553 546.208069,242.478958 
   C544.203430,246.252731 542.908203,252.286209 539.956177,253.262421 
   C534.623535,255.025909 528.289551,253.761475 521.484558,253.761475 
   C522.707031,251.419708 523.586365,249.764786 524.439453,248.096451 
   C531.976746,233.356522 539.493103,218.605896 547.048950,203.875473 
   C552.034241,194.156479 557.073059,184.464935 562.092651,174.763596 
   C562.397278,174.174820 562.654480,173.527054 563.087769,173.045197 
   C565.097717,170.809952 581.332092,171.797501 583.093445,174.261612 
   C583.956604,175.469070 584.814880,176.701950 585.490112,178.018341 
   C593.348328,193.338684 601.144958,208.690628 609.017090,224.003815 
   C611.890198,229.592621 614.920349,235.100723 617.999939,240.999420 
M566.527222,201.959976 
   C563.466492,208.127701 560.405823,214.295410 557.238586,220.677856 
   C567.306152,220.677856 576.777649,220.677856 586.833740,220.677856 
   C581.741699,210.526947 576.898682,200.872467 571.614014,190.337662 
   C569.748169,194.671921 568.322205,197.984436 566.527222,201.959976 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1086.303589,108.664391 
   C1090.939697,117.443855 1095.388794,125.901161 1100.172974,134.995590 
   C1093.366211,134.995590 1087.551758,135.174774 1081.762939,134.867905 
   C1080.339478,134.792450 1078.467285,133.439499 1077.718994,132.143906 
   C1075.234131,127.841469 1073.355469,123.186295 1070.830322,118.910957 
   C1070.025635,117.548668 1067.943237,116.153015 1066.421753,116.126465 
   C1053.761108,115.905556 1041.092896,115.903442 1028.432617,116.134789 
   C1026.970581,116.161507 1025.537842,118.013123 1024.106201,119.039246 
   C1024.007080,119.110374 1024.053955,119.362495 1023.994934,119.515823 
   C1017.931641,135.249466 1015.553284,136.662491 998.368713,134.590836 
   C998.320679,134.013275 998.012207,133.260147 998.254211,132.791031 
   C1011.244873,107.614807 1024.229736,82.435020 1037.436523,57.372219 
   C1038.278564,55.774178 1040.840820,54.219273 1042.668213,54.139423 
   C1058.240601,53.458950 1058.196045,53.587460 1065.289917,67.612480 
   C1072.172241,81.219345 1079.169678,94.768005 1086.303589,108.664391 
M1060.539673,98.060143 
   C1056.261353,89.535568 1051.983032,81.011002 1047.394653,71.868568 
   C1042.533203,82.135490 1037.961060,91.791145 1033.242554,101.756004 
   C1043.042114,101.756004 1052.260986,101.756004 1061.917480,101.756004 
   C1061.503296,100.516441 1061.206299,99.628036 1060.539673,98.060143 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M278.127808,186.366241 
   C278.667236,185.352692 279.231873,184.456070 280.043762,184.022827 
   C291.964569,177.661850 303.911896,171.350357 315.876007,165.070999 
   C316.873627,164.547394 318.036896,164.339417 319.490723,163.953918 
   C321.379272,167.570831 322.743439,171.293747 324.447815,174.853745 
   C333.227203,193.191711 342.099518,211.485184 350.914490,229.806183 
   C351.394775,230.804443 351.649384,231.911285 351.959656,232.822601 
   C351.526611,233.288895 351.313477,233.716354 351.106079,233.713577 
   C335.046967,233.498215 318.969086,233.591843 302.947144,232.691238 
   C300.010468,232.526169 296.079224,228.870483 294.699493,225.865356 
   C288.795288,213.005844 283.674500,199.786697 278.127808,186.366241 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1466.583984,190.292068 
   C1453.756714,183.353271 1441.136353,182.162430 1427.929321,188.447037 
   C1416.980103,193.657227 1410.890381,205.280472 1412.064697,215.259491 
   C1413.458252,227.102356 1420.375854,235.029892 1431.373901,238.873749 
   C1444.649902,243.513718 1457.535156,241.763657 1469.307007,234.027664 
   C1472.672974,231.815750 1474.813477,231.710739 1476.795532,235.293564 
   C1477.192627,236.011047 1477.666626,236.689545 1478.144165,237.358139 
   C1482.629639,243.638184 1482.616943,243.607040 1475.347900,247.301498 
   C1455.668335,257.303284 1435.813232,258.130615 1415.810425,248.367981 
   C1402.295776,241.772064 1394.296265,230.979813 1393.094360,215.954712 
   C1391.708984,198.634842 1399.538818,186.025116 1414.588867,177.769073 
   C1434.354736,166.926041 1462.123535,168.823685 1480.965454,182.577896 
   C1478.126221,186.320068 1475.287476,190.061600 1472.233032,194.087555 
   C1470.387329,192.840103 1468.643799,191.661606 1466.583984,190.292068 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1380.788940,73.712875 
   C1369.764893,82.587715 1368.148560,97.846214 1374.562988,108.075027 
   C1380.601685,117.704834 1389.931030,122.094185 1400.746094,122.870186 
   C1412.611816,123.721565 1415.507690,122.395027 1432.489014,113.104820 
   C1435.295776,116.659241 1438.128540,120.246712 1441.191040,124.125015 
   C1434.522217,130.000107 1426.610718,132.863373 1418.499390,134.847488 
   C1405.038574,138.140091 1391.702393,137.092850 1378.737549,132.044983 
   C1350.493164,121.048027 1343.371094,89.438545 1361.416870,68.391006 
   C1369.358398,59.128311 1380.627197,54.816349 1392.797119,52.935154 
   C1405.616821,50.953529 1417.737671,53.033184 1429.494385,58.035213 
   C1432.212524,59.191689 1434.759033,60.755112 1437.366089,62.165440 
   C1437.792480,62.396183 1438.496094,63.004452 1438.443726,63.102119 
   C1436.513916,66.707077 1434.671875,70.382812 1432.375000,73.746490 
   C1431.932373,74.394615 1429.340942,74.331200 1428.295288,73.690781 
   C1420.695068,69.036736 1412.785522,65.723907 1403.628418,65.960747 
   C1395.294312,66.176292 1387.671021,68.239967 1380.788940,73.712875 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M480.552795,121.738632 
   C495.153076,125.328621 507.652252,121.187607 519.275757,112.870308 
   C522.263489,116.700661 525.074768,120.304718 528.102417,124.186234 
   C521.822327,129.854156 514.204041,132.656448 506.448914,134.616837 
   C490.556946,138.634079 474.900818,137.210587 460.320984,129.590775 
   C434.230408,115.955101 432.401917,83.397758 450.320251,66.324608 
   C458.145111,58.868813 467.968872,54.834076 478.631165,53.059116 
   C493.493134,50.585045 507.503448,53.109173 520.728760,60.316708 
   C526.819641,63.636082 526.917175,63.577274 522.961060,69.027596 
   C521.432800,71.133125 519.637756,73.045013 518.411194,74.510941 
   C511.298767,71.716866 504.830292,68.071732 497.908722,66.680367 
   C482.219421,63.526505 466.198730,69.782394 460.114868,83.587540 
   C453.295563,99.061554 461.394684,115.768929 478.780060,121.058601 
   C479.251740,121.202126 479.703369,121.411400 480.552795,121.738632 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M469.309418,239.673309 
   C458.063873,217.165695 447.007080,194.981949 435.558105,172.011398 
   C442.599884,172.011398 448.723328,171.781204 454.805389,172.180786 
   C456.242279,172.275208 458.081482,174.228119 458.819000,175.765747 
   C467.330383,193.510605 475.660217,211.342514 484.056274,229.142822 
   C484.759735,230.634140 485.593597,232.063950 486.761566,234.266647 
   C488.777985,230.280197 490.617004,226.844223 492.279022,223.324661 
   C499.763306,207.475647 507.276062,191.638977 514.577148,175.705582 
   C515.892944,172.834122 517.495361,171.775055 520.586182,171.937225 
   C525.200500,172.179321 529.836975,172.000061 535.283203,172.000061 
   C534.174683,174.403305 533.419800,176.163361 532.565186,177.873581 
   C522.193237,198.629883 511.806641,219.378906 501.423248,240.129517 
   C501.052307,240.870819 500.677185,241.610062 500.299805,242.348114 
   C498.362640,246.136780 497.286987,251.967926 494.284576,253.235886 
   C489.739777,255.155258 483.846222,254.073105 478.542450,253.857071 
   C477.342682,253.808197 475.821747,252.300949 475.122040,251.079315 
   C473.065826,247.489273 471.348297,243.705246 469.309418,239.673309 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1025.002686,186.000000 
   C1019.549316,186.000000 1014.595032,186.000000 1009.325134,186.000000 
   C1009.325134,194.077301 1009.325134,201.648026 1009.325134,209.906586 
   C1025.394165,209.906586 1041.418457,209.906586 1057.714600,209.906586 
   C1057.714600,214.827637 1057.714600,219.067673 1057.714600,223.817322 
   C1041.637939,223.817322 1025.736084,223.817322 1009.146729,223.817322 
   C1009.146729,233.998489 1009.146729,243.697189 1009.146729,253.691345 
   C1002.886597,253.691345 997.314819,253.691345 991.369507,253.691345 
   C991.369507,226.694077 991.369507,199.807144 991.369507,172.459351 
   C1015.588623,172.459351 1039.927856,172.459351 1064.634888,172.459351 
   C1064.634888,176.664368 1064.634888,180.905655 1064.634888,186.000000 
   C1051.636841,186.000000 1038.569458,186.000000 1025.002686,186.000000 
z"
      />
      <path
        fill="#ED1B2F"
        opacity="1.000000"
        stroke="none"
        d="
M306.831482,101.289856 
   C303.103638,93.048851 299.647705,85.084015 296.458374,77.733566 
   C302.014099,76.112793 306.897339,75.499847 310.983337,73.362663 
   C335.018311,60.791199 358.865417,47.860729 382.787567,35.073193 
   C383.605194,34.636147 384.547119,34.431747 385.988342,33.921711 
   C385.988342,45.252300 386.042786,56.173809 385.895813,67.092613 
   C385.882538,68.078369 384.784149,69.425873 383.828400,69.965622 
   C371.315826,77.031990 358.829651,84.155029 346.144043,90.901352 
   C337.950989,95.258507 329.528137,99.216331 321.040222,102.973480 
   C316.345703,105.051498 311.463837,104.851959 306.831482,101.289856 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M925.149780,69.378418 
   C925.050720,91.297005 925.050720,112.758018 925.050720,134.608490 
   C919.060852,134.608490 913.483093,134.608490 907.210388,134.608490 
   C907.210388,112.728149 907.210388,90.701775 907.210388,68.164505 
   C896.626770,68.164505 886.712830,68.164505 876.403748,68.164505 
   C876.403748,63.291313 876.403748,58.934212 876.403748,54.283836 
   C902.823730,54.283836 929.054565,54.283836 955.643005,54.283836 
   C955.643005,58.682606 955.643005,62.925362 955.643005,68.000000 
   C947.610901,68.000000 939.525879,67.938995 931.442932,68.042114 
   C929.374817,68.068497 927.313293,68.613892 925.149780,69.378418 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M688.999573,254.000000 
   C672.213013,254.000000 655.926331,254.000000 639.322510,254.000000 
   C639.322510,226.579575 639.322510,199.674973 639.322510,172.384583 
   C644.955505,172.384583 650.534424,172.384583 656.817322,172.384583 
   C656.817322,194.630341 656.817322,216.996231 656.817322,239.874359 
   C674.082764,239.874359 690.661499,239.874359 707.616943,239.874359 
   C707.616943,244.630539 707.616943,248.874542 707.616943,254.000000 
   C701.667358,254.000000 695.583435,254.000000 688.999573,254.000000 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1569.028809,56.303009 
   C1569.703247,55.237720 1570.342773,54.141769 1571.012695,54.122841 
   C1576.131836,53.978237 1581.256714,54.043530 1586.896973,54.043530 
   C1586.896973,76.438080 1586.896973,98.352394 1586.896973,120.786911 
   C1603.991577,120.786911 1620.573242,120.786911 1637.574829,120.786911 
   C1637.574829,125.665703 1637.574829,130.024948 1637.574829,134.697998 
   C1614.868530,134.697998 1592.292603,134.697998 1569.007935,134.697998 
   C1569.007935,108.629997 1569.007935,82.700592 1569.028809,56.303009 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M842.149658,133.621109 
   C842.050659,107.056908 842.050659,80.950249 842.050659,54.422340 
   C847.996460,54.422340 853.685059,54.422340 859.690369,54.422340 
   C859.690369,81.086433 859.690369,107.631721 859.690369,135.298111 
   C853.833801,134.888641 848.041199,134.483643 842.149658,133.621109 
z"
      />
      <path
        fill="#006A4E"
        opacity="1.000000"
        stroke="none"
        d="
M1335.000000,94.000000 
   C1335.000000,107.771034 1335.000000,121.042068 1335.000000,134.653900 
   C1328.968750,134.653900 1323.399170,134.653900 1317.411865,134.653900 
   C1317.411865,108.008507 1317.411865,81.351761 1317.411865,54.346504 
   C1323.030518,54.346504 1328.600098,54.346504 1335.000000,54.346504 
   C1335.000000,67.382370 1335.000000,80.441185 1335.000000,94.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1137.597168,208.727524 
   C1132.813477,209.941483 1128.406372,211.580551 1123.913696,211.864441 
   C1116.169678,212.353775 1108.372437,212.000000 1100.303955,212.000000 
   C1100.303955,203.215225 1100.303955,194.981415 1100.303955,185.902527 
   C1111.887451,186.827652 1123.420166,184.265762 1134.808716,187.821991 
   C1140.163086,189.493912 1143.388428,193.155930 1143.771851,198.432083 
   C1144.076416,202.621017 1141.967285,206.372849 1137.597168,208.727524 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M562.000000,81.990921 
   C562.000000,77.216454 562.000000,72.936661 562.000000,68.782455 
   C573.174377,68.782455 583.897827,68.177025 594.510498,69.000175 
   C601.324890,69.528725 605.960876,75.091110 605.962585,79.999977 
   C605.964478,85.666893 600.080383,92.266235 593.502808,92.841705 
   C583.322815,93.732368 573.083923,93.949608 562.000000,94.495224 
   C562.000000,89.958565 562.000000,86.222076 562.000000,81.990921 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M827.998779,209.356339 
   C830.261475,228.569046 815.445068,238.707474 798.178894,239.918259 
   C791.408752,240.393005 784.577515,239.995972 777.390015,239.995972 
   C777.390015,221.937836 777.390015,204.255875 777.390015,186.051468 
   C788.752991,186.412292 800.000244,184.492950 810.897644,188.843246 
   C820.196899,192.555588 826.306091,198.800430 827.998779,209.356339 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1252.990601,68.000000 
   C1259.722168,68.221794 1266.051270,67.136765 1271.975952,70.417961 
   C1279.526978,74.599815 1280.422363,85.063866 1272.936768,89.383507 
   C1268.813477,91.762947 1263.826660,93.256744 1259.076416,93.791397 
   C1252.502441,94.531311 1245.784668,94.003754 1239.128418,93.990509 
   C1237.658569,93.987587 1236.188965,93.865616 1234.365479,93.782295 
   C1234.365479,85.342964 1234.365479,77.106934 1234.365479,68.000000 
   C1240.339478,68.000000 1246.416260,68.000000 1252.990601,68.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M754.000000,77.047684 
   C754.000000,73.928886 754.000000,71.302933 754.000000,67.923607 
   C767.476074,69.093063 780.788940,65.128159 793.247009,72.526131 
   C801.603699,77.488617 805.632568,84.599510 805.948914,93.947868 
   C806.284912,103.875061 802.131653,111.906136 793.177734,116.124657 
   C787.535278,118.783005 781.027283,120.078575 774.769104,120.825310 
   C768.219055,121.606880 761.503296,121.000000 754.000000,121.000000 
   C754.000000,106.321907 754.000000,91.931213 754.000000,77.047684 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1519.129761,70.686775 
   C1528.664795,76.882195 1533.701660,85.386078 1532.853882,96.347382 
   C1532.030884,106.988106 1526.236694,114.607780 1516.400757,119.327301 
   C1500.596313,126.910637 1481.301147,122.430122 1472.172974,108.695076 
   C1463.088745,95.025848 1469.907715,74.769119 1485.244019,68.979652 
   C1496.767334,64.629593 1507.858276,65.096703 1519.129761,70.686775 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1226.976318,221.000000 
   C1221.176147,221.000000 1215.869873,221.000000 1209.580688,221.000000 
   C1214.668823,210.752518 1219.415894,201.191757 1224.475220,191.002151 
   C1229.485229,201.016571 1234.236572,210.514130 1239.482422,221.000000 
   C1234.921387,221.000000 1231.195801,221.000000 1226.976318,221.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M566.711731,201.628479 
   C568.322205,197.984436 569.748169,194.671921 571.614014,190.337662 
   C576.898682,200.872467 581.741699,210.526947 586.833740,220.677856 
   C576.777649,220.677856 567.306152,220.677856 557.238586,220.677856 
   C560.405823,214.295410 563.466492,208.127701 566.711731,201.628479 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1060.724609,98.399887 
   C1061.206299,99.628036 1061.503296,100.516441 1061.917480,101.756004 
   C1052.260986,101.756004 1043.042114,101.756004 1033.242554,101.756004 
   C1037.961060,91.791145 1042.533203,82.135490 1047.394653,71.868568 
   C1051.983032,81.011002 1056.261353,89.535568 1060.724609,98.399887 
z"
      />
    </svg>
  );
};

BforBankSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default BforBankSvg;
