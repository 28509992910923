import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import RegistrationForm from './RegistrationForm';
import Footer from '../../../components/Footer/Footer';

// Assets
import CatalizrSquareLogo from '../../../components/AssetPictos/components/iconCatalizSquare/index';

import '../styles.scss';

const Registration = props => {
  useEffect(() => {
    const init = async () => {
      const { initView, logout, location, history } = props;
      await logout();
      await initView(location, history);
    };
    init();
  }, []);

  return (
    <div className="registration__main">
      {/* LOGO AND TITLE */}
      <CatalizrSquareLogo width="100" height="100" alt="Catalizr logo" title="Catalizr logo" />
      <h1>Catalizr</h1>
      <RegistrationForm {...props} />
      <Footer />
    </div>
  );
};

Registration.propTypes = {
  // provided by react-router to interact the browser history API
  history: PropTypes.instanceOf(Object).isRequired,
  bank: PropTypes.object.isRequired,

  // ACTIONS
  initView: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,

  logout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default Registration;
