import * as React from 'react';
import PropTypes from 'prop-types';

const CaPyreneesGascogneSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1678 255"
      enableBackground="new 0 0 1678 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1273.000000,256.000000 
	C848.721436,256.000000 424.942871,256.000000 1.082154,256.000000 
	C1.082154,171.065826 1.082154,86.131538 1.082154,1.098629 
	C560.333313,1.098629 1119.666626,1.098629 1678.973633,1.098629 
	C1678.973633,86.057228 1678.973633,171.028625 1678.973633,256.000000 
	C1543.798096,256.000000 1408.649048,256.000000 1273.000000,256.000000 
M550.099976,31.018829 
	C545.054871,31.065655 540.009705,31.112480 534.042114,31.011251 
	C366.421204,31.011251 198.800293,31.011251 31.160572,31.011251 
	C31.160572,60.069351 31.160572,88.525352 31.022520,117.908157 
	C31.068523,126.945030 31.114529,135.981903 31.011345,145.943146 
	C31.007565,171.146820 30.971708,196.350616 31.083920,221.553787 
	C31.090292,222.985504 32.309902,224.411804 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C740.963074,225.992493 1192.968262,225.996445 1644.973389,225.990707 
	C1646.244873,225.990692 1647.516357,225.849716 1648.840576,225.771484 
	C1648.840576,218.306824 1648.840576,211.171021 1648.981201,203.123184 
	C1648.934326,199.736908 1648.887451,196.350647 1648.988770,192.041977 
	C1648.988770,138.457199 1648.988770,84.872429 1648.988770,31.000000 
	C1646.702759,31.000000 1644.898315,31.000000 1643.093750,31.000000 
	C1280.899902,31.000000 918.706177,30.999763 556.512390,31.006647 
	C554.683777,31.006681 552.855225,31.107403 550.099976,31.018829 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1648.840576,204.035187 
	C1648.840576,211.171021 1648.840576,218.306824 1648.840576,225.771484 
	C1647.516357,225.849716 1646.244873,225.990692 1644.973389,225.990707 
	C1192.968262,225.996445 740.963074,225.992493 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411804 31.090292,222.985504 31.083920,221.553787 
	C30.971708,196.350616 31.007565,171.146820 31.461489,145.505661 
	C32.263626,145.542664 32.800144,145.968414 32.940273,146.498871 
	C35.557484,156.406693 40.206745,165.480759 47.850002,172.076050 
	C68.036095,189.494446 92.444420,192.326324 117.741936,189.797699 
	C133.060059,188.266541 148.005051,184.221710 162.209259,178.257111 
	C180.923187,170.398773 199.333603,161.818573 217.897079,153.599701 
	C219.506210,152.887253 221.283447,152.554504 223.014984,152.418243 
	C227.100082,162.690536 231.140411,172.595093 235.211578,182.486969 
	C236.859726,186.491531 239.859756,188.112045 244.212128,188.049637 
	C254.851303,187.897125 265.494202,188.014679 276.135437,187.979095 
	C277.340851,187.975067 278.545319,187.687149 280.106964,187.484604 
	C272.264069,171.046799 264.695435,155.101700 257.003296,139.216415 
	C256.380219,137.929672 254.910553,137.052872 253.871460,135.620316 
	C254.492950,134.749298 255.019501,134.148941 255.670044,133.751694 
	C271.305359,124.204453 286.981384,114.723274 302.555267,105.077080 
	C304.023041,104.167976 305.798035,102.274040 305.843170,100.789108 
	C306.137909,91.090858 305.994751,81.379303 305.994751,71.326225 
	C284.295013,83.076637 263.155548,94.523674 241.985931,105.602158 
	C234.182831,87.898300 226.369156,70.581032 218.675049,53.210796 
	C217.317612,50.146221 215.601593,48.784966 211.983704,48.894951 
	C201.993347,49.198669 191.984268,49.149868 181.989929,48.920994 
	C178.355026,48.837757 176.441528,49.950489 174.751373,53.320972 
	C168.595032,65.597786 162.060867,77.687134 155.564636,89.791061 
	C145.111298,109.268021 134.574905,128.700394 123.616508,148.998245 
	C126.163620,148.998245 127.983139,149.313019 129.652161,148.945099 
	C137.985001,147.108200 146.523941,145.777695 154.506302,142.917877 
	C160.677338,140.707001 167.697617,138.617477 170.987488,131.876740 
	C175.846848,121.920212 180.415787,111.822136 185.129532,101.794273 
	C188.175903,95.313507 191.260071,88.850502 194.703812,81.585228 
	C195.733994,84.008568 196.405655,85.473206 196.986481,86.973022 
	C201.624420,98.949081 206.245132,110.931816 210.512726,122.962906 
	C193.223892,130.978470 176.703613,140.039444 159.238480,146.575928 
	C144.185287,152.209747 128.204620,155.764359 112.351463,158.725739 
	C101.240448,160.801285 89.658058,160.742645 78.278671,160.851395 
	C71.050499,160.920502 64.146317,158.711426 57.908787,154.570740 
	C53.732258,151.798218 52.465164,147.979218 53.143902,143.565933 
	C53.682713,140.062500 54.808292,136.508453 56.382023,133.327484 
	C68.259766,109.319214 88.436104,94.495598 112.255829,84.129562 
	C125.386230,78.415382 139.243149,75.012398 154.783981,74.732361 
	C152.081787,67.208847 150.137329,59.897671 146.787521,53.300037 
	C145.204727,50.182587 141.233978,47.240841 137.797623,46.363049 
	C133.481018,45.260395 128.488586,45.753265 123.987846,46.609348 
	C107.418037,49.761086 91.911308,55.865856 77.579979,64.799484 
	C58.618061,76.619659 43.109154,91.612015 34.086670,112.525574 
	C33.393394,114.132538 32.148869,115.501671 31.160572,116.981354 
	C31.160572,88.525352 31.160572,60.069351 31.160572,31.011251 
	C198.800293,31.011251 366.421204,31.011251 534.488770,31.430481 
	C532.621399,35.301231 530.307312,38.752754 527.249329,43.313896 
	C533.663574,42.131325 539.397400,45.296379 543.686279,39.839977 
	C546.026184,36.863037 548.573059,34.048737 551.026611,31.161102 
	C552.855225,31.107403 554.683777,31.006681 556.512390,31.006647 
	C918.706177,30.999763 1280.899902,31.000000 1643.093750,31.000000 
	C1644.898315,31.000000 1646.702759,31.000000 1648.988770,31.000000 
	C1648.988770,84.872429 1648.988770,138.457199 1648.456665,192.515274 
	C1636.594849,192.992310 1625.265015,193.030716 1613.935425,192.985504 
	C1608.076782,192.962128 1604.635010,190.866013 1604.139038,186.620483 
	C1603.744385,183.242325 1604.059448,179.781281 1604.059448,175.866943 
	C1617.682007,175.866943 1630.599854,175.866943 1643.560059,175.866943 
	C1643.560059,171.982666 1643.560059,168.623734 1643.560059,164.761719 
	C1630.209595,164.761719 1617.292969,164.761719 1604.315430,164.761719 
	C1604.315430,160.000717 1604.315430,155.754562 1604.315430,150.832306 
	C1618.657959,150.832306 1632.701172,150.832306 1646.631836,150.832306 
	C1646.631836,146.895020 1646.631836,143.646255 1646.631836,140.303360 
	C1627.687744,140.303360 1609.113281,140.303360 1590.664429,140.303360 
	C1590.393677,140.891281 1590.143677,141.181595 1590.142578,141.472824 
	C1590.083984,157.464691 1589.747070,173.464737 1590.179565,189.443893 
	C1590.318848,194.584900 1592.969360,199.581741 1598.347168,201.570831 
	C1601.984375,202.916122 1605.991455,203.807556 1609.855469,203.893997 
	C1622.845093,204.184509 1635.844727,204.023453 1648.840576,204.035187 
M678.000000,180.500702 
	C678.000000,174.259216 678.000000,168.017731 678.000000,160.307800 
	C681.873779,164.657700 684.852783,167.876816 687.691467,171.215317 
	C694.636719,179.383347 701.444519,187.669388 708.462097,195.774200 
	C710.995972,198.700684 713.536072,202.441055 716.858826,203.648422 
	C720.628723,205.018295 725.279236,203.964691 729.573120,203.964691 
	C729.573120,182.310135 729.573120,161.298309 729.573120,140.362671 
	C724.564636,140.362671 719.989075,140.362671 714.904480,140.362671 
	C714.904480,154.230942 714.904480,167.746796 714.904480,182.080322 
	C713.373718,180.705185 712.453369,180.035522 711.723938,179.199326 
	C702.602722,168.742035 692.765564,158.798676 684.663330,147.601074 
	C679.020752,139.802673 672.582092,139.082886 664.389465,140.474289 
	C664.389465,161.613815 664.389465,182.625626 664.389465,203.674286 
	C669.025879,203.674286 673.269226,203.674286 678.000000,203.674286 
	C678.000000,196.071671 678.000000,188.785995 678.000000,180.500702 
M1547.117065,171.336273 
	C1539.580933,162.561584 1532.090576,153.746796 1524.472534,145.043793 
	C1522.823608,143.159851 1520.916870,140.652084 1518.775879,140.232193 
	C1514.816284,139.455597 1510.595581,140.010025 1506.438232,140.010025 
	C1506.438232,161.676285 1506.438232,182.707230 1506.438232,203.619354 
	C1511.129883,203.619354 1515.376831,203.619354 1520.162354,203.619354 
	C1520.162354,189.071136 1520.162354,174.894302 1520.162354,159.759399 
	C1521.862305,161.870850 1522.831787,163.158905 1523.886841,164.372589 
	C1533.002197,174.858414 1542.848267,184.808426 1551.014404,195.986267 
	C1556.697266,203.764771 1563.074707,205.255585 1571.581787,203.470551 
	C1571.581787,182.364853 1571.581787,161.334015 1571.581787,140.360565 
	C1566.911011,140.360565 1562.664062,140.360565 1557.885254,140.360565 
	C1557.885254,154.566864 1557.885254,168.422226 1557.885254,183.442474 
	C1553.874512,178.996368 1550.689941,175.466171 1547.117065,171.336273 
M566.466553,204.000000 
	C568.868835,204.000000 571.271057,204.000000 573.673340,204.000000 
	C573.883545,203.560516 574.093689,203.121017 574.303894,202.681534 
	C567.985840,195.238693 561.667786,187.795853 555.010315,179.953186 
	C557.237366,179.187531 559.134766,178.714111 560.875305,177.903854 
	C566.477905,175.295761 570.844238,171.510498 571.971985,165.048355 
	C574.501343,150.554657 565.471008,142.469086 553.313538,141.252991 
	C539.329041,139.854156 525.154175,140.352737 511.061127,140.065674 
	C510.464813,140.053513 509.860596,140.429199 509.321472,140.604248 
	C509.321472,161.716995 509.321472,182.608276 509.321472,203.567123 
	C514.344238,203.567123 519.038940,203.567123 524.000000,203.567123 
	C524.000000,199.652603 524.000000,196.169052 524.000000,192.685501 
	C524.000000,189.255447 524.000000,185.825394 524.000000,182.007248 
	C529.018433,182.007248 533.321838,181.894775 537.612366,182.080826 
	C538.786072,182.131729 540.241699,182.793777 541.015198,183.665726 
	C543.633728,186.617783 545.700867,190.108566 548.536682,192.807236 
	C553.494629,197.525543 556.034607,206.156982 566.466553,204.000000 
M492.242004,74.404411 
	C495.184448,62.936153 489.202698,51.870979 477.192200,50.297493 
	C462.818298,48.414383 448.164581,48.658329 433.630005,48.028442 
	C432.538879,47.981159 431.431305,48.313175 430.291687,48.474483 
	C430.291687,69.660713 430.291687,90.548927 430.291687,111.597275 
	C434.947113,111.597275 439.305359,111.597275 444.160339,111.597275 
	C444.160339,104.289528 444.160339,97.378242 444.160339,90.019196 
	C449.110840,90.019196 453.573029,89.894768 458.019379,90.104759 
	C459.162506,90.158737 460.537628,91.021889 461.325287,91.930290 
	C465.644714,96.911789 469.745087,102.083389 474.072174,107.057938 
	C475.653229,108.875549 477.497559,111.459068 479.488403,111.729332 
	C484.455811,112.403687 489.576141,111.951561 495.787506,111.951561 
	C488.640167,103.688782 482.339325,96.404655 475.534851,88.538254 
	C483.006958,86.039864 489.429871,82.802650 492.242004,74.404411 
M969.952454,110.178345 
	C969.952454,103.581932 969.952454,96.985512 969.952454,90.007553 
	C975.037964,90.007553 979.351257,89.855553 983.641968,90.099800 
	C984.935547,90.173447 986.500671,91.058952 987.370483,92.065834 
	C992.116272,97.559776 996.621704,103.261139 1001.358093,108.763527 
	C1002.490601,110.079216 1004.125305,111.694565 1005.638000,111.816116 
	C1010.492004,112.206177 1015.397095,111.961143 1021.617188,111.961143 
	C1014.316101,103.494141 1007.889587,96.041351 1001.359009,88.467888 
	C1003.680847,87.567657 1006.221619,87.229858 1007.834167,85.825905 
	C1011.468079,82.662117 1016.261963,79.536385 1017.710083,75.391190 
	C1022.713379,61.069847 1012.949585,50.622128 997.906067,49.172813 
	C985.709229,47.997765 973.367065,48.321136 961.088562,48.015976 
	C959.498352,47.976456 957.902771,48.152988 956.338135,48.228077 
	C956.338135,69.716270 956.338135,90.625298 956.338135,112.353600 
	C961.073059,111.908478 965.417297,111.500076 969.952454,110.178345 
M1143.091187,96.253517 
	C1144.873169,98.413483 1146.355103,100.942787 1148.485596,102.673882 
	C1163.051880,114.509430 1179.465698,115.844894 1196.577026,109.877678 
	C1209.475220,105.379738 1217.825195,96.048325 1218.766602,82.197853 
	C1219.573608,70.322357 1214.031372,60.741329 1203.817261,54.314514 
	C1190.284424,45.799507 1175.927612,45.521408 1161.119507,50.429085 
	C1142.387451,56.637260 1132.419312,76.610344 1143.091187,96.253517 
M581.000000,61.508759 
	C581.000000,78.252457 581.000000,94.996162 581.000000,111.992867 
	C593.026489,111.992867 604.222168,112.579597 615.326294,111.823357 
	C625.858765,111.106056 635.501465,107.600060 643.176208,99.458717 
	C653.897278,88.085762 652.030334,65.946800 639.198914,57.062649 
	C631.214478,51.534401 622.419861,48.719402 612.682129,48.777493 
	C602.291626,48.839478 591.900452,48.791454 581.000000,48.791454 
	C581.000000,52.589462 581.000000,56.550980 581.000000,61.508759 
M1342.429321,145.859619 
	C1340.753052,147.191803 1339.026123,148.465454 1337.408569,149.865433 
	C1324.970337,160.630173 1324.389771,180.358994 1335.476318,192.195328 
	C1348.870117,206.494781 1374.788940,208.657837 1391.382690,198.731461 
	C1403.046265,191.754318 1408.788696,181.336182 1406.995728,167.417267 
	C1405.574951,156.386780 1398.952881,149.018707 1389.496826,144.319412 
	C1373.952148,136.594162 1358.335449,136.951065 1342.429321,145.859619 
M871.439941,94.910912 
	C874.454224,98.341370 876.903198,102.626968 880.578857,105.056946 
	C898.180176,116.693115 916.474670,115.115120 934.720581,106.502518 
	C936.780151,105.530357 939.599060,103.330879 939.711121,101.552200 
	C940.239075,93.170410 939.946289,84.736900 939.946289,76.329407 
	C928.288025,76.329407 917.381592,76.329407 906.445679,76.329407 
	C906.445679,80.028793 906.445679,83.388199 906.445679,87.243347 
	C912.805664,87.243347 918.723877,87.243347 924.774414,87.243347 
	C924.866394,89.049522 924.764160,90.395218 925.029297,91.664162 
	C926.159790,97.075165 923.510132,99.911797 918.550354,100.637604 
	C913.510742,101.375076 908.299011,102.371307 903.325562,101.810242 
	C894.497864,100.814354 887.256836,96.491341 884.108521,87.650459 
	C881.513733,80.363846 882.293701,72.999405 887.541443,67.126152 
	C894.144348,59.736370 902.953674,56.658436 912.640137,58.536629 
	C918.649475,59.701817 924.311523,62.658150 930.350342,64.892227 
	C932.266968,62.224804 934.224426,59.500492 936.162842,56.802773 
	C923.289978,46.895882 906.739929,44.913315 889.277954,50.849892 
	C870.899353,57.098095 862.621277,76.836739 871.439941,94.910912 
M1181.537109,170.028091 
	C1186.735352,172.022125 1191.835815,174.343399 1197.155029,175.932449 
	C1204.864868,178.235611 1212.786499,179.831284 1220.492676,182.145294 
	C1222.229858,182.666931 1224.458984,184.809174 1224.609985,186.383224 
	C1224.745239,187.791351 1222.609863,190.276154 1220.981812,190.828552 
	C1216.671509,192.291016 1212.016357,194.041245 1207.637939,193.727600 
	C1198.460327,193.070175 1189.345825,191.319122 1181.362061,186.022491 
	C1180.485107,185.440659 1178.160278,185.527740 1177.636475,186.193375 
	C1175.563477,188.827347 1173.939941,191.815109 1172.062866,194.810318 
	C1184.549683,202.210892 1197.571655,205.578003 1211.352661,204.833908 
	C1219.194824,204.410477 1227.007568,202.668381 1233.647949,197.710724 
	C1240.392578,192.675400 1241.992065,183.730560 1237.341675,176.649490 
	C1233.242188,170.407196 1226.471680,168.906693 1220.066528,167.095444 
	C1211.696411,164.728500 1203.100952,163.166153 1194.696899,160.905884 
	C1192.935547,160.432144 1191.585571,158.428818 1190.046021,157.130341 
	C1191.498413,155.523178 1192.653564,153.148483 1194.457764,152.449814 
	C1200.338745,150.172546 1206.478394,148.742798 1212.819824,150.846115 
	C1219.301514,152.996002 1225.777954,155.162216 1232.589355,157.432098 
	C1233.560059,156.054337 1234.682861,153.996368 1236.240112,152.349274 
	C1239.303345,149.109192 1238.321533,147.072403 1234.555786,145.852386 
	C1227.543701,143.580582 1220.615723,140.416794 1213.414185,139.501801 
	C1200.733643,137.890625 1188.221191,138.922745 1178.467651,149.022247 
	C1172.612671,155.084854 1173.810913,164.967346 1181.537109,170.028091 
M943.870483,190.586685 
	C928.582275,196.903076 914.674377,192.379120 900.945374,184.796722 
	C899.858215,186.405609 898.848145,187.870911 897.868042,189.356003 
	C894.149048,194.990952 893.955811,195.663940 900.066895,197.853729 
	C907.590210,200.549591 915.463318,202.358215 923.282349,204.118805 
	C934.261597,206.591003 944.777161,204.355850 954.239014,198.763397 
	C965.338623,192.202927 964.597534,176.569229 954.666748,172.296722 
	C947.780029,169.333923 940.496338,167.255890 933.311890,165.035263 
	C927.369995,163.198685 921.269226,161.872498 915.342712,159.992737 
	C914.030945,159.576630 912.143982,157.597427 912.314758,156.667007 
	C912.577942,155.232986 914.150818,153.504608 915.585327,152.913452 
	C921.586548,150.440338 927.842468,148.814194 934.353088,150.701813 
	C941.195068,152.685471 947.965454,154.915665 955.119812,157.148163 
	C956.788879,154.801392 958.865479,151.881622 960.884216,149.043259 
	C960.644287,148.552841 960.580994,148.122894 960.349304,147.992661 
	C942.478943,137.950333 924.141418,134.889359 905.249573,144.982071 
	C896.462769,149.676376 893.176086,162.523651 902.119141,168.366776 
	C907.233765,171.708466 913.199341,173.990509 919.060608,175.888412 
	C927.315186,178.561295 935.962952,180.036377 944.179077,182.805298 
	C948.866577,184.385010 948.693970,186.849045 943.870483,190.586685 
M1465.488770,178.000000 
	C1468.236816,178.000000 1470.984863,178.000000 1473.637329,178.000000 
	C1474.765381,190.417267 1474.785767,190.503937 1463.707520,193.112686 
	C1460.856689,193.783997 1457.771851,194.244263 1454.899170,193.907867 
	C1441.419434,192.329285 1433.326294,185.691895 1432.160645,173.288742 
	C1430.947754,160.382538 1441.630737,151.264587 1454.718506,149.986389 
	C1463.540161,149.124832 1471.174072,152.281967 1478.656738,157.364151 
	C1481.127319,154.142242 1483.352905,151.239990 1485.758789,148.102448 
	C1468.299927,137.250549 1451.111572,135.662491 1433.701294,144.793945 
	C1412.780151,155.766861 1410.753540,183.727219 1430.335083,197.363495 
	C1446.769897,208.808350 1470.174438,207.187057 1486.928467,195.947159 
	C1487.871216,195.314713 1488.876465,193.946091 1488.897461,192.900986 
	C1489.059814,184.793716 1488.987061,176.681747 1488.987061,168.383911 
	C1477.741089,168.383911 1467.049805,168.383911 1456.323486,168.383911 
	C1456.323486,171.686386 1456.323486,174.600571 1456.323486,178.000000 
	C1459.265137,178.000000 1461.889893,178.000000 1465.488770,178.000000 
M1051.164307,150.002991 
	C1057.140015,152.119843 1063.115723,154.236679 1069.608887,156.536804 
	C1071.273071,154.193710 1073.313721,151.320786 1075.192261,148.675934 
	C1074.702393,147.810516 1074.614136,147.419556 1074.373291,147.262970 
	C1060.765991,138.411316 1046.177246,136.742645 1030.831055,141.623596 
	C1017.080627,145.996902 1008.440918,155.321289 1007.224792,169.764069 
	C1006.230835,181.568466 1011.180115,191.431549 1021.470093,197.925400 
	C1038.109253,208.426224 1059.664185,206.509033 1076.537964,196.674088 
	C1077.572876,196.070908 1078.811890,194.696442 1078.834961,193.655991 
	C1079.021484,185.237610 1078.939697,176.813293 1078.939697,168.298752 
	C1067.684937,168.298752 1057.111572,168.298752 1046.415527,168.298752 
	C1046.415527,171.635818 1046.415527,174.660751 1046.415527,178.184906 
	C1052.741455,178.184906 1058.658813,178.184906 1064.516113,178.184906 
	C1065.512939,189.839890 1066.492798,189.457581 1054.945068,193.024399 
	C1048.463135,195.026520 1042.168457,194.016388 1036.005371,191.882263 
	C1028.314941,189.219269 1021.473572,179.917084 1022.128296,171.936615 
	C1022.920959,162.275177 1026.913330,155.577484 1036.194092,152.213303 
	C1040.575195,150.625244 1045.554077,150.686462 1051.164307,150.002991 
M624.499817,140.000000 
	C612.429871,140.000000 600.359924,140.000000 588.010803,140.000000 
	C588.010803,157.136169 588.002625,173.435226 588.035461,189.734192 
	C588.037048,190.529510 588.332703,191.346848 588.601196,192.115570 
	C591.754272,201.142227 596.667419,203.455185 607.035400,203.889893 
	C618.501648,204.370651 630.003601,204.010010 641.489990,203.989197 
	C643.243469,203.986023 644.996704,203.839264 646.635620,203.764160 
	C646.635620,199.898453 646.635620,196.651611 646.635620,193.000000 
	C644.473206,193.000000 642.672058,193.000061 640.870850,193.000000 
	C631.049133,192.999664 621.227234,193.036972 611.405823,192.981995 
	C606.919312,192.956879 602.957642,191.610153 602.158936,186.594788 
	C601.627747,183.259216 602.058044,179.770523 602.058044,175.871857 
	C615.681030,175.871857 628.590454,175.871857 641.571899,175.871857 
	C641.571899,172.005493 641.571899,168.646805 641.571899,164.796692 
	C628.242249,164.796692 615.333801,164.796692 602.287781,164.796692 
	C602.287781,160.052246 602.287781,155.809586 602.287781,150.899673 
	C616.591919,150.899673 630.624939,150.899673 644.676147,150.899673 
	C644.676147,146.975906 644.676147,143.731476 644.676147,140.000000 
	C638.076233,140.000000 631.787903,140.000000 624.499817,140.000000 
M749.001038,143.717270 
	C749.000732,158.213303 748.933350,172.709747 749.024841,187.205200 
	C749.081238,196.148193 753.677734,201.780106 762.342102,202.949371 
	C768.221497,203.742813 774.213440,203.856705 780.160522,203.958908 
	C789.268494,204.115433 798.381165,204.000000 807.571716,204.000000 
	C807.571716,200.009674 807.571716,196.648819 807.571716,193.000000 
	C797.329041,193.000000 787.511841,193.000000 777.694641,193.000000 
	C763.917358,193.000000 762.253601,191.211487 763.169495,177.347351 
	C763.188538,177.058578 763.459045,176.786423 763.981628,175.831726 
	C776.656616,175.831726 789.700500,175.831726 802.631470,175.831726 
	C802.631470,171.894562 802.631470,168.645691 802.631470,164.900085 
	C789.355103,164.900085 776.437256,164.900085 763.423340,164.900085 
	C763.423340,160.015854 763.423340,155.655258 763.423340,150.793152 
	C777.758179,150.793152 791.675903,150.793152 805.701538,150.793152 
	C805.701538,147.032562 805.701538,143.786652 805.701538,140.016785 
	C787.459900,140.016785 769.510986,139.978821 751.563721,140.133682 
	C750.704224,140.141098 749.859436,141.851791 749.001038,143.717270 
M1320.670532,101.000000 
	C1309.274536,101.554474 1308.833496,97.938988 1310.814087,83.900513 
	C1323.315430,83.900513 1335.897827,83.900513 1348.579956,83.900513 
	C1348.579956,80.359276 1348.579956,77.332237 1348.579956,73.805695 
	C1335.587280,73.805695 1323.006836,73.805695 1310.289551,73.805695 
	C1310.289551,68.718643 1310.289551,64.141640 1310.289551,58.888924 
	C1324.600830,58.888924 1338.639404,58.888924 1352.664551,58.888924 
	C1352.664551,54.957817 1352.664551,51.710297 1352.664551,48.440819 
	C1333.303711,48.440819 1314.281738,48.440819 1295.006226,48.440819 
	C1295.006226,64.360992 1294.748047,79.847565 1295.096558,95.320465 
	C1295.329834,105.676666 1302.397949,111.959160 1312.761719,111.995087 
	C1320.090210,112.020485 1327.418823,111.999992 1334.747437,112.000000 
	C1341.017334,112.000000 1347.287231,112.000000 1353.543335,112.000000 
	C1353.543335,107.946960 1353.543335,104.589233 1353.543335,101.000000 
	C1342.601440,101.000000 1332.114868,101.000000 1320.670532,101.000000 
M841.718506,151.001007 
	C854.648193,151.001007 867.577942,151.001007 880.563965,151.001007 
	C880.563965,146.990845 880.563965,143.631546 880.563965,140.357300 
	C861.559814,140.357300 842.993896,140.357300 824.021667,140.357300 
	C824.021667,157.163620 823.802368,173.620590 824.131042,190.066620 
	C824.260132,196.525681 829.262329,202.028732 836.033081,202.846176 
	C844.385803,203.854630 852.880188,203.770538 861.317810,203.968048 
	C868.076782,204.126266 874.842285,204.000000 881.701721,204.000000 
	C881.701721,200.029556 881.701721,196.785217 881.701721,193.241913 
	C879.799011,193.152512 878.176147,193.013809 876.552979,193.010284 
	C866.895569,192.989288 857.237732,193.054184 847.580933,192.978668 
	C841.828308,192.933685 838.527344,190.669876 838.097839,186.278214 
	C837.780823,183.036057 838.035583,179.737991 838.035583,175.812836 
	C851.347290,175.812836 864.048950,175.812836 876.628540,175.812836 
	C876.628540,171.885818 876.628540,168.638443 876.628540,164.903534 
	C863.688843,164.903534 851.110718,164.903534 838.016724,164.903534 
	C838.016724,160.797089 837.833801,157.161377 838.142700,153.567978 
	C838.221924,152.646072 839.853027,151.857559 841.718506,151.001007 
M537.500000,48.000000 
	C527.421875,48.000000 517.343750,48.000000 507.000000,48.000000 
	C507.000000,63.836552 506.998291,78.816650 507.000549,93.796745 
	C507.002380,106.008675 513.039429,111.985680 525.405212,111.997581 
	C537.065918,112.008804 548.726685,112.000000 560.387390,112.000000 
	C562.142761,112.000000 563.898132,112.000000 565.668823,112.000000 
	C565.668823,107.966850 565.668823,104.719147 565.668823,101.000000 
	C563.555908,101.000000 561.757629,101.000038 559.959412,101.000000 
	C549.797913,100.999771 539.636353,100.982727 529.474915,101.006813 
	C524.808350,101.017876 521.768799,98.795273 521.119446,94.219772 
	C520.664490,91.013947 521.031616,87.691444 521.031616,83.941765 
	C534.604370,83.941765 547.520935,83.941765 560.600037,83.941765 
	C560.600037,80.400070 560.600037,77.372673 560.600037,73.842773 
	C547.293823,73.842773 534.378784,73.842773 521.449463,73.842773 
	C521.449463,68.627487 521.449463,63.928722 521.449463,58.745007 
	C536.142517,58.745007 550.391357,58.745007 564.679626,58.745007 
	C564.679626,54.988579 564.679626,51.741348 564.679626,48.000000 
	C555.745728,48.000000 547.122864,48.000000 537.500000,48.000000 
M355.000000,146.600372 
	C355.000000,165.641022 355.000000,184.681686 355.000000,203.640640 
	C360.433960,203.640640 365.006958,203.640640 370.073364,203.640640 
	C370.073364,197.381119 370.073364,191.465836 370.073364,185.000000 
	C372.348572,185.000000 374.131195,185.060379 375.908600,184.989578 
	C383.672943,184.680252 391.478088,184.723679 399.187286,183.889511 
	C406.924744,183.052277 413.388824,179.262665 417.235474,172.282486 
	C424.026672,159.959015 418.022949,142.933365 399.419128,141.204834 
	C385.263947,139.889633 370.940399,140.382019 356.691132,140.103424 
	C356.256653,140.094940 355.814880,140.456985 355.000000,140.808868 
	C355.000000,142.366135 355.000000,143.999969 355.000000,146.600372 
M1134.434448,189.000000 
	C1141.532104,187.360855 1145.609863,190.516449 1147.641968,197.160904 
	C1148.119507,198.722488 1148.986938,200.202072 1149.881958,201.585480 
	C1150.467041,202.489777 1151.397217,203.784531 1152.219971,203.820297 
	C1157.070923,204.031296 1161.935791,203.924210 1166.930786,203.924210 
	C1166.806519,202.542297 1166.904785,201.987732 1166.699463,201.589783 
	C1157.674316,184.092285 1148.256104,166.783981 1139.756592,149.035629 
	C1136.435181,142.100021 1132.629395,138.820557 1124.750244,139.807312 
	C1121.128540,140.260864 1119.283325,141.278580 1117.676514,144.512466 
	C1109.501221,160.966537 1101.071899,177.294708 1092.689941,193.645645 
	C1091.079712,196.786789 1089.283691,199.832733 1087.454834,203.136627 
	C1100.776123,206.065247 1102.781982,205.037491 1107.152832,193.717133 
	C1107.271973,193.408783 1107.505005,193.139755 1107.597534,192.826599 
	C1108.470337,189.872833 1110.281616,188.791519 1113.477661,188.919540 
	C1120.127319,189.185928 1126.794922,189.000031 1134.434448,189.000000 
M804.519165,102.971031 
	C805.918640,98.136551 808.909302,96.257225 814.071594,96.892693 
	C818.337585,97.417824 822.792969,97.509254 827.035645,96.898399 
	C837.171448,95.439079 844.358521,98.136597 847.093750,108.910538 
	C847.403809,110.131897 849.161133,111.764076 850.344299,111.848648 
	C855.070374,112.186470 859.834717,111.988022 865.106934,111.988022 
	C864.607239,110.664963 864.443909,110.057770 864.162109,109.511726 
	C854.143127,90.096840 844.183960,70.650070 833.955078,51.346268 
	C833.101318,49.735104 830.535278,48.589844 828.555481,48.130692 
	C826.184631,47.580830 823.516663,47.642834 821.115173,48.125591 
	C819.470154,48.456272 817.452026,49.606373 816.650024,50.995869 
	C813.013977,57.295380 809.732788,63.800690 806.358093,70.249878 
	C802.446167,77.725807 798.527161,85.198586 794.691650,92.713776 
	C791.511353,98.945335 788.447754,105.236488 785.084412,112.000000 
	C788.853455,112.000000 791.703735,111.763596 794.498474,112.056396 
	C798.512085,112.476875 801.251343,111.336258 802.416687,107.212357 
	C802.771423,105.957214 803.544189,104.820236 804.519165,102.971031 
M1125.854492,64.540794 
	C1127.921143,62.021057 1129.987793,59.501320 1132.037231,57.002632 
	C1118.789795,45.150047 1089.752441,43.575302 1074.086792,57.126804 
	C1059.635498,69.627777 1059.731201,94.004791 1076.060669,104.973015 
	C1091.148071,115.106895 1107.309326,115.370834 1123.830688,108.978416 
	C1127.196533,107.676102 1130.214478,105.474655 1133.646240,103.545204 
	C1130.798828,100.061470 1128.724243,97.523094 1126.564941,94.881111 
	C1124.778809,95.706314 1123.200928,96.235390 1121.834351,97.098923 
	C1112.502441,102.996140 1102.682861,103.596016 1092.432739,99.989723 
	C1084.667725,97.257767 1077.353271,86.987732 1078.163574,79.836578 
	C1080.120483,62.565605 1096.661255,55.831200 1109.233032,58.769489 
	C1114.728149,60.053783 1119.884521,62.786781 1125.854492,64.540794 
M363.085754,76.114273 
	C365.949463,65.844414 373.289673,61.105503 383.234772,58.713772 
	C393.375549,56.274979 401.425751,61.137199 409.937744,65.373375 
	C412.177307,62.309925 414.168121,59.586720 416.142426,56.886108 
	C415.377106,56.021004 414.996979,55.451260 414.485413,55.048435 
	C413.844299,54.543602 413.119202,54.129070 412.389374,53.755615 
	C395.843658,45.289127 379.293610,44.667755 362.902466,54.055462 
	C352.077759,60.255089 347.468750,69.879356 347.980286,82.214249 
	C348.434296,93.162827 354.277985,100.750206 363.000824,106.463966 
	C372.270691,112.536072 382.740814,113.854881 393.448334,112.898369 
	C402.050232,112.129944 409.922363,108.891647 417.025848,104.034294 
	C413.876556,95.442230 411.608643,94.568848 404.132050,98.360909 
	C401.649994,99.619797 398.971924,100.656685 396.264893,101.280746 
	C379.097290,105.238480 361.159790,95.625679 363.085754,76.114273 
M1270.149780,200.585358 
	C1283.751343,206.257584 1297.372070,206.155151 1311.091309,200.922409 
	C1321.148804,197.086349 1321.821777,194.459961 1314.359985,186.930908 
	C1314.134399,186.703278 1313.798462,186.585098 1313.440308,186.372009 
	C1304.361084,192.374222 1294.779663,196.092087 1283.576050,193.003845 
	C1273.699829,190.281464 1265.841675,180.914398 1266.000488,171.610504 
	C1266.167603,161.820038 1273.346069,153.669403 1283.653198,150.976990 
	C1294.230835,148.213882 1303.512939,151.023788 1312.583252,157.372879 
	C1315.001709,154.265564 1317.257324,151.367630 1319.526245,148.452393 
	C1304.325806,137.388901 1287.279907,136.793137 1272.279785,142.442703 
	C1258.460327,147.647690 1250.353027,159.497910 1251.082764,173.386932 
	C1251.738770,185.874023 1258.530029,194.567657 1270.149780,200.585358 
M268.012238,53.488213 
	C258.005981,58.480034 248.945145,65.694275 236.591019,66.535736 
	C238.283203,71.185028 239.586670,75.734398 241.556274,79.974327 
	C245.542526,88.555565 250.058136,90.325935 258.505615,86.033630 
	C273.379272,78.476097 288.044067,70.504745 302.719269,62.564289 
	C304.092499,61.821255 305.804688,60.092220 305.849731,58.769527 
	C306.134949,50.400360 305.994476,42.016689 305.994476,33.021301 
	C293.092163,39.949863 280.868164,46.514183 268.012238,53.488213 
M476.010406,149.469635 
	C471.248413,155.274628 466.486450,161.079605 461.508728,167.147614 
	C456.393585,160.728607 451.832703,154.677567 446.901917,148.944687 
	C444.107513,145.695755 441.165283,141.607101 437.459991,140.358292 
	C433.034180,138.866638 427.721436,140.006516 422.229004,140.006516 
	C422.900208,141.429169 423.150330,142.429092 423.743256,143.145233 
	C432.161682,153.313232 440.544434,163.513016 449.122467,173.545486 
	C452.347717,177.317566 454.547852,181.073135 454.107544,186.370056 
	C453.632172,192.089340 454.000000,197.878708 454.000000,203.684280 
	C459.004089,203.684280 463.248169,203.684280 468.000031,203.684280 
	C468.000031,196.259781 467.933197,189.139893 468.052338,182.023087 
	C468.078430,180.465530 468.318329,178.559494 469.225281,177.426956 
	C474.381866,170.987671 479.823334,164.777664 485.064911,158.404984 
	C489.804108,152.643051 494.411194,146.772476 499.335388,140.626755 
	C485.628143,138.489899 482.286041,139.773575 476.010406,149.469635 
M689.999939,54.434422 
	C690.117920,55.860241 690.235840,57.286060 690.392700,59.183010 
	C698.398987,59.183010 705.983398,59.183010 714.055969,59.183010 
	C714.055969,76.934044 714.055969,94.180527 714.055969,111.601700 
	C719.264282,111.601700 723.960083,111.601700 729.156372,111.601700 
	C729.156372,93.963524 729.156372,76.717667 729.156372,58.795944 
	C737.358337,58.795944 745.070496,58.795944 752.614014,58.795944 
	C752.614014,54.850773 752.614014,51.606392 752.614014,48.316040 
	C731.653137,48.316040 711.079712,48.316040 690.000000,48.316040 
	C690.000000,50.251545 690.000000,51.875771 689.999939,54.434422 
M1276.459717,112.000000 
	C1279.541138,112.000000 1282.622437,112.000000 1285.654785,112.000000 
	C1285.654785,107.931557 1285.654785,104.687019 1285.654785,100.794441 
	C1272.299561,100.794441 1259.276978,100.794441 1245.803955,100.794441 
	C1245.803955,82.911972 1245.803955,65.686951 1245.803955,48.444946 
	C1240.958008,48.444946 1236.602173,48.444946 1232.338623,48.444946 
	C1232.338623,69.772400 1232.338623,90.653343 1232.338623,112.000000 
	C1246.939575,112.000000 1261.206055,112.000000 1276.459717,112.000000 
M678.000000,99.487511 
	C678.000000,82.436630 678.000000,65.385750 678.000000,48.330559 
	C672.624268,48.330559 668.051880,48.330559 663.400146,48.330559 
	C663.400146,69.661888 663.400146,90.660240 663.400146,111.668594 
	C668.377563,111.668594 672.949951,111.668594 678.000000,111.668594 
	C678.000000,107.726440 678.000000,104.103394 678.000000,99.487511 
M1035.000000,52.671173 
	C1035.000000,72.252541 1035.000000,91.833908 1035.000000,111.612152 
	C1039.917969,111.612152 1044.274902,111.612152 1048.713379,111.612152 
	C1048.713379,90.339188 1048.713379,69.455246 1048.713379,48.014751 
	C1044.831177,48.014751 1041.185059,47.749187 1037.624634,48.184578 
	C1036.621094,48.307301 1035.868652,50.484230 1035.000000,52.671173 
M611.385010,133.998703 
	C621.446045,136.170853 626.721191,129.805466 631.983765,122.772827 
	C620.386047,120.232117 615.400635,122.220993 610.451965,131.277023 
	C610.085449,131.947739 610.541626,133.068024 611.385010,133.998703 
M783.675537,122.000000 
	C777.697205,122.026192 773.244019,126.084167 770.836731,133.699417 
	C781.913879,134.717545 789.271179,130.878464 792.597961,122.000000 
	C789.823730,122.000000 787.204651,122.000000 783.675537,122.000000 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M242.016052,105.970711 
	C263.155548,94.523674 284.295013,83.076637 305.994751,71.326225 
	C305.994751,81.379303 306.137909,91.090858 305.843170,100.789108 
	C305.798035,102.274040 304.023041,104.167976 302.555267,105.077080 
	C286.981384,114.723274 271.305359,124.204453 255.670044,133.751694 
	C255.019501,134.148941 254.492950,134.749298 253.485092,135.605591 
	C241.759735,138.934692 232.027740,144.848007 222.982605,152.045410 
	C221.283447,152.554504 219.506210,152.887253 217.897079,153.599701 
	C199.333603,161.818573 180.923187,170.398773 162.209259,178.257111 
	C148.005051,184.221710 133.060059,188.266541 117.741936,189.797699 
	C92.444420,192.326324 68.036095,189.494446 47.850002,172.076050 
	C40.206745,165.480759 35.557484,156.406693 32.940273,146.498871 
	C32.800144,145.968414 32.263626,145.542664 31.536083,145.043472 
	C31.114529,135.981903 31.068523,126.945030 31.091545,117.444756 
	C32.148869,115.501671 33.393394,114.132538 34.086670,112.525574 
	C43.109154,91.612015 58.618061,76.619659 77.579979,64.799484 
	C91.911308,55.865856 107.418037,49.761086 123.987846,46.609348 
	C128.488586,45.753265 133.481018,45.260395 137.797623,46.363049 
	C141.233978,47.240841 145.204727,50.182587 146.787521,53.300037 
	C150.137329,59.897671 152.081787,67.208847 154.783981,74.732361 
	C139.243149,75.012398 125.386230,78.415382 112.255829,84.129562 
	C88.436104,94.495598 68.259766,109.319214 56.382023,133.327484 
	C54.808292,136.508453 53.682713,140.062500 53.143902,143.565933 
	C52.465164,147.979218 53.732258,151.798218 57.908787,154.570740 
	C64.146317,158.711426 71.050499,160.920502 78.278671,160.851395 
	C89.658058,160.742645 101.240448,160.801285 112.351463,158.725739 
	C128.204620,155.764359 144.185287,152.209747 159.238480,146.575928 
	C176.703613,140.039444 193.223892,130.978470 210.856293,122.871277 
	C221.710892,117.143066 231.863464,111.556885 242.016052,105.970711 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1648.910889,203.579193 
	C1635.844727,204.023453 1622.845093,204.184509 1609.855469,203.893997 
	C1605.991455,203.807556 1601.984375,202.916122 1598.347168,201.570831 
	C1592.969360,199.581741 1590.318848,194.584900 1590.179565,189.443893 
	C1589.747070,173.464737 1590.083984,157.464691 1590.142578,141.472824 
	C1590.143677,141.181595 1590.393677,140.891281 1590.664429,140.303360 
	C1609.113281,140.303360 1627.687744,140.303360 1646.631836,140.303360 
	C1646.631836,143.646255 1646.631836,146.895020 1646.631836,150.832306 
	C1632.701172,150.832306 1618.657959,150.832306 1604.315430,150.832306 
	C1604.315430,155.754562 1604.315430,160.000717 1604.315430,164.761719 
	C1617.292969,164.761719 1630.209595,164.761719 1643.560059,164.761719 
	C1643.560059,168.623734 1643.560059,171.982666 1643.560059,175.866943 
	C1630.599854,175.866943 1617.682007,175.866943 1604.059448,175.866943 
	C1604.059448,179.781281 1603.744385,183.242325 1604.139038,186.620483 
	C1604.635010,190.866013 1608.076782,192.962128 1613.935425,192.985504 
	C1625.265015,193.030716 1636.594849,192.992310 1648.382568,192.976471 
	C1648.887451,196.350647 1648.934326,199.736908 1648.910889,203.579193 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563293,31.089964 
	C548.573059,34.048737 546.026184,36.863037 543.686279,39.839977 
	C539.397400,45.296379 533.663574,42.131325 527.249329,43.313896 
	C530.307312,38.752754 532.621399,35.301231 534.950012,31.504509 
	C540.009705,31.112480 545.054871,31.065655 550.563293,31.089964 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.985931,105.602158 
	C231.863464,111.556885 221.710892,117.143066 211.214722,122.820869 
	C206.245132,110.931816 201.624420,98.949081 196.986481,86.973022 
	C196.405655,85.473206 195.733994,84.008568 194.703812,81.585228 
	C191.260071,88.850502 188.175903,95.313507 185.129532,101.794273 
	C180.415787,111.822136 175.846848,121.920212 170.987488,131.876740 
	C167.697617,138.617477 160.677338,140.707001 154.506302,142.917877 
	C146.523941,145.777695 137.985001,147.108200 129.652161,148.945099 
	C127.983139,149.313019 126.163620,148.998245 123.616508,148.998245 
	C134.574905,128.700394 145.111298,109.268021 155.564636,89.791061 
	C162.060867,77.687134 168.595032,65.597786 174.751373,53.320972 
	C176.441528,49.950489 178.355026,48.837757 181.989929,48.920994 
	C191.984268,49.149868 201.993347,49.198669 211.983704,48.894951 
	C215.601593,48.784966 217.317612,50.146221 218.675049,53.210796 
	C226.369156,70.581032 234.182831,87.898300 241.985931,105.602158 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,181.000519 
	C678.000000,188.785995 678.000000,196.071671 678.000000,203.674286 
	C673.269226,203.674286 669.025879,203.674286 664.389465,203.674286 
	C664.389465,182.625626 664.389465,161.613815 664.389465,140.474289 
	C672.582092,139.082886 679.020752,139.802673 684.663330,147.601074 
	C692.765564,158.798676 702.602722,168.742035 711.723938,179.199326 
	C712.453369,180.035522 713.373718,180.705185 714.904480,182.080322 
	C714.904480,167.746796 714.904480,154.230942 714.904480,140.362671 
	C719.989075,140.362671 724.564636,140.362671 729.573120,140.362671 
	C729.573120,161.298309 729.573120,182.310135 729.573120,203.964691 
	C725.279236,203.964691 720.628723,205.018295 716.858826,203.648422 
	C713.536072,202.441055 710.995972,198.700684 708.462097,195.774200 
	C701.444519,187.669388 694.636719,179.383347 687.691467,171.215317 
	C684.852783,167.876816 681.873779,164.657700 678.000000,160.307800 
	C678.000000,168.017731 678.000000,174.259216 678.000000,181.000519 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1547.311279,171.636124 
	C1550.689941,175.466171 1553.874512,178.996368 1557.885254,183.442474 
	C1557.885254,168.422226 1557.885254,154.566864 1557.885254,140.360565 
	C1562.664062,140.360565 1566.911011,140.360565 1571.581787,140.360565 
	C1571.581787,161.334015 1571.581787,182.364853 1571.581787,203.470551 
	C1563.074707,205.255585 1556.697266,203.764771 1551.014404,195.986267 
	C1542.848267,184.808426 1533.002197,174.858414 1523.886841,164.372589 
	C1522.831787,163.158905 1521.862305,161.870850 1520.162354,159.759399 
	C1520.162354,174.894302 1520.162354,189.071136 1520.162354,203.619354 
	C1515.376831,203.619354 1511.129883,203.619354 1506.438232,203.619354 
	C1506.438232,182.707230 1506.438232,161.676285 1506.438232,140.010025 
	C1510.595581,140.010025 1514.816284,139.455597 1518.775879,140.232193 
	C1520.916870,140.652084 1522.823608,143.159851 1524.472534,145.043793 
	C1532.090576,153.746796 1539.580933,162.561584 1547.311279,171.636124 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M566.006470,204.000000 
	C556.034607,206.156982 553.494629,197.525543 548.536682,192.807236 
	C545.700867,190.108566 543.633728,186.617783 541.015198,183.665726 
	C540.241699,182.793777 538.786072,182.131729 537.612366,182.080826 
	C533.321838,181.894775 529.018433,182.007248 524.000000,182.007248 
	C524.000000,185.825394 524.000000,189.255447 524.000000,192.685501 
	C524.000000,196.169052 524.000000,199.652603 524.000000,203.567123 
	C519.038940,203.567123 514.344238,203.567123 509.321472,203.567123 
	C509.321472,182.608276 509.321472,161.716995 509.321472,140.604248 
	C509.860596,140.429199 510.464813,140.053513 511.061127,140.065674 
	C525.154175,140.352737 539.329041,139.854156 553.313538,141.252991 
	C565.471008,142.469086 574.501343,150.554657 571.971985,165.048355 
	C570.844238,171.510498 566.477905,175.295761 560.875305,177.903854 
	C559.134766,178.714111 557.237366,179.187531 555.010315,179.953186 
	C561.667786,187.795853 567.985840,195.238693 574.303894,202.681534 
	C574.093689,203.121017 573.883545,203.560516 573.673340,204.000000 
	C571.271057,204.000000 568.868835,204.000000 566.006470,204.000000 
M553.394409,168.069855 
	C555.857666,165.652802 558.595886,163.435379 557.163513,159.257523 
	C555.731689,155.081528 552.920288,152.412155 548.632019,152.037964 
	C540.621277,151.338913 532.572998,151.069946 524.436829,150.616241 
	C524.436829,158.012878 524.436829,164.376236 524.436829,170.687744 
	C539.310913,171.982361 546.481873,171.432190 553.394409,168.069855 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M492.146667,74.800827 
	C489.429871,82.802650 483.006958,86.039864 475.534851,88.538254 
	C482.339325,96.404655 488.640167,103.688782 495.787506,111.951561 
	C489.576141,111.951561 484.455811,112.403687 479.488403,111.729332 
	C477.497559,111.459068 475.653229,108.875549 474.072174,107.057938 
	C469.745087,102.083389 465.644714,96.911789 461.325287,91.930290 
	C460.537628,91.021889 459.162506,90.158737 458.019379,90.104759 
	C453.573029,89.894768 449.110840,90.019196 444.160339,90.019196 
	C444.160339,97.378242 444.160339,104.289528 444.160339,111.597275 
	C439.305359,111.597275 434.947113,111.597275 430.291687,111.597275 
	C430.291687,90.548927 430.291687,69.660713 430.291687,48.474483 
	C431.431305,48.313175 432.538879,47.981159 433.630005,48.028442 
	C448.164581,48.658329 462.818298,48.414383 477.192200,50.297493 
	C489.202698,51.870979 495.184448,62.936153 492.146667,74.800827 
M463.330872,79.000000 
	C464.827332,78.996613 466.335449,79.112915 467.818115,78.968163 
	C472.973022,78.464836 477.693176,74.087669 477.991638,69.638382 
	C478.269470,65.497131 475.279602,61.374294 469.893799,60.601311 
	C461.500397,59.396667 452.971649,59.135132 444.285858,58.457207 
	C444.285858,65.957123 444.285858,72.191879 444.285858,79.000000 
	C450.493683,79.000000 456.432922,79.000000 463.330872,79.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M969.856995,110.635010 
	C965.417297,111.500076 961.073059,111.908478 956.338135,112.353600 
	C956.338135,90.625298 956.338135,69.716270 956.338135,48.228077 
	C957.902771,48.152988 959.498352,47.976456 961.088562,48.015976 
	C973.367065,48.321136 985.709229,47.997765 997.906067,49.172813 
	C1012.949585,50.622128 1022.713379,61.069847 1017.710083,75.391190 
	C1016.261963,79.536385 1011.468079,82.662117 1007.834167,85.825905 
	C1006.221619,87.229858 1003.680847,87.567657 1001.359009,88.467888 
	C1007.889587,96.041351 1014.316101,103.494141 1021.617188,111.961143 
	C1015.397095,111.961143 1010.492004,112.206177 1005.638000,111.816116 
	C1004.125305,111.694565 1002.490601,110.079216 1001.358093,108.763527 
	C996.621704,103.261139 992.116272,97.559776 987.370483,92.065834 
	C986.500671,91.058952 984.935547,90.173447 983.641968,90.099800 
	C979.351257,89.855553 975.037964,90.007553 969.952454,90.007553 
	C969.952454,96.985512 969.952454,103.581932 969.856995,110.635010 
M970.000000,66.564857 
	C970.000000,70.627861 970.000000,74.690872 970.000000,78.990494 
	C978.506226,78.990494 986.139221,79.169083 993.758850,78.925964 
	C998.614136,78.771057 1003.541626,74.253212 1003.978088,70.219788 
	C1004.429871,66.043266 1001.113403,61.317787 995.867554,60.581352 
	C987.480469,59.403931 978.966064,59.133682 970.000000,58.440857 
	C970.000000,61.665295 970.000000,63.628464 970.000000,66.564857 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1142.971313,95.902756 
	C1132.419312,76.610344 1142.387451,56.637260 1161.119507,50.429085 
	C1175.927612,45.521408 1190.284424,45.799507 1203.817261,54.314514 
	C1214.031372,60.741329 1219.573608,70.322357 1218.766602,82.197853 
	C1217.825195,96.048325 1209.475220,105.379738 1196.577026,109.877678 
	C1179.465698,115.844894 1163.051880,114.509430 1148.485596,102.673882 
	C1146.355103,100.942787 1144.873169,98.413483 1142.971313,95.902756 
M1198.020996,66.523735 
	C1190.762573,57.659740 1175.905029,55.279148 1164.898804,61.614853 
	C1150.316772,70.008987 1150.130981,88.813087 1162.961792,97.662857 
	C1169.342529,102.063797 1176.330811,102.739014 1183.374268,101.840584 
	C1192.260742,100.707085 1199.086670,95.956551 1202.356079,87.162910 
	C1205.120972,79.726173 1203.917480,72.967453 1198.020996,66.523735 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M581.000000,61.010628 
	C581.000000,56.550980 581.000000,52.589462 581.000000,48.791454 
	C591.900452,48.791454 602.291626,48.839478 612.682129,48.777493 
	C622.419861,48.719402 631.214478,51.534401 639.198914,57.062649 
	C652.030334,65.946800 653.897278,88.085762 643.176208,99.458717 
	C635.501465,107.600060 625.858765,111.106056 615.326294,111.823357 
	C604.222168,112.579597 593.026489,111.992867 581.000000,111.992867 
	C581.000000,94.996162 581.000000,78.252457 581.000000,61.010628 
M595.000000,76.501297 
	C595.000000,84.566406 595.000000,92.631516 595.000000,101.891228 
	C604.238281,100.904190 612.688293,100.703262 620.799011,98.940643 
	C629.185608,97.118057 634.442993,89.684822 635.079529,82.513451 
	C636.012329,72.003868 629.992065,64.805565 622.166992,61.826473 
	C613.655518,58.586056 604.538086,58.429001 595.000000,59.353970 
	C595.000000,64.940987 595.000000,70.221466 595.000000,76.501297 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1342.741943,145.660858 
	C1358.335449,136.951065 1373.952148,136.594162 1389.496826,144.319412 
	C1398.952881,149.018707 1405.574951,156.386780 1406.995728,167.417267 
	C1408.788696,181.336182 1403.046265,191.754318 1391.382690,198.731461 
	C1374.788940,208.657837 1348.870117,206.494781 1335.476318,192.195328 
	C1324.389771,180.358994 1324.970337,160.630173 1337.408569,149.865433 
	C1339.026123,148.465454 1340.753052,147.191803 1342.741943,145.660858 
M1380.247559,153.079620 
	C1372.090698,148.843307 1363.658813,148.741684 1355.441895,152.388412 
	C1346.876587,156.189682 1341.574707,165.070084 1342.624390,174.543411 
	C1343.953125,186.533173 1357.302002,195.076172 1370.046753,194.047333 
	C1379.324707,193.298386 1389.453735,185.877686 1391.670044,177.685959 
	C1394.297363,167.974686 1390.380615,159.148163 1380.247559,153.079620 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M871.259644,94.577194 
	C862.621277,76.836739 870.899353,57.098095 889.277954,50.849892 
	C906.739929,44.913315 923.289978,46.895882 936.162842,56.802773 
	C934.224426,59.500492 932.266968,62.224804 930.350342,64.892227 
	C924.311523,62.658150 918.649475,59.701817 912.640137,58.536629 
	C902.953674,56.658436 894.144348,59.736370 887.541443,67.126152 
	C882.293701,72.999405 881.513733,80.363846 884.108521,87.650459 
	C887.256836,96.491341 894.497864,100.814354 903.325562,101.810242 
	C908.299011,102.371307 913.510742,101.375076 918.550354,100.637604 
	C923.510132,99.911797 926.159790,97.075165 925.029297,91.664162 
	C924.764160,90.395218 924.866394,89.049522 924.774414,87.243347 
	C918.723877,87.243347 912.805664,87.243347 906.445679,87.243347 
	C906.445679,83.388199 906.445679,80.028793 906.445679,76.329407 
	C917.381592,76.329407 928.288025,76.329407 939.946289,76.329407 
	C939.946289,84.736900 940.239075,93.170410 939.711121,101.552200 
	C939.599060,103.330879 936.780151,105.530357 934.720581,106.502518 
	C916.474670,115.115120 898.180176,116.693115 880.578857,105.056946 
	C876.903198,102.626968 874.454224,98.341370 871.259644,94.577194 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1181.255371,169.784668 
	C1173.810913,164.967346 1172.612671,155.084854 1178.467651,149.022247 
	C1188.221191,138.922745 1200.733643,137.890625 1213.414185,139.501801 
	C1220.615723,140.416794 1227.543701,143.580582 1234.555786,145.852386 
	C1238.321533,147.072403 1239.303345,149.109192 1236.240112,152.349274 
	C1234.682861,153.996368 1233.560059,156.054337 1232.589355,157.432098 
	C1225.777954,155.162216 1219.301514,152.996002 1212.819824,150.846115 
	C1206.478394,148.742798 1200.338745,150.172546 1194.457764,152.449814 
	C1192.653564,153.148483 1191.498413,155.523178 1190.046021,157.130341 
	C1191.585571,158.428818 1192.935547,160.432144 1194.696899,160.905884 
	C1203.100952,163.166153 1211.696411,164.728500 1220.066528,167.095444 
	C1226.471680,168.906693 1233.242188,170.407196 1237.341675,176.649490 
	C1241.992065,183.730560 1240.392578,192.675400 1233.647949,197.710724 
	C1227.007568,202.668381 1219.194824,204.410477 1211.352661,204.833908 
	C1197.571655,205.578003 1184.549683,202.210892 1172.062866,194.810318 
	C1173.939941,191.815109 1175.563477,188.827347 1177.636475,186.193375 
	C1178.160278,185.527740 1180.485107,185.440659 1181.362061,186.022491 
	C1189.345825,191.319122 1198.460327,193.070175 1207.637939,193.727600 
	C1212.016357,194.041245 1216.671509,192.291016 1220.981812,190.828552 
	C1222.609863,190.276154 1224.745239,187.791351 1224.609985,186.383224 
	C1224.458984,184.809174 1222.229858,182.666931 1220.492676,182.145294 
	C1212.786499,179.831284 1204.864868,178.235611 1197.155029,175.932449 
	C1191.835815,174.343399 1186.735352,172.022125 1181.255371,169.784668 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M944.185120,190.378754 
	C948.693970,186.849045 948.866577,184.385010 944.179077,182.805298 
	C935.962952,180.036377 927.315186,178.561295 919.060608,175.888412 
	C913.199341,173.990509 907.233765,171.708466 902.119141,168.366776 
	C893.176086,162.523651 896.462769,149.676376 905.249573,144.982071 
	C924.141418,134.889359 942.478943,137.950333 960.349304,147.992661 
	C960.580994,148.122894 960.644287,148.552841 960.884216,149.043259 
	C958.865479,151.881622 956.788879,154.801392 955.119812,157.148163 
	C947.965454,154.915665 941.195068,152.685471 934.353088,150.701813 
	C927.842468,148.814194 921.586548,150.440338 915.585327,152.913452 
	C914.150818,153.504608 912.577942,155.232986 912.314758,156.667007 
	C912.143982,157.597427 914.030945,159.576630 915.342712,159.992737 
	C921.269226,161.872498 927.369995,163.198685 933.311890,165.035263 
	C940.496338,167.255890 947.780029,169.333923 954.666748,172.296722 
	C964.597534,176.569229 965.338623,192.202927 954.239014,198.763397 
	C944.777161,204.355850 934.261597,206.591003 923.282349,204.118805 
	C915.463318,202.358215 907.590210,200.549591 900.066895,197.853729 
	C893.955811,195.663940 894.149048,194.990952 897.868042,189.356003 
	C898.848145,187.870911 899.858215,186.405609 900.945374,184.796722 
	C914.674377,192.379120 928.582275,196.903076 944.185120,190.378754 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1465.001709,178.000000 
	C1461.889893,178.000000 1459.265137,178.000000 1456.323486,178.000000 
	C1456.323486,174.600571 1456.323486,171.686386 1456.323486,168.383911 
	C1467.049805,168.383911 1477.741089,168.383911 1488.987061,168.383911 
	C1488.987061,176.681747 1489.059814,184.793716 1488.897461,192.900986 
	C1488.876465,193.946091 1487.871216,195.314713 1486.928467,195.947159 
	C1470.174438,207.187057 1446.769897,208.808350 1430.335083,197.363495 
	C1410.753540,183.727219 1412.780151,155.766861 1433.701294,144.793945 
	C1451.111572,135.662491 1468.299927,137.250549 1485.758789,148.102448 
	C1483.352905,151.239990 1481.127319,154.142242 1478.656738,157.364151 
	C1471.174072,152.281967 1463.540161,149.124832 1454.718506,149.986389 
	C1441.630737,151.264587 1430.947754,160.382538 1432.160645,173.288742 
	C1433.326294,185.691895 1441.419434,192.329285 1454.899170,193.907867 
	C1457.771851,194.244263 1460.856689,193.783997 1463.707520,193.112686 
	C1474.785767,190.503937 1474.765381,190.417267 1473.637329,178.000000 
	C1470.984863,178.000000 1468.236816,178.000000 1465.001709,178.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1050.713135,150.001694 
	C1045.554077,150.686462 1040.575195,150.625244 1036.194092,152.213303 
	C1026.913330,155.577484 1022.920959,162.275177 1022.128296,171.936615 
	C1021.473572,179.917084 1028.314941,189.219269 1036.005371,191.882263 
	C1042.168457,194.016388 1048.463135,195.026520 1054.945068,193.024399 
	C1066.492798,189.457581 1065.512939,189.839890 1064.516113,178.184906 
	C1058.658813,178.184906 1052.741455,178.184906 1046.415527,178.184906 
	C1046.415527,174.660751 1046.415527,171.635818 1046.415527,168.298752 
	C1057.111572,168.298752 1067.684937,168.298752 1078.939697,168.298752 
	C1078.939697,176.813293 1079.021484,185.237610 1078.834961,193.655991 
	C1078.811890,194.696442 1077.572876,196.070908 1076.537964,196.674088 
	C1059.664185,206.509033 1038.109253,208.426224 1021.470093,197.925400 
	C1011.180115,191.431549 1006.230835,181.568466 1007.224792,169.764069 
	C1008.440918,155.321289 1017.080627,145.996902 1030.831055,141.623596 
	C1046.177246,136.742645 1060.765991,138.411316 1074.373291,147.262970 
	C1074.614136,147.419556 1074.702393,147.810516 1075.192261,148.675934 
	C1073.313721,151.320786 1071.273071,154.193710 1069.608887,156.536804 
	C1063.115723,154.236679 1057.140015,152.119843 1050.713135,150.001694 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M624.999756,140.000000 
	C631.787903,140.000000 638.076233,140.000000 644.676147,140.000000 
	C644.676147,143.731476 644.676147,146.975906 644.676147,150.899673 
	C630.624939,150.899673 616.591919,150.899673 602.287781,150.899673 
	C602.287781,155.809586 602.287781,160.052246 602.287781,164.796692 
	C615.333801,164.796692 628.242249,164.796692 641.571899,164.796692 
	C641.571899,168.646805 641.571899,172.005493 641.571899,175.871857 
	C628.590454,175.871857 615.681030,175.871857 602.058044,175.871857 
	C602.058044,179.770523 601.627747,183.259216 602.158936,186.594788 
	C602.957642,191.610153 606.919312,192.956879 611.405823,192.981995 
	C621.227234,193.036972 631.049133,192.999664 640.870850,193.000000 
	C642.672058,193.000061 644.473206,193.000000 646.635620,193.000000 
	C646.635620,196.651611 646.635620,199.898453 646.635620,203.764160 
	C644.996704,203.839264 643.243469,203.986023 641.489990,203.989197 
	C630.003601,204.010010 618.501648,204.370651 607.035400,203.889893 
	C596.667419,203.455185 591.754272,201.142227 588.601196,192.115570 
	C588.332703,191.346848 588.037048,190.529510 588.035461,189.734192 
	C588.002625,173.435226 588.010803,157.136169 588.010803,140.000000 
	C600.359924,140.000000 612.429871,140.000000 624.999756,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M749.004395,143.243286 
	C749.859436,141.851791 750.704224,140.141098 751.563721,140.133682 
	C769.510986,139.978821 787.459900,140.016785 805.701538,140.016785 
	C805.701538,143.786652 805.701538,147.032562 805.701538,150.793152 
	C791.675903,150.793152 777.758179,150.793152 763.423340,150.793152 
	C763.423340,155.655258 763.423340,160.015854 763.423340,164.900085 
	C776.437256,164.900085 789.355103,164.900085 802.631470,164.900085 
	C802.631470,168.645691 802.631470,171.894562 802.631470,175.831726 
	C789.700500,175.831726 776.656616,175.831726 763.981628,175.831726 
	C763.459045,176.786423 763.188538,177.058578 763.169495,177.347351 
	C762.253601,191.211487 763.917358,193.000000 777.694641,193.000000 
	C787.511841,193.000000 797.329041,193.000000 807.571716,193.000000 
	C807.571716,196.648819 807.571716,200.009674 807.571716,204.000000 
	C798.381165,204.000000 789.268494,204.115433 780.160522,203.958908 
	C774.213440,203.856705 768.221497,203.742813 762.342102,202.949371 
	C753.677734,201.780106 749.081238,196.148193 749.024841,187.205200 
	C748.933350,172.709747 749.000732,158.213303 749.004395,143.243286 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1321.149414,101.000000 
	C1332.114868,101.000000 1342.601440,101.000000 1353.543335,101.000000 
	C1353.543335,104.589233 1353.543335,107.946960 1353.543335,112.000000 
	C1347.287231,112.000000 1341.017334,112.000000 1334.747437,112.000000 
	C1327.418823,111.999992 1320.090210,112.020485 1312.761719,111.995087 
	C1302.397949,111.959160 1295.329834,105.676666 1295.096558,95.320465 
	C1294.748047,79.847565 1295.006226,64.360992 1295.006226,48.440819 
	C1314.281738,48.440819 1333.303711,48.440819 1352.664551,48.440819 
	C1352.664551,51.710297 1352.664551,54.957817 1352.664551,58.888924 
	C1338.639404,58.888924 1324.600830,58.888924 1310.289551,58.888924 
	C1310.289551,64.141640 1310.289551,68.718643 1310.289551,73.805695 
	C1323.006836,73.805695 1335.587280,73.805695 1348.579956,73.805695 
	C1348.579956,77.332237 1348.579956,80.359276 1348.579956,83.900513 
	C1335.897827,83.900513 1323.315430,83.900513 1310.814087,83.900513 
	C1308.833496,97.938988 1309.274536,101.554474 1321.149414,101.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M841.244812,151.004395 
	C839.853027,151.857559 838.221924,152.646072 838.142700,153.567978 
	C837.833801,157.161377 838.016724,160.797089 838.016724,164.903534 
	C851.110718,164.903534 863.688843,164.903534 876.628540,164.903534 
	C876.628540,168.638443 876.628540,171.885818 876.628540,175.812836 
	C864.048950,175.812836 851.347290,175.812836 838.035583,175.812836 
	C838.035583,179.737991 837.780823,183.036057 838.097839,186.278214 
	C838.527344,190.669876 841.828308,192.933685 847.580933,192.978668 
	C857.237732,193.054184 866.895569,192.989288 876.552979,193.010284 
	C878.176147,193.013809 879.799011,193.152512 881.701721,193.241913 
	C881.701721,196.785217 881.701721,200.029556 881.701721,204.000000 
	C874.842285,204.000000 868.076782,204.126266 861.317810,203.968048 
	C852.880188,203.770538 844.385803,203.854630 836.033081,202.846176 
	C829.262329,202.028732 824.260132,196.525681 824.131042,190.066620 
	C823.802368,173.620590 824.021667,157.163620 824.021667,140.357300 
	C842.993896,140.357300 861.559814,140.357300 880.563965,140.357300 
	C880.563965,143.631546 880.563965,146.990845 880.563965,151.001007 
	C867.577942,151.001007 854.648193,151.001007 841.244812,151.004395 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M538.000000,48.000000 
	C547.122864,48.000000 555.745728,48.000000 564.679626,48.000000 
	C564.679626,51.741348 564.679626,54.988579 564.679626,58.745007 
	C550.391357,58.745007 536.142517,58.745007 521.449463,58.745007 
	C521.449463,63.928722 521.449463,68.627487 521.449463,73.842773 
	C534.378784,73.842773 547.293823,73.842773 560.600037,73.842773 
	C560.600037,77.372673 560.600037,80.400070 560.600037,83.941765 
	C547.520935,83.941765 534.604370,83.941765 521.031616,83.941765 
	C521.031616,87.691444 520.664490,91.013947 521.119446,94.219772 
	C521.768799,98.795273 524.808350,101.017876 529.474915,101.006813 
	C539.636353,100.982727 549.797913,100.999771 559.959412,101.000000 
	C561.757629,101.000038 563.555908,101.000000 565.668823,101.000000 
	C565.668823,104.719147 565.668823,107.966850 565.668823,112.000000 
	C563.898132,112.000000 562.142761,112.000000 560.387390,112.000000 
	C548.726685,112.000000 537.065918,112.008804 525.405212,111.997581 
	C513.039429,111.985680 507.002380,106.008675 507.000549,93.796745 
	C506.998291,78.816650 507.000000,63.836552 507.000000,48.000000 
	C517.343750,48.000000 527.421875,48.000000 538.000000,48.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M355.000000,146.117081 
	C355.000000,143.999969 355.000000,142.366135 355.000000,140.808868 
	C355.814880,140.456985 356.256653,140.094940 356.691132,140.103424 
	C370.940399,140.382019 385.263947,139.889633 399.419128,141.204834 
	C418.022949,142.933365 424.026672,159.959015 417.235474,172.282486 
	C413.388824,179.262665 406.924744,183.052277 399.187286,183.889511 
	C391.478088,184.723679 383.672943,184.680252 375.908600,184.989578 
	C374.131195,185.060379 372.348572,185.000000 370.073364,185.000000 
	C370.073364,191.465836 370.073364,197.381119 370.073364,203.640640 
	C365.006958,203.640640 360.433960,203.640640 355.000000,203.640640 
	C355.000000,184.681686 355.000000,165.641022 355.000000,146.117081 
M381.494629,173.999985 
	C387.323975,173.171219 393.278717,172.825485 398.941528,171.355240 
	C403.191254,170.251923 405.069641,166.115662 404.843475,161.961121 
	C404.609283,157.659149 402.292297,154.453339 397.794067,152.782562 
	C388.703583,149.406097 379.524811,151.746155 370.321411,150.978241 
	C370.321411,159.014389 370.321411,166.257523 370.321411,174.000000 
	C373.922302,174.000000 377.216217,174.000000 381.494629,173.999985 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1133.944824,189.000000 
	C1126.794922,189.000031 1120.127319,189.185928 1113.477661,188.919540 
	C1110.281616,188.791519 1108.470337,189.872833 1107.597534,192.826599 
	C1107.505005,193.139755 1107.271973,193.408783 1107.152832,193.717133 
	C1102.781982,205.037491 1100.776123,206.065247 1087.454834,203.136627 
	C1089.283691,199.832733 1091.079712,196.786789 1092.689941,193.645645 
	C1101.071899,177.294708 1109.501221,160.966537 1117.676514,144.512466 
	C1119.283325,141.278580 1121.128540,140.260864 1124.750244,139.807312 
	C1132.629395,138.820557 1136.435181,142.100021 1139.756592,149.035629 
	C1148.256104,166.783981 1157.674316,184.092285 1166.699463,201.589783 
	C1166.904785,201.987732 1166.806519,202.542297 1166.930786,203.924210 
	C1161.935791,203.924210 1157.070923,204.031296 1152.219971,203.820297 
	C1151.397217,203.784531 1150.467041,202.489777 1149.881958,201.585480 
	C1148.986938,200.202072 1148.119507,198.722488 1147.641968,197.160904 
	C1145.609863,190.516449 1141.532104,187.360855 1133.944824,189.000000 
M1131.502930,165.004486 
	C1129.890015,161.837158 1128.276978,158.669830 1126.429932,155.042648 
	C1122.646851,162.721008 1119.116333,169.886505 1115.292236,177.648254 
	C1123.359863,177.648254 1130.313599,177.648254 1137.732178,177.648254 
	C1135.630859,173.338043 1133.756958,169.494141 1131.502930,165.004486 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M804.322327,103.300095 
	C803.544189,104.820236 802.771423,105.957214 802.416687,107.212357 
	C801.251343,111.336258 798.512085,112.476875 794.498474,112.056396 
	C791.703735,111.763596 788.853455,112.000000 785.084412,112.000000 
	C788.447754,105.236488 791.511353,98.945335 794.691650,92.713776 
	C798.527161,85.198586 802.446167,77.725807 806.358093,70.249878 
	C809.732788,63.800690 813.013977,57.295380 816.650024,50.995869 
	C817.452026,49.606373 819.470154,48.456272 821.115173,48.125591 
	C823.516663,47.642834 826.184631,47.580830 828.555481,48.130692 
	C830.535278,48.589844 833.101318,49.735104 833.955078,51.346268 
	C844.183960,70.650070 854.143127,90.096840 864.162109,109.511726 
	C864.443909,110.057770 864.607239,110.664963 865.106934,111.988022 
	C859.834717,111.988022 855.070374,112.186470 850.344299,111.848648 
	C849.161133,111.764076 847.403809,110.131897 847.093750,108.910538 
	C844.358521,98.136597 837.171448,95.439079 827.035645,96.898399 
	C822.792969,97.509254 818.337585,97.417824 814.071594,96.892693 
	C808.909302,96.257225 805.918640,98.136551 804.322327,103.300095 
M828.123840,71.370102 
	C826.959839,69.044174 825.795837,66.718239 824.237854,63.605099 
	C820.376282,71.334076 816.841431,78.409058 813.208862,85.679672 
	C820.836182,85.679672 827.703613,85.679672 835.243469,85.679672 
	C832.827148,80.776894 830.666626,76.393028 828.123840,71.370102 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M223.014984,152.418228 
	C232.027740,144.848007 241.759735,138.934692 253.447906,135.970856 
	C254.910553,137.052872 256.380219,137.929672 257.003296,139.216415 
	C264.695435,155.101700 272.264069,171.046799 280.106964,187.484604 
	C278.545319,187.687149 277.340851,187.975067 276.135437,187.979095 
	C265.494202,188.014679 254.851303,187.897125 244.212128,188.049637 
	C239.859756,188.112045 236.859726,186.491531 235.211578,182.486969 
	C231.140411,172.595093 227.100082,162.690536 223.014984,152.418228 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1125.524658,64.701340 
	C1119.884521,62.786781 1114.728149,60.053783 1109.233032,58.769489 
	C1096.661255,55.831200 1080.120483,62.565605 1078.163574,79.836578 
	C1077.353271,86.987732 1084.667725,97.257767 1092.432739,99.989723 
	C1102.682861,103.596016 1112.502441,102.996140 1121.834351,97.098923 
	C1123.200928,96.235390 1124.778809,95.706314 1126.564941,94.881111 
	C1128.724243,97.523094 1130.798828,100.061470 1133.646240,103.545204 
	C1130.214478,105.474655 1127.196533,107.676102 1123.830688,108.978416 
	C1107.309326,115.370834 1091.148071,115.106895 1076.060669,104.973015 
	C1059.731201,94.004791 1059.635498,69.627777 1074.086792,57.126804 
	C1089.752441,43.575302 1118.789795,45.150047 1132.037231,57.002632 
	C1129.987793,59.501320 1127.921143,62.021057 1125.524658,64.701340 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M363.051208,76.541733 
	C361.159790,95.625679 379.097290,105.238480 396.264893,101.280746 
	C398.971924,100.656685 401.649994,99.619797 404.132050,98.360909 
	C411.608643,94.568848 413.876556,95.442230 417.025848,104.034294 
	C409.922363,108.891647 402.050232,112.129944 393.448334,112.898369 
	C382.740814,113.854881 372.270691,112.536072 363.000824,106.463966 
	C354.277985,100.750206 348.434296,93.162827 347.980286,82.214249 
	C347.468750,69.879356 352.077759,60.255089 362.902466,54.055462 
	C379.293610,44.667755 395.843658,45.289127 412.389374,53.755615 
	C413.119202,54.129070 413.844299,54.543602 414.485413,55.048435 
	C414.996979,55.451260 415.377106,56.021004 416.142426,56.886108 
	C414.168121,59.586720 412.177307,62.309925 409.937744,65.373375 
	C401.425751,61.137199 393.375549,56.274979 383.234772,58.713772 
	C373.289673,61.105503 365.949463,65.844414 363.051208,76.541733 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1269.829712,200.377136 
	C1258.530029,194.567657 1251.738770,185.874023 1251.082764,173.386932 
	C1250.353027,159.497910 1258.460327,147.647690 1272.279785,142.442703 
	C1287.279907,136.793137 1304.325806,137.388901 1319.526245,148.452393 
	C1317.257324,151.367630 1315.001709,154.265564 1312.583252,157.372879 
	C1303.512939,151.023788 1294.230835,148.213882 1283.653198,150.976990 
	C1273.346069,153.669403 1266.167603,161.820038 1266.000488,171.610504 
	C1265.841675,180.914398 1273.699829,190.281464 1283.576050,193.003845 
	C1294.779663,196.092087 1304.361084,192.374222 1313.440308,186.372009 
	C1313.798462,186.585098 1314.134399,186.703278 1314.359985,186.930908 
	C1321.821777,194.459961 1321.148804,197.086349 1311.091309,200.922409 
	C1297.372070,206.155151 1283.751343,206.257584 1269.829712,200.377136 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M268.328186,53.283356 
	C280.868164,46.514183 293.092163,39.949863 305.994476,33.021301 
	C305.994476,42.016689 306.134949,50.400360 305.849731,58.769527 
	C305.804688,60.092220 304.092499,61.821255 302.719269,62.564289 
	C288.044067,70.504745 273.379272,78.476097 258.505615,86.033630 
	C250.058136,90.325935 245.542526,88.555565 241.556274,79.974327 
	C239.586670,75.734398 238.283203,71.185028 236.591019,66.535736 
	C248.945145,65.694275 258.005981,58.480034 268.328186,53.283356 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M476.250793,149.204132 
	C482.286041,139.773575 485.628143,138.489899 499.335388,140.626755 
	C494.411194,146.772476 489.804108,152.643051 485.064911,158.404984 
	C479.823334,164.777664 474.381866,170.987671 469.225281,177.426956 
	C468.318329,178.559494 468.078430,180.465530 468.052338,182.023087 
	C467.933197,189.139893 468.000031,196.259781 468.000031,203.684280 
	C463.248169,203.684280 459.004089,203.684280 454.000000,203.684280 
	C454.000000,197.878708 453.632172,192.089340 454.107544,186.370056 
	C454.547852,181.073135 452.347717,177.317566 449.122467,173.545486 
	C440.544434,163.513016 432.161682,153.313232 423.743256,143.145233 
	C423.150330,142.429092 422.900208,141.429169 422.229004,140.006516 
	C427.721436,140.006516 433.034180,138.866638 437.459991,140.358292 
	C441.165283,141.607101 444.107513,145.695755 446.901917,148.944687 
	C451.832703,154.677567 456.393585,160.728607 461.508728,167.147614 
	C466.486450,161.079605 471.248413,155.274628 476.250793,149.204132 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M690.000000,53.967209 
	C690.000000,51.875771 690.000000,50.251545 690.000000,48.316040 
	C711.079712,48.316040 731.653137,48.316040 752.614014,48.316040 
	C752.614014,51.606392 752.614014,54.850773 752.614014,58.795944 
	C745.070496,58.795944 737.358337,58.795944 729.156372,58.795944 
	C729.156372,76.717667 729.156372,93.963524 729.156372,111.601700 
	C723.960083,111.601700 719.264282,111.601700 714.055969,111.601700 
	C714.055969,94.180527 714.055969,76.934044 714.055969,59.183010 
	C705.983398,59.183010 698.398987,59.183010 690.392700,59.183010 
	C690.235840,57.286060 690.117920,55.860241 690.000000,53.967209 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1275.966064,112.000000 
	C1261.206055,112.000000 1246.939575,112.000000 1232.338623,112.000000 
	C1232.338623,90.653343 1232.338623,69.772400 1232.338623,48.444946 
	C1236.602173,48.444946 1240.958008,48.444946 1245.803955,48.444946 
	C1245.803955,65.686951 1245.803955,82.911972 1245.803955,100.794441 
	C1259.276978,100.794441 1272.299561,100.794441 1285.654785,100.794441 
	C1285.654785,104.687019 1285.654785,107.931557 1285.654785,112.000000 
	C1282.622437,112.000000 1279.541138,112.000000 1275.966064,112.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,99.983932 
	C678.000000,104.103394 678.000000,107.726440 678.000000,111.668594 
	C672.949951,111.668594 668.377563,111.668594 663.400146,111.668594 
	C663.400146,90.660240 663.400146,69.661888 663.400146,48.330559 
	C668.051880,48.330559 672.624268,48.330559 678.000000,48.330559 
	C678.000000,65.385750 678.000000,82.436630 678.000000,99.983932 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1035.000488,52.195068 
	C1035.868652,50.484230 1036.621094,48.307301 1037.624634,48.184578 
	C1041.185059,47.749187 1044.831177,48.014751 1048.713379,48.014751 
	C1048.713379,69.455246 1048.713379,90.339188 1048.713379,111.612152 
	C1044.274902,111.612152 1039.917969,111.612152 1035.000000,111.612152 
	C1035.000000,91.833908 1035.000000,72.252541 1035.000488,52.195068 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M611.000122,133.989014 
	C610.541626,133.068024 610.085449,131.947739 610.451965,131.277023 
	C615.400635,122.220993 620.386047,120.232117 631.983765,122.772827 
	C626.721191,129.805466 621.446045,136.170853 611.000122,133.989014 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M784.130554,122.000000 
	C787.204651,122.000000 789.823730,122.000000 792.597961,122.000000 
	C789.271179,130.878464 781.913879,134.717545 770.836731,133.699417 
	C773.244019,126.084167 777.697205,122.026192 784.130554,122.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M553.107910,168.325684 
	C546.481873,171.432190 539.310913,171.982361 524.436829,170.687744 
	C524.436829,164.376236 524.436829,158.012878 524.436829,150.616241 
	C532.572998,151.069946 540.621277,151.338913 548.632019,152.037964 
	C552.920288,152.412155 555.731689,155.081528 557.163513,159.257523 
	C558.595886,163.435379 555.857666,165.652802 553.107910,168.325684 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M462.851501,79.000000 
	C456.432922,79.000000 450.493683,79.000000 444.285858,79.000000 
	C444.285858,72.191879 444.285858,65.957123 444.285858,58.457207 
	C452.971649,59.135132 461.500397,59.396667 469.893799,60.601311 
	C475.279602,61.374294 478.269470,65.497131 477.991638,69.638382 
	C477.693176,74.087669 472.973022,78.464836 467.818115,78.968163 
	C466.335449,79.112915 464.827332,78.996613 462.851501,79.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M970.000000,66.078247 
	C970.000000,63.628464 970.000000,61.665295 970.000000,58.440857 
	C978.966064,59.133682 987.480469,59.403931 995.867554,60.581352 
	C1001.113403,61.317787 1004.429871,66.043266 1003.978088,70.219788 
	C1003.541626,74.253212 998.614136,78.771057 993.758850,78.925964 
	C986.139221,79.169083 978.506226,78.990494 970.000000,78.990494 
	C970.000000,74.690872 970.000000,70.627861 970.000000,66.078247 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1198.281006,66.780289 
	C1203.917480,72.967453 1205.120972,79.726173 1202.356079,87.162910 
	C1199.086670,95.956551 1192.260742,100.707085 1183.374268,101.840584 
	C1176.330811,102.739014 1169.342529,102.063797 1162.961792,97.662857 
	C1150.130981,88.813087 1150.316772,70.008987 1164.898804,61.614853 
	C1175.905029,55.279148 1190.762573,57.659740 1198.281006,66.780289 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M595.000000,76.001617 
	C595.000000,70.221466 595.000000,64.940987 595.000000,59.353970 
	C604.538086,58.429001 613.655518,58.586056 622.166992,61.826473 
	C629.992065,64.805565 636.012329,72.003868 635.079529,82.513451 
	C634.442993,89.684822 629.185608,97.118057 620.799011,98.940643 
	C612.688293,100.703262 604.238281,100.904190 595.000000,101.891228 
	C595.000000,92.631516 595.000000,84.566406 595.000000,76.001617 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1380.579468,153.259705 
	C1390.380615,159.148163 1394.297363,167.974686 1391.670044,177.685959 
	C1389.453735,185.877686 1379.324707,193.298386 1370.046753,194.047333 
	C1357.302002,195.076172 1343.953125,186.533173 1342.624390,174.543411 
	C1341.574707,165.070084 1346.876587,156.189682 1355.441895,152.388412 
	C1363.658813,148.741684 1372.090698,148.843307 1380.579468,153.259705 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M381.002380,174.000000 
	C377.216217,174.000000 373.922302,174.000000 370.321411,174.000000 
	C370.321411,166.257523 370.321411,159.014389 370.321411,150.978241 
	C379.524811,151.746155 388.703583,149.406097 397.794067,152.782562 
	C402.292297,154.453339 404.609283,157.659149 404.843475,161.961121 
	C405.069641,166.115662 403.191254,170.251923 398.941528,171.355240 
	C393.278717,172.825485 387.323975,173.171219 381.002380,174.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1131.692871,165.327362 
	C1133.756958,169.494141 1135.630859,173.338043 1137.732178,177.648254 
	C1130.313599,177.648254 1123.359863,177.648254 1115.292236,177.648254 
	C1119.116333,169.886505 1122.646851,162.721008 1126.429932,155.042648 
	C1128.276978,158.669830 1129.890015,161.837158 1131.692871,165.327362 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M828.314941,71.689636 
	C830.666626,76.393028 832.827148,80.776894 835.243469,85.679672 
	C827.703613,85.679672 820.836182,85.679672 813.208862,85.679672 
	C816.841431,78.409058 820.376282,71.334076 824.237854,63.605099 
	C825.795837,66.718239 826.959839,69.044174 828.314941,71.689636 
z"
      />
    </svg>
  );
};

CaPyreneesGascogneSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaPyreneesGascogneSvg;
