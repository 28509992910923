import React from 'react';
import CaBriePicardieSvg from './svg/icon-ca-brie-picardie-svg';
import CaAlsaceVosgesSvg from './svg/icon-ca-alsace-vosges-svg';
import CaCentreEstSvg from './svg/icon-ca-centre-est-svg';
import CaCentreOuestSvg from './svg/icon-ca-centre-ouest-svg';
import CaDesSavoieSvg from './svg/icon-ca-des-savoie-svg';
import CaLanguedocSvg from './svg/icon-ca-languedoc-svg';
import CaNordEstSvg from './svg/icon-ca-nord-est-svg';
import CaNordMidiPyrenesSvg from './svg/icon-ca-nord-midi-pyrenees-svg';
import CaNormandieSvg from './svg/icon-ca-normandie-svg';
import CaPyreneesGascogneSvg from './svg/icon-ca-pyrenees-gascogne-svg';
import CaHauteLoireSvg from './svg/icon-ca-loire-haute-loire-svg';
import CaCentreLoireSvg from './svg/icon-ca-centre-loire-svg';
import CaTourainePoitouSvg from './svg/icon-ca-touraine-poitou-svg';
import CaAquitaineSvg from './svg/icon-ca-aquitaine-svg';
import CaNordDeFranceSvg from './svg/icon-ca-nord-de-france-svg';
import CaValDeFranceSvg from './svg/icon-ca-valdefrance-svg';
import CaAlpesProvenceSvg from './svg/icon-ca-alpesprovence-svg';
import LCLSvg from './svg/icon-lcl-svg';
import NortiaSvg from './svg/icon-nortia-svg';
import BforBankSvg from './svg/icon-bforbank-svg';
import './styles.scss';

const mappingBankIcon = {
  'ca-alsace-vosges': <CaAlsaceVosgesSvg alt="logo ca_alsace_vosges" width="300px" height="50px" />,
  'ca-norddefrance': <CaNordDeFranceSvg alt="logo ca_nord_de_france" width="330px" height="50px" />,
  'ca-centrest': <CaCentreEstSvg alt="logo ca_centre_est" width="300px" height="50px" />,
  //'ca-paris': (),
  //'ca-anjou-maine': (),
  'ca-aquitaine': <CaAquitaineSvg alt="logo ca_aquitaine" width="370px" height="50px" />,
  'ca-normandie': <CaNormandieSvg alt="logo ca_normandie" width="370px" height="50px" />,
  'ca-nord-est': <CaNordEstSvg alt="logo ca_nord_est" width="370px" height="50px" />,
  'ca-tourainepoitou': (
    <CaTourainePoitouSvg alt="logo ca_touraine_poitou" width="370px" height="50px" />
  ),
  'ca-briepicardie': <CaBriePicardieSvg alt="logo ca_brie_picardie" width="370px" height="50px" />,
  'ca-nmp': <CaNordMidiPyrenesSvg alt="logo ca_nord_midi_pyrenees" width="370px" height="50px" />,
  'ca-des-savoie': <CaDesSavoieSvg alt="logo ca_des_savoie" width="300px" height="50px" />,
  'ca-loirehauteloire': (
    <CaHauteLoireSvg alt="logo ca_loire_haute_loire" width="180px" height="50px" />
  ),
  'ca-centreloire': <CaCentreLoireSvg alt="logo ca_centre_loire" width="300px" height="50px" />,
  'ca-centreouest': <CaCentreOuestSvg alt="logo ca_centre_ouest" width="300px" height="50px" />,
  'ca-languedoc': <CaLanguedocSvg alt="logo ca_languedoc" width="370px" height="50px" />,
  'ca-pyrenees-gascogne': (
    <CaPyreneesGascogneSvg alt="logo ca_pyrenees_gascogne" width="370px" height="50px" />
  ),
  'ca-valdefrance': <CaValDeFranceSvg alt="logo ca_valdefrance" width="370px" height="50px" />,
  'ca-alpesprovence': <CaAlpesProvenceSvg alt="logo ca_valdefrance" width="370px" height="50px" />,
  lcl: <LCLSvg alt="logo lcl" />,
  nortia: <NortiaSvg alt="logo nortia" />,
  bforbank: <BforBankSvg alt="logo bforbank" />,
};

/**
 * Returns email icon and email status value to display emails in dashboard timeline
 * @returns {object} With two properties (bank icon)
 * @param subdomain
 */
export const getBankIcon = subdomain => {
  const icon = mappingBankIcon[subdomain] || null;
  if (process.env.NODE_ENV === 'development' && icon === null) {
    console.error('no svg icon associated to subdomain : ', subdomain);
  }
  return {
    icon: icon,
  };
};

export const cssStringToJson = cssText => {
  const cssJson = `{"${cssText.replace(/; /g, '","').replace(/:/g, '":"').replace(';', '')}"}`;
  console.error(cssJson);
  const obj = JSON.parse(cssJson);

  const keyValues = Object.keys(obj).map(key => {
    var camelCased = key.replace(/-[a-z]/g, g => g[1].toUpperCase());
    return { [camelCased]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};
