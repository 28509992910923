import React from 'react';
import PropTypes from 'prop-types';
import { getBankIcon } from './utils';
import './styles.scss';

const IconBank = props => {
  const { bankDomain } = props;

  return <div className="bank__logo">{getBankIcon(bankDomain).icon}</div>;
};

IconBank.propTypes = {
  bankDomain: PropTypes.string,
};

export default IconBank;
