import * as React from 'react';
import PropTypes from 'prop-types';

const CaTourainePoitouSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 2093 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M2063.842773,177.044876 
	C2063.842773,193.181519 2063.842773,209.318146 2063.842773,225.788010 
	C2062.500977,225.861145 2061.227539,225.991425 2059.954102,225.991440 
	C1469.622070,225.996384 879.289978,225.992493 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396374,141.702835 
	C35.163578,159.090759 44.555679,172.322098 60.492535,180.820984 
	C81.032387,191.774612 103.036148,192.215927 125.145378,188.720566 
	C149.023453,184.945557 171.210312,175.552170 193.040466,165.455276 
	C202.704987,160.985245 212.311127,156.388947 222.249832,152.052338 
	C226.693497,162.138870 230.823563,172.026230 234.972198,181.905762 
	C236.766739,186.179230 239.908508,188.119324 244.638489,188.048050 
	C254.961838,187.892487 265.289154,188.014175 275.614746,187.982758 
	C276.989410,187.978577 278.363342,187.742661 280.272491,187.564011 
	C275.070740,176.831482 270.096161,166.762253 265.300598,156.608459 
	C261.783813,149.162247 258.501831,141.605118 255.422760,133.933243 
	C271.511902,124.169968 287.313049,114.602600 303.037750,104.911209 
	C304.288544,104.140327 305.820190,102.530052 305.849548,101.277725 
	C306.080719,91.412811 305.977905,81.540070 305.977905,71.332542 
	C284.290497,83.077248 263.153290,94.524002 241.985916,105.602203 
	C234.182739,87.898232 226.368973,70.580856 218.674835,53.210503 
	C217.317307,50.145775 215.600922,48.785046 211.983292,48.895000 
	C201.992905,49.198650 191.983810,49.149979 181.989441,48.920982 
	C178.354187,48.837692 176.441223,49.951389 174.751221,53.321468 
	C168.594742,65.598244 162.060623,77.687630 155.564392,89.791588 
	C145.111069,109.268486 134.574707,128.700836 123.616600,148.998199 
	C126.164070,148.998199 127.983742,149.313034 129.652878,148.945084 
	C137.985733,147.108109 146.524750,145.777634 154.507065,142.917603 
	C160.677963,140.706573 167.698105,138.616974 170.987793,131.875916 
	C175.846741,121.919182 180.407639,111.817322 185.132401,101.794678 
	C188.186081,95.316910 191.305756,88.870255 194.744019,81.681763 
	C195.733902,84.009399 196.405746,85.473572 196.986450,86.973030 
	C201.624435,98.949081 206.245163,110.931831 210.512756,122.962914 
	C189.828476,132.143051 169.925980,142.443024 149.031952,150.002365 
	C134.440201,155.281570 118.566650,157.253784 103.125099,159.875336 
	C90.238953,162.063034 77.146912,162.520355 64.509781,157.946045 
	C60.082073,156.343323 54.962238,154.749283 53.989147,149.383835 
	C53.325432,145.724243 53.581627,141.556625 54.654526,137.985062 
	C58.377323,125.592270 66.699860,116.267944 75.896706,107.542961 
	C90.639534,93.556541 107.908005,84.159714 127.461342,79.068741 
	C136.080704,76.824577 144.947952,75.532379 154.102783,73.729172 
	C152.813553,69.588219 151.456070,65.072983 150.002625,60.588863 
	C145.760345,47.500607 138.097534,43.214226 124.576385,46.225288 
	C108.322014,49.845020 92.799988,55.385311 78.581932,64.152145 
	C56.463665,77.790237 39.016514,95.400688 31.158739,120.975662 
	C31.158739,91.187675 31.158739,61.399689 31.158739,31.011251 
	C198.801392,31.011251 366.421753,31.011251 534.488770,31.430481 
	C532.621399,35.301231 530.307312,38.752754 527.249329,43.313896 
	C533.663574,42.131325 539.397400,45.296379 543.686279,39.839977 
	C546.026184,36.863037 548.573059,34.048737 551.026611,31.161102 
	C552.855347,31.107405 554.684082,31.006666 556.512817,31.006641 
	C1057.029785,30.999828 1557.546753,31.000000 2058.063721,31.000000 
	C2059.868408,31.000000 2061.673096,31.000000 2063.988770,31.000000 
	C2063.988770,67.191475 2063.988770,103.116814 2063.459473,139.515381 
	C2058.689941,139.988602 2054.449951,139.988602 2050.000000,139.988602 
	C2050.000000,150.383148 2050.097656,159.863297 2049.951904,169.339737 
	C2049.893799,173.113358 2049.705078,176.973190 2048.859375,180.628067 
	C2047.107544,188.200424 2038.917480,194.101151 2030.571167,193.958725 
	C2022.348267,193.818420 2014.240967,188.928726 2012.620850,181.114914 
	C2012.223267,179.196716 2012.035400,177.202301 2012.026367,175.241623 
	C2011.972534,163.652847 2012.000000,152.063721 2012.000000,140.440933 
	C2006.638306,140.440933 2001.943359,140.440933 1997.005737,140.440933 
	C1997.005737,153.018829 1996.453735,165.191254 1997.190552,177.285172 
	C1997.723877,186.039444 2001.265015,193.970901 2009.354736,198.854980 
	C2017.996460,204.072281 2027.202026,205.400055 2037.283936,204.160812 
	C2048.744873,202.752075 2059.181152,195.130951 2062.394775,184.126160 
	C2063.067871,181.821381 2063.369873,179.408127 2063.842773,177.044876 
M1164.000000,154.505188 
	C1164.000000,163.743759 1164.000000,172.982330 1164.000000,183.485413 
	C1161.609619,180.664734 1160.069336,178.832336 1158.513306,177.013321 
	C1148.850098,165.716522 1139.214233,154.396042 1129.475952,143.164398 
	C1128.348389,141.863983 1126.717285,140.319809 1125.195190,140.172150 
	C1120.970703,139.762375 1116.680542,140.030029 1112.288086,140.030029 
	C1112.288086,161.621292 1112.288086,182.525772 1112.288086,203.596008 
	C1117.278931,203.596008 1121.972168,203.596008 1127.206909,203.596008 
	C1127.206909,189.367615 1127.206909,175.534576 1127.206909,160.759781 
	C1128.771606,162.766739 1129.610962,163.957642 1130.564453,165.048782 
	C1140.030640,175.880997 1149.579102,186.641861 1158.971436,197.537445 
	C1164.788086,204.285034 1169.374878,205.767776 1177.618408,203.136581 
	C1177.618408,182.185745 1177.618408,161.280243 1177.618408,140.321335 
	C1172.987671,140.321335 1168.741943,140.321335 1164.000000,140.321335 
	C1164.000000,144.923584 1164.000000,149.216171 1164.000000,154.505188 
M968.242676,202.744995 
	C973.661987,203.320129 979.081299,203.895279 984.500610,204.470413 
	C984.700073,203.948486 984.899536,203.426559 985.098999,202.904633 
	C982.063354,199.107361 979.108215,195.241943 975.969421,191.531906 
	C972.799316,187.784866 969.447144,184.191895 965.869629,180.190170 
	C967.761353,179.398819 969.045837,178.653717 970.429871,178.314285 
	C985.908203,174.518188 986.673645,153.332947 977.061951,146.652451 
	C974.530762,144.893173 971.657715,143.470352 968.748169,142.439011 
	C958.478516,138.798676 947.758240,140.220963 937.191895,140.018951 
	C931.595825,139.911957 925.996033,140.000000 920.295471,140.000000 
	C920.295471,161.633240 920.295471,182.538574 920.295471,203.588226 
	C925.295288,203.588226 929.988953,203.588226 935.187073,203.588226 
	C935.187073,196.264221 935.187073,189.346115 935.187073,182.000076 
	C939.325867,182.000076 942.960999,182.086319 946.589661,181.971298 
	C949.231567,181.887543 950.928772,182.558655 952.753296,184.937607 
	C957.373474,190.961655 962.671021,196.466095 968.242676,202.744995 
M420.536255,187.882004 
	C421.119019,186.692902 421.724243,185.514175 422.281097,184.313065 
	C427.942017,172.103043 424.384216,156.827805 413.494720,148.884262 
	C406.439972,143.738037 398.616638,140.917786 390.083313,140.630035 
	C378.543274,140.240906 366.980255,140.533310 355.448212,140.533310 
	C355.448212,161.731781 355.448212,182.743469 355.448212,202.988770 
	C368.823517,202.988770 381.804413,203.760742 394.643494,202.743271 
	C404.820984,201.936722 414.095337,197.664581 420.536255,187.882004 
M454.484558,48.000000 
	C446.402161,48.000000 438.319733,48.000000 430.379211,48.000000 
	C430.379211,69.794182 430.379211,90.707047 430.379211,111.679016 
	C435.008820,111.679016 439.255768,111.679016 444.186005,111.679016 
	C444.186005,104.329247 444.186005,97.279808 444.186005,90.004395 
	C449.032623,90.004395 453.201996,89.824310 457.340790,90.101463 
	C458.801880,90.199303 460.578522,91.073448 461.542633,92.177086 
	C466.319397,97.645073 470.808014,103.364052 475.552094,108.861771 
	C476.673767,110.161652 478.330078,111.714600 479.842407,111.831406 
	C484.732056,112.209084 489.669128,111.972351 495.830170,111.972351 
	C491.925903,107.429626 488.872040,103.848663 485.787109,100.294678 
	C482.555267,96.571472 479.293549,92.874176 475.664246,88.731041 
	C477.376312,88.009392 478.679932,87.476547 479.970245,86.913239 
	C487.064575,83.816200 492.312958,79.184845 492.987762,70.898842 
	C493.718811,61.922165 489.807068,55.653843 481.701874,51.894943 
	C473.341736,48.017838 464.411987,47.995842 454.484558,48.000000 
M1012.434753,83.037979 
	C1024.044556,71.552574 1020.379211,56.027946 1004.664856,50.918812 
	C1000.340393,49.512798 995.723511,48.444267 991.206726,48.301918 
	C979.635376,47.937237 968.044922,48.180218 956.428345,48.180218 
	C956.428345,69.693565 956.428345,90.705917 956.428345,111.635040 
	C961.105713,111.635040 965.348816,111.635040 970.088013,111.635040 
	C970.088013,104.365829 970.088013,97.450554 970.088013,90.014030 
	C975.029175,90.014030 979.488770,89.853539 983.923279,90.115860 
	C985.186218,90.190559 986.664795,91.245827 987.551880,92.275772 
	C992.285156,97.770821 996.771912,103.479713 1001.556396,108.928131 
	C1002.762268,110.301369 1004.684448,111.704689 1006.387146,111.855316 
	C1010.956604,112.259552 1015.585449,111.993256 1021.665283,111.993256 
	C1014.381470,103.531761 1007.910095,96.014030 1001.609497,88.694733 
	C1005.158569,86.912376 1008.523804,85.222343 1012.434753,83.037979 
M1139.000000,82.339127 
	C1138.744263,90.770851 1142.645752,97.626122 1148.659546,102.693779 
	C1163.012329,114.788338 1179.611450,115.817787 1196.676392,109.850876 
	C1209.550903,105.349228 1217.830078,96.019432 1218.802124,82.127670 
	C1219.641357,70.137177 1213.843994,60.788754 1203.786743,54.289726 
	C1193.952148,47.934662 1183.227417,46.619526 1171.637695,47.853455 
	C1155.218140,49.601589 1136.513794,62.871830 1139.000000,82.339127 
M749.444580,158.081894 
	C742.653992,170.243378 746.136475,184.762161 755.403748,193.980270 
	C767.579834,206.091827 795.090210,209.555344 812.833374,196.843964 
	C828.455872,185.651779 830.899902,163.461792 816.743958,150.433060 
	C806.221863,140.748871 793.396545,137.711395 779.129211,139.761383 
	C766.718506,141.544586 756.420715,146.265823 749.444580,158.081894 
M581.000000,80.500000 
	C581.000000,90.914360 581.000000,101.328712 581.000000,111.993050 
	C593.019958,111.993050 604.214600,112.579514 615.317932,111.823624 
	C625.849792,111.106651 635.494202,107.605537 643.169739,99.464874 
	C653.893433,88.091385 652.036255,65.950638 639.207336,57.066811 
	C631.224731,51.538921 622.431458,48.718838 612.691345,48.780628 
	C602.300354,48.846546 591.908630,48.795471 581.000000,48.795471 
	C581.000000,58.917351 581.000000,69.208679 581.000000,80.500000 
M1501.385010,140.995285 
	C1488.370239,140.995285 1475.355469,140.995285 1462.327759,140.995285 
	C1462.327759,161.711853 1462.327759,182.611404 1462.327759,203.083649 
	C1472.893433,203.083649 1483.124634,202.986282 1493.353149,203.114548 
	C1501.480225,203.216476 1509.014282,201.314407 1516.138916,197.436279 
	C1530.621948,189.552902 1535.573486,169.598907 1526.499512,155.861664 
	C1520.701782,147.084320 1512.033081,143.188690 1501.385010,140.995285 
M1954.189941,203.975052 
	C1960.343506,201.358475 1967.089111,199.623413 1972.541504,195.962555 
	C1989.966797,184.262756 1990.063721,157.862305 1970.248535,145.749207 
	C1956.709351,137.472778 1942.166382,136.889618 1927.328735,142.043625 
	C1913.826294,146.733826 1904.482910,159.215118 1905.061157,172.332123 
	C1905.613892,184.868866 1912.415283,193.688477 1923.116211,199.497787 
	C1932.548950,204.618698 1942.771240,205.698074 1954.189941,203.975052 
M1774.395630,203.049026 
	C1776.736694,202.291550 1779.182007,201.760956 1781.402832,200.741379 
	C1803.029175,190.812302 1808.490967,166.769852 1792.950317,150.747009 
	C1777.461182,134.777328 1750.955322,136.078979 1735.196411,146.421799 
	C1718.854248,157.147354 1717.081299,181.707901 1731.411011,193.996536 
	C1743.914673,204.719254 1758.190796,206.201950 1774.395630,203.049026 
M920.213867,111.978050 
	C925.818970,109.645775 931.483643,107.441589 936.984924,104.885803 
	C938.263794,104.291672 939.794983,102.525688 939.834229,101.256432 
	C940.089600,92.994637 939.965332,84.721123 939.965332,76.277092 
	C928.408875,76.277092 917.498474,76.277092 906.394287,76.277092 
	C906.394287,79.922279 906.394287,83.282982 906.394287,87.149010 
	C912.701172,87.149010 918.621704,87.149010 924.999146,87.149010 
	C924.999146,89.950302 925.453064,92.371628 924.864136,94.505096 
	C924.336975,96.414558 923.041260,98.905449 921.427734,99.588806 
	C912.838806,103.226364 903.929077,103.267891 895.382996,99.583397 
	C888.201721,96.487289 884.176880,90.383057 883.095947,82.767960 
	C881.953430,74.719971 884.929749,67.718224 891.627930,63.333557 
	C901.221436,57.053627 911.684509,56.258030 922.289124,61.190044 
	C930.554932,65.034348 930.546692,65.060524 935.590576,57.678692 
	C935.758362,57.433121 935.733154,57.055668 935.892761,56.276939 
	C934.588074,55.358978 933.234375,54.151268 931.672791,53.347511 
	C917.002441,45.796658 901.880798,45.184490 886.865540,51.739620 
	C876.247498,56.375080 869.087219,64.504211 868.071045,76.551537 
	C866.888916,90.566566 872.136963,101.452858 885.040222,108.006409 
	C895.973328,113.559288 907.509338,114.165344 920.213867,111.978050 
M457.600586,151.000000 
	C469.545929,151.000000 481.491272,151.000000 493.597839,151.000000 
	C493.597839,147.059525 493.597839,143.700089 493.597839,140.332703 
	C474.288757,140.332703 455.383759,140.332703 436.060883,140.332703 
	C436.060883,156.281128 435.392731,171.939270 436.282837,187.508316 
	C436.936310,198.938263 443.915161,203.988159 455.668671,203.999237 
	C460.499542,204.003799 465.330414,204.000000 470.161285,204.000000 
	C478.270996,204.000000 486.380676,204.000000 494.573914,204.000000 
	C494.573914,200.010178 494.573914,196.651398 494.573914,193.000000 
	C492.331055,193.000000 490.513245,193.000015 488.695435,192.999985 
	C479.033691,192.999832 469.371460,193.055664 459.710449,192.971207 
	C455.981720,192.938629 451.935516,192.535858 451.235596,187.946457 
	C450.653503,184.129593 451.116394,180.153351 451.116394,175.759888 
	C464.461761,175.759888 477.044617,175.759888 489.686523,175.759888 
	C489.686523,172.002487 489.686523,168.755814 489.686523,164.834457 
	C476.682373,164.834457 463.977631,164.834457 451.364868,164.834457 
	C451.364868,159.900009 451.364868,155.654922 451.364868,151.000000 
	C453.362701,151.000000 454.998474,151.000000 457.600586,151.000000 
M1338.498291,204.000000 
	C1343.917480,204.000000 1349.336792,204.000000 1354.628052,204.000000 
	C1354.628052,199.888290 1354.628052,196.639252 1354.628052,193.000000 
	C1352.458984,193.000000 1350.651611,193.000076 1348.844360,193.000000 
	C1339.679565,192.999557 1330.507202,193.216492 1321.353271,192.900482 
	C1317.208008,192.757385 1312.168945,192.684128 1311.150635,187.162445 
	C1310.508789,183.682648 1311.030762,179.988205 1311.030762,175.901459 
	C1324.311401,175.901459 1336.897583,175.901459 1349.576538,175.901459 
	C1349.576538,172.017227 1349.576538,168.657639 1349.576538,164.792313 
	C1336.575439,164.792313 1323.990967,164.792313 1311.301270,164.792313 
	C1311.301270,160.030472 1311.301270,155.784393 1311.301270,150.858521 
	C1325.630127,150.858521 1339.673218,150.858521 1353.644043,150.858521 
	C1353.644043,146.914658 1353.644043,143.667465 1353.644043,140.293579 
	C1334.377563,140.293579 1315.468262,140.293579 1296.100708,140.293579 
	C1296.100708,156.028976 1295.332153,171.527313 1296.357666,186.906052 
	C1297.101685,198.062469 1300.969604,201.425934 1312.180786,202.982666 
	C1320.506592,204.138779 1329.051514,203.718262 1338.498291,204.000000 
M1230.499756,165.000000 
	C1223.753540,165.000000 1217.007202,165.000000 1210.364746,165.000000 
	C1210.364746,159.894058 1210.364746,155.651001 1210.364746,150.917816 
	C1224.631104,150.917816 1238.541748,150.917816 1252.593506,150.917816 
	C1252.593506,147.048386 1252.593506,143.690033 1252.593506,140.328751 
	C1233.618896,140.328751 1215.052734,140.328751 1196.246582,140.328751 
	C1196.153809,142.100250 1196.011841,143.565842 1196.010132,145.031586 
	C1195.993286,159.185013 1195.977051,173.338516 1196.008545,187.491913 
	C1196.027222,195.871811 1200.154541,201.519119 1208.456787,202.894821 
	C1214.773315,203.941483 1221.303833,203.845062 1227.744385,203.959976 
	C1236.674072,204.119308 1245.608643,204.000000 1254.550415,204.000000 
	C1254.550415,199.963974 1254.550415,196.604416 1254.550415,192.999573 
	C1242.288452,192.999573 1230.474121,192.943146 1218.660645,193.029831 
	C1214.463257,193.060608 1211.214600,191.333359 1210.391113,187.365921 
	C1209.649780,183.794205 1210.234863,179.947174 1210.234863,175.737000 
	C1223.825562,175.737000 1236.736572,175.737000 1249.678223,175.737000 
	C1249.678223,171.981216 1249.678223,168.736298 1249.678223,165.000000 
	C1243.408813,165.000000 1237.454224,165.000000 1230.499756,165.000000 
M1295.000000,59.519646 
	C1295.000000,70.838890 1294.963989,82.158279 1295.010010,93.477341 
	C1295.057861,105.202415 1301.834351,111.987038 1313.422729,111.998039 
	C1323.410400,112.007523 1333.397949,112.000000 1343.385620,112.000000 
	C1346.808838,112.000000 1350.232178,112.000000 1353.675537,112.000000 
	C1353.675537,107.977150 1353.675537,104.731361 1353.675537,101.000000 
	C1343.743408,101.000000 1334.127441,101.000000 1324.511475,101.000000 
	C1309.822388,101.000000 1309.372192,100.532501 1310.065308,85.867134 
	C1310.087036,85.406662 1310.349854,84.957581 1310.710327,83.868141 
	C1323.292603,83.868141 1335.995728,83.868141 1348.658936,83.868141 
	C1348.658936,80.279121 1348.658936,77.364876 1348.658936,73.789574 
	C1335.631836,73.789574 1322.933472,73.789574 1310.378418,73.789574 
	C1310.378418,68.530899 1310.378418,63.956158 1310.378418,58.895599 
	C1324.655884,58.895599 1338.563599,58.895599 1352.585449,58.895599 
	C1352.585449,55.032528 1352.585449,51.674088 1352.585449,48.330135 
	C1333.273926,48.330135 1314.378784,48.330135 1295.000000,48.330135 
	C1295.000000,51.944798 1295.000000,55.236050 1295.000000,59.519646 
M523.769287,59.007534 
	C537.365051,59.007534 550.960876,59.007534 564.717407,59.007534 
	C564.717407,55.065014 564.717407,51.819389 564.717407,48.392128 
	C545.408752,48.392128 526.384766,48.392128 507.010529,48.392128 
	C507.010529,65.085091 506.844330,81.390549 507.092957,97.689682 
	C507.191376,104.143188 512.199646,110.248329 518.493164,110.881119 
	C527.868652,111.823776 537.351440,111.757713 546.791992,111.972939 
	C553.055359,112.115723 559.324585,112.000000 565.705566,112.000000 
	C565.705566,108.040962 565.705566,104.794876 565.705566,101.245346 
	C563.814331,101.155159 562.187988,101.013756 560.561340,101.010445 
	C550.399658,100.989769 540.237915,101.028061 530.076416,100.988914 
	C524.346313,100.966843 521.671326,98.759216 521.091187,93.268463 
	C520.783936,90.360680 521.036804,87.393715 521.036804,83.794319 
	C534.691284,83.794319 547.730347,83.794319 560.615295,83.794319 
	C560.615295,80.191902 560.615295,77.278130 560.615295,73.871819 
	C547.324524,73.871819 534.409241,73.871819 521.006409,73.871819 
	C521.006409,69.583557 520.880737,65.783806 521.090088,62.002602 
	C521.146240,60.988468 522.223511,60.030857 523.769287,59.007534 
M1651.000000,192.480469 
	C1651.000000,196.226868 1651.000000,199.973267 1651.000000,203.639465 
	C1656.094971,203.639465 1660.338989,203.639465 1665.082764,203.639465 
	C1665.082764,197.373932 1665.082764,191.453659 1665.082764,185.000000 
	C1671.692871,185.000000 1677.838867,185.373917 1683.916748,184.891266 
	C1689.609253,184.439255 1695.288940,183.337662 1700.867188,182.056396 
	C1708.272339,180.355591 1715.302856,172.090500 1715.300171,161.894699 
	C1715.297852,153.202332 1709.169312,144.504700 1700.812866,142.480255 
	C1695.885620,141.286530 1690.802856,140.263046 1685.761108,140.109451 
	C1674.342529,139.761612 1662.906128,140.000000 1651.000000,140.000000 
	C1651.000000,157.598450 1651.000000,174.542984 1651.000000,192.480469 
M614.246826,141.432861 
	C603.553894,162.092361 592.860962,182.751862 582.027527,203.682922 
	C596.483826,204.817581 596.483826,204.817581 602.633301,192.773499 
	C602.708923,192.625336 602.816589,192.488251 602.865112,192.332230 
	C603.658691,189.779129 605.228760,188.796829 608.033569,188.928207 
	C613.346802,189.177063 618.758118,189.580109 623.988281,188.876312 
	C634.113464,187.513809 641.307312,190.081818 644.067322,200.843781 
	C644.385071,202.082794 646.100708,203.760147 647.268738,203.844604 
	C652.005554,204.187119 656.781677,203.986893 662.634338,203.986893 
	C659.441528,198.079056 656.641785,193.094925 654.023682,188.017120 
	C646.367004,173.166855 638.810303,158.264984 631.122253,143.431137 
	C630.442078,142.118759 629.115601,140.222488 628.044373,140.192307 
	C623.638000,140.068207 619.214172,140.560501 614.246826,141.432861 
M809.512268,64.060593 
	C801.436890,79.857681 793.361511,95.654762 785.418457,111.193047 
	C796.315552,114.514473 802.976990,110.904823 805.766663,100.531639 
	C805.809082,100.373894 805.865234,100.167702 805.984070,100.090485 
	C807.586792,99.049438 809.152649,97.246948 810.822632,97.141739 
	C816.629822,96.775894 822.578552,97.697403 828.281311,96.844597 
	C838.033386,95.386246 844.043762,98.713303 846.739624,108.250671 
	C847.131775,109.638321 848.542969,111.693207 849.607239,111.772743 
	C854.611328,112.146706 859.658875,111.939156 865.581055,111.939156 
	C862.896118,107.024315 860.685730,103.179436 858.662170,99.238586 
	C850.644470,83.623344 842.753784,67.942154 834.611389,52.392582 
	C833.667175,50.589554 831.608093,48.470135 829.791931,48.173096 
	C819.591125,46.504772 818.403748,47.311256 813.772339,56.514652 
	C812.583252,58.877502 811.261047,61.173363 809.512268,64.060593 
M996.885559,191.312500 
	C994.740173,195.228027 992.594788,199.143555 990.239624,203.441956 
	C1004.389954,205.191574 1004.389526,205.191345 1010.047913,193.871597 
	C1010.270386,193.426636 1010.593323,193.017120 1010.737732,192.550354 
	C1011.591431,189.789413 1013.313782,188.794250 1016.288879,188.927063 
	C1021.940613,189.179352 1027.712524,189.677460 1033.252441,188.852020 
	C1043.259155,187.361053 1049.693237,190.478775 1052.365234,200.586166 
	C1052.711792,201.897400 1054.391602,203.704193 1055.571533,203.797806 
	C1060.247803,204.168884 1064.970703,203.950317 1069.755127,203.950317 
	C1069.625732,202.816208 1069.677612,202.453049 1069.540649,202.187088 
	C1059.237183,182.174530 1048.954346,162.151123 1038.507202,142.213715 
	C1037.928467,141.109238 1036.046631,140.327682 1034.654053,140.093765 
	C1032.548096,139.740005 1030.307983,140.233704 1028.180542,139.949356 
	C1024.270386,139.426651 1022.344055,141.071945 1020.605286,144.592468 
	C1012.980225,160.031067 1004.986572,175.287628 996.885559,191.312500 
M889.998169,178.059998 
	C888.320496,187.876282 882.092102,193.629059 872.742310,193.998215 
	C861.697449,194.434311 855.327209,189.874298 852.835083,179.522339 
	C852.263855,177.149582 852.055420,174.641083 852.036011,172.192230 
	C851.951965,161.598190 852.000000,151.003098 852.000000,140.300812 
	C847.028992,140.300812 842.784363,140.300812 838.000000,140.300812 
	C838.000000,152.709839 837.914001,164.826675 838.025574,176.941696 
	C838.126221,187.870010 843.434692,195.958176 852.900391,200.851456 
	C862.671936,205.902817 873.129089,206.115555 883.702454,203.178680 
	C896.182861,199.712143 904.722839,187.808762 904.101868,175.479279 
	C903.599670,165.508865 904.011841,155.492981 903.988892,145.497345 
	C903.984863,143.745117 903.834961,141.993225 903.758728,140.374237 
	C898.880737,140.374237 894.635071,140.374237 890.516418,140.374237 
	C890.289368,141.053436 890.097717,141.360596 890.096619,141.668457 
	C890.053528,153.495102 890.027893,165.321823 889.998169,178.059998 
M1581.614746,204.039032 
	C1593.986084,203.588654 1607.161865,194.649872 1608.102051,182.259583 
	C1609.151245,168.434357 1608.342773,154.468155 1608.342773,140.410400 
	C1604.046875,140.410400 1599.683472,140.410400 1595.000000,140.410400 
	C1595.000000,152.799850 1595.039673,164.782013 1594.987061,176.763779 
	C1594.947754,185.722717 1588.945312,192.147354 1578.853638,193.873489 
	C1572.628540,194.938232 1560.988403,191.517487 1558.899414,182.801270 
	C1558.062134,179.307983 1557.165649,175.729980 1557.085571,172.172745 
	C1556.847046,161.582245 1557.000000,150.982941 1557.000000,140.312927 
	C1552.005981,140.312927 1547.760010,140.312927 1543.000000,140.312927 
	C1543.000000,152.408783 1543.260864,164.206207 1542.942627,175.987976 
	C1542.381226,196.770447 1564.029907,207.229340 1581.614746,204.039032 
M1118.574951,61.855690 
	C1126.367676,65.720810 1127.163818,65.397537 1131.723022,56.516685 
	C1116.676758,44.700726 1091.800781,44.580334 1076.130737,55.325508 
	C1059.498413,66.730553 1060.266235,94.753281 1075.817993,104.704765 
	C1091.861084,114.970665 1107.926270,115.243332 1124.850952,108.519890 
	C1127.803101,107.347153 1130.451172,105.409325 1133.631592,103.602226 
	C1130.881714,100.206268 1128.737061,97.557816 1126.532104,94.834869 
	C1124.669312,95.713684 1123.072388,96.241684 1121.722046,97.140335 
	C1112.234741,103.454514 1101.953247,103.905380 1091.892334,99.735825 
	C1077.854370,93.917969 1073.935181,77.318718 1084.495239,66.008400 
	C1091.185425,58.842915 1105.258911,54.825005 1118.574951,61.855690 
M414.526978,99.026886 
	C412.447144,97.102898 411.219818,93.220634 407.142242,96.410690 
	C400.488220,101.616394 392.657532,102.560249 384.541168,101.977760 
	C373.333313,101.173401 363.334808,91.656906 363.001068,81.586021 
	C362.521271,67.106949 377.987305,54.777454 392.565521,58.424866 
	C398.533386,59.918011 404.216766,62.548382 410.378937,64.788414 
	C412.194244,62.293106 414.177399,59.567062 416.227509,56.748959 
	C414.990692,55.640167 414.250000,54.645676 413.259735,54.137516 
	C395.734741,45.144184 378.406921,44.299469 361.221313,55.207539 
	C344.086853,66.083130 342.258575,92.895897 361.778687,105.820457 
	C377.941467,116.522102 400.639862,115.460243 417.248962,103.742722 
	C416.534882,102.393715 415.787323,100.981430 414.526978,99.026886 
M289.407104,41.912857 
	C285.231689,44.155613 281.109222,46.504391 276.871002,48.621387 
	C263.842438,55.129131 252.149231,64.567497 236.531784,66.776863 
	C238.560699,71.977135 240.255341,77.076202 242.512787,81.912567 
	C245.246674,87.769714 249.428467,89.731201 255.442108,87.007622 
	C266.298096,82.090958 276.834961,76.461929 287.445221,71.013809 
	C292.388153,68.475731 297.300232,65.838661 301.998993,62.884876 
	C303.677032,61.830017 305.736755,59.761028 305.820099,58.066299 
	C306.218781,49.959709 305.998932,41.822704 305.998932,33.058262 
	C300.370728,36.024849 295.195679,38.752575 289.407104,41.912857 
M708.367859,59.000008 
	C710.123413,59.000008 711.878906,59.000008 714.131042,59.000008 
	C714.131042,77.011826 714.131042,94.256187 714.131042,111.565880 
	C719.340576,111.565880 724.038269,111.565880 729.225220,111.565880 
	C729.225220,93.892326 729.225220,76.647903 729.225220,58.907249 
	C737.306519,58.907249 744.890442,58.907249 752.581787,58.907249 
	C752.581787,55.028431 752.581787,51.668297 752.581787,48.344383 
	C731.589844,48.344383 711.018005,48.344383 690.283325,48.344383 
	C690.283325,51.937588 690.283325,55.182671 690.283325,59.000000 
	C696.168701,59.000000 701.784790,59.000000 708.367859,59.000008 
M716.303467,203.998825 
	C719.636841,204.500015 720.077026,202.634186 720.043518,199.864853 
	C719.928772,190.370651 719.999878,180.874207 720.000000,171.378616 
	C720.000061,164.766907 720.000000,158.155212 720.000000,150.867889 
	C728.285889,150.867889 736.000671,150.867889 743.650024,150.867889 
	C743.650024,146.929855 743.650024,143.681564 743.650024,140.280197 
	C722.726807,140.280197 702.154419,140.280197 681.396851,140.280197 
	C681.396851,143.928253 681.396851,147.288467 681.396851,151.155594 
	C689.368652,151.155594 696.955627,151.155594 705.203125,151.155594 
	C705.203125,169.025589 705.203125,186.391861 705.203125,203.999847 
	C709.041992,203.999847 712.199280,203.999847 716.303467,203.998825 
M1391.748413,203.992081 
	C1395.354492,203.992081 1398.960449,203.992081 1403.227783,203.992081 
	C1403.227783,185.956940 1403.227783,168.593185 1403.227783,150.732910 
	C1411.497070,150.732910 1419.083252,150.732910 1426.668457,150.732910 
	C1426.668457,146.966660 1426.668457,143.718689 1426.668457,140.438263 
	C1405.973389,140.438263 1385.613159,140.438263 1365.380615,140.438263 
	C1365.380615,144.135483 1365.380615,147.381683 1365.380615,151.124359 
	C1373.336914,151.124359 1380.920288,151.124359 1389.000000,151.124359 
	C1389.000000,153.413956 1389.000000,155.209335 1389.000000,157.004715 
	C1389.000000,170.999268 1388.939575,184.994446 1389.080078,198.987564 
	C1389.096680,200.647766 1390.211914,202.296921 1391.748413,203.992081 
M1848.535889,151.000000 
	C1853.284546,151.000000 1858.033325,151.000000 1863.243774,151.000000 
	C1863.243774,169.127808 1863.243774,186.371948 1863.243774,203.687408 
	C1867.995361,203.687408 1872.240479,203.687408 1877.158936,203.687408 
	C1877.158936,186.020584 1877.158936,168.656586 1877.158936,150.800247 
	C1885.418579,150.800247 1893.002930,150.800247 1900.707153,150.800247 
	C1900.707153,147.042343 1900.707153,143.797333 1900.707153,140.395416 
	C1880.055298,140.395416 1859.698486,140.395416 1839.335938,140.395416 
	C1839.335938,144.042847 1839.335938,147.290390 1839.335938,151.000000 
	C1842.287720,151.000000 1844.919556,151.000000 1848.535889,151.000000 
M1232.000000,56.554626 
	C1232.000000,74.933151 1232.000000,93.311684 1232.000000,111.686394 
	C1250.381470,111.686394 1267.935547,111.686394 1285.574707,111.686394 
	C1285.574707,108.006706 1285.574707,104.650215 1285.574707,100.814072 
	C1272.256958,100.814072 1259.357544,100.814072 1245.821289,100.814072 
	C1245.821289,82.989029 1245.821289,65.643272 1245.821289,48.348354 
	C1240.920532,48.348354 1236.680664,48.348354 1232.000000,48.348354 
	C1232.000000,50.986809 1232.000000,53.281662 1232.000000,56.554626 
M536.000000,185.499420 
	C536.000000,191.584686 536.000000,197.669952 536.000000,203.627167 
	C550.786255,203.627167 564.700684,203.627167 578.685364,203.627167 
	C578.685364,200.002487 578.685364,196.754700 578.685364,192.824371 
	C569.010010,192.824371 559.627319,192.824371 549.766357,192.824371 
	C549.766357,174.886276 549.766357,157.634521 549.766357,140.316010 
	C545.001587,140.316010 540.754639,140.316010 536.000000,140.316010 
	C536.000000,155.250610 536.000000,169.875076 536.000000,185.499420 
M1829.000000,190.491241 
	C1829.000000,173.773026 1829.000000,157.054794 1829.000000,140.329453 
	C1823.624634,140.329453 1819.052246,140.329453 1814.398682,140.329453 
	C1814.398682,161.659317 1814.398682,182.657349 1814.398682,203.670273 
	C1819.374268,203.670273 1823.946655,203.670273 1829.000000,203.670273 
	C1829.000000,199.397430 1829.000000,195.442474 1829.000000,190.491241 
M675.247620,111.991592 
	C678.474365,112.202095 677.990479,109.865608 677.991760,107.929543 
	C678.003845,89.130966 678.006958,70.332367 677.975403,51.533840 
	C677.973572,50.440781 677.649902,49.348263 677.491516,48.352730 
	C672.579346,48.352730 668.006592,48.352730 663.429016,48.352730 
	C663.429016,69.711861 663.429016,90.715347 663.429016,111.998856 
	C667.336670,111.998856 670.819214,111.998856 675.247620,111.991592 
M1090.302246,140.001099 
	C1086.696289,140.001099 1083.090332,140.001099 1079.398071,140.001099 
	C1079.398071,161.646805 1079.398071,182.650421 1079.398071,203.668945 
	C1084.376221,203.668945 1088.949585,203.668945 1093.983398,203.668945 
	C1093.983398,183.083633 1094.017090,162.825409 1093.867554,142.568512 
	C1093.861206,141.708603 1092.156616,140.861237 1090.302246,140.001099 
M1039.625732,48.000137 
	C1038.196045,48.113739 1036.766357,48.227345 1035.285400,48.345013 
	C1035.285400,69.658768 1035.285400,90.542465 1035.285400,111.605042 
	C1039.932861,111.605042 1044.289795,111.605042 1048.706665,111.605042 
	C1048.706665,90.325012 1048.706665,69.441231 1048.706665,48.000008 
	C1045.807861,48.000008 1043.193604,48.000008 1039.625732,48.000137 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M242.016052,105.970749 
	C263.153290,94.524002 284.290497,83.077248 305.977905,71.332542 
	C305.977905,81.540070 306.080719,91.412811 305.849548,101.277725 
	C305.820190,102.530052 304.288544,104.140327 303.037750,104.911209 
	C287.313049,114.602600 271.511902,124.169968 255.038239,133.905975 
	C253.853729,134.080460 253.280838,133.966019 252.880554,134.177795 
	C242.851074,139.483978 232.832916,144.811829 222.846985,150.199280 
	C222.383850,150.449127 222.237595,151.286316 221.943756,151.849976 
	C212.311127,156.388947 202.704987,160.985245 193.040466,165.455276 
	C171.210312,175.552170 149.023453,184.945557 125.145378,188.720566 
	C103.036148,192.215927 81.032387,191.774612 60.492535,180.820984 
	C44.555679,172.322098 35.163578,159.090759 31.469837,141.240967 
	C31.112789,134.651733 31.067492,128.277084 31.090466,121.439056 
	C39.016514,95.400688 56.463665,77.790237 78.581932,64.152145 
	C92.799988,55.385311 108.322014,49.845020 124.576385,46.225288 
	C138.097534,43.214226 145.760345,47.500607 150.002625,60.588863 
	C151.456070,65.072983 152.813553,69.588219 154.102783,73.729172 
	C144.947952,75.532379 136.080704,76.824577 127.461342,79.068741 
	C107.908005,84.159714 90.639534,93.556541 75.896706,107.542961 
	C66.699860,116.267944 58.377323,125.592270 54.654526,137.985062 
	C53.581627,141.556625 53.325432,145.724243 53.989147,149.383835 
	C54.962238,154.749283 60.082073,156.343323 64.509781,157.946045 
	C77.146912,162.520355 90.238953,162.063034 103.125099,159.875336 
	C118.566650,157.253784 134.440201,155.281570 149.031952,150.002365 
	C169.925980,142.443024 189.828476,132.143051 210.856232,122.871330 
	C221.710785,117.143150 231.863419,111.556953 242.016052,105.970749 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M2063.912354,176.578888 
	C2063.369873,179.408127 2063.067871,181.821381 2062.394775,184.126160 
	C2059.181152,195.130951 2048.744873,202.752075 2037.283936,204.160812 
	C2027.202026,205.400055 2017.996460,204.072281 2009.354736,198.854980 
	C2001.265015,193.970901 1997.723877,186.039444 1997.190552,177.285172 
	C1996.453735,165.191254 1997.005737,153.018829 1997.005737,140.440933 
	C2001.943359,140.440933 2006.638306,140.440933 2012.000000,140.440933 
	C2012.000000,152.063721 2011.972534,163.652847 2012.026367,175.241623 
	C2012.035400,177.202301 2012.223267,179.196716 2012.620850,181.114914 
	C2014.240967,188.928726 2022.348267,193.818420 2030.571167,193.958725 
	C2038.917480,194.101151 2047.107544,188.200424 2048.859375,180.628067 
	C2049.705078,176.973190 2049.893799,173.113358 2049.951904,169.339737 
	C2050.097656,159.863297 2050.000000,150.383148 2050.000000,139.988602 
	C2054.449951,139.988602 2058.689941,139.988602 2063.385254,139.976624 
	C2063.887939,152.014053 2063.934814,164.063477 2063.912354,176.578888 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563293,31.089964 
	C548.573059,34.048737 546.026184,36.863037 543.686279,39.839977 
	C539.397400,45.296379 533.663574,42.131325 527.249329,43.313896 
	C530.307312,38.752754 532.621399,35.301231 534.950012,31.504509 
	C540.009705,31.112480 545.054871,31.065655 550.563293,31.089964 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.985916,105.602196 
	C231.863419,111.556953 221.710785,117.143150 211.214661,122.820938 
	C206.245163,110.931831 201.624435,98.949081 196.986450,86.973030 
	C196.405746,85.473572 195.733902,84.009399 194.744019,81.681763 
	C191.305756,88.870255 188.186081,95.316910 185.132401,101.794678 
	C180.407639,111.817322 175.846741,121.919182 170.987793,131.875916 
	C167.698105,138.616974 160.677963,140.706573 154.507065,142.917603 
	C146.524750,145.777634 137.985733,147.108109 129.652878,148.945084 
	C127.983742,149.313034 126.164070,148.998199 123.616600,148.998199 
	C134.574707,128.700836 145.111069,109.268486 155.564392,89.791588 
	C162.060623,77.687630 168.594742,65.598244 174.751221,53.321468 
	C176.441223,49.951389 178.354187,48.837692 181.989441,48.920982 
	C191.983810,49.149979 201.992905,49.198650 211.983292,48.895000 
	C215.600922,48.785046 217.317307,50.145775 218.674835,53.210503 
	C226.368973,70.580856 234.182739,87.898232 241.985916,105.602196 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1164.000000,154.006958 
	C1164.000000,149.216171 1164.000000,144.923584 1164.000000,140.321335 
	C1168.741943,140.321335 1172.987671,140.321335 1177.618408,140.321335 
	C1177.618408,161.280243 1177.618408,182.185745 1177.618408,203.136581 
	C1169.374878,205.767776 1164.788086,204.285034 1158.971436,197.537445 
	C1149.579102,186.641861 1140.030640,175.880997 1130.564453,165.048782 
	C1129.610962,163.957642 1128.771606,162.766739 1127.206909,160.759781 
	C1127.206909,175.534576 1127.206909,189.367615 1127.206909,203.596008 
	C1121.972168,203.596008 1117.278931,203.596008 1112.288086,203.596008 
	C1112.288086,182.525772 1112.288086,161.621292 1112.288086,140.030029 
	C1116.680542,140.030029 1120.970703,139.762375 1125.195190,140.172150 
	C1126.717285,140.319809 1128.348389,141.863983 1129.475952,143.164398 
	C1139.214233,154.396042 1148.850098,165.716522 1158.513306,177.013321 
	C1160.069336,178.832336 1161.609619,180.664734 1164.000000,183.485413 
	C1164.000000,172.982330 1164.000000,163.743759 1164.000000,154.006958 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M967.969971,202.461807 
	C962.671021,196.466095 957.373474,190.961655 952.753296,184.937607 
	C950.928772,182.558655 949.231567,181.887543 946.589661,181.971298 
	C942.960999,182.086319 939.325867,182.000076 935.187073,182.000076 
	C935.187073,189.346115 935.187073,196.264221 935.187073,203.588226 
	C929.988953,203.588226 925.295288,203.588226 920.295471,203.588226 
	C920.295471,182.538574 920.295471,161.633240 920.295471,140.000000 
	C925.996033,140.000000 931.595825,139.911957 937.191895,140.018951 
	C947.758240,140.220963 958.478516,138.798676 968.748169,142.439011 
	C971.657715,143.470352 974.530762,144.893173 977.061951,146.652451 
	C986.673645,153.332947 985.908203,174.518188 970.429871,178.314285 
	C969.045837,178.653717 967.761353,179.398819 965.869629,180.190170 
	C969.447144,184.191895 972.799316,187.784866 975.969421,191.531906 
	C979.108215,195.241943 982.063354,199.107361 985.098999,202.904633 
	C984.899536,203.426559 984.700073,203.948486 984.500610,204.470413 
	C979.081299,203.895279 973.661987,203.320129 967.969971,202.461807 
M962.263367,152.963104 
	C953.369141,152.045654 944.474976,151.128220 935.430542,150.195282 
	C935.430542,158.040909 935.430542,164.384598 935.430542,170.990479 
	C943.145020,170.990479 950.474243,171.463287 957.702026,170.811371 
	C961.969666,170.426437 966.512451,168.846176 967.960266,163.682938 
	C969.337463,158.771179 967.050171,155.521790 962.263367,152.963104 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M420.292358,188.159790 
	C414.095337,197.664581 404.820984,201.936722 394.643494,202.743271 
	C381.804413,203.760742 368.823517,202.988770 355.448212,202.988770 
	C355.448212,182.743469 355.448212,161.731781 355.448212,140.533310 
	C366.980255,140.533310 378.543274,140.240906 390.083313,140.630035 
	C398.616638,140.917786 406.439972,143.738037 413.494720,148.884262 
	C424.384216,156.827805 427.942017,172.103043 422.281097,184.313065 
	C421.724243,185.514175 421.119019,186.692902 420.292358,188.159790 
M402.975037,186.528107 
	C416.483734,174.885895 408.607147,155.824234 394.054932,152.719971 
	C386.443909,151.096390 378.477264,151.139984 370.352631,150.401657 
	C370.352631,165.230820 370.352631,178.895111 370.352631,192.978882 
	C376.147827,192.978882 381.743500,193.751450 387.008392,192.762695 
	C392.327820,191.763702 397.319427,189.019211 402.975037,186.528107 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M454.980774,48.000000 
	C464.411987,47.995842 473.341736,48.017838 481.701874,51.894943 
	C489.807068,55.653843 493.718811,61.922165 492.987762,70.898842 
	C492.312958,79.184845 487.064575,83.816200 479.970245,86.913239 
	C478.679932,87.476547 477.376312,88.009392 475.664246,88.731041 
	C479.293549,92.874176 482.555267,96.571472 485.787109,100.294678 
	C488.872040,103.848663 491.925903,107.429626 495.830170,111.972351 
	C489.669128,111.972351 484.732056,112.209084 479.842407,111.831406 
	C478.330078,111.714600 476.673767,110.161652 475.552094,108.861771 
	C470.808014,103.364052 466.319397,97.645073 461.542633,92.177086 
	C460.578522,91.073448 458.801880,90.199303 457.340790,90.101463 
	C453.201996,89.824310 449.032623,90.004395 444.186005,90.004395 
	C444.186005,97.279808 444.186005,104.329247 444.186005,111.679016 
	C439.255768,111.679016 435.008820,111.679016 430.379211,111.679016 
	C430.379211,90.707047 430.379211,69.794182 430.379211,48.000000 
	C438.319733,48.000000 446.402161,48.000000 454.980774,48.000000 
M444.241272,78.086472 
	C452.627319,78.313797 461.050598,79.107071 469.385590,78.558517 
	C474.474182,78.223618 477.990326,73.234734 477.996735,69.010178 
	C478.004944,63.596779 474.384705,61.135139 469.887756,60.567043 
	C461.458038,59.502121 452.939819,59.137753 444.048462,58.450314 
	C444.048462,65.559891 444.048462,71.364456 444.241272,78.086472 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1012.161865,83.285141 
	C1008.523804,85.222343 1005.158569,86.912376 1001.609497,88.694733 
	C1007.910095,96.014030 1014.381470,103.531761 1021.665283,111.993256 
	C1015.585449,111.993256 1010.956604,112.259552 1006.387146,111.855316 
	C1004.684448,111.704689 1002.762268,110.301369 1001.556396,108.928131 
	C996.771912,103.479713 992.285156,97.770821 987.551880,92.275772 
	C986.664795,91.245827 985.186218,90.190559 983.923279,90.115860 
	C979.488770,89.853539 975.029175,90.014030 970.088013,90.014030 
	C970.088013,97.450554 970.088013,104.365829 970.088013,111.635040 
	C965.348816,111.635040 961.105713,111.635040 956.428345,111.635040 
	C956.428345,90.705917 956.428345,69.693565 956.428345,48.180218 
	C968.044922,48.180218 979.635376,47.937237 991.206726,48.301918 
	C995.723511,48.444267 1000.340393,49.512798 1004.664856,50.918812 
	C1020.379211,56.027946 1024.044556,71.552574 1012.161865,83.285141 
M994.755432,78.935371 
	C995.667480,78.550331 996.600708,78.208542 997.487854,77.772758 
	C1001.266235,75.916756 1004.444824,73.342896 1003.883179,68.721153 
	C1003.335144,64.212013 1000.299194,61.062096 995.849731,60.530663 
	C987.426636,59.524628 978.929810,59.135586 970.299194,58.477661 
	C970.299194,65.989899 970.299194,72.222008 970.299194,78.989311 
	C978.352417,78.989311 986.118530,78.989311 994.755432,78.935371 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1139.000000,81.871696 
	C1136.513794,62.871830 1155.218140,49.601589 1171.637695,47.853455 
	C1183.227417,46.619526 1193.952148,47.934662 1203.786743,54.289726 
	C1213.843994,60.788754 1219.641357,70.137177 1218.802124,82.127670 
	C1217.830078,96.019432 1209.550903,105.349228 1196.676392,109.850876 
	C1179.611450,115.817787 1163.012329,114.788338 1148.659546,102.693779 
	C1142.645752,97.626122 1138.744263,90.770851 1139.000000,81.871696 
M1187.223633,101.047928 
	C1189.474854,100.032974 1191.913086,99.300293 1193.944946,97.954254 
	C1202.823364,92.072800 1207.806030,78.654709 1199.979492,68.586563 
	C1191.318604,57.444908 1176.870483,54.869423 1164.413696,61.861435 
	C1156.132080,66.509926 1151.969238,77.006355 1154.585693,86.158348 
	C1158.160889,98.664185 1171.355469,104.477806 1187.223633,101.047928 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M749.647217,157.764374 
	C756.420715,146.265823 766.718506,141.544586 779.129211,139.761383 
	C793.396545,137.711395 806.221863,140.748871 816.743958,150.433060 
	C830.899902,163.461792 828.455872,185.651779 812.833374,196.843964 
	C795.090210,209.555344 767.579834,206.091827 755.403748,193.980270 
	C746.136475,184.762161 742.653992,170.243378 749.647217,157.764374 
M770.772034,189.918762 
	C778.669006,194.237976 786.753479,195.513245 795.484436,192.298721 
	C805.391296,188.651230 811.350098,180.863586 810.997986,171.202667 
	C810.657776,161.866486 803.700256,153.648544 794.018372,151.098419 
	C784.840332,148.681015 776.323730,150.100723 768.757019,155.839203 
	C759.489441,162.867722 756.504517,178.929367 770.772034,189.918762 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M581.000000,80.000000 
	C581.000000,69.208679 581.000000,58.917351 581.000000,48.795471 
	C591.908630,48.795471 602.300354,48.846546 612.691345,48.780628 
	C622.431458,48.718838 631.224731,51.538921 639.207336,57.066811 
	C652.036255,65.950638 653.893433,88.091385 643.169739,99.464874 
	C635.494202,107.605537 625.849792,111.106651 615.317932,111.823624 
	C604.214600,112.579514 593.019958,111.993050 581.000000,111.993050 
	C581.000000,101.328712 581.000000,90.914360 581.000000,80.000000 
M632.165833,90.507072 
	C637.845337,82.489754 635.074951,70.188240 627.475769,64.890663 
	C617.478333,57.921280 606.372070,59.078632 595.000000,59.103378 
	C595.000000,66.725098 595.000000,73.548714 595.000000,80.372330 
	C595.000000,87.132027 595.000000,93.891731 595.000000,100.999969 
	C600.908386,100.999969 606.058167,101.178314 611.190979,100.959213 
	C619.409302,100.608414 626.852051,98.374687 632.165833,90.507072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1501.816772,141.010132 
	C1512.033081,143.188690 1520.701782,147.084320 1526.499512,155.861664 
	C1535.573486,169.598907 1530.621948,189.552902 1516.138916,197.436279 
	C1509.014282,201.314407 1501.480225,203.216476 1493.353149,203.114548 
	C1483.124634,202.986282 1472.893433,203.083649 1462.327759,203.083649 
	C1462.327759,182.611404 1462.327759,161.711853 1462.327759,140.995285 
	C1475.355469,140.995285 1488.370239,140.995285 1501.816772,141.010132 
M1476.000000,160.540375 
	C1476.000000,171.266724 1476.000000,181.993057 1476.000000,194.233047 
	C1485.765259,192.733002 1494.589233,191.851074 1503.172729,189.907440 
	C1509.896118,188.385040 1515.825195,180.158096 1515.930908,173.227066 
	C1516.051147,165.339050 1513.319824,159.038742 1505.880981,154.832779 
	C1496.373047,149.456924 1486.409058,151.330826 1476.000000,151.151794 
	C1476.000000,154.303818 1476.000000,156.929199 1476.000000,160.540375 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1953.752686,203.985504 
	C1942.771240,205.698074 1932.548950,204.618698 1923.116211,199.497787 
	C1912.415283,193.688477 1905.613892,184.868866 1905.061157,172.332123 
	C1904.482910,159.215118 1913.826294,146.733826 1927.328735,142.043625 
	C1942.166382,136.889618 1956.709351,137.472778 1970.248535,145.749207 
	C1990.063721,157.862305 1989.966797,184.262756 1972.541504,195.962555 
	C1967.089111,199.623413 1960.343506,201.358475 1953.752686,203.985504 
M1924.082153,159.758347 
	C1918.449951,168.537659 1918.676514,176.876465 1925.211548,184.756714 
	C1932.974487,194.117508 1947.986450,196.777527 1958.487549,190.784271 
	C1973.089478,182.450455 1973.375488,164.726425 1963.247314,156.156906 
	C1951.493774,146.212234 1932.480957,148.401825 1924.082153,159.758347 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1773.980591,203.123657 
	C1758.190796,206.201950 1743.914673,204.719254 1731.411011,193.996536 
	C1717.081299,181.707901 1718.854248,157.147354 1735.196411,146.421799 
	C1750.955322,136.078979 1777.461182,134.777328 1792.950317,150.747009 
	C1808.490967,166.769852 1803.029175,190.812302 1781.402832,200.741379 
	C1779.182007,201.760956 1776.736694,202.291550 1773.980591,203.123657 
M1773.470337,191.180450 
	C1785.537842,185.636261 1791.106201,171.024979 1782.299316,159.501602 
	C1776.322876,151.681717 1768.342529,149.720810 1759.148682,149.963303 
	C1747.423828,150.272537 1737.359497,158.664520 1736.405640,170.598648 
	C1735.730469,179.046066 1741.140991,188.779327 1752.988403,192.580612 
	C1759.853149,194.783249 1766.310303,193.987762 1773.470337,191.180450 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M919.784302,111.991966 
	C907.509338,114.165344 895.973328,113.559288 885.040222,108.006409 
	C872.136963,101.452858 866.888916,90.566566 868.071045,76.551537 
	C869.087219,64.504211 876.247498,56.375080 886.865540,51.739620 
	C901.880798,45.184490 917.002441,45.796658 931.672791,53.347511 
	C933.234375,54.151268 934.588074,55.358978 935.892761,56.276939 
	C935.733154,57.055668 935.758362,57.433121 935.590576,57.678692 
	C930.546692,65.060524 930.554932,65.034348 922.289124,61.190044 
	C911.684509,56.258030 901.221436,57.053627 891.627930,63.333557 
	C884.929749,67.718224 881.953430,74.719971 883.095947,82.767960 
	C884.176880,90.383057 888.201721,96.487289 895.382996,99.583397 
	C903.929077,103.267891 912.838806,103.226364 921.427734,99.588806 
	C923.041260,98.905449 924.336975,96.414558 924.864136,94.505096 
	C925.453064,92.371628 924.999146,89.950302 924.999146,87.149010 
	C918.621704,87.149010 912.701172,87.149010 906.394287,87.149010 
	C906.394287,83.282982 906.394287,79.922279 906.394287,76.277092 
	C917.498474,76.277092 928.408875,76.277092 939.965332,76.277092 
	C939.965332,84.721123 940.089600,92.994637 939.834229,101.256432 
	C939.794983,102.525688 938.263794,104.291672 936.984924,104.885803 
	C931.483643,107.441589 925.818970,109.645775 919.784302,111.991966 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M457.117401,151.000000 
	C454.998474,151.000000 453.362701,151.000000 451.364868,151.000000 
	C451.364868,155.654922 451.364868,159.900009 451.364868,164.834457 
	C463.977631,164.834457 476.682373,164.834457 489.686523,164.834457 
	C489.686523,168.755814 489.686523,172.002487 489.686523,175.759888 
	C477.044617,175.759888 464.461761,175.759888 451.116394,175.759888 
	C451.116394,180.153351 450.653503,184.129593 451.235596,187.946457 
	C451.935516,192.535858 455.981720,192.938629 459.710449,192.971207 
	C469.371460,193.055664 479.033691,192.999832 488.695435,192.999985 
	C490.513245,193.000015 492.331055,193.000000 494.573914,193.000000 
	C494.573914,196.651398 494.573914,200.010178 494.573914,204.000000 
	C486.380676,204.000000 478.270996,204.000000 470.161285,204.000000 
	C465.330414,204.000000 460.499542,204.003799 455.668671,203.999237 
	C443.915161,203.988159 436.936310,198.938263 436.282837,187.508316 
	C435.392731,171.939270 436.060883,156.281128 436.060883,140.332703 
	C455.383759,140.332703 474.288757,140.332703 493.597839,140.332703 
	C493.597839,143.700089 493.597839,147.059525 493.597839,151.000000 
	C481.491272,151.000000 469.545929,151.000000 457.117401,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1337.998535,204.000000 
	C1329.051514,203.718262 1320.506592,204.138779 1312.180786,202.982666 
	C1300.969604,201.425934 1297.101685,198.062469 1296.357666,186.906052 
	C1295.332153,171.527313 1296.100708,156.028976 1296.100708,140.293579 
	C1315.468262,140.293579 1334.377563,140.293579 1353.644043,140.293579 
	C1353.644043,143.667465 1353.644043,146.914658 1353.644043,150.858521 
	C1339.673218,150.858521 1325.630127,150.858521 1311.301270,150.858521 
	C1311.301270,155.784393 1311.301270,160.030472 1311.301270,164.792313 
	C1323.990967,164.792313 1336.575439,164.792313 1349.576538,164.792313 
	C1349.576538,168.657639 1349.576538,172.017227 1349.576538,175.901459 
	C1336.897583,175.901459 1324.311401,175.901459 1311.030762,175.901459 
	C1311.030762,179.988205 1310.508789,183.682648 1311.150635,187.162445 
	C1312.168945,192.684128 1317.208008,192.757385 1321.353271,192.900482 
	C1330.507202,193.216492 1339.679565,192.999557 1348.844360,193.000000 
	C1350.651611,193.000076 1352.458984,193.000000 1354.628052,193.000000 
	C1354.628052,196.639252 1354.628052,199.888290 1354.628052,204.000000 
	C1349.336792,204.000000 1343.917480,204.000000 1337.998535,204.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1230.999634,165.000000 
	C1237.454224,165.000000 1243.408813,165.000000 1249.678223,165.000000 
	C1249.678223,168.736298 1249.678223,171.981216 1249.678223,175.737000 
	C1236.736572,175.737000 1223.825562,175.737000 1210.234863,175.737000 
	C1210.234863,179.947174 1209.649780,183.794205 1210.391113,187.365921 
	C1211.214600,191.333359 1214.463257,193.060608 1218.660645,193.029831 
	C1230.474121,192.943146 1242.288452,192.999573 1254.550415,192.999573 
	C1254.550415,196.604416 1254.550415,199.963974 1254.550415,204.000000 
	C1245.608643,204.000000 1236.674072,204.119308 1227.744385,203.959976 
	C1221.303833,203.845062 1214.773315,203.941483 1208.456787,202.894821 
	C1200.154541,201.519119 1196.027222,195.871811 1196.008545,187.491913 
	C1195.977051,173.338516 1195.993286,159.185013 1196.010132,145.031586 
	C1196.011841,143.565842 1196.153809,142.100250 1196.246582,140.328751 
	C1215.052734,140.328751 1233.618896,140.328751 1252.593506,140.328751 
	C1252.593506,143.690033 1252.593506,147.048386 1252.593506,150.917816 
	C1238.541748,150.917816 1224.631104,150.917816 1210.364746,150.917816 
	C1210.364746,155.651001 1210.364746,159.894058 1210.364746,165.000000 
	C1217.007202,165.000000 1223.753540,165.000000 1230.999634,165.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1295.000000,59.023472 
	C1295.000000,55.236050 1295.000000,51.944798 1295.000000,48.330135 
	C1314.378784,48.330135 1333.273926,48.330135 1352.585449,48.330135 
	C1352.585449,51.674088 1352.585449,55.032528 1352.585449,58.895599 
	C1338.563599,58.895599 1324.655884,58.895599 1310.378418,58.895599 
	C1310.378418,63.956158 1310.378418,68.530899 1310.378418,73.789574 
	C1322.933472,73.789574 1335.631836,73.789574 1348.658936,73.789574 
	C1348.658936,77.364876 1348.658936,80.279121 1348.658936,83.868141 
	C1335.995728,83.868141 1323.292603,83.868141 1310.710327,83.868141 
	C1310.349854,84.957581 1310.087036,85.406662 1310.065308,85.867134 
	C1309.372192,100.532501 1309.822388,101.000000 1324.511475,101.000000 
	C1334.127441,101.000000 1343.743408,101.000000 1353.675537,101.000000 
	C1353.675537,104.731361 1353.675537,107.977150 1353.675537,112.000000 
	C1350.232178,112.000000 1346.808838,112.000000 1343.385620,112.000000 
	C1333.397949,112.000000 1323.410400,112.007523 1313.422729,111.998039 
	C1301.834351,111.987038 1295.057861,105.202415 1295.010010,93.477341 
	C1294.963989,82.158279 1295.000000,70.838890 1295.000000,59.023472 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M523.300659,59.027420 
	C522.223511,60.030857 521.146240,60.988468 521.090088,62.002602 
	C520.880737,65.783806 521.006409,69.583557 521.006409,73.871819 
	C534.409241,73.871819 547.324524,73.871819 560.615295,73.871819 
	C560.615295,77.278130 560.615295,80.191902 560.615295,83.794319 
	C547.730347,83.794319 534.691284,83.794319 521.036804,83.794319 
	C521.036804,87.393715 520.783936,90.360680 521.091187,93.268463 
	C521.671326,98.759216 524.346313,100.966843 530.076416,100.988914 
	C540.237915,101.028061 550.399658,100.989769 560.561340,101.010445 
	C562.187988,101.013756 563.814331,101.155159 565.705566,101.245346 
	C565.705566,104.794876 565.705566,108.040962 565.705566,112.000000 
	C559.324585,112.000000 553.055359,112.115723 546.791992,111.972939 
	C537.351440,111.757713 527.868652,111.823776 518.493164,110.881119 
	C512.199646,110.248329 507.191376,104.143188 507.092957,97.689682 
	C506.844330,81.390549 507.010529,65.085091 507.010529,48.392128 
	C526.384766,48.392128 545.408752,48.392128 564.717407,48.392128 
	C564.717407,51.819389 564.717407,55.065014 564.717407,59.007534 
	C550.960876,59.007534 537.365051,59.007534 523.300659,59.027420 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1651.000000,191.983994 
	C1651.000000,174.542984 1651.000000,157.598450 1651.000000,140.000000 
	C1662.906128,140.000000 1674.342529,139.761612 1685.761108,140.109451 
	C1690.802856,140.263046 1695.885620,141.286530 1700.812866,142.480255 
	C1709.169312,144.504700 1715.297852,153.202332 1715.300171,161.894699 
	C1715.302856,172.090500 1708.272339,180.355591 1700.867188,182.056396 
	C1695.288940,183.337662 1689.609253,184.439255 1683.916748,184.891266 
	C1677.838867,185.373917 1671.692871,185.000000 1665.082764,185.000000 
	C1665.082764,191.453659 1665.082764,197.373932 1665.082764,203.639465 
	C1660.338989,203.639465 1656.094971,203.639465 1651.000000,203.639465 
	C1651.000000,199.973267 1651.000000,196.226868 1651.000000,191.983994 
M1665.001099,170.284241 
	C1665.703735,171.510422 1666.455322,173.827087 1667.101685,173.798035 
	C1675.808838,173.406784 1684.572754,173.160004 1693.166748,171.852310 
	C1697.435791,171.202713 1699.781128,167.400864 1700.044800,162.683243 
	C1700.311768,157.912979 1698.438477,154.770752 1694.008423,152.983536 
	C1684.541504,149.164185 1674.858398,151.737076 1665.000000,151.035461 
	C1665.000000,157.703156 1665.000000,163.516510 1665.001099,170.284241 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M614.521851,141.129486 
	C619.214172,140.560501 623.638000,140.068207 628.044373,140.192307 
	C629.115601,140.222488 630.442078,142.118759 631.122253,143.431137 
	C638.810303,158.264984 646.367004,173.166855 654.023682,188.017120 
	C656.641785,193.094925 659.441528,198.079056 662.634338,203.986893 
	C656.781677,203.986893 652.005554,204.187119 647.268738,203.844604 
	C646.100708,203.760147 644.385071,202.082794 644.067322,200.843781 
	C641.307312,190.081818 634.113464,187.513809 623.988281,188.876312 
	C618.758118,189.580109 613.346802,189.177063 608.033569,188.928207 
	C605.228760,188.796829 603.658691,189.779129 602.865112,192.332230 
	C602.816589,192.488251 602.708923,192.625336 602.633301,192.773499 
	C596.483826,204.817581 596.483826,204.817581 582.027527,203.682922 
	C592.860962,182.751862 603.553894,162.092361 614.521851,141.129486 
M610.673218,177.033417 
	C617.635010,177.474838 624.596741,177.916260 632.687012,178.429230 
	C628.547302,170.170715 625.092285,163.278091 621.218689,155.550415 
	C617.477417,163.100372 614.199280,169.715805 610.673218,177.033417 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M809.756104,63.780304 
	C811.261047,61.173363 812.583252,58.877502 813.772339,56.514652 
	C818.403748,47.311256 819.591125,46.504772 829.791931,48.173096 
	C831.608093,48.470135 833.667175,50.589554 834.611389,52.392582 
	C842.753784,67.942154 850.644470,83.623344 858.662170,99.238586 
	C860.685730,103.179436 862.896118,107.024315 865.581055,111.939156 
	C859.658875,111.939156 854.611328,112.146706 849.607239,111.772743 
	C848.542969,111.693207 847.131775,109.638321 846.739624,108.250671 
	C844.043762,98.713303 838.033386,95.386246 828.281311,96.844597 
	C822.578552,97.697403 816.629822,96.775894 810.822632,97.141739 
	C809.152649,97.246948 807.586792,99.049438 805.984070,100.090485 
	C805.865234,100.167702 805.809082,100.373894 805.766663,100.531639 
	C802.976990,110.904823 796.315552,114.514473 785.418457,111.193047 
	C793.361511,95.654762 801.436890,79.857681 809.756104,63.780304 
M816.121887,79.634315 
	C815.225647,81.614426 814.329468,83.594543 813.406921,85.632729 
	C820.916504,85.632729 827.765625,85.632729 835.288818,85.632729 
	C831.546814,78.168480 828.091919,71.276924 824.247253,63.607971 
	C821.365479,69.334145 818.934509,74.164513 816.121887,79.634315 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M997.006104,190.961365 
	C1004.986572,175.287628 1012.980225,160.031067 1020.605286,144.592468 
	C1022.344055,141.071945 1024.270386,139.426651 1028.180542,139.949356 
	C1030.307983,140.233704 1032.548096,139.740005 1034.654053,140.093765 
	C1036.046631,140.327682 1037.928467,141.109238 1038.507202,142.213715 
	C1048.954346,162.151123 1059.237183,182.174530 1069.540649,202.187088 
	C1069.677612,202.453049 1069.625732,202.816208 1069.755127,203.950317 
	C1064.970703,203.950317 1060.247803,204.168884 1055.571533,203.797806 
	C1054.391602,203.704193 1052.711792,201.897400 1052.365234,200.586166 
	C1049.693237,190.478775 1043.259155,187.361053 1033.252441,188.852020 
	C1027.712524,189.677460 1021.940613,189.179352 1016.288879,188.927063 
	C1013.313782,188.794250 1011.591431,189.789413 1010.737732,192.550354 
	C1010.593323,193.017120 1010.270386,193.426636 1010.047913,193.871597 
	C1004.389526,205.191345 1004.389954,205.191574 990.239624,203.441956 
	C992.594788,199.143555 994.740173,195.228027 997.006104,190.961365 
M1024.500854,164.998856 
	C1022.457031,169.173737 1020.413269,173.348618 1018.245300,177.777191 
	C1026.104736,177.777191 1033.158936,177.777191 1040.841309,177.777191 
	C1036.990601,170.052856 1033.353394,162.756714 1029.507202,155.041412 
	C1027.807739,158.462692 1026.344482,161.408463 1024.500854,164.998856 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M889.999084,177.604263 
	C890.027893,165.321823 890.053528,153.495102 890.096619,141.668457 
	C890.097717,141.360596 890.289368,141.053436 890.516418,140.374237 
	C894.635071,140.374237 898.880737,140.374237 903.758728,140.374237 
	C903.834961,141.993225 903.984863,143.745117 903.988892,145.497345 
	C904.011841,155.492981 903.599670,165.508865 904.101868,175.479279 
	C904.722839,187.808762 896.182861,199.712143 883.702454,203.178680 
	C873.129089,206.115555 862.671936,205.902817 852.900391,200.851456 
	C843.434692,195.958176 838.126221,187.870010 838.025574,176.941696 
	C837.914001,164.826675 838.000000,152.709839 838.000000,140.300812 
	C842.784363,140.300812 847.028992,140.300812 852.000000,140.300812 
	C852.000000,151.003098 851.951965,161.598190 852.036011,172.192230 
	C852.055420,174.641083 852.263855,177.149582 852.835083,179.522339 
	C855.327209,189.874298 861.697449,194.434311 872.742310,193.998215 
	C882.092102,193.629059 888.320496,187.876282 889.999084,177.604263 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1581.179443,204.100922 
	C1564.029907,207.229340 1542.381226,196.770447 1542.942627,175.987976 
	C1543.260864,164.206207 1543.000000,152.408783 1543.000000,140.312927 
	C1547.760010,140.312927 1552.005981,140.312927 1557.000000,140.312927 
	C1557.000000,150.982941 1556.847046,161.582245 1557.085571,172.172745 
	C1557.165649,175.729980 1558.062134,179.307983 1558.899414,182.801270 
	C1560.988403,191.517487 1572.628540,194.938232 1578.853638,193.873489 
	C1588.945312,192.147354 1594.947754,185.722717 1594.987061,176.763779 
	C1595.039673,164.782013 1595.000000,152.799850 1595.000000,140.410400 
	C1599.683472,140.410400 1604.046875,140.410400 1608.342773,140.410400 
	C1608.342773,154.468155 1609.151245,168.434357 1608.102051,182.259583 
	C1607.161865,194.649872 1593.986084,203.588654 1581.179443,204.100922 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249832,152.052338 
	C222.237595,151.286316 222.383850,150.449127 222.846985,150.199280 
	C232.832916,144.811829 242.851074,139.483978 252.880554,134.177795 
	C253.280838,133.966019 253.853729,134.080460 254.730713,134.070160 
	C258.501831,141.605118 261.783813,149.162247 265.300598,156.608459 
	C270.096161,166.762253 275.070740,176.831482 280.272491,187.564011 
	C278.363342,187.742661 276.989410,187.978577 275.614746,187.982758 
	C265.289154,188.014175 254.961838,187.892487 244.638489,188.048050 
	C239.908508,188.119324 236.766739,186.179230 234.972198,181.905762 
	C230.823563,172.026230 226.693497,162.138870 222.249832,152.052338 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1118.248779,61.651512 
	C1105.258911,54.825005 1091.185425,58.842915 1084.495239,66.008400 
	C1073.935181,77.318718 1077.854370,93.917969 1091.892334,99.735825 
	C1101.953247,103.905380 1112.234741,103.454514 1121.722046,97.140335 
	C1123.072388,96.241684 1124.669312,95.713684 1126.532104,94.834869 
	C1128.737061,97.557816 1130.881714,100.206268 1133.631592,103.602226 
	C1130.451172,105.409325 1127.803101,107.347153 1124.850952,108.519890 
	C1107.926270,115.243332 1091.861084,114.970665 1075.817993,104.704765 
	C1060.266235,94.753281 1059.498413,66.730553 1076.130737,55.325508 
	C1091.800781,44.580334 1116.676758,44.700726 1131.723022,56.516685 
	C1127.163818,65.397537 1126.367676,65.720810 1118.248779,61.651512 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M414.783386,99.298012 
	C415.787323,100.981430 416.534882,102.393715 417.248962,103.742722 
	C400.639862,115.460243 377.941467,116.522102 361.778687,105.820457 
	C342.258575,92.895897 344.086853,66.083130 361.221313,55.207539 
	C378.406921,44.299469 395.734741,45.144184 413.259735,54.137516 
	C414.250000,54.645676 414.990692,55.640167 416.227509,56.748959 
	C414.177399,59.567062 412.194244,62.293106 410.378937,64.788414 
	C404.216766,62.548382 398.533386,59.918011 392.565521,58.424866 
	C377.987305,54.777454 362.521271,67.106949 363.001068,81.586021 
	C363.334808,91.656906 373.333313,101.173401 384.541168,101.977760 
	C392.657532,102.560249 400.488220,101.616394 407.142242,96.410690 
	C411.219818,93.220634 412.447144,97.102898 414.783386,99.298012 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M289.713867,41.696579 
	C295.195679,38.752575 300.370728,36.024849 305.998932,33.058262 
	C305.998932,41.822704 306.218781,49.959709 305.820099,58.066299 
	C305.736755,59.761028 303.677032,61.830017 301.998993,62.884876 
	C297.300232,65.838661 292.388153,68.475731 287.445221,71.013809 
	C276.834961,76.461929 266.298096,82.090958 255.442108,87.007622 
	C249.428467,89.731201 245.246674,87.769714 242.512787,81.912567 
	C240.255341,77.076202 238.560699,71.977135 236.531784,66.776863 
	C252.149231,64.567497 263.842438,55.129131 276.871002,48.621387 
	C281.109222,46.504391 285.231689,44.155613 289.713867,41.696579 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M707.884399,59.000004 
	C701.784790,59.000000 696.168701,59.000000 690.283325,59.000000 
	C690.283325,55.182671 690.283325,51.937588 690.283325,48.344383 
	C711.018005,48.344383 731.589844,48.344383 752.581787,48.344383 
	C752.581787,51.668297 752.581787,55.028431 752.581787,58.907249 
	C744.890442,58.907249 737.306519,58.907249 729.225220,58.907249 
	C729.225220,76.647903 729.225220,93.892326 729.225220,111.565880 
	C724.038269,111.565880 719.340576,111.565880 714.131042,111.565880 
	C714.131042,94.256187 714.131042,77.011826 714.131042,59.000008 
	C711.878906,59.000008 710.123413,59.000008 707.884399,59.000004 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M715.830017,203.999329 
	C712.199280,203.999847 709.041992,203.999847 705.203125,203.999847 
	C705.203125,186.391861 705.203125,169.025589 705.203125,151.155594 
	C696.955627,151.155594 689.368652,151.155594 681.396851,151.155594 
	C681.396851,147.288467 681.396851,143.928253 681.396851,140.280197 
	C702.154419,140.280197 722.726807,140.280197 743.650024,140.280197 
	C743.650024,143.681564 743.650024,146.929855 743.650024,150.867889 
	C736.000671,150.867889 728.285889,150.867889 720.000000,150.867889 
	C720.000000,158.155212 720.000061,164.766907 720.000000,171.378616 
	C719.999878,180.874207 719.928772,190.370651 720.043518,199.864853 
	C720.077026,202.634186 719.636841,204.500015 715.830017,203.999329 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1391.283081,203.971634 
	C1390.211914,202.296921 1389.096680,200.647766 1389.080078,198.987564 
	C1388.939575,184.994446 1389.000000,170.999268 1389.000000,157.004715 
	C1389.000000,155.209335 1389.000000,153.413956 1389.000000,151.124359 
	C1380.920288,151.124359 1373.336914,151.124359 1365.380615,151.124359 
	C1365.380615,147.381683 1365.380615,144.135483 1365.380615,140.438263 
	C1385.613159,140.438263 1405.973389,140.438263 1426.668457,140.438263 
	C1426.668457,143.718689 1426.668457,146.966660 1426.668457,150.732910 
	C1419.083252,150.732910 1411.497070,150.732910 1403.227783,150.732910 
	C1403.227783,168.593185 1403.227783,185.956940 1403.227783,203.992081 
	C1398.960449,203.992081 1395.354492,203.992081 1391.283081,203.971634 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1848.043579,151.000000 
	C1844.919556,151.000000 1842.287720,151.000000 1839.335938,151.000000 
	C1839.335938,147.290390 1839.335938,144.042847 1839.335938,140.395416 
	C1859.698486,140.395416 1880.055298,140.395416 1900.707153,140.395416 
	C1900.707153,143.797333 1900.707153,147.042343 1900.707153,150.800247 
	C1893.002930,150.800247 1885.418579,150.800247 1877.158936,150.800247 
	C1877.158936,168.656586 1877.158936,186.020584 1877.158936,203.687408 
	C1872.240479,203.687408 1867.995361,203.687408 1863.243774,203.687408 
	C1863.243774,186.371948 1863.243774,169.127808 1863.243774,151.000000 
	C1858.033325,151.000000 1853.284546,151.000000 1848.043579,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1232.000000,56.065571 
	C1232.000000,53.281662 1232.000000,50.986809 1232.000000,48.348354 
	C1236.680664,48.348354 1240.920532,48.348354 1245.821289,48.348354 
	C1245.821289,65.643272 1245.821289,82.989029 1245.821289,100.814072 
	C1259.357544,100.814072 1272.256958,100.814072 1285.574707,100.814072 
	C1285.574707,104.650215 1285.574707,108.006706 1285.574707,111.686394 
	C1267.935547,111.686394 1250.381470,111.686394 1232.000000,111.686394 
	C1232.000000,93.311684 1232.000000,74.933151 1232.000000,56.065571 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M536.000000,184.999481 
	C536.000000,169.875076 536.000000,155.250610 536.000000,140.316010 
	C540.754639,140.316010 545.001587,140.316010 549.766357,140.316010 
	C549.766357,157.634521 549.766357,174.886276 549.766357,192.824371 
	C559.627319,192.824371 569.010010,192.824371 578.685364,192.824371 
	C578.685364,196.754700 578.685364,200.002487 578.685364,203.627167 
	C564.700684,203.627167 550.786255,203.627167 536.000000,203.627167 
	C536.000000,197.669952 536.000000,191.584686 536.000000,184.999481 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1829.000000,190.989380 
	C1829.000000,195.442474 1829.000000,199.397430 1829.000000,203.670273 
	C1823.946655,203.670273 1819.374268,203.670273 1814.398682,203.670273 
	C1814.398682,182.657349 1814.398682,161.659317 1814.398682,140.329453 
	C1819.052246,140.329453 1823.624634,140.329453 1829.000000,140.329453 
	C1829.000000,157.054794 1829.000000,173.773026 1829.000000,190.989380 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M674.774658,111.995224 
	C670.819214,111.998856 667.336670,111.998856 663.429016,111.998856 
	C663.429016,90.715347 663.429016,69.711861 663.429016,48.352730 
	C668.006592,48.352730 672.579346,48.352730 677.491516,48.352730 
	C677.649902,49.348263 677.973572,50.440781 677.975403,51.533840 
	C678.006958,70.332367 678.003845,89.130966 677.991760,107.929543 
	C677.990479,109.865608 678.474365,112.202095 674.774658,111.995224 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1090.772705,140.004562 
	C1092.156616,140.861237 1093.861206,141.708603 1093.867554,142.568512 
	C1094.017090,162.825409 1093.983398,183.083633 1093.983398,203.668945 
	C1088.949585,203.668945 1084.376221,203.668945 1079.398071,203.668945 
	C1079.398071,182.650421 1079.398071,161.646805 1079.398071,140.001099 
	C1083.090332,140.001099 1086.696289,140.001099 1090.772705,140.004562 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1040.102539,48.000072 
	C1043.193604,48.000008 1045.807861,48.000008 1048.706665,48.000008 
	C1048.706665,69.441231 1048.706665,90.325012 1048.706665,111.605042 
	C1044.289795,111.605042 1039.932861,111.605042 1035.285400,111.605042 
	C1035.285400,90.542465 1035.285400,69.658768 1035.285400,48.345013 
	C1036.766357,48.227345 1038.196045,48.113739 1040.102539,48.000072 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M962.652710,153.026749 
	C967.050171,155.521790 969.337463,158.771179 967.960266,163.682938 
	C966.512451,168.846176 961.969666,170.426437 957.702026,170.811371 
	C950.474243,171.463287 943.145020,170.990479 935.430542,170.990479 
	C935.430542,164.384598 935.430542,158.040909 935.430542,150.195282 
	C944.474976,151.128220 953.369141,152.045654 962.652710,153.026749 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M402.714844,186.783737 
	C397.319427,189.019211 392.327820,191.763702 387.008392,192.762695 
	C381.743500,193.751450 376.147827,192.978882 370.352631,192.978882 
	C370.352631,178.895111 370.352631,165.230820 370.352631,150.401657 
	C378.477264,151.139984 386.443909,151.096390 394.054932,152.719971 
	C408.607147,155.824234 416.483734,174.885895 402.714844,186.783737 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M444.144867,77.627747 
	C444.048462,71.364456 444.048462,65.559891 444.048462,58.450314 
	C452.939819,59.137753 461.458038,59.502121 469.887756,60.567043 
	C474.384705,61.135139 478.004944,63.596779 477.996735,69.010178 
	C477.990326,73.234734 474.474182,78.223618 469.385590,78.558517 
	C461.050598,79.107071 452.627319,78.313797 444.144867,77.627747 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M994.320068,78.962341 
	C986.118530,78.989311 978.352417,78.989311 970.299194,78.989311 
	C970.299194,72.222008 970.299194,65.989899 970.299194,58.477661 
	C978.929810,59.135586 987.426636,59.524628 995.849731,60.530663 
	C1000.299194,61.062096 1003.335144,64.212013 1003.883179,68.721153 
	C1004.444824,73.342896 1001.266235,75.916756 997.487854,77.772758 
	C996.600708,78.208542 995.667480,78.550331 994.320068,78.962341 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1186.816528,101.144806 
	C1171.355469,104.477806 1158.160889,98.664185 1154.585693,86.158348 
	C1151.969238,77.006355 1156.132080,66.509926 1164.413696,61.861435 
	C1176.870483,54.869423 1191.318604,57.444908 1199.979492,68.586563 
	C1207.806030,78.654709 1202.823364,92.072800 1193.944946,97.954254 
	C1191.913086,99.300293 1189.474854,100.032974 1186.816528,101.144806 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M770.453003,189.734497 
	C756.504517,178.929367 759.489441,162.867722 768.757019,155.839203 
	C776.323730,150.100723 784.840332,148.681015 794.018372,151.098419 
	C803.700256,153.648544 810.657776,161.866486 810.997986,171.202667 
	C811.350098,180.863586 805.391296,188.651230 795.484436,192.298721 
	C786.753479,195.513245 778.669006,194.237976 770.453003,189.734497 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M632.044312,90.861725 
	C626.852051,98.374687 619.409302,100.608414 611.190979,100.959213 
	C606.058167,101.178314 600.908386,100.999969 595.000000,100.999969 
	C595.000000,93.891731 595.000000,87.132027 595.000000,80.372330 
	C595.000000,73.548714 595.000000,66.725098 595.000000,59.103378 
	C606.372070,59.078632 617.478333,57.921280 627.475769,64.890663 
	C635.074951,70.188240 637.845337,82.489754 632.044312,90.861725 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1476.000000,160.047470 
	C1476.000000,156.929199 1476.000000,154.303818 1476.000000,151.151794 
	C1486.409058,151.330826 1496.373047,149.456924 1505.880981,154.832779 
	C1513.319824,159.038742 1516.051147,165.339050 1515.930908,173.227066 
	C1515.825195,180.158096 1509.896118,188.385040 1503.172729,189.907440 
	C1494.589233,191.851074 1485.765259,192.733002 1476.000000,194.233047 
	C1476.000000,181.993057 1476.000000,171.266724 1476.000000,160.047470 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1924.270020,159.441010 
	C1932.480957,148.401825 1951.493774,146.212234 1963.247314,156.156906 
	C1973.375488,164.726425 1973.089478,182.450455 1958.487549,190.784271 
	C1947.986450,196.777527 1932.974487,194.117508 1925.211548,184.756714 
	C1918.676514,176.876465 1918.449951,168.537659 1924.270020,159.441010 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1773.143677,191.392334 
	C1766.310303,193.987762 1759.853149,194.783249 1752.988403,192.580612 
	C1741.140991,188.779327 1735.730469,179.046066 1736.405640,170.598648 
	C1737.359497,158.664520 1747.423828,150.272537 1759.148682,149.963303 
	C1768.342529,149.720810 1776.322876,151.681717 1782.299316,159.501602 
	C1791.106201,171.024979 1785.537842,185.636261 1773.143677,191.392334 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1665.000488,169.807053 
	C1665.000000,163.516510 1665.000000,157.703156 1665.000000,151.035461 
	C1674.858398,151.737076 1684.541504,149.164185 1694.008423,152.983536 
	C1698.438477,154.770752 1700.311768,157.912979 1700.044800,162.683243 
	C1699.781128,167.400864 1697.435791,171.202713 1693.166748,171.852310 
	C1684.572754,173.160004 1675.808838,173.406784 1667.101685,173.798035 
	C1666.455322,173.827087 1665.703735,171.510422 1665.000488,169.807053 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M610.797119,176.682312 
	C614.199280,169.715805 617.477417,163.100372 621.218689,155.550415 
	C625.092285,163.278091 628.547302,170.170715 632.687012,178.429230 
	C624.596741,177.916260 617.635010,177.474838 610.797119,176.682312 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M816.312744,79.314598 
	C818.934509,74.164513 821.365479,69.334145 824.247253,63.607971 
	C828.091919,71.276924 831.546814,78.168480 835.288818,85.632729 
	C827.765625,85.632729 820.916504,85.632729 813.406921,85.632729 
	C814.329468,83.594543 815.225647,81.614426 816.312744,79.314598 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1024.691040,164.676544 
	C1026.344482,161.408463 1027.807739,158.462692 1029.507202,155.041412 
	C1033.353394,162.756714 1036.990601,170.052856 1040.841309,177.777191 
	C1033.158936,177.777191 1026.104736,177.777191 1018.245300,177.777191 
	C1020.413269,173.348618 1022.457031,169.173737 1024.691040,164.676544 
z"
      />
    </svg>
  );
};

CaTourainePoitouSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaTourainePoitouSvg;
