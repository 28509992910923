import * as dashboardConst from '../../common/const/dashboard.const';

// Actions
import {
  getCounters,
  getUserInvestmentsByCurrentTab,
  setCurrentTab,
} from '../../common/actions/dashboard.actions';
import { setOriginalJourneyId } from '../../../../redux/actions';
import { resetInvestorState } from '../../../../shared/actions/actions/investor.actions';
import { dashboardTab } from '../../../../shared/utils/CatalizrConstants';
import { isFeatureEnabled } from '../../../../shared/utils/CatalizrUtils';
import { getInvestmentTypeList } from '../../../../shared/api/investment.api';
import { initInvestmentType } from '../../../../shared/actions/actions/investment.actions';
import { fetchAgencyList } from '../../../../shared/actions/actions/bank.actions';

export const initViewNewOperation = (subDomain = null) => {
  return dispatch => {
    // INIT MODAL NEW OPERATION
    getInvestmentTypeList(null, null, subDomain).then(investmentTypeListResp => {
      // Get operation types list with product types allowed
      dispatch(initInvestmentType(investmentTypeListResp.data.data));
      // Get agency list if it is allowed in bank init
      if (isFeatureEnabled('display.agency_list')) {
        dispatch(fetchAgencyList());
      }
    });
  };
};

const refreshReduxData = () => ({ type: dashboardConst.REFRESH_REDUX_DATA });

export const initView = (subDomain = null) => {
  return async (dispatch, getState) => {
    const { tabIndex } = getState().dashboard;
    const hasOperationModule = isFeatureEnabled('module.operations');
    const hasOSTModule = isFeatureEnabled('module.OST');
    dispatch(refreshReduxData());
    dispatch(setOriginalJourneyId(''));
    dispatch(resetInvestorState());

    // INIT DASHBOARD OPERATIONS
    const getDashboardCounters = dispatch(getCounters());
    const getOperation = dispatch(getUserInvestmentsByCurrentTab());

    if (hasOSTModule && !hasOperationModule && tabIndex !== dashboardTab.CORPORATE_ACTIONS) {
      dispatch(setCurrentTab(dashboardTab.CORPORATE_ACTIONS));
    }

    // INIT MODAL NEW OPERATION
    if (hasOperationModule) {
      dispatch(initViewNewOperation(subDomain));
    }

    await Promise.all([getDashboardCounters, getOperation]);
  };
};
