import * as React from 'react';
import PropTypes from 'prop-types';

const CaCentreEstSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101135,31.018839 
	C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
	C367.081024,31.011070 199.130295,31.011070 31.158703,31.011070 
	C31.158703,61.733963 31.158703,91.854759 31.022190,122.902138 
	C31.067490,128.943542 31.112789,134.984955 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
	C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
	C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
	C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
	C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
	C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
	C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.158703,121.975555 
	C31.158703,91.854759 31.158703,61.733963 31.158703,31.011070 
	C199.130295,31.011070 367.081024,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
	C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
	C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
	C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
	C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
	C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
	C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
	C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
	C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
	C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
	C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
	C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
	C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
	C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
	C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
	C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
	C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
	C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
	C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
	C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396374,141.702835 
	C33.838219,153.473328 39.009460,164.324600 48.020203,172.170288 
	C68.104713,189.657944 92.682549,192.806702 117.854546,189.595886 
	C132.444336,187.734894 147.099915,183.871750 160.863556,178.640732 
	C181.619522,170.752213 201.621353,160.879379 222.249176,152.051559 
	C226.404083,161.530182 230.330032,170.776535 234.067825,180.098328 
	C235.717728,184.213165 238.189972,187.730011 242.735672,187.852341 
	C254.987122,188.182007 267.253296,187.965057 280.266357,187.965057 
	C277.519806,181.940796 275.127045,176.566132 272.629791,171.240448 
	C266.818359,158.846909 260.956604,146.476974 255.422760,133.933289 
	C258.574829,132.157990 261.474976,130.636963 264.254456,128.920486 
	C277.770233,120.573799 291.267151,112.196251 304.720276,103.749214 
	C305.683533,103.144417 306.875824,101.905647 306.891357,100.944397 
	C307.050720,91.058205 306.989105,81.168449 306.989105,70.488380 
	C296.574036,76.321434 287.078735,81.753006 277.476379,86.988258 
	C265.702301,93.407539 253.838638,99.662567 241.984283,105.615341 
	C241.267654,103.389175 240.693451,101.484711 239.875458,99.691437 
	C232.742523,84.053932 225.401993,68.507729 218.542130,52.751976 
	C217.187042,49.639626 215.690323,48.887329 212.702225,48.952824 
	C205.874237,49.102486 198.919937,49.812557 192.236374,48.818924 
	C180.807343,47.119785 173.616730,50.944286 170.342102,62.130207 
	C169.803635,63.969559 168.299454,65.508400 167.365356,67.252426 
	C154.794312,90.723755 142.229767,114.198608 129.707291,137.695877 
	C127.816177,141.244385 126.146652,144.910995 123.988312,149.310410 
	C140.375534,148.173737 154.983871,144.926407 167.883148,136.006546 
	C168.779724,135.386566 169.649460,134.499008 170.125000,133.535217 
	C173.845718,125.994522 177.498795,118.419891 181.110855,110.826302 
	C185.599518,101.389862 190.028885,91.925224 194.797562,81.807167 
	C195.738205,84.020462 196.409058,85.484077 196.989456,86.982727 
	C201.626358,98.955574 206.246140,110.935051 210.512741,122.962891 
	C193.228058,130.975769 176.711304,140.032120 159.251160,146.568451 
	C144.202454,152.202057 128.228149,155.762085 112.380074,158.725235 
	C101.272728,160.801987 89.658501,161.131119 78.330208,160.694672 
	C71.734215,160.440552 64.982796,157.753937 58.806889,155.019791 
	C53.446785,152.646805 52.397484,147.631470 53.604740,141.687454 
	C55.497101,132.370270 60.241489,124.435616 66.203453,117.513893 
	C83.444824,97.496994 105.090714,84.243439 130.842941,78.080078 
	C138.385437,76.274895 146.140747,75.358826 154.024963,73.996117 
	C153.025665,70.293663 151.905853,66.549919 151.009979,62.753334 
	C147.623413,48.401253 138.627228,43.034019 124.134506,46.355972 
	C108.544205,49.929512 93.716553,55.284279 79.939651,63.514069 
	C56.832554,77.317345 38.691296,95.204971 31.158703,121.975555 
M557.000000,152.512482 
	C557.000000,162.418365 557.000000,172.324249 557.000000,182.931808 
	C555.810913,182.048981 555.225647,181.758148 554.832825,181.301620 
	C545.884277,170.902435 536.993713,160.453064 528.004456,150.089279 
	C525.010803,146.637894 522.335205,142.153702 518.487366,140.417969 
	C514.809937,138.759079 509.797241,140.060150 505.320770,140.060150 
	C505.320770,161.674881 505.320770,182.586166 505.320770,203.560791 
	C510.347900,203.560791 515.047485,203.560791 520.092896,203.560791 
	C520.092896,189.396332 520.092896,175.666458 520.092896,161.171448 
	C523.878845,165.480911 527.144043,169.155533 530.362915,172.870331 
	C537.185852,180.744598 544.248718,188.430450 550.717163,196.586929 
	C556.857300,204.329422 561.181274,206.070435 570.618652,203.150574 
	C570.618652,182.200363 570.618652,161.288071 570.618652,140.323120 
	C565.986938,140.323120 561.739990,140.323120 557.000000,140.323120 
	C557.000000,144.261795 557.000000,147.890732 557.000000,152.512482 
M675.952271,202.179871 
	C675.952271,195.587738 675.952271,188.995590 675.952271,182.007904 
	C681.018127,182.007904 685.321716,181.925018 689.617798,182.068085 
	C690.649719,182.102463 691.956299,182.620178 692.625366,183.371078 
	C696.358032,187.560608 700.096191,191.764282 703.527466,196.199142 
	C709.779175,204.279449 715.668152,206.226807 726.172180,202.964264 
	C722.984863,198.998352 719.982727,195.124832 716.829529,191.378403 
	C713.700378,187.660477 710.419189,184.070526 706.912964,180.093674 
	C708.906555,179.288208 710.211853,178.586227 711.605408,178.224670 
	C726.429321,174.378616 728.187866,153.573822 717.835693,146.571198 
	C713.884644,143.898575 708.953125,141.586136 704.308105,141.235641 
	C691.092285,140.238419 677.794128,140.321747 664.527283,140.028946 
	C663.438965,140.004929 662.342346,140.353516 661.341797,140.514175 
	C661.341797,161.746231 661.341797,182.643753 661.341797,204.375381 
	C666.407837,203.924866 671.084290,203.508987 675.952271,202.179871 
M465.982697,97.479546 
	C467.460419,99.251770 468.881805,101.074959 470.428253,102.785049 
	C473.184967,105.833565 475.531128,110.400124 478.934326,111.443146 
	C483.795502,112.933044 489.453705,111.822426 495.807220,111.822426 
	C488.615265,103.456177 482.329132,96.143639 476.473511,89.331894 
	C481.057953,86.199883 485.813965,84.116447 488.939728,80.588577 
	C497.116455,71.360016 494.721375,53.429321 477.171448,50.377888 
	C462.942383,47.903854 448.150146,48.658337 433.605621,48.029324 
	C432.515930,47.982197 431.409454,48.324245 430.316071,48.484600 
	C430.316071,69.691696 430.316071,90.590446 430.316071,111.569382 
	C435.001160,111.569382 439.363251,111.569382 444.220642,111.569382 
	C444.220642,104.227356 444.220642,97.310722 444.220642,90.198929 
	C452.407135,90.186722 460.884186,87.428490 465.982697,97.479546 
M1013.512939,102.011017 
	C1009.683960,97.624641 1005.854919,93.238274 1001.813599,88.608742 
	C1004.943848,87.217857 1007.309143,86.406822 1009.431580,85.176743 
	C1014.804321,82.062965 1018.427979,77.794273 1019.052246,71.198387 
	C1020.435852,56.578552 1009.662048,50.242596 998.274597,49.195171 
	C985.582397,48.027725 972.754883,48.319950 959.985107,48.025082 
	C958.733826,47.996189 957.474792,48.305294 956.358704,48.440617 
	C956.358704,69.778145 956.358704,90.677834 956.358704,111.703796 
	C960.963623,111.703796 965.207336,111.703796 970.130249,111.703796 
	C970.130249,104.387390 970.130249,97.341240 970.130249,90.000259 
	C974.624146,90.000259 978.461426,89.742302 982.238525,90.107094 
	C984.180237,90.294624 986.462219,91.142639 987.806702,92.480095 
	C990.831543,95.489220 993.311401,99.038406 996.118469,102.276466 
	C998.920105,105.508438 1001.320618,110.249405 1004.853027,111.404060 
	C1009.684143,112.983269 1015.418762,111.798553 1021.654541,111.798553 
	C1018.606995,108.096375 1016.312012,105.308380 1013.512939,102.011017 
M618.832520,111.590912 
	C628.015015,110.231133 636.866272,107.290283 642.946960,100.262596 
	C655.912781,85.277481 652.324463,60.167953 630.721130,52.197338 
	C624.651550,49.957970 617.952637,48.843422 611.467041,48.447350 
	C601.446777,47.835419 591.361633,48.286068 581.327515,48.286068 
	C581.327515,69.725693 581.327515,90.618217 581.327515,111.890938 
	C593.760498,111.890938 605.869751,111.890938 618.832520,111.590912 
M1166.095215,111.552330 
	C1183.272461,115.160660 1199.354980,113.369560 1211.652710,99.630936 
	C1223.327393,86.588425 1221.556152,68.353539 1209.010742,57.950737 
	C1193.252441,44.883755 1175.144165,43.786102 1156.700439,52.726139 
	C1143.764648,58.996300 1137.181274,71.388405 1139.031250,85.194084 
	C1140.587769,96.810249 1152.853760,108.916641 1166.095215,111.552330 
M873.460571,97.963852 
	C877.565430,101.429825 881.174316,105.932320 885.863892,108.176338 
	C903.120911,116.434143 920.221741,114.251427 936.754089,105.511650 
	C938.062439,104.820007 939.789001,103.278061 939.825684,102.085312 
	C940.089600,93.503387 939.967041,84.909576 939.967041,76.341446 
	C928.611267,76.341446 918.030212,76.341446 907.282104,76.341446 
	C907.282104,79.931923 907.282104,83.178589 907.282104,87.106750 
	C913.593567,87.106750 919.645630,87.106750 925.592102,87.106750 
	C926.640259,97.013512 926.287964,98.033211 921.064026,99.885406 
	C913.016296,102.738823 904.856750,103.341042 896.844543,100.010834 
	C888.918823,96.716568 883.893799,90.651985 883.141418,82.063545 
	C882.446655,74.132271 885.662781,67.418922 892.507935,63.066837 
	C903.475525,56.093719 914.624084,56.373432 925.632629,63.134865 
	C930.123108,65.892876 931.446167,65.352173 934.921082,59.253120 
	C937.254883,55.157040 933.690063,54.450733 931.424377,53.208038 
	C919.551208,46.695835 906.913025,45.536274 894.069885,49.171162 
	C869.217651,56.204884 861.694580,78.567993 873.460571,97.963852 
M953.499695,202.036713 
	C957.997070,202.984207 962.455627,204.416595 966.999756,204.781082 
	C977.356445,205.611771 987.582092,204.632568 996.582886,198.848923 
	C1008.620361,191.113998 1008.029968,176.782028 994.944214,171.229385 
	C985.955017,167.415054 976.090271,165.659760 966.609619,163.009979 
	C962.110779,161.752548 957.165771,161.440521 954.048035,157.230423 
	C957.491638,152.516403 957.717957,151.935730 966.253540,150.489136 
	C977.169128,148.639191 987.055725,152.245621 996.579590,158.167358 
	C998.178406,156.127045 999.673584,154.181671 1001.208923,152.268463 
	C1003.560669,149.337830 1003.036804,147.182114 999.508789,145.830536 
	C995.520508,144.302628 991.622437,142.408783 987.510376,141.358246 
	C973.347107,137.739914 959.395813,137.377213 946.528198,145.793976 
	C936.951599,152.058060 936.703125,164.401459 946.535583,169.980820 
	C952.281860,173.241547 958.975403,174.926788 965.363281,176.937973 
	C972.394653,179.151764 979.712280,180.520233 986.620605,183.038055 
	C991.092102,184.667755 991.041443,187.446503 987.119385,190.253433 
	C985.943909,191.094650 984.483521,191.605759 983.085632,192.057312 
	C972.105713,195.604065 961.659912,193.105911 951.379089,189.109146 
	C948.568665,188.016617 945.342102,185.231247 943.193542,185.934143 
	C940.777222,186.724640 939.347168,190.570343 937.535095,193.120407 
	C937.282776,193.475372 937.299438,194.021378 936.955139,195.482162 
	C942.155762,197.631729 947.417053,199.806366 953.499695,202.036713 
M750.261536,202.645035 
	C766.411499,203.293228 782.561523,203.941406 798.620117,204.585938 
	C798.620117,199.866043 798.620117,196.619812 798.620117,193.000000 
	C796.607300,193.000000 794.965332,193.000015 793.323303,193.000015 
	C783.160889,192.999954 772.997803,192.933533 762.836304,193.029572 
	C758.189148,193.073486 754.956177,191.096390 754.168945,186.571747 
	C753.591431,183.252304 754.055786,179.751556 754.055786,175.842316 
	C767.708740,175.842316 780.625549,175.842316 793.548340,175.842316 
	C793.548340,171.962341 793.548340,168.601501 793.548340,164.740372 
	C780.186829,164.740372 767.271301,164.740372 754.325195,164.740372 
	C754.325195,159.980469 754.325195,155.734589 754.325195,150.814728 
	C768.677551,150.814728 782.717590,150.814728 796.623901,150.814728 
	C796.623901,146.879150 796.623901,143.630585 796.623901,140.309113 
	C777.673157,140.309113 759.100159,140.309113 740.263428,140.309113 
	C740.164673,142.059219 740.012695,143.518707 740.010803,144.978378 
	C739.992859,159.139114 740.002991,173.299896 739.998779,187.460663 
	C739.996704,194.463379 742.935608,199.545013 750.261536,202.645035 
M919.365723,204.000000 
	C921.122498,204.000000 922.879333,204.000000 924.678955,204.000000 
	C924.678955,199.985245 924.678955,196.738922 924.678955,193.000000 
	C922.572205,193.000000 920.781128,193.000031 918.989990,192.999985 
	C908.832703,192.999786 898.675415,192.976837 888.518250,193.009506 
	C883.857239,193.024506 880.794739,190.834274 880.125427,186.267166 
	C879.655884,183.063095 880.034851,179.734711 880.034851,175.809753 
	C893.684082,175.809753 906.721008,175.809753 919.626831,175.809753 
	C919.626831,171.882553 919.626831,168.635101 919.626831,164.900024 
	C906.351868,164.900024 893.440369,164.900024 880.415710,164.900024 
	C880.415710,160.030273 880.415710,155.669266 880.415710,150.817657 
	C894.735657,150.817657 908.646729,150.817657 922.541382,150.817657 
	C922.541382,146.947632 922.541382,143.588425 922.541382,140.379669 
	C903.514221,140.379669 884.947815,140.379669 866.000000,140.379669 
	C866.000000,155.550491 866.419861,170.360260 865.867676,185.133728 
	C865.422974,197.030563 873.049011,204.027405 884.929321,204.004593 
	C896.085571,203.983170 907.242004,204.000000 919.365723,204.000000 
M453.540405,151.000000 
	C464.615814,151.000000 475.691254,151.000000 486.616425,151.000000 
	C486.616425,146.862793 486.616425,143.615112 486.616425,140.317688 
	C467.657318,140.317688 449.083496,140.317688 430.000000,140.317688 
	C430.000000,142.420792 430.000000,144.211685 430.000000,146.002563 
	C430.000000,159.164581 429.993591,172.326614 430.001892,185.488632 
	C430.009399,197.325287 436.555664,203.934265 448.435211,203.987946 
	C460.430725,204.042145 472.426727,204.011261 484.422424,203.984680 
	C485.852234,203.981522 487.281586,203.771286 488.655151,203.661499 
	C488.655151,199.940582 488.655151,196.692139 488.655151,193.000000 
	C486.523285,193.000000 484.721985,193.000061 482.920654,193.000000 
	C473.090790,192.999664 463.260925,193.003357 453.431061,192.997223 
	C448.614532,192.994217 444.891754,191.258835 444.215576,186.046738 
	C443.801300,182.853500 444.138977,179.562698 444.138977,175.824417 
	C457.728149,175.824417 470.645111,175.824417 483.717957,175.824417 
	C483.717957,172.066727 483.717957,168.820801 483.717957,164.893631 
	C470.412048,164.893631 457.369690,164.893631 444.336334,164.893631 
	C444.336334,159.958435 444.336334,155.712479 444.336334,151.000000 
	C447.296082,151.000000 449.925354,151.000000 453.540405,151.000000 
M538.501221,84.000000 
	C545.920776,84.000000 553.340332,84.000000 560.627563,84.000000 
	C560.627563,80.222672 560.627563,77.305420 560.627563,73.892075 
	C547.346985,73.892075 534.430542,73.892075 521.427612,73.892075 
	C521.427612,68.675247 521.427612,63.977005 521.427612,58.784042 
	C536.100525,58.784042 550.352112,58.784042 564.696594,58.784042 
	C564.696594,55.024078 564.696594,51.777241 564.696594,48.417774 
	C545.362915,48.417774 526.335510,48.417774 507.011932,48.417774 
	C507.011932,64.814552 506.727905,80.802406 507.125977,96.773254 
	C507.340637,105.385307 514.908386,111.855255 523.709290,111.979622 
	C530.706238,112.078499 537.705627,111.999901 544.703979,111.999977 
	C551.644653,112.000061 558.585327,112.000000 565.554810,112.000000 
	C565.554810,107.976120 565.554810,104.614975 565.554810,100.999519 
	C553.290344,100.999519 541.459534,101.252029 529.655579,100.801567 
	C527.075745,100.703110 523.285217,98.660973 522.358032,96.516884 
	C520.822876,92.966713 521.160400,88.606766 520.642761,84.000000 
	C526.913818,84.000000 532.207825,84.000000 538.501221,84.000000 
M801.875183,79.375999 
	C796.471069,89.977013 791.066956,100.578033 785.496948,111.504478 
	C800.249695,113.184372 800.300598,113.205963 805.532837,100.872475 
	C806.699768,98.121773 808.151978,96.735382 811.410645,96.916679 
	C816.888550,97.221458 822.482727,97.620560 827.871887,96.865807 
	C837.891907,95.462479 844.731689,98.205429 847.447815,108.672226 
	C847.794800,110.009247 849.808228,111.712265 851.178833,111.838089 
	C855.589661,112.242996 860.061035,111.988846 865.202026,111.988846 
	C864.117859,109.576042 863.412415,107.780495 862.529846,106.076714 
	C853.931458,89.478546 844.888123,73.092461 836.892517,56.209770 
	C833.780151,49.638073 830.212524,47.102627 823.073547,47.802608 
	C819.244690,48.178032 817.244141,49.163074 815.567444,52.662327 
	C811.351501,61.460983 806.628662,70.016754 801.875183,79.375999 
M410.488403,108.169365 
	C412.878540,106.747169 415.268646,105.324974 417.839661,103.795143 
	C416.670441,102.094894 415.512268,100.702354 414.676117,99.137726 
	C412.547211,95.153976 410.427551,94.538979 406.191681,97.185112 
	C395.760986,103.701073 384.575439,104.311050 373.695068,98.215912 
	C361.163055,91.195526 359.051605,74.531677 370.760590,64.508690 
	C380.337128,56.311108 395.723572,56.294247 405.822968,63.238472 
	C409.966736,66.087700 411.103455,65.462135 414.792572,59.505436 
	C417.199463,55.619026 414.130554,54.597202 411.680328,53.335857 
	C402.233826,48.472874 392.446014,46.322105 381.639923,47.826130 
	C372.644257,49.078167 364.460419,51.885967 357.844238,57.843441 
	C348.536346,66.224632 345.713165,77.103668 349.214142,89.050827 
	C352.683350,100.889343 361.745941,107.422195 373.016113,110.948006 
	C385.514862,114.858177 397.826019,113.366814 410.488403,108.169365 
M1107.809204,47.850266 
	C1104.251343,47.898724 1100.673340,47.721989 1097.139404,48.038059 
	C1086.732788,48.968822 1077.723999,52.807396 1070.628662,60.821949 
	C1060.548340,72.208092 1061.780518,93.413986 1074.543579,103.386810 
	C1087.216309,113.288956 1101.330200,114.777565 1116.229126,111.649940 
	C1122.339478,110.367256 1128.486206,108.065453 1133.228882,103.081345 
	C1131.572266,100.903130 1129.892090,99.221886 1128.858887,97.208595 
	C1127.400513,94.366776 1125.526367,94.789993 1123.630249,96.203354 
	C1116.725220,101.350525 1108.887695,102.391907 1100.575317,102.036583 
	C1090.314453,101.597961 1079.197144,91.530205 1079.000000,82.219818 
	C1078.670288,66.639374 1093.570435,54.619293 1108.851318,58.531136 
	C1114.677124,60.022541 1120.213867,62.643192 1126.284180,64.898476 
	C1128.141724,62.360214 1130.136108,59.635067 1132.324341,56.644901 
	C1125.053101,51.177189 1117.322998,48.369427 1107.809204,47.850266 
M416.859436,193.786987 
	C413.868317,190.814682 412.906525,184.241852 405.993927,188.896698 
	C396.378571,195.371506 386.028931,195.619125 375.387238,191.016678 
	C363.899475,186.048325 359.805206,171.736252 366.252197,161.015274 
	C372.532410,150.571640 389.818054,147.269272 400.373840,152.186218 
	C403.620178,153.698410 406.737305,155.488037 410.121002,157.259186 
	C412.344788,154.113312 414.379333,151.235168 416.629913,148.051437 
	C399.257538,137.382172 381.961426,135.628693 364.448395,144.952682 
	C343.200317,156.265213 342.006470,184.387131 362.061981,197.538147 
	C375.219330,206.165848 389.622009,207.101410 404.242462,202.136749 
	C408.809753,200.585831 412.723541,197.110397 416.859436,193.786987 
M307.000000,39.669872 
	C307.000000,37.561855 307.000000,35.453835 307.000000,32.384922 
	C296.963776,37.893322 287.861420,43.311874 278.391510,47.985691 
	C264.868439,54.659920 252.718918,64.429695 236.584457,66.763481 
	C238.053619,70.484207 239.518585,74.084290 240.900955,77.715805 
	C245.080536,88.695686 249.797241,90.772270 259.989502,85.458099 
	C274.654724,77.811760 289.213776,69.956123 303.672150,61.926861 
	C305.269867,61.039589 306.697784,58.497776 306.850189,56.613762 
	C307.278687,51.316505 306.999878,45.962025 307.000000,39.669872 
M714.000000,64.633743 
	C714.000000,80.239998 714.000000,95.846260 714.000000,111.588013 
	C719.293945,111.588013 723.990173,111.588013 729.181519,111.588013 
	C729.181519,93.937126 729.181519,76.692543 729.181519,58.950165 
	C737.262024,58.950165 744.846313,58.950165 752.603638,58.950165 
	C752.603638,55.071381 752.603638,51.711815 752.603638,48.323669 
	C731.633728,48.323669 711.061951,48.323669 690.441589,48.323669 
	C690.441589,52.018169 690.441589,55.378567 690.441589,59.065311 
	C698.339783,59.065311 705.799622,59.065311 713.644348,59.065311 
	C713.778320,60.800648 713.889160,62.235676 714.000000,64.633743 
M1016.721863,150.999008 
	C1023.326477,150.999008 1029.931152,150.999008 1037.201782,150.999008 
	C1037.201782,169.023865 1037.201782,186.390259 1037.201782,203.617401 
	C1042.470581,203.617401 1047.049194,203.617401 1052.124268,203.617401 
	C1052.124268,185.995865 1052.124268,168.750534 1052.124268,150.831131 
	C1060.326660,150.831131 1068.040405,150.831131 1075.631104,150.831131 
	C1075.631104,146.892899 1075.631104,143.644424 1075.631104,140.298889 
	C1054.688110,140.298889 1034.115356,140.298889 1013.016541,140.298889 
	C1013.016541,143.202591 1012.771667,145.861191 1013.143066,148.430740 
	C1013.281921,149.391510 1014.857056,150.144684 1016.721863,150.999008 
M622.094482,203.755524 
	C622.396301,202.021667 622.951172,200.289124 622.960022,198.553787 
	C623.031372,184.559799 622.991638,170.565308 623.010620,156.570999 
	C623.013000,154.813278 623.159424,153.055740 623.260742,150.820480 
	C631.397278,150.820480 638.983032,150.820480 646.715820,150.820480 
	C646.715820,147.062119 646.715820,143.816238 646.715820,140.391663 
	C626.069702,140.391663 605.709717,140.391663 585.332764,140.391663 
	C585.332764,144.035690 585.332764,147.283676 585.332764,151.207718 
	C593.359985,151.207718 601.074463,151.207718 609.255615,151.207718 
	C609.255615,169.138718 609.255615,186.385635 609.255615,203.950455 
	C613.566467,203.950455 617.373779,203.950455 622.094482,203.755524 
M1247.000000,59.519566 
	C1247.000000,55.776756 1247.000000,52.033951 1247.000000,48.349667 
	C1241.583496,48.349667 1237.011841,48.349667 1232.419678,48.349667 
	C1232.419678,69.700356 1232.419678,90.698601 1232.419678,111.680847 
	C1250.394653,111.680847 1267.947998,111.680847 1285.569092,111.680847 
	C1285.569092,107.995415 1285.569092,104.639030 1285.569092,100.803482 
	C1272.578857,100.803482 1260.013062,100.803482 1247.000000,100.803482 
	C1247.000000,87.051079 1247.000000,73.781784 1247.000000,59.519566 
M1040.593628,112.000000 
	C1043.539673,112.000000 1046.485718,112.000000 1049.639160,112.000000 
	C1049.639160,90.427299 1049.639160,69.425079 1049.639160,48.293259 
	C1044.701660,48.293259 1040.128906,48.293259 1035.369629,48.293259 
	C1035.369629,69.589920 1035.369629,90.591743 1035.369629,111.768723 
	C1037.033081,111.858688 1038.338257,111.929276 1040.593628,112.000000 
M678.000000,52.666656 
	C677.889221,51.233215 677.778442,49.799774 677.662903,48.304283 
	C673.013062,48.304283 668.772278,48.304283 664.377014,48.304283 
	C664.377014,69.610931 664.377014,90.611465 664.377014,111.690292 
	C668.998047,111.690292 673.239014,111.690292 678.000000,111.690292 
	C678.000000,92.134758 678.000000,72.884499 678.000000,52.666656 
M834.498718,178.000000 
	C840.235779,178.000000 845.972900,178.000000 851.658936,178.000000 
	C851.658936,173.599731 851.658936,170.025711 851.658936,166.410660 
	C838.313232,166.410660 825.312622,166.410660 812.340576,166.410660 
	C812.340576,170.399368 812.340576,173.973373 812.340576,178.000000 
	C819.614624,178.000000 826.557068,178.000000 834.498718,178.000000 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M242.013702,105.988602 
	C253.838638,99.662567 265.702301,93.407539 277.476379,86.988258 
	C287.078735,81.753006 296.574036,76.321434 306.989105,70.488380 
	C306.989105,81.168449 307.050720,91.058205 306.891357,100.944397 
	C306.875824,101.905647 305.683533,103.144417 304.720276,103.749214 
	C291.267151,112.196251 277.770233,120.573799 264.254456,128.920486 
	C261.474976,130.636963 258.574829,132.157990 255.040039,133.905914 
	C253.554413,134.208954 252.666992,134.201675 251.977570,134.567535 
	C242.534271,139.578979 233.106583,144.620377 223.717834,149.732986 
	C222.965332,150.142746 222.527679,151.130707 221.943710,151.849960 
	C201.621353,160.879379 181.619522,170.752213 160.863556,178.640732 
	C147.099915,183.871750 132.444336,187.734894 117.854546,189.595886 
	C92.682549,192.806702 68.104713,189.657944 48.020203,172.170288 
	C39.009460,164.324600 33.838219,153.473328 31.469835,141.240967 
	C31.112789,134.984955 31.067490,128.943542 31.090446,122.438843 
	C38.691296,95.204971 56.832554,77.317345 79.939651,63.514069 
	C93.716553,55.284279 108.544205,49.929512 124.134506,46.355972 
	C138.627228,43.034019 147.623413,48.401253 151.009979,62.753334 
	C151.905853,66.549919 153.025665,70.293663 154.024963,73.996117 
	C146.140747,75.358826 138.385437,76.274895 130.842941,78.080078 
	C105.090714,84.243439 83.444824,97.496994 66.203453,117.513893 
	C60.241489,124.435616 55.497101,132.370270 53.604740,141.687454 
	C52.397484,147.631470 53.446785,152.646805 58.806889,155.019791 
	C64.982796,157.753937 71.734215,160.440552 78.330208,160.694672 
	C89.658501,161.131119 101.272728,160.801987 112.380074,158.725235 
	C128.228149,155.762085 144.202454,152.202057 159.251160,146.568451 
	C176.711304,140.032120 193.228058,130.975769 210.856308,122.871277 
	C221.710114,117.149040 231.861908,111.568817 242.013702,105.988602 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910889,111.579178 
	C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
	C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
	C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
	C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
	C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
	C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
	C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
	C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
	C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
	C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
	C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
	C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
	C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
	C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.984283,105.615341 
	C231.861908,111.568817 221.710114,117.149040 211.214737,122.820877 
	C206.246140,110.935051 201.626358,98.955574 196.989456,86.982727 
	C196.409058,85.484077 195.738205,84.020462 194.797562,81.807167 
	C190.028885,91.925224 185.599518,101.389862 181.110855,110.826302 
	C177.498795,118.419891 173.845718,125.994522 170.125000,133.535217 
	C169.649460,134.499008 168.779724,135.386566 167.883148,136.006546 
	C154.983871,144.926407 140.375534,148.173737 123.988312,149.310410 
	C126.146652,144.910995 127.816177,141.244385 129.707291,137.695877 
	C142.229767,114.198608 154.794312,90.723755 167.365356,67.252426 
	C168.299454,65.508400 169.803635,63.969559 170.342102,62.130207 
	C173.616730,50.944286 180.807343,47.119785 192.236374,48.818924 
	C198.919937,49.812557 205.874237,49.102486 212.702225,48.952824 
	C215.690323,48.887329 217.187042,49.639626 218.542130,52.751976 
	C225.401993,68.507729 232.742523,84.053932 239.875458,99.691437 
	C240.693451,101.484711 241.267654,103.389175 241.984283,105.615341 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M557.000000,152.016068 
	C557.000000,147.890732 557.000000,144.261795 557.000000,140.323120 
	C561.739990,140.323120 565.986938,140.323120 570.618652,140.323120 
	C570.618652,161.288071 570.618652,182.200363 570.618652,203.150574 
	C561.181274,206.070435 556.857300,204.329422 550.717163,196.586929 
	C544.248718,188.430450 537.185852,180.744598 530.362915,172.870331 
	C527.144043,169.155533 523.878845,165.480911 520.092896,161.171448 
	C520.092896,175.666458 520.092896,189.396332 520.092896,203.560791 
	C515.047485,203.560791 510.347900,203.560791 505.320770,203.560791 
	C505.320770,182.586166 505.320770,161.674881 505.320770,140.060150 
	C509.797241,140.060150 514.809937,138.759079 518.487366,140.417969 
	C522.335205,142.153702 525.010803,146.637894 528.004456,150.089279 
	C536.993713,160.453064 545.884277,170.902435 554.832825,181.301620 
	C555.225647,181.758148 555.810913,182.048981 557.000000,182.931808 
	C557.000000,172.324249 557.000000,162.418365 557.000000,152.016068 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M675.856506,202.636490 
	C671.084290,203.508987 666.407837,203.924866 661.341797,204.375381 
	C661.341797,182.643753 661.341797,161.746231 661.341797,140.514175 
	C662.342346,140.353516 663.438965,140.004929 664.527283,140.028946 
	C677.794128,140.321747 691.092285,140.238419 704.308105,141.235641 
	C708.953125,141.586136 713.884644,143.898575 717.835693,146.571198 
	C728.187866,153.573822 726.429321,174.378616 711.605408,178.224670 
	C710.211853,178.586227 708.906555,179.288208 706.912964,180.093674 
	C710.419189,184.070526 713.700378,187.660477 716.829529,191.378403 
	C719.982727,195.124832 722.984863,198.998352 726.172180,202.964264 
	C715.668152,206.226807 709.779175,204.279449 703.527466,196.199142 
	C700.096191,191.764282 696.358032,187.560608 692.625366,183.371078 
	C691.956299,182.620178 690.649719,182.102463 689.617798,182.068085 
	C685.321716,181.925018 681.018127,182.007904 675.952271,182.007904 
	C675.952271,188.995590 675.952271,195.587738 675.856506,202.636490 
M683.568970,151.000000 
	C681.152710,151.000000 678.736450,151.000000 676.340637,151.000000 
	C676.340637,158.060516 676.340637,164.301804 676.340637,170.980209 
	C683.797058,170.980209 690.935669,171.270920 698.038574,170.892471 
	C704.237976,170.562180 708.886292,166.193726 708.999451,161.504913 
	C709.136353,155.837494 706.612549,153.079681 699.349976,152.013123 
	C694.470154,151.296494 689.482910,151.312027 683.568970,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M465.727722,97.226303 
	C460.884186,87.428490 452.407135,90.186722 444.220642,90.198929 
	C444.220642,97.310722 444.220642,104.227356 444.220642,111.569382 
	C439.363251,111.569382 435.001160,111.569382 430.316071,111.569382 
	C430.316071,90.590446 430.316071,69.691696 430.316071,48.484600 
	C431.409454,48.324245 432.515930,47.982197 433.605621,48.029324 
	C448.150146,48.658337 462.942383,47.903854 477.171448,50.377888 
	C494.721375,53.429321 497.116455,71.360016 488.939728,80.588577 
	C485.813965,84.116447 481.057953,86.199883 476.473511,89.331894 
	C482.329132,96.143639 488.615265,103.456177 495.807220,111.822426 
	C489.453705,111.822426 483.795502,112.933044 478.934326,111.443146 
	C475.531128,110.400124 473.184967,105.833565 470.428253,102.785049 
	C468.881805,101.074959 467.460419,99.251770 465.727722,97.226303 
M472.119629,61.037682 
	C462.947601,58.267956 453.626190,58.992062 444.358215,59.137848 
	C444.358215,66.096390 444.358215,72.338165 444.358215,78.976501 
	C452.503326,78.976501 460.308502,79.227180 468.087158,78.881653 
	C472.687683,78.677307 476.479095,75.877319 477.770691,71.562439 
	C479.049530,67.290154 477.451263,63.344475 472.119629,61.037682 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1013.765015,102.265701 
	C1016.312012,105.308380 1018.606995,108.096375 1021.654541,111.798553 
	C1015.418762,111.798553 1009.684143,112.983269 1004.853027,111.404060 
	C1001.320618,110.249405 998.920105,105.508438 996.118469,102.276466 
	C993.311401,99.038406 990.831543,95.489220 987.806702,92.480095 
	C986.462219,91.142639 984.180237,90.294624 982.238525,90.107094 
	C978.461426,89.742302 974.624146,90.000259 970.130249,90.000259 
	C970.130249,97.341240 970.130249,104.387390 970.130249,111.703796 
	C965.207336,111.703796 960.963623,111.703796 956.358704,111.703796 
	C956.358704,90.677834 956.358704,69.778145 956.358704,48.440617 
	C957.474792,48.305294 958.733826,47.996189 959.985107,48.025082 
	C972.754883,48.319950 985.582397,48.027725 998.274597,49.195171 
	C1009.662048,50.242596 1020.435852,56.578552 1019.052246,71.198387 
	C1018.427979,77.794273 1014.804321,82.062965 1009.431580,85.176743 
	C1007.309143,86.406822 1004.943848,87.217857 1001.813599,88.608742 
	C1005.854919,93.238274 1009.683960,97.624641 1013.765015,102.265701 
M993.048096,78.998215 
	C999.540039,77.902016 1003.756897,74.506004 1003.997803,70.179947 
	C1004.279297,65.125397 1001.606018,60.995724 996.183716,60.316261 
	C987.728455,59.256733 979.158813,59.109737 970.382019,58.560055 
	C970.382019,65.916039 970.382019,72.269066 970.382019,78.999809 
	C977.884827,78.999809 985.010681,78.999809 993.048096,78.998215 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M618.405762,111.740921 
	C605.869751,111.890938 593.760498,111.890938 581.327515,111.890938 
	C581.327515,90.618217 581.327515,69.725693 581.327515,48.286068 
	C591.361633,48.286068 601.446777,47.835419 611.467041,48.447350 
	C617.952637,48.843422 624.651550,49.957970 630.721130,52.197338 
	C652.324463,60.167953 655.912781,85.277481 642.946960,100.262596 
	C636.866272,107.290283 628.015015,110.231133 618.405762,111.740921 
M595.909851,59.242752 
	C595.606567,64.956955 595.144775,70.668205 595.038147,76.386070 
	C594.887695,84.455124 595.000000,92.529076 595.000000,100.978188 
	C601.842224,100.978188 608.032959,101.576607 614.058533,100.833954 
	C622.498840,99.793686 630.332886,96.628006 633.822510,88.012924 
	C638.497559,76.471329 632.774719,64.435677 620.340942,61.222141 
	C612.817688,59.277718 604.684326,59.693611 595.909851,59.242752 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1165.725708,111.364456 
	C1152.853760,108.916641 1140.587769,96.810249 1139.031250,85.194084 
	C1137.181274,71.388405 1143.764648,58.996300 1156.700439,52.726139 
	C1175.144165,43.786102 1193.252441,44.883755 1209.010742,57.950737 
	C1221.556152,68.353539 1223.327393,86.588425 1211.652710,99.630936 
	C1199.354980,113.369560 1183.272461,115.160660 1165.725708,111.364456 
M1200.615967,69.056694 
	C1194.264404,59.006248 1180.408813,55.482796 1167.660400,60.676239 
	C1154.933350,65.860954 1149.976440,79.176033 1156.994019,90.677063 
	C1161.922363,98.754219 1169.566528,102.194031 1179.165527,102.072433 
	C1198.455566,101.828064 1208.453491,87.256332 1200.615967,69.056694 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M873.205994,97.693192 
	C861.694580,78.567993 869.217651,56.204884 894.069885,49.171162 
	C906.913025,45.536274 919.551208,46.695835 931.424377,53.208038 
	C933.690063,54.450733 937.254883,55.157040 934.921082,59.253120 
	C931.446167,65.352173 930.123108,65.892876 925.632629,63.134865 
	C914.624084,56.373432 903.475525,56.093719 892.507935,63.066837 
	C885.662781,67.418922 882.446655,74.132271 883.141418,82.063545 
	C883.893799,90.651985 888.918823,96.716568 896.844543,100.010834 
	C904.856750,103.341042 913.016296,102.738823 921.064026,99.885406 
	C926.287964,98.033211 926.640259,97.013512 925.592102,87.106750 
	C919.645630,87.106750 913.593567,87.106750 907.282104,87.106750 
	C907.282104,83.178589 907.282104,79.931923 907.282104,76.341446 
	C918.030212,76.341446 928.611267,76.341446 939.967041,76.341446 
	C939.967041,84.909576 940.089600,93.503387 939.825684,102.085312 
	C939.789001,103.278061 938.062439,104.820007 936.754089,105.511650 
	C920.221741,114.251427 903.120911,116.434143 885.863892,108.176338 
	C881.174316,105.932320 877.565430,101.429825 873.205994,97.693192 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M953.088989,202.008850 
	C947.417053,199.806366 942.155762,197.631729 936.955139,195.482162 
	C937.299438,194.021378 937.282776,193.475372 937.535095,193.120407 
	C939.347168,190.570343 940.777222,186.724640 943.193542,185.934143 
	C945.342102,185.231247 948.568665,188.016617 951.379089,189.109146 
	C961.659912,193.105911 972.105713,195.604065 983.085632,192.057312 
	C984.483521,191.605759 985.943909,191.094650 987.119385,190.253433 
	C991.041443,187.446503 991.092102,184.667755 986.620605,183.038055 
	C979.712280,180.520233 972.394653,179.151764 965.363281,176.937973 
	C958.975403,174.926788 952.281860,173.241547 946.535583,169.980820 
	C936.703125,164.401459 936.951599,152.058060 946.528198,145.793976 
	C959.395813,137.377213 973.347107,137.739914 987.510376,141.358246 
	C991.622437,142.408783 995.520508,144.302628 999.508789,145.830536 
	C1003.036804,147.182114 1003.560669,149.337830 1001.208923,152.268463 
	C999.673584,154.181671 998.178406,156.127045 996.579590,158.167358 
	C987.055725,152.245621 977.169128,148.639191 966.253540,150.489136 
	C957.717957,151.935730 957.491638,152.516403 954.048035,157.230423 
	C957.165771,161.440521 962.110779,161.752548 966.609619,163.009979 
	C976.090271,165.659760 985.955017,167.415054 994.944214,171.229385 
	C1008.029968,176.782028 1008.620361,191.113998 996.582886,198.848923 
	C987.582092,204.632568 977.356445,205.611771 966.999756,204.781082 
	C962.455627,204.416595 957.997070,202.984207 953.088989,202.008850 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M749.927246,202.428726 
	C742.935608,199.545013 739.996704,194.463379 739.998779,187.460663 
	C740.002991,173.299896 739.992859,159.139114 740.010803,144.978378 
	C740.012695,143.518707 740.164673,142.059219 740.263428,140.309113 
	C759.100159,140.309113 777.673157,140.309113 796.623901,140.309113 
	C796.623901,143.630585 796.623901,146.879150 796.623901,150.814728 
	C782.717590,150.814728 768.677551,150.814728 754.325195,150.814728 
	C754.325195,155.734589 754.325195,159.980469 754.325195,164.740372 
	C767.271301,164.740372 780.186829,164.740372 793.548340,164.740372 
	C793.548340,168.601501 793.548340,171.962341 793.548340,175.842316 
	C780.625549,175.842316 767.708740,175.842316 754.055786,175.842316 
	C754.055786,179.751556 753.591431,183.252304 754.168945,186.571747 
	C754.956177,191.096390 758.189148,193.073486 762.836304,193.029572 
	C772.997803,192.933533 783.160889,192.999954 793.323303,193.000015 
	C794.965332,193.000015 796.607300,193.000000 798.620117,193.000000 
	C798.620117,196.619812 798.620117,199.866043 798.620117,204.585938 
	C782.561523,203.941406 766.411499,203.293228 749.927246,202.428726 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M918.882019,204.000000 
	C907.242004,204.000000 896.085571,203.983170 884.929321,204.004593 
	C873.049011,204.027405 865.422974,197.030563 865.867676,185.133728 
	C866.419861,170.360260 866.000000,155.550491 866.000000,140.379669 
	C884.947815,140.379669 903.514221,140.379669 922.541382,140.379669 
	C922.541382,143.588425 922.541382,146.947632 922.541382,150.817657 
	C908.646729,150.817657 894.735657,150.817657 880.415710,150.817657 
	C880.415710,155.669266 880.415710,160.030273 880.415710,164.900024 
	C893.440369,164.900024 906.351868,164.900024 919.626831,164.900024 
	C919.626831,168.635101 919.626831,171.882553 919.626831,175.809753 
	C906.721008,175.809753 893.684082,175.809753 880.034851,175.809753 
	C880.034851,179.734711 879.655884,183.063095 880.125427,186.267166 
	C880.794739,190.834274 883.857239,193.024506 888.518250,193.009506 
	C898.675415,192.976837 908.832703,192.999786 918.989990,192.999985 
	C920.781128,193.000031 922.572205,193.000000 924.678955,193.000000 
	C924.678955,196.738922 924.678955,199.985245 924.678955,204.000000 
	C922.879333,204.000000 921.122498,204.000000 918.882019,204.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M453.047516,151.000000 
	C449.925354,151.000000 447.296082,151.000000 444.336334,151.000000 
	C444.336334,155.712479 444.336334,159.958435 444.336334,164.893631 
	C457.369690,164.893631 470.412048,164.893631 483.717957,164.893631 
	C483.717957,168.820801 483.717957,172.066727 483.717957,175.824417 
	C470.645111,175.824417 457.728149,175.824417 444.138977,175.824417 
	C444.138977,179.562698 443.801300,182.853500 444.215576,186.046738 
	C444.891754,191.258835 448.614532,192.994217 453.431061,192.997223 
	C463.260925,193.003357 473.090790,192.999664 482.920654,193.000000 
	C484.721985,193.000061 486.523285,193.000000 488.655151,193.000000 
	C488.655151,196.692139 488.655151,199.940582 488.655151,203.661499 
	C487.281586,203.771286 485.852234,203.981522 484.422424,203.984680 
	C472.426727,204.011261 460.430725,204.042145 448.435211,203.987946 
	C436.555664,203.934265 430.009399,197.325287 430.001892,185.488632 
	C429.993591,172.326614 430.000000,159.164581 430.000000,146.002563 
	C430.000000,144.211685 430.000000,142.420792 430.000000,140.317688 
	C449.083496,140.317688 467.657318,140.317688 486.616425,140.317688 
	C486.616425,143.615112 486.616425,146.862793 486.616425,151.000000 
	C475.691254,151.000000 464.615814,151.000000 453.047516,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M538.001526,84.000000 
	C532.207825,84.000000 526.913818,84.000000 520.642761,84.000000 
	C521.160400,88.606766 520.822876,92.966713 522.358032,96.516884 
	C523.285217,98.660973 527.075745,100.703110 529.655579,100.801567 
	C541.459534,101.252029 553.290344,100.999519 565.554810,100.999519 
	C565.554810,104.614975 565.554810,107.976120 565.554810,112.000000 
	C558.585327,112.000000 551.644653,112.000061 544.703979,111.999977 
	C537.705627,111.999901 530.706238,112.078499 523.709290,111.979622 
	C514.908386,111.855255 507.340637,105.385307 507.125977,96.773254 
	C506.727905,80.802406 507.011932,64.814552 507.011932,48.417774 
	C526.335510,48.417774 545.362915,48.417774 564.696594,48.417774 
	C564.696594,51.777241 564.696594,55.024078 564.696594,58.784042 
	C550.352112,58.784042 536.100525,58.784042 521.427612,58.784042 
	C521.427612,63.977005 521.427612,68.675247 521.427612,73.892075 
	C534.430542,73.892075 547.346985,73.892075 560.627563,73.892075 
	C560.627563,77.305420 560.627563,80.222672 560.627563,84.000000 
	C553.340332,84.000000 545.920776,84.000000 538.001526,84.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M801.992188,79.022949 
	C806.628662,70.016754 811.351501,61.460983 815.567444,52.662327 
	C817.244141,49.163074 819.244690,48.178032 823.073547,47.802608 
	C830.212524,47.102627 833.780151,49.638073 836.892517,56.209770 
	C844.888123,73.092461 853.931458,89.478546 862.529846,106.076714 
	C863.412415,107.780495 864.117859,109.576042 865.202026,111.988846 
	C860.061035,111.988846 855.589661,112.242996 851.178833,111.838089 
	C849.808228,111.712265 847.794800,110.009247 847.447815,108.672226 
	C844.731689,98.205429 837.891907,95.462479 827.871887,96.865807 
	C822.482727,97.620560 816.888550,97.221458 811.410645,96.916679 
	C808.151978,96.735382 806.699768,98.121773 805.532837,100.872475 
	C800.300598,113.205963 800.249695,113.184372 785.496948,111.504478 
	C791.066956,100.578033 796.471069,89.977013 801.992188,79.022949 
M816.182068,85.990601 
	C822.539490,85.990601 828.896912,85.990601 835.968567,85.990601 
	C831.966736,77.991943 828.342407,70.747719 824.369873,62.807537 
	C820.931152,70.250015 817.798767,76.945793 814.790283,83.696815 
	C814.543640,84.250343 815.140808,85.179932 816.182068,85.990601 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249176,152.051559 
	C222.527679,151.130707 222.965332,150.142746 223.717834,149.732986 
	C233.106583,144.620377 242.534271,139.578979 251.977570,134.567535 
	C252.666992,134.201675 253.554413,134.208954 254.732513,134.070114 
	C260.956604,146.476974 266.818359,158.846909 272.629791,171.240448 
	C275.127045,176.566132 277.519806,181.940796 280.266357,187.965057 
	C267.253296,187.965057 254.987122,188.182007 242.735672,187.852341 
	C238.189972,187.730011 235.717728,184.213165 234.067825,180.098328 
	C230.330032,170.776535 226.404083,161.530182 222.249176,152.051559 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M410.168518,108.377823 
	C397.826019,113.366814 385.514862,114.858177 373.016113,110.948006 
	C361.745941,107.422195 352.683350,100.889343 349.214142,89.050827 
	C345.713165,77.103668 348.536346,66.224632 357.844238,57.843441 
	C364.460419,51.885967 372.644257,49.078167 381.639923,47.826130 
	C392.446014,46.322105 402.233826,48.472874 411.680328,53.335857 
	C414.130554,54.597202 417.199463,55.619026 414.792572,59.505436 
	C411.103455,65.462135 409.966736,66.087700 405.822968,63.238472 
	C395.723572,56.294247 380.337128,56.311108 370.760590,64.508690 
	C359.051605,74.531677 361.163055,91.195526 373.695068,98.215912 
	C384.575439,104.311050 395.760986,103.701073 406.191681,97.185112 
	C410.427551,94.538979 412.547211,95.153976 414.676117,99.137726 
	C415.512268,100.702354 416.670441,102.094894 417.839661,103.795143 
	C415.268646,105.324974 412.878540,106.747169 410.168518,108.377823 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1108.248779,47.907703 
	C1117.322998,48.369427 1125.053101,51.177189 1132.324341,56.644901 
	C1130.136108,59.635067 1128.141724,62.360214 1126.284180,64.898476 
	C1120.213867,62.643192 1114.677124,60.022541 1108.851318,58.531136 
	C1093.570435,54.619293 1078.670288,66.639374 1079.000000,82.219818 
	C1079.197144,91.530205 1090.314453,101.597961 1100.575317,102.036583 
	C1108.887695,102.391907 1116.725220,101.350525 1123.630249,96.203354 
	C1125.526367,94.789993 1127.400513,94.366776 1128.858887,97.208595 
	C1129.892090,99.221886 1131.572266,100.903130 1133.228882,103.081345 
	C1128.486206,108.065453 1122.339478,110.367256 1116.229126,111.649940 
	C1101.330200,114.777565 1087.216309,113.288956 1074.543579,103.386810 
	C1061.780518,93.413986 1060.548340,72.208092 1070.628662,60.821949 
	C1077.723999,52.807396 1086.732788,48.968822 1097.139404,48.038059 
	C1100.673340,47.721989 1104.251343,47.898724 1108.248779,47.907703 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M416.897949,194.151459 
	C412.723541,197.110397 408.809753,200.585831 404.242462,202.136749 
	C389.622009,207.101410 375.219330,206.165848 362.061981,197.538147 
	C342.006470,184.387131 343.200317,156.265213 364.448395,144.952682 
	C381.961426,135.628693 399.257538,137.382172 416.629913,148.051437 
	C414.379333,151.235168 412.344788,154.113312 410.121002,157.259186 
	C406.737305,155.488037 403.620178,153.698410 400.373840,152.186218 
	C389.818054,147.269272 372.532410,150.571640 366.252197,161.015274 
	C359.805206,171.736252 363.899475,186.048325 375.387238,191.016678 
	C386.028931,195.619125 396.378571,195.371506 405.993927,188.896698 
	C412.906525,184.241852 413.868317,190.814682 416.897949,194.151459 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M307.000000,40.149990 
	C306.999878,45.962025 307.278687,51.316505 306.850189,56.613762 
	C306.697784,58.497776 305.269867,61.039589 303.672150,61.926861 
	C289.213776,69.956123 274.654724,77.811760 259.989502,85.458099 
	C249.797241,90.772270 245.080536,88.695686 240.900955,77.715805 
	C239.518585,74.084290 238.053619,70.484207 236.584457,66.763481 
	C252.718918,64.429695 264.868439,54.659920 278.391510,47.985691 
	C287.861420,43.311874 296.963776,37.893322 307.000000,32.384922 
	C307.000000,35.453835 307.000000,37.561855 307.000000,40.149990 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M714.000000,64.152222 
	C713.889160,62.235676 713.778320,60.800648 713.644348,59.065311 
	C705.799622,59.065311 698.339783,59.065311 690.441589,59.065311 
	C690.441589,55.378567 690.441589,52.018169 690.441589,48.323669 
	C711.061951,48.323669 731.633728,48.323669 752.603638,48.323669 
	C752.603638,51.711815 752.603638,55.071381 752.603638,58.950165 
	C744.846313,58.950165 737.262024,58.950165 729.181519,58.950165 
	C729.181519,76.692543 729.181519,93.937126 729.181519,111.588013 
	C723.990173,111.588013 719.293945,111.588013 714.000000,111.588013 
	C714.000000,95.846260 714.000000,80.239998 714.000000,64.152222 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1016.249634,150.995758 
	C1014.857056,150.144684 1013.281921,149.391510 1013.143066,148.430740 
	C1012.771667,145.861191 1013.016541,143.202591 1013.016541,140.298889 
	C1034.115356,140.298889 1054.688110,140.298889 1075.631104,140.298889 
	C1075.631104,143.644424 1075.631104,146.892899 1075.631104,150.831131 
	C1068.040405,150.831131 1060.326660,150.831131 1052.124268,150.831131 
	C1052.124268,168.750534 1052.124268,185.995865 1052.124268,203.617401 
	C1047.049194,203.617401 1042.470581,203.617401 1037.201782,203.617401 
	C1037.201782,186.390259 1037.201782,169.023865 1037.201782,150.999008 
	C1029.931152,150.999008 1023.326477,150.999008 1016.249634,150.995758 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M621.637756,203.852997 
	C617.373779,203.950455 613.566467,203.950455 609.255615,203.950455 
	C609.255615,186.385635 609.255615,169.138718 609.255615,151.207718 
	C601.074463,151.207718 593.359985,151.207718 585.332764,151.207718 
	C585.332764,147.283676 585.332764,144.035690 585.332764,140.391663 
	C605.709717,140.391663 626.069702,140.391663 646.715820,140.391663 
	C646.715820,143.816238 646.715820,147.062119 646.715820,150.820480 
	C638.983032,150.820480 631.397278,150.820480 623.260742,150.820480 
	C623.159424,153.055740 623.013000,154.813278 623.010620,156.570999 
	C622.991638,170.565308 623.031372,184.559799 622.960022,198.553787 
	C622.951172,200.289124 622.396301,202.021667 621.637756,203.852997 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1247.000000,60.016029 
	C1247.000000,73.781784 1247.000000,87.051079 1247.000000,100.803482 
	C1260.013062,100.803482 1272.578857,100.803482 1285.569092,100.803482 
	C1285.569092,104.639030 1285.569092,107.995415 1285.569092,111.680847 
	C1267.947998,111.680847 1250.394653,111.680847 1232.419678,111.680847 
	C1232.419678,90.698601 1232.419678,69.700356 1232.419678,48.349667 
	C1237.011841,48.349667 1241.583496,48.349667 1247.000000,48.349667 
	C1247.000000,52.033951 1247.000000,55.776756 1247.000000,60.016029 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1040.118530,111.999939 
	C1038.338257,111.929276 1037.033081,111.858688 1035.369629,111.768723 
	C1035.369629,90.591743 1035.369629,69.589920 1035.369629,48.293259 
	C1040.128906,48.293259 1044.701660,48.293259 1049.639160,48.293259 
	C1049.639160,69.425079 1049.639160,90.427299 1049.639160,112.000000 
	C1046.485718,112.000000 1043.539673,112.000000 1040.118530,111.999939 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,53.150448 
	C678.000000,72.884499 678.000000,92.134758 678.000000,111.690292 
	C673.239014,111.690292 668.998047,111.690292 664.377014,111.690292 
	C664.377014,90.611465 664.377014,69.610931 664.377014,48.304283 
	C668.772278,48.304283 673.013062,48.304283 677.662903,48.304283 
	C677.778442,49.799774 677.889221,51.233215 678.000000,53.150448 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M833.999146,178.000000 
	C826.557068,178.000000 819.614624,178.000000 812.340576,178.000000 
	C812.340576,173.973373 812.340576,170.399368 812.340576,166.410660 
	C825.312622,166.410660 838.313232,166.410660 851.658936,166.410660 
	C851.658936,170.025711 851.658936,173.599731 851.658936,178.000000 
	C845.972900,178.000000 840.235779,178.000000 833.999146,178.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M684.056335,151.000000 
	C689.482910,151.312027 694.470154,151.296494 699.349976,152.013123 
	C706.612549,153.079681 709.136353,155.837494 708.999451,161.504913 
	C708.886292,166.193726 704.237976,170.562180 698.038574,170.892471 
	C690.935669,171.270920 683.797058,170.980209 676.340637,170.980209 
	C676.340637,164.301804 676.340637,158.060516 676.340637,151.000000 
	C678.736450,151.000000 681.152710,151.000000 684.056335,151.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M472.478607,61.214386 
	C477.451263,63.344475 479.049530,67.290154 477.770691,71.562439 
	C476.479095,75.877319 472.687683,78.677307 468.087158,78.881653 
	C460.308502,79.227180 452.503326,78.976501 444.358215,78.976501 
	C444.358215,72.338165 444.358215,66.096390 444.358215,59.137848 
	C453.626190,58.992062 462.947601,58.267956 472.478607,61.214386 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M992.592285,78.999008 
	C985.010681,78.999809 977.884827,78.999809 970.382019,78.999809 
	C970.382019,72.269066 970.382019,65.916039 970.382019,58.560055 
	C979.158813,59.109737 987.728455,59.256733 996.183716,60.316261 
	C1001.606018,60.995724 1004.279297,65.125397 1003.997803,70.179947 
	C1003.756897,74.506004 999.540039,77.902016 992.592285,78.999008 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M596.367432,59.145889 
	C604.684326,59.693611 612.817688,59.277718 620.340942,61.222141 
	C632.774719,64.435677 638.497559,76.471329 633.822510,88.012924 
	C630.332886,96.628006 622.498840,99.793686 614.058533,100.833954 
	C608.032959,101.576607 601.842224,100.978188 595.000000,100.978188 
	C595.000000,92.529076 594.887695,84.455124 595.038147,76.386070 
	C595.144775,70.668205 595.606567,64.956955 596.367432,59.145889 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1200.860352,69.348312 
	C1208.453491,87.256332 1198.455566,101.828064 1179.165527,102.072433 
	C1169.566528,102.194031 1161.922363,98.754219 1156.994019,90.677063 
	C1149.976440,79.176033 1154.933350,65.860954 1167.660400,60.676239 
	C1180.408813,55.482796 1194.264404,59.006248 1200.860352,69.348312 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M815.763977,85.962708 
	C815.140808,85.179932 814.543640,84.250343 814.790283,83.696815 
	C817.798767,76.945793 820.931152,70.250015 824.369873,62.807537 
	C828.342407,70.747719 831.966736,77.991943 835.968567,85.990601 
	C828.896912,85.990601 822.539490,85.990601 815.763977,85.962708 
z"
      />
    </svg>
  );
};

CaCentreEstSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaCentreEstSvg;
