import React from 'react';
import PropTypes from 'prop-types';
import IconQuestionMarkSvg from './svg/icon-question-mark-svg';
import './styles.scss';

const IconQuestionMark = props => {
  const { title, alt, width, height, fill } = props;

  return (
    <div className={fill ? 'question__mark__login' : 'question__mark'}>
      <IconQuestionMarkSvg
        className={fill ? 'question__mark__login__icon' : 'question__mark__icon'}
        title={title}
        alt={alt}
        width={width}
        height={height}
        fill={fill}
      />
    </div>
  );
};

IconQuestionMark.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.bool,
};

export default IconQuestionMark;
