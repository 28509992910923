import * as React from 'react';
import PropTypes from 'prop-types';

const CaNordDeFranceSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1399 255"
      enableBackground="new 0 0 1399 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1054.000000,256.000000 
	C702.666748,256.000000 351.833557,256.000000 1.000235,256.000000 
	C1.000157,171.000137 1.000157,86.000259 1.000078,1.000292 
	C467.333038,1.000195 933.666077,1.000195 1399.999268,1.000098 
	C1399.999512,85.999702 1399.999512,170.999405 1399.999756,255.999557 
	C1284.833374,256.000000 1169.666626,256.000000 1054.000000,256.000000 
M201.785263,123.692657 
	C184.226273,131.817581 166.855164,140.384338 149.059036,147.951553 
	C128.495392,156.695541 106.662697,160.518631 84.448975,161.926239 
	C72.811668,162.663666 61.237843,162.186890 50.539387,156.482040 
	C45.417500,153.750824 42.577095,149.193039 44.208321,143.927246 
	C45.951073,138.301437 47.902870,132.479767 51.083149,127.604492 
	C65.766319,105.095619 86.471924,90.181633 111.946503,82.166428 
	C122.582428,78.819984 133.674942,76.924690 144.914917,74.286240 
	C143.202271,68.861664 141.743744,63.290009 139.689209,57.947552 
	C136.212082,48.905891 128.307083,44.572556 119.033615,46.780533 
	C109.914444,48.951778 100.631805,50.913567 91.935646,54.292175 
	C68.662346,63.334255 48.670071,77.038651 33.856907,97.634850 
	C24.130125,111.158936 19.459957,126.138222 22.280209,142.540894 
	C25.348690,160.387207 35.657944,173.401459 51.776737,181.972351 
	C72.189529,192.826523 93.917099,192.368561 115.797554,189.633881 
	C135.358139,187.189163 153.283524,179.344788 171.135590,171.401810 
	C185.300308,165.099442 199.641388,159.193451 214.084610,153.808075 
	C217.032227,160.806168 220.681915,167.602249 222.767197,174.848495 
	C226.108978,186.461044 233.103897,190.507385 244.876083,189.180542 
	C253.154419,188.247498 261.622742,189.000244 270.289703,189.000244 
	C269.997162,187.299194 270.041718,186.229080 269.632721,185.376526 
	C261.712341,168.867020 253.738983,152.382950 246.114960,135.284317 
	C246.635773,134.875732 247.120621,134.407806 247.682632,134.067230 
	C262.091919,125.335007 276.548370,116.679497 290.885010,107.829811 
	C293.161316,106.424713 296.500671,104.254906 296.623688,102.269829 
	C297.222229,92.609291 296.894257,82.891365 296.894257,72.604065 
	C294.331421,73.900574 292.573669,74.750267 290.851593,75.667053 
	C271.588135,85.922623 252.330185,96.188538 232.563583,105.883110 
	C224.956879,88.768295 217.471115,71.597328 209.585495,54.612000 
	C208.634399,52.563374 205.576355,50.317123 203.398743,50.215157 
	C193.423630,49.748070 183.398224,49.703060 173.428131,50.222019 
	C170.817368,50.357914 167.329071,52.450790 165.979416,54.690716 
	C160.594070,63.628422 155.895309,72.979721 150.938736,82.175934 
	C142.046799,98.673615 133.105850,115.145119 124.283836,131.680099 
	C121.196518,137.466614 118.399239,143.407867 114.855461,150.508896 
	C121.738823,149.534683 127.331284,149.070709 132.775436,147.910721 
	C148.593689,144.540329 161.625809,137.546478 167.918732,121.261566 
	C171.617783,111.689079 176.203476,102.455971 180.485168,93.113670 
	C181.964294,89.886337 183.729782,86.790245 185.689743,83.006195 
	C186.410217,84.329193 186.761292,84.845528 186.989349,85.411430 
	C192.048111,97.964439 197.093277,110.522926 201.785263,123.692657 
M274.267731,205.003754 
	C193.317932,205.002563 112.368126,205.001312 31.418324,205.000610 
	C30.252392,205.000595 29.076406,205.107681 27.922459,204.991898 
	C24.782961,204.676910 23.899513,206.214890 23.967966,209.122833 
	C24.106499,215.008057 24.009298,220.898834 24.009298,227.007950 
	C24.733656,227.007950 25.551035,227.007996 26.368412,227.007965 
	C108.484238,227.005096 190.600052,227.002243 272.715881,226.998871 
	C273.548676,226.998840 274.387177,226.926895 275.213257,226.999481 
	C277.984741,227.243027 279.101257,226.045807 279.023773,223.242493 
	C278.890381,218.415878 278.889954,213.580215 279.023651,208.753616 
	C279.101471,205.945023 277.970734,204.740967 274.267731,205.003754 
M1172.070435,164.547348 
	C1182.201050,176.351837 1192.697021,187.869659 1202.290649,200.095459 
	C1206.388916,205.318161 1210.808105,206.335083 1216.767334,206.143372 
	C1220.961792,206.008423 1222.121826,204.682770 1222.075806,200.628326 
	C1221.888306,184.147858 1222.101685,167.663132 1221.964844,151.181671 
	C1221.869019,139.634628 1224.050781,141.226151 1211.583374,140.954132 
	C1208.144897,140.879089 1206.895630,142.026459 1206.946655,145.529907 
	C1207.109009,156.682404 1207.013916,167.838821 1206.987183,178.993652 
	C1206.983276,180.627502 1206.808716,182.260941 1206.644043,185.075424 
	C1196.811157,173.486465 1187.950562,163.019196 1179.061523,152.576157 
	C1173.827759,146.427490 1169.670288,138.934280 1159.264526,140.792633 
	C1155.356567,141.490555 1153.874146,142.179916 1153.922852,146.312424 
	C1154.118774,162.959198 1153.920410,179.610199 1154.029663,196.258575 
	C1154.101929,207.272217 1152.286377,205.694458 1163.860229,206.078674 
	C1168.229858,206.223724 1169.139160,204.451309 1169.068604,200.522110 
	C1168.864990,189.204391 1168.986938,177.880600 1169.013062,166.559265 
	C1169.016479,165.071167 1169.206543,163.583496 1169.394165,160.888321 
	C1170.701294,162.735367 1171.163452,163.388565 1172.070435,164.547348 
M357.805481,141.043900 
	C355.474182,141.029770 353.117157,141.230774 350.816986,140.956085 
	C346.237122,140.409195 344.831970,142.201828 344.902344,146.797806 
	C345.152130,163.114731 344.897736,179.438644 345.032959,195.758270 
	C345.129242,207.381912 342.971832,205.729980 355.358032,206.063110 
	C359.275452,206.168488 360.105652,204.540649 360.057190,201.029465 
	C359.898621,189.540527 359.988983,178.047974 360.016968,166.556900 
	C360.020233,165.221741 360.232269,163.887054 360.440552,161.474518 
	C372.718445,175.394028 384.599884,188.130173 395.517029,201.645248 
	C399.259216,206.277985 403.212311,205.711212 407.736359,206.059082 
	C411.953949,206.383392 413.123718,204.726746 413.076477,200.646088 
	C412.885681,184.161011 413.102203,167.671570 412.964722,151.185425 
	C412.868408,139.635376 415.055115,141.226730 402.581360,140.954254 
	C399.137634,140.879013 397.896118,142.034103 397.946960,145.534515 
	C398.108856,156.690186 398.014648,167.849777 397.986664,179.007782 
	C397.982513,180.656113 397.801514,182.304031 397.631470,185.129868 
	C388.376221,174.178787 380.174835,164.253082 371.711456,154.555984 
	C367.608765,149.855194 363.015045,145.582977 357.805481,141.043900 
M608.874756,141.053268 
	C607.930298,142.120499 606.171448,143.180710 606.162231,144.255997 
	C605.995728,163.580948 605.983459,182.908295 606.173950,202.232803 
	C606.186218,203.473373 608.113586,205.761902 609.155457,205.765015 
	C624.071716,205.809753 639.148499,207.504868 653.793091,203.510422 
	C668.311584,199.550385 678.763306,187.240967 678.954651,174.300095 
	C679.237793,155.153336 664.268066,141.947845 645.792725,141.108337 
	C633.821777,140.564377 621.805725,141.013596 608.874756,141.053268 
M778.884033,147.510330 
	C772.705139,145.343506 766.639709,141.832016 760.322998,141.299316 
	C748.423767,140.295837 736.379150,141.079132 724.396484,140.961990 
	C721.261658,140.931351 719.937012,141.874054 719.956299,145.208878 
	C720.064453,163.849731 720.064758,182.491882 719.957520,201.132751 
	C719.936096,204.858871 721.456360,206.098221 725.033508,206.038513 
	C733.686035,205.894089 742.386414,206.528229 750.984070,205.826294 
	C758.141602,205.241913 765.432861,203.947678 772.216797,201.646835 
	C784.575134,197.455414 793.545288,184.423248 792.703735,172.283218 
	C792.016174,162.365555 788.230591,153.694321 778.884033,147.510330 
M1047.594971,167.780518 
	C1051.688477,154.398788 1043.253418,141.651260 1029.275513,141.140671 
	C1015.130371,140.623978 1000.951782,141.059372 986.788269,140.984299 
	C983.996948,140.969513 982.975342,142.080032 982.984497,144.832184 
	C983.047180,163.661636 982.956055,182.492081 983.168457,201.319351 
	C983.182312,202.547913 985.193237,204.674789 986.459778,204.824692 
	C997.990723,206.189148 998.002625,206.087845 998.002991,194.446381 
	C998.002991,193.946472 998.002930,193.446579 998.002991,192.946671 
	C998.003601,182.988022 998.003662,182.990952 1008.170898,182.996902 
	C1011.639709,182.998932 1014.959900,182.671326 1017.576477,186.097839 
	C1022.090698,192.009445 1027.055420,197.579117 1031.894653,203.237915 
	C1032.503906,203.950378 1033.484741,204.774765 1034.314819,204.798523 
	C1039.363281,204.943039 1044.418091,204.870621 1050.878662,204.870621 
	C1043.392944,196.118912 1036.919556,188.550690 1030.228027,180.727478 
	C1040.507568,177.537521 1043.968994,174.954727 1047.594971,167.780518 
M584.481201,146.989365 
	C579.682129,143.169144 574.403259,140.878281 568.084839,140.956085 
	C555.774109,141.107727 543.455444,141.198044 531.150024,140.900558 
	C526.455505,140.787064 524.797974,142.006317 524.889465,146.957291 
	C525.187622,163.091293 524.894958,179.235184 525.037415,195.373428 
	C525.130920,205.963943 522.834351,204.886475 534.964783,205.026520 
	C537.994080,205.061493 539.116455,203.909653 539.026123,200.922897 
	C538.885376,196.268661 538.926575,191.604095 539.069275,186.949417 
	C539.106140,185.747391 539.447937,184.095337 540.272888,183.462402 
	C542.810303,181.515594 556.179077,183.487473 558.396179,185.894409 
	C562.213501,190.038513 566.101990,194.121750 569.798279,198.371719 
	C575.662415,205.114212 582.792725,207.037766 591.834473,203.869492 
	C585.219543,196.221481 578.690674,188.673035 572.057922,181.004395 
	C591.103516,173.841095 596.448914,159.552048 584.481201,146.989365 
M509.979492,169.831329 
	C509.651794,168.215744 509.360962,166.591660 508.990570,164.985931 
	C506.341064,153.499680 490.554932,139.139954 478.394836,140.828415 
	C471.536652,141.780716 464.419006,140.694275 457.455353,141.125778 
	C453.775696,141.353790 449.879120,142.273438 446.597321,143.914505 
	C438.002838,148.212265 432.096802,155.007050 428.994568,164.323715 
	C423.601471,180.520386 435.557983,200.693756 453.983582,204.805511 
	C460.667328,206.296997 467.821991,205.824814 474.767548,205.968002 
	C492.626221,206.336227 511.147064,190.753601 509.979492,169.831329 
M993.318298,89.125954 
	C994.814331,88.411758 996.383667,87.818321 997.794250,86.963165 
	C1005.264832,82.434410 1011.114746,76.798775 1009.895996,67.060425 
	C1008.895447,59.066120 1001.975464,52.109161 991.927612,51.051525 
	C978.591919,49.647823 965.106079,49.714161 951.696350,48.946045 
	C947.216431,48.689430 945.966492,50.518288 946.013855,54.770668 
	C946.191833,70.760841 946.083374,86.754189 946.083130,102.746346 
	C946.083008,113.693085 946.079041,113.607002 957.230591,113.006958 
	C959.990051,112.858475 961.096924,111.947502 961.027222,109.165070 
	C960.906250,104.337425 960.889587,99.498772 961.104431,94.677002 
	C961.158142,93.471619 962.188660,91.338402 962.892883,91.286186 
	C968.818054,90.846794 974.962158,89.912376 979.641357,95.137871 
	C984.070801,100.084328 988.499512,105.034843 993.069580,109.849602 
	C994.252075,111.095367 995.902405,112.544693 997.444031,112.678772 
	C1001.835449,113.060722 1006.281677,112.812889 1011.305237,112.812889 
	C1010.666199,111.653793 1010.447144,111.063843 1010.070679,110.602234 
	C1004.328613,103.560555 998.565857,96.535706 993.318298,89.125954 
M481.985809,107.484253 
	C476.959473,101.664169 471.933136,95.844078 466.685577,89.767822 
	C469.263275,88.431534 471.127838,87.350830 473.082794,86.471016 
	C481.978882,82.467422 484.584534,75.835762 483.884247,67.168983 
	C483.287781,59.787785 477.191833,53.915993 468.882080,51.993511 
	C454.853638,48.748001 440.590271,48.982800 426.323181,48.905884 
	C422.146912,48.883369 420.932892,50.204498 420.982056,54.308933 
	C421.179321,70.783875 421.061310,87.262581 421.061676,103.739891 
	C421.061890,113.317650 421.060028,113.188690 430.771454,112.934204 
	C434.042633,112.848480 435.170807,111.707176 435.045380,108.507652 
	C434.863007,103.854965 434.919739,99.185684 435.092194,94.531555 
	C435.133545,93.415596 435.742676,91.747520 436.585419,91.328545 
	C441.611755,88.829720 451.844696,91.778717 455.327789,96.428139 
	C457.016052,98.681778 459.069031,100.692581 460.546204,103.068642 
	C466.388885,112.466621 474.598114,115.160324 485.588776,112.084373 
	C484.413940,110.536972 483.445435,109.261330 481.985809,107.484253 
M572.054443,61.533108 
	C572.056641,77.177048 572.136841,92.821671 572.000549,108.464401 
	C571.970398,111.927597 573.098938,113.170319 576.588928,113.058701 
	C583.239197,112.846024 589.920166,113.308083 596.552612,112.903152 
	C603.634399,112.470802 610.839905,112.047493 617.698181,110.399857 
	C631.021912,107.198952 640.741638,95.611465 640.998535,83.889763 
	C641.365417,67.151505 634.769592,58.020794 618.785095,52.849895 
	C607.398315,49.166325 595.681091,48.822037 583.882568,48.993698 
	C570.209778,49.192631 572.250916,47.512505 572.054443,61.533108 
M1182.240601,50.025749 
	C1168.280884,48.243698 1154.745605,47.364262 1142.354248,56.717014 
	C1124.077759,70.511848 1125.277832,95.990135 1145.012329,107.581398 
	C1159.690186,116.202553 1174.753662,115.865143 1189.867920,109.893387 
	C1205.971802,103.530647 1213.811768,84.885033 1207.254517,69.815338 
	C1202.615723,59.154331 1193.986328,53.190987 1182.240601,50.025749 
M1320.420288,142.047195 
	C1320.298462,157.019730 1319.578369,172.018707 1320.237915,186.956726 
	C1320.799194,199.665115 1327.338867,205.647232 1339.273560,205.920883 
	C1351.757324,206.207108 1364.255493,205.833588 1376.741699,206.060074 
	C1382.105957,206.157379 1380.834595,202.591827 1380.991821,199.644531 
	C1381.161011,196.471176 1381.240967,193.795151 1376.533691,193.917313 
	C1365.716064,194.198029 1354.886108,194.018967 1344.061157,193.991119 
	C1337.311646,193.973755 1335.915649,192.741837 1335.082275,186.161621 
	C1334.957520,185.175903 1335.130127,184.150223 1334.984741,183.169830 
	C1334.291992,178.499512 1335.953857,176.710083 1340.940918,176.890472 
	C1351.086304,177.257446 1361.260132,176.795105 1371.410645,177.083679 
	C1375.968140,177.213226 1376.034180,174.784454 1376.050903,171.478592 
	C1376.067871,168.120178 1375.868530,165.770264 1371.365967,165.917160 
	C1362.216187,166.215622 1353.049072,166.065186 1343.890259,165.983490 
	C1333.903442,165.894409 1335.321899,167.328949 1334.910522,156.787567 
	C1334.729858,152.158051 1336.277100,150.800079 1340.829468,150.906235 
	C1352.646973,151.181793 1364.476196,150.915405 1376.299316,151.024902 
	C1379.657471,151.056015 1380.257568,149.243668 1379.966309,146.597961 
	C1379.723145,144.389145 1381.157227,141.052536 1376.990356,141.067963 
	C1358.352539,141.136917 1339.715088,141.286789 1320.420288,142.047195 
M848.498230,193.998642 
	C841.668457,193.997086 834.838074,194.050217 828.009094,193.977203 
	C822.235718,193.915466 820.174744,191.861298 820.014282,186.235352 
	C819.971558,184.737350 820.151062,183.220306 819.978516,181.741180 
	C819.535461,177.942215 821.248352,176.875565 824.876099,176.938660 
	C835.367371,177.121109 845.869202,176.802826 856.356079,177.084427 
	C860.845581,177.205002 861.074890,174.901245 861.051025,171.530350 
	C861.027649,168.231537 860.983948,165.760620 856.414978,165.918243 
	C847.596863,166.222504 838.759277,166.098633 829.932190,165.976364 
	C818.697144,165.820724 820.251038,167.915680 819.943420,155.823532 
	C819.849304,152.125320 821.068054,150.878387 824.794678,150.935013 
	C836.785583,151.117203 848.782837,150.877731 860.773865,151.056137 
	C864.619812,151.113342 865.133545,149.224792 865.039612,146.121063 
	C864.953918,143.292267 865.125916,140.916290 861.019165,140.951385 
	C843.696167,141.099457 826.370972,141.058548 809.047180,140.970917 
	C806.028748,140.955658 804.946777,142.063873 804.968506,145.064377 
	C805.067200,158.723175 804.317810,172.429367 805.219482,186.029266 
	C806.087891,199.127884 809.223938,204.928711 824.311035,205.832809 
	C836.759888,206.578796 849.291382,205.840057 861.781128,206.056000 
	C867.177734,206.149292 865.803955,202.518387 865.990417,199.601746 
	C866.196228,196.383011 866.151672,193.561768 861.485474,193.939743 
	C857.513855,194.261475 853.495117,194.001343 848.498230,193.998642 
M873.148743,107.445740 
	C890.601562,116.819824 908.096558,116.489372 925.312927,107.088509 
	C927.804871,105.727798 930.338196,102.375412 930.721130,99.645699 
	C931.545654,93.767937 930.894470,87.693611 931.026245,81.703674 
	C931.088989,78.853073 929.887146,77.942719 927.166138,77.973236 
	C918.344482,78.072189 909.519531,78.112534 900.699097,77.970718 
	C896.732361,77.906937 897.119873,80.484886 897.121765,82.997513 
	C897.123657,85.505768 896.721497,88.184395 900.694641,88.020569 
	C904.516418,87.862976 908.358643,87.867409 912.174622,88.092430 
	C913.421387,88.165932 915.327942,88.853920 915.716919,89.774582 
	C918.462402,96.272186 915.104553,101.651680 908.152588,101.941216 
	C902.901062,102.159943 897.613403,102.296608 892.376953,101.945663 
	C877.049744,100.918449 870.504517,85.574051 875.304993,73.522804 
	C879.229797,63.669731 887.755737,59.051823 897.798950,59.299957 
	C904.334778,59.461433 910.809937,62.404507 917.294373,64.167488 
	C920.028992,64.910973 925.363037,62.313919 925.587891,59.277710 
	C925.699463,57.770164 923.563110,55.437885 921.879639,54.558575 
	C906.760559,46.661232 891.372986,46.461231 876.083435,54.005352 
	C854.553223,64.628685 852.697083,93.477348 873.148743,107.445740 
M1061.506470,201.998138 
	C1061.153931,203.145523 1060.801270,204.292923 1060.423462,205.522659 
	C1074.278198,207.261963 1075.250610,206.700287 1080.328369,194.411484 
	C1080.391846,194.258026 1080.497437,194.120255 1080.548462,193.963730 
	C1081.481445,191.102493 1083.224854,189.796204 1086.449707,189.933289 
	C1092.266846,190.180603 1098.102051,189.998932 1103.929810,189.998611 
	C1117.624390,189.997864 1117.592407,190.015503 1124.158691,202.279022 
	C1124.888184,203.641510 1126.161011,205.599091 1127.312988,205.694366 
	C1132.018921,206.083557 1136.775879,205.856628 1142.292847,205.856628 
	C1141.464111,203.625275 1141.102295,202.221466 1140.452759,200.966507 
	C1131.639648,183.939407 1122.416748,167.110886 1114.111450,149.840622 
	C1110.388672,142.099457 1105.192139,139.434937 1097.335693,141.102615 
	C1095.404297,141.512589 1092.904419,142.678238 1092.058838,144.247192 
	C1081.845337,163.198761 1071.893433,182.291336 1061.506470,201.998138 
M1309.556519,84.961761 
	C1317.721069,84.961044 1325.890015,84.800598 1334.048340,85.026436 
	C1338.180542,85.140816 1340.028809,84.079567 1340.069702,79.486359 
	C1340.111328,74.808846 1337.562256,74.941574 1334.351562,74.970917 
	C1326.187744,75.045532 1318.021118,75.096909 1309.858521,74.969948 
	C1298.552368,74.794098 1300.289429,76.985138 1299.943726,64.806099 
	C1299.838989,61.113644 1300.996582,59.864689 1304.752075,59.930546 
	C1316.245361,60.132099 1327.746582,59.880104 1339.240723,60.056633 
	C1343.275513,60.118591 1343.881470,58.077778 1343.900513,54.773182 
	C1343.920288,51.357346 1343.525391,49.027225 1339.143066,49.070293 
	C1322.815552,49.230747 1306.485352,49.172955 1290.156494,49.101856 
	C1287.281372,49.089336 1285.933105,49.926121 1285.957764,53.075108 
	C1286.071289,67.570435 1285.845093,82.069778 1286.075562,96.562363 
	C1286.229126,106.221695 1290.909546,111.344711 1300.715454,111.916168 
	C1313.815796,112.679626 1326.966797,112.531609 1340.083130,113.082985 
	C1344.440186,113.266144 1344.935669,111.209641 1344.950439,107.691208 
	C1344.965698,104.066879 1344.682373,101.739594 1339.964966,101.897514 
	C1330.145386,102.226250 1320.305054,102.100624 1310.476929,101.938156 
	C1305.972534,101.863693 1302.100952,100.769249 1300.799438,95.319160 
	C1298.535278,85.838799 1299.022705,84.970291 1309.556519,84.961761 
M532.500183,59.992680 
	C538.495178,59.990437 544.500366,59.775166 550.481689,60.059738 
	C554.569153,60.254208 555.220764,58.251549 555.026733,54.899654 
	C554.854431,51.925121 555.618164,48.884258 550.752197,48.940063 
	C534.434631,49.127213 518.113403,49.048660 501.794067,48.974281 
	C499.034119,48.961704 497.958160,49.938721 497.974213,52.746159 
	C498.058899,67.566582 497.765900,82.393677 498.114807,97.206429 
	C498.337311,106.653198 504.534210,112.680641 514.014465,112.906830 
	C526.829773,113.212585 539.659119,112.879768 552.479614,113.044144 
	C557.161011,113.104179 556.771484,110.156914 556.924133,107.063293 
	C557.089905,103.702721 556.437439,101.812279 552.360657,101.921989 
	C542.875488,102.177246 533.378723,102.002129 523.886658,101.998047 
	C513.901001,101.993759 512.083740,99.946091 511.900330,90.112450 
	C511.819000,85.751076 513.476685,84.830902 517.424988,84.922775 
	C527.244751,85.151245 537.076843,84.841400 546.896973,85.061264 
	C550.884766,85.150536 551.094055,83.048126 551.037781,80.064018 
	C550.984436,77.237762 551.176270,74.821510 547.035156,74.939552 
	C538.882019,75.171974 530.717407,75.000351 522.557556,74.999046 
	C511.111145,74.997215 511.184998,75.003456 511.963348,63.522686 
	C512.134399,60.999443 512.945312,59.938065 515.514526,59.983578 
	C520.841553,60.077953 526.171631,59.999859 532.500183,59.992680 
M785.904724,93.371536 
	C782.747314,99.654144 779.589905,105.936745 776.296570,112.489822 
	C787.379150,113.783691 793.448547,113.585205 796.349365,102.186653 
	C796.812317,100.367653 800.444275,98.425842 802.775757,98.193695 
	C808.710266,97.602806 814.739502,98.021072 820.728149,97.902252 
	C831.496582,97.688606 833.851013,99.308517 838.078430,109.104301 
	C838.778198,110.725754 840.875427,112.573997 842.527100,112.790604 
	C846.584473,113.322739 850.758484,112.964668 855.623047,112.964668 
	C854.720154,110.866539 854.168701,109.360245 853.446472,107.940857 
	C844.876526,91.099007 836.288757,74.266197 827.677490,57.445435 
	C827.000122,56.122280 826.140686,54.888161 825.318298,53.644066 
	C820.596191,46.499969 810.221191,46.902245 806.283386,54.385685 
	C799.570007,67.143959 792.870972,79.909805 785.904724,93.371536 
M1272.600098,194.070328 
	C1265.931641,192.618134 1259.668945,190.132965 1256.107178,183.991638 
	C1247.170044,168.581787 1257.456909,151.663086 1274.864380,150.001328 
	C1284.132446,149.116608 1292.623779,151.116547 1300.241455,157.271500 
	C1302.268921,154.786972 1304.249634,152.771896 1305.656616,150.414459 
	C1306.155151,149.579086 1305.624756,147.054947 1304.936157,146.758072 
	C1300.000000,144.629929 1295.027954,141.928696 1289.824463,141.237335 
	C1282.786621,140.302261 1275.486938,140.512955 1268.384888,141.164108 
	C1263.260864,141.633896 1257.739502,142.653183 1253.320068,145.098587 
	C1231.833008,156.988022 1232.142700,181.941818 1247.792603,195.010651 
	C1261.332397,206.317429 1277.336548,207.571014 1293.815063,202.791473 
	C1298.850342,201.331039 1303.458984,198.399384 1309.023804,195.783859 
	C1305.862549,192.039932 1303.682007,189.457443 1301.538940,186.919418 
	C1292.501221,192.473022 1283.821655,196.759094 1272.600098,194.070328 
M1097.318604,102.997772 
	C1092.594727,102.310036 1087.687744,102.209541 1083.186646,100.806824 
	C1074.950684,98.240143 1067.900757,87.363037 1069.144897,79.993927 
	C1070.894409,69.631607 1077.114014,63.153004 1087.033447,60.129196 
	C1095.313477,57.605160 1103.064941,59.399158 1110.554810,63.205326 
	C1116.631958,66.293579 1117.272461,66.098564 1120.825073,60.415340 
	C1121.422607,59.459450 1121.861450,58.404411 1122.365723,57.410786 
	C1105.728882,46.048943 1084.042847,44.472221 1066.096558,57.297855 
	C1050.463989,68.469986 1050.339478,93.554718 1065.784912,104.960724 
	C1075.520142,112.149948 1086.376953,114.929321 1098.111206,113.859894 
	C1107.185547,113.032890 1115.940796,110.568390 1123.181885,104.497314 
	C1118.293701,95.439636 1118.377075,95.621567 1109.541382,99.673248 
	C1106.005737,101.294502 1102.011963,101.916809 1097.318604,102.997772 
M405.563568,106.146812 
	C406.109924,105.042107 407.415405,103.456413 407.072449,102.915184 
	C405.508484,100.446892 403.481506,98.271988 401.512360,95.858551 
	C400.143372,96.707703 399.209320,97.367516 398.206451,97.896889 
	C386.511139,104.070389 374.746460,106.146942 363.020142,98.069466 
	C350.651215,89.549332 350.837280,72.581200 363.189911,64.185287 
	C373.115082,57.439308 383.333923,57.592735 393.859985,62.381725 
	C402.030334,66.098969 403.421509,64.702507 406.562836,57.497414 
	C393.170563,46.361290 368.531921,45.287067 353.332397,55.176624 
	C336.422211,66.179207 334.059357,88.484894 348.288696,102.789818 
	C361.764709,116.337402 387.756927,118.074623 405.563568,106.146812 
M914.737549,141.005417 
	C910.952087,140.482132 909.902832,142.246399 909.936340,145.875244 
	C910.085815,162.035156 909.998596,178.197250 909.997620,194.358566 
	C909.996948,205.990417 910.008606,206.091751 921.449768,204.832581 
	C922.734924,204.691132 924.700439,202.684418 924.815674,201.404572 
	C925.232178,196.776245 925.284363,192.066193 924.921936,187.430161 
	C924.541382,182.560959 925.897705,180.631119 931.199097,180.874390 
	C940.842163,181.316895 950.522949,180.862823 960.182251,181.062759 
	C963.874023,181.139175 965.138916,179.944992 965.098633,176.207520 
	C965.058899,172.523239 964.260010,170.810913 960.098877,170.918289 
	C949.942505,171.180344 939.773010,170.896469 929.612122,171.050125 
	C926.205505,171.101624 924.743896,170.026932 924.959717,166.489975 
	C925.182495,162.838135 925.183228,159.151474 924.959717,155.499725 
	C924.742615,151.951843 926.228882,150.908981 929.622314,150.950394 
	C941.283325,151.092728 952.949829,150.858475 964.608521,151.074432 
	C968.639648,151.149109 970.116333,150.037994 970.137512,145.783401 
	C970.159058,141.457321 968.199463,140.931641 964.674866,140.955078 
	C948.347656,141.063721 932.019348,141.002350 914.737549,141.005417 
M297.003113,54.293976 
	C297.003113,47.737392 297.003113,41.180805 297.003113,34.409061 
	C273.146790,44.803791 252.948257,61.099224 227.400177,68.396553 
	C229.849365,73.977814 232.003876,79.480598 234.654953,84.732735 
	C236.616516,88.618935 240.314438,89.780357 244.441025,88.797104 
	C246.988922,88.190010 249.548935,87.310974 251.862091,86.094856 
	C265.050476,79.161194 278.133942,72.027779 291.325958,65.101135 
	C295.439789,62.941120 297.813751,60.074558 297.003113,54.293976 
M692.513550,60.006973 
	C694.511169,60.007107 696.508789,60.002865 698.506409,60.008175 
	C704.977783,60.025368 704.989685,60.026714 704.994080,66.554756 
	C705.003479,80.371742 704.960815,94.189011 705.054810,108.005379 
	C705.065186,109.523621 705.690125,112.286446 706.263855,112.349678 
	C710.025574,112.764229 713.940002,113.034180 717.592590,112.298561 
	C718.473389,112.121155 718.883789,107.993263 718.952942,105.665497 
	C719.362610,91.884392 719.713745,78.099358 719.838013,64.313423 
	C719.871216,60.633968 721.450928,59.833523 724.687927,59.962532 
	C729.341492,60.147999 734.019104,59.805706 738.666077,60.060314 
	C742.479736,60.269268 743.114685,58.347816 742.965698,55.198616 
	C742.822144,52.162106 743.373840,49.072861 738.655762,49.100368 
	C720.844177,49.204201 703.031677,49.157234 685.219482,49.127888 
	C682.621643,49.123608 681.305176,50.049335 681.078064,52.858814 
	C680.548462,59.408997 680.959839,59.985016 687.535583,60.005962 
	C688.867371,60.010208 690.199097,60.006840 692.513550,60.006973 
M1237.000000,74.500015 
	C1236.997803,67.670929 1236.849854,60.837532 1237.051514,54.014469 
	C1237.162354,50.259758 1235.618652,48.835052 1232.036255,49.115765 
	C1230.711914,49.219536 1229.364746,49.225040 1228.041504,49.114094 
	C1224.432739,48.811531 1222.997070,50.296089 1223.021606,54.015358 
	C1223.141968,72.169968 1223.145264,90.326302 1223.015381,108.480774 
	C1222.990356,111.971123 1224.173584,113.094193 1227.632202,113.060966 
	C1242.454956,112.918526 1257.282471,112.847710 1272.102417,113.097015 
	C1276.426270,113.169762 1276.974976,111.244453 1276.972290,107.707825 
	C1276.969604,104.117592 1276.766235,101.746315 1272.016968,101.896294 
	C1261.700439,102.222061 1251.365356,101.913940 1241.039795,102.031487 
	C1238.033813,102.065704 1236.905273,101.025520 1236.962891,97.985138 
	C1237.104858,90.492546 1237.003418,82.995354 1237.000000,74.500015 
M654.357544,110.835060 
	C657.371643,111.534012 660.363281,112.359489 663.405334,112.901337 
	C667.641663,113.655884 669.182983,111.988747 669.110596,107.469978 
	C668.849487,91.179039 669.094543,74.880753 668.978943,58.586449 
	C668.903442,47.937263 670.810791,49.323746 659.478394,49.002811 
	C655.237427,48.882710 654.042053,50.571247 654.077759,54.575298 
	C654.242371,73.030197 654.190857,91.487030 654.357544,110.835060 
M1039.942505,67.502106 
	C1039.942505,64.342705 1039.943115,61.183300 1039.942383,58.023895 
	C1039.940063,48.793774 1039.939819,48.793930 1030.514282,49.130421 
	C1030.181885,49.142284 1029.717407,49.004856 1029.538208,49.174026 
	C1028.329468,50.314869 1026.177002,51.508259 1026.163452,52.705490 
	C1025.951782,71.326653 1025.983154,89.951241 1026.112793,108.573967 
	C1026.120483,109.687080 1027.203857,111.417786 1028.180786,111.770988 
	C1030.316650,112.543198 1032.703857,112.629272 1034.992554,112.967201 
	C1038.507690,113.486206 1040.072876,112.329704 1040.013794,108.407974 
	C1039.813599,95.108368 1039.940552,81.803825 1039.942505,67.502106 
M539.448547,32.125526 
	C536.291321,32.083973 533.126709,31.899216 529.980042,32.062035 
	C528.435486,32.141953 526.305298,32.381172 525.514038,33.390347 
	C523.134155,36.425648 521.281921,39.874638 519.120544,43.327312 
	C528.382080,45.480419 536.975891,42.515675 539.448547,32.125526 
z"
      />
      <path
        fill="#129394"
        opacity="1.000000"
        stroke="none"
        d="
M213.904175,153.111465 
	C199.641388,159.193451 185.300308,165.099442 171.135590,171.401810 
	C153.283524,179.344788 135.358139,187.189163 115.797554,189.633881 
	C93.917099,192.368561 72.189529,192.826523 51.776737,181.972351 
	C35.657944,173.401459 25.348690,160.387207 22.280209,142.540894 
	C19.459957,126.138222 24.130125,111.158936 33.856907,97.634850 
	C48.670071,77.038651 68.662346,63.334255 91.935646,54.292175 
	C100.631805,50.913567 109.914444,48.951778 119.033615,46.780533 
	C128.307083,44.572556 136.212082,48.905891 139.689209,57.947552 
	C141.743744,63.290009 143.202271,68.861664 144.914917,74.286240 
	C133.674942,76.924690 122.582428,78.819984 111.946503,82.166428 
	C86.471924,90.181633 65.766319,105.095619 51.083149,127.604492 
	C47.902870,132.479767 45.951073,138.301437 44.208321,143.927246 
	C42.577095,149.193039 45.417500,153.750824 50.539387,156.482040 
	C61.237843,162.186890 72.811668,162.663666 84.448975,161.926239 
	C106.662697,160.518631 128.495392,156.695541 149.059036,147.951553 
	C166.855164,140.384338 184.226273,131.817581 202.322327,123.351074 
	C212.929825,117.490219 223.000290,111.970940 233.070740,106.451660 
	C252.330185,96.188538 271.588135,85.922623 290.851593,75.667053 
	C292.573669,74.750267 294.331421,73.900574 296.894257,72.604065 
	C296.894257,82.891365 297.222229,92.609291 296.623688,102.269829 
	C296.500671,104.254906 293.161316,106.424713 290.885010,107.829811 
	C276.548370,116.679497 262.091919,125.335007 247.682632,134.067230 
	C247.120621,134.407806 246.635773,134.875732 245.572189,135.583832 
	C243.007507,136.590424 240.809280,136.995682 238.993790,138.056137 
	C230.572479,142.975113 222.257324,148.075821 213.904175,153.111465 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M274.738098,205.008072 
	C277.970734,204.740967 279.101471,205.945023 279.023651,208.753616 
	C278.889954,213.580215 278.890381,218.415878 279.023773,223.242493 
	C279.101257,226.045807 277.984741,227.243027 275.213257,226.999481 
	C274.387177,226.926895 273.548676,226.998840 272.715881,226.998871 
	C190.600052,227.002243 108.484238,227.005096 26.368412,227.007965 
	C25.551035,227.007996 24.733656,227.007950 24.009298,227.007950 
	C24.009298,220.898834 24.106499,215.008057 23.967966,209.122833 
	C23.899513,206.214890 24.782961,204.676910 27.922459,204.991898 
	C29.076406,205.107681 30.252392,205.000595 31.418324,205.000610 
	C112.368126,205.001312 193.317932,205.002563 274.738098,205.008072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M232.817169,106.167389 
	C223.000290,111.970940 212.929825,117.490219 202.500214,123.044930 
	C197.093277,110.522926 192.048111,97.964439 186.989349,85.411430 
	C186.761292,84.845528 186.410217,84.329193 185.689743,83.006195 
	C183.729782,86.790245 181.964294,89.886337 180.485168,93.113670 
	C176.203476,102.455971 171.617783,111.689079 167.918732,121.261566 
	C161.625809,137.546478 148.593689,144.540329 132.775436,147.910721 
	C127.331284,149.070709 121.738823,149.534683 114.855461,150.508896 
	C118.399239,143.407867 121.196518,137.466614 124.283836,131.680099 
	C133.105850,115.145119 142.046799,98.673615 150.938736,82.175934 
	C155.895309,72.979721 160.594070,63.628422 165.979416,54.690716 
	C167.329071,52.450790 170.817368,50.357914 173.428131,50.222019 
	C183.398224,49.703060 193.423630,49.748070 203.398743,50.215157 
	C205.576355,50.317123 208.634399,52.563374 209.585495,54.612000 
	C217.471115,71.597328 224.956879,88.768295 232.817169,106.167389 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M1171.848145,164.294556 
	C1171.163452,163.388565 1170.701294,162.735367 1169.394165,160.888321 
	C1169.206543,163.583496 1169.016479,165.071167 1169.013062,166.559265 
	C1168.986938,177.880600 1168.864990,189.204391 1169.068604,200.522110 
	C1169.139160,204.451309 1168.229858,206.223724 1163.860229,206.078674 
	C1152.286377,205.694458 1154.101929,207.272217 1154.029663,196.258575 
	C1153.920410,179.610199 1154.118774,162.959198 1153.922852,146.312424 
	C1153.874146,142.179916 1155.356567,141.490555 1159.264526,140.792633 
	C1169.670288,138.934280 1173.827759,146.427490 1179.061523,152.576157 
	C1187.950562,163.019196 1196.811157,173.486465 1206.644043,185.075424 
	C1206.808716,182.260941 1206.983276,180.627502 1206.987183,178.993652 
	C1207.013916,167.838821 1207.109009,156.682404 1206.946655,145.529907 
	C1206.895630,142.026459 1208.144897,140.879089 1211.583374,140.954132 
	C1224.050781,141.226151 1221.869019,139.634628 1221.964844,151.181671 
	C1222.101685,167.663132 1221.888306,184.147858 1222.075806,200.628326 
	C1222.121826,204.682770 1220.961792,206.008423 1216.767334,206.143372 
	C1210.808105,206.335083 1206.388916,205.318161 1202.290649,200.095459 
	C1192.697021,187.869659 1182.201050,176.351837 1171.848145,164.294556 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M358.224548,141.080322 
	C363.015045,145.582977 367.608765,149.855194 371.711456,154.555984 
	C380.174835,164.253082 388.376221,174.178787 397.631470,185.129868 
	C397.801514,182.304031 397.982513,180.656113 397.986664,179.007782 
	C398.014648,167.849777 398.108856,156.690186 397.946960,145.534515 
	C397.896118,142.034103 399.137634,140.879013 402.581360,140.954254 
	C415.055115,141.226730 412.868408,139.635376 412.964722,151.185425 
	C413.102203,167.671570 412.885681,184.161011 413.076477,200.646088 
	C413.123718,204.726746 411.953949,206.383392 407.736359,206.059082 
	C403.212311,205.711212 399.259216,206.277985 395.517029,201.645248 
	C384.599884,188.130173 372.718445,175.394028 360.440552,161.474518 
	C360.232269,163.887054 360.020233,165.221741 360.016968,166.556900 
	C359.988983,178.047974 359.898621,189.540527 360.057190,201.029465 
	C360.105652,204.540649 359.275452,206.168488 355.358032,206.063110 
	C342.971832,205.729980 345.129242,207.381912 345.032959,195.758270 
	C344.897736,179.438644 345.152130,163.114731 344.902344,146.797806 
	C344.831970,142.201828 346.237122,140.409195 350.816986,140.956085 
	C353.117157,141.230774 355.474182,141.029770 358.224548,141.080322 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M609.342285,141.035522 
	C621.805725,141.013596 633.821777,140.564377 645.792725,141.108337 
	C664.268066,141.947845 679.237793,155.153336 678.954651,174.300095 
	C678.763306,187.240967 668.311584,199.550385 653.793091,203.510422 
	C639.148499,207.504868 624.071716,205.809753 609.155457,205.765015 
	C608.113586,205.761902 606.186218,203.473373 606.173950,202.232803 
	C605.983459,182.908295 605.995728,163.580948 606.162231,144.255997 
	C606.171448,143.180710 607.930298,142.120499 609.342285,141.035522 
M629.571289,151.000336 
	C626.925964,151.898666 622.131958,148.605591 622.066589,154.532700 
	C621.934448,166.509232 621.971741,178.488922 622.117004,190.465149 
	C622.130737,191.606079 623.373230,193.699356 624.089844,193.718964 
	C634.315613,193.998520 644.849731,195.563553 654.188477,189.816162 
	C661.239685,185.476624 663.604797,178.562851 662.947937,170.555817 
	C662.323059,162.938141 658.431152,157.097794 651.276733,154.837143 
	C644.620361,152.733856 637.462952,152.216034 629.571289,151.000336 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M779.159912,147.747803 
	C788.230591,153.694321 792.016174,162.365555 792.703735,172.283218 
	C793.545288,184.423248 784.575134,197.455414 772.216797,201.646835 
	C765.432861,203.947678 758.141602,205.241913 750.984070,205.826294 
	C742.386414,206.528229 733.686035,205.894089 725.033508,206.038513 
	C721.456360,206.098221 719.936096,204.858871 719.957520,201.132751 
	C720.064758,182.491882 720.064453,163.849731 719.956299,145.208878 
	C719.937012,141.874054 721.261658,140.931351 724.396484,140.961990 
	C736.379150,141.079132 748.423767,140.295837 760.322998,141.299316 
	C766.639709,141.832016 772.705139,145.343506 779.159912,147.747803 
M764.211548,154.089478 
	C755.713623,150.415451 746.728760,150.824829 737.807800,151.220596 
	C736.826294,151.264114 735.160645,153.543732 735.138611,154.809402 
	C734.932800,166.609634 735.062134,178.415253 734.984192,190.218567 
	C734.965820,193.003281 736.076416,194.069519 738.824890,194.014084 
	C744.308899,193.903427 749.811035,194.229538 755.279785,193.919250 
	C766.052002,193.307999 774.776184,186.348373 776.785950,177.150406 
	C778.852783,167.691147 774.455383,159.290878 764.211548,154.089478 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M1047.391113,168.116318 
	C1043.968994,174.954727 1040.507568,177.537521 1030.228027,180.727478 
	C1036.919556,188.550690 1043.392944,196.118912 1050.878662,204.870621 
	C1044.418091,204.870621 1039.363281,204.943039 1034.314819,204.798523 
	C1033.484741,204.774765 1032.503906,203.950378 1031.894653,203.237915 
	C1027.055420,197.579117 1022.090698,192.009445 1017.576477,186.097839 
	C1014.959900,182.671326 1011.639709,182.998932 1008.170898,182.996902 
	C998.003662,182.990952 998.003601,182.988022 998.002991,192.946671 
	C998.002930,193.446579 998.002991,193.946472 998.002991,194.446381 
	C998.002625,206.087845 997.990723,206.189148 986.459778,204.824692 
	C985.193237,204.674789 983.182312,202.547913 983.168457,201.319351 
	C982.956055,182.492081 983.047180,163.661636 982.984497,144.832184 
	C982.975342,142.080032 983.996948,140.969513 986.788269,140.984299 
	C1000.951782,141.059372 1015.130371,140.623978 1029.275513,141.140671 
	C1043.253418,141.651260 1051.688477,154.398788 1047.391113,168.116318 
M998.998657,164.403168 
	C999.668396,166.887680 1000.341187,171.530853 1001.007507,171.529922 
	C1009.726379,171.517944 1018.535400,171.430969 1027.122314,170.087875 
	C1031.117920,169.462906 1034.094727,165.561264 1033.932739,160.769852 
	C1033.770508,155.962784 1030.796631,152.926041 1026.397949,152.121796 
	C1021.565247,151.238220 1016.556274,151.098907 1011.619751,151.045425 
	C998.999634,150.908676 998.998657,150.999832 998.998657,164.403168 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M584.740112,147.245331 
	C596.448914,159.552048 591.103516,173.841095 572.057922,181.004395 
	C578.690674,188.673035 585.219543,196.221481 591.834473,203.869492 
	C582.792725,207.037766 575.662415,205.114212 569.798279,198.371719 
	C566.101990,194.121750 562.213501,190.038513 558.396179,185.894409 
	C556.179077,183.487473 542.810303,181.515594 540.272888,183.462402 
	C539.447937,184.095337 539.106140,185.747391 539.069275,186.949417 
	C538.926575,191.604095 538.885376,196.268661 539.026123,200.922897 
	C539.116455,203.909653 537.994080,205.061493 534.964783,205.026520 
	C522.834351,204.886475 525.130920,205.963943 525.037415,195.373428 
	C524.894958,179.235184 525.187622,163.091293 524.889465,146.957291 
	C524.797974,142.006317 526.455505,140.787064 531.150024,140.900558 
	C543.455444,141.198044 555.774109,141.107727 568.084839,140.956085 
	C574.403259,140.878281 579.682129,143.169144 584.740112,147.245331 
M548.574951,150.999817 
	C547.579651,151.000443 546.568176,151.117538 545.591919,150.981598 
	C541.295776,150.383347 539.532288,152.014587 539.931824,156.492004 
	C540.284363,160.442291 539.827271,164.462952 540.141602,168.419662 
	C540.238464,169.639297 541.705994,171.763168 542.529114,171.755035 
	C550.607544,171.675430 558.720337,171.593643 566.743225,170.750412 
	C571.173828,170.284760 574.777161,165.298767 575.014893,161.475296 
	C575.363159,155.872955 571.862976,152.954300 567.282410,152.143188 
	C561.487671,151.117081 555.474609,151.324448 548.574951,150.999817 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M509.986237,170.276520 
	C511.147064,190.753601 492.626221,206.336227 474.767548,205.968002 
	C467.821991,205.824814 460.667328,206.296997 453.983582,204.805511 
	C435.557983,200.693756 423.601471,180.520386 428.994568,164.323715 
	C432.096802,155.007050 438.002838,148.212265 446.597321,143.914505 
	C449.879120,142.273438 453.775696,141.353790 457.455353,141.125778 
	C464.419006,140.694275 471.536652,141.780716 478.394836,140.828415 
	C490.554932,139.139954 506.341064,153.499680 508.990570,164.985931 
	C509.360962,166.591660 509.651794,168.215744 509.986237,170.276520 
M449.500336,187.999512 
	C459.554474,197.082230 471.965759,197.806030 483.090515,191.778503 
	C494.203491,185.757385 497.585693,173.199982 491.797028,162.442200 
	C485.098236,149.992966 466.188446,146.095230 452.888428,154.687073 
	C441.747314,161.884262 439.848877,177.856400 449.500336,187.999512 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M993.063110,89.316422 
	C998.565857,96.535706 1004.328613,103.560555 1010.070679,110.602234 
	C1010.447144,111.063843 1010.666199,111.653793 1011.305237,112.812889 
	C1006.281677,112.812889 1001.835449,113.060722 997.444031,112.678772 
	C995.902405,112.544693 994.252075,111.095367 993.069580,109.849602 
	C988.499512,105.034843 984.070801,100.084328 979.641357,95.137871 
	C974.962158,89.912376 968.818054,90.846794 962.892883,91.286186 
	C962.188660,91.338402 961.158142,93.471619 961.104431,94.677002 
	C960.889587,99.498772 960.906250,104.337425 961.027222,109.165070 
	C961.096924,111.947502 959.990051,112.858475 957.230591,113.006958 
	C946.079041,113.607002 946.083008,113.693085 946.083130,102.746346 
	C946.083374,86.754189 946.191833,70.760841 946.013855,54.770668 
	C945.966492,50.518288 947.216431,48.689430 951.696350,48.946045 
	C965.106079,49.714161 978.591919,49.647823 991.927612,51.051525 
	C1001.975464,52.109161 1008.895447,59.066120 1009.895996,67.060425 
	C1011.114746,76.798775 1005.264832,82.434410 997.794250,86.963165 
	C996.383667,87.818321 994.814331,88.411758 993.063110,89.316422 
M961.003845,66.609772 
	C961.006042,69.598770 961.142883,72.595444 960.973083,75.574638 
	C960.798279,78.640373 961.679382,80.149002 965.107483,80.045082 
	C971.743652,79.843933 978.392944,80.103920 985.031372,79.945137 
	C990.224670,79.820923 994.978455,74.926125 994.974670,70.073082 
	C994.971252,65.546463 990.754578,61.536312 985.513977,61.020912 
	C983.055115,60.779087 980.611938,60.338329 978.149353,60.181961 
	C972.627258,59.831333 967.097534,59.602261 961.004089,59.295124 
	C961.004089,61.741135 961.004089,63.698044 961.003845,66.609772 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M482.231384,107.734970 
	C483.445435,109.261330 484.413940,110.536972 485.588776,112.084373 
	C474.598114,115.160324 466.388885,112.466621 460.546204,103.068642 
	C459.069031,100.692581 457.016052,98.681778 455.327789,96.428139 
	C451.844696,91.778717 441.611755,88.829720 436.585419,91.328545 
	C435.742676,91.747520 435.133545,93.415596 435.092194,94.531555 
	C434.919739,99.185684 434.863007,103.854965 435.045380,108.507652 
	C435.170807,111.707176 434.042633,112.848480 430.771454,112.934204 
	C421.060028,113.188690 421.061890,113.317650 421.061676,103.739891 
	C421.061310,87.262581 421.179321,70.783875 420.982056,54.308933 
	C420.932892,50.204498 422.146912,48.883369 426.323181,48.905884 
	C440.590271,48.982800 454.853638,48.748001 468.882080,51.993511 
	C477.191833,53.915993 483.287781,59.787785 483.884247,67.168983 
	C484.584534,75.835762 481.978882,82.467422 473.082794,86.471016 
	C471.127838,87.350830 469.263275,88.431534 466.685577,89.767822 
	C471.933136,95.844078 476.959473,101.664169 482.231384,107.734970 
M468.621124,67.324181 
	C464.319580,60.395817 457.441986,59.972553 450.328064,59.989666 
	C434.108490,60.028679 434.193512,59.994999 434.941010,76.112465 
	C435.085754,79.233582 436.265137,80.087906 439.137878,80.027634 
	C445.277954,79.898827 451.422974,80.030235 457.565491,79.984451 
	C465.002899,79.929016 469.027222,75.652611 468.621124,67.324181 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M572.054443,61.040024 
	C572.250916,47.512505 570.209778,49.192631 583.882568,48.993698 
	C595.681091,48.822037 607.398315,49.166325 618.785095,52.849895 
	C634.769592,58.020794 641.365417,67.151505 640.998535,83.889763 
	C640.741638,95.611465 631.021912,107.198952 617.698181,110.399857 
	C610.839905,112.047493 603.634399,112.470802 596.552612,112.903152 
	C589.920166,113.308083 583.239197,112.846024 576.588928,113.058701 
	C573.098938,113.170319 571.970398,111.927597 572.000549,108.464401 
	C572.136841,92.821671 572.056641,77.177048 572.054443,61.040024 
M623.953003,90.272514 
	C624.874573,86.982529 627.007141,83.486885 626.489868,80.441246 
	C624.959656,71.431221 620.174377,64.039551 610.706299,62.034615 
	C603.520569,60.512985 595.986267,60.550987 588.592529,60.222198 
	C587.786377,60.186348 586.156494,62.306179 586.138306,63.445747 
	C585.952759,75.078590 586.080322,86.716003 585.978882,98.350899 
	C585.953613,101.251389 587.235535,102.097389 589.886414,102.040039 
	C594.703918,101.935806 599.527954,102.105423 604.344177,101.974152 
	C612.705933,101.746254 619.330811,98.355942 623.953003,90.272514 
z"
      />
      <path
        fill="#156C55"
        opacity="1.000000"
        stroke="none"
        d="
M1182.655518,50.083019 
	C1193.986328,53.190987 1202.615723,59.154331 1207.254517,69.815338 
	C1213.811768,84.885033 1205.971802,103.530647 1189.867920,109.893387 
	C1174.753662,115.865143 1159.690186,116.202553 1145.012329,107.581398 
	C1125.277832,95.990135 1124.077759,70.511848 1142.354248,56.717014 
	C1154.745605,47.364262 1168.280884,48.243698 1182.655518,50.083019 
M1144.325439,85.780556 
	C1145.526489,91.383263 1148.272583,95.884476 1153.432495,98.713280 
	C1160.518311,102.597855 1168.201904,103.904900 1176.033569,102.761703 
	C1187.831421,101.039558 1197.044189,88.345367 1194.566040,78.009338 
	C1191.546997,65.417442 1179.303833,57.002113 1165.864014,59.165165 
	C1153.320312,61.184002 1142.715942,68.733437 1144.325439,85.780556 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M1320.748901,141.727325 
	C1339.715088,141.286789 1358.352539,141.136917 1376.990356,141.067963 
	C1381.157227,141.052536 1379.723145,144.389145 1379.966309,146.597961 
	C1380.257568,149.243668 1379.657471,151.056015 1376.299316,151.024902 
	C1364.476196,150.915405 1352.646973,151.181793 1340.829468,150.906235 
	C1336.277100,150.800079 1334.729858,152.158051 1334.910522,156.787567 
	C1335.321899,167.328949 1333.903442,165.894409 1343.890259,165.983490 
	C1353.049072,166.065186 1362.216187,166.215622 1371.365967,165.917160 
	C1375.868530,165.770264 1376.067871,168.120178 1376.050903,171.478592 
	C1376.034180,174.784454 1375.968140,177.213226 1371.410645,177.083679 
	C1361.260132,176.795105 1351.086304,177.257446 1340.940918,176.890472 
	C1335.953857,176.710083 1334.291992,178.499512 1334.984741,183.169830 
	C1335.130127,184.150223 1334.957520,185.175903 1335.082275,186.161621 
	C1335.915649,192.741837 1337.311646,193.973755 1344.061157,193.991119 
	C1354.886108,194.018967 1365.716064,194.198029 1376.533691,193.917313 
	C1381.240967,193.795151 1381.161011,196.471176 1380.991821,199.644531 
	C1380.834595,202.591827 1382.105957,206.157379 1376.741699,206.060074 
	C1364.255493,205.833588 1351.757324,206.207108 1339.273560,205.920883 
	C1327.338867,205.647232 1320.799194,199.665115 1320.237915,186.956726 
	C1319.578369,172.018707 1320.298462,157.019730 1320.748901,141.727325 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M848.997681,193.998642 
	C853.495117,194.001343 857.513855,194.261475 861.485474,193.939743 
	C866.151672,193.561768 866.196228,196.383011 865.990417,199.601746 
	C865.803955,202.518387 867.177734,206.149292 861.781128,206.056000 
	C849.291382,205.840057 836.759888,206.578796 824.311035,205.832809 
	C809.223938,204.928711 806.087891,199.127884 805.219482,186.029266 
	C804.317810,172.429367 805.067200,158.723175 804.968506,145.064377 
	C804.946777,142.063873 806.028748,140.955658 809.047180,140.970917 
	C826.370972,141.058548 843.696167,141.099457 861.019165,140.951385 
	C865.125916,140.916290 864.953918,143.292267 865.039612,146.121063 
	C865.133545,149.224792 864.619812,151.113342 860.773865,151.056137 
	C848.782837,150.877731 836.785583,151.117203 824.794678,150.935013 
	C821.068054,150.878387 819.849304,152.125320 819.943420,155.823532 
	C820.251038,167.915680 818.697144,165.820724 829.932190,165.976364 
	C838.759277,166.098633 847.596863,166.222504 856.414978,165.918243 
	C860.983948,165.760620 861.027649,168.231537 861.051025,171.530350 
	C861.074890,174.901245 860.845581,177.205002 856.356079,177.084427 
	C845.869202,176.802826 835.367371,177.121109 824.876099,176.938660 
	C821.248352,176.875565 819.535461,177.942215 819.978516,181.741180 
	C820.151062,183.220306 819.971558,184.737350 820.014282,186.235352 
	C820.174744,191.861298 822.235718,193.915466 828.009094,193.977203 
	C834.838074,194.050217 841.668457,193.997086 848.997681,193.998642 
z"
      />
      <path
        fill="#156C55"
        opacity="1.000000"
        stroke="none"
        d="
M872.855957,107.226746 
	C852.697083,93.477348 854.553223,64.628685 876.083435,54.005352 
	C891.372986,46.461231 906.760559,46.661232 921.879639,54.558575 
	C923.563110,55.437885 925.699463,57.770164 925.587891,59.277710 
	C925.363037,62.313919 920.028992,64.910973 917.294373,64.167488 
	C910.809937,62.404507 904.334778,59.461433 897.798950,59.299957 
	C887.755737,59.051823 879.229797,63.669731 875.304993,73.522804 
	C870.504517,85.574051 877.049744,100.918449 892.376953,101.945663 
	C897.613403,102.296608 902.901062,102.159943 908.152588,101.941216 
	C915.104553,101.651680 918.462402,96.272186 915.716919,89.774582 
	C915.327942,88.853920 913.421387,88.165932 912.174622,88.092430 
	C908.358643,87.867409 904.516418,87.862976 900.694641,88.020569 
	C896.721497,88.184395 897.123657,85.505768 897.121765,82.997513 
	C897.119873,80.484886 896.732361,77.906937 900.699097,77.970718 
	C909.519531,78.112534 918.344482,78.072189 927.166138,77.973236 
	C929.887146,77.942719 931.088989,78.853073 931.026245,81.703674 
	C930.894470,87.693611 931.545654,93.767937 930.721130,99.645699 
	C930.338196,102.375412 927.804871,105.727798 925.312927,107.088509 
	C908.096558,116.489372 890.601562,116.819824 872.855957,107.226746 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M1061.689941,201.672638 
	C1071.893433,182.291336 1081.845337,163.198761 1092.058838,144.247192 
	C1092.904419,142.678238 1095.404297,141.512589 1097.335693,141.102615 
	C1105.192139,139.434937 1110.388672,142.099457 1114.111450,149.840622 
	C1122.416748,167.110886 1131.639648,183.939407 1140.452759,200.966507 
	C1141.102295,202.221466 1141.464111,203.625275 1142.292847,205.856628 
	C1136.775879,205.856628 1132.018921,206.083557 1127.312988,205.694366 
	C1126.161011,205.599091 1124.888184,203.641510 1124.158691,202.279022 
	C1117.592407,190.015503 1117.624390,189.997864 1103.929810,189.998611 
	C1098.102051,189.998932 1092.266846,190.180603 1086.449707,189.933289 
	C1083.224854,189.796204 1081.481445,191.102493 1080.548462,193.963730 
	C1080.497437,194.120255 1080.391846,194.258026 1080.328369,194.411484 
	C1075.250610,206.700287 1074.278198,207.261963 1060.423462,205.522659 
	C1060.801270,204.292923 1061.153931,203.145523 1061.689941,201.672638 
M1108.943970,173.405869 
	C1106.242432,167.817810 1103.541016,162.229767 1100.254761,155.431839 
	C1096.156860,163.750626 1092.650269,170.868835 1088.779663,178.726257 
	C1097.009399,178.726257 1103.907349,178.726257 1111.507080,178.726257 
	C1110.677490,176.863571 1110.034912,175.420914 1108.943970,173.405869 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M1309.066895,84.961754 
	C1299.022705,84.970291 1298.535278,85.838799 1300.799438,95.319160 
	C1302.100952,100.769249 1305.972534,101.863693 1310.476929,101.938156 
	C1320.305054,102.100624 1330.145386,102.226250 1339.964966,101.897514 
	C1344.682373,101.739594 1344.965698,104.066879 1344.950439,107.691208 
	C1344.935669,111.209641 1344.440186,113.266144 1340.083130,113.082985 
	C1326.966797,112.531609 1313.815796,112.679626 1300.715454,111.916168 
	C1290.909546,111.344711 1286.229126,106.221695 1286.075562,96.562363 
	C1285.845093,82.069778 1286.071289,67.570435 1285.957764,53.075108 
	C1285.933105,49.926121 1287.281372,49.089336 1290.156494,49.101856 
	C1306.485352,49.172955 1322.815552,49.230747 1339.143066,49.070293 
	C1343.525391,49.027225 1343.920288,51.357346 1343.900513,54.773182 
	C1343.881470,58.077778 1343.275513,60.118591 1339.240723,60.056633 
	C1327.746582,59.880104 1316.245361,60.132099 1304.752075,59.930546 
	C1300.996582,59.864689 1299.838989,61.113644 1299.943726,64.806099 
	C1300.289429,76.985138 1298.552368,74.794098 1309.858521,74.969948 
	C1318.021118,75.096909 1326.187744,75.045532 1334.351562,74.970917 
	C1337.562256,74.941574 1340.111328,74.808846 1340.069702,79.486359 
	C1340.028809,84.079567 1338.180542,85.140816 1334.048340,85.026436 
	C1325.890015,84.800598 1317.721069,84.961044 1309.066895,84.961754 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M532.000366,59.992676 
	C526.171631,59.999859 520.841553,60.077953 515.514526,59.983578 
	C512.945312,59.938065 512.134399,60.999443 511.963348,63.522686 
	C511.184998,75.003456 511.111145,74.997215 522.557556,74.999046 
	C530.717407,75.000351 538.882019,75.171974 547.035156,74.939552 
	C551.176270,74.821510 550.984436,77.237762 551.037781,80.064018 
	C551.094055,83.048126 550.884766,85.150536 546.896973,85.061264 
	C537.076843,84.841400 527.244751,85.151245 517.424988,84.922775 
	C513.476685,84.830902 511.819000,85.751076 511.900330,90.112450 
	C512.083740,99.946091 513.901001,101.993759 523.886658,101.998047 
	C533.378723,102.002129 542.875488,102.177246 552.360657,101.921989 
	C556.437439,101.812279 557.089905,103.702721 556.924133,107.063293 
	C556.771484,110.156914 557.161011,113.104179 552.479614,113.044144 
	C539.659119,112.879768 526.829773,113.212585 514.014465,112.906830 
	C504.534210,112.680641 498.337311,106.653198 498.114807,97.206429 
	C497.765900,82.393677 498.058899,67.566582 497.974213,52.746159 
	C497.958160,49.938721 499.034119,48.961704 501.794067,48.974281 
	C518.113403,49.048660 534.434631,49.127213 550.752197,48.940063 
	C555.618164,48.884258 554.854431,51.925121 555.026733,54.899654 
	C555.220764,58.251549 554.569153,60.254208 550.481689,60.059738 
	C544.500366,59.775166 538.495178,59.990437 532.000366,59.992676 
z"
      />
      <path
        fill="#156C55"
        opacity="1.000000"
        stroke="none"
        d="
M786.035278,93.022003 
	C792.870972,79.909805 799.570007,67.143959 806.283386,54.385685 
	C810.221191,46.902245 820.596191,46.499969 825.318298,53.644066 
	C826.140686,54.888161 827.000122,56.122280 827.677490,57.445435 
	C836.288757,74.266197 844.876526,91.099007 853.446472,107.940857 
	C854.168701,109.360245 854.720154,110.866539 855.623047,112.964668 
	C850.758484,112.964668 846.584473,113.322739 842.527100,112.790604 
	C840.875427,112.573997 838.778198,110.725754 838.078430,109.104301 
	C833.851013,99.308517 831.496582,97.688606 820.728149,97.902252 
	C814.739502,98.021072 808.710266,97.602806 802.775757,98.193695 
	C800.444275,98.425842 796.812317,100.367653 796.349365,102.186653 
	C793.448547,113.585205 787.379150,113.783691 776.296570,112.489822 
	C779.589905,105.936745 782.747314,99.654144 786.035278,93.022003 
M814.536316,86.997383 
	C818.225952,86.997383 821.915649,86.997383 826.449768,86.997383 
	C822.620728,78.976677 819.340210,72.104950 815.446228,63.948162 
	C811.357117,72.194641 807.939941,79.085976 804.016907,86.997375 
	C808.322327,86.997375 810.951233,86.997375 814.536316,86.997383 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M213.994385,153.459778 
	C222.257324,148.075821 230.572479,142.975113 238.993790,138.056137 
	C240.809280,136.995682 243.007507,136.590424 245.402954,135.888519 
	C253.738983,152.382950 261.712341,168.867020 269.632721,185.376526 
	C270.041718,186.229080 269.997162,187.299194 270.289703,189.000244 
	C261.622742,189.000244 253.154419,188.247498 244.876083,189.180542 
	C233.103897,190.507385 226.108978,186.461044 222.767197,174.848495 
	C220.681915,167.602249 217.032227,160.806168 213.994385,153.459778 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M1273.017334,194.150757 
	C1283.821655,196.759094 1292.501221,192.473022 1301.538940,186.919418 
	C1303.682007,189.457443 1305.862549,192.039932 1309.023804,195.783859 
	C1303.458984,198.399384 1298.850342,201.331039 1293.815063,202.791473 
	C1277.336548,207.571014 1261.332397,206.317429 1247.792603,195.010651 
	C1232.142700,181.941818 1231.833008,156.988022 1253.320068,145.098587 
	C1257.739502,142.653183 1263.260864,141.633896 1268.384888,141.164108 
	C1275.486938,140.512955 1282.786621,140.302261 1289.824463,141.237335 
	C1295.027954,141.928696 1300.000000,144.629929 1304.936157,146.758072 
	C1305.624756,147.054947 1306.155151,149.579086 1305.656616,150.414459 
	C1304.249634,152.771896 1302.268921,154.786972 1300.241455,157.271500 
	C1292.623779,151.116547 1284.132446,149.116608 1274.864380,150.001328 
	C1257.456909,151.663086 1247.170044,168.581787 1256.107178,183.991638 
	C1259.668945,190.132965 1265.931641,192.618134 1273.017334,194.150757 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M1097.772705,102.995789 
	C1102.011963,101.916809 1106.005737,101.294502 1109.541382,99.673248 
	C1118.377075,95.621567 1118.293701,95.439636 1123.181885,104.497314 
	C1115.940796,110.568390 1107.185547,113.032890 1098.111206,113.859894 
	C1086.376953,114.929321 1075.520142,112.149948 1065.784912,104.960724 
	C1050.339478,93.554718 1050.463989,68.469986 1066.096558,57.297855 
	C1084.042847,44.472221 1105.728882,46.048943 1122.365723,57.410786 
	C1121.861450,58.404411 1121.422607,59.459450 1120.825073,60.415340 
	C1117.272461,66.098564 1116.631958,66.293579 1110.554810,63.205326 
	C1103.064941,59.399158 1095.313477,57.605160 1087.033447,60.129196 
	C1077.114014,63.153004 1070.894409,69.631607 1069.144897,79.993927 
	C1067.900757,87.363037 1074.950684,98.240143 1083.186646,100.806824 
	C1087.687744,102.209541 1092.594727,102.310036 1097.772705,102.995789 
z"
      />
      <path
        fill="#156C55"
        opacity="1.000000"
        stroke="none"
        d="
M405.277496,106.363220 
	C387.756927,118.074623 361.764709,116.337402 348.288696,102.789818 
	C334.059357,88.484894 336.422211,66.179207 353.332397,55.176624 
	C368.531921,45.287067 393.170563,46.361290 406.562836,57.497414 
	C403.421509,64.702507 402.030334,66.098969 393.859985,62.381725 
	C383.333923,57.592735 373.115082,57.439308 363.189911,64.185287 
	C350.837280,72.581200 350.651215,89.549332 363.020142,98.069466 
	C374.746460,106.146942 386.511139,104.070389 398.206451,97.896889 
	C399.209320,97.367516 400.143372,96.707703 401.512360,95.858551 
	C403.481506,98.271988 405.508484,100.446892 407.072449,102.915184 
	C407.415405,103.456413 406.109924,105.042107 405.277496,106.363220 
z"
      />
      <path
        fill="#00664A"
        opacity="1.000000"
        stroke="none"
        d="
M915.214478,141.003632 
	C932.019348,141.002350 948.347656,141.063721 964.674866,140.955078 
	C968.199463,140.931641 970.159058,141.457321 970.137512,145.783401 
	C970.116333,150.037994 968.639648,151.149109 964.608521,151.074432 
	C952.949829,150.858475 941.283325,151.092728 929.622314,150.950394 
	C926.228882,150.908981 924.742615,151.951843 924.959717,155.499725 
	C925.183228,159.151474 925.182495,162.838135 924.959717,166.489975 
	C924.743896,170.026932 926.205505,171.101624 929.612122,171.050125 
	C939.773010,170.896469 949.942505,171.180344 960.098877,170.918289 
	C964.260010,170.810913 965.058899,172.523239 965.098633,176.207520 
	C965.138916,179.944992 963.874023,181.139175 960.182251,181.062759 
	C950.522949,180.862823 940.842163,181.316895 931.199097,180.874390 
	C925.897705,180.631119 924.541382,182.560959 924.921936,187.430161 
	C925.284363,192.066193 925.232178,196.776245 924.815674,201.404572 
	C924.700439,202.684418 922.734924,204.691132 921.449768,204.832581 
	C910.008606,206.091751 909.996948,205.990417 909.997620,194.358566 
	C909.998596,178.197250 910.085815,162.035156 909.936340,145.875244 
	C909.902832,142.246399 910.952087,140.482132 915.214478,141.003632 
z"
      />
      <path
        fill="#D83F39"
        opacity="1.000000"
        stroke="none"
        d="
M297.002869,54.760151 
	C297.813751,60.074558 295.439789,62.941120 291.325958,65.101135 
	C278.133942,72.027779 265.050476,79.161194 251.862091,86.094856 
	C249.548935,87.310974 246.988922,88.190010 244.441025,88.797104 
	C240.314438,89.780357 236.616516,88.618935 234.654953,84.732735 
	C232.003876,79.480598 229.849365,73.977814 227.400177,68.396553 
	C252.948257,61.099224 273.146790,44.803791 297.003113,34.409061 
	C297.003113,41.180805 297.003113,47.737392 297.002869,54.760151 
z"
      />
      <path
        fill="#156B54"
        opacity="1.000000"
        stroke="none"
        d="
M692.022217,60.006977 
	C690.199097,60.006840 688.867371,60.010208 687.535583,60.005962 
	C680.959839,59.985016 680.548462,59.408997 681.078064,52.858814 
	C681.305176,50.049335 682.621643,49.123608 685.219482,49.127888 
	C703.031677,49.157234 720.844177,49.204201 738.655762,49.100368 
	C743.373840,49.072861 742.822144,52.162106 742.965698,55.198616 
	C743.114685,58.347816 742.479736,60.269268 738.666077,60.060314 
	C734.019104,59.805706 729.341492,60.147999 724.687927,59.962532 
	C721.450928,59.833523 719.871216,60.633968 719.838013,64.313423 
	C719.713745,78.099358 719.362610,91.884392 718.952942,105.665497 
	C718.883789,107.993263 718.473389,112.121155 717.592590,112.298561 
	C713.940002,113.034180 710.025574,112.764229 706.263855,112.349678 
	C705.690125,112.286446 705.065186,109.523621 705.054810,108.005379 
	C704.960815,94.189011 705.003479,80.371742 704.994080,66.554756 
	C704.989685,60.026714 704.977783,60.025368 698.506409,60.008175 
	C696.508789,60.002865 694.511169,60.007107 692.022217,60.006977 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M1237.000000,75.000015 
	C1237.003418,82.995354 1237.104858,90.492546 1236.962891,97.985138 
	C1236.905273,101.025520 1238.033813,102.065704 1241.039795,102.031487 
	C1251.365356,101.913940 1261.700439,102.222061 1272.016968,101.896294 
	C1276.766235,101.746315 1276.969604,104.117592 1276.972290,107.707825 
	C1276.974976,111.244453 1276.426270,113.169762 1272.102417,113.097015 
	C1257.282471,112.847710 1242.454956,112.918526 1227.632202,113.060966 
	C1224.173584,113.094193 1222.990356,111.971123 1223.015381,108.480774 
	C1223.145264,90.326302 1223.141968,72.169968 1223.021606,54.015358 
	C1222.997070,50.296089 1224.432739,48.811531 1228.041504,49.114094 
	C1229.364746,49.225040 1230.711914,49.219536 1232.036255,49.115765 
	C1235.618652,48.835052 1237.162354,50.259758 1237.051514,54.014469 
	C1236.849854,60.837532 1236.997803,67.670929 1237.000000,75.000015 
z"
      />
      <path
        fill="#156B53"
        opacity="1.000000"
        stroke="none"
        d="
M654.287231,110.389114 
	C654.190857,91.487030 654.242371,73.030197 654.077759,54.575298 
	C654.042053,50.571247 655.237427,48.882710 659.478394,49.002811 
	C670.810791,49.323746 668.903442,47.937263 668.978943,58.586449 
	C669.094543,74.880753 668.849487,91.179039 669.110596,107.469978 
	C669.182983,111.988747 667.641663,113.655884 663.405334,112.901337 
	C660.363281,112.359489 657.371643,111.534012 654.287231,110.389114 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M1039.942505,68.001640 
	C1039.940552,81.803825 1039.813599,95.108368 1040.013794,108.407974 
	C1040.072876,112.329704 1038.507690,113.486206 1034.992554,112.967201 
	C1032.703857,112.629272 1030.316650,112.543198 1028.180786,111.770988 
	C1027.203857,111.417786 1026.120483,109.687080 1026.112793,108.573967 
	C1025.983154,89.951241 1025.951782,71.326653 1026.163452,52.705490 
	C1026.177002,51.508259 1028.329468,50.314869 1029.538208,49.174026 
	C1029.717407,49.004856 1030.181885,49.142284 1030.514282,49.130421 
	C1039.939819,48.793930 1039.940063,48.793774 1039.942383,58.023895 
	C1039.943115,61.183300 1039.942505,64.342705 1039.942505,68.001640 
z"
      />
      <path
        fill="#156C54"
        opacity="1.000000"
        stroke="none"
        d="
M539.813171,32.254787 
	C536.975891,42.515675 528.382080,45.480419 519.120544,43.327312 
	C521.281921,39.874638 523.134155,36.425648 525.514038,33.390347 
	C526.305298,32.381172 528.435486,32.141953 529.980042,32.062035 
	C533.126709,31.899216 536.291321,32.083973 539.813171,32.254787 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M630.048706,151.000275 
	C637.462952,152.216034 644.620361,152.733856 651.276733,154.837143 
	C658.431152,157.097794 662.323059,162.938141 662.947937,170.555817 
	C663.604797,178.562851 661.239685,185.476624 654.188477,189.816162 
	C644.849731,195.563553 634.315613,193.998520 624.089844,193.718964 
	C623.373230,193.699356 622.130737,191.606079 622.117004,190.465149 
	C621.971741,178.488922 621.934448,166.509232 622.066589,154.532700 
	C622.131958,148.605591 626.925964,151.898666 630.048706,151.000275 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M764.553345,154.262741 
	C774.455383,159.290878 778.852783,167.691147 776.785950,177.150406 
	C774.776184,186.348373 766.052002,193.307999 755.279785,193.919250 
	C749.811035,194.229538 744.308899,193.903427 738.824890,194.014084 
	C736.076416,194.069519 734.965820,193.003281 734.984192,190.218567 
	C735.062134,178.415253 734.932800,166.609634 735.138611,154.809402 
	C735.160645,153.543732 736.826294,151.264114 737.807800,151.220596 
	C746.728760,150.824829 755.713623,150.415451 764.553345,154.262741 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M998.998657,163.920700 
	C998.998657,150.999832 998.999634,150.908676 1011.619751,151.045425 
	C1016.556274,151.098907 1021.565247,151.238220 1026.397949,152.121796 
	C1030.796631,152.926041 1033.770508,155.962784 1033.932739,160.769852 
	C1034.094727,165.561264 1031.117920,169.462906 1027.122314,170.087875 
	C1018.535400,171.430969 1009.726379,171.517944 1001.007507,171.529922 
	C1000.341187,171.530853 999.668396,166.887680 998.998657,163.920700 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M549.065308,150.999817 
	C555.474609,151.324448 561.487671,151.117081 567.282410,152.143188 
	C571.862976,152.954300 575.363159,155.872955 575.014893,161.475296 
	C574.777161,165.298767 571.173828,170.284760 566.743225,170.750412 
	C558.720337,171.593643 550.607544,171.675430 542.529114,171.755035 
	C541.705994,171.763168 540.238464,169.639297 540.141602,168.419662 
	C539.827271,164.462952 540.284363,160.442291 539.931824,156.492004 
	C539.532288,152.014587 541.295776,150.383347 545.591919,150.981598 
	C546.568176,151.117538 547.579651,151.000443 549.065308,150.999817 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M449.245056,187.743652 
	C439.848877,177.856400 441.747314,161.884262 452.888428,154.687073 
	C466.188446,146.095230 485.098236,149.992966 491.797028,162.442200 
	C497.585693,173.199982 494.203491,185.757385 483.090515,191.778503 
	C471.965759,197.806030 459.554474,197.082230 449.245056,187.743652 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M961.003967,66.132362 
	C961.004089,63.698044 961.004089,61.741135 961.004089,59.295124 
	C967.097534,59.602261 972.627258,59.831333 978.149353,60.181961 
	C980.611938,60.338329 983.055115,60.779087 985.513977,61.020912 
	C990.754578,61.536312 994.971252,65.546463 994.974670,70.073082 
	C994.978455,74.926125 990.224670,79.820923 985.031372,79.945137 
	C978.392944,80.103920 971.743652,79.843933 965.107483,80.045082 
	C961.679382,80.149002 960.798279,78.640373 960.973083,75.574638 
	C961.142883,72.595444 961.006042,69.598770 961.003967,66.132362 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M468.742310,67.701126 
	C469.027222,75.652611 465.002899,79.929016 457.565491,79.984451 
	C451.422974,80.030235 445.277954,79.898827 439.137878,80.027634 
	C436.265137,80.087906 435.085754,79.233582 434.941010,76.112465 
	C434.193512,59.994999 434.108490,60.028679 450.328064,59.989666 
	C457.441986,59.972553 464.319580,60.395817 468.742310,67.701126 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M623.783447,90.606735 
	C619.330811,98.355942 612.705933,101.746254 604.344177,101.974152 
	C599.527954,102.105423 594.703918,101.935806 589.886414,102.040039 
	C587.235535,102.097389 585.953613,101.251389 585.978882,98.350899 
	C586.080322,86.716003 585.952759,75.078590 586.138306,63.445747 
	C586.156494,62.306179 587.786377,60.186348 588.592529,60.222198 
	C595.986267,60.550987 603.520569,60.512985 610.706299,62.034615 
	C620.174377,64.039551 624.959656,71.431221 626.489868,80.441246 
	C627.007141,83.486885 624.874573,86.982529 623.783447,90.606735 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1144.221191,85.366096 
	C1142.715942,68.733437 1153.320312,61.184002 1165.864014,59.165165 
	C1179.303833,57.002113 1191.546997,65.417442 1194.566040,78.009338 
	C1197.044189,88.345367 1187.831421,101.039558 1176.033569,102.761703 
	C1168.201904,103.904900 1160.518311,102.597855 1153.432495,98.713280 
	C1148.272583,95.884476 1145.526489,91.383263 1144.221191,85.366096 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1109.168213,173.692047 
	C1110.034912,175.420914 1110.677490,176.863571 1111.507080,178.726257 
	C1103.907349,178.726257 1097.009399,178.726257 1088.779663,178.726257 
	C1092.650269,170.868835 1096.156860,163.750626 1100.254761,155.431839 
	C1103.541016,162.229767 1106.242432,167.817810 1109.168213,173.692047 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M814.058228,86.997375 
	C810.951233,86.997375 808.322327,86.997375 804.016907,86.997375 
	C807.939941,79.085976 811.357117,72.194641 815.446228,63.948162 
	C819.340210,72.104950 822.620728,78.976677 826.449768,86.997383 
	C821.915649,86.997383 818.225952,86.997383 814.058228,86.997375 
z"
      />
    </svg>
  );
};

CaNordDeFranceSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaNordDeFranceSvg;
