import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { utils } from '@utocat/catalizr-const-sharing-lib';

// Components
import IconLegalNotice from '../../components/AssetPictos/components/iconLegalNotice/index';
import IconSupport from '../../components/AssetPictos/components/iconSupport/index';
import IconUser from '../../components/AssetPictos/components/iconUser/index';
import IconPower from '../../components/AssetPictos/components/iconPower/index';
import IconHelpCircle from '../../components/AssetPictos/components/iconHelpCircle/index';

// Utils
import { isFeatureEnabled } from '../../shared/utils/CatalizrUtils';
import { FAQ_URL, ROLE_TO_NAME } from '../../shared/utils/CatalizrConstants';
import { isUserBank, isUserCompany } from '../../shared/utils/JourneyUtils';
import { getEntityPathFromUserType } from '../../shared/utils/user';

import './styles.scss';
import { generateMailSupport } from './navbar.actions';

const NavbarAccount = props => {
  const { history } = props;
  const { email, firstname, lastname, type, title, siren, company_name } = props.user;

  const entityPath = getEntityPathFromUserType(type);
  // Adding quick start guide pdf path according to subdomain and user type
  const displayQuickStartGuide = isFeatureEnabled('display.quick_start_guide') || isUserCompany();
  const quickStartGuidePath = displayQuickStartGuide ? FAQ_URL[entityPath.toUpperCase()] : null;

  // testing the lastname and firstname is the only way to check if the account exists in the mysql db..
  // temporary hack waiting myaccount refactoring => prevent corporation investor to access to account
  const hasAccessToAccount =
    (type === 'investor' && firstname && lastname && !utils.users.isLegalEntity(title)) ||
    type === 'company';

  let identity = '';
  // For investor : display company name and siren if he is legal person
  if (type === 'investor' && utils.users.isLegalEntity(title)) {
    identity = `${company_name} (${siren})`;
  } else if (firstname && lastname) {
    identity = `${firstname} ${lastname}`;
  }

  const manageRedirection = icon => {
    let redirection = '';
    switch (icon) {
      case 'IconLegalNotice':
        redirection = '/legal-notice';
        break;
      case 'IconUser':
        redirection = '/account';
        break;
      case 'IconPower':
        redirection = '/login';
        break;
      case 'IconHelpCircle':
        redirection = quickStartGuidePath;
        break;
    }
    if (icon.includes('IconLegalNotice') || icon.includes('IconHelpCircle')) {
      return window.open(redirection);
    } else return history.push(redirection);
  };

  return (
    <div className="navbar__account">
      <div className="navbar__infos">
        <div className="type">
          {ROLE_TO_NAME[type] || `${type.charAt(0).toUpperCase()}${type.slice(1)}`}
        </div>
        {!isUserBank() && <div className="identity">{identity}</div>}
        <div className="email">{email}</div>
        {isFeatureEnabled('display.support_phone') && (
          <div>
            <div className="mt-1">Support Catalizr : 03 74 09 54 99</div>
          </div>
        )}
      </div>
      <div className="navbar__links">
        {displayQuickStartGuide && (
          <div className="icon" title="Guide / FAQ">
            <IconHelpCircle
              title="Aide"
              alt="Aide"
              width="30px"
              height="30px"
              manageRedirection={() => manageRedirection('IconHelpCircle')}
              dataTestId="legal-notice_icon"
            />
          </div>
        )}
        {hasAccessToAccount ? (
          <div className="icon" title="Mon compte">
            <IconUser
              title="Mon compte"
              alt="Mon compte"
              width="30px"
              height="30px"
              manageRedirection={() => manageRedirection('IconUser')}
              dataTestId="legal-notice_icon"
            />
          </div>
        ) : null}
        <div className="icon" title="Mentions Légales">
          <IconLegalNotice
            alt="Mentions Légales"
            title="Mentions Légales"
            width="30px"
            height="30px"
            manageRedirection={() => manageRedirection('IconLegalNotice')}
            dataTestId="legal-notice_icon"
          />
        </div>
        <div
          className="icon"
          title="Template Mail au Support Catalizr"
          onClick={() => generateMailSupport()}
        >
          <IconSupport
            alt="Template Mail au Support Catalizr"
            title="Template Mail au Support Catalizr"
            width="30px"
            height="30px"
          />
        </div>
      </div>
      <div className="navbar__disconnection" onClick={() => manageRedirection('IconPower')}>
        <IconPower alt="Déconnexion" title="Déconnexion" width="30px" height="30px" />
        Se déconnecter
      </div>
    </div>
  );
};

NavbarAccount.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    siren: PropTypes.string,
    company_name: PropTypes.string,
  }),
  history: PropTypes.object,
};

export default withRouter(
  connect(state => ({
    user: state.user,
  }))(NavbarAccount),
);
