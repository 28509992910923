import * as React from 'react';
import PropTypes from 'prop-types';

const CaAlsaceVosgesSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1385 255"
      enableBackground="new 0 0 1385 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M957.000000,256.000000 
	C638.000061,256.000000 319.500061,256.000000 1.000085,256.000000 
	C1.000057,171.000046 1.000057,86.000099 1.000028,1.000108 
	C462.666534,1.000072 924.333069,1.000072 1385.999756,1.000036 
	C1385.999756,85.999886 1385.999756,170.999771 1385.999878,255.999832 
	C1243.166626,256.000000 1100.333374,256.000000 957.000000,256.000000 
M551.108398,31.018597 
	C546.389954,31.064713 541.671448,31.110828 536.031738,31.011074 
	C367.747986,31.011074 199.464218,31.011074 31.158798,31.011074 
	C31.158798,61.066792 31.158798,90.521317 31.022179,120.903000 
	C31.067720,128.610062 31.113262,136.317108 31.011219,144.948090 
	C31.007481,170.483444 30.972076,196.018921 31.083876,221.553772 
	C31.090145,222.985443 32.309898,224.411804 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.569580,225.988754 1000.181213,225.988754 1355.840576,225.988754 
	C1355.840576,187.621002 1355.840576,149.828094 1355.981323,111.123169 
	C1355.934448,107.736908 1355.887573,104.350647 1355.988770,100.041985 
	C1355.988770,77.117699 1355.988770,54.193413 1355.988770,31.000000 
	C1353.845337,31.000000 1352.208496,31.000000 1350.571533,31.000000 
	C1086.054443,31.000000 821.537292,30.999710 557.020142,31.006586 
	C555.358398,31.006630 553.696594,31.106279 551.108398,31.018597 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M32.964851,225.840637 
	C32.309898,224.411804 31.090145,222.985443 31.083876,221.553772 
	C30.972076,196.018921 31.007481,170.483444 31.409367,144.646942 
	C32.205391,145.208511 32.717541,146.037491 32.983562,146.939117 
	C35.823238,156.563431 40.339314,165.561081 47.825882,172.040588 
	C68.000526,189.501434 92.502144,192.815659 117.716110,189.607101 
	C132.324295,187.748154 146.998734,183.884247 160.781036,178.651413 
	C181.564529,170.760406 201.593826,160.883026 222.249176,152.051559 
	C226.403061,161.524353 230.316742,170.769165 234.075699,180.076462 
	C236.380875,185.784134 239.400879,187.983826 245.699799,187.995590 
	C254.845383,188.012695 263.991028,188.000000 273.136658,188.000000 
	C275.335510,188.000000 277.534393,188.000000 280.511261,188.000000 
	C272.215881,170.705566 264.463806,154.480621 256.602600,138.308731 
	C256.121735,137.319534 254.777008,136.750275 253.871429,135.620361 
	C257.193695,133.189194 260.464355,131.099899 263.766388,129.061508 
	C277.418854,120.633743 291.094116,112.242699 304.711029,103.757980 
	C305.676636,103.156281 306.874786,101.917259 306.890320,100.957199 
	C307.050171,91.068390 306.988434,81.175987 306.988434,70.490662 
	C296.581482,76.317513 287.087463,81.749748 277.483765,86.980537 
	C265.709564,93.393494 253.843597,99.637932 241.985367,105.584625 
	C241.263489,103.362724 240.682526,101.461868 239.866486,99.667946 
	C232.820511,84.178627 225.572601,68.778030 218.782349,53.177982 
	C217.308487,49.791862 215.599670,48.869457 212.196671,48.933273 
	C202.206451,49.120617 192.205673,49.177055 182.219223,48.907486 
	C178.543015,48.808254 176.520935,50.102062 174.869690,53.248089 
	C166.877426,68.475082 158.741241,83.626740 150.619904,98.785721 
	C143.750565,111.607780 136.803360,124.388168 129.952667,137.220108 
	C127.974380,140.925568 126.210533,144.745529 124.010025,149.197540 
	C137.952606,148.485031 150.455215,145.990158 161.864899,139.552277 
	C165.181381,137.680954 169.092316,135.363968 170.698364,132.214767 
	C178.496216,116.924477 185.596039,101.278458 192.972198,85.772278 
	C193.447876,84.772285 194.168213,83.888672 195.304291,82.132172 
	C200.780655,96.478523 205.825912,109.695511 210.512726,122.962898 
	C193.226944,130.976501 176.709259,140.034027 159.247803,146.570419 
	C144.197998,152.204071 128.222107,155.762756 112.372757,158.725449 
	C101.264503,160.801880 89.649452,161.131973 78.320526,160.694305 
	C71.724815,160.439499 64.974304,157.748062 58.798725,155.012329 
	C53.442749,152.639694 52.403660,147.632446 53.610481,141.676880 
	C55.499218,132.356094 60.251907,124.424774 66.213814,117.504143 
	C83.457497,97.487564 105.103958,84.232147 130.862366,78.078827 
	C138.402466,76.277603 146.153839,75.360786 154.080902,73.991539 
	C152.725067,69.171196 151.684692,64.192177 149.923721,59.482609 
	C146.431778,50.143673 142.629456,44.735851 131.567001,46.087990 
	C124.455742,46.957184 117.289955,48.129620 110.424950,50.119507 
	C91.928406,55.480911 75.182961,64.466843 60.613991,77.041275 
	C47.105671,88.700264 36.951733,102.903267 31.158798,119.975845 
	C31.158798,90.521317 31.158798,61.066792 31.158798,31.011074 
	C199.464218,31.011074 367.747986,31.011074 536.350464,31.382301 
	C534.287415,35.105972 531.905640,38.458416 529.281555,42.151962 
	C540.583191,44.477863 547.068726,41.118561 552.034851,31.159416 
	C553.696594,31.106279 555.358398,31.006630 557.020142,31.006586 
	C821.537292,30.999710 1086.054443,31.000000 1350.571533,31.000000 
	C1352.208496,31.000000 1353.845337,31.000000 1355.988770,31.000000 
	C1355.988770,54.193413 1355.988770,77.117699 1355.456665,100.515289 
	C1343.099609,100.992157 1331.274780,100.983833 1319.449829,101.005173 
	C1314.782349,101.013596 1311.755859,98.774605 1311.116455,94.197556 
	C1310.668701,90.992500 1311.030151,87.674385 1311.030151,83.930115 
	C1324.617676,83.930115 1337.532227,83.930115 1350.596191,83.930115 
	C1350.596191,80.389282 1350.596191,77.363503 1350.596191,73.837845 
	C1337.287354,73.837845 1324.374634,73.837845 1311.449463,73.837845 
	C1311.449463,68.625793 1311.449463,63.927708 1311.449463,58.747478 
	C1325.807495,58.747478 1339.721191,58.747478 1353.682983,58.747478 
	C1353.682983,54.994511 1353.682983,51.747894 1353.682983,48.421783 
	C1334.675049,48.421783 1315.987061,48.421783 1297.000000,48.421783 
	C1297.000000,63.816795 1296.998413,78.791954 1297.000488,93.767113 
	C1297.002197,106.006691 1303.018066,111.981491 1315.369629,111.998451 
	C1328.859985,112.016975 1342.350220,112.023460 1355.840576,112.035194 
	C1355.840576,149.828094 1355.840576,187.621002 1355.840576,225.988754 
	C1000.181213,225.988754 644.569580,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
M642.998657,59.498508 
	C638.833923,56.922127 634.938477,53.611176 630.434570,51.960068 
	C625.111816,50.008812 619.299255,48.794254 613.635315,48.503952 
	C603.256104,47.971973 592.830139,48.352413 582.284424,48.352413 
	C582.284424,69.619896 582.284424,90.521080 582.284424,112.156609 
	C597.733398,111.176643 613.059082,114.026016 628.021179,109.840004 
	C653.018982,102.846260 658.479919,75.764061 642.998657,59.498508 
M493.997803,65.886452 
	C493.363525,60.474686 491.153015,55.671284 486.024780,53.635384 
	C480.479431,51.433887 474.542633,49.342731 468.674530,49.011547 
	C456.326874,48.314659 443.912537,48.799778 431.419495,48.799778 
	C431.419495,69.651894 431.419495,90.675697 431.419495,111.640305 
	C436.090057,111.640305 440.335602,111.640305 445.083435,111.640305 
	C445.083435,104.372849 445.083435,97.453217 445.083435,90.000000 
	C448.365601,90.000000 451.168671,90.176140 453.940918,89.958839 
	C458.130371,89.630440 461.774017,90.148766 464.484406,93.917587 
	C466.983368,97.392365 469.720093,100.714142 472.555756,103.923332 
	C475.033386,106.727295 477.390656,110.732964 480.524994,111.604561 
	C485.228638,112.912567 490.565094,111.944862 496.935394,111.944862 
	C489.772339,103.488518 483.497009,96.080154 477.104095,88.533005 
	C492.524902,81.513298 494.000793,79.560516 493.997803,65.886452 
M1012.458557,84.032249 
	C1013.947754,82.758537 1015.809021,81.735779 1016.865112,80.169975 
	C1023.266357,70.679939 1021.554260,53.942547 1004.658997,50.117893 
	C989.298035,46.640579 973.651184,48.132404 958.099915,48.231625 
	C957.843262,48.233261 957.588989,48.605427 957.309021,48.823849 
	C957.309021,69.699326 957.309021,90.587090 957.309021,111.580063 
	C961.987366,111.580063 966.343567,111.580063 971.194031,111.580063 
	C971.194031,104.253487 971.194031,97.341583 971.194031,90.000000 
	C974.487305,90.000000 977.300781,90.201027 980.074219,89.953209 
	C984.306763,89.575005 987.872253,90.306427 990.563904,94.006081 
	C993.079712,97.464066 995.788635,100.800056 998.627747,103.998779 
	C1001.102966,106.787651 1003.480713,110.746613 1006.614014,111.613571 
	C1011.301697,112.910599 1016.613831,111.950577 1022.974304,111.950577 
	C1015.783081,103.503136 1009.522644,96.149078 1003.077637,88.578224 
	C1006.294800,87.098343 1009.100647,85.807648 1012.458557,84.032249 
M940.749939,200.917648 
	C959.547424,209.482162 985.514221,204.526505 995.820923,188.673218 
	C1004.825073,174.823547 1001.951233,159.105850 989.954529,149.117630 
	C978.468811,139.554886 964.701538,137.235291 950.293396,140.178421 
	C938.319214,142.624374 927.896667,148.295639 923.172913,160.265930 
	C916.521484,177.121078 923.140381,192.056870 940.749939,200.917648 
M1147.504272,60.068718 
	C1147.191162,60.651360 1146.952393,61.291355 1146.553955,61.808258 
	C1136.934570,74.289398 1138.017944,90.130196 1148.845825,101.418144 
	C1163.124878,116.304039 1196.130493,117.639145 1211.944946,100.764069 
	C1223.641968,88.282417 1223.064209,67.671242 1209.006714,56.789757 
	C1191.478027,43.221336 1164.799072,43.740540 1147.504272,60.068718 
M1065.136841,201.893600 
	C1067.586182,200.522354 1070.163940,199.336563 1072.461182,197.745651 
	C1079.927002,192.575302 1081.154419,181.381714 1074.561035,175.268265 
	C1071.631958,172.552292 1067.855591,170.221405 1064.054077,169.079971 
	C1054.775757,166.294083 1045.212646,164.470322 1035.864502,161.895340 
	C1032.954468,161.093781 1030.365601,159.126129 1027.629761,157.692307 
	C1027.688110,157.021927 1027.746460,156.351547 1027.804810,155.681152 
	C1030.818604,154.171173 1033.681396,152.059311 1036.873169,151.258270 
	C1048.956299,148.225800 1059.910645,151.856003 1070.753784,158.383362 
	C1073.037476,155.028198 1075.172607,151.891357 1077.454102,148.539261 
	C1068.904663,143.400650 1060.645996,140.733658 1051.586792,139.610916 
	C1039.938477,138.167267 1029.184570,139.869995 1019.442688,146.346954 
	C1012.033936,151.272659 1010.742859,162.285324 1017.606506,167.709381 
	C1022.457703,171.543030 1028.763550,173.866501 1034.756592,175.844421 
	C1043.005981,178.567047 1051.615479,180.182068 1059.966309,182.628357 
	C1061.537109,183.088531 1063.684448,185.252350 1063.643799,186.570770 
	C1063.597778,188.065781 1061.624878,190.381348 1060.079224,190.790787 
	C1054.718506,192.210815 1048.963257,194.413193 1043.756714,193.638504 
	C1034.803345,192.306305 1026.149780,188.958771 1016.404785,186.181992 
	C1015.159546,187.967255 1013.029297,191.021179 1010.356628,194.852737 
	C1027.802246,204.633270 1045.372559,208.207825 1065.136841,201.893600 
M503.108704,187.687973 
	C501.662415,190.024597 500.216095,192.361206 498.612885,194.951279 
	C499.934570,195.770691 500.809631,196.577652 501.837982,196.909073 
	C510.256592,199.622360 518.541565,203.386398 527.182373,204.616425 
	C538.537109,206.232742 549.982910,204.995041 559.967896,198.027344 
	C568.824097,191.847321 569.036865,178.783157 559.933594,173.111313 
	C555.674805,170.457825 550.815979,168.496948 545.974854,167.108627 
	C537.619080,164.712387 529.019287,163.174377 520.631470,160.876282 
	C518.909546,160.404510 517.668945,158.176086 516.206482,156.757370 
	C517.585083,155.466003 518.750916,153.721451 520.377258,152.957596 
	C530.191162,148.348190 539.960938,149.761612 549.512878,153.867508 
	C559.147827,158.009048 559.108032,158.111893 564.722046,149.414627 
	C564.863525,149.195541 564.710388,148.786316 564.680908,148.185654 
	C563.850098,147.662964 562.910706,147.021881 561.925903,146.460388 
	C554.623474,142.296768 546.669189,140.562378 538.378784,139.585083 
	C526.945068,138.237259 516.211121,139.927399 506.930695,146.804962 
	C500.313354,151.708923 497.693420,162.538910 505.797089,168.038223 
	C510.842926,171.462418 516.733582,173.936249 522.572083,175.826508 
	C530.686462,178.453598 539.157776,179.961670 547.356873,182.357437 
	C549.098816,182.866440 551.114502,184.950836 551.510864,186.656418 
	C551.768982,187.767059 549.429810,189.613678 548.050903,190.915192 
	C547.386230,191.542526 546.268738,191.725235 545.328247,192.019958 
	C532.124023,196.158081 519.909729,193.058502 508.357086,186.524475 
	C506.106201,185.251404 504.927124,185.059402 503.108704,187.687973 
M887.065857,69.815659 
	C891.307739,66.526039 895.125488,62.301262 899.881104,60.144680 
	C909.296875,55.874851 918.504822,57.876820 927.154602,63.385338 
	C928.539307,64.267151 931.562805,64.408707 932.675415,63.492325 
	C934.807983,61.735600 936.112854,58.973881 937.768372,56.618797 
	C925.651489,47.924358 912.644104,45.666233 898.779053,48.251263 
	C890.694153,49.758636 883.203430,53.180077 877.483459,59.152889 
	C864.356262,72.860580 867.103943,94.923729 882.758240,105.957260 
	C897.526001,116.365952 924.324097,114.932327 938.684143,104.811684 
	C939.562744,104.192551 940.828125,103.234055 940.842834,102.414207 
	C940.999939,93.684235 940.940430,84.950371 940.940430,76.000000 
	C934.766113,76.000000 929.444397,76.000000 924.122620,76.000000 
	C918.862793,76.000000 913.602966,76.000000 908.321655,76.000000 
	C908.321655,80.016144 908.321655,83.262505 908.321655,87.177002 
	C914.671875,87.177002 920.721619,87.177002 926.674316,87.177002 
	C927.617004,98.220078 927.239075,99.003159 917.270813,101.240479 
	C913.616516,102.060677 909.699768,102.112228 905.928650,101.941414 
	C890.550293,101.244812 878.682861,84.917778 887.065857,69.815659 
M1270.859985,175.876068 
	C1272.964478,176.259232 1275.110352,176.491364 1277.166016,177.053177 
	C1283.967163,178.912003 1290.796875,180.709457 1297.469727,182.962036 
	C1298.942139,183.459061 1300.774292,185.780441 1300.701416,187.170502 
	C1300.625122,188.624069 1298.624268,190.930405 1297.165405,191.191330 
	C1291.104370,192.275375 1284.746704,193.977936 1278.841797,193.111282 
	C1270.555420,191.895142 1262.574585,188.596497 1254.008911,186.053757 
	C1252.436523,188.344406 1250.270386,191.499939 1247.938110,194.897507 
	C1266.476562,205.146454 1284.994141,208.695816 1304.595581,200.941849 
	C1315.730103,196.537262 1320.287720,183.264938 1312.487915,175.747787 
	C1308.025024,171.446548 1301.188232,169.178818 1295.037598,167.163773 
	C1286.785889,164.460266 1278.132202,163.003571 1269.731689,160.715286 
	C1268.294189,160.323685 1266.192627,158.353378 1266.291504,157.288437 
	C1266.436523,155.729645 1268.037842,153.428360 1269.464600,153.026260 
	C1274.322266,151.657211 1279.596313,149.604309 1284.302612,150.359772 
	C1291.616211,151.533783 1298.572876,154.866348 1305.735840,157.108139 
	C1306.871094,157.463455 1308.903564,157.384232 1309.474854,156.669510 
	C1311.471191,154.172150 1313.008667,151.308182 1314.781616,148.486374 
	C1306.655762,143.066635 1298.020752,141.061676 1288.908691,139.688324 
	C1278.078003,138.055954 1268.136353,139.939575 1258.901611,145.186508 
	C1253.675171,148.156036 1250.460693,153.001450 1250.966431,159.622314 
	C1251.425049,165.625824 1254.662231,169.331253 1260.014160,171.508102 
	C1263.372070,172.873932 1266.741699,174.211121 1270.859985,175.876068 
M1136.554077,193.203156 
	C1131.006348,193.093216 1125.257935,193.873642 1119.954834,192.678391 
	C1110.436523,190.533035 1102.416626,177.944839 1104.228638,169.616226 
	C1106.419189,159.547989 1112.798096,153.723969 1122.535278,151.003967 
	C1131.844116,148.403625 1140.112549,150.804626 1148.075073,155.552490 
	C1149.160645,156.199799 1151.381470,156.801163 1151.862915,156.287125 
	C1154.120972,153.876007 1155.954468,151.067429 1158.144897,148.125137 
	C1139.902588,137.028564 1122.073608,135.927643 1104.033813,146.040680 
	C1084.828491,156.807098 1084.514771,184.826248 1102.224365,197.148026 
	C1119.002686,208.821884 1142.541138,206.379776 1158.143921,197.234161 
	C1159.297974,196.557693 1160.813965,195.189072 1160.841797,194.108185 
	C1161.063354,185.529373 1160.965210,176.942307 1160.965210,168.311218 
	C1149.656006,168.311218 1139.083496,168.311218 1128.427979,168.311218 
	C1128.427979,171.658707 1128.427979,174.684830 1128.427979,178.209137 
	C1134.766602,178.209137 1140.682373,178.209137 1146.542969,178.209137 
	C1147.460327,191.007721 1148.474731,189.943497 1136.554077,193.203156 
M793.326111,193.000122 
	C782.672913,192.999756 772.019714,192.974335 761.366638,193.011856 
	C757.319214,193.026123 754.501648,191.358566 754.101624,187.192764 
	C753.759277,183.627960 754.030701,180.004227 754.030701,175.933838 
	C767.282043,175.933838 779.857727,175.933838 792.604614,175.933838 
	C792.604614,172.070694 792.604614,168.712402 792.604614,164.860962 
	C779.642822,164.860962 767.067688,164.860962 754.431824,164.860962 
	C754.431824,159.994217 754.431824,155.633087 754.431824,150.790863 
	C768.765381,150.790863 782.673035,150.790863 796.711487,150.790863 
	C796.711487,147.049271 796.711487,143.805161 796.711487,140.382294 
	C777.407532,140.382294 758.394043,140.382294 739.026367,140.382294 
	C739.026367,156.550476 738.780273,172.344513 739.159485,188.123505 
	C739.292725,193.669052 741.887024,198.697296 747.172119,201.492462 
	C749.318909,202.627853 751.794312,203.771133 754.136292,203.805374 
	C768.564941,204.016159 782.998230,203.911118 797.994080,203.911118 
	C797.994080,200.994827 797.860962,198.538651 798.028198,196.103119 
	C798.230286,193.159241 796.524780,192.825150 793.326111,193.000122 
M554.422363,74.000000 
	C544.022034,74.000000 533.621704,74.000000 523.392334,74.000000 
	C523.392334,68.502510 523.392334,63.927067 523.392334,58.860241 
	C537.690186,58.860241 551.603455,58.860241 565.561157,58.860241 
	C565.561157,54.986572 565.561157,51.626614 565.561157,48.364967 
	C546.217590,48.364967 527.314819,48.364967 508.024414,48.364967 
	C508.024414,64.519485 507.568024,80.330048 508.187286,96.098373 
	C508.589813,106.348640 515.691162,111.981079 526.043518,111.996033 
	C537.868591,112.013115 549.693726,112.009987 561.518799,111.989067 
	C563.270569,111.985970 565.022156,111.836861 566.622559,111.762344 
	C566.622559,107.869850 566.622559,104.624039 566.622559,101.000000 
	C564.442505,101.000000 562.637207,101.000038 560.831909,100.999985 
	C550.672302,100.999680 540.512512,101.040176 530.353271,100.976784 
	C526.815247,100.954720 523.504028,100.004311 523.090271,95.843124 
	C522.718140,92.100273 523.011780,88.291229 523.011780,83.854881 
	C536.305176,83.854881 549.012939,83.854881 561.646118,83.854881 
	C561.646118,80.256935 561.646118,77.341293 561.646118,74.000000 
	C559.330200,74.000000 557.364014,74.000000 554.422363,74.000000 
M1221.491211,140.000000 
	C1207.085693,140.000000 1192.680054,140.000000 1178.000000,140.000000 
	C1178.000000,155.840302 1178.598022,170.853287 1177.801025,185.791824 
	C1177.217407,196.731415 1185.491699,204.283020 1196.400024,204.053146 
	C1207.723022,203.814529 1219.055054,204.000000 1230.383057,204.000000 
	C1232.138916,204.000000 1233.894775,204.000000 1235.670166,204.000000 
	C1235.670166,199.969711 1235.670166,196.721985 1235.670166,193.000000 
	C1233.551025,193.000000 1231.756958,193.000031 1229.962891,192.999985 
	C1220.134155,192.999771 1210.305420,192.982178 1200.476685,193.006897 
	C1195.809937,193.018631 1192.769531,190.796585 1192.119507,186.221085 
	C1191.664062,183.015152 1192.031738,179.692291 1192.031738,175.941406 
	C1205.603516,175.941406 1218.520020,175.941406 1231.600220,175.941406 
	C1231.600220,172.065842 1231.600220,168.705658 1231.600220,164.842133 
	C1218.293091,164.842133 1205.377808,164.842133 1192.450317,164.842133 
	C1192.450317,159.960236 1192.450317,155.595016 1192.450317,150.742767 
	C1206.811523,150.742767 1220.726929,150.742767 1234.677979,150.742767 
	C1234.677979,146.985260 1234.677979,143.737869 1234.677979,140.000000 
	C1230.408691,140.000000 1226.448120,140.000000 1221.491211,140.000000 
M383.521057,189.000000 
	C385.850616,189.000000 388.241669,189.344101 390.499207,188.940811 
	C400.632202,187.130707 407.676361,190.104675 410.502136,200.755661 
	C410.851776,202.073425 412.893524,203.719086 414.274139,203.843079 
	C418.684967,204.239090 423.153748,203.990005 428.566071,203.990005 
	C427.231689,201.007294 426.360107,198.772812 425.263977,196.654541 
	C417.059479,180.799301 408.400055,165.161346 400.767670,149.036682 
	C397.487854,142.107559 393.798737,138.662659 385.882324,139.821976 
	C382.754333,140.280060 380.980774,140.887909 379.490967,143.835480 
	C373.828186,155.039490 367.748596,166.031891 361.974182,177.180771 
	C357.513702,185.792816 353.276459,194.520447 348.538452,204.000000 
	C351.908905,204.000000 354.389099,203.711807 356.776520,204.059998 
	C361.744659,204.784576 365.140778,203.227493 366.379425,198.172348 
	C368.538452,189.360947 374.687988,187.722473 383.521057,189.000000 
M793.501831,97.997360 
	C791.333496,102.400017 789.165222,106.802681 786.938782,111.323395 
	C799.678650,113.697769 800.880127,113.131439 806.125977,102.629723 
	C806.496155,101.888657 806.953857,101.179955 807.231689,100.406235 
	C808.224915,97.640373 810.150940,96.842857 813.025208,96.939346 
	C819.011475,97.140305 825.114929,97.709557 830.977722,96.835793 
	C840.056335,95.482735 845.181763,98.991280 847.847168,107.414360 
	C848.384155,109.111336 849.976685,111.581497 851.296692,111.724937 
	C856.093201,112.246117 860.980591,111.930557 865.720459,111.930557 
	C865.818604,110.652107 865.977844,110.255440 865.849854,110.006516 
	C856.849365,92.501915 847.457642,75.184998 838.979248,57.431835 
	C835.412598,49.963531 830.950989,46.216129 822.749939,48.075462 
	C821.093384,48.451046 818.937073,49.404549 818.225098,50.739189 
	C809.975952,66.204460 801.960144,81.794266 793.501831,97.997360 
M594.882385,160.354767 
	C587.444336,174.607025 580.006348,188.859283 572.416016,203.403366 
	C586.785339,205.478363 586.985413,205.381668 592.673340,193.680130 
	C593.381897,192.222443 593.987366,190.714645 594.741821,189.000000 
	C601.589905,189.000000 608.501465,189.747482 615.182251,188.824722 
	C625.192993,187.442032 631.585876,190.438217 634.333313,200.502731 
	C634.711487,201.888092 636.632263,203.696854 637.975708,203.820251 
	C642.497437,204.235580 647.080933,203.978241 651.658325,203.978241 
	C651.610718,202.920258 651.699402,202.550949 651.567627,202.295456 
	C642.166809,184.075455 632.353088,166.052643 623.548035,147.549774 
	C620.508057,141.161606 616.913513,139.411346 610.346619,139.788773 
	C606.161987,140.029282 604.091675,141.608139 602.470825,144.995117 
	C600.112671,149.922714 597.577637,154.765686 594.882385,160.354767 
M366.721344,107.909691 
	C384.857391,116.299965 402.122864,114.502747 419.246399,103.721458 
	C417.683594,101.696129 416.194275,100.282555 415.341370,98.554840 
	C413.477234,94.778664 411.570221,94.588196 407.975037,96.870300 
	C399.158630,102.466705 389.616821,103.853355 379.523499,100.266495 
	C370.218384,96.959755 364.058502,89.311501 364.001587,80.596558 
	C363.944733,71.898354 370.551208,63.162384 379.364960,60.001274 
	C389.071869,56.519821 398.118805,57.706112 406.818604,63.154079 
	C408.256165,64.054306 411.115875,64.818253 411.984924,64.078545 
	C414.298218,62.109497 415.883820,59.285549 417.904358,56.608658 
	C402.916260,46.391838 387.239777,44.624332 370.654205,50.758678 
	C358.477295,55.262421 350.909607,63.791420 349.191895,76.869301 
	C347.983246,86.071129 351.885498,100.186066 366.721344,107.909691 
M702.725647,139.831894 
	C693.613159,137.613892 684.938354,139.858032 676.722656,143.296722 
	C665.825806,147.857635 658.443054,155.968033 657.110535,168.102020 
	C655.779846,180.219055 659.947327,190.319824 670.433411,197.126160 
	C687.161865,207.984253 704.354187,207.266113 721.703064,198.648743 
	C724.183228,197.416809 727.542664,196.071289 724.701416,192.532211 
	C719.578247,186.150894 721.055359,185.606064 715.819153,188.801010 
	C706.277832,194.622787 696.274963,195.937134 685.950989,191.831787 
	C674.405090,187.240555 666.919006,173.410202 675.782349,160.480225 
	C681.027954,152.827881 693.693604,147.933167 703.060364,150.632095 
	C708.517517,152.204483 713.720154,154.659882 719.354004,156.840622 
	C721.207397,154.279785 723.182068,151.551285 725.370972,148.526855 
	C718.543823,143.526855 711.605713,140.425766 702.725647,139.831894 
M1129.411499,96.008171 
	C1127.978638,96.117508 1126.242432,95.740707 1125.161987,96.414886 
	C1118.010864,100.877182 1110.338257,102.625923 1101.941650,102.041077 
	C1090.593628,101.250664 1080.292358,91.768288 1080.000366,81.497887 
	C1079.588623,67.017288 1095.119629,54.774700 1109.696777,58.465958 
	C1115.656006,59.974987 1121.348511,62.537781 1127.251099,64.652969 
	C1128.412964,63.359833 1129.750732,61.910507 1131.044312,60.422581 
	C1133.419434,57.690376 1133.211060,55.665245 1129.737305,53.869057 
	C1111.497559,44.437775 1093.667480,44.593418 1076.510864,56.286015 
	C1064.832764,64.244759 1061.441284,83.692444 1068.986694,95.538315 
	C1082.224609,116.321396 1115.066772,118.221001 1134.311157,103.467918 
	C1132.841431,101.205139 1131.347168,98.904526 1129.411499,96.008171 
M892.514893,158.978424 
	C887.966187,168.383362 883.417542,177.788284 878.288940,188.392273 
	C876.588928,185.041031 875.595825,183.188995 874.696228,181.292618 
	C868.743896,168.744736 862.863037,156.162415 856.803162,143.666794 
	C856.100769,142.218353 854.526733,140.323730 853.209595,140.199783 
	C848.656433,139.771347 844.038147,140.034714 838.797546,140.034714 
	C839.527161,141.766418 839.908081,142.811417 840.394775,143.804626 
	C848.770874,160.900192 857.529968,177.822296 865.363403,195.162628 
	C868.480591,202.062775 872.170776,205.217529 879.872253,204.182281 
	C883.191467,203.736115 885.104614,203.060471 886.633728,199.933655 
	C895.658264,181.479691 904.911438,163.137512 914.079163,144.753464 
	C914.759399,143.389465 915.372681,141.992096 916.301575,140.000000 
	C912.162720,140.000000 908.983826,140.261612 905.868286,139.929199 
	C902.499084,139.569717 900.744751,140.859238 899.493774,143.902679 
	C897.485596,148.788239 895.111633,153.523468 892.514893,158.978424 
M242.169128,65.989937 
	C240.539581,65.989937 238.910034,65.989937 236.349762,65.989937 
	C238.058731,70.444275 239.531815,74.180046 240.928589,77.944138 
	C244.859970,88.538673 249.856445,90.756226 259.717499,85.620880 
	C274.504059,77.920502 289.154846,69.957001 303.780945,61.954769 
	C305.151855,61.204731 306.800140,59.408428 306.847290,58.046894 
	C307.132507,49.813107 306.989288,41.564476 306.989288,32.416443 
	C300.663849,35.870411 295.173737,38.990772 289.574890,41.902039 
	C274.076141,49.961006 258.532043,57.932762 242.169128,65.989937 
M730.000000,63.666107 
	C730.111450,62.231483 730.222961,60.796864 730.371277,58.888557 
	C738.325989,58.888557 745.909607,58.888557 753.572266,58.888557 
	C753.572266,55.009327 753.572266,51.649151 753.572266,48.353603 
	C732.903870,48.353603 712.665161,48.353603 692.292847,48.353603 
	C692.292847,51.957226 692.292847,55.202400 692.292847,59.128632 
	C700.275513,59.128632 707.988037,59.128632 716.172241,59.128632 
	C716.172241,77.054176 716.172241,94.298882 716.172241,111.543121 
	C721.048157,111.543121 725.411438,111.543121 730.000000,111.543121 
	C730.000000,95.602898 730.000000,80.118355 730.000000,63.666107 
M1248.000000,65.501312 
	C1248.000000,59.749340 1248.000000,53.997364 1248.000000,48.375462 
	C1242.546875,48.375462 1237.967041,48.375462 1233.316650,48.375462 
	C1233.316650,69.664299 1233.316650,90.576202 1233.316650,111.568878 
	C1251.669312,111.568878 1269.702026,111.568878 1287.625000,111.568878 
	C1287.625000,107.883308 1287.625000,104.633881 1287.625000,100.883568 
	C1274.339844,100.883568 1261.419922,100.883568 1248.000000,100.883568 
	C1248.000000,89.083946 1248.000000,77.792305 1248.000000,65.501312 
M471.499969,204.000000 
	C478.919708,204.000000 486.339478,204.000000 493.625671,204.000000 
	C493.625671,199.884491 493.625671,196.635071 493.625671,192.884766 
	C480.341034,192.884766 467.422791,192.884766 453.826050,192.884766 
	C453.826050,175.006683 453.826050,157.634430 453.826050,140.374954 
	C448.879272,140.374954 444.633575,140.374954 440.316284,140.374954 
	C440.316284,161.663254 440.316284,182.575119 440.316284,204.000000 
	C450.582733,204.000000 460.541351,204.000000 471.499969,204.000000 
M1036.000000,88.499969 
	C1036.000000,96.237823 1036.000000,103.975677 1036.000000,111.653168 
	C1041.409058,111.653168 1045.981567,111.653168 1050.584717,111.653168 
	C1050.584717,90.305725 1050.584717,69.307816 1050.584717,48.346443 
	C1045.591675,48.346443 1041.019165,48.346443 1036.000000,48.346443 
	C1036.000000,61.625145 1036.000000,74.562569 1036.000000,88.499969 
M677.182861,111.950035 
	C677.788574,110.297920 678.909180,108.649429 678.920837,106.993149 
	C679.047424,89.026123 679.005737,71.057854 678.990173,53.089951 
	C678.988770,51.498119 678.848938,49.906410 678.774231,48.332756 
	C673.956726,48.332756 669.714966,48.332756 665.410278,48.332756 
	C665.410278,69.672539 665.410278,90.676987 665.410278,111.991631 
	C669.293579,111.991631 672.771729,111.991631 677.182861,111.950035 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M242.017303,105.955231 
	C253.843597,99.637932 265.709564,93.393494 277.483765,86.980537 
	C287.087463,81.749748 296.581482,76.317513 306.988434,70.490662 
	C306.988434,81.175987 307.050171,91.068390 306.890320,100.957199 
	C306.874786,101.917259 305.676636,103.156281 304.711029,103.757980 
	C291.094116,112.242699 277.418854,120.633743 263.766388,129.061508 
	C260.464355,131.099899 257.193695,133.189194 253.485077,135.605621 
	C242.777191,140.937317 232.491058,145.914810 222.222061,150.927368 
	C222.025894,151.023132 222.031982,151.533203 221.943695,151.849945 
	C201.593826,160.883026 181.564529,170.760406 160.781036,178.651413 
	C146.998734,183.884247 132.324295,187.748154 117.716110,189.607101 
	C92.502144,192.815659 68.000526,189.501434 47.825882,172.040588 
	C40.339314,165.561081 35.823238,156.563431 32.983562,146.939117 
	C32.717541,146.037491 32.205391,145.208511 31.483158,144.184982 
	C31.113262,136.317108 31.067720,128.610062 31.090488,120.439423 
	C36.951733,102.903267 47.105671,88.700264 60.613991,77.041275 
	C75.182961,64.466843 91.928406,55.480911 110.424950,50.119507 
	C117.289955,48.129620 124.455742,46.957184 131.567001,46.087990 
	C142.629456,44.735851 146.431778,50.143673 149.923721,59.482609 
	C151.684692,64.192177 152.725067,69.171196 154.080902,73.991539 
	C146.153839,75.360786 138.402466,76.277603 130.862366,78.078827 
	C105.103958,84.232147 83.457497,97.487564 66.213814,117.504143 
	C60.251907,124.424774 55.499218,132.356094 53.610481,141.676880 
	C52.403660,147.632446 53.442749,152.639694 58.798725,155.012329 
	C64.974304,157.748062 71.724815,160.439499 78.320526,160.694305 
	C89.649452,161.131973 101.264503,160.801880 112.372757,158.725449 
	C128.222107,155.762756 144.197998,152.204071 159.247803,146.570419 
	C176.709259,140.034027 193.226944,130.976501 210.856293,122.871277 
	C220.741730,117.964371 229.938675,113.225098 239.090897,108.401001 
	C240.190567,107.821365 241.047729,106.781685 242.017303,105.955231 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1355.910889,111.579178 
	C1342.350220,112.023460 1328.859985,112.016975 1315.369629,111.998451 
	C1303.018066,111.981491 1297.002197,106.006691 1297.000488,93.767113 
	C1296.998413,78.791954 1297.000000,63.816795 1297.000000,48.421783 
	C1315.987061,48.421783 1334.675049,48.421783 1353.682983,48.421783 
	C1353.682983,51.747894 1353.682983,54.994511 1353.682983,58.747478 
	C1339.721191,58.747478 1325.807495,58.747478 1311.449463,58.747478 
	C1311.449463,63.927708 1311.449463,68.625793 1311.449463,73.837845 
	C1324.374634,73.837845 1337.287354,73.837845 1350.596191,73.837845 
	C1350.596191,77.363503 1350.596191,80.389282 1350.596191,83.930115 
	C1337.532227,83.930115 1324.617676,83.930115 1311.030151,83.930115 
	C1311.030151,87.674385 1310.668701,90.992500 1311.116455,94.197556 
	C1311.755859,98.774605 1314.782349,101.013596 1319.449829,101.005173 
	C1331.274780,100.983833 1343.099609,100.992157 1355.382568,100.976494 
	C1355.887573,104.350647 1355.934448,107.736908 1355.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M551.571655,31.089006 
	C547.068726,41.118561 540.583191,44.477863 529.281555,42.151962 
	C531.905640,38.458416 534.287415,35.105972 536.811096,31.455235 
	C541.671448,31.110828 546.389954,31.064713 551.571655,31.089006 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.985367,105.584625 
	C241.047729,106.781685 240.190567,107.821365 239.090897,108.401001 
	C229.938675,113.225098 220.741730,117.964371 211.214737,122.820877 
	C205.825912,109.695511 200.780655,96.478523 195.304291,82.132172 
	C194.168213,83.888672 193.447876,84.772285 192.972198,85.772278 
	C185.596039,101.278458 178.496216,116.924477 170.698364,132.214767 
	C169.092316,135.363968 165.181381,137.680954 161.864899,139.552277 
	C150.455215,145.990158 137.952606,148.485031 124.010025,149.197540 
	C126.210533,144.745529 127.974380,140.925568 129.952667,137.220108 
	C136.803360,124.388168 143.750565,111.607780 150.619904,98.785721 
	C158.741241,83.626740 166.877426,68.475082 174.869690,53.248089 
	C176.520935,50.102062 178.543015,48.808254 182.219223,48.907486 
	C192.205673,49.177055 202.206451,49.120617 212.196671,48.933273 
	C215.599670,48.869457 217.308487,49.791862 218.782349,53.177982 
	C225.572601,68.778030 232.820511,84.178627 239.866486,99.667946 
	C240.682526,101.461868 241.263489,103.362724 241.985367,105.584625 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M643.254944,59.754345 
	C658.479919,75.764061 653.018982,102.846260 628.021179,109.840004 
	C613.059082,114.026016 597.733398,111.176643 582.284424,112.156609 
	C582.284424,90.521080 582.284424,69.619896 582.284424,48.352413 
	C592.830139,48.352413 603.256104,47.971973 613.635315,48.503952 
	C619.299255,48.794254 625.111816,50.008812 630.434570,51.960068 
	C634.938477,53.611176 638.833923,56.922127 643.254944,59.754345 
M597.000000,86.495285 
	C597.000000,91.235916 597.000000,95.976555 597.000000,101.029350 
	C607.454590,100.472031 617.330078,102.826157 626.386414,97.300941 
	C632.995056,93.269028 637.065857,87.390060 636.734985,79.901329 
	C636.415405,72.669899 633.142883,66.415810 625.887329,62.886509 
	C616.713074,58.423927 607.133240,58.964077 597.000000,59.161236 
	C597.000000,68.285896 597.000000,76.891167 597.000000,86.495285 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M493.998718,66.329750 
	C494.000793,79.560516 492.524902,81.513298 477.104095,88.533005 
	C483.497009,96.080154 489.772339,103.488518 496.935394,111.944862 
	C490.565094,111.944862 485.228638,112.912567 480.524994,111.604561 
	C477.390656,110.732964 475.033386,106.727295 472.555756,103.923332 
	C469.720093,100.714142 466.983368,97.392365 464.484406,93.917587 
	C461.774017,90.148766 458.130371,89.630440 453.940918,89.958839 
	C451.168671,90.176140 448.365601,90.000000 445.083435,90.000000 
	C445.083435,97.453217 445.083435,104.372849 445.083435,111.640305 
	C440.335602,111.640305 436.090057,111.640305 431.419495,111.640305 
	C431.419495,90.675697 431.419495,69.651894 431.419495,48.799778 
	C443.912537,48.799778 456.326874,48.314659 468.674530,49.011547 
	C474.542633,49.342731 480.479431,51.433887 486.024780,53.635384 
	C491.153015,55.671284 493.363525,60.474686 493.998718,66.329750 
M456.512421,79.000000 
	C460.508881,78.997398 464.514038,79.161278 468.499664,78.952171 
	C474.121002,78.657265 478.500793,75.162193 478.985657,70.920090 
	C479.582214,65.700272 477.458191,61.535450 471.833099,60.638210 
	C463.139923,59.251583 454.241760,59.150089 445.327148,58.495987 
	C445.327148,66.029526 445.327148,72.271820 445.327148,79.000000 
	C448.934509,79.000000 452.228455,79.000000 456.512421,79.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1012.182495,84.274605 
	C1009.100647,85.807648 1006.294800,87.098343 1003.077637,88.578224 
	C1009.522644,96.149078 1015.783081,103.503136 1022.974304,111.950577 
	C1016.613831,111.950577 1011.301697,112.910599 1006.614014,111.613571 
	C1003.480713,110.746613 1001.102966,106.787651 998.627747,103.998779 
	C995.788635,100.800056 993.079712,97.464066 990.563904,94.006081 
	C987.872253,90.306427 984.306763,89.575005 980.074219,89.953209 
	C977.300781,90.201027 974.487305,90.000000 971.194031,90.000000 
	C971.194031,97.341583 971.194031,104.253487 971.194031,111.580063 
	C966.343567,111.580063 961.987366,111.580063 957.309021,111.580063 
	C957.309021,90.587090 957.309021,69.699326 957.309021,48.823849 
	C957.588989,48.605427 957.843262,48.233261 958.099915,48.231625 
	C973.651184,48.132404 989.298035,46.640579 1004.658997,50.117893 
	C1021.554260,53.942547 1023.266357,70.679939 1016.865112,80.169975 
	C1015.809021,81.735779 1013.947754,82.758537 1012.182495,84.274605 
M1000.980164,76.587151 
	C1004.925415,74.871246 1005.299194,71.465904 1004.955505,67.853722 
	C1004.547974,63.570400 1001.348511,61.061684 997.810730,60.592670 
	C989.089783,59.436512 980.254272,59.144962 971.288574,58.492985 
	C971.288574,65.978249 971.288574,72.205391 971.288574,79.000000 
	C976.329163,79.000000 981.112671,79.247284 985.858704,78.931244 
	C990.713623,78.607948 995.531799,77.732430 1000.980164,76.587151 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M940.414795,200.735992 
	C923.140381,192.056870 916.521484,177.121078 923.172913,160.265930 
	C927.896667,148.295639 938.319214,142.624374 950.293396,140.178421 
	C964.701538,137.235291 978.468811,139.554886 989.954529,149.117630 
	C1001.951233,159.105850 1004.825073,174.823547 995.820923,188.673218 
	C985.514221,204.526505 959.547424,209.482162 940.414795,200.735992 
M956.901306,193.997864 
	C959.231934,193.997833 961.580322,194.184570 963.889954,193.964462 
	C972.500793,193.143875 979.497375,189.253754 983.558838,181.576065 
	C987.517639,174.092514 986.634094,166.588409 981.399597,159.763367 
	C972.064514,147.591721 953.683350,147.110062 943.448425,156.179276 
	C934.636536,163.987640 933.219482,173.496017 939.031433,182.915161 
	C942.890869,189.170013 949.008606,192.359207 956.901306,193.997864 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1147.747803,59.798889 
	C1164.799072,43.740540 1191.478027,43.221336 1209.006714,56.789757 
	C1223.064209,67.671242 1223.641968,88.282417 1211.944946,100.764069 
	C1196.130493,117.639145 1163.124878,116.304039 1148.845825,101.418144 
	C1138.017944,90.130196 1136.934570,74.289398 1146.553955,61.808258 
	C1146.952393,61.291355 1147.191162,60.651360 1147.747803,59.798889 
M1172.862305,59.015972 
	C1169.785278,60.574326 1166.531860,61.863087 1163.663208,63.739979 
	C1153.704102,70.255966 1152.181152,85.074707 1160.150879,93.725952 
	C1166.878174,101.028442 1175.312012,103.060555 1184.587036,101.896530 
	C1193.800049,100.740273 1200.581787,95.571083 1203.961182,86.861206 
	C1206.672729,79.872086 1205.349854,72.942383 1200.531006,67.304871 
	C1193.469971,59.044403 1184.314087,56.373318 1172.862305,59.015972 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1064.749023,201.958252 
	C1045.372559,208.207825 1027.802246,204.633270 1010.356628,194.852737 
	C1013.029297,191.021179 1015.159546,187.967255 1016.404785,186.181992 
	C1026.149780,188.958771 1034.803345,192.306305 1043.756714,193.638504 
	C1048.963257,194.413193 1054.718506,192.210815 1060.079224,190.790787 
	C1061.624878,190.381348 1063.597778,188.065781 1063.643799,186.570770 
	C1063.684448,185.252350 1061.537109,183.088531 1059.966309,182.628357 
	C1051.615479,180.182068 1043.005981,178.567047 1034.756592,175.844421 
	C1028.763550,173.866501 1022.457703,171.543030 1017.606506,167.709381 
	C1010.742859,162.285324 1012.033936,151.272659 1019.442688,146.346954 
	C1029.184570,139.869995 1039.938477,138.167267 1051.586792,139.610916 
	C1060.645996,140.733658 1068.904663,143.400650 1077.454102,148.539261 
	C1075.172607,151.891357 1073.037476,155.028198 1070.753784,158.383362 
	C1059.910645,151.856003 1048.956299,148.225800 1036.873169,151.258270 
	C1033.681396,152.059311 1030.818604,154.171173 1027.804810,155.681152 
	C1027.746460,156.351547 1027.688110,157.021927 1027.629761,157.692307 
	C1030.365601,159.126129 1032.954468,161.093781 1035.864502,161.895340 
	C1045.212646,164.470322 1054.775757,166.294083 1064.054077,169.079971 
	C1067.855591,170.221405 1071.631958,172.552292 1074.561035,175.268265 
	C1081.154419,181.381714 1079.927002,192.575302 1072.461182,197.745651 
	C1070.163940,199.336563 1067.586182,200.522354 1064.749023,201.958252 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M503.304169,187.381500 
	C504.927124,185.059402 506.106201,185.251404 508.357086,186.524475 
	C519.909729,193.058502 532.124023,196.158081 545.328247,192.019958 
	C546.268738,191.725235 547.386230,191.542526 548.050903,190.915192 
	C549.429810,189.613678 551.768982,187.767059 551.510864,186.656418 
	C551.114502,184.950836 549.098816,182.866440 547.356873,182.357437 
	C539.157776,179.961670 530.686462,178.453598 522.572083,175.826508 
	C516.733582,173.936249 510.842926,171.462418 505.797089,168.038223 
	C497.693420,162.538910 500.313354,151.708923 506.930695,146.804962 
	C516.211121,139.927399 526.945068,138.237259 538.378784,139.585083 
	C546.669189,140.562378 554.623474,142.296768 561.925903,146.460388 
	C562.910706,147.021881 563.850098,147.662964 564.680908,148.185654 
	C564.710388,148.786316 564.863525,149.195541 564.722046,149.414627 
	C559.108032,158.111893 559.147827,158.009048 549.512878,153.867508 
	C539.960938,149.761612 530.191162,148.348190 520.377258,152.957596 
	C518.750916,153.721451 517.585083,155.466003 516.206482,156.757370 
	C517.668945,158.176086 518.909546,160.404510 520.631470,160.876282 
	C529.019287,163.174377 537.619080,164.712387 545.974854,167.108627 
	C550.815979,168.496948 555.674805,170.457825 559.933594,173.111313 
	C569.036865,178.783157 568.824097,191.847321 559.967896,198.027344 
	C549.982910,204.995041 538.537109,206.232742 527.182373,204.616425 
	C518.541565,203.386398 510.256592,199.622360 501.837982,196.909073 
	C500.809631,196.577652 499.934570,195.770691 498.612885,194.951279 
	C500.216095,192.361206 501.662415,190.024597 503.304169,187.381500 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M886.942200,70.172920 
	C878.682861,84.917778 890.550293,101.244812 905.928650,101.941414 
	C909.699768,102.112228 913.616516,102.060677 917.270813,101.240479 
	C927.239075,99.003159 927.617004,98.220078 926.674316,87.177002 
	C920.721619,87.177002 914.671875,87.177002 908.321655,87.177002 
	C908.321655,83.262505 908.321655,80.016144 908.321655,76.000000 
	C913.602966,76.000000 918.862793,76.000000 924.122620,76.000000 
	C929.444397,76.000000 934.766113,76.000000 940.940430,76.000000 
	C940.940430,84.950371 940.999939,93.684235 940.842834,102.414207 
	C940.828125,103.234055 939.562744,104.192551 938.684143,104.811684 
	C924.324097,114.932327 897.526001,116.365952 882.758240,105.957260 
	C867.103943,94.923729 864.356262,72.860580 877.483459,59.152889 
	C883.203430,53.180077 890.694153,49.758636 898.779053,48.251263 
	C912.644104,45.666233 925.651489,47.924358 937.768372,56.618797 
	C936.112854,58.973881 934.807983,61.735600 932.675415,63.492325 
	C931.562805,64.408707 928.539307,64.267151 927.154602,63.385338 
	C918.504822,57.876820 909.296875,55.874851 899.881104,60.144680 
	C895.125488,62.301262 891.307739,66.526039 886.942200,70.172920 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1270.483032,175.718597 
	C1266.741699,174.211121 1263.372070,172.873932 1260.014160,171.508102 
	C1254.662231,169.331253 1251.425049,165.625824 1250.966431,159.622314 
	C1250.460693,153.001450 1253.675171,148.156036 1258.901611,145.186508 
	C1268.136353,139.939575 1278.078003,138.055954 1288.908691,139.688324 
	C1298.020752,141.061676 1306.655762,143.066635 1314.781616,148.486374 
	C1313.008667,151.308182 1311.471191,154.172150 1309.474854,156.669510 
	C1308.903564,157.384232 1306.871094,157.463455 1305.735840,157.108139 
	C1298.572876,154.866348 1291.616211,151.533783 1284.302612,150.359772 
	C1279.596313,149.604309 1274.322266,151.657211 1269.464600,153.026260 
	C1268.037842,153.428360 1266.436523,155.729645 1266.291504,157.288437 
	C1266.192627,158.353378 1268.294189,160.323685 1269.731689,160.715286 
	C1278.132202,163.003571 1286.785889,164.460266 1295.037598,167.163773 
	C1301.188232,169.178818 1308.025024,171.446548 1312.487915,175.747787 
	C1320.287720,183.264938 1315.730103,196.537262 1304.595581,200.941849 
	C1284.994141,208.695816 1266.476562,205.146454 1247.938110,194.897507 
	C1250.270386,191.499939 1252.436523,188.344406 1254.008911,186.053757 
	C1262.574585,188.596497 1270.555420,191.895142 1278.841797,193.111282 
	C1284.746704,193.977936 1291.104370,192.275375 1297.165405,191.191330 
	C1298.624268,190.930405 1300.625122,188.624069 1300.701416,187.170502 
	C1300.774292,185.780441 1298.942139,183.459061 1297.469727,182.962036 
	C1290.796875,180.709457 1283.967163,178.912003 1277.166016,177.053177 
	C1275.110352,176.491364 1272.964478,176.259232 1270.483032,175.718597 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1136.975830,193.126587 
	C1148.474731,189.943497 1147.460327,191.007721 1146.542969,178.209137 
	C1140.682373,178.209137 1134.766602,178.209137 1128.427979,178.209137 
	C1128.427979,174.684830 1128.427979,171.658707 1128.427979,168.311218 
	C1139.083496,168.311218 1149.656006,168.311218 1160.965210,168.311218 
	C1160.965210,176.942307 1161.063354,185.529373 1160.841797,194.108185 
	C1160.813965,195.189072 1159.297974,196.557693 1158.143921,197.234161 
	C1142.541138,206.379776 1119.002686,208.821884 1102.224365,197.148026 
	C1084.514771,184.826248 1084.828491,156.807098 1104.033813,146.040680 
	C1122.073608,135.927643 1139.902588,137.028564 1158.144897,148.125137 
	C1155.954468,151.067429 1154.120972,153.876007 1151.862915,156.287125 
	C1151.381470,156.801163 1149.160645,156.199799 1148.075073,155.552490 
	C1140.112549,150.804626 1131.844116,148.403625 1122.535278,151.003967 
	C1112.798096,153.723969 1106.419189,159.547989 1104.228638,169.616226 
	C1102.416626,177.944839 1110.436523,190.533035 1119.954834,192.678391 
	C1125.257935,193.873642 1131.006348,193.093216 1136.975830,193.126587 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M793.801758,193.000580 
	C796.524780,192.825150 798.230286,193.159241 798.028198,196.103119 
	C797.860962,198.538651 797.994080,200.994827 797.994080,203.911118 
	C782.998230,203.911118 768.564941,204.016159 754.136292,203.805374 
	C751.794312,203.771133 749.318909,202.627853 747.172119,201.492462 
	C741.887024,198.697296 739.292725,193.669052 739.159485,188.123505 
	C738.780273,172.344513 739.026367,156.550476 739.026367,140.382294 
	C758.394043,140.382294 777.407532,140.382294 796.711487,140.382294 
	C796.711487,143.805161 796.711487,147.049271 796.711487,150.790863 
	C782.673035,150.790863 768.765381,150.790863 754.431824,150.790863 
	C754.431824,155.633087 754.431824,159.994217 754.431824,164.860962 
	C767.067688,164.860962 779.642822,164.860962 792.604614,164.860962 
	C792.604614,168.712402 792.604614,172.070694 792.604614,175.933838 
	C779.857727,175.933838 767.282043,175.933838 754.030701,175.933838 
	C754.030701,180.004227 753.759277,183.627960 754.101624,187.192764 
	C754.501648,191.358566 757.319214,193.026123 761.366638,193.011856 
	C772.019714,192.974335 782.672913,192.999756 793.801758,193.000580 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M554.910034,74.000000 
	C557.364014,74.000000 559.330200,74.000000 561.646118,74.000000 
	C561.646118,77.341293 561.646118,80.256935 561.646118,83.854881 
	C549.012939,83.854881 536.305176,83.854881 523.011780,83.854881 
	C523.011780,88.291229 522.718140,92.100273 523.090271,95.843124 
	C523.504028,100.004311 526.815247,100.954720 530.353271,100.976784 
	C540.512512,101.040176 550.672302,100.999680 560.831909,100.999985 
	C562.637207,101.000038 564.442505,101.000000 566.622559,101.000000 
	C566.622559,104.624039 566.622559,107.869850 566.622559,111.762344 
	C565.022156,111.836861 563.270569,111.985970 561.518799,111.989067 
	C549.693726,112.009987 537.868591,112.013115 526.043518,111.996033 
	C515.691162,111.981079 508.589813,106.348640 508.187286,96.098373 
	C507.568024,80.330048 508.024414,64.519485 508.024414,48.364967 
	C527.314819,48.364967 546.217590,48.364967 565.561157,48.364967 
	C565.561157,51.626614 565.561157,54.986572 565.561157,58.860241 
	C551.603455,58.860241 537.690186,58.860241 523.392334,58.860241 
	C523.392334,63.927067 523.392334,68.502510 523.392334,74.000000 
	C533.621704,74.000000 544.022034,74.000000 554.910034,74.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1221.989380,140.000000 
	C1226.448120,140.000000 1230.408691,140.000000 1234.677979,140.000000 
	C1234.677979,143.737869 1234.677979,146.985260 1234.677979,150.742767 
	C1220.726929,150.742767 1206.811523,150.742767 1192.450317,150.742767 
	C1192.450317,155.595016 1192.450317,159.960236 1192.450317,164.842133 
	C1205.377808,164.842133 1218.293091,164.842133 1231.600220,164.842133 
	C1231.600220,168.705658 1231.600220,172.065842 1231.600220,175.941406 
	C1218.520020,175.941406 1205.603516,175.941406 1192.031738,175.941406 
	C1192.031738,179.692291 1191.664062,183.015152 1192.119507,186.221085 
	C1192.769531,190.796585 1195.809937,193.018631 1200.476685,193.006897 
	C1210.305420,192.982178 1220.134155,192.999771 1229.962891,192.999985 
	C1231.756958,193.000031 1233.551025,193.000000 1235.670166,193.000000 
	C1235.670166,196.721985 1235.670166,199.969711 1235.670166,204.000000 
	C1233.894775,204.000000 1232.138916,204.000000 1230.383057,204.000000 
	C1219.055054,204.000000 1207.723022,203.814529 1196.400024,204.053146 
	C1185.491699,204.283020 1177.217407,196.731415 1177.801025,185.791824 
	C1178.598022,170.853287 1178.000000,155.840302 1178.000000,140.000000 
	C1192.680054,140.000000 1207.085693,140.000000 1221.989380,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M383.026947,189.000000 
	C374.687988,187.722473 368.538452,189.360947 366.379425,198.172348 
	C365.140778,203.227493 361.744659,204.784576 356.776520,204.059998 
	C354.389099,203.711807 351.908905,204.000000 348.538452,204.000000 
	C353.276459,194.520447 357.513702,185.792816 361.974182,177.180771 
	C367.748596,166.031891 373.828186,155.039490 379.490967,143.835480 
	C380.980774,140.887909 382.754333,140.280060 385.882324,139.821976 
	C393.798737,138.662659 397.487854,142.107559 400.767670,149.036682 
	C408.400055,165.161346 417.059479,180.799301 425.263977,196.654541 
	C426.360107,198.772812 427.231689,201.007294 428.566071,203.990005 
	C423.153748,203.990005 418.684967,204.239090 414.274139,203.843079 
	C412.893524,203.719086 410.851776,202.073425 410.502136,200.755661 
	C407.676361,190.104675 400.632202,187.130707 390.499207,188.940811 
	C388.241669,189.344101 385.850616,189.000000 383.026947,189.000000 
M396.875885,173.625626 
	C393.917633,167.828201 390.959412,162.030762 387.672852,155.589966 
	C383.820923,163.396423 380.377716,170.374466 376.733704,177.759567 
	C384.494995,177.759567 391.357361,177.759567 398.657196,177.759567 
	C398.032043,176.374985 397.570557,175.352890 396.875885,173.625626 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M793.692139,97.674202 
	C801.960144,81.794266 809.975952,66.204460 818.225098,50.739189 
	C818.937073,49.404549 821.093384,48.451046 822.749939,48.075462 
	C830.950989,46.216129 835.412598,49.963531 838.979248,57.431835 
	C847.457642,75.184998 856.849365,92.501915 865.849854,110.006516 
	C865.977844,110.255440 865.818604,110.652107 865.720459,111.930557 
	C860.980591,111.930557 856.093201,112.246117 851.296692,111.724937 
	C849.976685,111.581497 848.384155,109.111336 847.847168,107.414360 
	C845.181763,98.991280 840.056335,95.482735 830.977722,96.835793 
	C825.114929,97.709557 819.011475,97.140305 813.025208,96.939346 
	C810.150940,96.842857 808.224915,97.640373 807.231689,100.406235 
	C806.953857,101.179955 806.496155,101.888657 806.125977,102.629723 
	C800.880127,113.131439 799.678650,113.697769 786.938782,111.323395 
	C789.165222,106.802681 791.333496,102.400017 793.692139,97.674202 
M835.398315,85.973091 
	C835.655334,85.516487 836.250244,84.897079 836.120117,84.627052 
	C832.832581,77.804825 829.468872,71.019302 825.744263,63.468525 
	C821.846008,71.362221 818.392029,78.356300 814.618347,85.997704 
	C821.958618,85.997704 828.277588,85.997704 835.398315,85.973091 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M595.000183,159.999756 
	C597.577637,154.765686 600.112671,149.922714 602.470825,144.995117 
	C604.091675,141.608139 606.161987,140.029282 610.346619,139.788773 
	C616.913513,139.411346 620.508057,141.161606 623.548035,147.549774 
	C632.353088,166.052643 642.166809,184.075455 651.567627,202.295456 
	C651.699402,202.550949 651.610718,202.920258 651.658325,203.978241 
	C647.080933,203.978241 642.497437,204.235580 637.975708,203.820251 
	C636.632263,203.696854 634.711487,201.888092 634.333313,200.502731 
	C631.585876,190.438217 625.192993,187.442032 615.182251,188.824722 
	C608.501465,189.747482 601.589905,189.000000 594.741821,189.000000 
	C593.987366,190.714645 593.381897,192.222443 592.673340,193.680130 
	C586.985413,205.381668 586.785339,205.478363 572.416016,203.403366 
	C580.006348,188.859283 587.444336,174.607025 595.000183,159.999756 
M618.880676,169.640839 
	C616.563721,165.148941 614.246765,160.657028 611.338684,155.019089 
	C607.529053,163.214996 604.245483,170.279144 600.762207,177.772888 
	C608.435242,177.772888 615.323547,177.772888 622.762512,177.772888 
	C621.417175,175.034424 620.266296,172.691666 618.880676,169.640839 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249176,152.051575 
	C222.031982,151.533203 222.025894,151.023132 222.222061,150.927368 
	C232.491058,145.914810 242.777191,140.937317 253.447876,135.970886 
	C254.777008,136.750275 256.121735,137.319534 256.602600,138.308731 
	C264.463806,154.480621 272.215881,170.705566 280.511261,188.000000 
	C277.534393,188.000000 275.335510,188.000000 273.136658,188.000000 
	C263.991028,188.000000 254.845383,188.012695 245.699799,187.995590 
	C239.400879,187.983826 236.380875,185.784134 234.075699,180.076462 
	C230.316742,170.769165 226.403061,161.524353 222.249176,152.051575 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M366.394135,107.727707 
	C351.885498,100.186066 347.983246,86.071129 349.191895,76.869301 
	C350.909607,63.791420 358.477295,55.262421 370.654205,50.758678 
	C387.239777,44.624332 402.916260,46.391838 417.904358,56.608658 
	C415.883820,59.285549 414.298218,62.109497 411.984924,64.078545 
	C411.115875,64.818253 408.256165,64.054306 406.818604,63.154079 
	C398.118805,57.706112 389.071869,56.519821 379.364960,60.001274 
	C370.551208,63.162384 363.944733,71.898354 364.001587,80.596558 
	C364.058502,89.311501 370.218384,96.959755 379.523499,100.266495 
	C389.616821,103.853355 399.158630,102.466705 407.975037,96.870300 
	C411.570221,94.588196 413.477234,94.778664 415.341370,98.554840 
	C416.194275,100.282555 417.683594,101.696129 419.246399,103.721458 
	C402.122864,114.502747 384.857391,116.299965 366.394135,107.727707 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M703.163086,139.895508 
	C711.605713,140.425766 718.543823,143.526855 725.370972,148.526855 
	C723.182068,151.551285 721.207397,154.279785 719.354004,156.840622 
	C713.720154,154.659882 708.517517,152.204483 703.060364,150.632095 
	C693.693604,147.933167 681.027954,152.827881 675.782349,160.480225 
	C666.919006,173.410202 674.405090,187.240555 685.950989,191.831787 
	C696.274963,195.937134 706.277832,194.622787 715.819153,188.801010 
	C721.055359,185.606064 719.578247,186.150894 724.701416,192.532211 
	C727.542664,196.071289 724.183228,197.416809 721.703064,198.648743 
	C704.354187,207.266113 687.161865,207.984253 670.433411,197.126160 
	C659.947327,190.319824 655.779846,180.219055 657.110535,168.102020 
	C658.443054,155.968033 665.825806,147.857635 676.722656,143.296722 
	C684.938354,139.858032 693.613159,137.613892 703.163086,139.895508 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1129.632202,96.306046 
	C1131.347168,98.904526 1132.841431,101.205139 1134.311157,103.467918 
	C1115.066772,118.221001 1082.224609,116.321396 1068.986694,95.538315 
	C1061.441284,83.692444 1064.832764,64.244759 1076.510864,56.286015 
	C1093.667480,44.593418 1111.497559,44.437775 1129.737305,53.869057 
	C1133.211060,55.665245 1133.419434,57.690376 1131.044312,60.422581 
	C1129.750732,61.910507 1128.412964,63.359833 1127.251099,64.652969 
	C1121.348511,62.537781 1115.656006,59.974987 1109.696777,58.465958 
	C1095.119629,54.774700 1079.588623,67.017288 1080.000366,81.497887 
	C1080.292358,91.768288 1090.593628,101.250664 1101.941650,102.041077 
	C1110.338257,102.625923 1118.010864,100.877182 1125.161987,96.414886 
	C1126.242432,95.740707 1127.978638,96.117508 1129.632202,96.306046 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M892.703613,158.650330 
	C895.111633,153.523468 897.485596,148.788239 899.493774,143.902679 
	C900.744751,140.859238 902.499084,139.569717 905.868286,139.929199 
	C908.983826,140.261612 912.162720,140.000000 916.301575,140.000000 
	C915.372681,141.992096 914.759399,143.389465 914.079163,144.753464 
	C904.911438,163.137512 895.658264,181.479691 886.633728,199.933655 
	C885.104614,203.060471 883.191467,203.736115 879.872253,204.182281 
	C872.170776,205.217529 868.480591,202.062775 865.363403,195.162628 
	C857.529968,177.822296 848.770874,160.900192 840.394775,143.804626 
	C839.908081,142.811417 839.527161,141.766418 838.797546,140.034714 
	C844.038147,140.034714 848.656433,139.771347 853.209595,140.199783 
	C854.526733,140.323730 856.100769,142.218353 856.803162,143.666794 
	C862.863037,156.162415 868.743896,168.744736 874.696228,181.292618 
	C875.595825,183.188995 876.588928,185.041031 878.288940,188.392273 
	C883.417542,177.788284 887.966187,168.383362 892.703613,158.650330 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M242.587509,65.964462 
	C258.532043,57.932762 274.076141,49.961006 289.574890,41.902039 
	C295.173737,38.990772 300.663849,35.870411 306.989288,32.416443 
	C306.989288,41.564476 307.132507,49.813107 306.847290,58.046894 
	C306.800140,59.408428 305.151855,61.204731 303.780945,61.954769 
	C289.154846,69.957001 274.504059,77.920502 259.717499,85.620880 
	C249.856445,90.756226 244.859970,88.538673 240.928589,77.944138 
	C239.531815,74.180046 238.058731,70.444275 236.349762,65.989937 
	C238.910034,65.989937 240.539581,65.989937 242.587509,65.964462 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M730.000000,64.149963 
	C730.000000,80.118355 730.000000,95.602898 730.000000,111.543121 
	C725.411438,111.543121 721.048157,111.543121 716.172241,111.543121 
	C716.172241,94.298882 716.172241,77.054176 716.172241,59.128632 
	C707.988037,59.128632 700.275513,59.128632 692.292847,59.128632 
	C692.292847,55.202400 692.292847,51.957226 692.292847,48.353603 
	C712.665161,48.353603 732.903870,48.353603 753.572266,48.353603 
	C753.572266,51.649151 753.572266,55.009327 753.572266,58.888557 
	C745.909607,58.888557 738.325989,58.888557 730.371277,58.888557 
	C730.222961,60.796864 730.111450,62.231483 730.000000,64.149963 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1248.000000,66.000984 
	C1248.000000,77.792305 1248.000000,89.083946 1248.000000,100.883568 
	C1261.419922,100.883568 1274.339844,100.883568 1287.625000,100.883568 
	C1287.625000,104.633881 1287.625000,107.883308 1287.625000,111.568878 
	C1269.702026,111.568878 1251.669312,111.568878 1233.316650,111.568878 
	C1233.316650,90.576202 1233.316650,69.664299 1233.316650,48.375462 
	C1237.967041,48.375462 1242.546875,48.375462 1248.000000,48.375462 
	C1248.000000,53.997364 1248.000000,59.749340 1248.000000,66.000984 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M471.000000,204.000000 
	C460.541351,204.000000 450.582733,204.000000 440.316284,204.000000 
	C440.316284,182.575119 440.316284,161.663254 440.316284,140.374954 
	C444.633575,140.374954 448.879272,140.374954 453.826050,140.374954 
	C453.826050,157.634430 453.826050,175.006683 453.826050,192.884766 
	C467.422791,192.884766 480.341034,192.884766 493.625671,192.884766 
	C493.625671,196.635071 493.625671,199.884491 493.625671,204.000000 
	C486.339478,204.000000 478.919708,204.000000 471.000000,204.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1036.000000,87.999985 
	C1036.000000,74.562569 1036.000000,61.625145 1036.000000,48.346443 
	C1041.019165,48.346443 1045.591675,48.346443 1050.584717,48.346443 
	C1050.584717,69.307816 1050.584717,90.305725 1050.584717,111.653168 
	C1045.981567,111.653168 1041.409058,111.653168 1036.000000,111.653168 
	C1036.000000,103.975677 1036.000000,96.237823 1036.000000,87.999985 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M676.716431,111.970833 
	C672.771729,111.991631 669.293579,111.991631 665.410278,111.991631 
	C665.410278,90.676987 665.410278,69.672539 665.410278,48.332756 
	C669.714966,48.332756 673.956726,48.332756 678.774231,48.332756 
	C678.848938,49.906410 678.988770,51.498119 678.990173,53.089951 
	C679.005737,71.057854 679.047424,89.026123 678.920837,106.993149 
	C678.909180,108.649429 677.788574,110.297920 676.716431,111.970833 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M597.000000,85.995865 
	C597.000000,76.891167 597.000000,68.285896 597.000000,59.161236 
	C607.133240,58.964077 616.713074,58.423927 625.887329,62.886509 
	C633.142883,66.415810 636.415405,72.669899 636.734985,79.901329 
	C637.065857,87.390060 632.995056,93.269028 626.386414,97.300941 
	C617.330078,102.826157 607.454590,100.472031 597.000000,101.029350 
	C597.000000,95.976555 597.000000,91.235916 597.000000,85.995865 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M456.017395,79.000000 
	C452.228455,79.000000 448.934509,79.000000 445.327148,79.000000 
	C445.327148,72.271820 445.327148,66.029526 445.327148,58.495987 
	C454.241760,59.150089 463.139923,59.251583 471.833099,60.638210 
	C477.458191,61.535450 479.582214,65.700272 478.985657,70.920090 
	C478.500793,75.162193 474.121002,78.657265 468.499664,78.952171 
	C464.514038,79.161278 460.508881,78.997398 456.017395,79.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1000.673096,76.842903 
	C995.531799,77.732430 990.713623,78.607948 985.858704,78.931244 
	C981.112671,79.247284 976.329163,79.000000 971.288574,79.000000 
	C971.288574,72.205391 971.288574,65.978249 971.288574,58.492985 
	C980.254272,59.144962 989.089783,59.436512 997.810730,60.592670 
	C1001.348511,61.061684 1004.547974,63.570400 1004.955505,67.853722 
	C1005.299194,71.465904 1004.925415,74.871246 1000.673096,76.842903 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M956.459717,193.991425 
	C949.008606,192.359207 942.890869,189.170013 939.031433,182.915161 
	C933.219482,173.496017 934.636536,163.987640 943.448425,156.179276 
	C953.683350,147.110062 972.064514,147.591721 981.399597,159.763367 
	C986.634094,166.588409 987.517639,174.092514 983.558838,181.576065 
	C979.497375,189.253754 972.500793,193.143875 963.889954,193.964462 
	C961.580322,194.184570 959.231934,193.997833 956.459717,193.991425 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1173.280884,59.004166 
	C1184.314087,56.373318 1193.469971,59.044403 1200.531006,67.304871 
	C1205.349854,72.942383 1206.672729,79.872086 1203.961182,86.861206 
	C1200.581787,95.571083 1193.800049,100.740273 1184.587036,101.896530 
	C1175.312012,103.060555 1166.878174,101.028442 1160.150879,93.725952 
	C1152.181152,85.074707 1153.704102,70.255966 1163.663208,63.739979 
	C1166.531860,61.863087 1169.785278,60.574326 1173.280884,59.004166 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M396.992462,173.978210 
	C397.570557,175.352890 398.032043,176.374985 398.657196,177.759567 
	C391.357361,177.759567 384.494995,177.759567 376.733704,177.759567 
	C380.377716,170.374466 383.820923,163.396423 387.672852,155.589966 
	C390.959412,162.030762 393.917633,167.828201 396.992462,173.978210 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M834.997437,85.985397 
	C828.277588,85.997704 821.958618,85.997704 814.618347,85.997704 
	C818.392029,78.356300 821.846008,71.362221 825.744263,63.468525 
	C829.468872,71.019302 832.832581,77.804825 836.120117,84.627052 
	C836.250244,84.897079 835.655334,85.516487 834.997437,85.985397 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M618.998047,169.994873 
	C620.266296,172.691666 621.417175,175.034424 622.762512,177.772888 
	C615.323547,177.772888 608.435242,177.772888 600.762207,177.772888 
	C604.245483,170.279144 607.529053,163.214996 611.338684,155.019089 
	C614.246765,160.657028 616.563721,165.148941 618.998047,169.994873 
z"
      />
    </svg>
  );
};

CaAlsaceVosgesSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaAlsaceVosgesSvg;
