import React from 'react';
import PropTypes from 'prop-types';
import IconHelpCircleSvg from './svg/icon-help-circle-svg';
import './styles.scss';

const IconHelpCircle = props => {
  const { title, alt, width, height, manageRedirection } = props;
  return (
    <div className="help__circle" onClick={manageRedirection}>
      <IconHelpCircleSvg
        className="help__circle__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconHelpCircle.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  manageRedirection: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconHelpCircle;
