import { EmailCode, Enum } from '@utocat/catalizr-const-sharing-lib';

export const NAME_SURNAME_REGEX =
  "[0-9A-Za-zĄÀÁÂÃÄÅÇĆÈÉÊËĘÌÍÎÏŁÑŃÒÓÔÕÖŚÙÚÛÜÝŹŻąàáâãäåæçćèéêëęìíîïłñńòóôõöśùúûüýÿźżß`\\'()\\-\\s]";

export const FAKE_IBAN_PATTERN = 'FAKE_GENERATED_IBAN_';
export const TITLE_CORPORATION = 'Personne morale';

// --------------------------------------------------------------------------------------------
// List of operation types allowed
// --------------------------------------------------------------------------------------------

export const OPERATION_TYPE_CREATE = 'Création de société';
export const OPERATION_TYPE_CAPITAL_INCREASE = 'Augmentation de capital';
export const OPERATION_TYPE_REPURCHASE = 'Gré à gré';
export const OPERATION_TYPE_DISPOSAL = 'Cession';
export const OPERATION_TYPE_BOND = 'Obligations';
export const OPERATION_TYPE_PRIVATE_EQUITY = 'Souscription fonds';
export const OPERATION_TYPE_KIND_CONTRIBUTION = 'Apport de titres';

// --------------------------------------------------------------------------------------------
// List of support types allowed
// --------------------------------------------------------------------------------------------

export const SUPPORT_CTO = 'Compte titres ordinaire';
export const SUPPORT_PEA = 'PEA';
export const SUPPORT_PEA_PME = 'PEA-PME';
export const SUPPORT_PERI = 'PERI';
export const SUPPORT_PRIVATE_EQUITY = 'Compte private equity';

// --------------------------------------------------------------------------------------------
// List of support types allowed
// --------------------------------------------------------------------------------------------

/**
 * @deprecated
 */
export const modalType = {
  EDIT: 'EDIT',
  IN_NAME_OF: 'IN_NAME_OF',
  DEFAULT: 'DEFAULT',
  SUGGESTION_OPERATION: 'SUGGESTION_OPERATION',
  EDIT_SUGGESTION_OPERATION: 'EDIT_SUGGESTION_OPERATION',
};

// --------------------------------------------------------------------------------------------
// List of operation status : sort by logic order
// --------------------------------------------------------------------------------------------
export const OPERATION_STATUSES = {
  WAITING_DOCUMENT: 'WAITING_DOCUMENT',
  INVESTOR_SIGNED: 'INVESTOR_SIGNED',
  PAYMENT_EMITTED: 'PAYMENT_EMITTED',
  PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
  PAYMENT_TO_BE_MADE: 'PAYMENT_TO_BE_MADE',
  ALL_SIGNED: 'ALL_SIGNED',
  INVESTMENT_UPDATED: 'INVESTMENT_UPDATED',
  CANCELLED: 'CANCELLED',
  INVESTMENT_CLOSED: 'INVESTMENT_CLOSED',
};

// --------------------------------------------------------------------------------------------
// List of users
// --------------------------------------------------------------------------------------------
export const USER_TYPE = {
  INVESTOR: 'Investisseur',
  COMPANY: 'Entreprise',
  ADVISOR: 'Conseiller bancaire',
  BACKOFFICE: 'Service Titres',
  BANK: 'Banque',
  BO_OST: 'Service Titres',
  MIDDLE_OFFICE: 'Middle Office',
  REGIONAL_SECURITIES_SERVICE_ROLE: 'Service Titres Régional',
};

export const ROLE_TO_NAME = {
  [Enum.User.Role.INVESTOR_ROLE]: USER_TYPE.INVESTOR,
  [Enum.User.Role.COMPANY_ROLE]: USER_TYPE.COMPANY,
  [Enum.User.Role.ADVISOR_ROLE]: USER_TYPE.ADVISOR,
  [Enum.User.Role.BACKOFFICE_ROLE]: USER_TYPE.BACKOFFICE,
  [Enum.User.Role.BO_OST_ROLE]: USER_TYPE.BO_OST,
  [Enum.User.Role.SUPER_BO_ROLE]: USER_TYPE.BACKOFFICE,
  [Enum.User.Role.MIDDLE_OFFICE_ROLE]: USER_TYPE.MIDDLE_OFFICE,
  [Enum.User.Role.REGIONAL_SECURITIES_SERVICE_ROLE]: USER_TYPE.REGIONAL_SECURITIES_SERVICE_ROLE,
};

// --------------------------------------------------------------------------------------------
// List of creation method
// --------------------------------------------------------------------------------------------
export const CREATION_METHOD = {
  COMPLETE: 'COMPLETE',
  TRANSMIT: 'TRANSMIT',
};

// --------------------------------------------------------------------------------------------
// Labels associated to overview icons
// --------------------------------------------------------------------------------------------

export const OVERVIEW_LABEL = {
  REMINDER: "Présence d'une relance active",
  FLAG_INDICATOR_TRACKING: 'Suivi',
  FLAG_INDICATOR_NO_TRACKING: 'Non Suivi',
  RISK_INDICATOR_LEVEL_1: 'Niveau 1',
  RISK_INDICATOR_LEVEL_2: 'Niveau 2',
  RISK_INDICATOR_LEVEL_3: 'Niveau 3',
  RISK_INDICATOR_LEVEL_4: 'Critique',
  COMMENTS: 'Présence de commentaires',
  ACTIVE_ACTOR: 'Dossier en attente d’une action de votre part',
  PAYMENT_EMITTED: 'Virement enregistré',
  PAYMENT_TO_BE_MADE: 'Virement à faire',
  CHECK_PAYMENT: 'Statut de virement',
  UPDATE: 'Modifier',
  DELETE: 'Supprimer',
  EMAIL: 'Ouvrir l’email reminder',
  CANCEL: 'Annuler l’opération',
  CANCEL_ROW: 'Supprimer la ligne',
  UPDATE_INVESTMENT: 'Modifier l’opération',
  APPEAL_IN_PROGRESS: 'Appels non terminés',
};

// --------------------------------------------------------------------------------------------
// Tooltip associated to the operation status
// --------------------------------------------------------------------------------------------

export const TIMELINE_STATUS = {
  STEP1: "Génération et signature de la lettre d'engagement",
  STEP1_PRIVATE_EQUITY: 'Génération et signature des documents de souscription',
  STEP2: 'Ajout d’éléments pour transmission à la banque',
  STEP3: 'Validation et transfert des fonds',
  STEP4: 'Réception des fonds',
  STEP4_REPURCHASE: 'Confirmation de la vente des titres',
  STEP5: 'Ajout d’éléments pour signature de l’attestation de propriété',
  STEP6: 'Contrôle réglementaire et enregistrement des titres',
  FINAL_STEP: 'FINAL_STEP',
};

// --------------------------------------------------------------------------------------------
// Objects containing the data needed to display the dashboard timeline
// --------------------------------------------------------------------------------------------
export const TIMELINE_STEPS = [
  {
    title: TIMELINE_STATUS.STEP1,
    stepStatus: [OPERATION_STATUSES.WAITING_DOCUMENT],
    userType: USER_TYPE.INVESTOR,
    callToAction_template_code: [
      EmailCode.Investor.INVESTOR_INFO_OPERATION_REJECTED,
      EmailCode.Investor.INVESTOR_INFO_ACCOUNT_LOGIN,
      EmailCode.Investor.INVESTOR_INFO_DISPOSAL_TRANSMIT_LOGIN,
    ],
    reminder_template_code: 'investorReminders',
  },
  {
    title: TIMELINE_STATUS.STEP2,
    stepStatus: [
      OPERATION_STATUSES.INVESTOR_SIGNED,
      OPERATION_STATUSES.PAYMENT_TO_BE_MADE,
      OPERATION_STATUSES.PAYMENT_EMITTED,
    ],
    userType: USER_TYPE.COMPANY,
    callToAction_template_code: [EmailCode.Company.COMPANY_INFO_ACCOUNT_LOGIN],
    reminder_template_code: 'companyS1Reminders',
  },
  {
    title: TIMELINE_STATUS.STEP3,
    stepStatus: [OPERATION_STATUSES.INVESTOR_SIGNED, OPERATION_STATUSES.PAYMENT_TO_BE_MADE],
    userType: USER_TYPE.BANK,
    callToAction_template_code: [EmailCode.Company.COMPANY_INFO_OPERATION_BANK_PAYMENT_EMITTED],
  },
  {
    stepStatus: [OPERATION_STATUSES.ALL_SIGNED, OPERATION_STATUSES.PAYMENT_EMITTED],
    userType: USER_TYPE.COMPANY,
    callToAction_template_code: [EmailCode.Company.COMPANY_INFO_OPERATION_STAGE_2],
    reminder_template_code: 'companyS2Reminders',
  },
  {
    title: TIMELINE_STATUS.STEP5,
    stepStatus: [OPERATION_STATUSES.PAYMENT_RECEIVED],
    userType: USER_TYPE.COMPANY,
    callToAction_template_code: [EmailCode.Company.COMPANY_INFO_OPERATION_REJECTED],
  },
  {
    title: TIMELINE_STATUS.STEP6,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_UPDATED, OPERATION_STATUSES.PAYMENT_EMITTED],
    userType: USER_TYPE.BANK,
  },
  {
    title: TIMELINE_STATUS.FINAL_STEP,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_CLOSED, OPERATION_STATUSES.CANCELLED],
    callToAction_template_code: [
      EmailCode.Company.COMPANY_INFO_OPERATION_CLOSED,
      EmailCode.Investor.INVESTOR_INFO_OPERATION_CANCELLED,
    ],
    userType: '',
  },
];

export const TIMELINE_STEPS_PRIVATE_EQUITY = [
  {
    title: TIMELINE_STATUS.STEP1_PRIVATE_EQUITY,
    stepStatus: [OPERATION_STATUSES.WAITING_DOCUMENT],
    userType: USER_TYPE.INVESTOR,
    callToAction_template_code: [
      EmailCode.Investor.INVESTOR_INFO_OPERATION_REJECTED,
      EmailCode.Investor.INVESTOR_INFO_ACCOUNT_LOGIN,
      EmailCode.Investor.INVESTOR_INFO_DISPOSAL_TRANSMIT_LOGIN,
    ],
    reminder_template_code: 'investorReminders',
  },
  {
    title: TIMELINE_STATUS.STEP2,
    stepStatus: [OPERATION_STATUSES.INVESTOR_SIGNED],
    userType: 'Société de gestion',
    callToAction_template_code: [EmailCode.Company.COMPANY_INFO_ACCOUNT_LOGIN],
    reminder_template_code: 'companyS1Reminders',
  },
  {
    title: TIMELINE_STATUS.STEP6,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_UPDATED],
    userType: USER_TYPE.BANK,
  },
  {
    title: TIMELINE_STATUS.FINAL_STEP,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_CLOSED, OPERATION_STATUSES.CANCELLED],
    callToAction_template_code: [
      EmailCode.Company.COMPANY_INFO_OPERATION_CLOSED,
      EmailCode.Investor.INVESTOR_INFO_OPERATION_CANCELLED,
    ],
    userType: '',
  },
];

export const TIMELINE_STATUS_DISPOSAL = {
  STEP1: 'Fourniture du dossier de cession',
  STEP2: 'Rapprochement du virement',
  STEP3: 'Contrôle réglementaire et désenregistrement des titres',
};

export const TIMELINE_STEPS_DISPOSAL = [
  {
    title: TIMELINE_STATUS_DISPOSAL.STEP1,
    stepStatus: [OPERATION_STATUSES.WAITING_DOCUMENT],
    userType: USER_TYPE.INVESTOR,
    callToAction_template_code: [EmailCode.Investor.INVESTOR_INFO_DISPOSAL_TRANSMIT_LOGIN],
    reminder_template_code: 'investorReminders',
  },
  {
    title: TIMELINE_STATUS_DISPOSAL.STEP2,
    stepStatus: [],
    userType: USER_TYPE.BANK,
    callToAction_template_code: [],
  },
  {
    title: TIMELINE_STATUS_DISPOSAL.STEP3,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_UPDATED, OPERATION_STATUSES.PAYMENT_EMITTED],
    userType: USER_TYPE.BANK,
  },
  {
    title: TIMELINE_STATUS.FINAL_STEP,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_CLOSED, OPERATION_STATUSES.CANCELLED],
    callToAction_template_code: [EmailCode.Investor.INVESTOR_INFO_OPERATION_CANCELLED],
    userType: '',
  },
];

export const TIMELINE_STEPS_KIND_CONTRIBUTION = [
  {
    title: TIMELINE_STATUS.STEP1,
    stepStatus: [OPERATION_STATUSES.WAITING_DOCUMENT],
    userType: USER_TYPE.INVESTOR,
    callToAction_template_code: [
      EmailCode.Investor.INVESTOR_INFO_OPERATION_REJECTED,
      EmailCode.Investor.INVESTOR_INFO_ACCOUNT_LOGIN,
      EmailCode.Investor.INVESTOR_INFO_DISPOSAL_TRANSMIT_LOGIN,
    ],
    reminder_template_code: 'investorReminders',
  },
  {
    title: TIMELINE_STATUS.STEP5,
    stepStatus: [OPERATION_STATUSES.PAYMENT_RECEIVED],
    userType: USER_TYPE.COMPANY,
    callToAction_template_code: [
      EmailCode.Company.COMPANY_INFO_OPERATION_STAGE_2,
      EmailCode.Company.COMPANY_INFO_OPERATION_REJECTED,
    ],
  },
  {
    title: TIMELINE_STATUS.STEP6,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_UPDATED, OPERATION_STATUSES.PAYMENT_EMITTED],
    userType: USER_TYPE.BANK,
  },
  {
    title: TIMELINE_STATUS.FINAL_STEP,
    stepStatus: [OPERATION_STATUSES.INVESTMENT_CLOSED, OPERATION_STATUSES.CANCELLED],
    callToAction_template_code: [
      EmailCode.Company.COMPANY_INFO_OPERATION_CLOSED,
      EmailCode.Investor.INVESTOR_INFO_OPERATION_CANCELLED,
    ],
    userType: '',
  },
];

export const SIGN_DOCUMENTS_ENTITY_TYPE = {
  investor: 'INVESTOR',
  company: 'COMPANY',
  advisor: 'INVESTOR',
  backoffice: 'INVESTOR',
};

export const PROGRESS_BAR_STATUS = new Map();
PROGRESS_BAR_STATUS.set('WAITING_DOCUMENT', { value: 20, status: 'Opération créée' });
PROGRESS_BAR_STATUS.set('INVESTOR_SIGNED', { value: 40, status: "Lettre d'engagement signée" });
PROGRESS_BAR_STATUS.set('PAYMENT_EMITTED', { value: 60, status: 'Émission du virement' });
PROGRESS_BAR_STATUS.set('COMPANY_SIGNED', { value: 60, status: 'Documents ajoutés' });
PROGRESS_BAR_STATUS.set('ALL_SIGNED', { value: 60, status: 'Documents ajoutés' });
PROGRESS_BAR_STATUS.set('PAYMENT_RECEIVED', { value: 80, status: 'Fonds réceptionnés' });
PROGRESS_BAR_STATUS.set('INVESTMENT_UPDATED', { value: 100, status: 'Clôture' });

export const PROGRESS_BAR_STATUS_DISPOSAL_OPERATION = new Map();
PROGRESS_BAR_STATUS_DISPOSAL_OPERATION.set('WAITING_DOCUMENT', { value: 50, status: 'En cours' });
PROGRESS_BAR_STATUS_DISPOSAL_OPERATION.set('INVESTMENT_UPDATED', { value: 100, status: 'Clôture' });

export const PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION = new Map();
PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION.set('WAITING_DOCUMENT', {
  value: 20,
  status: 'Opération créée',
});
PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION.set('INVESTOR_SIGNED', {
  value: 40,
  status: "Lettre d'engagement signée",
});
PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION.set('INVESTMENT_UPDATED', {
  value: 100,
  status: 'Clôture',
});

export const MAX_STR_LENGTH_TITLE = 20;

// List of status for which the company is forbidden to process its stage 1
export const FORBIDDEN_STATUS_FOR_COMPANY_TO_PROCESS_STAGE1 = [
  'COMPANY_SIGNED',
  'ALL_SIGNED',
  'PAYMENT_RECEIVED',
  'INVESTMENT_UPDATED',
  'INVESTMENT_CLOSED',
  'CANCELLED',
];

// List of status for which the investor is forbidden to process its operation
export const FORBIDDEN_STATUS_FOR_INVESTOR_TO_PROCESS = [
  'INVESTOR_SIGNED',
  'COMPANY_SIGNED',
  'ALL_SIGNED',
  'PAYMENT_EMITTED',
  'PAYMENT_RECEIVED',
  'INVESTMENT_UPDATED',
  'INVESTMENT_CLOSED',
  'CANCELLED',
];

// --------------------------------------------------------------------------------------------
// Objects containing the data needed to display comments
// --------------------------------------------------------------------------------------------

export const PAYMENT_COMMENTS = {
  PAYMENT_TO_BE_MADE: 'Mise en paiement demandée',
  PAYMENT_EMITTED: 'Virement enregistré',
  PAYMENT_RECEIVED: 'La réception du virement a été confirmée',
};

export const FILE_MAX_SIZE_MB = 20;

export const INT_MAX_LIMIT = 2147483647;

export const FUND_COMPANY_TYPE = 'FUND_MANAGEMENT';

// --------------------------------------------------------------------------------------------
// List of dashboard statuses
// --------------------------------------------------------------------------------------------
export const DASHBOARD_STATUSES = {
  TRANSMIT: 'TRANSMIT',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
  OPERATION_SUGGESTED: 'OPERATION_SUGGESTION', // for /api/operation/filters?status={value}
  CANCELLED: 'CANCELLED',
  CORPORATE_ACTIONS: 'CORPORATE_ACTIONS',
  SHAREHOLDING: 'SHAREHOLDING',
  OPERATION_TEMPLATES: 'OPERATION_TEMPLATES',
  MOVEMENT_ORDERS: 'MOVEMENT_ORDERS',
  FUND_MANAGEMENT: 'FUND_MANAGEMENT',
};

export const dashboardTab = {
  TRANSMIT: Enum.Dashboard.Tab.TRANSMIT, // 0
  IN_PROGRESS: Enum.Dashboard.Tab.IN_PROGRESS, // 1
  CLOSED: Enum.Dashboard.Tab.CLOSED, // 2
  OPERATION_SUGGESTED: Enum.Dashboard.Tab.OPERATION_SUGGESTED, // 3
  CANCELLED: Enum.Dashboard.Tab.CANCELLED, // 4
  CORPORATE_ACTIONS: Enum.Dashboard.Tab.CORPORATE_ACTIONS, // 5
  SHAREHOLDING: Enum.Dashboard.Tab.SHAREHOLDING, // 6
  OPERATION_TEMPLATES: Enum.Dashboard.Tab.OPERATION_TEMPLATES, // 7
  MOVEMENT_ORDERS: Enum.Dashboard.Tab.MOVEMENT_ORDERS, // 8
  FUND_MANAGEMENT: Enum.Dashboard.Tab.FUND_MANAGEMENT, // 9
};

export const dashboardTabConstName = {
  [dashboardTab.TRANSMIT]: DASHBOARD_STATUSES.TRANSMIT,
  [dashboardTab.IN_PROGRESS]: DASHBOARD_STATUSES.IN_PROGRESS,
  [dashboardTab.CLOSED]: DASHBOARD_STATUSES.CLOSED,
  [dashboardTab.OPERATION_SUGGESTED]: DASHBOARD_STATUSES.OPERATION_SUGGESTED,
  [dashboardTab.CANCELLED]: DASHBOARD_STATUSES.CANCELLED,
  [dashboardTab.CORPORATE_ACTIONS]: DASHBOARD_STATUSES.CORPORATE_ACTIONS,
  [dashboardTab.SHAREHOLDING]: DASHBOARD_STATUSES.SHAREHOLDING,
  [dashboardTab.OPERATION_TEMPLATES]: DASHBOARD_STATUSES.OPERATION_TEMPLATES,
  [dashboardTab.MOVEMENT_ORDERS]: DASHBOARD_STATUSES.MOVEMENT_ORDERS,
  [dashboardTab.FUND_MANAGEMENT]: DASHBOARD_STATUSES.FUND_MANAGEMENT,
};

export const ACCOUNT_NAVIGATION = {
  PARAMETERS: 'parameters',
  ACCOUNT: 'account',
  LIBRARY: 'library',
};

export const DASHBOARD_RESULT_RANGE = [10, 20, 30, 40, 50];

export const FAQ_URL = {
  BANK: 'https://www.utocat.com/faq-banques',
  COMPANY: 'https://www.utocat.com/faq-entreprises',
  INVESTOR: 'https://www.utocat.com/faq-investisseurs',
};

//Disposal mode
export const FILL_IN_DIRECTLY_MODE = 'fill_in_directly_mode';

// --------------------------------------------------------------------------------------------
// Movement orders constants
// --------------------------------------------------------------------------------------------
export const STATUS_CODE_TO_VALIDATE = [30, 40, 80];
