import React from 'react';
import PropTypes from 'prop-types';
import AmpersandSvg from './svg/icon-ampersand-svg';
import './styles.scss';

const IconAmpersand = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="ampersand">
      <AmpersandSvg
        className="ampersand__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconAmpersand.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconAmpersand;
