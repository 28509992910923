import dayjs from 'dayjs';
import { isAxiosError } from 'axios';
import { utils } from '@utocat/catalizr-const-sharing-lib';

// Const
import * as CommonAction from './invest-details-common.actions';
import * as OperationUtils from '../../../shared/utils/OperationUtils';
import { FAKE_IBAN_PATTERN, SUPPORT_CTO } from '../../../shared/utils/CatalizrConstants';

// Api
import { getInvestorByEmail } from '../../../shared/api/investor.api';

// Actions
import { dispatchChangeToInvestDetailsForm } from '../../../shared/utils/CatalizrFormUtils';
import { getCurrentPortfolioType } from '../../../shared/utils/CatalizrUtils';
import { User } from '../../../shared/utils/user';

// Utils
import { create, increase } from '../../../shared/utils/OperationUtils';
import { resetInvestorOnEmailDoesNotExist } from '../../../shared/actions/actions/investor.actions';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Dispatches the investor data to redux and the redux-form
 * @param {string} investment the full investment data (containing a investor property)
 * @param {func} dispatch the redux dispatch function
 */
export const initInvestDetailsWithInvestorData = (investment = null, dispatch) => {
  const investorData = investment ? investment.investor : null;
  if (investorData) {
    investorData.ppe = investorData?.ppe ? 'ppe_on' : 'ppe_off';
    investorData.ppe_partner = investorData?.ppe_partner ? 'ppe_partner_on' : 'ppe_partner_off';
    investorData.ppe_family = investorData?.ppe_family ? 'ppe_family_on' : 'ppe_family_off';
    investorData.investor_investment_ownership = investorData?.investor_investment_ownership
      ? 'investor_investment_ownership_on'
      : 'investor_investment_ownership_off';
  }
  if (investorData && dispatch) {
    dispatchToRedux(dispatch, investorData, User.INVESTOR, investment);
    // init seller data for repurchase only
    if (OperationUtils.repurchase()) {
      const sellerData = investment.custom1;
      dispatchChangeToInvestDetailsForm('seller', sellerData, dispatch);
    }
  }
  if (investment.investor_email) {
    dispatch(getInvestorExistByEmail(investment.investor_email, investment));
  }
  // for repurshase draft mode
  if (investment.isDraftMode && OperationUtils.repurchase()) {
    const sellerData = investment.custom1;
    dispatchChangeToInvestDetailsForm('seller', sellerData, dispatch);
  }
};

/**
 * Retrieves an investor by its email and dispatch the result to redux and redux-form
 * @param {string} investorEmail
 * @param {object} investment for init in case of new portfolio type not registered
 */
export const getInvestorExistByEmail =
  (investorEmail, investment = null) =>
  dispatch => {
    return getInvestorByEmail(investorEmail)
      .then(({ data: investorData }) => {
        const data = {
          ...investorData,
          ppe: investorData?.ppe ? 'ppe_on' : 'ppe_off',
          ppe_partner: investorData?.ppe_partner ? 'ppe_partner_on' : 'ppe_partner_off',
          ppe_family: investorData?.ppe_family ? 'ppe_family_on' : 'ppe_family_off',
          investor_investment_ownership: investorData?.investor_investment_ownership
            ? 'investor_investment_ownership_on'
            : 'investor_investment_ownership_off',
          registered_country: 'France',
        };
        dispatchToRedux(dispatch, data, User.INVESTOR, investment);
      })
      .catch(err => {
        dispatch(resetInvestorOnEmailDoesNotExist());
        if (!isAxiosError(err)) {
          throw err;
        } else if (err.response.status !== 404) {
          // do not throw error if just api return user not found
          throw err;
        }
      });
  };

/**
 * Checks if an email is linked to an investor account
 * @param {string} email the email to test
 * @returns {boolean}
 */
export const isEmailLinkedToAnInvestorAccount = async email => {
  try {
    const resp = await getInvestorByEmail(email);
    return resp && resp.data && resp.data.email === email;
  } catch (e) {
    return false;
  }
};

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 *
 * @param {object} investorDataFromForm the investor data contained in the form
 * @param {object} investmentDataFromForm the investment data contained in the form
 */
export const buildInvestorData = (
  investorDataFromForm,
  investmentFormRedux,
  investmentDataFromForm,
) => {
  // common investor data

  let investorData = {
    title: investorDataFromForm.title,
    name: investorDataFromForm.name,
    surname: investorDataFromForm.surname,
    address: investorDataFromForm.address,
    zip: investorDataFromForm.zip,
    city: investorDataFromForm.city,
    country: investorDataFromForm.country,
    registered_country: investorDataFromForm.registered_country,
    email: investorDataFromForm.email,
    bank_name: investorDataFromForm.bank_name,
    iban: investorDataFromForm.iban || '',
    trading_account_number: investorDataFromForm.trading_account_number
      ? investorDataFromForm.trading_account_number
      : '',
    portfolio_type: investmentFormRedux.portfolio_type,
    ppe: investorDataFromForm.ppe === 'ppe_on',
    ppe_partner: investorDataFromForm.ppe_partner === 'ppe_partner_on',
    ppe_function: investorDataFromForm.ppe_function,
    ppe_country: investorDataFromForm.ppe_country,
    ppe_family: investorDataFromForm.ppe_family === 'ppe_family_on',
    family_ppe_function: investorDataFromForm.family_ppe_function,
    family_ppe_country: investorDataFromForm.family_ppe_country,
    share_capital: investorDataFromForm.share_capital,
    place_of_registration: investorDataFromForm.place_of_registration,
    country_of_tax_residence: investorDataFromForm.country_of_tax_residence,
    tax_residence: investorDataFromForm.tax_residence,
    tax_regime: investorDataFromForm.tax_regime,
  };

  // extra investor datas linked to display.investor_birth feature flag
  if (investorDataFromForm.birth_date && investorDataFromForm.birth_city) {
    _addDateToPayload(investorDataFromForm, investorData, 'birth_date');
    investorData.birth_city = investorDataFromForm.birth_city;
  }

  // extra investor datas linked to display.occupation
  if (investorDataFromForm.occupation) {
    investorData.occupation = investorDataFromForm.occupation;
  }

  // extra investor datas linked to display.giin_number
  if (investorDataFromForm.giin_number) {
    investorData.giin_number = investorDataFromForm.giin_number;
  }

  if (!OperationUtils.disposal()) {
    // extra investor data (all operations except disposal)
    investorData.phone = investorDataFromForm.phone;
  }

  if (OperationUtils.disposal()) {
    investorData.phone = investmentDataFromForm.investor_phone;
  }

  // extra investor data for CTO legal person only if investor is a legal person
  if (
    utils.users.isLegalEntity(investorDataFromForm.title) &&
    SUPPORT_CTO === investmentFormRedux.portfolio_type
  ) {
    investorData.siren = investorDataFromForm.siren;
    investorData.company_name = investorDataFromForm.company_name;
  }

  //FIXME: pb si investor personne morale deja en db et que l'utilisateur reprend une personne physique (ex: Monsieur)
  // les données siren et company_name seront tjs stockées en bdd et se seront pas reset

  return investorData;
};

export const buildInvestorCompanyDataToDispatch = company => {
  const companyData = {
    id: company.id,
    siren: company.siren,
    company_name: company.name,
    lastname: company.lastname,
    surname: company.surname,
    boss: company.boss,
    user: company.user,
    address: company.address,
    country: company.country,
    registered_country: company.registered_country,
    city: company.city,
    zip: company.zip,
    email: company.email,
    legal_form: company.legal_form,
    bank_name: company.bank_name,
    fundraising_default: company.fundraising_default,
    in_progress: company.in_progress,
    data_from_insee: company.data_from_insee,
    eligibility: company.eligibility,
    is_ceased: company.is_ceased,
    eligibility_comment: company.eligibility_comment,
  };

  // dispatch the company iban only if not a fake generated (fake company iban are generated for repurchase operations, only for new company)
  if (typeof company.iban === 'string' && !company.iban.startsWith(FAKE_IBAN_PATTERN)) {
    companyData.iban = company.iban;
  }

  // if the company changes in the form (click on a company suggestion), we empty the iban field
  // we empty IBAN only if it is required in form (increase and create only for now)
  if ((increase() || create()) && !company.user) {
    companyData.iban = '';
  }

  return companyData;
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

/**
 * Dispatch the provided investor data to redux and redux-form
 * @param {object} investment current investment in case of new portfolio type not registerd
 * @param {object} investorData the investor data returned by the API call
 * @param {string} reduxObjectName the name of the redux form object to dispatch data
 * @param {function} dispatch the redux dispatch function
 */
export const dispatchToRedux = (
  dispatch,
  investorData,
  reduxObjectName = User.INVESTOR,
  investment = null,
) => {
  // retrieves the IBAN to use in the investor portfolios/accounts
  const ibanAccountToUse = investorData.ibans?.find(
    ibanAccount => ibanAccount.portfolio_type === getCurrentPortfolioType(),
  );
  if (ibanAccountToUse) {
    // reuse registered portfolio
    investorData.iban = ibanAccountToUse.iban;
    investorData.trading_account_number = ibanAccountToUse.trading_account_number;
  } else if (investment) {
    // case of init on a new portfolio not registered yet
    investorData.iban = investment.investor_iban;
    investorData.trading_account_number = investment.investor_trading_account_number;
  } else {
    // clean as switch of investor mail for example
    investorData.iban = '';
    investorData.trading_account_number = '';
  }
  // dispatch investor data to redux
  dispatch(CommonAction.setInvestorData(investorData));
  // dispatch investor data to the redux form (invest-details)
  dispatchChangeToInvestDetailsForm(reduxObjectName, investorData, dispatch);
};

const _addDateToPayload = (investorDataFromForm, payload, dateField) => {
  const dateFromForm = investorDataFromForm[dateField];
  const isValidDateFromForm = dateFromForm && dayjs(dateFromForm).isValid();

  // only if valid date given
  if (isValidDateFromForm) {
    payload[dateField] = dayjs(dateFromForm).format('MM-DD-YYYY');
  }

  if (dayjs.isDayjs(dateFromForm) && dateFromForm) {
    payload[dateField] = dateFromForm.format('MM-DD-YYYY');
  }
  if (typeof dateFromForm === 'object' && dateFromForm) {
    payload[dateField] = dayjs(dateFromForm).format('MM-DD-YYYY');
  } else if (!dateFromForm) {
    payload[dateField] = undefined;
  }
};
