// Const
import * as SSOConst from '../const/sso.const';

// Actions
import { getUserFulfilled } from '../../../shared/actions/actions/user.actions';
import { setNewUrl } from '../../../pages/login/actions/login.actions';
import { fetchBankKit } from '../../../shared/actions/actions/bank.actions';
import { setJourney } from '../../../redux/actions';

// API
import { getSSOStatus, getIsSSOEnabledByEmail } from '../../api/sso.api';

// Utils
import { JOURNEY } from '../../../shared/utils/JourneyUtils';

// Other
import queryString from 'query-string';

export const fetchSSOStatus = () => ({
  type: SSOConst.GET_SSO_STATUS,
  payload: getSSOStatus(),
});

export const fetchSSOStatusByEmail = email => ({
  type: SSOConst.GET_SSO_STATUS_BY_EMAIL,
  payload: getIsSSOEnabledByEmail(email),
});

const connectSSOPending = () => ({ type: SSOConst.CONNECT_SSO_PENDING });
const connectSSOFulfilled = () => ({ type: SSOConst.CONNECT_SSO_FULFILLED });
const connectSSORejected = () => ({ type: SSOConst.CONNECT_SSO_REJECTED });

export const connectSSOActive = history => async dispatch => {
  dispatch(connectSSOPending());

  try {
    const data = queryString.parse(history.location.search);
    let redirectUrl = '/bank/dashboard';

    if (localStorage.getItem('ssoRedirectUrl')) {
      // this is only for the PAPI partial flow
      redirectUrl = localStorage.getItem('ssoRedirectUrl');
      localStorage.removeItem('ssoRedirectUrl');
      dispatch(setJourney(JOURNEY.BANK_COMPLETE));
    }

    dispatch(setNewUrl(redirectUrl));
    dispatch(getUserFulfilled(data));
    dispatch(fetchBankKit());
    dispatch(connectSSOFulfilled());
    history.push(redirectUrl);
  } catch (error) {
    dispatch(connectSSORejected());
  }
};
