import * as React from 'react';
import PropTypes from 'prop-types';
import { cssStringToJson } from '../utils';

const LCLSvg = ({ alt, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 80 60"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      width="81"
      height="60"
      aria-label={alt}
      {...props}
    >
      <defs>
        <linearGradient
          id="linear0"
          gradientUnits="userSpaceOnUse"
          x1="1799.608"
          y1="1942.563"
          x2="4525.93"
          y2="4657.735"
          gradientTransform="matrix(0.573643,0,0,0.576923,0,0)"
        >
          <stop
            offset="0"
            style={cssStringToJson('stop-color:rgb(100%,100%,100%); stop-opacity:1;')}
          />
          <stop
            offset="1"
            style={cssStringToJson(
              'stop-color:rgb(47.843137%,47.45098%,50.196078%); stop-opacity:1;',
            )}
          />
        </linearGradient>
        <clipPath id="clip1">
          <path d="M 8 16.707031 L 60.960938 16.707031 L 60.960938 51 L 8 51 Z M 8 16.707031 " />
        </clipPath>
        <clipPath id="clip2">
          <path d="M 9 16.707031 L 60.960938 16.707031 L 60.960938 51 L 9 51 Z M 9 16.707031 " />
        </clipPath>
        <clipPath id="clip3">
          <path d="M 8 16.707031 L 60.960938 16.707031 L 60.960938 51 L 8 51 Z M 8 16.707031 " />
        </clipPath>
        <clipPath id="clip4">
          <path d="M 9 16.707031 L 60.960938 16.707031 L 60.960938 51 L 9 51 Z M 9 16.707031 " />
        </clipPath>
        <clipPath id="clip5">
          <path d="M 9 16.707031 L 60.960938 16.707031 L 60.960938 50 L 9 50 Z M 9 16.707031 " />
        </clipPath>
        <clipPath id="clip6">
          <path d="M 9 16.707031 L 60.960938 16.707031 L 60.960938 49 L 9 49 Z M 9 16.707031 " />
        </clipPath>
      </defs>
      <g id="surface1">
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(15.294118%,18.039216%,50.196078%); fill-opacity:1;',
          )}
          d="M 0.574219 0 L 74 0 L 74 60 L 0.574219 60 Z M 0.574219 0 "
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,50.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 38.113017 13.142188 C 20.442251 18.592708 7.980785 37.002604 7.980785 53.807813 C 7.980785 80.809896 30.390995 94.222917 56.491976 94.222917 C 79.351615 94.222917 95.129329 81.967708 103.648068 69.807292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,50.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.351615 94.222917 56.491976 94.222917 C 30.30247 94.222917 7.980785 80.721875 7.980785 53.807813 C 7.980785 37.002604 20.442251 18.592708 38.113017 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,50.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.351615 94.222917 56.491976 94.222917 C 30.30247 94.222917 7.980785 80.721875 7.980785 53.807813 C 7.980785 37.002604 20.442251 18.592708 38.113017 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,49.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.351615 94.128125 56.5805 94.128125 C 30.390995 94.128125 8.06931 80.627083 8.06931 53.719792 C 8.06931 37.002604 20.530775 18.497917 38.113017 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,49.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.263091 94.128125 56.5805 94.128125 C 30.479519 94.128125 8.06931 80.627083 8.06931 53.719792 C 8.06931 37.002604 20.530775 18.592708 38.113017 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,49.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.263091 94.128125 56.5805 94.128125 C 30.479519 94.128125 8.06931 80.627083 8.06931 53.719792 C 8.06931 37.002604 20.442251 18.592708 38.113017 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,49.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.263091 94.128125 56.5805 94.128125 C 30.479519 94.128125 8.06931 80.627083 8.06931 53.719792 C 8.06931 37.002604 20.442251 18.592708 38.017684 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,17.647059%,49.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.263091 94.128125 56.5805 94.128125 C 30.479519 94.128125 8.06931 80.627083 8.06931 53.719792 C 8.06931 37.002604 20.442251 18.592708 38.017684 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,17.647059%,49.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.263091 94.040104 56.5805 94.040104 C 30.479519 94.040104 8.06931 80.451042 8.06931 53.631771 C 8.06931 36.921354 20.442251 18.592708 38.017684 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.217853 81.967708 79.263091 94.040104 56.669024 94.040104 C 30.479519 94.040104 8.157834 80.539062 8.157834 53.631771 C 8.157834 36.921354 20.530775 18.592708 38.113017 13.142188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.967708 79.263091 94.040104 56.669024 94.040104 C 30.479519 94.040104 8.157834 80.451042 8.157834 53.631771 C 8.157834 36.921354 20.530775 18.592708 38.017684 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.967708 79.263091 94.040104 56.669024 94.040104 C 30.568043 94.040104 8.157834 80.451042 8.157834 53.631771 C 8.157834 36.921354 20.530775 18.592708 38.017684 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.967708 79.263091 94.040104 56.669024 94.040104 C 30.568043 94.040104 8.157834 80.451042 8.157834 53.631771 C 8.157834 36.921354 20.530775 18.680729 38.017684 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.952083 56.669024 93.952083 C 30.568043 94.040104 8.253167 80.363021 8.253167 53.631771 C 8.253167 36.921354 20.619299 18.680729 38.017684 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.952083 56.669024 93.952083 C 30.568043 93.952083 8.253167 80.363021 8.253167 53.631771 C 8.253167 37.002604 20.619299 18.680729 38.017684 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.952083 56.757549 93.952083 C 30.663376 93.952083 8.253167 80.363021 8.253167 53.536979 C 8.253167 36.921354 20.530775 18.592708 38.017684 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.952083 56.757549 93.952083 C 30.663376 93.952083 8.253167 80.268229 8.253167 53.536979 C 8.253167 36.921354 20.530775 18.680729 37.92916 13.230208 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.952083 56.757549 93.952083 C 30.663376 93.952083 8.341691 80.268229 8.341691 53.631771 C 8.341691 37.002604 20.619299 18.76875 38.017684 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.647059%,49.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.857292 56.757549 93.857292 C 30.663376 93.857292 8.341691 80.180208 8.341691 53.536979 C 8.341691 36.921354 20.619299 18.680729 38.017684 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.254902%,49.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.857292 56.757549 93.857292 C 30.663376 93.857292 8.341691 80.180208 8.341691 53.536979 C 8.341691 36.921354 20.619299 18.680729 37.92916 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.254902%,48.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.263091 93.857292 56.757549 93.857292 C 30.663376 93.857292 8.341691 80.180208 8.341691 53.536979 C 8.341691 36.921354 20.619299 18.76875 37.92916 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.254902%,48.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.167758 93.857292 56.852882 93.857292 C 30.7519 93.857292 8.430215 80.180208 8.430215 53.536979 C 8.430215 36.921354 20.707823 18.76875 38.017684 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.254902%,48.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.167758 93.857292 56.852882 93.857292 C 30.7519 93.857292 8.430215 80.180208 8.430215 53.536979 C 8.430215 36.921354 20.707823 18.76875 38.017684 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.254902%,48.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.167758 93.769271 56.852882 93.769271 C 30.7519 93.769271 8.430215 80.092188 8.430215 53.448958 C 8.430215 36.921354 20.707823 18.76875 37.92916 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.901961%,17.254902%,48.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.167758 93.769271 56.852882 93.769271 C 30.7519 93.769271 8.430215 80.092188 8.430215 53.448958 C 8.430215 36.921354 20.707823 18.76875 37.92916 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,48.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.879688 79.167758 93.769271 56.852882 93.769271 C 30.7519 93.769271 8.430215 79.997396 8.430215 53.448958 C 8.518739 36.921354 20.707823 18.76875 37.92916 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,48.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.769271 56.852882 93.769271 C 30.7519 93.769271 8.430215 79.997396 8.430215 53.448958 C 8.518739 36.921354 20.707823 18.76875 37.92916 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,48.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.769271 56.941406 93.769271 C 30.840424 93.769271 8.518739 79.997396 8.518739 53.448958 C 8.518739 36.833333 20.707823 18.76875 37.92916 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,48.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.68125 56.941406 93.68125 C 30.840424 93.68125 8.518739 79.922917 8.518739 53.360937 C 8.518739 36.833333 20.707823 18.76875 37.92916 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,48.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.68125 56.941406 93.68125 C 30.840424 93.68125 8.518739 79.922917 8.518739 53.360937 C 8.518739 36.833333 20.803157 18.76875 37.92916 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,48.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.68125 56.941406 93.68125 C 30.840424 93.68125 8.518739 79.922917 8.518739 53.360937 C 8.607264 36.833333 20.803157 18.76875 37.92916 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,17.254902%,47.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.68125 56.941406 93.68125 C 30.840424 93.68125 8.518739 79.922917 8.518739 53.360937 C 8.607264 36.833333 20.803157 18.76875 37.92916 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.68125 57.02993 93.68125 C 30.928948 93.68125 8.607264 79.922917 8.607264 53.360937 C 8.607264 36.833333 20.803157 18.76875 37.92916 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.593229 57.02993 93.593229 C 30.928948 93.593229 8.607264 79.740104 8.607264 53.279688 C 8.607264 36.738542 20.803157 18.76875 37.840636 13.311458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.593229 57.02993 93.593229 C 30.928948 93.593229 8.607264 79.740104 8.607264 53.279688 C 8.607264 36.738542 20.803157 18.76875 37.840636 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.593229 57.02993 93.593229 C 30.928948 93.593229 8.607264 79.740104 8.607264 53.279688 C 8.607264 36.833333 20.803157 18.76875 37.840636 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.593229 57.02993 93.593229 C 30.928948 93.593229 8.702597 79.740104 8.702597 53.279688 C 8.702597 36.833333 20.803157 18.856771 37.840636 13.399479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.593229 57.02993 93.593229 C 30.928948 93.593229 8.702597 79.740104 8.702597 53.360937 C 8.702597 36.921354 20.803157 18.951563 37.840636 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.648068 69.807292 C 95.129329 81.791667 79.167758 93.498438 57.118454 93.498438 C 31.017473 93.498438 8.777502 79.652083 8.777502 53.279688 C 8.777502 36.833333 20.891681 18.856771 37.92916 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.498438 57.206978 93.498438 C 31.112806 93.498438 8.872836 79.652083 8.872836 53.279688 C 8.872836 36.833333 20.980205 18.856771 37.92916 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.509804%,16.862745%,47.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.498438 57.206978 93.498438 C 31.112806 93.498438 8.872836 79.652083 8.872836 53.279688 C 8.872836 36.833333 20.980205 18.951563 37.92916 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.862745%,47.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.498438 57.206978 93.498438 C 31.112806 93.498438 8.872836 79.557292 8.872836 53.279688 C 8.872836 36.833333 20.980205 18.951563 37.92916 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.862745%,47.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.498438 57.206978 93.498438 C 31.112806 93.498438 8.872836 79.557292 8.872836 53.279688 C 8.872836 36.833333 20.980205 18.951563 37.840636 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.862745%,47.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.410417 57.206978 93.410417 C 31.112806 93.410417 8.872836 79.469271 8.872836 53.191667 C 8.872836 36.738542 20.980205 18.856771 37.840636 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,47.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.410417 57.288693 93.410417 C 31.289854 93.410417 8.96136 79.469271 8.96136 53.191667 C 8.96136 36.738542 21.068729 18.951563 37.92916 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,47.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.410417 57.288693 93.410417 C 31.289854 93.410417 8.96136 79.469271 8.96136 53.191667 C 8.96136 36.833333 21.068729 18.951563 37.840636 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,47.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.410417 57.288693 93.410417 C 31.289854 93.410417 8.96136 79.469271 8.96136 53.191667 C 8.96136 36.833333 20.980205 18.951563 37.840636 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,47.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.410417 57.288693 93.410417 C 31.289854 93.410417 8.96136 79.469271 8.96136 53.191667 C 8.96136 36.833333 20.980205 18.951563 37.840636 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.322396 57.288693 93.322396 C 31.289854 93.322396 8.96136 79.38125 8.96136 53.096875 C 8.96136 36.738542 20.980205 18.951563 37.840636 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.322396 57.288693 93.322396 C 31.289854 93.322396 8.96136 79.38125 8.96136 53.096875 C 8.96136 36.738542 20.980205 18.951563 37.752111 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.322396 57.377217 93.322396 C 31.378378 93.322396 9.049884 79.293229 9.049884 53.096875 C 9.049884 36.738542 21.068729 18.951563 37.840636 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.696875 79.167758 93.322396 57.377217 93.322396 C 31.378378 93.322396 9.049884 79.293229 9.049884 53.096875 C 9.049884 36.738542 21.068729 18.951563 37.752111 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.167758 93.322396 57.377217 93.322396 C 31.378378 93.322396 9.049884 79.293229 9.049884 53.096875 C 9.049884 36.738542 21.068729 19.039583 37.752111 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.167758 93.241146 57.377217 93.241146 C 31.378378 93.241146 9.049884 79.198437 9.049884 53.008854 C 9.049884 36.650521 21.068729 18.951563 37.752111 13.4875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.167758 93.241146 57.377217 93.241146 C 31.378378 93.241146 9.049884 79.198437 9.049884 53.008854 C 9.049884 36.650521 21.068729 18.951563 37.752111 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.167758 93.241146 57.377217 93.241146 C 31.378378 93.241146 9.138408 79.198437 9.138408 53.008854 C 9.138408 36.650521 21.157253 18.951563 37.752111 13.582292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(14.117647%,16.470588%,46.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.167758 93.241146 57.472551 93.241146 C 31.466902 93.241146 9.226932 79.198437 9.226932 53.096875 C 9.226932 36.833333 21.252587 19.039583 37.840636 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,46.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.241146 57.472551 93.241146 C 31.466902 93.241146 9.226932 79.198437 9.226932 53.096875 C 9.226932 36.833333 21.157253 19.127604 37.840636 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,46.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.153125 57.472551 93.153125 C 31.466902 93.153125 9.226932 79.022396 9.226932 53.008854 C 9.226932 36.738542 21.157253 19.039583 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.153125 57.472551 93.153125 C 31.466902 93.153125 9.226932 79.022396 9.226932 53.008854 C 9.226932 36.738542 21.157253 19.039583 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.153125 57.472551 93.153125 C 31.466902 93.153125 9.226932 79.022396 9.226932 53.008854 C 9.226932 36.738542 21.157253 19.039583 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.153125 57.472551 93.153125 C 31.466902 93.153125 9.226932 79.022396 9.226932 53.008854 C 9.226932 36.738542 21.157253 19.127604 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.153125 57.561075 93.153125 C 31.562236 93.153125 9.322266 79.022396 9.322266 53.008854 C 9.226932 36.650521 21.157253 19.039583 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.058333 57.561075 93.058333 C 31.562236 93.058333 9.322266 78.927604 9.322266 52.920833 C 9.322266 36.650521 21.157253 19.039583 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.608854 79.079234 93.058333 57.561075 93.058333 C 31.562236 93.058333 9.322266 78.927604 9.322266 52.920833 C 9.322266 36.650521 21.252587 19.039583 37.752111 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 93.058333 57.561075 93.058333 C 31.562236 93.058333 9.322266 78.927604 9.322266 52.920833 C 9.322266 36.650521 21.252587 19.127604 37.656778 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 93.058333 57.561075 93.058333 C 31.562236 93.058333 9.322266 78.927604 9.322266 52.920833 C 9.322266 36.738542 21.252587 19.127604 37.656778 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 93.058333 57.561075 93.058333 C 31.562236 93.058333 9.322266 78.839583 9.322266 52.920833 C 9.322266 36.738542 21.157253 19.127604 37.656778 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,16.078431%,45.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.970313 57.649599 92.970313 C 31.65076 92.970313 9.41079 78.751563 9.41079 52.832813 C 9.41079 36.650521 21.252587 19.039583 37.656778 13.670312 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,15.686275%,45.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.970313 57.649599 92.970313 C 31.65076 92.970313 9.41079 78.751563 9.41079 52.832813 C 9.41079 36.650521 21.252587 19.127604 37.656778 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,15.686275%,45.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.970313 57.649599 92.970313 C 31.65076 92.970313 9.41079 78.751563 9.41079 52.832813 C 9.41079 36.650521 21.252587 19.127604 37.656778 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,15.686275%,45.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.970313 57.649599 92.970313 C 31.65076 92.970313 9.41079 78.751563 9.41079 52.832813 C 9.41079 36.650521 21.252587 19.127604 37.568254 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.72549%,15.686275%,45.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.970313 57.649599 92.970313 C 31.65076 92.970313 9.41079 78.751563 9.41079 52.832813 C 9.41079 36.650521 21.252587 19.127604 37.568254 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,45.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.882292 57.649599 92.882292 C 31.65076 92.882292 9.41079 78.656771 9.41079 52.738021 C 9.41079 36.5625 21.252587 19.127604 37.568254 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,45.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 79.079234 92.882292 57.738123 92.882292 C 31.739284 92.882292 9.587838 78.656771 9.587838 52.738021 C 9.587838 36.5625 21.429635 19.127604 37.752111 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,45.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 78.990709 92.882292 57.738123 92.882292 C 31.739284 92.882292 9.587838 78.56875 9.587838 52.738021 C 9.587838 36.5625 21.429635 19.127604 37.656778 13.758333 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,44.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 78.990709 92.882292 57.738123 92.882292 C 31.739284 92.882292 9.587838 78.56875 9.587838 52.832813 C 9.587838 36.650521 21.429635 19.208854 37.656778 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,44.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.520833 78.990709 92.882292 57.738123 92.882292 C 31.739284 92.882292 9.587838 78.56875 9.587838 52.832813 C 9.587838 36.738542 21.341111 19.296875 37.656778 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,44.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.7875 57.738123 92.7875 C 31.739284 92.7875 9.587838 78.480729 9.587838 52.738021 C 9.587838 36.650521 21.341111 19.208854 37.568254 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,44.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.7875 57.738123 92.7875 C 31.739284 92.7875 9.587838 78.480729 9.587838 52.738021 C 9.587838 36.650521 21.341111 19.208854 37.568254 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,44.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.7875 57.826647 92.7875 C 31.827808 92.7875 9.683171 78.480729 9.683171 52.738021 C 9.683171 36.650521 21.429635 19.208854 37.656778 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.686275%,44.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.7875 57.826647 92.7875 C 31.827808 92.7875 9.683171 78.480729 9.683171 52.738021 C 9.683171 36.650521 21.429635 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,44.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.7875 57.826647 92.7875 C 31.827808 92.7875 9.683171 78.480729 9.683171 52.738021 C 9.683171 36.650521 21.429635 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,44.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.699479 57.826647 92.699479 C 31.827808 92.699479 9.683171 78.311458 9.683171 52.65 C 9.683171 36.5625 21.429635 19.208854 37.568254 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,44.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.699479 57.826647 92.699479 C 31.923142 92.699479 9.683171 78.311458 9.683171 52.65 C 9.683171 36.5625 21.429635 19.208854 37.47973 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,44.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.699479 57.826647 92.699479 C 31.923142 92.699479 9.683171 78.311458 9.683171 52.65 C 9.683171 36.5625 21.429635 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,44.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.699479 57.921981 92.699479 C 32.011666 92.699479 9.771696 78.311458 9.771696 52.65 C 9.771696 36.5625 21.518159 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,43.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.699479 57.921981 92.699479 C 32.011666 92.699479 9.771696 78.311458 9.771696 52.65 C 9.771696 36.650521 21.518159 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(13.333333%,15.294118%,43.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.611458 57.921981 92.611458 C 32.011666 92.611458 9.771696 78.216667 9.771696 52.561979 C 9.771696 36.5625 21.429635 19.208854 37.47973 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,15.294118%,43.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.611458 57.921981 92.611458 C 32.011666 92.611458 9.771696 78.216667 9.771696 52.561979 C 9.771696 36.5625 21.429635 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,15.294118%,43.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.807292 C 95.217853 81.439583 78.990709 92.611458 57.921981 92.611458 C 32.011666 92.611458 9.771696 78.216667 9.771696 52.561979 C 9.771696 36.5625 21.429635 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,15.294118%,43.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.719271 C 95.217853 81.256771 78.990709 92.516667 57.921981 92.516667 C 32.011666 92.516667 9.771696 78.128646 9.771696 52.467188 C 9.771696 36.467708 21.429635 19.208854 37.391206 13.853125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,15.294118%,43.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.719271 C 95.217853 81.256771 78.902185 92.516667 58.010505 92.516667 C 32.10019 92.516667 9.948744 78.128646 9.948744 52.561979 C 9.948744 36.5625 21.606683 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,15.294118%,43.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.719271 C 95.217853 81.256771 78.902185 92.428646 58.010505 92.428646 C 32.10019 92.516667 9.948744 78.040625 9.948744 52.467188 C 9.948744 36.467708 21.606683 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.719271 C 95.217853 81.256771 78.902185 92.428646 58.010505 92.428646 C 32.10019 92.516667 9.948744 78.040625 9.948744 52.467188 C 9.948744 36.467708 21.606683 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.743402 69.719271 C 95.217853 81.256771 78.902185 92.428646 58.010505 92.428646 C 32.10019 92.516667 9.948744 78.040625 9.948744 52.467188 C 9.948744 36.467708 21.606683 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.428646 58.099029 92.428646 C 32.188714 92.428646 9.948744 77.952604 9.948744 52.467188 C 9.948744 36.467708 21.606683 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.428646 58.099029 92.428646 C 32.188714 92.428646 10.037268 77.952604 10.037268 52.467188 C 10.037268 36.467708 21.702016 19.391667 37.568254 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.340625 58.187553 92.340625 C 32.277238 92.340625 10.132601 77.857813 10.132601 52.379167 C 10.132601 36.467708 21.702016 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.340625 58.187553 92.340625 C 32.277238 92.340625 10.132601 77.857813 10.132601 52.379167 C 10.132601 36.467708 21.702016 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.340625 58.187553 92.340625 C 32.277238 92.340625 10.132601 77.769792 10.132601 52.379167 C 10.132601 36.467708 21.702016 19.296875 37.568254 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.340625 58.187553 92.340625 C 32.277238 92.340625 10.132601 77.769792 10.132601 52.379167 C 10.132601 36.467708 21.702016 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.340625 58.187553 92.340625 C 32.277238 92.340625 10.132601 77.769792 10.132601 52.379167 C 10.132601 36.467708 21.702016 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,43.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.256771 78.990709 92.252604 58.187553 92.252604 C 32.277238 92.252604 10.132601 77.681771 10.132601 52.291146 C 10.132601 36.379688 21.702016 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.941176%,14.901961%,42.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.990709 92.252604 58.282886 92.252604 C 32.372572 92.252604 10.221125 77.681771 10.221125 52.291146 C 10.221125 36.379688 21.790541 19.296875 37.47973 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.901961%,42.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.990709 92.252604 58.282886 92.252604 C 32.372572 92.252604 10.221125 77.681771 10.221125 52.291146 C 10.221125 36.379688 21.790541 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.901961%,42.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.990709 92.252604 58.282886 92.252604 C 32.372572 92.252604 10.221125 77.681771 10.221125 52.291146 C 10.221125 36.379688 21.790541 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.990709 92.252604 58.282886 92.252604 C 32.372572 92.252604 10.221125 77.681771 10.221125 52.291146 C 10.221125 36.379688 21.790541 19.391667 37.391206 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.990709 92.157813 58.282886 92.157813 C 32.372572 92.157813 10.221125 77.586979 10.221125 52.203125 C 10.221125 36.379688 21.702016 19.296875 37.391206 13.941146 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.990709 92.157813 58.282886 92.157813 C 32.372572 92.157813 10.221125 77.498958 10.221125 52.203125 C 10.221125 36.379688 21.702016 19.391667 37.391206 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.313186 81.16875 78.902185 92.157813 58.37141 92.157813 C 32.461096 92.157813 10.309649 77.498958 10.309649 52.203125 C 10.309649 36.379688 21.790541 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.16875 78.902185 92.157813 58.37141 92.157813 C 32.461096 92.157813 10.309649 77.498958 10.309649 52.203125 C 10.309649 36.379688 21.790541 19.391667 37.391206 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.16875 78.902185 92.157813 58.37141 92.157813 C 32.461096 92.157813 10.398174 77.498958 10.398174 52.203125 C 10.398174 36.379688 21.879065 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.16875 78.902185 92.069792 58.37141 92.069792 C 32.461096 92.069792 10.398174 77.410938 10.398174 52.108333 C 10.398174 36.291667 21.879065 19.391667 37.47973 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,42.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.16875 78.902185 92.069792 58.37141 92.069792 C 32.461096 92.069792 10.398174 77.410938 10.398174 52.108333 C 10.398174 36.291667 21.879065 19.391667 37.391206 14.029167 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,41.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.16875 78.902185 92.069792 58.37141 92.069792 C 32.461096 92.069792 10.398174 77.410938 10.398174 52.203125 C 10.398174 36.379688 21.879065 19.479688 37.391206 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,41.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.16875 78.902185 92.069792 58.459935 92.069792 C 32.54962 92.069792 10.486698 77.410938 10.486698 52.203125 C 10.486698 36.379688 21.967589 19.479688 37.47973 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,41.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 92.069792 58.459935 92.069792 C 32.54962 92.069792 10.486698 77.322917 10.486698 52.203125 C 10.486698 36.379688 21.967589 19.567708 37.391206 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,41.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.981771 58.459935 91.981771 C 32.54962 91.981771 10.486698 77.228125 10.486698 52.108333 C 10.486698 36.379688 21.967589 19.479688 37.391206 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.509804%,41.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.981771 58.459935 91.981771 C 32.54962 91.981771 10.486698 77.228125 10.486698 52.108333 C 10.486698 36.379688 21.879065 19.479688 37.391206 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.54902%,14.117647%,41.568627%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.981771 58.459935 91.981771 C 32.54962 91.981771 10.486698 77.228125 10.486698 52.108333 C 10.486698 36.379688 21.879065 19.479688 37.302682 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.568627%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.981771 58.548459 91.981771 C 32.638144 91.981771 10.582031 77.228125 10.582031 52.108333 C 10.582031 36.379688 21.967589 19.567708 37.391206 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.568627%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.981771 58.548459 91.981771 C 32.638144 91.981771 10.582031 77.228125 10.582031 52.108333 C 10.582031 36.379688 21.967589 19.567708 37.391206 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.568627%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.886979 58.548459 91.886979 C 32.638144 91.886979 10.582031 77.140104 10.582031 52.020313 C 10.582031 36.291667 21.967589 19.479688 37.391206 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.568627%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.886979 58.548459 91.886979 C 32.726668 91.886979 10.582031 77.140104 10.582031 52.020313 C 10.582031 36.291667 21.967589 19.479688 37.302682 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.568627%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.886979 58.548459 91.886979 C 32.726668 91.886979 10.582031 77.052083 10.582031 52.020313 C 10.582031 36.291667 21.967589 19.567708 37.302682 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.176471%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.886979 58.548459 91.886979 C 32.726668 91.886979 10.582031 77.052083 10.582031 52.020313 C 10.582031 36.291667 21.967589 19.567708 37.302682 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.176471%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.902185 91.886979 58.636983 91.886979 C 32.822002 91.886979 10.670555 77.052083 10.670555 52.020313 C 10.670555 36.291667 22.062922 19.567708 37.302682 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.176471%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.806852 91.8125 58.636983 91.8125 C 32.822002 91.8125 10.670555 76.970833 10.670555 51.932292 C 10.670555 36.196875 22.062922 19.479688 37.302682 14.117188 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.176471%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 81.080729 78.806852 91.8125 58.636983 91.8125 C 32.822002 91.8125 10.670555 76.970833 10.670555 51.932292 C 10.670555 36.291667 21.967589 19.567708 37.302682 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,41.176471%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.8125 58.636983 91.8125 C 32.822002 91.8125 10.670555 76.970833 10.670555 51.932292 C 10.670555 36.291667 21.967589 19.567708 37.207348 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,14.117647%,40.784314%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.8125 58.636983 91.8125 C 32.822002 91.8125 10.759079 76.970833 10.759079 51.932292 C 10.759079 36.291667 22.062922 19.567708 37.302682 14.211979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,13.72549%,40.784314%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.8125 58.636983 91.8125 C 32.822002 91.8125 10.759079 76.970833 10.759079 51.932292 C 10.759079 36.291667 22.062922 19.6625 37.302682 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,13.72549%,40.784314%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.717708 58.732316 91.717708 C 32.910526 91.717708 10.847603 76.882812 10.847603 51.8375 C 10.759079 36.291667 22.062922 19.6625 37.302682 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,13.72549%,40.784314%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.717708 58.732316 91.717708 C 32.910526 91.717708 10.847603 76.882812 10.847603 51.932292 C 10.847603 36.291667 22.151446 19.6625 37.302682 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,13.72549%,40.784314%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.717708 58.732316 91.717708 C 32.910526 91.717708 10.847603 76.882812 10.847603 51.932292 C 10.847603 36.291667 22.151446 19.6625 37.302682 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(12.156863%,13.72549%,40.784314%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.717708 58.732316 91.717708 C 32.910526 91.717708 10.847603 76.788021 10.847603 51.932292 C 10.847603 36.291667 22.151446 19.6625 37.302682 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40.392157%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.717708 58.732316 91.717708 C 32.910526 91.717708 10.847603 76.788021 10.847603 51.8375 C 10.847603 36.196875 22.151446 19.6625 37.207348 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40.392157%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.629688 58.732316 91.629688 C 32.910526 91.629688 10.847603 76.7 10.847603 51.8375 C 10.847603 36.196875 22.151446 19.6625 37.207348 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40.392157%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.629688 58.82084 91.629688 C 32.99224 91.629688 10.942937 76.7 10.942937 51.8375 C 10.942937 36.291667 22.233161 19.6625 37.302682 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40.392157%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.629688 58.82084 91.629688 C 32.99224 91.629688 10.942937 76.7 10.942937 51.8375 C 10.942937 36.291667 22.151446 19.6625 37.207348 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40.392157%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.629688 58.82084 91.629688 C 32.99224 91.629688 10.942937 76.7 10.942937 51.8375 C 10.942937 36.291667 22.151446 19.750521 37.207348 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40.392157%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.992708 78.806852 91.629688 58.82084 91.629688 C 32.99224 91.629688 10.942937 76.611979 10.942937 51.8375 C 10.942937 36.291667 22.151446 19.750521 37.207348 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.806852 91.541667 58.82084 91.541667 C 32.99224 91.541667 10.942937 76.517188 10.942937 51.763021 C 10.942937 36.196875 22.151446 19.6625 37.118824 14.3 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.72549%,40%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.806852 91.541667 58.82084 91.541667 C 32.99224 91.541667 10.942937 76.517188 10.942937 51.763021 C 10.942937 36.196875 22.151446 19.6625 37.118824 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,40%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.806852 91.541667 58.909364 91.541667 C 33.080764 91.541667 11.031461 76.517188 11.031461 51.763021 C 11.031461 36.196875 22.233161 19.750521 37.207348 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,40%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.541667 58.909364 91.541667 C 33.080764 91.541667 11.031461 76.517188 11.031461 51.763021 C 11.031461 36.196875 22.233161 19.750521 37.118824 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,40%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.541667 58.909364 91.541667 C 33.080764 91.541667 11.031461 76.517188 11.031461 51.763021 C 11.031461 36.196875 22.233161 19.750521 37.118824 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,39.607843%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.446875 58.909364 91.446875 C 33.080764 91.446875 11.031461 76.429167 11.031461 51.668229 C 11.031461 36.108854 22.233161 19.6625 37.118824 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,39.607843%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.446875 58.909364 91.446875 C 33.080764 91.446875 11.031461 76.429167 11.031461 51.668229 C 11.031461 36.108854 22.233161 19.750521 37.118824 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,39.607843%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.446875 58.909364 91.446875 C 33.080764 91.446875 11.119985 76.429167 11.119985 51.668229 C 11.119985 36.196875 22.233161 19.750521 37.118824 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,39.607843%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.446875 58.997889 91.446875 C 33.169288 91.446875 11.208509 76.341146 11.208509 51.668229 C 11.208509 36.196875 22.321685 19.750521 37.207348 14.388021 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.764706%,13.333333%,39.607843%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.831926 69.719271 C 95.217853 80.897917 78.731947 91.446875 58.997889 91.446875 C 33.169288 91.446875 11.208509 76.341146 11.208509 51.668229 C 11.208509 36.196875 22.321685 19.750521 37.207348 14.482812 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,13.333333%,39.607843%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.897917 78.806852 91.446875 59.093222 91.446875 C 33.257812 91.446875 11.297033 76.341146 11.297033 51.668229 C 11.297033 36.196875 22.410209 19.838542 37.207348 14.482812 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,13.333333%,39.215686%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.897917 78.806852 91.358854 59.093222 91.358854 C 33.257812 91.358854 11.297033 76.253125 11.297033 51.668229 C 11.297033 36.196875 22.410209 19.838542 37.207348 14.482812 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,13.333333%,39.215686%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.897917 78.806852 91.358854 59.093222 91.358854 C 33.257812 91.358854 11.297033 76.253125 11.297033 51.668229 C 11.297033 36.196875 22.410209 19.838542 37.207348 14.482812 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,13.333333%,39.215686%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.358854 59.093222 91.358854 C 33.257812 91.358854 11.297033 76.253125 11.297033 51.668229 C 11.297033 36.196875 22.410209 19.838542 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,39.215686%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.358854 59.181746 91.358854 C 33.353146 91.358854 11.392367 76.253125 11.392367 51.668229 C 11.392367 36.196875 22.498733 19.838542 37.207348 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,39.215686%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.358854 59.181746 91.358854 C 33.353146 91.358854 11.392367 76.253125 11.392367 51.668229 C 11.392367 36.196875 22.498733 19.933333 37.207348 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.823529%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.270833 59.181746 91.270833 C 33.353146 91.270833 11.392367 76.070312 11.392367 51.580208 C 11.392367 36.108854 22.498733 19.838542 37.118824 14.482812 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.823529%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.270833 59.181746 91.270833 C 33.353146 91.270833 11.392367 76.070312 11.392367 51.580208 C 11.392367 36.196875 22.410209 19.838542 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.823529%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.270833 59.181746 91.270833 C 33.353146 91.270833 11.392367 76.070312 11.392367 51.580208 C 11.392367 36.196875 22.410209 19.838542 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.823529%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.270833 59.181746 91.270833 C 33.353146 91.270833 11.392367 76.070312 11.392367 51.580208 C 11.392367 36.196875 22.410209 19.933333 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.823529%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.806852 91.270833 59.27027 91.270833 C 33.44167 91.270833 11.467272 76.070312 11.467272 51.580208 C 11.467272 36.196875 22.498733 19.933333 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.823529%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.731947 91.182812 59.27027 91.182812 C 33.530194 91.182812 11.467272 75.982292 11.467272 51.492188 C 11.467272 36.108854 22.498733 19.838542 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.431373%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.731947 91.182812 59.27027 91.182812 C 33.530194 91.182812 11.467272 75.982292 11.467272 51.492188 C 11.467272 36.108854 22.498733 19.838542 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.431373%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.731947 91.182812 59.27027 91.182812 C 33.530194 91.182812 11.467272 75.982292 11.467272 51.492188 C 11.467272 36.108854 22.498733 19.933333 37.0303 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.431373%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.731947 91.182812 59.27027 91.182812 C 33.530194 91.182812 11.467272 75.8875 11.467272 51.492188 C 11.467272 36.108854 22.498733 19.933333 37.0303 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(11.372549%,12.941176%,38.431373%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.731947 91.182812 59.27027 91.182812 C 33.530194 91.182812 11.467272 75.8875 11.467272 51.492188 C 11.467272 36.108854 22.498733 19.933333 37.0303 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.941176%,38.431373%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.809896 78.731947 91.088021 59.358794 91.088021 C 33.618718 91.088021 11.65113 75.799479 11.65113 51.397396 C 11.65113 36.020833 22.682591 19.838542 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,38.039216%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91.088021 59.358794 91.088021 C 33.618718 91.088021 11.65113 75.799479 11.65113 51.397396 C 11.65113 36.020833 22.682591 19.933333 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,38.039216%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91.088021 59.358794 91.088021 C 33.618718 91.088021 11.65113 75.799479 11.65113 51.397396 C 11.65113 36.108854 22.587257 19.933333 37.118824 14.570833 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,38.039216%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91.088021 59.358794 91.088021 C 33.618718 91.088021 11.65113 75.799479 11.65113 51.397396 C 11.65113 36.108854 22.587257 19.933333 37.0303 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,38.039216%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91.088021 59.358794 91.088021 C 33.618718 91.088021 11.65113 75.799479 11.65113 51.492188 C 11.65113 36.196875 22.587257 20.021354 37.0303 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,38.039216%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91 59.358794 91 C 33.618718 91 11.65113 75.711458 11.65113 51.397396 C 11.65113 36.108854 22.587257 20.021354 37.0303 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,38.039216%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91 59.447318 91 C 33.707242 91 11.739654 75.711458 11.739654 51.397396 C 11.739654 36.108854 22.682591 20.021354 37.118824 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,37.647059%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91 59.447318 91 C 33.707242 91 11.739654 75.616667 11.739654 51.309375 C 11.739654 36.020833 22.682591 19.933333 37.0303 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,37.647059%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91 59.447318 91 C 33.707242 91 11.739654 75.616667 11.739654 51.309375 C 11.739654 36.020833 22.682591 19.933333 37.0303 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,37.647059%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 91 59.447318 91 C 33.707242 90.911979 11.739654 75.528646 11.739654 51.309375 C 11.739654 36.020833 22.682591 20.021354 37.0303 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,37.647059%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 90.911979 59.447318 90.911979 C 33.707242 90.911979 11.739654 75.528646 11.739654 51.309375 C 11.739654 36.020833 22.682591 20.021354 36.941776 14.658854 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,37.647059%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.731947 90.911979 59.447318 90.911979 C 33.707242 90.911979 11.739654 75.528646 11.739654 51.309375 C 11.739654 36.020833 22.682591 20.021354 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.54902%,37.647059%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.636613 90.911979 59.542652 90.911979 C 33.802576 90.911979 11.828178 75.528646 11.828178 51.309375 C 11.828178 36.108854 22.682591 20.021354 37.0303 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.156863%,37.254902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.721875 78.636613 90.911979 59.542652 90.911979 C 33.802576 90.911979 11.828178 75.528646 11.828178 51.309375 C 11.828178 36.108854 22.682591 20.109375 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.156863%,37.254902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.911979 59.542652 90.911979 C 33.802576 90.911979 11.828178 75.528646 11.828178 51.309375 C 11.828178 36.108854 22.682591 20.109375 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.980392%,12.156863%,37.254902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.817188 59.542652 90.817188 C 33.802576 90.817188 11.828178 75.359375 11.828178 51.221354 C 11.828178 36.020833 22.682591 20.021354 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,37.254902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.817188 59.542652 90.817188 C 33.802576 90.817188 11.828178 75.359375 11.828178 51.221354 C 11.828178 36.020833 22.682591 20.021354 36.853252 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,37.254902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.817188 59.542652 90.817188 C 33.802576 90.817188 11.828178 75.359375 11.828178 51.221354 C 11.828178 36.020833 22.682591 20.109375 36.853252 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.862745%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.817188 59.631176 90.817188 C 33.8911 90.817188 11.916702 75.359375 11.916702 51.221354 C 11.916702 36.020833 22.771115 20.109375 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.862745%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.817188 59.631176 90.817188 C 33.8911 90.817188 12.012035 75.359375 12.012035 51.221354 C 12.012035 36.020833 22.859639 20.109375 37.0303 14.828125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.862745%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.729167 59.631176 90.729167 C 33.8911 90.729167 12.012035 75.271354 12.012035 51.133333 C 12.012035 35.932813 22.859639 20.021354 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.862745%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.729167 59.631176 90.729167 C 33.8911 90.729167 12.012035 75.271354 12.012035 51.133333 C 12.012035 35.932813 22.859639 20.109375 36.941776 14.746875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.862745%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.729167 59.631176 90.729167 C 33.8911 90.729167 12.012035 75.271354 12.012035 51.133333 C 12.012035 35.932813 22.771115 20.109375 36.941776 14.828125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.862745%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.729167 59.631176 90.729167 C 33.8911 90.729167 12.012035 75.271354 12.012035 51.221354 C 12.012035 36.108854 22.771115 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.470588%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.729167 59.7197 90.729167 C 33.979624 90.729167 12.10056 75.183333 12.10056 51.221354 C 12.10056 36.108854 22.859639 20.197396 36.941776 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,12.156863%,36.470588%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.641146 59.7197 90.641146 C 33.979624 90.641146 12.10056 75.088542 12.10056 51.133333 C 12.10056 36.020833 22.859639 20.197396 36.941776 14.828125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,11.764706%,36.470588%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.627083 78.636613 90.641146 59.7197 90.641146 C 33.979624 90.641146 12.10056 75.088542 12.10056 51.133333 C 12.10056 36.020833 22.859639 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,11.764706%,36.470588%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.636613 90.641146 59.7197 90.641146 C 33.979624 90.641146 12.10056 75.088542 12.10056 51.133333 C 12.10056 36.020833 22.859639 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,11.764706%,36.470588%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.636613 90.641146 59.7197 90.641146 C 33.979624 90.641146 12.10056 75.088542 12.10056 51.133333 C 12.10056 36.020833 22.859639 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,11.764706%,36.078431%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.636613 90.641146 59.7197 90.641146 C 33.979624 90.641146 12.10056 75.088542 12.10056 51.133333 C 12.10056 36.020833 22.859639 20.292188 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,11.764706%,36.078431%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.553125 59.808224 90.553125 C 34.068148 90.553125 12.189084 75.000521 12.189084 51.038542 C 12.189084 35.932813 22.948163 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.588235%,11.764706%,36.078431%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.553125 59.808224 90.553125 C 34.068148 90.553125 12.189084 75.000521 12.189084 51.038542 C 12.189084 35.932813 22.948163 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.764706%,36.078431%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.553125 59.808224 90.553125 C 34.156672 90.553125 12.277608 74.9125 12.277608 50.950521 C 12.277608 35.838021 23.036687 20.109375 36.941776 14.828125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.764706%,36.078431%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.553125 59.808224 90.553125 C 34.156672 90.458333 12.277608 74.9125 12.277608 50.950521 C 12.277608 35.932813 22.948163 20.197396 36.853252 14.828125 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.764706%,36.078431%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.553125 59.808224 90.553125 C 34.156672 90.458333 12.277608 74.817708 12.277608 50.950521 C 12.277608 35.932813 22.948163 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.764706%,35.686275%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.458333 59.896748 90.458333 C 34.156672 90.458333 12.277608 74.817708 12.277608 50.950521 C 12.277608 35.932813 22.948163 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.764706%,35.686275%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.458333 59.896748 90.458333 C 34.252006 90.458333 12.277608 74.817708 12.277608 50.950521 C 12.277608 35.932813 22.948163 20.197396 36.757918 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.764706%,35.686275%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.458333 59.896748 90.458333 C 34.252006 90.458333 12.372941 74.817708 12.372941 50.950521 C 12.372941 35.932813 23.036687 20.292188 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.686275%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.458333 59.896748 90.458333 C 34.252006 90.458333 12.372941 74.817708 12.372941 50.950521 C 12.372941 35.932813 23.036687 20.292188 36.853252 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.686275%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 103.92045 69.719271 C 95.313186 80.539062 78.548089 90.458333 59.896748 90.458333 C 34.252006 90.458333 12.372941 74.817708 12.372941 50.950521 C 12.372941 35.932813 23.036687 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.294118%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.719271 C 95.40171 80.451042 78.636613 90.370312 59.978463 90.370312 C 34.34053 90.370312 12.461465 74.729687 12.461465 50.8625 C 12.461465 35.838021 23.132021 20.197396 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.294118%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.719271 C 95.40171 80.451042 78.636613 90.370312 60.066987 90.370312 C 34.429054 90.370312 12.549989 74.641667 12.549989 50.8625 C 12.549989 35.838021 23.220545 20.292188 36.941776 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.294118%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.719271 C 95.40171 80.451042 78.636613 90.370312 60.066987 90.370312 C 34.429054 90.370312 12.549989 74.641667 12.549989 50.8625 C 12.549989 35.838021 23.220545 20.292188 36.941776 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.294118%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.719271 C 95.313186 80.451042 78.636613 90.370312 60.066987 90.370312 C 34.429054 90.370312 12.549989 74.641667 12.549989 50.950521 C 12.549989 35.932813 23.132021 20.380208 36.853252 15.098958 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.294118%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.636613 90.289062 60.066987 90.289062 C 34.429054 90.289062 12.549989 74.546875 12.549989 50.8625 C 12.549989 35.932813 23.132021 20.292188 36.853252 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,35.294118%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.636613 90.201042 60.066987 90.201042 C 34.429054 90.201042 12.549989 74.458854 12.549989 50.767708 C 12.549989 35.838021 23.132021 20.292188 36.853252 14.922917 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,34.901961%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.636613 90.201042 60.066987 90.201042 C 34.429054 90.201042 12.549989 74.458854 12.549989 50.767708 C 12.549989 35.838021 23.132021 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(10.196078%,11.372549%,34.901961%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.636613 90.201042 60.162321 90.201042 C 34.517578 90.201042 12.638514 74.458854 12.638514 50.767708 C 12.638514 35.838021 23.220545 20.292188 36.853252 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,11.372549%,34.901961%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.548089 90.201042 60.162321 90.201042 C 34.517578 90.201042 12.638514 74.458854 12.638514 50.767708 C 12.638514 35.838021 23.220545 20.292188 36.853252 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,11.372549%,34.901961%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.548089 90.201042 60.162321 90.201042 C 34.517578 90.201042 12.638514 74.370833 12.638514 50.767708 C 12.638514 35.838021 23.220545 20.380208 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,11.372549%,34.901961%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.548089 90.113021 60.162321 90.113021 C 34.517578 90.113021 12.638514 74.282813 12.638514 50.679688 C 12.638514 35.75 23.220545 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.509804%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.548089 90.113021 60.162321 90.113021 C 34.517578 90.113021 12.638514 74.282813 12.638514 50.679688 C 12.638514 35.75 23.220545 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.509804%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.363021 78.548089 90.113021 60.162321 90.113021 C 34.517578 90.113021 12.638514 74.282813 12.638514 50.679688 C 12.638514 35.75 23.220545 20.292188 36.669394 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.509804%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.113021 60.250845 90.113021 C 34.612912 90.113021 12.727038 74.282813 12.727038 50.679688 C 12.727038 35.75 23.309069 20.380208 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.509804%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.113021 60.250845 90.113021 C 34.612912 90.113021 12.727038 74.282813 12.727038 50.679688 C 12.727038 35.838021 23.220545 20.380208 36.757918 15.098958 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.509804%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.018229 60.250845 90.018229 C 34.612912 90.018229 12.727038 74.188021 12.727038 50.591667 C 12.727038 35.75 23.220545 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.509804%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.018229 60.250845 90.018229 C 34.612912 90.018229 12.822371 74.188021 12.822371 50.591667 C 12.822371 35.75 23.309069 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.117647%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.018229 60.250845 90.018229 C 34.612912 90.018229 12.822371 74.1 12.822371 50.591667 C 12.822371 35.75 23.309069 20.380208 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.117647%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.018229 60.250845 90.018229 C 34.612912 90.018229 12.822371 74.1 12.822371 50.591667 C 12.822371 35.75 23.309069 20.380208 36.757918 15.098958 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.117647%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 90.018229 60.339369 90.018229 C 34.701436 90.018229 12.910895 74.1 12.910895 50.591667 C 12.910895 35.75 23.397593 20.380208 36.757918 15.098958 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.117647%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 89.930208 60.339369 89.930208 C 34.701436 89.930208 12.910895 74.01875 12.910895 50.496875 C 12.910895 35.661979 23.397593 20.292188 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.117647%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 89.930208 60.339369 89.930208 C 34.701436 89.930208 12.910895 74.01875 12.910895 50.496875 C 12.910895 35.661979 23.397593 20.380208 36.757918 15.010938 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.980392%,34.117647%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 89.930208 60.339369 89.930208 C 34.701436 89.930208 12.910895 74.01875 12.910895 50.591667 C 12.910895 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.803922%,10.588235%,33.72549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 89.930208 60.339369 89.930208 C 34.701436 89.930208 12.910895 74.01875 12.910895 50.591667 C 12.910895 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.72549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 89.930208 60.339369 89.930208 C 34.701436 89.930208 12.910895 74.01875 12.910895 50.591667 C 12.910895 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.72549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.268229 78.548089 89.842188 60.427893 89.842188 C 34.78996 89.842188 12.999419 73.930729 12.999419 50.496875 C 12.910895 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.72549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.842188 60.427893 89.842188 C 34.78996 89.842188 12.999419 73.842708 12.999419 50.496875 C 12.999419 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.72549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.842188 60.427893 89.842188 C 34.78996 89.842188 12.999419 73.842708 12.999419 50.496875 C 12.999419 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.333333%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.842188 60.427893 89.842188 C 34.78996 89.842188 12.999419 73.842708 12.999419 50.496875 C 12.999419 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.333333%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.842188 60.427893 89.842188 C 34.78996 89.842188 12.999419 73.842708 12.999419 50.496875 C 12.999419 35.75 23.397593 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.333333%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.747396 60.427893 89.747396 C 34.78996 89.747396 12.999419 73.747917 12.999419 50.408854 C 13.087943 35.75 23.492927 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.333333%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.747396 60.523226 89.747396 C 34.878484 89.747396 13.087943 73.747917 13.087943 50.408854 C 13.087943 35.75 23.492927 20.468229 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.333333%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.747396 60.523226 89.747396 C 34.878484 89.747396 13.087943 73.747917 13.087943 50.408854 C 13.087943 35.75 23.492927 20.563021 36.58087 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,33.333333%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.747396 60.523226 89.747396 C 34.878484 89.747396 13.087943 73.747917 13.087943 50.408854 C 13.087943 35.661979 23.492927 20.563021 36.58087 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,32.941176%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.747396 60.523226 89.747396 C 34.878484 89.747396 13.087943 73.659896 13.087943 50.408854 C 13.087943 35.661979 23.397593 20.563021 36.58087 15.281771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.588235%,32.941176%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.659375 60.523226 89.659375 C 34.967008 89.659375 13.183277 73.571875 13.183277 50.320833 C 13.183277 35.661979 23.492927 20.468229 36.58087 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.196078%,32.941176%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.659375 60.523226 89.659375 C 34.967008 89.659375 13.183277 73.571875 13.183277 50.320833 C 13.183277 35.661979 23.492927 20.468229 36.58087 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.196078%,32.941176%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.659375 60.61175 89.659375 C 35.062342 89.659375 13.271801 73.571875 13.271801 50.320833 C 13.271801 35.661979 23.581451 20.563021 36.669394 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.196078%,32.941176%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.180208 78.459565 89.659375 60.61175 89.659375 C 35.062342 89.659375 13.271801 73.571875 13.271801 50.320833 C 13.271801 35.661979 23.581451 20.563021 36.58087 15.281771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.411765%,10.196078%,32.54902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.459565 89.659375 60.61175 89.659375 C 35.062342 89.659375 13.271801 73.571875 13.271801 50.320833 C 13.271801 35.661979 23.581451 20.563021 36.58087 15.281771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.54902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.459565 89.571354 60.61175 89.571354 C 35.062342 89.571354 13.271801 73.477083 13.271801 50.239583 C 13.271801 35.567188 23.581451 20.468229 36.58087 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.54902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.459565 89.571354 60.61175 89.571354 C 35.062342 89.571354 13.271801 73.477083 13.271801 50.239583 C 13.271801 35.567188 23.581451 20.563021 36.58087 15.186979 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.54902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.459565 89.571354 60.61175 89.571354 C 35.062342 89.571354 13.271801 73.477083 13.271801 50.320833 C 13.271801 35.661979 23.581451 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.54902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.459565 89.571354 60.700274 89.571354 C 35.150866 89.571354 13.360325 73.389063 13.360325 50.320833 C 13.360325 35.661979 23.669975 20.651042 36.58087 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.54902%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.571354 60.700274 89.571354 C 35.150866 89.571354 13.360325 73.389063 13.360325 50.320833 C 13.360325 35.661979 23.669975 20.651042 36.58087 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.156863%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.483333 60.700274 89.483333 C 35.150866 89.483333 13.360325 73.301042 13.360325 50.239583 C 13.360325 35.661979 23.581451 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.156863%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.483333 60.700274 89.483333 C 35.150866 89.483333 13.360325 73.301042 13.360325 50.239583 C 13.360325 35.661979 23.581451 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,10.196078%,32.156863%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.483333 60.700274 89.483333 C 35.150866 89.483333 13.360325 73.301042 13.360325 50.239583 C 13.360325 35.661979 23.581451 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,32.156863%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.483333 60.700274 89.483333 C 35.150866 89.483333 13.360325 73.301042 13.360325 50.239583 C 13.360325 35.661979 23.581451 20.651042 36.397012 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,32.156863%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.483333 60.788799 89.483333 C 35.23939 89.483333 13.448849 73.301042 13.448849 50.239583 C 13.448849 35.661979 23.669975 20.651042 36.492346 15.457813 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,31.764706%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.388542 60.788799 89.388542 C 35.23939 89.388542 13.448849 73.213021 13.448849 50.151563 C 13.448849 35.567188 23.669975 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,31.764706%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.388542 60.788799 89.388542 C 35.23939 89.388542 13.537373 73.213021 13.537373 50.151563 C 13.537373 35.567188 23.758499 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,31.764706%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 80.092188 78.371041 89.388542 60.877323 89.388542 C 35.23939 89.388542 13.537373 73.118229 13.537373 50.151563 C 13.537373 35.567188 23.758499 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,31.764706%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 79.997396 78.371041 89.388542 60.877323 89.388542 C 35.327914 89.388542 13.537373 73.118229 13.537373 50.151563 C 13.537373 35.567188 23.758499 20.651042 36.492346 15.457813 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,31.764706%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 79.997396 78.371041 89.388542 60.877323 89.388542 C 35.327914 89.388542 13.537373 73.118229 13.537373 50.151563 C 13.537373 35.567188 23.758499 20.732292 36.492346 15.457813 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(9.019608%,9.803922%,31.764706%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 79.997396 78.371041 89.300521 60.877323 89.300521 C 35.327914 89.300521 13.632707 73.030208 13.632707 50.056771 C 13.632707 35.492708 23.758499 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.803922%,31.372549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.008974 69.63125 C 95.313186 79.997396 78.371041 89.300521 60.877323 89.300521 C 35.327914 89.300521 13.632707 73.030208 13.632707 50.056771 C 13.632707 35.567188 23.758499 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.803922%,31.372549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.459565 89.300521 60.972656 89.300521 C 35.423247 89.300521 13.721231 73.030208 13.721231 50.056771 C 13.721231 35.567188 23.847023 20.651042 36.58087 15.457813 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.803922%,31.372549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.459565 89.300521 60.972656 89.300521 C 35.423247 89.300521 13.721231 73.030208 13.721231 50.056771 C 13.721231 35.567188 23.847023 20.732292 36.492346 15.457813 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.803922%,31.372549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.459565 89.300521 60.972656 89.300521 C 35.423247 89.300521 13.721231 72.942188 13.721231 50.056771 C 13.721231 35.567188 23.847023 20.732292 36.492346 15.457813 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.803922%,31.372549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.459565 89.2125 60.972656 89.2125 C 35.423247 89.2125 13.721231 72.847396 13.721231 49.96875 C 13.721231 35.492708 23.847023 20.651042 36.492346 15.369792 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,31.372549%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.371041 89.2125 61.06118 89.2125 C 35.511772 89.2125 13.809755 72.847396 13.809755 50.056771 C 13.809755 35.567188 23.942356 20.732292 36.492346 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.980392%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.371041 89.2125 61.06118 89.2125 C 35.511772 89.2125 13.809755 72.847396 13.809755 50.056771 C 13.809755 35.567188 23.942356 20.820312 36.492346 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.980392%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.371041 89.2125 61.06118 89.2125 C 35.511772 89.2125 13.809755 72.847396 13.809755 50.056771 C 13.809755 35.567188 23.942356 20.820312 36.492346 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.980392%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.997396 78.371041 89.2125 61.06118 89.2125 C 35.511772 89.2125 13.809755 72.847396 13.809755 50.056771 C 13.809755 35.567188 23.942356 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.980392%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.117708 61.06118 89.117708 C 35.511772 89.117708 13.809755 72.759375 13.809755 49.96875 C 13.809755 35.492708 23.847023 20.732292 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.980392%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.117708 61.06118 89.117708 C 35.511772 89.117708 13.809755 72.759375 13.809755 49.96875 C 13.809755 35.567188 23.847023 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.588235%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.117708 61.149704 89.117708 C 35.600296 89.117708 13.898279 72.759375 13.898279 49.96875 C 13.898279 35.567188 23.942356 20.820312 36.492346 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.588235%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.117708 61.149704 89.117708 C 35.600296 89.117708 13.898279 72.671354 13.898279 49.96875 C 13.898279 35.567188 23.942356 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.588235%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.117708 61.149704 89.117708 C 35.600296 89.117708 13.898279 72.671354 13.898279 49.96875 C 13.898279 35.567188 23.942356 20.820312 36.397012 15.640625 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.588235%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.029688 61.149704 89.029688 C 35.600296 89.029688 13.898279 72.583333 13.898279 49.880729 C 13.898279 35.492708 23.942356 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.627451%,9.411765%,30.588235%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.029688 61.149704 89.029688 C 35.600296 89.029688 13.898279 72.583333 13.898279 49.880729 C 13.898279 35.492708 23.942356 20.820312 36.308488 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.411765%,30.588235%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.029688 61.149704 89.029688 C 35.600296 89.029688 13.898279 72.583333 13.898279 49.880729 C 13.898279 35.492708 23.942356 20.820312 36.308488 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,30.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.029688 61.238228 89.029688 C 35.68201 89.029688 14.082137 72.583333 14.082137 49.880729 C 14.082137 35.492708 24.119405 20.820312 36.492346 15.640625 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,30.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 89.029688 61.238228 89.029688 C 35.68201 89.029688 14.082137 72.583333 14.082137 49.880729 C 14.082137 35.492708 24.119405 20.908333 36.397012 15.640625 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,30.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 88.941667 61.238228 88.941667 C 35.770534 88.941667 14.082137 72.502083 14.082137 49.792708 C 14.082137 35.397917 24.119405 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,30.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 88.941667 61.238228 88.941667 C 35.770534 88.941667 14.082137 72.407292 14.082137 49.792708 C 14.082137 35.397917 24.03088 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,30.196078%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.922917 78.371041 88.941667 61.238228 88.941667 C 35.770534 88.941667 14.082137 72.407292 14.082137 49.792708 C 14.082137 35.492708 24.03088 20.820312 36.397012 15.640625 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.371041 88.941667 61.326753 88.941667 C 35.859058 88.941667 14.157042 72.407292 14.157042 49.792708 C 14.157042 35.492708 24.119405 20.908333 36.397012 15.640625 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.941667 61.326753 88.941667 C 35.859058 88.941667 14.157042 72.407292 14.157042 49.792708 C 14.157042 35.492708 24.119405 20.908333 36.397012 15.640625 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.846875 61.326753 88.846875 C 35.859058 88.846875 14.157042 72.319271 14.157042 49.697917 C 14.157042 35.397917 24.119405 20.820312 36.397012 15.552604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.846875 61.326753 88.846875 C 35.859058 88.846875 14.157042 72.319271 14.157042 49.792708 C 14.157042 35.492708 24.119405 20.908333 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.846875 61.326753 88.846875 C 35.859058 88.846875 14.157042 72.319271 14.157042 49.792708 C 14.157042 35.492708 24.119405 21.003125 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.803922%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.846875 61.326753 88.846875 C 35.859058 88.846875 14.157042 72.319271 14.157042 49.792708 C 14.157042 35.492708 24.119405 21.003125 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.846875 61.422086 88.846875 C 35.947582 88.846875 14.252375 72.319271 14.252375 49.792708 C 14.252375 35.492708 24.207929 21.003125 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,9.019608%,29.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.772396 61.422086 88.772396 C 35.947582 88.772396 14.252375 72.143229 14.252375 49.697917 C 14.252375 35.397917 24.207929 20.908333 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,8.627451%,29.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.772396 61.422086 88.772396 C 35.947582 88.772396 14.252375 72.143229 14.252375 49.697917 C 14.252375 35.397917 24.119405 21.003125 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(8.235294%,8.627451%,29.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.772396 61.422086 88.772396 C 35.947582 88.772396 14.252375 72.143229 14.252375 49.697917 C 14.252375 35.492708 24.119405 21.003125 36.219964 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.411765%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.772396 61.422086 88.772396 C 35.947582 88.772396 14.252375 72.143229 14.252375 49.697917 C 14.252375 35.492708 24.119405 21.003125 36.219964 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.772396 61.422086 88.772396 C 35.947582 88.772396 14.252375 72.143229 14.252375 49.697917 C 14.252375 35.492708 24.119405 21.003125 36.219964 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.828125 78.282517 88.677604 61.51061 88.677604 C 36.042916 88.677604 14.340899 72.048438 14.340899 49.609896 C 14.340899 35.397917 24.207929 21.003125 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.677604 61.51061 88.677604 C 36.042916 88.677604 14.340899 72.048438 14.340899 49.609896 C 14.340899 35.397917 24.207929 21.003125 36.219964 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.677604 61.51061 88.677604 C 36.042916 88.677604 14.429424 71.960417 14.429424 49.609896 C 14.429424 35.397917 24.303262 21.003125 36.308488 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.677604 61.51061 88.677604 C 36.042916 88.677604 14.429424 71.960417 14.429424 49.609896 C 14.429424 35.397917 24.303262 21.003125 36.308488 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,29.019608%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.677604 61.51061 88.677604 C 36.042916 88.589583 14.429424 71.960417 14.429424 49.609896 C 14.429424 35.397917 24.303262 21.091146 36.219964 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,28.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.589583 61.51061 88.589583 C 36.13144 88.589583 14.429424 71.872396 14.429424 49.521875 C 14.429424 35.309896 24.303262 21.003125 36.219964 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,28.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.589583 61.599134 88.589583 C 36.13144 88.589583 14.429424 71.872396 14.429424 49.521875 C 14.429424 35.397917 24.303262 21.003125 36.219964 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,28.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.187183 88.589583 61.599134 88.589583 C 36.13144 88.589583 14.517948 71.872396 14.517948 49.521875 C 14.517948 35.309896 24.303262 21.003125 36.219964 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.627451%,28.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.187183 88.589583 61.599134 88.589583 C 36.13144 88.589583 14.517948 71.872396 14.517948 49.521875 C 14.517948 35.397917 24.303262 21.091146 36.219964 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.235294%,28.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.282517 88.501563 61.599134 88.501563 C 36.13144 88.501563 14.517948 71.777604 14.517948 49.427083 C 14.517948 35.309896 24.303262 21.003125 36.219964 15.728646 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.235294%,28.627451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.652083 78.282517 88.501563 61.599134 88.501563 C 36.13144 88.501563 14.517948 71.777604 14.517948 49.521875 C 14.517948 35.397917 24.303262 21.091146 36.13144 15.816667 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.235294%,28.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.652083 78.282517 88.501563 61.687658 88.501563 C 36.219964 88.501563 14.613281 71.689583 14.613281 49.521875 C 14.613281 35.397917 24.391786 21.091146 36.219964 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.235294%,28.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.652083 78.187183 88.501563 61.687658 88.501563 C 36.219964 88.501563 14.613281 71.689583 14.613281 49.521875 C 14.613281 35.397917 24.391786 21.179167 36.219964 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.843137%,8.235294%,28.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.740104 78.187183 88.501563 61.687658 88.501563 C 36.219964 88.501563 14.613281 71.689583 14.613281 49.521875 C 14.613281 35.397917 24.391786 21.179167 36.219964 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,28.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.40171 79.652083 78.187183 88.501563 61.687658 88.501563 C 36.219964 88.501563 14.613281 71.689583 14.613281 49.521875 C 14.613281 35.397917 24.391786 21.179167 36.13144 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,28.235294%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.406771 61.687658 88.406771 C 36.219964 88.406771 14.613281 71.601562 14.613281 49.427083 C 14.613281 35.309896 24.391786 21.091146 36.13144 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,27.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.406771 61.687658 88.406771 C 36.219964 88.406771 14.613281 71.601562 14.613281 49.427083 C 14.613281 35.309896 24.391786 21.179167 36.13144 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,27.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.406771 61.687658 88.406771 C 36.219964 88.406771 14.613281 71.601562 14.613281 49.427083 C 14.613281 35.309896 24.303262 21.179167 36.042916 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,27.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.406771 61.782992 88.406771 C 36.308488 88.406771 14.701805 71.601562 14.701805 49.427083 C 14.701805 35.397917 24.391786 21.179167 36.13144 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,27.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.406771 61.782992 88.406771 C 36.308488 88.406771 14.701805 71.601562 14.701805 49.427083 C 14.701805 35.397917 24.391786 21.179167 36.13144 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,8.235294%,27.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.31875 61.782992 88.31875 C 36.308488 88.31875 14.701805 71.41875 14.701805 49.339063 C 14.701805 35.309896 24.391786 21.179167 36.042916 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.843137%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.31875 61.782992 88.31875 C 36.308488 88.31875 14.790329 71.41875 14.790329 49.339063 C 14.790329 35.309896 24.48031 21.179167 36.13144 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.31875 61.782992 88.31875 C 36.308488 88.31875 14.790329 71.41875 14.790329 49.339063 C 14.790329 35.309896 24.48031 21.179167 36.13144 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.097498 69.63125 C 95.313186 79.652083 78.187183 88.31875 61.782992 88.31875 C 36.308488 88.31875 14.790329 71.41875 14.790329 49.339063 C 14.790329 35.309896 24.48031 21.179167 36.042916 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.652083 78.282517 88.31875 61.96004 88.31875 C 36.492346 88.31875 14.967378 71.41875 14.967378 49.339063 C 14.967378 35.309896 24.657359 21.267188 36.219964 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.652083 78.187183 88.230729 61.96004 88.230729 C 36.58087 88.230729 14.967378 71.330729 14.967378 49.251042 C 14.967378 35.221875 24.657359 21.179167 36.219964 15.911458 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.45098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.652083 78.187183 88.230729 61.96004 88.230729 C 36.58087 88.230729 14.967378 71.330729 14.967378 49.251042 C 14.967378 35.221875 24.657359 21.179167 36.219964 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.230729 61.96004 88.230729 C 36.58087 88.230729 14.967378 71.330729 14.967378 49.251042 C 14.967378 35.221875 24.568834 21.179167 36.13144 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.230729 61.96004 88.230729 C 36.58087 88.230729 14.967378 71.242708 14.967378 49.251042 C 14.967378 35.221875 24.568834 21.267188 36.13144 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.45098%,7.843137%,27.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.230729 61.96004 88.230729 C 36.58087 88.230729 14.967378 71.242708 14.967378 49.251042 C 14.967378 35.309896 24.568834 21.267188 36.13144 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.843137%,27.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.142708 62.048564 88.142708 C 36.669394 88.142708 15.062711 71.147917 15.062711 49.251042 C 15.062711 35.309896 24.657359 21.267188 36.13144 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.843137%,27.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.142708 62.048564 88.142708 C 36.669394 88.142708 15.062711 71.147917 15.062711 49.251042 C 15.062711 35.309896 24.657359 21.267188 36.13144 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.843137%,27.058824%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.142708 62.048564 88.142708 C 36.669394 88.142708 15.062711 71.147917 15.062711 49.251042 C 15.062711 35.309896 24.657359 21.361979 36.13144 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.843137%,26.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.142708 62.048564 88.142708 C 36.669394 88.142708 15.062711 71.147917 15.062711 49.251042 C 15.062711 35.309896 24.657359 21.361979 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.142708 62.048564 88.142708 C 36.669394 88.142708 15.062711 71.147917 15.062711 49.251042 C 15.062711 35.309896 24.657359 21.361979 36.042916 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.63125 C 95.40171 79.557292 78.187183 88.047917 62.048564 88.047917 C 36.669394 88.047917 15.062711 71.059896 15.062711 49.163021 C 15.062711 35.221875 24.657359 21.267188 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.469271 78.187183 87.959896 62.137088 87.959896 C 36.757918 87.959896 15.151235 70.978646 15.151235 49.068229 C 15.151235 35.127083 24.752692 21.267188 36.042916 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.666667%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.469271 78.187183 87.959896 62.137088 87.959896 C 36.757918 87.959896 15.151235 70.890625 15.151235 49.068229 C 15.151235 35.127083 24.752692 21.267188 36.042916 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.469271 78.187183 87.959896 62.137088 87.959896 C 36.757918 87.959896 15.151235 70.890625 15.151235 49.068229 C 15.151235 35.127083 24.657359 21.267188 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.469271 78.187183 87.959896 62.137088 87.959896 C 36.757918 87.959896 15.151235 70.890625 15.151235 49.068229 C 15.151235 35.221875 24.657359 21.267188 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.187183 87.871875 62.137088 87.871875 C 36.757918 87.871875 15.239759 70.802604 15.239759 48.980208 C 15.239759 35.127083 24.752692 21.267188 36.042916 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.187183 87.871875 62.137088 87.871875 C 36.757918 87.871875 15.239759 70.802604 15.239759 48.980208 C 15.239759 35.127083 24.752692 21.267188 36.042916 15.999479 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.187183 87.871875 62.232422 87.871875 C 36.853252 87.871875 15.328283 70.802604 15.328283 48.980208 C 15.328283 35.127083 24.841216 21.267188 36.13144 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,26.27451%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.871875 62.232422 87.871875 C 36.853252 87.871875 15.328283 70.802604 15.328283 48.980208 C 15.328283 35.127083 24.841216 21.267188 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,25.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.871875 62.232422 87.871875 C 36.853252 87.871875 15.328283 70.802604 15.328283 48.980208 C 15.328283 35.127083 24.752692 21.361979 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(7.058824%,7.45098%,25.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.777083 62.232422 87.777083 C 36.853252 87.871875 15.328283 70.707813 15.328283 48.980208 C 15.328283 35.127083 24.841216 21.361979 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.777083 62.232422 87.777083 C 36.853252 87.871875 15.328283 70.707813 15.328283 48.980208 C 15.328283 35.127083 24.841216 21.361979 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.777083 62.232422 87.777083 C 36.853252 87.777083 15.328283 70.707813 15.328283 48.980208 C 15.328283 35.127083 24.841216 21.361979 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.777083 62.320946 87.777083 C 36.941776 87.777083 15.416807 70.619792 15.416807 48.892187 C 15.416807 35.039062 24.841216 21.361979 36.042916 16.0875 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.882353%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.777083 62.320946 87.777083 C 36.941776 87.777083 15.416807 70.619792 15.416807 48.980208 C 15.416807 35.221875 24.841216 21.45 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.689063 62.320946 87.689063 C 36.941776 87.689063 15.416807 70.531771 15.416807 48.892187 C 15.416807 35.127083 24.841216 21.361979 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.689063 62.320946 87.689063 C 36.941776 87.689063 15.416807 70.531771 15.416807 48.892187 C 15.416807 35.127083 24.841216 21.361979 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.38125 78.098659 87.689063 62.320946 87.689063 C 36.941776 87.689063 15.416807 70.531771 15.416807 48.892187 C 15.416807 35.127083 24.922931 21.45 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.689063 62.320946 87.689063 C 36.941776 87.689063 15.416807 70.436979 15.416807 48.892187 C 15.512141 35.127083 24.922931 21.45 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.490196%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.689063 62.40947 87.689063 C 37.0303 87.689063 15.512141 70.436979 15.512141 48.892187 C 15.512141 35.127083 24.922931 21.45 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.40947 87.601042 C 37.0303 87.601042 15.512141 70.348958 15.512141 48.810938 C 15.512141 35.127083 24.922931 21.45 35.947582 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.40947 87.601042 C 37.0303 87.601042 15.512141 70.348958 15.512141 48.810938 C 15.512141 35.127083 24.922931 21.45 35.947582 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.40947 87.601042 C 37.0303 87.601042 15.512141 70.348958 15.512141 48.810938 C 15.600665 35.127083 24.922931 21.45 35.947582 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,7.058824%,25.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.40947 87.601042 C 37.0303 87.601042 15.512141 70.348958 15.512141 48.810938 C 15.600665 35.039062 24.922931 21.45 35.947582 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,6.666667%,25.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.497994 87.601042 C 37.118824 87.601042 15.689189 70.348958 15.689189 48.810938 C 15.600665 35.039062 25.011455 21.45 35.947582 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,6.666667%,25.098039%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.497994 87.601042 C 37.118824 87.601042 15.689189 70.348958 15.689189 48.810938 C 15.689189 35.127083 25.011455 21.45 35.947582 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.666667%,6.666667%,24.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.098659 87.601042 62.497994 87.601042 C 37.118824 87.601042 15.689189 70.348958 15.689189 48.810938 C 15.689189 35.127083 25.011455 21.538021 35.947582 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.010135 87.513021 62.497994 87.513021 C 37.118824 87.513021 15.689189 70.172917 15.689189 48.722917 C 15.689189 35.039062 25.011455 21.45 35.859058 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.010135 87.513021 62.497994 87.513021 C 37.118824 87.513021 15.689189 70.172917 15.689189 48.722917 C 15.689189 35.039062 25.011455 21.45 35.859058 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.010135 87.513021 62.497994 87.513021 C 37.118824 87.513021 15.689189 70.172917 15.689189 48.722917 C 15.689189 35.039062 25.011455 21.45 35.859058 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.705882%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.293229 78.010135 87.418229 62.497994 87.418229 C 37.207348 87.418229 15.689189 70.078125 15.689189 48.628125 C 15.689189 34.951042 25.011455 21.45 35.859058 16.182292 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.418229 62.593328 87.418229 C 37.302682 87.418229 15.777713 70.078125 15.777713 48.628125 C 15.777713 34.951042 25.099979 21.45 35.859058 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.418229 62.593328 87.418229 C 37.302682 87.418229 15.777713 70.078125 15.777713 48.628125 C 15.777713 34.951042 25.099979 21.45 35.859058 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.418229 62.593328 87.418229 C 37.302682 87.418229 15.777713 70.078125 15.777713 48.628125 C 15.777713 34.951042 25.099979 21.45 35.859058 16.256771 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.418229 62.593328 87.418229 C 37.302682 87.418229 15.777713 70.078125 15.777713 48.722917 C 15.777713 35.039062 25.011455 21.632812 35.770534 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.666667%,24.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.336979 62.593328 87.336979 C 37.302682 87.336979 15.777713 69.902083 15.777713 48.628125 C 15.777713 35.039062 25.011455 21.538021 35.770534 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,24.313725%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.336979 62.668233 87.336979 C 37.391206 87.336979 15.873047 69.902083 15.873047 48.628125 C 15.873047 35.039062 25.099979 21.538021 35.859058 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,23.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.336979 62.668233 87.336979 C 37.391206 87.336979 15.873047 69.902083 15.873047 48.628125 C 15.873047 35.039062 25.099979 21.538021 35.770534 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,23.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.336979 62.668233 87.336979 C 37.391206 87.336979 15.873047 69.902083 15.873047 48.628125 C 15.873047 35.039062 25.099979 21.632812 35.770534 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,23.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.336979 62.668233 87.336979 C 37.391206 87.336979 15.873047 69.902083 15.873047 48.628125 C 15.873047 35.039062 25.099979 21.632812 35.770534 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,23.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.248958 62.668233 87.248958 C 37.391206 87.248958 15.873047 69.807292 15.873047 48.540104 C 15.873047 34.951042 25.099979 21.538021 35.68201 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,23.921569%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.248958 62.668233 87.248958 C 37.391206 87.248958 15.873047 69.807292 15.873047 48.540104 C 15.873047 34.951042 25.099979 21.538021 35.68201 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(6.27451%,6.27451%,23.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 78.010135 87.248958 62.756757 87.248958 C 37.47973 87.248958 15.961571 69.807292 15.961571 48.540104 C 15.961571 34.951042 25.188503 21.632812 35.770534 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,6.27451%,23.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.198437 77.921611 87.248958 62.756757 87.248958 C 37.47973 87.248958 16.050095 69.807292 16.050095 48.540104 C 16.050095 34.951042 25.277027 21.632812 35.859058 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,6.27451%,23.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.192832 69.55 C 95.40171 79.110417 77.921611 87.248958 62.756757 87.248958 C 37.47973 87.248958 16.050095 69.719271 16.050095 48.540104 C 16.050095 34.951042 25.188503 21.632812 35.770534 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,6.27451%,23.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.160938 62.85209 87.160938 C 37.568254 87.160938 16.138619 69.63125 16.138619 48.452083 C 16.138619 34.863021 25.277027 21.538021 35.859058 16.351562 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,6.27451%,23.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.160938 62.85209 87.160938 C 37.568254 87.160938 16.138619 69.63125 16.138619 48.452083 C 16.138619 34.951042 25.277027 21.632812 35.859058 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,6.27451%,23.529412%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.160938 62.85209 87.160938 C 37.568254 87.160938 16.138619 69.63125 16.138619 48.452083 C 16.138619 34.951042 25.277027 21.632812 35.770534 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,6.27451%,23.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.160938 62.940614 87.160938 C 37.656778 87.160938 16.227143 69.63125 16.227143 48.452083 C 16.227143 34.951042 25.372361 21.632812 35.859058 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,23.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.160938 62.940614 87.160938 C 37.656778 87.160938 16.227143 69.63125 16.227143 48.452083 C 16.227143 34.951042 25.372361 21.632812 35.859058 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,23.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.072917 62.940614 87.072917 C 37.656778 87.072917 16.227143 69.55 16.227143 48.357292 C 16.227143 34.863021 25.372361 21.632812 35.770534 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,23.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.072917 62.940614 87.072917 C 37.656778 87.072917 16.227143 69.55 16.227143 48.452083 C 16.227143 34.951042 25.372361 21.720833 35.770534 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,23.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.072917 62.940614 87.072917 C 37.656778 87.072917 16.227143 69.55 16.227143 48.452083 C 16.227143 34.951042 25.372361 21.720833 35.770534 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,23.137255%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.072917 62.940614 87.072917 C 37.656778 87.072917 16.227143 69.461979 16.227143 48.357292 C 16.227143 34.863021 25.372361 21.632812 35.770534 16.439583 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,22.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 87.072917 63.029139 87.072917 C 37.656778 87.072917 16.227143 69.461979 16.227143 48.357292 C 16.227143 34.863021 25.277027 21.720833 35.68201 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,22.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 86.978125 63.029139 86.978125 C 37.752111 86.978125 16.322477 69.367188 16.322477 48.357292 C 16.322477 34.863021 25.372361 21.720833 35.770534 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,22.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.110417 78.010135 86.978125 63.029139 86.978125 C 37.752111 86.978125 16.322477 69.367188 16.322477 48.357292 C 16.322477 34.951042 25.372361 21.720833 35.770534 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,22.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 78.010135 86.978125 63.029139 86.978125 C 37.752111 86.978125 16.322477 69.367188 16.322477 48.357292 C 16.322477 34.951042 25.372361 21.720833 35.68201 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.882353%,5.882353%,22.745098%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 78.010135 86.978125 63.029139 86.978125 C 37.752111 86.978125 16.322477 69.367188 16.322477 48.357292 C 16.322477 34.951042 25.372361 21.808854 35.68201 16.622396 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.882353%,22.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 78.010135 86.978125 63.029139 86.978125 C 37.752111 86.978125 16.411001 69.279167 16.411001 48.269271 C 16.411001 34.863021 25.460885 21.720833 35.770534 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.882353%,22.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 78.010135 86.890104 63.117663 86.890104 C 37.840636 86.890104 16.411001 69.191146 16.411001 48.269271 C 16.411001 34.863021 25.460885 21.720833 35.68201 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,22.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.890104 63.117663 86.890104 C 37.840636 86.890104 16.411001 69.191146 16.411001 48.269271 C 16.411001 34.863021 25.460885 21.720833 35.68201 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,22.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.890104 63.117663 86.890104 C 37.840636 86.890104 16.499525 69.191146 16.499525 48.269271 C 16.499525 34.863021 25.549409 21.808854 35.770534 16.622396 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,22.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.890104 63.117663 86.890104 C 37.840636 86.890104 16.499525 69.191146 16.499525 48.269271 C 16.499525 34.863021 25.549409 21.808854 35.68201 16.622396 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,22.352941%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.890104 63.117663 86.890104 C 37.840636 86.890104 16.499525 69.191146 16.499525 48.269271 C 16.499525 34.863021 25.549409 21.808854 35.68201 16.622396 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,21.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.802083 63.117663 86.802083 C 37.840636 86.802083 16.499525 69.103125 16.499525 48.18125 C 16.499525 34.768229 25.460885 21.720833 35.68201 16.527604 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,21.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.802083 63.212996 86.802083 C 37.92916 86.802083 16.588049 69.103125 16.588049 48.18125 C 16.588049 34.768229 25.549409 21.808854 35.770534 16.622396 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(5.490196%,5.490196%,21.960784%); stroke-opacity:1; stroke-miterlimit:4;',
          )}
          d="M 104.281356 69.55 C 95.490234 79.022396 77.921611 86.802083 63.212996 86.802083 C 37.92916 86.802083 16.588049 69.103125 16.588049 48.18125 C 16.588049 34.863021 25.549409 21.808854 35.68201 16.622396 "
          transform="matrix(0.573643,0,0,0.576923,0,0)"
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(15.294118%,18.039216%,50.196078%); fill-opacity:1;',
          )}
          d="M 53.859375 7.21875 C 60.800781 11.394531 65.328125 18.148438 65.328125 25.828125 C 65.328125 38.515625 52.9375 48.824219 37.660156 48.824219 C 22.382812 48.824219 9.988281 38.515625 9.988281 25.828125 C 9.988281 18.148438 14.511719 11.394531 21.460938 7.21875 C 21.667969 7.21875 53.859375 7.21875 53.859375 7.21875 Z M 53.859375 7.21875 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,82.745098%,0%); fill-opacity:1;',
          )}
          d="M 54.25 32.191406 L 63.148438 32.191406 L 63.148438 31.042969 L 53.636719 31.042969 L 53.636719 18.425781 L 49.851562 18.425781 L 49.851562 28.546875 C 49.851562 31.414062 51.285156 32.191406 54.25 32.191406 Z M 54.25 32.191406 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,82.745098%,0%); fill-opacity:1;',
          )}
          d="M 27.5625 32.191406 L 19.789062 32.191406 C 16.875 32.191406 15.390625 31.414062 15.390625 28.546875 L 15.390625 18.425781 L 19.175781 18.425781 L 19.175781 30.992188 L 26.4375 30.992188 C 26.691406 31.363281 27.253906 31.984375 27.5625 32.191406 Z M 27.5625 32.191406 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,82.745098%,0%); fill-opacity:1;',
          )}
          d="M 39.066406 18.167969 C 31.804688 18.167969 27.613281 21.191406 27.613281 25.621094 C 27.613281 29.84375 31.957031 32.503906 38.296875 32.503906 C 40.441406 32.503906 42.695312 32.191406 44.175781 31.671875 L 44.175781 30.214844 C 42.949219 30.886719 41.054688 31.363281 39.265625 31.363281 C 34.359375 31.363281 31.601562 28.332031 31.601562 25.363281 C 31.601562 21.820312 34.613281 19.316406 39.265625 19.316406 C 40.855469 19.316406 42.746094 19.730469 44.074219 20.410156 L 44.074219 18.84375 C 42.4375 18.375 40.34375 18.167969 39.066406 18.167969 Z M 39.066406 18.167969 "
        />
        <path
          style={cssStringToJson('stroke:none; fill-rule:nonzero; fill:url(#linear0);')}
          d="M 37.402344 49.148438 C 22.113281 49.148438 9.722656 38.855469 9.722656 26.179688 C 9.722656 20.648438 12.097656 15.582031 16.023438 11.597656 C 11.480469 15.632812 8.535156 21.269531 8.535156 27.269531 C 8.535156 40.617188 20.773438 50.285156 36.109375 50.285156 C 45.660156 50.285156 54.496094 46.042969 59.503906 39.941406 C 54.445312 45.53125 46.4375 49.148438 37.402344 49.148438 Z M 37.402344 49.148438 "
        />
        <g clipPath="url(#clip1)" clipRule="nonzero">
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.901961%,68.627451%,88.627451%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.22329 44.457292 C 114.22329 66.421875 92.773226 84.263021 66.318148 84.263021 C 39.86988 84.263021 18.426626 66.421875 18.426626 44.457292 C 18.426626 31.2 26.291649 19.459375 38.262827 12.201042 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.901961%,68.235294%,88.235294%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.22329 44.457292 C 114.22329 66.421875 92.773226 84.263021 66.318148 84.263021 C 39.86988 84.263021 18.426626 66.421875 18.426626 44.369271 C 18.426626 31.111979 26.291649 19.459375 38.262827 12.201042 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.509804%,67.843137%,87.843137%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.22329 44.457292 C 114.22329 66.421875 92.773226 84.263021 66.406672 84.263021 C 39.958404 84.263021 18.426626 66.421875 18.521959 44.369271 C 18.521959 31.111979 26.380173 19.459375 38.351351 12.289062 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.509804%,67.45098%,87.843137%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.22329 44.457292 C 114.22329 66.421875 92.86175 84.263021 66.406672 84.263021 C 40.046928 84.263021 18.338102 66.421875 18.521959 44.288021 C 18.596864 31.017188 26.380173 19.459375 38.351351 12.289062 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.117647%,67.058824%,87.45098%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.311814 44.369271 C 114.311814 66.327083 92.950274 84.080208 66.502006 84.080208 C 40.142261 84.080208 18.426626 66.239063 18.596864 44.017187 C 18.692198 30.753125 26.468697 19.283333 38.439875 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.117647%,66.666667%,87.058824%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.311814 44.369271 C 114.311814 66.327083 92.950274 84.080208 66.502006 84.080208 C 40.142261 84.080208 18.338102 66.239063 18.521959 44.017187 C 18.692198 30.841146 26.380173 19.371354 38.351351 12.113021 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(34.117647%,66.27451%,86.666667%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.311814 44.369271 C 114.311814 66.327083 92.950274 84.080208 66.502006 84.080208 C 40.142261 84.080208 18.249578 66.239063 18.521959 43.929167 C 18.692198 30.753125 26.380173 19.371354 38.351351 12.113021 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(33.72549%,65.882353%,86.666667%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.311814 44.369271 C 114.311814 66.239063 92.950274 84.080208 66.59053 84.080208 C 40.230785 84.080208 18.338102 66.239063 18.596864 43.841146 C 18.692198 30.658333 26.380173 19.283333 38.351351 12.113021 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(33.72549%,65.490196%,86.27451%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.311814 44.369271 C 114.311814 66.239063 92.950274 84.080208 66.59053 83.992188 C 40.230785 83.992188 18.338102 66.151042 18.596864 43.753125 C 18.780722 30.570312 26.468697 19.283333 38.351351 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(33.72549%,65.098039%,85.882353%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.311814 44.369271 C 114.311814 66.239063 92.950274 83.992188 66.59053 83.992188 C 40.230785 83.992188 18.249578 66.151042 18.596864 43.658333 C 18.780722 30.482292 26.468697 19.283333 38.351351 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(33.333333%,64.705882%,85.490196%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.400338 44.369271 C 114.400338 66.239063 93.127323 83.992188 66.679054 83.992188 C 40.407834 83.992188 18.338102 66.151042 18.692198 43.658333 C 18.95777 30.482292 26.557221 19.283333 38.439875 12.113021 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(33.333333%,64.313725%,85.490196%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.400338 44.369271 C 114.400338 66.239063 93.127323 83.992188 66.679054 83.992188 C 40.407834 83.992188 18.249578 66.063021 18.692198 43.570312 C 18.95777 30.3875 26.557221 19.283333 38.439875 12.113021 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.941176%,63.921569%,85.098039%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.400338 44.369271 C 114.400338 66.239063 93.127323 83.992188 66.767578 83.992188 C 40.503167 83.992188 18.249578 66.063021 18.780722 43.482292 C 19.053104 30.299479 26.652555 19.283333 38.528399 12.113021 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.941176%,63.529412%,84.705882%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.400338 44.369271 C 114.400338 66.239063 93.127323 83.897396 66.767578 83.897396 C 40.503167 83.897396 18.249578 65.968229 18.692198 43.3875 C 18.95777 30.211458 26.557221 19.188542 38.439875 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.941176%,63.137255%,84.313725%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.400338 44.369271 C 114.400338 66.239063 93.127323 83.897396 66.767578 83.897396 C 40.503167 83.897396 18.161054 65.968229 18.692198 43.299479 C 19.053104 30.116667 26.557221 19.188542 38.439875 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.54902%,62.745098%,84.313725%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.400338 44.288021 C 114.400338 66.063021 93.127323 83.809375 66.767578 83.809375 C 40.503167 83.809375 18.161054 65.886979 18.692198 43.211458 C 19.053104 30.028646 26.468697 19.188542 38.439875 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.54902%,62.352941%,83.921569%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.222656 83.809375 66.862912 83.809375 C 40.591691 83.809375 18.161054 65.886979 18.780722 43.116667 C 19.141628 29.947396 26.557221 19.188542 38.528399 12.018229 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.156863%,61.960784%,83.529412%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.31118 83.809375 66.951436 83.721354 C 40.768739 83.721354 18.161054 65.798958 18.869246 42.940625 C 19.141628 29.947396 26.652555 19.100521 38.528399 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.156863%,61.568627%,83.137255%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.31118 83.721354 66.951436 83.721354 C 40.768739 83.721354 18.161054 65.798958 18.869246 42.940625 C 19.230152 29.859375 26.652555 19.100521 38.528399 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(32.156863%,61.176471%,83.137255%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.31118 83.721354 66.951436 83.721354 C 40.768739 83.721354 18.07253 65.798958 18.869246 42.852604 C 19.230152 29.771354 26.652555 19.100521 38.528399 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(31.764706%,60.784314%,82.745098%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.31118 83.721354 66.951436 83.721354 C 40.768739 83.721354 18.07253 65.798958 18.780722 42.852604 C 19.318676 29.683333 26.652555 19.100521 38.528399 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(31.764706%,60.392157%,82.352941%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.31118 83.721354 66.951436 83.721354 C 40.768739 83.721354 17.977196 65.798958 18.780722 42.757812 C 19.318676 29.588542 26.741079 19.100521 38.616924 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(31.372549%,60%,81.960784%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.488862 44.288021 C 114.488862 66.063021 93.31118 83.721354 67.03996 83.633333 C 40.857264 83.633333 18.07253 65.710938 18.869246 42.581771 C 19.318676 29.500521 26.652555 19.0125 38.528399 11.842187 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(31.372549%,59.607843%,81.960784%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.577386 44.288021 C 114.577386 65.968229 93.399704 83.633333 67.128484 83.633333 C 41.027502 83.633333 18.07253 65.710938 18.95777 42.581771 C 19.502534 29.500521 26.741079 19.100521 38.616924 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(31.372549%,59.215686%,81.568627%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.577386 44.288021 C 114.577386 65.968229 93.488228 83.633333 67.128484 83.633333 C 41.027502 83.633333 17.977196 65.710938 18.95777 42.486979 C 19.502534 29.4125 26.741079 19.100521 38.616924 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.980392%,58.823529%,81.176471%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.577386 44.288021 C 114.577386 65.968229 93.488228 83.633333 67.128484 83.633333 C 41.027502 83.633333 17.977196 65.710938 18.95777 42.398958 C 19.502534 29.317708 26.741079 19.0125 38.616924 11.930208 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
        </g>
        <g clipPath="url(#clip2)" clipRule="nonzero">
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.980392%,58.431373%,80.784314%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.577386 44.2 C 114.577386 65.886979 93.488228 83.538542 67.217008 83.450521 C 41.122836 83.450521 17.977196 65.528125 19.053104 42.222917 C 19.591058 29.141667 26.829603 18.917708 38.712257 11.747396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
        </g>
        <g clipPath="url(#clip3)" clipRule="nonzero">
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.980392%,58.039216%,80.392157%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.577386 44.2 C 114.577386 65.886979 93.488228 83.538542 67.217008 83.450521 C 41.122836 83.450521 17.977196 65.528125 18.95777 42.128125 C 19.591058 29.046875 26.741079 18.917708 38.616924 11.747396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.588235%,57.647059%,80.392157%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.577386 44.2 C 114.577386 65.886979 93.488228 83.450521 67.217008 83.450521 C 41.122836 83.450521 17.888672 65.528125 18.95777 42.128125 C 19.591058 29.141667 26.741079 18.917708 38.616924 11.842187 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
        </g>
        <g clipPath="url(#clip4)" clipRule="nonzero">
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.588235%,57.254902%,80%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.67272 44.2 C 114.67272 65.886979 93.576753 83.450521 67.312342 83.450521 C 41.21136 83.450521 17.888672 65.440104 19.053104 42.040104 C 19.679582 29.046875 26.829603 18.917708 38.712257 11.842187 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.196078%,56.862745%,79.607843%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.67272 44.2 C 114.67272 65.886979 93.576753 83.450521 67.312342 83.450521 C 41.299884 83.450521 17.888672 65.440104 19.053104 41.952083 C 19.679582 28.958854 26.829603 18.917708 38.712257 11.747396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
        </g>
        <g clipPath="url(#clip5)" clipRule="nonzero">
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.196078%,56.470588%,79.215686%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.67272 44.2 C 114.67272 65.798958 93.672086 83.450521 67.400866 83.3625 C 41.388408 83.3625 17.888672 65.352083 19.141628 41.870833 C 19.85663 28.870833 26.918127 18.917708 38.800781 11.747396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(30.196078%,56.078431%,79.215686%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.67272 44.2 C 114.67272 65.798958 93.672086 83.3625 67.400866 83.3625 C 41.388408 83.3625 17.888672 65.352083 19.141628 41.782812 C 19.85663 28.782813 26.918127 18.829688 38.800781 11.747396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.803922%,55.686275%,78.823529%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.67272 44.2 C 114.67272 65.798958 93.672086 83.3625 67.400866 83.3625 C 41.388408 83.3625 17.800148 65.352083 19.141628 41.782812 C 19.85663 28.782813 26.918127 18.917708 38.800781 11.842187 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.803922%,55.294118%,78.431373%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.67272 44.2 C 114.67272 65.798958 93.672086 83.3625 67.400866 83.3625 C 41.388408 83.3625 17.711624 65.352083 19.053104 41.688021 C 19.768106 28.688021 26.829603 18.917708 38.712257 11.842187 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.411765%,54.901961%,78.039216%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.2 C 114.761244 65.798958 93.76061 83.3625 67.48258 83.267708 C 41.476932 83.267708 17.800148 65.257292 19.141628 41.511979 C 19.85663 28.511979 26.918127 18.829688 38.800781 11.659375 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.411765%,54.509804%,78.039216%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.2 C 114.761244 65.798958 93.76061 83.3625 67.571104 83.267708 C 41.572266 83.267708 17.800148 65.257292 19.230152 41.511979 C 20.026869 28.511979 26.999842 18.829688 38.800781 11.747396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.411765%,54.117647%,77.647059%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.111979 C 114.761244 65.710938 93.76061 83.179688 67.571104 83.179688 C 41.66079 83.179688 17.800148 65.169271 19.230152 41.329167 C 20.026869 28.329167 26.999842 18.741667 38.800781 11.659375 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.019608%,53.72549%,77.254902%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.111979 C 114.761244 65.710938 93.849134 83.179688 67.571104 83.179688 C 41.66079 83.179688 17.711624 65.169271 19.230152 41.241146 C 20.026869 28.329167 26.999842 18.741667 38.800781 11.659375 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(29.019608%,53.333333%,76.862745%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.111979 C 114.761244 65.622917 93.849134 83.179688 67.571104 83.091667 C 41.66079 83.091667 17.6231 65.08125 19.230152 41.153125 C 20.026869 28.241146 26.999842 18.646875 38.800781 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(28.627451%,52.941176%,76.862745%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.111979 C 114.761244 65.622917 93.849134 83.179688 67.571104 83.091667 C 41.66079 82.996875 17.6231 65.08125 19.230152 41.058333 C 20.122202 28.153125 26.999842 18.646875 38.800781 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(28.627451%,52.54902%,76.470588%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.761244 44.111979 C 114.761244 65.622917 93.849134 83.179688 67.659628 83.091667 C 41.749314 82.996875 17.6231 65.08125 19.318676 41.058333 C 20.210726 28.153125 27.088366 18.741667 38.889305 11.659375 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(28.627451%,52.156863%,76.078431%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.849768 44.111979 C 114.849768 65.622917 93.937658 83.091667 67.748152 83.091667 C 41.837838 82.996875 17.711624 65.08125 19.318676 40.970312 C 20.210726 28.058333 27.088366 18.741667 38.889305 11.659375 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(28.235294%,51.764706%,75.686275%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.849768 44.111979 C 114.849768 65.622917 93.937658 83.091667 67.748152 83.091667 C 41.933171 82.996875 17.6231 65.08125 19.318676 40.882292 C 20.210726 27.970313 27.088366 18.646875 38.889305 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(28.235294%,51.372549%,75.686275%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.849768 44.111979 C 114.849768 65.622917 93.937658 83.091667 67.748152 82.996875 C 41.933171 82.996875 17.6231 64.993229 19.318676 40.7875 C 20.29925 27.882292 27.088366 18.646875 38.889305 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(28.235294%,50.980392%,75.294118%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.849768 44.111979 C 114.849768 65.622917 94.019373 83.091667 67.748152 82.996875 C 41.933171 82.908854 17.527766 64.898438 19.318676 40.699479 C 20.29925 27.7875 27.088366 18.646875 38.889305 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.843137%,50.588235%,74.901961%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.849768 44.111979 C 114.849768 65.622917 94.019373 83.091667 67.836677 82.996875 C 42.021696 82.908854 17.527766 64.898438 19.4072 40.611458 C 20.387774 27.699479 27.17689 18.646875 38.977829 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.843137%,50.196078%,74.509804%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.849768 44.111979 C 114.849768 65.528125 94.019373 82.996875 67.836677 82.996875 C 42.021696 82.908854 17.527766 64.898438 19.4072 40.611458 C 20.387774 27.699479 27.17689 18.646875 38.977829 11.659375 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.45098%,49.803922%,74.509804%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.931482 44.017187 C 114.931482 65.440104 94.107897 82.908854 67.93201 82.820833 C 42.11022 82.820833 17.527766 64.810417 19.4072 40.428646 C 20.387774 27.611458 27.17689 18.558854 38.977829 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.45098%,49.411765%,74.117647%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.931482 44.017187 C 114.931482 65.440104 94.107897 82.908854 67.93201 82.820833 C 42.11022 82.820833 17.439242 64.810417 19.4072 40.340625 C 20.483108 27.530208 27.17689 18.558854 38.977829 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.45098%,49.019608%,73.72549%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.931482 44.017187 C 114.931482 65.440104 94.107897 82.908854 67.93201 82.820833 C 42.198744 82.820833 17.439242 64.810417 19.502534 40.252604 C 20.571632 27.442187 27.272223 18.558854 39.073163 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.058824%,48.627451%,73.333333%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.931482 44.017187 C 114.931482 65.440104 94.107897 82.908854 68.020534 82.820833 C 42.198744 82.732813 17.439242 64.722396 19.502534 40.252604 C 20.571632 27.442187 27.272223 18.558854 38.977829 11.571354 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(27.058824%,48.235294%,72.941176%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.931482 44.017187 C 114.931482 65.440104 94.203231 82.820833 68.020534 82.820833 C 42.287268 82.732813 17.439242 64.722396 19.502534 40.157812 C 20.571632 27.347396 27.272223 18.558854 38.977829 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(26.666667%,47.843137%,72.941176%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 114.931482 44.017187 C 114.931482 65.440104 94.203231 82.820833 68.020534 82.732813 C 42.287268 82.732813 17.350718 64.722396 19.502534 40.069792 C 20.571632 27.259375 27.272223 18.558854 38.977829 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(26.666667%,47.45098%,72.54902%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 44.017187 C 115.020006 65.440104 94.291755 82.820833 68.109058 82.732813 C 42.368982 82.651562 17.350718 64.627604 19.591058 39.981771 C 20.74868 27.171354 27.360747 18.470833 39.073163 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(26.666667%,47.058824%,72.156863%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 44.017187 C 115.020006 65.352083 94.291755 82.820833 68.109058 82.732813 C 42.368982 82.651562 17.350718 64.627604 19.591058 39.886979 C 20.74868 27.083333 27.360747 18.470833 39.073163 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(26.27451%,46.666667%,71.764706%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 44.017187 C 115.020006 65.352083 94.291755 82.732813 68.197582 82.732813 C 42.457506 82.651562 17.350718 64.539583 19.591058 39.798958 C 20.74868 26.988542 27.360747 18.470833 39.073163 11.483333 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(26.27451%,46.27451%,71.764706%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 44.017187 C 115.020006 65.352083 94.291755 82.732813 68.197582 82.651562 C 42.55284 82.556771 17.262194 64.539583 19.591058 39.710938 C 20.74868 26.900521 27.360747 18.470833 39.073163 11.388542 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(26.27451%,45.882353%,71.372549%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 44.017187 C 115.020006 65.352083 94.380279 82.732813 68.197582 82.651562 C 42.55284 82.556771 17.262194 64.539583 19.591058 39.622917 C 20.837204 26.900521 27.360747 18.382812 39.073163 11.388542 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.882353%,45.490196%,70.980392%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 43.929167 C 115.020006 65.257292 94.380279 82.651562 68.197582 82.556771 C 42.55284 82.46875 17.180479 64.451563 19.591058 39.541667 C 20.837204 26.8125 27.360747 18.382812 39.073163 11.388542 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.882353%,45.098039%,70.588235%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.020006 43.929167 C 115.020006 65.257292 94.380279 82.651562 68.197582 82.556771 C 42.55284 82.46875 17.091955 64.451563 19.591058 39.446875 C 20.837204 26.717708 27.360747 18.382812 39.073163 11.388542 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.490196%,44.705882%,70.588235%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.10853 43.929167 C 115.10853 65.257292 94.468803 82.556771 68.38144 82.556771 C 42.729888 82.46875 17.262194 64.451563 19.768106 39.358854 C 21.021062 26.629687 27.537796 18.382812 39.250211 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.490196%,44.313725%,70.196078%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.10853 43.929167 C 115.10853 65.257292 94.468803 82.556771 68.38144 82.46875 C 42.729888 82.380729 17.180479 64.356771 19.768106 39.270833 C 21.021062 26.541667 27.537796 18.288021 39.250211 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.490196%,43.921569%,69.803922%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.10853 43.929167 C 115.10853 65.169271 94.468803 82.556771 68.38144 82.46875 C 42.818412 82.380729 17.180479 64.356771 19.768106 39.182813 C 21.109586 26.446875 27.537796 18.288021 39.250211 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.098039%,43.529412%,69.411765%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.10853 43.929167 C 115.10853 65.169271 94.468803 82.556771 68.38144 82.46875 C 42.818412 82.380729 17.091955 64.26875 19.768106 39.088021 C 21.109586 26.358854 27.537796 18.288021 39.250211 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(25.098039%,43.137255%,69.411765%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.10853 43.929167 C 115.10853 65.169271 94.557327 82.556771 68.469964 82.46875 C 42.906936 82.380729 17.091955 64.26875 19.768106 39.088021 C 21.109586 26.358854 27.537796 18.288021 39.161687 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(24.705882%,42.745098%,69.019608%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.10853 43.929167 C 115.10853 65.169271 94.557327 82.46875 68.469964 82.380729 C 42.906936 82.292708 17.091955 64.180729 19.768106 38.911979 C 21.109586 26.270833 27.633129 18.288021 39.250211 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(24.705882%,42.352941%,68.627451%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.197054 43.929167 C 115.197054 65.169271 94.65266 82.46875 68.558488 82.380729 C 43.00227 82.292708 17.091955 64.180729 19.85663 38.911979 C 21.19811 26.182813 27.633129 18.288021 39.250211 11.300521 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(24.705882%,41.960784%,68.235294%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.197054 43.929167 C 115.197054 65.169271 94.65266 82.46875 68.558488 82.380729 C 43.00227 82.292708 16.996622 64.180729 19.85663 38.817188 C 21.19811 26.088021 27.633129 18.2 39.250211 11.2125 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(24.313725%,41.568627%,68.235294%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.197054 43.929167 C 115.197054 65.169271 94.65266 82.46875 68.558488 82.380729 C 43.00227 82.292708 16.996622 64.180729 19.85663 38.729167 C 21.286634 26 27.633129 18.2 39.250211 11.2125 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(24.313725%,41.176471%,67.843137%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.197054 43.841146 C 115.197054 65.08125 94.65266 82.380729 68.647012 82.292708 C 43.179318 82.197917 16.996622 64.092708 19.951964 38.641146 C 21.286634 26 27.721653 18.2 39.250211 11.2125 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.921569%,40.784314%,67.45098%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.197054 43.841146 C 115.197054 64.993229 94.65266 82.292708 68.647012 82.197917 C 43.179318 82.197917 16.908098 64.092708 19.85663 38.553125 C 21.381968 25.911979 27.721653 18.2 39.250211 11.2125 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.921569%,40.392157%,67.058824%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.197054 43.841146 C 115.197054 64.993229 94.741185 82.292708 68.647012 82.197917 C 43.179318 82.197917 16.908098 63.997917 19.951964 38.458333 C 21.381968 25.817187 27.721653 18.2 39.338735 11.2125 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.921569%,40%,67.058824%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.292388 43.841146 C 115.292388 64.993229 94.829709 82.292708 68.742346 82.197917 C 43.267842 82.109896 16.908098 63.997917 19.951964 38.370313 C 21.456873 25.729167 27.721653 18.111979 39.338735 11.2125 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.529412%,39.607843%,66.666667%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.292388 43.841146 C 115.292388 64.993229 94.829709 82.292708 68.742346 82.197917 C 43.267842 82.109896 16.908098 63.997917 19.951964 38.282292 C 21.456873 25.641146 27.721653 18.111979 39.338735 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.529412%,39.215686%,66.27451%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.292388 43.841146 C 115.292388 64.993229 94.829709 82.197917 68.83087 82.109896 C 43.363176 82.021875 16.908098 63.909896 20.026869 38.1875 C 21.552207 25.553125 27.810177 18.111979 39.427259 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.529412%,38.823529%,65.882353%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.292388 43.841146 C 115.292388 64.993229 94.829709 82.197917 68.83087 82.109896 C 43.363176 82.021875 16.819573 63.909896 19.951964 38.099479 C 21.456873 25.458333 27.721653 18.111979 39.338735 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.137255%,38.431373%,65.882353%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.292388 43.841146 C 115.292388 64.993229 94.829709 82.197917 68.83087 82.109896 C 43.4517 82.021875 16.819573 63.909896 20.026869 38.011458 C 21.640731 25.370313 27.810177 18.017188 39.427259 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(23.137255%,38.039216%,65.490196%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.292388 43.841146 C 115.292388 64.993229 94.829709 82.197917 68.83087 82.109896 C 43.4517 82.021875 16.731049 63.821875 20.026869 37.916667 C 21.640731 25.370313 27.810177 18.017188 39.427259 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(22.745098%,37.647059%,65.098039%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.841146 C 115.380912 64.898438 95.006757 82.197917 68.912584 82.109896 C 43.540224 81.927083 16.731049 63.821875 20.026869 37.916667 C 21.640731 25.370313 27.810177 18.111979 39.427259 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(22.745098%,37.254902%,64.705882%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.841146 C 115.380912 64.898438 95.006757 82.109896 69.001109 82.021875 C 43.628748 81.927083 16.819573 63.821875 20.122202 37.828646 C 21.729255 25.282292 27.898701 18.017188 39.427259 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(22.745098%,36.862745%,64.313725%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.753125 C 115.380912 64.810417 95.006757 82.021875 69.001109 81.927083 C 43.628748 81.839062 16.731049 63.639063 20.122202 37.652604 C 21.729255 25.113021 27.898701 17.942708 39.427259 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(22.352941%,36.470588%,64.313725%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.753125 C 115.380912 64.810417 95.006757 82.021875 69.001109 81.927083 C 43.628748 81.839062 16.642525 63.727083 20.122202 37.652604 C 21.817779 25.113021 27.898701 18.017188 39.427259 11.117708 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(22.352941%,36.078431%,63.921569%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.753125 C 115.380912 64.810417 95.006757 82.021875 69.001109 81.927083 C 43.717272 81.839062 16.642525 63.639063 20.122202 37.557813 C 21.817779 25.018229 27.898701 18.017188 39.427259 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.960784%,35.686275%,63.529412%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.753125 C 115.380912 64.810417 95.006757 82.021875 69.001109 81.839062 C 43.717272 81.839062 16.642525 63.639063 20.122202 37.469792 C 21.817779 24.930208 27.898701 17.942708 39.427259 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.960784%,35.294118%,63.137255%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.380912 43.753125 C 115.380912 64.810417 95.006757 81.927083 69.089633 81.839062 C 43.798986 81.751042 16.547192 63.551042 20.122202 37.381771 C 21.817779 24.842188 27.898701 17.942708 39.427259 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.960784%,34.901961%,63.137255%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.469436 43.753125 C 115.469436 64.810417 95.190614 81.927083 69.178157 81.839062 C 43.887511 81.751042 16.642525 63.551042 20.210726 37.286979 C 21.913112 24.747396 27.987226 17.942708 39.522593 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.568627%,34.509804%,62.745098%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.469436 43.753125 C 115.469436 64.722396 95.190614 81.927083 69.178157 81.839062 C 43.887511 81.751042 16.547192 63.551042 20.210726 37.198958 C 22.001636 24.659375 27.987226 17.942708 39.522593 10.941667 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.568627%,34.117647%,62.352941%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.469436 43.753125 C 115.469436 64.722396 95.190614 81.927083 69.178157 81.839062 C 43.887511 81.751042 16.458668 63.551042 20.210726 37.198958 C 22.001636 24.659375 27.987226 17.942708 39.522593 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.568627%,33.72549%,61.960784%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.469436 43.753125 C 115.469436 64.722396 95.190614 81.927083 69.178157 81.751042 C 43.887511 81.663021 16.458668 63.469792 20.210726 37.029688 C 22.001636 24.571354 27.987226 17.847917 39.522593 10.941667 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.176471%,33.333333%,61.960784%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.469436 43.753125 C 115.469436 64.722396 95.190614 81.839062 69.266681 81.751042 C 44.071368 81.663021 16.458668 63.469792 20.29925 37.029688 C 22.09016 24.571354 28.082559 17.942708 39.597498 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(21.176471%,32.941176%,61.568627%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.469436 43.753125 C 115.469436 64.722396 95.190614 81.839062 69.266681 81.751042 C 44.071368 81.663021 16.370144 63.469792 20.29925 36.941667 C 22.09016 24.483333 28.082559 17.847917 39.597498 11.029687 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
        </g>
        <g clipPath="url(#clip6)" clipRule="nonzero">
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20.784314%,32.54902%,61.176471%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.55796 43.658333 C 115.55796 64.627604 95.279139 81.751042 69.362014 81.663021 C 44.159892 81.568229 16.458668 63.381771 20.29925 36.758854 C 22.178685 24.300521 28.082559 17.759896 39.597498 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20.784314%,32.156863%,60.784314%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.55796 43.658333 C 115.55796 64.627604 95.367663 81.751042 69.362014 81.568229 C 44.159892 81.480208 16.370144 63.286979 20.29925 36.670833 C 22.178685 24.2125 28.082559 17.759896 39.597498 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20.784314%,31.764706%,60.784314%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.55796 43.658333 C 115.55796 64.627604 95.367663 81.663021 69.362014 81.568229 C 44.159892 81.480208 16.28162 63.286979 20.29925 36.582813 C 22.178685 24.117708 28.082559 17.759896 39.522593 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20.392157%,31.372549%,60.392157%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.55796 43.658333 C 115.55796 64.539583 95.367663 81.663021 69.450538 81.568229 C 44.248416 81.480208 16.370144 63.286979 20.387774 36.488021 C 22.267209 24.029688 28.171083 17.671875 39.597498 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20.392157%,30.980392%,60%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.55796 43.658333 C 115.55796 64.539583 95.367663 81.663021 69.450538 81.568229 C 44.248416 81.480208 16.28162 63.286979 20.387774 36.488021 C 22.267209 24.029688 28.171083 17.759896 39.597498 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20%,30.588235%,59.607843%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.55796 43.658333 C 115.55796 64.539583 95.367663 81.663021 69.450538 81.568229 C 44.33694 81.480208 16.193095 63.198958 20.387774 36.4 C 22.267209 23.941667 28.171083 17.759896 39.597498 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20%,30.196078%,59.607843%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.658333 C 115.653294 64.539583 95.449377 81.663021 69.539062 81.480208 C 44.432274 81.392188 16.28162 63.110938 20.483108 36.311979 C 22.451066 23.846875 28.259607 17.759896 39.692832 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(20%,29.803922%,59.215686%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.658333 C 115.653294 64.539583 95.449377 81.568229 69.539062 81.480208 C 44.432274 81.392188 16.193095 63.110938 20.483108 36.217188 C 22.451066 23.846875 28.259607 17.671875 39.692832 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(19.607843%,29.411765%,58.823529%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.658333 C 115.653294 64.539583 95.537901 81.568229 69.627587 81.480208 C 44.520798 81.297396 16.193095 63.110938 20.483108 36.129167 C 22.451066 23.758854 28.259607 17.671875 39.692832 10.772396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(19.607843%,29.019608%,58.431373%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.658333 C 115.653294 64.539583 95.537901 81.568229 69.627587 81.480208 C 44.520798 81.392188 16.193095 63.110938 20.483108 36.129167 C 22.451066 23.758854 28.259607 17.759896 39.692832 10.846875 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(19.215686%,28.627451%,58.431373%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.658333 C 115.653294 64.539583 95.537901 81.568229 69.627587 81.392188 C 44.609322 81.297396 16.097762 63.022917 20.483108 35.953125 C 22.451066 23.582812 28.259607 17.671875 39.692832 10.772396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(19.215686%,28.235294%,58.039216%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.570312 C 115.653294 64.451563 95.537901 81.480208 69.627587 81.297396 C 44.609322 81.209375 16.009238 62.928125 20.483108 35.858333 C 22.53959 23.488021 28.259607 17.577083 39.692832 10.772396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(19.215686%,27.843137%,57.647059%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.653294 43.570312 C 115.653294 64.356771 95.537901 81.392188 69.627587 81.297396 C 44.609322 81.209375 16.009238 62.928125 20.483108 35.770313 C 22.53959 23.4 28.259607 17.577083 39.692832 10.772396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.823529%,27.45098%,57.254902%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.741818 43.570312 C 115.741818 64.356771 95.633235 81.392188 69.811444 81.297396 C 44.79318 81.209375 16.097762 62.928125 20.660156 35.682292 C 22.716639 23.311979 28.429846 17.577083 39.86988 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.823529%,27.058824%,56.862745%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.741818 43.570312 C 115.741818 64.356771 95.633235 81.392188 69.811444 81.297396 C 44.79318 81.209375 16.009238 62.928125 20.660156 35.682292 C 22.716639 23.311979 28.429846 17.577083 39.86988 10.772396 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.823529%,26.666667%,56.862745%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.741818 43.570312 C 115.741818 64.356771 95.721759 81.392188 69.811444 81.209375 C 44.79318 81.121354 16.009238 62.840104 20.571632 35.499479 C 22.628114 23.129167 28.348131 17.489062 39.781356 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.431373%,26.27451%,56.470588%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.741818 43.570312 C 115.741818 64.356771 95.721759 81.392188 69.811444 81.209375 C 44.79318 81.121354 15.920714 62.840104 20.571632 35.411458 C 22.716639 23.041146 28.348131 17.489062 39.692832 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.431373%,25.882353%,56.078431%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.741818 43.570312 C 115.741818 64.356771 95.721759 81.297396 69.899968 81.209375 C 44.881704 81.121354 15.920714 62.840104 20.660156 35.411458 C 22.798353 23.041146 28.429846 17.577083 39.781356 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.039216%,25.490196%,55.686275%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.741818 43.570312 C 115.741818 64.356771 95.721759 81.297396 69.899968 81.209375 C 44.970228 81.121354 15.920714 62.840104 20.660156 35.316667 C 22.798353 23.041146 28.429846 17.489062 39.781356 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.039216%,25.098039%,55.686275%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.830342 43.570312 C 115.830342 64.356771 95.810283 81.297396 69.988492 81.121354 C 44.970228 81.033333 15.83219 62.752083 20.660156 35.228646 C 22.798353 22.953125 28.429846 17.489062 39.781356 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(18.039216%,24.705882%,55.294118%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.830342 43.570312 C 115.830342 64.26875 95.810283 81.297396 69.988492 81.121354 C 45.058752 80.938542 15.83219 62.657292 20.74868 35.140625 C 22.886877 22.858333 28.51837 17.489062 39.86988 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(17.647059%,24.313725%,54.901961%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.830342 43.570312 C 115.830342 64.26875 95.810283 81.297396 69.988492 81.121354 C 45.058752 80.938542 15.83219 62.657292 20.74868 35.052604 C 22.982211 22.770312 28.51837 17.489062 39.86988 10.677604 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(17.647059%,23.921569%,54.509804%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.830342 43.482292 C 115.830342 64.180729 95.898807 81.121354 70.077016 81.033333 C 45.140467 80.938542 15.750475 62.657292 20.74868 34.957813 C 22.982211 22.689063 28.51837 17.401042 39.86988 10.589583 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(17.254902%,23.529412%,54.509804%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.830342 43.482292 C 115.830342 64.180729 95.898807 81.121354 70.077016 81.033333 C 45.140467 80.938542 15.750475 62.569271 20.74868 34.869792 C 22.982211 22.601042 28.51837 17.401042 39.86988 10.589583 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(17.254902%,23.137255%,54.117647%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.830342 43.482292 C 115.830342 64.180729 95.898807 81.121354 70.077016 80.938542 C 45.140467 80.850521 15.661951 62.48125 20.74868 34.781771 C 22.982211 22.513021 28.51837 17.401042 39.86988 10.589583 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(17.254902%,22.745098%,53.72549%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.180729 95.987331 81.121354 70.17235 80.938542 C 45.317515 80.850521 15.750475 62.48125 20.837204 34.700521 C 23.070735 22.418229 28.606894 17.313021 39.958404 10.589583 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.862745%,22.352941%,53.333333%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.180729 95.987331 81.033333 70.17235 80.938542 C 45.317515 80.7625 15.661951 62.48125 20.837204 34.6125 C 23.070735 22.330208 28.606894 17.313021 39.958404 10.501563 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.862745%,21.960784%,53.333333%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.180729 95.987331 81.033333 70.260874 80.938542 C 45.412848 80.7625 15.661951 62.48125 20.932538 34.6125 C 23.159259 22.330208 28.702228 17.313021 39.958404 10.589583 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.470588%,21.568627%,52.941176%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.092708 96.082665 81.033333 70.260874 80.850521 C 45.412848 80.667708 15.661951 62.393229 20.932538 34.429688 C 23.159259 22.242188 28.702228 17.313021 39.958404 10.501563 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.470588%,21.176471%,52.54902%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.092708 96.082665 81.033333 70.260874 80.850521 C 45.412848 80.667708 15.478093 62.393229 20.837204 34.341667 C 23.159259 22.147396 28.702228 17.313021 39.958404 10.501563 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.470588%,20.784314%,52.156863%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.092708 96.082665 81.033333 70.260874 80.850521 C 45.501372 80.667708 15.478093 62.298437 20.932538 34.341667 C 23.247783 22.147396 28.702228 17.313021 40.046928 10.501563 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.078431%,20.392157%,52.156863%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 115.918866 43.482292 C 115.918866 64.092708 96.082665 80.938542 70.260874 80.850521 C 45.501372 80.667708 15.389569 62.393229 20.837204 34.246875 C 23.159259 22.059375 28.606894 17.313021 39.86988 10.501563 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.078431%,20%,51.764706%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 116.00739 43.482292 C 116.00739 64.092708 96.171189 80.938542 70.431113 80.850521 C 45.678421 80.667708 15.478093 62.393229 21.021062 34.246875 C 23.343117 22.059375 28.790752 17.401042 40.046928 10.589583 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(16.078431%,19.607843%,51.372549%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 116.00739 43.3875 C 116.00739 63.997917 96.171189 80.850521 70.431113 80.667708 C 45.678421 80.491667 15.478093 62.122396 21.021062 33.982813 C 23.431641 21.788542 28.790752 17.130208 40.046928 10.406771 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.686275%,19.215686%,50.980392%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 116.00739 43.3875 C 116.00739 63.997917 96.171189 80.850521 70.431113 80.667708 C 45.678421 80.491667 15.389569 62.122396 21.021062 33.888021 C 23.431641 21.700521 28.790752 17.130208 40.046928 10.406771 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.686275%,18.823529%,50.980392%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 116.00739 43.3875 C 116.00739 63.997917 96.259713 80.850521 70.431113 80.667708 C 45.678421 80.491667 15.389569 62.122396 21.021062 33.888021 C 23.431641 21.700521 28.790752 17.218229 40.046928 10.406771 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.431373%,50.588235%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 116.00739 43.3875 C 116.00739 63.909896 96.259713 80.7625 70.431113 80.667708 C 45.766945 80.491667 15.389569 62.122396 21.021062 33.8 C 23.431641 21.6125 28.790752 17.218229 40.046928 10.406771 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
          <path
            style={cssStringToJson(
              'fill:none; stroke-width:0.578; stroke-linecap:butt; stroke-linejoin:miter; stroke:rgb(15.294118%,18.039216%,50.196078%); stroke-opacity:1; stroke-miterlimit:4;',
            )}
            d="M 116.00739 43.3875 C 116.00739 63.909896 96.259713 80.7625 70.519637 80.579688 C 45.862278 80.491667 15.301045 62.122396 21.021062 33.711979 C 23.431641 21.517708 28.790752 17.130208 40.046928 10.406771 "
            transform="matrix(0.573643,0,0,0.576923,0,0)"
          />
        </g>
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(15.294118%,18.039216%,50.196078%); fill-opacity:1;',
          )}
          d="M 58.390625 38.132812 L 67.054688 38.132812 L 67.054688 40.484375 L 58.390625 40.484375 Z M 58.390625 38.132812 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(15.294118%,18.039216%,50.196078%); fill-opacity:1;',
          )}
          d="M 57.726562 40.535156 L 58.390625 41.265625 L 58.949219 40.847656 L 59.308594 40.484375 L 59.308594 39.808594 L 58.289062 39.492188 Z M 57.726562 40.535156 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:evenodd; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 27.441406 36.96875 C 27.851562 37.03125 28.058594 37.261719 28.058594 37.617188 C 28.058594 37.960938 27.851562 38.140625 27.496094 38.199219 C 27.390625 38.199219 27.238281 38.253906 27.082031 38.253906 L 26.007812 38.253906 L 26.007812 35.917969 L 26.925781 35.917969 C 27.238281 35.917969 27.496094 35.976562 27.59375 36.035156 C 27.746094 36.15625 27.851562 36.269531 27.851562 36.5 C 27.851562 36.625 27.796875 36.738281 27.695312 36.855469 C 27.695312 36.910156 27.59375 36.96875 27.441406 36.96875 Z M 26.882812 36.855469 C 27.238281 36.855469 27.441406 36.738281 27.441406 36.445312 C 27.441406 36.269531 27.390625 36.214844 27.238281 36.15625 C 27.183594 36.15625 27.03125 36.097656 26.882812 36.097656 L 26.417969 36.097656 L 26.417969 36.855469 Z M 26.46875 37.148438 L 26.46875 37.960938 L 27.03125 37.960938 C 27.390625 37.960938 27.59375 37.847656 27.59375 37.558594 C 27.59375 37.261719 27.390625 37.148438 26.976562 37.148438 Z M 26.46875 37.148438 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:evenodd; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 30.046875 37.5 L 28.820312 37.5 L 28.515625 38.199219 L 28.109375 38.199219 L 29.179688 35.863281 L 29.691406 35.863281 L 30.816406 38.199219 L 30.355469 38.199219 Z M 29.898438 37.261719 L 29.433594 36.214844 L 28.976562 37.261719 Z M 29.898438 37.261719 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 32.8125 37.671875 C 32.8125 37.671875 32.863281 37.730469 32.863281 37.789062 C 32.863281 37.789062 32.863281 37.789062 32.863281 37.847656 L 32.863281 35.917969 L 33.269531 35.917969 L 33.269531 38.253906 L 32.761719 38.253906 L 31.480469 36.445312 C 31.480469 36.445312 31.429688 36.386719 31.429688 36.328125 C 31.429688 36.328125 31.429688 36.328125 31.429688 36.386719 C 31.429688 36.386719 31.429688 36.386719 31.429688 36.445312 L 31.429688 38.3125 L 31.074219 38.3125 L 31.074219 35.976562 L 31.585938 35.976562 Z M 32.8125 37.671875 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:evenodd; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 35.625 37.960938 L 36.339844 37.902344 L 36.339844 38.199219 L 34.855469 38.199219 C 34.5 38.199219 34.1875 38.082031 33.9375 37.902344 C 33.679688 37.671875 33.574219 37.378906 33.574219 37.03125 C 33.574219 36.679688 33.679688 36.328125 33.9375 36.15625 C 34.144531 35.976562 34.449219 35.863281 34.855469 35.863281 C 35.214844 35.863281 35.519531 35.976562 35.777344 36.15625 C 36.03125 36.386719 36.132812 36.679688 36.132812 37.03125 C 36.132812 37.261719 36.082031 37.4375 36.03125 37.617188 C 35.882812 37.730469 35.777344 37.902344 35.625 37.960938 Z M 34.039062 37.03125 C 34.039062 37.671875 34.292969 37.960938 34.804688 37.960938 C 35.0625 37.960938 35.269531 37.902344 35.417969 37.730469 C 35.570312 37.558594 35.625 37.320312 35.625 37.03125 C 35.625 36.386719 35.371094 36.097656 34.855469 36.097656 C 34.292969 36.097656 34.039062 36.386719 34.039062 37.03125 Z M 34.039062 37.03125 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 37.671875 38.253906 C 37.308594 38.253906 37.003906 38.199219 36.851562 38.023438 C 36.695312 37.847656 36.59375 37.617188 36.59375 37.261719 L 36.59375 35.863281 L 37.003906 35.863281 L 37.003906 37.207031 C 37.003906 37.5 37.058594 37.671875 37.109375 37.789062 C 37.207031 37.902344 37.363281 37.960938 37.621094 37.960938 C 37.871094 37.960938 38.078125 37.902344 38.128906 37.789062 C 38.234375 37.671875 38.234375 37.5 38.234375 37.207031 L 38.234375 35.863281 L 38.640625 35.863281 L 38.640625 37.261719 C 38.640625 37.617188 38.539062 37.902344 38.335938 38.023438 C 38.285156 38.199219 38.027344 38.253906 37.671875 38.253906 Z M 37.671875 38.253906 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 40.835938 37.960938 L 40.835938 38.253906 L 39.152344 38.253906 L 39.152344 35.917969 L 40.792969 35.917969 L 40.792969 36.214844 L 39.5625 36.214844 L 39.5625 36.855469 L 40.6875 36.855469 L 40.6875 37.089844 L 39.5625 37.089844 L 39.5625 37.902344 L 40.835938 37.902344 Z M 40.835938 37.960938 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 43.855469 37.960938 L 43.855469 38.253906 L 42.167969 38.253906 L 42.167969 35.917969 L 43.808594 35.917969 L 43.808594 36.214844 L 42.582031 36.214844 L 42.582031 36.855469 L 43.707031 36.855469 L 43.707031 37.089844 L 42.582031 37.089844 L 42.582031 37.902344 L 43.855469 37.902344 Z M 43.855469 37.960938 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 46.058594 36.15625 L 45.238281 36.15625 L 45.238281 38.253906 L 44.832031 38.253906 L 44.832031 36.15625 L 44.011719 36.15625 L 44.011719 35.863281 L 46.058594 35.863281 Z M 46.058594 36.15625 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:evenodd; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 49.023438 37.5 L 47.796875 37.5 L 47.484375 38.199219 L 47.078125 38.199219 L 48.152344 35.863281 L 48.667969 35.863281 L 49.792969 38.199219 L 49.328125 38.199219 Z M 48.867188 37.261719 L 48.410156 36.214844 L 47.949219 37.261719 Z M 48.867188 37.261719 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 50.917969 35.804688 C 51.070312 35.804688 51.273438 35.804688 51.53125 35.863281 C 51.582031 35.863281 51.683594 35.863281 51.730469 35.917969 L 51.730469 36.214844 C 51.425781 36.097656 51.167969 36.035156 50.96875 36.035156 C 50.605469 36.035156 50.40625 36.15625 50.40625 36.328125 C 50.40625 36.445312 50.457031 36.5 50.605469 36.625 C 50.660156 36.625 50.8125 36.738281 51.117188 36.855469 C 51.375 36.96875 51.53125 37.03125 51.632812 37.148438 C 51.78125 37.261719 51.835938 37.4375 51.835938 37.617188 C 51.835938 37.960938 51.632812 38.199219 51.21875 38.253906 C 51.117188 38.253906 50.96875 38.253906 50.863281 38.253906 C 50.660156 38.253906 50.40625 38.253906 50.097656 38.199219 C 49.992188 38.199219 49.941406 38.140625 49.890625 38.140625 L 49.890625 37.789062 C 50.199219 37.902344 50.554688 38.023438 50.863281 38.023438 C 51.117188 38.023438 51.273438 37.960938 51.375 37.847656 C 51.425781 37.789062 51.425781 37.730469 51.425781 37.671875 C 51.425781 37.558594 51.375 37.4375 51.21875 37.378906 C 51.117188 37.320312 50.96875 37.261719 50.710938 37.148438 C 50.457031 37.03125 50.304688 36.96875 50.199219 36.855469 C 50.042969 36.738281 49.941406 36.566406 49.941406 36.386719 C 49.941406 36.15625 50.042969 35.976562 50.304688 35.917969 C 50.457031 35.863281 50.660156 35.804688 50.917969 35.804688 Z M 50.917969 35.804688 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 53.113281 35.804688 C 53.269531 35.804688 53.476562 35.804688 53.726562 35.863281 C 53.777344 35.863281 53.882812 35.863281 53.933594 35.917969 L 53.933594 36.214844 C 53.625 36.097656 53.371094 36.035156 53.164062 36.035156 C 52.808594 36.035156 52.601562 36.15625 52.601562 36.328125 C 52.601562 36.445312 52.65625 36.5 52.808594 36.625 C 52.859375 36.625 53.011719 36.738281 53.320312 36.855469 C 53.574219 36.96875 53.726562 37.03125 53.832031 37.148438 C 53.984375 37.261719 54.039062 37.4375 54.039062 37.617188 C 54.039062 37.960938 53.832031 38.199219 53.421875 38.253906 C 53.320312 38.253906 53.164062 38.253906 53.0625 38.253906 C 52.859375 38.253906 52.601562 38.253906 52.300781 38.199219 C 52.195312 38.199219 52.144531 38.140625 52.09375 38.140625 L 52.09375 37.789062 C 52.398438 37.902344 52.757812 38.023438 53.0625 38.023438 C 53.320312 38.023438 53.476562 37.960938 53.574219 37.847656 C 53.625 37.789062 53.625 37.730469 53.625 37.671875 C 53.625 37.558594 53.574219 37.4375 53.421875 37.378906 C 53.320312 37.320312 53.164062 37.261719 52.914062 37.148438 C 52.65625 37.03125 52.5 36.96875 52.398438 36.855469 C 52.246094 36.738281 52.144531 36.566406 52.144531 36.386719 C 52.144531 36.15625 52.246094 35.976562 52.5 35.917969 C 52.65625 35.863281 52.859375 35.804688 53.113281 35.804688 Z M 53.113281 35.804688 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 55.464844 38.253906 C 55.109375 38.253906 54.800781 38.199219 54.652344 38.023438 C 54.496094 37.847656 54.394531 37.617188 54.394531 37.261719 L 54.394531 35.863281 L 54.800781 35.863281 L 54.800781 37.207031 C 54.800781 37.5 54.851562 37.671875 54.902344 37.789062 C 55.007812 37.902344 55.160156 37.960938 55.414062 37.960938 C 55.671875 37.960938 55.878906 37.902344 55.929688 37.789062 C 56.027344 37.671875 56.027344 37.5 56.027344 37.207031 L 56.027344 35.863281 L 56.441406 35.863281 L 56.441406 37.261719 C 56.441406 37.617188 56.335938 37.902344 56.132812 38.023438 C 56.027344 38.199219 55.773438 38.253906 55.464844 38.253906 Z M 55.464844 38.253906 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:evenodd; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 58.378906 37.089844 C 58.535156 37.089844 58.636719 37.148438 58.691406 37.207031 C 58.742188 37.261719 58.792969 37.378906 58.84375 37.5 L 59.046875 38.140625 L 58.585938 38.140625 L 58.429688 37.617188 C 58.378906 37.4375 58.332031 37.320312 58.230469 37.207031 C 58.128906 37.148438 58.023438 37.089844 57.871094 37.089844 L 57.410156 37.089844 L 57.410156 38.140625 L 57.003906 38.140625 L 57.003906 35.804688 L 58.023438 35.804688 C 58.332031 35.804688 58.535156 35.863281 58.691406 35.917969 C 58.84375 36.035156 58.949219 36.15625 58.949219 36.386719 C 58.949219 36.566406 58.894531 36.679688 58.792969 36.796875 C 58.636719 37.03125 58.535156 37.089844 58.378906 37.089844 Z M 57.921875 36.910156 C 58.28125 36.910156 58.484375 36.796875 58.484375 36.5 C 58.484375 36.386719 58.429688 36.269531 58.332031 36.214844 C 58.230469 36.15625 58.128906 36.15625 57.921875 36.15625 L 57.359375 36.15625 L 57.359375 36.910156 Z M 57.921875 36.910156 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:evenodd; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 60.988281 37.5 L 59.761719 37.5 L 59.457031 38.199219 L 59.046875 38.199219 L 60.125 35.863281 L 60.632812 35.863281 L 61.757812 38.199219 L 61.300781 38.199219 Z M 60.886719 37.261719 L 60.425781 36.214844 L 59.96875 37.261719 Z M 60.886719 37.261719 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 63.800781 37.671875 C 63.800781 37.671875 63.851562 37.730469 63.851562 37.789062 C 63.851562 37.789062 63.851562 37.789062 63.851562 37.847656 L 63.851562 35.917969 L 64.265625 35.917969 L 64.265625 38.253906 L 63.753906 38.253906 L 62.476562 36.445312 C 62.476562 36.445312 62.425781 36.386719 62.425781 36.328125 C 62.425781 36.328125 62.425781 36.328125 62.425781 36.386719 C 62.425781 36.386719 62.425781 36.386719 62.425781 36.445312 L 62.425781 38.3125 L 62.0625 38.3125 L 62.0625 35.976562 L 62.578125 35.976562 Z M 63.800781 37.671875 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 66.667969 36.214844 C 66.515625 36.097656 66.308594 36.035156 66.003906 36.035156 C 65.695312 36.035156 65.441406 36.15625 65.285156 36.328125 C 65.132812 36.5 65.027344 36.738281 65.027344 37.03125 C 65.027344 37.320312 65.132812 37.558594 65.285156 37.730469 C 65.441406 37.902344 65.695312 37.960938 65.953125 37.960938 C 66.160156 37.960938 66.359375 37.902344 66.515625 37.847656 C 66.566406 37.847656 66.617188 37.789062 66.667969 37.789062 L 66.667969 38.082031 C 66.410156 38.199219 66.160156 38.199219 65.847656 38.199219 C 65.441406 38.199219 65.132812 38.082031 64.878906 37.902344 C 64.621094 37.671875 64.519531 37.378906 64.519531 37.03125 C 64.519531 36.738281 64.621094 36.5 64.777344 36.269531 C 64.929688 36.097656 65.132812 35.917969 65.339844 35.863281 C 65.492188 35.804688 65.695312 35.746094 65.898438 35.746094 C 66.160156 35.746094 66.410156 35.804688 66.617188 35.863281 L 66.617188 36.214844 Z M 66.667969 36.214844 "
        />
        <path
          style={cssStringToJson(
            'stroke:none; fill-rule:nonzero; fill:rgb(100%,100%,100%); fill-opacity:1;',
          )}
          d="M 68.710938 37.960938 L 68.710938 38.253906 L 67.023438 38.253906 L 67.023438 35.917969 L 68.660156 35.917969 L 68.660156 36.214844 L 67.433594 36.214844 L 67.433594 36.855469 L 68.5625 36.855469 L 68.5625 37.089844 L 67.433594 37.089844 L 67.433594 37.902344 L 68.710938 37.902344 Z M 68.710938 37.960938 "
        />
      </g>
    </svg>
  );
};

LCLSvg.propTypes = {
  alt: PropTypes.string.isRequired,
};

export default LCLSvg;
