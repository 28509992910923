import React from 'react';

import './styles.scss';

const Footer = () => (
  <div className="footer__main">
    <a target="__blank" href="//www.utocat.com">
      www.utocat.com
    </a>
    <p>© COPYRIGHT UTOCAT 2017</p>
    <a target="_blank" href="/legal-notice">
      Mentions légales
    </a>{' '}
    -&nbsp;
    <a target="_blank" href="/cgu">
      Conditions Générales D’utilisation
    </a>
    <p>v. {process.env.REACT_APP_PROJECT_VERSION}</p>
  </div>
);

export default Footer;
