import * as React from 'react';
import PropTypes from 'prop-types';

const CaAlpesProvenceSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      alt={alt}
      viewBox="0 0 1396 255"
      enableBackground="new 0 0 1396 255"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1051.000000,256.000000 
	C700.666748,256.000000 350.833557,256.000000 1.000235,256.000000 
	C1.000156,171.000122 1.000156,86.000259 1.000078,1.000292 
	C466.333038,1.000194 931.666077,1.000194 1396.999268,1.000097 
	C1396.999512,85.999702 1396.999512,170.999405 1396.999756,255.999557 
	C1281.833374,256.000000 1166.666626,256.000000 1051.000000,256.000000 
M227.530243,152.284882 
	C231.685471,162.182465 235.819946,172.088852 240.005569,181.973557 
	C241.818497,186.254959 245.065811,188.161118 249.767853,188.038849 
	C256.085052,187.874573 262.409821,187.999451 268.731323,187.999832 
	C273.962830,188.000153 279.194336,187.999908 285.380066,187.999908 
	C280.482056,177.743576 276.032837,168.548843 271.692780,159.302826 
	C267.764954,150.935104 263.956329,142.511459 260.710999,133.781921 
	C276.522186,124.174454 292.360474,114.610786 308.095367,104.879951 
	C309.415771,104.063377 310.826172,102.143326 310.862915,100.701988 
	C311.107666,91.101295 310.989838,81.491348 310.989838,71.319931 
	C289.325348,83.064003 268.184906,94.523994 246.943085,105.250931 
	C239.079575,87.747398 231.163391,70.267166 223.405319,52.717022 
	C222.137466,49.848907 220.423904,48.884724 217.338928,48.937019 
	C207.020340,49.111950 196.695923,49.083008 186.375977,48.953861 
	C183.394394,48.916550 181.479523,49.684280 180.077286,52.566509 
	C176.600143,59.713600 172.836365,66.724243 169.077866,73.730751 
	C163.288757,84.522781 157.354660,95.237129 151.578964,106.036255 
	C144.105133,120.010513 136.726578,134.035736 128.946701,148.719040 
	C131.299240,148.826752 133.123825,149.278763 134.774796,148.923645 
	C142.983047,147.158066 151.390015,145.864273 159.246323,143.058456 
	C165.448227,140.843491 172.505127,138.713715 175.864151,132.095947 
	C180.809784,122.352333 185.326050,112.390175 189.992111,102.505493 
	C193.111435,95.897423 196.173279,89.262230 199.918945,81.227486 
	C205.647415,96.168365 210.770706,109.530861 215.177841,123.009895 
	C200.656616,130.001007 186.247864,137.239075 171.584412,143.917969 
	C154.931091,151.503189 137.209198,155.936935 119.234367,158.623337 
	C106.700119,160.496613 93.825195,161.069489 81.156006,160.640762 
	C74.980911,160.431793 68.803795,156.892670 62.860195,154.322968 
	C58.242298,152.326431 57.440449,147.799637 58.164623,143.391724 
	C58.740303,139.887695 60.098740,136.465332 61.447659,133.143982 
	C65.659187,122.774216 73.203697,114.911476 81.138336,107.341438 
	C95.739899,93.410843 112.978195,84.177177 132.371338,79.075020 
	C141.002640,76.804199 149.897614,75.535530 159.276871,73.693382 
	C157.199677,67.534271 155.276489,60.943859 152.740158,54.598530 
	C150.788956,49.716957 146.798416,46.318024 141.477371,46.151337 
	C135.747452,45.971836 129.762665,46.077194 124.269279,47.512939 
	C95.604637,55.004719 70.277176,68.258148 51.534683,92.014656 
	C42.218510,103.823090 35.121529,116.431885 35.902859,132.397217 
	C36.974072,154.285751 46.545155,170.584961 65.905800,180.962357 
	C86.336845,191.913467 108.270416,192.155457 130.276382,188.663757 
	C154.245621,184.860565 176.503815,175.452194 198.387161,165.255447 
	C207.919495,160.813766 217.427322,156.319550 227.530243,152.284882 
M113.500000,226.000000 
	C148.644363,226.000000 183.788712,226.000397 218.933075,225.999863 
	C241.918503,225.999496 264.904327,225.928909 287.888947,226.064850 
	C291.697510,226.087357 293.374756,225.083176 293.062897,221.023911 
	C292.757599,217.049973 292.694885,213.004898 293.076508,209.044113 
	C293.544128,204.190826 291.773468,202.858246 286.955505,202.894684 
	C258.475189,203.110077 229.992416,202.999939 201.510483,203.000076 
	C148.877197,203.000336 96.243813,203.046066 43.610840,202.917496 
	C39.330673,202.907043 37.511448,203.946167 37.926319,208.477295 
	C38.289280,212.441513 38.273884,216.488907 37.929291,220.456451 
	C37.545090,224.880035 39.153084,226.130997 43.544563,226.092529 
	C66.528214,225.891129 89.514580,225.999390 113.500000,226.000000 
M1149.772827,203.984894 
	C1151.937744,203.988388 1154.121582,203.811920 1156.263550,204.035019 
	C1160.056519,204.430069 1161.200928,202.801025 1161.061035,199.150803 
	C1160.825073,192.998199 1160.999023,186.829895 1160.999756,180.668198 
	C1161.000488,174.637573 1161.000000,168.606964 1161.000000,162.576340 
	C1172.063110,173.790634 1182.239258,185.108490 1191.810791,196.916428 
	C1195.795654,201.832474 1199.760620,204.673294 1206.395142,204.176773 
	C1210.586182,203.863144 1212.171875,203.064896 1212.100708,198.540054 
	C1211.841431,182.057419 1212.084106,165.567627 1211.968384,149.081726 
	C1211.897705,138.996429 1213.801758,140.195084 1202.511597,139.948120 
	C1199.003540,139.871384 1197.891968,141.133347 1197.946533,144.569351 
	C1198.110107,154.891739 1198.001953,165.218445 1198.000000,175.543457 
	C1197.999634,177.630371 1198.000000,179.717285 1198.000000,181.804199 
	C1195.212524,180.299850 1193.596436,178.351303 1191.932617,176.444321 
	C1182.028076,165.092102 1172.151611,153.714813 1162.142700,142.455246 
	C1161.094116,141.275543 1159.309814,140.346237 1157.747314,140.117645 
	C1155.138794,139.736069 1152.428589,140.117050 1149.770508,139.987000 
	C1146.968140,139.849899 1145.970947,141.055603 1145.980591,143.811401 
	C1146.046265,162.629303 1145.962891,181.448044 1146.138550,200.264526 
	C1146.150024,201.502487 1147.905396,202.724182 1149.772827,203.984894 
M899.955872,164.948730 
	C900.154724,158.616241 901.074768,151.690613 894.908264,147.681473 
	C890.335510,144.708496 884.961121,141.800537 879.705078,141.281525 
	C867.504395,140.076721 855.153015,140.475433 842.875916,139.927444 
	C838.407227,139.727997 836.812988,140.992615 836.895081,145.714050 
	C837.173279,161.705414 837.000183,177.704651 837.000000,193.700867 
	C836.999878,204.797516 836.994812,204.705307 848.285583,204.048065 
	C851.108398,203.883728 852.083740,202.845749 852.018982,200.132935 
	C851.907776,195.470261 851.935059,190.800919 852.056091,186.138306 
	C852.088379,184.894821 852.677185,182.589676 853.002502,182.591095 
	C858.004700,182.613052 863.922852,181.353470 867.776184,183.586899 
	C872.673035,186.425110 876.142456,191.961258 879.783447,196.676910 
	C885.535461,204.126785 892.496460,206.323776 902.121704,202.737732 
	C895.830444,195.303925 889.669434,188.023987 883.465088,180.692917 
	C897.489746,172.697525 897.489746,172.697525 899.955872,164.948730 
M626.639404,111.219376 
	C636.403320,110.161995 645.217590,106.598991 651.338501,98.880066 
	C664.316345,82.514099 656.852905,58.828621 637.064636,51.883083 
	C621.918213,46.566780 606.249207,48.256115 590.716797,48.202747 
	C589.824585,48.199680 588.153259,50.258430 588.144104,51.368294 
	C587.986572,70.517067 587.979736,89.668083 588.179016,108.816132 
	C588.190125,109.879112 590.271301,111.822632 591.402222,111.832481 
	C602.875427,111.932442 614.351440,111.715164 626.639404,111.219376 
M971.575928,48.000023 
	C961.911560,48.137337 963.057190,46.883762 963.017090,56.487232 
	C962.953186,71.797050 962.888794,87.108429 963.038147,102.417061 
	C963.142029,113.056938 960.802429,111.862976 972.973877,112.030975 
	C976.001648,112.072777 977.128113,110.953194 977.030273,107.949493 
	C976.878662,103.295143 976.892578,98.627075 977.086548,93.975266 
	C977.139404,92.706528 977.751221,90.818130 978.683594,90.340462 
	C982.692139,88.286804 993.330139,90.455956 996.191406,93.739967 
	C999.009644,96.974670 1001.756042,100.283783 1004.349121,103.700523 
	C1010.420105,111.700134 1019.574585,114.555977 1027.889648,110.607758 
	C1024.649902,106.948425 1021.417175,103.426117 1018.333679,99.777618 
	C1015.291382,96.177963 1012.400330,92.450577 1009.800598,89.223305 
	C1014.450867,86.062752 1019.617310,84.009033 1022.622681,80.234604 
	C1031.527832,69.050659 1025.159424,52.898792 1010.169067,50.319637 
	C997.863342,48.202385 985.112000,48.675213 971.575928,48.000023 
M473.937103,48.468925 
	C463.301422,48.313816 452.661194,48.293694 442.032471,47.933495 
	C438.086212,47.799759 436.885345,49.138573 436.927612,53.047768 
	C437.105835,69.526878 436.890015,86.010063 437.040100,102.489731 
	C437.135956,113.018173 434.736084,111.869621 447.053375,112.033165 
	C450.171814,112.074577 451.113007,110.780327 451.027679,107.867668 
	C450.891205,103.210632 450.888977,98.541191 451.091217,93.888344 
	C451.145538,92.638298 451.827515,90.760323 452.764618,90.315727 
	C457.139771,88.240059 467.287476,90.492401 470.605438,94.167175 
	C473.257568,97.104477 475.553741,100.361313 478.173737,103.329979 
	C480.770599,106.272522 483.122559,110.518311 486.396851,111.514854 
	C491.024689,112.923347 496.401276,111.871750 502.770782,111.871750 
	C495.726074,103.434471 489.592255,96.088120 483.230927,88.469276 
	C485.932831,87.291199 487.867065,86.381912 489.849579,85.593964 
	C504.527679,79.760162 502.676544,57.809170 491.373962,53.087860 
	C486.159363,50.909618 480.337494,50.185226 473.937103,48.468925 
M982.887268,154.729553 
	C980.295593,152.169174 977.996338,149.196075 975.063293,147.117386 
	C959.482666,136.075439 934.109741,136.369720 919.077576,149.235138 
	C903.407959,162.646301 904.852295,184.135605 919.291504,195.334366 
	C935.238525,207.702576 953.213806,208.463501 971.125061,199.315628 
	C988.096680,190.647690 993.483093,170.074982 982.887268,154.729553 
M1224.865967,71.024200 
	C1221.869629,66.515465 1219.778687,60.831932 1215.718872,57.703003 
	C1198.890259,44.733334 1180.543213,43.731468 1161.870361,53.653191 
	C1141.692749,64.374512 1140.402710,92.278946 1159.241821,105.104591 
	C1172.732422,114.288940 1187.446289,115.244011 1202.462891,110.575081 
	C1220.298340,105.029778 1229.274902,88.944870 1224.865967,71.024200 
M699.194641,168.145859 
	C693.079224,166.749161 686.901184,165.576660 680.866943,163.889679 
	C675.359802,162.350082 668.952576,162.546631 664.878601,156.771637 
	C670.497314,150.451035 677.872375,149.305023 684.994751,150.423447 
	C691.839905,151.498276 698.307861,154.845673 705.044189,156.849869 
	C706.404053,157.254456 708.477661,156.478897 709.759399,155.584305 
	C711.033691,154.694885 711.743835,152.982895 712.667664,151.608948 
	C714.174866,149.367294 713.827271,147.921890 711.216003,146.574860 
	C695.078552,138.250198 678.526245,136.209686 661.497009,143.368317 
	C654.792603,146.186661 650.694275,151.552704 650.158569,158.953629 
	C649.775391,164.247223 655.332214,170.515778 662.057251,172.869232 
	C665.640381,174.123184 669.315430,175.131210 672.983643,176.121933 
	C680.942322,178.271454 688.968933,180.186829 696.856445,182.566864 
	C698.440613,183.044876 700.573975,185.166794 700.566650,186.520462 
	C700.558533,187.995163 698.567200,190.422577 697.067688,190.755524 
	C691.195862,192.059204 684.989746,194.057266 679.256714,193.266861 
	C670.776184,192.097687 662.592773,188.773239 653.613464,186.172577 
	C652.206177,188.122726 650.108276,191.029892 647.687439,194.384491 
	C649.578491,195.702438 651.006958,197.273483 652.755005,197.817337 
	C660.604431,200.259460 668.419006,203.220825 676.482422,204.469742 
	C687.805176,206.223511 698.965637,204.833282 708.837158,198.068466 
	C717.343567,192.239151 718.593506,181.567368 711.016174,174.744614 
	C707.931946,171.967575 703.657043,170.513000 699.194641,168.145859 
M921.608643,76.000099 
	C918.584412,76.653114 913.786316,73.833160 913.932434,80.465782 
	C914.075806,86.971756 913.808411,86.979355 920.536255,86.998619 
	C922.868286,87.005287 925.242004,86.732613 927.519165,87.086327 
	C929.367004,87.373329 932.620056,88.398293 932.624023,89.110367 
	C932.642761,92.543640 935.215210,97.435188 929.876160,98.900925 
	C924.228638,100.451347 918.329163,101.669510 912.502258,101.854462 
	C901.540283,102.202385 891.413513,93.335739 890.187805,82.416786 
	C889.230164,73.885765 894.043884,64.076523 905.438965,59.995777 
	C915.200989,56.499821 924.238586,57.807762 932.957458,63.238064 
	C934.355652,64.108871 937.183716,64.646118 938.144165,63.861172 
	C940.348511,62.059605 941.808411,59.347164 943.589172,56.979698 
	C942.801819,56.227100 942.354126,55.756775 941.861328,55.339886 
	C941.356079,54.912441 940.842346,54.474998 940.271423,54.148430 
	C926.688538,46.379456 912.387268,45.115051 897.745300,50.276588 
	C885.070007,54.744793 876.507385,63.391541 875.148560,77.249550 
	C873.858521,90.407158 880.047729,100.232224 890.866089,107.270744 
	C904.295715,116.008240 932.265442,114.649063 944.794373,104.636871 
	C945.889648,103.761627 946.844421,102.011238 946.887451,100.636696 
	C947.101379,93.813934 946.855835,86.977264 947.044922,80.153046 
	C947.139221,76.748955 945.660217,75.892746 942.567322,75.961540 
	C935.907776,76.109665 929.242615,76.002625 921.608643,76.000099 
M1074.000000,172.499023 
	C1074.000366,176.996155 1073.977417,181.493439 1074.005981,185.990387 
	C1074.075317,196.908813 1080.581787,203.762177 1091.522949,203.943375 
	C1103.845093,204.147430 1116.177368,203.806366 1128.496338,204.088058 
	C1133.160278,204.194702 1132.995972,201.648132 1133.050049,198.428925 
	C1133.106934,195.040298 1132.831665,192.768265 1128.351562,192.912079 
	C1118.867554,193.216568 1109.366699,193.004227 1099.872803,192.999191 
	C1089.932129,192.993927 1087.832275,190.629639 1087.927856,180.496033 
	C1087.960938,176.986832 1089.152100,175.886917 1092.577393,175.946609 
	C1102.234863,176.114883 1111.900635,175.853394 1121.556396,176.067856 
	C1125.790039,176.161880 1128.218140,175.798477 1128.206787,170.369553 
	C1128.195801,165.091614 1125.751587,164.867645 1121.796387,164.948807 
	C1113.471924,165.119629 1105.140747,165.067535 1096.813599,164.982483 
	C1087.039307,164.882645 1088.276367,166.398773 1087.922119,156.241623 
	C1087.774780,152.020462 1089.258667,150.836777 1093.342041,150.920837 
	C1104.663208,151.153915 1115.997192,150.808960 1127.316284,151.089157 
	C1131.746948,151.198822 1132.135010,149.037704 1132.049316,145.606628 
	C1131.969849,142.428238 1132.238159,139.841705 1127.533081,139.912491 
	C1111.213989,140.158020 1094.888062,140.079742 1078.566284,139.947357 
	C1075.127075,139.919464 1073.873413,141.015060 1073.944824,144.517868 
	C1074.128174,153.508347 1074.002319,162.505127 1074.000000,172.499023 
M633.359253,203.999634 
	C633.692139,203.999268 634.031067,203.957642 634.357056,204.004776 
	C638.572510,204.614120 640.220093,203.143036 640.154785,198.572144 
	C640.093079,194.258392 638.976440,192.744019 634.503357,192.903839 
	C625.358948,193.230545 616.194763,193.003006 607.038696,192.999268 
	C596.815674,192.995087 594.920959,190.894424 594.918091,180.747955 
	C594.916992,176.989929 596.159973,175.862442 599.824402,175.935257 
	C609.808838,176.133652 619.804932,175.812958 629.785889,176.084946 
	C634.030518,176.200623 635.102295,174.686264 635.129578,170.644485 
	C635.158020,166.424637 634.150330,164.710037 629.571838,164.900879 
	C621.096436,165.254166 612.594482,165.092361 604.105896,164.976318 
	C593.987549,164.838028 595.195496,166.785889 594.948730,155.541962 
	C594.872498,152.068634 596.057617,150.893616 599.536133,150.944626 
	C610.687439,151.108185 621.848206,150.795013 632.993713,151.095718 
	C637.543091,151.218460 638.052795,149.197723 638.108337,145.437103 
	C638.166748,141.479294 637.210022,139.845383 632.865906,139.911285 
	C617.387451,140.146057 601.900208,140.172928 586.423096,139.897324 
	C581.844360,139.815781 580.846069,141.443207 580.916992,145.655960 
	C581.143921,159.136490 580.988831,172.623291 581.004150,186.107635 
	C581.016968,197.388260 587.559692,203.939468 598.928711,203.988098 
	C610.082214,204.035812 621.236084,203.999405 633.359253,203.999634 
M1303.000000,153.512543 
	C1303.000366,164.665573 1302.972534,175.818695 1303.009766,186.971603 
	C1303.042603,196.843719 1309.483643,203.761566 1319.294922,203.933167 
	C1332.108643,204.157257 1344.932373,203.821548 1357.744995,204.075516 
	C1362.662720,204.173004 1361.875488,201.130783 1362.020752,198.186279 
	C1362.177979,194.995361 1361.832520,192.814957 1357.614136,192.926636 
	C1347.966064,193.182037 1338.306641,193.005676 1328.651733,192.998993 
	C1319.018921,192.992325 1317.075439,190.862091 1316.916016,181.362579 
	C1316.848022,177.315353 1317.960327,175.801758 1322.210327,175.914673 
	C1332.357422,176.184296 1342.520752,175.823334 1352.668823,176.072601 
	C1356.949097,176.177750 1357.123535,173.879700 1357.022339,170.759644 
	C1356.924927,167.755447 1357.573730,164.753952 1352.689331,164.922302 
	C1343.877319,165.226059 1335.046143,165.089035 1326.224976,164.977509 
	C1315.966064,164.847824 1317.239136,166.725250 1316.933350,155.662949 
	C1316.826294,151.787140 1318.357056,150.883331 1321.914795,150.939682 
	C1332.898560,151.113556 1343.891479,150.813309 1354.871094,151.088806 
	C1359.227661,151.198120 1360.162842,149.507614 1360.108398,145.557449 
	C1360.056519,141.789597 1359.528687,139.807251 1354.989380,139.904022 
	C1341.844849,140.184250 1328.690308,140.000763 1315.539795,140.000137 
	C1303.000000,139.999527 1303.000000,140.000000 1303.000000,153.512543 
M546.501648,84.000000 
	C552.333069,83.995941 558.172180,83.808388 563.993408,84.050232 
	C568.096252,84.220680 567.984680,81.790039 568.038574,78.931152 
	C568.095581,75.910400 567.800903,73.837921 563.854797,73.941292 
	C555.363403,74.163742 546.861023,74.084335 538.364929,73.979576 
	C527.728271,73.848412 529.297729,75.651390 528.925171,64.287079 
	C528.788635,60.122715 530.158691,58.828671 534.300171,58.918484 
	C545.457947,59.160458 556.627991,58.833336 567.786011,59.069637 
	C572.206482,59.163254 572.094055,56.613960 572.001770,53.635708 
	C571.916565,50.886276 572.716797,47.903332 568.058777,47.948940 
	C551.398987,48.112068 534.736450,48.058079 518.075562,47.969151 
	C515.097778,47.953259 513.944275,48.956451 513.966553,52.010269 
	C514.071106,66.338272 513.782288,80.672508 514.093079,94.994553 
	C514.324585,105.663368 521.274658,111.953087 532.037354,111.989372 
	C543.866760,112.029259 555.701538,111.798538 567.523499,112.099663 
	C572.031189,112.214470 573.193542,110.695663 573.131470,106.396057 
	C573.073792,102.402061 572.117615,100.798576 567.819519,100.913094 
	C557.496826,101.188110 547.161377,101.050667 536.831909,100.972954 
	C531.146729,100.930183 529.139893,98.813789 529.012329,93.072327 
	C528.971619,91.240654 529.091431,89.403412 529.002502,87.575294 
	C528.879944,85.054825 529.914795,83.935921 532.507812,83.995140 
	C536.837280,84.094002 541.170898,84.008110 546.501648,84.000000 
M1356.950562,81.243401 
	C1357.029541,74.009087 1357.029541,74.005562 1349.670654,74.001152 
	C1342.171631,73.996658 1334.670898,74.103455 1327.173950,73.976059 
	C1315.553345,73.778610 1317.289795,76.085503 1316.932861,63.592377 
	C1316.819092,59.610172 1318.532959,58.897835 1321.995361,58.945328 
	C1333.158325,59.098457 1344.330078,58.797512 1355.487671,59.088150 
	C1360.147583,59.209534 1359.999390,56.660709 1360.050659,53.432819 
	C1360.104370,50.044449 1359.829102,47.841904 1355.352539,47.910629 
	C1339.525024,48.153637 1323.690674,48.093983 1307.860840,47.936855 
	C1304.228027,47.900795 1302.882812,48.929573 1302.930786,52.726925 
	C1303.114014,67.222733 1302.914551,81.723000 1303.039795,96.220032 
	C1303.117310,105.201004 1309.879517,111.885780 1318.903320,111.965729 
	C1331.734009,112.079399 1344.570190,111.817398 1357.395996,112.089287 
	C1361.932861,112.185455 1362.070435,109.859924 1362.051270,106.523659 
	C1362.032349,103.244957 1362.046265,100.786987 1357.443481,100.911438 
	C1346.619629,101.204086 1335.782104,101.032967 1324.950439,100.978424 
	C1320.507568,100.956062 1317.499390,98.181854 1317.063843,93.789772 
	C1316.900269,92.138535 1317.163940,90.442574 1316.974365,88.796783 
	C1316.545410,85.074181 1318.068481,83.856468 1321.787842,83.933182 
	C1331.782227,84.139320 1341.785278,84.085922 1351.781006,83.913589 
	C1353.482422,83.884254 1355.164673,82.756172 1356.950562,81.243401 
M823.976807,156.799240 
	C821.562500,148.449142 815.611816,143.015869 807.501160,142.195831 
	C792.692932,140.698639 777.736816,140.624207 762.835510,140.189285 
	C761.932007,140.162918 760.162476,142.172394 760.153137,143.246338 
	C759.987610,162.398407 759.995972,181.552551 760.128113,200.705154 
	C760.135498,201.774673 761.350830,203.528580 762.321411,203.791412 
	C771.913757,206.389053 774.998535,203.964355 775.000061,194.139816 
	C775.000244,192.807388 775.124634,191.461456 774.979797,190.145081 
	C774.566040,186.383774 775.829590,184.655807 779.943604,184.928986 
	C784.752380,185.248306 789.639587,185.395187 794.418396,184.899475 
	C800.287292,184.290726 806.268066,183.538956 811.862183,181.775742 
	C821.288025,178.804764 826.825378,167.162415 823.976807,156.799240 
M519.985168,200.203156 
	C519.990112,197.704559 519.979553,195.205841 520.002808,192.707413 
	C520.082581,184.150940 519.236816,185.167053 527.871338,184.980194 
	C537.272461,184.776779 546.754333,186.041534 555.896606,182.204788 
	C564.207153,178.717072 570.568115,169.884872 569.779297,161.797241 
	C568.632996,150.043549 560.038696,141.702179 547.692566,141.070343 
	C535.258667,140.434006 522.792419,140.452499 510.350586,139.936371 
	C506.267212,139.766937 504.859924,141.016373 504.915863,145.235397 
	C505.130035,161.390244 505.000610,177.549652 505.000061,193.707352 
	C504.999695,204.516953 506.579315,205.884933 517.261292,203.842606 
	C518.293884,203.645187 519.062805,202.069031 519.985168,200.203156 
M364.501617,181.997314 
	C360.937866,188.985916 357.374146,195.974518 353.725708,203.129227 
	C366.000458,205.858551 368.024628,204.937637 373.037476,194.900650 
	C373.260101,194.454865 373.590515,194.043900 373.722748,193.574966 
	C374.826477,189.660995 377.501953,188.722763 381.318787,188.928802 
	C386.633636,189.215683 392.075531,189.653809 397.284027,188.856949 
	C406.641083,187.425415 412.333282,190.717224 415.048737,199.785782 
	C415.528931,201.389465 417.374603,203.568085 418.787170,203.727097 
	C423.293060,204.234314 427.891968,203.915146 433.161987,203.915146 
	C432.021179,201.366531 431.369446,199.698914 430.552094,198.116867 
	C421.344147,180.294571 412.093170,162.494476 402.902802,144.663162 
	C401.501129,141.943634 400.360382,139.361633 396.404266,139.942062 
	C391.806305,140.616653 386.627655,138.250687 383.621826,144.561249 
	C377.705597,156.982010 371.160339,169.103134 364.501617,181.997314 
M800.125488,96.648094 
	C797.780212,101.492516 795.434998,106.336945 793.008362,111.349503 
	C806.346375,113.593239 806.760254,113.388779 812.353516,102.250893 
	C812.651062,101.658325 812.776550,100.823441 813.252380,100.503845 
	C815.119507,99.249962 817.029785,97.320610 819.042664,97.163643 
	C824.830627,96.712326 830.778564,97.689308 836.476257,96.843208 
	C845.643555,95.481865 851.222351,98.607346 853.892578,107.488846 
	C854.393860,109.156334 856.090454,111.527916 857.448792,111.680946 
	C862.086853,112.203445 866.820007,111.881065 872.597290,111.881065 
	C862.398804,92.065025 852.405151,73.570389 843.370789,54.618435 
	C839.965942,47.475895 834.808105,47.496052 828.887817,48.137405 
	C827.160645,48.324509 824.876526,49.677666 824.078613,51.159245 
	C816.071838,66.027084 808.321838,81.033241 800.125488,96.648094 
M421.960632,99.414925 
	C420.000610,95.464073 417.667908,94.093185 413.475555,97.102867 
	C406.968781,101.774063 399.373596,102.595589 391.578033,101.966293 
	C380.200867,101.047867 370.293701,91.047829 370.029266,80.255569 
	C369.792572,70.595062 378.864227,61.261887 389.600403,58.865211 
	C398.570007,56.862888 406.227875,59.398067 413.873474,63.411087 
	C415.114990,64.062714 417.497620,64.635071 418.109222,64.015839 
	C420.318787,61.778553 421.997467,59.016972 424.001007,56.283611 
	C406.239349,45.494339 388.840576,44.013966 370.994293,53.526852 
	C353.206451,63.008549 349.665771,86.986809 363.847260,101.346275 
	C378.333588,116.014404 407.136292,117.174255 424.657776,103.499863 
	C423.934357,102.341522 423.183075,101.138504 421.960632,99.414925 
M1110.504639,47.016998 
	C1101.823975,48.150993 1093.328125,49.459866 1085.490845,54.094551 
	C1066.063477,65.583237 1065.986694,94.553764 1085.413574,106.204582 
	C1097.345459,113.360489 1110.041748,114.653893 1123.191284,111.645836 
	C1129.298828,110.248695 1135.502686,108.156349 1140.046509,103.109558 
	C1139.396606,102.173912 1139.008423,101.644539 1138.650635,101.095284 
	C1134.348145,94.490097 1134.427490,94.643936 1127.416626,98.186783 
	C1117.194336,103.352562 1106.860596,104.035881 1096.553467,98.219292 
	C1086.105835,92.323296 1082.791504,78.958481 1089.366333,69.057549 
	C1096.706299,58.004459 1114.117310,54.789070 1126.688843,62.164974 
	C1133.032837,65.887070 1133.036011,65.889145 1137.069946,59.802330 
	C1137.695679,58.858265 1138.277222,57.884899 1139.157593,56.480984 
	C1137.403809,55.271778 1135.963989,53.687584 1134.210083,53.172375 
	C1126.655518,50.953064 1119.008789,49.047363 1110.504639,47.016998 
M1265.420898,193.999268 
	C1261.375000,193.315475 1257.144775,193.167679 1253.316406,191.851273 
	C1239.725586,187.177841 1234.879883,172.140991 1242.874634,160.312073 
	C1250.288086,149.343216 1268.344116,146.374710 1280.864868,154.709839 
	C1285.081055,157.516647 1287.077515,156.051712 1289.360474,153.006027 
	C1290.331177,151.711014 1291.054321,150.230469 1292.089722,148.500443 
	C1290.629150,147.428726 1289.422241,146.304672 1288.017334,145.548996 
	C1273.039551,137.493088 1257.739380,136.832260 1242.254272,143.942017 
	C1219.891235,154.209717 1219.384155,181.422668 1233.299683,193.995453 
	C1248.471191,207.703094 1273.874756,208.058014 1290.780640,197.468552 
	C1291.683350,196.903091 1292.587280,195.290298 1292.457764,194.300034 
	C1291.727783,188.715546 1286.719238,186.740311 1281.641602,189.016907 
	C1276.772827,191.199921 1271.453369,192.378067 1265.420898,193.999268 
M1011.501831,152.002991 
	C1010.000977,148.864090 1008.597290,145.672623 1006.944458,142.615875 
	C1006.394592,141.598770 1005.241577,140.261749 1004.302307,140.210693 
	C999.718811,139.961517 995.114502,140.097900 989.595154,140.097900 
	C990.739441,142.862823 991.417175,144.829224 992.338013,146.674362 
	C1000.484497,162.998215 1009.072388,179.118393 1016.655701,195.698547 
	C1019.700562,202.355804 1023.367554,204.956985 1030.547363,204.175064 
	C1034.079468,203.790390 1036.162598,203.055359 1037.771118,199.704575 
	C1046.593384,181.325912 1055.663208,163.066193 1064.610596,144.747330 
	C1065.221436,143.496689 1065.526123,142.096497 1066.230103,140.000778 
	C1062.783203,140.000778 1060.132080,140.212357 1057.528320,139.951630 
	C1053.530884,139.551361 1051.178955,140.907394 1049.506592,144.786850 
	C1045.380615,154.358536 1040.824707,163.746750 1036.355835,173.168045 
	C1034.072388,177.982208 1031.604370,182.708847 1028.732910,188.448044 
	C1022.722046,175.684570 1017.299011,164.169357 1011.501831,152.002991 
M284.022522,47.478973 
	C270.245972,54.339241 257.850250,64.178024 241.636215,66.903381 
	C243.358322,71.458321 244.754593,75.987267 246.744827,80.238136 
	C250.653687,88.586922 255.047440,90.313751 263.375427,86.090759 
	C278.277374,78.534218 292.958221,70.539742 307.675018,62.623322 
	C308.984039,61.919167 310.785889,60.474003 310.821869,59.324879 
	C311.088806,50.799660 310.966583,42.262257 310.966583,33.046436 
	C301.667419,38.006035 293.162537,42.542023 284.022522,47.478973 
M707.551392,59.000000 
	C710.216553,59.000183 712.881714,58.993839 715.546875,59.001854 
	C721.980103,59.021206 721.992065,59.023903 721.997253,65.627373 
	C722.008179,79.619385 722.067688,93.611885 721.960022,107.603058 
	C721.936035,110.727715 722.821838,112.354340 726.186584,112.012108 
	C727.505249,111.877991 728.870178,111.845558 730.178284,112.026001 
	C734.718140,112.652199 736.191284,110.871170 736.100342,106.236809 
	C735.822571,92.083702 736.110046,77.920258 735.932251,63.763924 
	C735.885132,60.016140 737.133240,58.763336 740.822510,58.940201 
	C745.974060,59.187164 751.151611,58.848400 756.309021,59.025158 
	C760.673035,59.174721 760.048096,56.189972 759.959351,53.616112 
	C759.875854,51.193977 761.040161,47.980782 756.539917,47.997246 
	C738.217407,48.064293 719.894287,47.987381 701.572327,48.107166 
	C700.420349,48.114697 698.577393,49.100616 698.242554,50.049892 
	C696.128784,56.043179 698.310852,58.970760 704.575867,58.999187 
	C705.242126,59.002209 705.908447,58.999844 707.551392,59.000000 
M1254.000000,90.460037 
	C1254.000000,79.816040 1254.138794,69.169746 1253.963257,58.528645 
	C1253.764648,46.499462 1256.519165,48.201073 1242.986450,47.970104 
	C1239.915527,47.917690 1238.957153,49.133331 1238.970093,52.085552 
	C1239.051880,70.712181 1239.055298,89.339546 1238.968384,107.966118 
	C1238.954346,110.978424 1240.032349,112.050583 1243.039062,112.031860 
	C1258.339233,111.936600 1273.641724,111.870888 1288.940186,112.060295 
	C1293.483398,112.116547 1293.004517,109.356438 1293.006348,106.497864 
	C1293.008179,103.635017 1293.479248,100.812302 1288.934814,100.941422 
	C1280.459839,101.182205 1271.972168,101.073723 1263.491211,100.982590 
	C1252.529907,100.864792 1254.189209,102.474182 1254.000000,90.460037 
M457.000000,164.500000 
	C457.000000,159.333405 457.067444,154.165726 456.985596,149.000427 
	C456.826935,138.986694 458.592621,140.222015 447.916840,139.944000 
	C444.343384,139.850937 442.893494,140.803665 442.929596,144.670502 
	C443.099335,162.835312 443.086487,181.003296 442.935852,199.168442 
	C442.905457,202.837051 443.996155,204.112823 447.757080,204.067993 
	C462.588104,203.891113 477.424927,203.844696 492.254059,204.088943 
	C496.605103,204.160614 497.055084,202.164276 497.079132,198.665985 
	C497.104279,195.014435 496.763031,192.755615 492.083282,192.903732 
	C481.927063,193.225220 471.751740,192.891541 461.587860,193.052826 
	C458.166138,193.107132 456.852631,192.027908 456.944702,188.498016 
	C457.144501,180.836746 457.002380,173.166550 457.000000,164.500000 
M1057.000000,74.500000 
	C1057.000000,69.012367 1057.079956,63.523327 1056.983398,58.037395 
	C1056.781372,46.558235 1058.884644,48.307652 1046.996216,47.930576 
	C1043.004395,47.803967 1041.890503,49.217972 1041.930908,53.072567 
	C1042.101074,69.367439 1041.891479,85.666107 1042.037354,101.961449 
	C1042.140137,113.414589 1039.869141,111.755142 1052.502563,112.049835 
	C1056.016968,112.131813 1057.103760,110.847038 1057.052734,107.427246 
	C1056.893799,96.786949 1056.997925,86.142708 1057.000000,74.500000 
M671.000000,73.500000 
	C671.000000,83.477440 670.938171,93.455376 671.017822,103.432175 
	C671.093750,112.945297 669.745422,111.689209 679.477722,112.087311 
	C683.902893,112.268341 685.156372,110.827385 685.092957,106.454666 
	C684.856445,90.161606 685.108276,73.862061 684.961243,57.566898 
	C684.865479,46.947964 687.197205,48.135933 675.023804,47.969128 
	C671.998840,47.927673 670.907837,49.047432 670.967773,52.047173 
	C671.103943,58.862278 671.004028,65.682098 671.000000,73.500000 
M556.529358,33.940269 
	C556.243958,32.977501 556.055176,31.230387 555.657288,31.181387 
	C553.362427,30.898809 551.018127,30.999874 548.690674,31.004009 
	C542.803284,31.014469 537.439697,36.069260 536.553650,42.562424 
	C542.829773,43.645882 548.741150,43.825546 552.890686,37.666130 
	C553.711731,36.447422 554.968079,35.521996 556.529358,33.940269 
z"
      />
      <path
        fill="#008E92"
        opacity="1.000000"
        stroke="none"
        d="
M247.044434,105.983978 
	C268.184906,94.523994 289.325348,83.064003 310.989838,71.319931 
	C310.989838,81.491348 311.107666,91.101295 310.862915,100.701988 
	C310.826172,102.143326 309.415771,104.063377 308.095367,104.879951 
	C292.360474,114.610786 276.522186,124.174454 260.025513,133.946777 
	C248.679108,139.722137 238.016357,145.329208 227.369125,150.965637 
	C227.137253,151.088394 227.083267,151.547165 226.946289,151.849182 
	C217.427322,156.319550 207.919495,160.813766 198.387161,165.255447 
	C176.503815,175.452194 154.245621,184.860565 130.276382,188.663757 
	C108.270416,192.155457 86.336845,191.913467 65.905800,180.962357 
	C46.545155,170.584961 36.974072,154.285751 35.902859,132.397217 
	C35.121529,116.431885 42.218510,103.823090 51.534683,92.014656 
	C70.277176,68.258148 95.604637,55.004719 124.269279,47.512939 
	C129.762665,46.077194 135.747452,45.971836 141.477371,46.151337 
	C146.798416,46.318024 150.788956,49.716957 152.740158,54.598530 
	C155.276489,60.943859 157.199677,67.534271 159.276871,73.693382 
	C149.897614,75.535530 141.002640,76.804199 132.371338,79.075020 
	C112.978195,84.177177 95.739899,93.410843 81.138336,107.341438 
	C73.203697,114.911476 65.659187,122.774216 61.447659,133.143982 
	C60.098740,136.465332 58.740303,139.887695 58.164623,143.391724 
	C57.440449,147.799637 58.242298,152.326431 62.860195,154.322968 
	C68.803795,156.892670 74.980911,160.431793 81.156006,160.640762 
	C93.825195,161.069489 106.700119,160.496613 119.234367,158.623337 
	C137.209198,155.936935 154.931091,151.503189 171.584412,143.917969 
	C186.247864,137.239075 200.656616,130.001007 215.868637,122.841354 
	C226.721100,117.109863 236.882767,111.546921 247.044434,105.983978 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M113.000000,226.000000 
	C89.514580,225.999390 66.528214,225.891129 43.544563,226.092529 
	C39.153084,226.130997 37.545090,224.880035 37.929291,220.456451 
	C38.273884,216.488907 38.289280,212.441513 37.926319,208.477295 
	C37.511448,203.946167 39.330673,202.907043 43.610840,202.917496 
	C96.243813,203.046066 148.877197,203.000336 201.510483,203.000076 
	C229.992416,202.999939 258.475189,203.110077 286.955505,202.894684 
	C291.773468,202.858246 293.544128,204.190826 293.076508,209.044113 
	C292.694885,213.004898 292.757599,217.049973 293.062897,221.023911 
	C293.374756,225.083176 291.697510,226.087357 287.888947,226.064850 
	C264.904327,225.928909 241.918503,225.999496 218.933075,225.999863 
	C183.788712,226.000397 148.644363,226.000000 113.000000,226.000000 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M246.993759,105.617455 
	C236.882767,111.546921 226.721100,117.109863 216.226715,122.783081 
	C210.770706,109.530861 205.647415,96.168365 199.918945,81.227486 
	C196.173279,89.262230 193.111435,95.897423 189.992111,102.505493 
	C185.326050,112.390175 180.809784,122.352333 175.864151,132.095947 
	C172.505127,138.713715 165.448227,140.843491 159.246323,143.058456 
	C151.390015,145.864273 142.983047,147.158066 134.774796,148.923645 
	C133.123825,149.278763 131.299240,148.826752 128.946701,148.719040 
	C136.726578,134.035736 144.105133,120.010513 151.578964,106.036255 
	C157.354660,95.237129 163.288757,84.522781 169.077866,73.730751 
	C172.836365,66.724243 176.600143,59.713600 180.077286,52.566509 
	C181.479523,49.684280 183.394394,48.916550 186.375977,48.953861 
	C196.695923,49.083008 207.020340,49.111950 217.338928,48.937019 
	C220.423904,48.884724 222.137466,49.848907 223.405319,52.717022 
	C231.163391,70.267166 239.079575,87.747398 246.993759,105.617455 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1149.311279,203.969162 
	C1147.905396,202.724182 1146.150024,201.502487 1146.138550,200.264526 
	C1145.962891,181.448044 1146.046265,162.629303 1145.980591,143.811401 
	C1145.970947,141.055603 1146.968140,139.849899 1149.770508,139.987000 
	C1152.428589,140.117050 1155.138794,139.736069 1157.747314,140.117645 
	C1159.309814,140.346237 1161.094116,141.275543 1162.142700,142.455246 
	C1172.151611,153.714813 1182.028076,165.092102 1191.932617,176.444321 
	C1193.596436,178.351303 1195.212524,180.299850 1198.000000,181.804199 
	C1198.000000,179.717285 1197.999634,177.630371 1198.000000,175.543457 
	C1198.001953,165.218445 1198.110107,154.891739 1197.946533,144.569351 
	C1197.891968,141.133347 1199.003540,139.871384 1202.511597,139.948120 
	C1213.801758,140.195084 1211.897705,138.996429 1211.968384,149.081726 
	C1212.084106,165.567627 1211.841431,182.057419 1212.100708,198.540054 
	C1212.171875,203.064896 1210.586182,203.863144 1206.395142,204.176773 
	C1199.760620,204.673294 1195.795654,201.832474 1191.810791,196.916428 
	C1182.239258,185.108490 1172.063110,173.790634 1161.000000,162.576340 
	C1161.000000,168.606964 1161.000488,174.637573 1160.999756,180.668198 
	C1160.999023,186.829895 1160.825073,192.998199 1161.061035,199.150803 
	C1161.200928,202.801025 1160.056519,204.430069 1156.263550,204.035019 
	C1154.121582,203.811920 1151.937744,203.988388 1149.311279,203.969162 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M899.917969,165.376236 
	C897.489746,172.697525 897.489746,172.697525 883.465088,180.692917 
	C889.669434,188.023987 895.830444,195.303925 902.121704,202.737732 
	C892.496460,206.323776 885.535461,204.126785 879.783447,196.676910 
	C876.142456,191.961258 872.673035,186.425110 867.776184,183.586899 
	C863.922852,181.353470 858.004700,182.613052 853.002502,182.591095 
	C852.677185,182.589676 852.088379,184.894821 852.056091,186.138306 
	C851.935059,190.800919 851.907776,195.470261 852.018982,200.132935 
	C852.083740,202.845749 851.108398,203.883728 848.285583,204.048065 
	C836.994812,204.705307 836.999878,204.797516 837.000000,193.700867 
	C837.000183,177.704651 837.173279,161.705414 836.895081,145.714050 
	C836.812988,140.992615 838.407227,139.727997 842.875916,139.927444 
	C855.153015,140.475433 867.504395,140.076721 879.705078,141.281525 
	C884.961121,141.800537 890.335510,144.708496 894.908264,147.681473 
	C901.074768,151.690613 900.154724,158.616241 899.917969,165.376236 
M852.004639,155.734482 
	C852.013367,159.714996 851.779480,163.720032 852.177612,167.661194 
	C852.298035,168.853760 854.126465,170.765991 855.244324,170.818405 
	C861.698364,171.121048 868.184448,171.195404 874.639099,170.898575 
	C880.807251,170.614899 884.798889,166.808563 884.988098,161.748795 
	C885.199219,156.103363 882.380615,152.861313 875.511963,152.088852 
	C868.970215,151.353134 862.344788,151.378662 855.760071,150.999741 
	C852.972595,150.839325 851.765747,152.055222 852.004639,155.734482 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M626.233032,111.403091 
	C614.351440,111.715164 602.875427,111.932442 591.402222,111.832481 
	C590.271301,111.822632 588.190125,109.879112 588.179016,108.816132 
	C587.979736,89.668083 587.986572,70.517067 588.144104,51.368294 
	C588.153259,50.258430 589.824585,48.199680 590.716797,48.202747 
	C606.249207,48.256115 621.918213,46.566780 637.064636,51.883083 
	C656.852905,58.828621 664.316345,82.514099 651.338501,98.880066 
	C645.217590,106.598991 636.403320,110.161995 626.233032,111.403091 
M603.000061,80.499924 
	C603.004089,85.991364 602.879028,91.488144 603.105530,96.970398 
	C603.160278,98.296700 604.350220,100.690315 605.062134,100.706902 
	C614.601318,100.929100 624.421814,102.586578 633.138000,96.902855 
	C639.239075,92.924446 643.074524,87.108086 642.710388,79.931274 
	C642.339111,72.614288 639.101746,66.357262 631.794434,62.867775 
	C623.380859,58.850002 614.500671,58.785885 605.562988,59.245625 
	C604.650330,59.292568 603.198425,61.674694 603.128174,63.031818 
	C602.844360,68.508751 603.004089,74.008675 603.000061,80.499924 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M972.067017,48.000011 
	C985.112000,48.675213 997.863342,48.202385 1010.169067,50.319637 
	C1025.159424,52.898792 1031.527832,69.050659 1022.622681,80.234604 
	C1019.617310,84.009033 1014.450867,86.062752 1009.800598,89.223305 
	C1012.400330,92.450577 1015.291382,96.177963 1018.333679,99.777618 
	C1021.417175,103.426117 1024.649902,106.948425 1027.889648,110.607758 
	C1019.574585,114.555977 1010.420105,111.700134 1004.349121,103.700523 
	C1001.756042,100.283783 999.009644,96.974670 996.191406,93.739967 
	C993.330139,90.455956 982.692139,88.286804 978.683594,90.340462 
	C977.751221,90.818130 977.139404,92.706528 977.086548,93.975266 
	C976.892578,98.627075 976.878662,103.295143 977.030273,107.949493 
	C977.128113,110.953194 976.001648,112.072777 972.973877,112.030975 
	C960.802429,111.862976 963.142029,113.056938 963.038147,102.417061 
	C962.888794,87.108429 962.953186,71.797050 963.017090,56.487232 
	C963.057190,46.883762 961.911560,48.137337 972.067017,48.000011 
M980.800903,78.985237 
	C985.789246,78.990128 990.779724,79.082977 995.765137,78.963997 
	C998.383179,78.901520 1001.077454,78.791306 1003.587097,78.131454 
	C1008.086670,76.948418 1011.193909,74.454323 1011.035889,69.097923 
	C1010.877014,63.712326 1007.306213,61.456272 1003.024902,60.292225 
	C999.563843,59.351242 995.839905,59.113327 992.224121,59.050625 
	C975.815979,58.766083 975.814514,58.828232 977.019165,75.291092 
	C977.067566,75.952568 976.890259,76.853264 977.249634,77.222153 
	C977.963196,77.954666 978.978027,78.393776 980.800903,78.985237 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M474.364014,48.635288 
	C480.337494,50.185226 486.159363,50.909618 491.373962,53.087860 
	C502.676544,57.809170 504.527679,79.760162 489.849579,85.593964 
	C487.867065,86.381912 485.932831,87.291199 483.230927,88.469276 
	C489.592255,96.088120 495.726074,103.434471 502.770782,111.871750 
	C496.401276,111.871750 491.024689,112.923347 486.396851,111.514854 
	C483.122559,110.518311 480.770599,106.272522 478.173737,103.329979 
	C475.553741,100.361313 473.257568,97.104477 470.605438,94.167175 
	C467.287476,90.492401 457.139771,88.240059 452.764618,90.315727 
	C451.827515,90.760323 451.145538,92.638298 451.091217,93.888344 
	C450.888977,98.541191 450.891205,103.210632 451.027679,107.867668 
	C451.113007,110.780327 450.171814,112.074577 447.053375,112.033165 
	C434.736084,111.869621 437.135956,113.018173 437.040100,102.489731 
	C436.890015,86.010063 437.105835,69.526878 436.927612,53.047768 
	C436.885345,49.138573 438.086212,47.799759 442.032471,47.933495 
	C452.661194,48.293694 463.301422,48.313816 474.364014,48.635288 
M451.390625,77.941315 
	C460.072327,78.043037 468.829254,78.880234 477.410706,78.002182 
	C483.711639,77.357475 485.456665,73.810486 484.979034,67.803902 
	C484.559387,62.526581 480.296631,60.798244 476.370117,60.261993 
	C469.167969,59.278378 461.816956,59.398373 454.530457,59.011963 
	C451.958984,58.875591 450.934204,60.016228 450.998383,62.558296 
	C451.120361,67.387779 451.098572,72.220894 451.390625,77.941315 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M983.025391,155.068756 
	C993.483093,170.074982 988.096680,190.647690 971.125061,199.315628 
	C953.213806,208.463501 935.238525,207.702576 919.291504,195.334366 
	C904.852295,184.135605 903.407959,162.646301 919.077576,149.235138 
	C934.109741,136.369720 959.482666,136.075439 975.063293,147.117386 
	C977.996338,149.196075 980.295593,152.169174 983.025391,155.068756 
M968.545105,159.055069 
	C964.446350,153.688446 958.595825,151.335022 952.284241,150.321671 
	C942.973816,148.826843 934.496704,151.566833 928.423096,158.587051 
	C922.003113,166.007889 921.156738,174.614594 926.698242,183.378815 
	C930.427917,189.277588 935.959229,192.410797 942.469849,193.597748 
	C953.035889,195.524002 962.537354,192.969177 969.131592,184.114883 
	C975.010315,176.221313 974.586365,167.749557 968.545105,159.055069 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1224.923096,71.432083 
	C1229.274902,88.944870 1220.298340,105.029778 1202.462891,110.575081 
	C1187.446289,115.244011 1172.732422,114.288940 1159.241821,105.104591 
	C1140.402710,92.278946 1141.692749,64.374512 1161.870361,53.653191 
	C1180.543213,43.731468 1198.890259,44.733334 1215.718872,57.703003 
	C1219.778687,60.831932 1221.869629,66.515465 1224.923096,71.432083 
M1175.135376,60.416264 
	C1159.396851,66.288185 1157.756226,83.910576 1165.423218,92.884201 
	C1173.389893,102.208717 1187.592285,105.058563 1198.535645,99.658051 
	C1212.737305,92.649559 1215.605835,73.430267 1203.431641,64.158150 
	C1195.250366,57.926971 1185.828003,56.114441 1175.135376,60.416264 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M699.559265,168.301300 
	C703.657043,170.513000 707.931946,171.967575 711.016174,174.744614 
	C718.593506,181.567368 717.343567,192.239151 708.837158,198.068466 
	C698.965637,204.833282 687.805176,206.223511 676.482422,204.469742 
	C668.419006,203.220825 660.604431,200.259460 652.755005,197.817337 
	C651.006958,197.273483 649.578491,195.702438 647.687439,194.384491 
	C650.108276,191.029892 652.206177,188.122726 653.613464,186.172577 
	C662.592773,188.773239 670.776184,192.097687 679.256714,193.266861 
	C684.989746,194.057266 691.195862,192.059204 697.067688,190.755524 
	C698.567200,190.422577 700.558533,187.995163 700.566650,186.520462 
	C700.573975,185.166794 698.440613,183.044876 696.856445,182.566864 
	C688.968933,180.186829 680.942322,178.271454 672.983643,176.121933 
	C669.315430,175.131210 665.640381,174.123184 662.057251,172.869232 
	C655.332214,170.515778 649.775391,164.247223 650.158569,158.953629 
	C650.694275,151.552704 654.792603,146.186661 661.497009,143.368317 
	C678.526245,136.209686 695.078552,138.250198 711.216003,146.574860 
	C713.827271,147.921890 714.174866,149.367294 712.667664,151.608948 
	C711.743835,152.982895 711.033691,154.694885 709.759399,155.584305 
	C708.477661,156.478897 706.404053,157.254456 705.044189,156.849869 
	C698.307861,154.845673 691.839905,151.498276 684.994751,150.423447 
	C677.872375,149.305023 670.497314,150.451035 664.878601,156.771637 
	C668.952576,162.546631 675.359802,162.350082 680.866943,163.889679 
	C686.901184,165.576660 693.079224,166.749161 699.559265,168.301300 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M922.094177,76.000061 
	C929.242615,76.002625 935.907776,76.109665 942.567322,75.961540 
	C945.660217,75.892746 947.139221,76.748955 947.044922,80.153046 
	C946.855835,86.977264 947.101379,93.813934 946.887451,100.636696 
	C946.844421,102.011238 945.889648,103.761627 944.794373,104.636871 
	C932.265442,114.649063 904.295715,116.008240 890.866089,107.270744 
	C880.047729,100.232224 873.858521,90.407158 875.148560,77.249550 
	C876.507385,63.391541 885.070007,54.744793 897.745300,50.276588 
	C912.387268,45.115051 926.688538,46.379456 940.271423,54.148430 
	C940.842346,54.474998 941.356079,54.912441 941.861328,55.339886 
	C942.354126,55.756775 942.801819,56.227100 943.589172,56.979698 
	C941.808411,59.347164 940.348511,62.059605 938.144165,63.861172 
	C937.183716,64.646118 934.355652,64.108871 932.957458,63.238064 
	C924.238586,57.807762 915.200989,56.499821 905.438965,59.995777 
	C894.043884,64.076523 889.230164,73.885765 890.187805,82.416786 
	C891.413513,93.335739 901.540283,102.202385 912.502258,101.854462 
	C918.329163,101.669510 924.228638,100.451347 929.876160,98.900925 
	C935.215210,97.435188 932.642761,92.543640 932.624023,89.110367 
	C932.620056,88.398293 929.367004,87.373329 927.519165,87.086327 
	C925.242004,86.732613 922.868286,87.005287 920.536255,86.998619 
	C913.808411,86.979355 914.075806,86.971756 913.932434,80.465782 
	C913.786316,73.833160 918.584412,76.653114 922.094177,76.000061 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1074.000000,171.999207 
	C1074.002319,162.505127 1074.128174,153.508347 1073.944824,144.517868 
	C1073.873413,141.015060 1075.127075,139.919464 1078.566284,139.947357 
	C1094.888062,140.079742 1111.213989,140.158020 1127.533081,139.912491 
	C1132.238159,139.841705 1131.969849,142.428238 1132.049316,145.606628 
	C1132.135010,149.037704 1131.746948,151.198822 1127.316284,151.089157 
	C1115.997192,150.808960 1104.663208,151.153915 1093.342041,150.920837 
	C1089.258667,150.836777 1087.774780,152.020462 1087.922119,156.241623 
	C1088.276367,166.398773 1087.039307,164.882645 1096.813599,164.982483 
	C1105.140747,165.067535 1113.471924,165.119629 1121.796387,164.948807 
	C1125.751587,164.867645 1128.195801,165.091614 1128.206787,170.369553 
	C1128.218140,175.798477 1125.790039,176.161880 1121.556396,176.067856 
	C1111.900635,175.853394 1102.234863,176.114883 1092.577393,175.946609 
	C1089.152100,175.886917 1087.960938,176.986832 1087.927856,180.496033 
	C1087.832275,190.629639 1089.932129,192.993927 1099.872803,192.999191 
	C1109.366699,193.004227 1118.867554,193.216568 1128.351562,192.912079 
	C1132.831665,192.768265 1133.106934,195.040298 1133.050049,198.428925 
	C1132.995972,201.648132 1133.160278,204.194702 1128.496338,204.088058 
	C1116.177368,203.806366 1103.845093,204.147430 1091.522949,203.943375 
	C1080.581787,203.762177 1074.075317,196.908813 1074.005981,185.990387 
	C1073.977417,181.493439 1074.000366,176.996155 1074.000000,171.999207 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M632.874512,203.999786 
	C621.236084,203.999405 610.082214,204.035812 598.928711,203.988098 
	C587.559692,203.939468 581.016968,197.388260 581.004150,186.107635 
	C580.988831,172.623291 581.143921,159.136490 580.916992,145.655960 
	C580.846069,141.443207 581.844360,139.815781 586.423096,139.897324 
	C601.900208,140.172928 617.387451,140.146057 632.865906,139.911285 
	C637.210022,139.845383 638.166748,141.479294 638.108337,145.437103 
	C638.052795,149.197723 637.543091,151.218460 632.993713,151.095718 
	C621.848206,150.795013 610.687439,151.108185 599.536133,150.944626 
	C596.057617,150.893616 594.872498,152.068634 594.948730,155.541962 
	C595.195496,166.785889 593.987549,164.838028 604.105896,164.976318 
	C612.594482,165.092361 621.096436,165.254166 629.571838,164.900879 
	C634.150330,164.710037 635.158020,166.424637 635.129578,170.644485 
	C635.102295,174.686264 634.030518,176.200623 629.785889,176.084946 
	C619.804932,175.812958 609.808838,176.133652 599.824402,175.935257 
	C596.159973,175.862442 594.916992,176.989929 594.918091,180.747955 
	C594.920959,190.894424 596.815674,192.995087 607.038696,192.999268 
	C616.194763,193.003006 625.358948,193.230545 634.503357,192.903839 
	C638.976440,192.744019 640.093079,194.258392 640.154785,198.572144 
	C640.220093,203.143036 638.572510,204.614120 634.357056,204.004776 
	C634.031067,203.957642 633.692139,203.999268 632.874512,203.999786 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1303.000000,153.015442 
	C1303.000000,140.000000 1303.000000,139.999527 1315.539795,140.000137 
	C1328.690308,140.000763 1341.844849,140.184250 1354.989380,139.904022 
	C1359.528687,139.807251 1360.056519,141.789597 1360.108398,145.557449 
	C1360.162842,149.507614 1359.227661,151.198120 1354.871094,151.088806 
	C1343.891479,150.813309 1332.898560,151.113556 1321.914795,150.939682 
	C1318.357056,150.883331 1316.826294,151.787140 1316.933350,155.662949 
	C1317.239136,166.725250 1315.966064,164.847824 1326.224976,164.977509 
	C1335.046143,165.089035 1343.877319,165.226059 1352.689331,164.922302 
	C1357.573730,164.753952 1356.924927,167.755447 1357.022339,170.759644 
	C1357.123535,173.879700 1356.949097,176.177750 1352.668823,176.072601 
	C1342.520752,175.823334 1332.357422,176.184296 1322.210327,175.914673 
	C1317.960327,175.801758 1316.848022,177.315353 1316.916016,181.362579 
	C1317.075439,190.862091 1319.018921,192.992325 1328.651733,192.998993 
	C1338.306641,193.005676 1347.966064,193.182037 1357.614136,192.926636 
	C1361.832520,192.814957 1362.177979,194.995361 1362.020752,198.186279 
	C1361.875488,201.130783 1362.662720,204.173004 1357.744995,204.075516 
	C1344.932373,203.821548 1332.108643,204.157257 1319.294922,203.933167 
	C1309.483643,203.761566 1303.042603,196.843719 1303.009766,186.971603 
	C1302.972534,175.818695 1303.000366,164.665573 1303.000000,153.015442 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M546.002197,84.000000 
	C541.170898,84.008110 536.837280,84.094002 532.507812,83.995140 
	C529.914795,83.935921 528.879944,85.054825 529.002502,87.575294 
	C529.091431,89.403412 528.971619,91.240654 529.012329,93.072327 
	C529.139893,98.813789 531.146729,100.930183 536.831909,100.972954 
	C547.161377,101.050667 557.496826,101.188110 567.819519,100.913094 
	C572.117615,100.798576 573.073792,102.402061 573.131470,106.396057 
	C573.193542,110.695663 572.031189,112.214470 567.523499,112.099663 
	C555.701538,111.798538 543.866760,112.029259 532.037354,111.989372 
	C521.274658,111.953087 514.324585,105.663368 514.093079,94.994553 
	C513.782288,80.672508 514.071106,66.338272 513.966553,52.010269 
	C513.944275,48.956451 515.097778,47.953259 518.075562,47.969151 
	C534.736450,48.058079 551.398987,48.112068 568.058777,47.948940 
	C572.716797,47.903332 571.916565,50.886276 572.001770,53.635708 
	C572.094055,56.613960 572.206482,59.163254 567.786011,59.069637 
	C556.627991,58.833336 545.457947,59.160458 534.300171,58.918484 
	C530.158691,58.828671 528.788635,60.122715 528.925171,64.287079 
	C529.297729,75.651390 527.728271,73.848412 538.364929,73.979576 
	C546.861023,74.084335 555.363403,74.163742 563.854797,73.941292 
	C567.800903,73.837921 568.095581,75.910400 568.038574,78.931152 
	C567.984680,81.790039 568.096252,84.220680 563.993408,84.050232 
	C558.172180,83.808388 552.333069,83.995941 546.002197,84.000000 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1356.903198,81.689545 
	C1355.164673,82.756172 1353.482422,83.884254 1351.781006,83.913589 
	C1341.785278,84.085922 1331.782227,84.139320 1321.787842,83.933182 
	C1318.068481,83.856468 1316.545410,85.074181 1316.974365,88.796783 
	C1317.163940,90.442574 1316.900269,92.138535 1317.063843,93.789772 
	C1317.499390,98.181854 1320.507568,100.956062 1324.950439,100.978424 
	C1335.782104,101.032967 1346.619629,101.204086 1357.443481,100.911438 
	C1362.046265,100.786987 1362.032349,103.244957 1362.051270,106.523659 
	C1362.070435,109.859924 1361.932861,112.185455 1357.395996,112.089287 
	C1344.570190,111.817398 1331.734009,112.079399 1318.903320,111.965729 
	C1309.879517,111.885780 1303.117310,105.201004 1303.039795,96.220032 
	C1302.914551,81.723000 1303.114014,67.222733 1302.930786,52.726925 
	C1302.882812,48.929573 1304.228027,47.900795 1307.860840,47.936855 
	C1323.690674,48.093983 1339.525024,48.153637 1355.352539,47.910629 
	C1359.829102,47.841904 1360.104370,50.044449 1360.050659,53.432819 
	C1359.999390,56.660709 1360.147583,59.209534 1355.487671,59.088150 
	C1344.330078,58.797512 1333.158325,59.098457 1321.995361,58.945328 
	C1318.532959,58.897835 1316.819092,59.610172 1316.932861,63.592377 
	C1317.289795,76.085503 1315.553345,73.778610 1327.173950,73.976059 
	C1334.670898,74.103455 1342.171631,73.996658 1349.670654,74.001152 
	C1357.029541,74.005562 1357.029541,74.009087 1356.903198,81.689545 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M824.020691,157.207428 
	C826.825378,167.162415 821.288025,178.804764 811.862183,181.775742 
	C806.268066,183.538956 800.287292,184.290726 794.418396,184.899475 
	C789.639587,185.395187 784.752380,185.248306 779.943604,184.928986 
	C775.829590,184.655807 774.566040,186.383774 774.979797,190.145081 
	C775.124634,191.461456 775.000244,192.807388 775.000061,194.139816 
	C774.998535,203.964355 771.913757,206.389053 762.321411,203.791412 
	C761.350830,203.528580 760.135498,201.774673 760.128113,200.705154 
	C759.995972,181.552551 759.987610,162.398407 760.153137,143.246338 
	C760.162476,142.172394 761.932007,140.162918 762.835510,140.189285 
	C777.736816,140.624207 792.692932,140.698639 807.501160,142.195831 
	C815.611816,143.015869 821.562500,148.449142 824.020691,157.207428 
M803.296875,172.128342 
	C807.686768,169.858856 810.078125,166.157059 809.511597,161.328293 
	C808.910706,156.205765 805.477539,152.743576 800.419739,152.193649 
	C793.039917,151.391235 785.575317,151.267715 778.142639,151.171585 
	C777.121887,151.158401 775.236511,152.921265 775.183716,153.936432 
	C774.890381,159.570877 774.861084,165.238876 775.191956,170.868912 
	C775.256287,171.963806 777.333191,173.853073 778.446411,173.827667 
	C786.526367,173.643402 794.597168,173.062164 803.296875,172.128342 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M519.969666,200.668030 
	C519.062805,202.069031 518.293884,203.645187 517.261292,203.842606 
	C506.579315,205.884933 504.999695,204.516953 505.000061,193.707352 
	C505.000610,177.549652 505.130035,161.390244 504.915863,145.235397 
	C504.859924,141.016373 506.267212,139.766937 510.350586,139.936371 
	C522.792419,140.452499 535.258667,140.434006 547.692566,141.070343 
	C560.038696,141.702179 568.632996,150.043549 569.779297,161.797241 
	C570.568115,169.884872 564.207153,178.717072 555.896606,182.204788 
	C546.754333,186.041534 537.272461,184.776779 527.871338,184.980194 
	C519.236816,185.167053 520.082581,184.150940 520.002808,192.707413 
	C519.979553,195.205841 519.990112,197.704559 519.969666,200.668030 
M553.148499,167.504547 
	C557.567078,161.089462 552.076721,152.566772 544.545166,152.040176 
	C537.464111,151.545120 530.362183,151.213715 523.268005,151.184967 
	C522.193359,151.180618 520.247620,153.158493 520.178528,154.308090 
	C519.850342,159.767944 519.911194,165.263474 520.136047,170.733841 
	C520.180603,171.817383 521.533447,173.743790 522.315430,173.760468 
	C529.437805,173.912445 536.633545,174.265884 543.665161,173.400436 
	C546.901611,173.002060 549.820435,170.023270 553.148499,167.504547 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M364.688446,181.672760 
	C371.160339,169.103134 377.705597,156.982010 383.621826,144.561249 
	C386.627655,138.250687 391.806305,140.616653 396.404266,139.942062 
	C400.360382,139.361633 401.501129,141.943634 402.902802,144.663162 
	C412.093170,162.494476 421.344147,180.294571 430.552094,198.116867 
	C431.369446,199.698914 432.021179,201.366531 433.161987,203.915146 
	C427.891968,203.915146 423.293060,204.234314 418.787170,203.727097 
	C417.374603,203.568085 415.528931,201.389465 415.048737,199.785782 
	C412.333282,190.717224 406.641083,187.425415 397.284027,188.856949 
	C392.075531,189.653809 386.633636,189.215683 381.318787,188.928802 
	C377.501953,188.722763 374.826477,189.660995 373.722748,193.574966 
	C373.590515,194.043900 373.260101,194.454865 373.037476,194.900650 
	C368.024628,204.937637 366.000458,205.858551 353.725708,203.129227 
	C357.374146,195.974518 360.937866,188.985916 364.688446,181.672760 
M402.084137,174.234863 
	C399.012360,168.124557 395.940582,162.014267 392.347015,154.866013 
	C388.586792,163.073654 385.341736,170.156845 381.756989,177.981461 
	C388.818726,177.981461 394.789856,177.958771 400.760651,177.991837 
	C403.406769,178.006485 403.465179,176.762146 402.084137,174.234863 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M800.312500,96.324287 
	C808.321838,81.033241 816.071838,66.027084 824.078613,51.159245 
	C824.876526,49.677666 827.160645,48.324509 828.887817,48.137405 
	C834.808105,47.496052 839.965942,47.475895 843.370789,54.618435 
	C852.405151,73.570389 862.398804,92.065025 872.597290,111.881065 
	C866.820007,111.881065 862.086853,112.203445 857.448792,111.680946 
	C856.090454,111.527916 854.393860,109.156334 853.892578,107.488846 
	C851.222351,98.607346 845.643555,95.481865 836.476257,96.843208 
	C830.778564,97.689308 824.830627,96.712326 819.042664,97.163643 
	C817.029785,97.320610 815.119507,99.249962 813.252380,100.503845 
	C812.776550,100.823441 812.651062,101.658325 812.353516,102.250893 
	C806.760254,113.388779 806.346375,113.593239 793.008362,111.349503 
	C795.434998,106.336945 797.780212,101.492516 800.312500,96.324287 
M830.715698,65.792229 
	C827.474060,72.206055 824.232483,78.619881 820.732239,85.545418 
	C828.449036,85.545418 835.212280,85.545418 842.733215,85.545418 
	C839.170105,78.413231 835.953552,71.937180 832.655762,65.502747 
	C832.513977,65.226097 831.622314,65.333740 830.715698,65.792229 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M227.238281,152.067032 
	C227.083267,151.547165 227.137253,151.088394 227.369125,150.965637 
	C238.016357,145.329208 248.679108,139.722137 259.717590,134.112061 
	C263.956329,142.511459 267.764954,150.935104 271.692780,159.302826 
	C276.032837,168.548843 280.482056,177.743576 285.380066,187.999908 
	C279.194336,187.999908 273.962830,188.000153 268.731323,187.999832 
	C262.409821,187.999451 256.085052,187.874573 249.767853,188.038849 
	C245.065811,188.161118 241.818497,186.254959 240.005569,181.973557 
	C235.819946,172.088852 231.685471,162.182465 227.238281,152.067032 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M422.196198,99.675201 
	C423.183075,101.138504 423.934357,102.341522 424.657776,103.499863 
	C407.136292,117.174255 378.333588,116.014404 363.847260,101.346275 
	C349.665771,86.986809 353.206451,63.008549 370.994293,53.526852 
	C388.840576,44.013966 406.239349,45.494339 424.001007,56.283611 
	C421.997467,59.016972 420.318787,61.778553 418.109222,64.015839 
	C417.497620,64.635071 415.114990,64.062714 413.873474,63.411087 
	C406.227875,59.398067 398.570007,56.862888 389.600403,58.865211 
	C378.864227,61.261887 369.792572,70.595062 370.029266,80.255569 
	C370.293701,91.047829 380.200867,101.047867 391.578033,101.966293 
	C399.373596,102.595589 406.968781,101.774063 413.475555,97.102867 
	C417.667908,94.093185 420.000610,95.464073 422.196198,99.675201 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1110.950195,47.022240 
	C1119.008789,49.047363 1126.655518,50.953064 1134.210083,53.172375 
	C1135.963989,53.687584 1137.403809,55.271778 1139.157593,56.480984 
	C1138.277222,57.884899 1137.695679,58.858265 1137.069946,59.802330 
	C1133.036011,65.889145 1133.032837,65.887070 1126.688843,62.164974 
	C1114.117310,54.789070 1096.706299,58.004459 1089.366333,69.057549 
	C1082.791504,78.958481 1086.105835,92.323296 1096.553467,98.219292 
	C1106.860596,104.035881 1117.194336,103.352562 1127.416626,98.186783 
	C1134.427490,94.643936 1134.348145,94.490097 1138.650635,101.095284 
	C1139.008423,101.644539 1139.396606,102.173912 1140.046509,103.109558 
	C1135.502686,108.156349 1129.298828,110.248695 1123.191284,111.645836 
	C1110.041748,114.653893 1097.345459,113.360489 1085.413574,106.204582 
	C1065.986694,94.553764 1066.063477,65.583237 1085.490845,54.094551 
	C1093.328125,49.459866 1101.823975,48.150993 1110.950195,47.022240 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1265.876465,193.998535 
	C1271.453369,192.378067 1276.772827,191.199921 1281.641602,189.016907 
	C1286.719238,186.740311 1291.727783,188.715546 1292.457764,194.300034 
	C1292.587280,195.290298 1291.683350,196.903091 1290.780640,197.468552 
	C1273.874756,208.058014 1248.471191,207.703094 1233.299683,193.995453 
	C1219.384155,181.422668 1219.891235,154.209717 1242.254272,143.942017 
	C1257.739380,136.832260 1273.039551,137.493088 1288.017334,145.548996 
	C1289.422241,146.304672 1290.629150,147.428726 1292.089722,148.500443 
	C1291.054321,150.230469 1290.331177,151.711014 1289.360474,153.006027 
	C1287.077515,156.051712 1285.081055,157.516647 1280.864868,154.709839 
	C1268.344116,146.374710 1250.288086,149.343216 1242.874634,160.312073 
	C1234.879883,172.140991 1239.725586,187.177841 1253.316406,191.851273 
	C1257.144775,193.167679 1261.375000,193.315475 1265.876465,193.998535 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1011.688904,152.328568 
	C1017.299011,164.169357 1022.722046,175.684570 1028.732910,188.448044 
	C1031.604370,182.708847 1034.072388,177.982208 1036.355835,173.168045 
	C1040.824707,163.746750 1045.380615,154.358536 1049.506592,144.786850 
	C1051.178955,140.907394 1053.530884,139.551361 1057.528320,139.951630 
	C1060.132080,140.212357 1062.783203,140.000778 1066.230103,140.000778 
	C1065.526123,142.096497 1065.221436,143.496689 1064.610596,144.747330 
	C1055.663208,163.066193 1046.593384,181.325912 1037.771118,199.704575 
	C1036.162598,203.055359 1034.079468,203.790390 1030.547363,204.175064 
	C1023.367554,204.956985 1019.700562,202.355804 1016.655701,195.698547 
	C1009.072388,179.118393 1000.484497,162.998215 992.338013,146.674362 
	C991.417175,144.829224 990.739441,142.862823 989.595154,140.097900 
	C995.114502,140.097900 999.718811,139.961517 1004.302307,140.210693 
	C1005.241577,140.261749 1006.394592,141.598770 1006.944458,142.615875 
	C1008.597290,145.672623 1010.000977,148.864090 1011.688904,152.328568 
z"
      />
      <path
        fill="#E31720"
        opacity="1.000000"
        stroke="none"
        d="
M284.340088,47.278492 
	C293.162537,42.542023 301.667419,38.006035 310.966583,33.046436 
	C310.966583,42.262257 311.088806,50.799660 310.821869,59.324879 
	C310.785889,60.474003 308.984039,61.919167 307.675018,62.623322 
	C292.958221,70.539742 278.277374,78.534218 263.375427,86.090759 
	C255.047440,90.313751 250.653687,88.586922 246.744827,80.238136 
	C244.754593,75.987267 243.358322,71.458321 241.636215,66.903381 
	C257.850250,64.178024 270.245972,54.339241 284.340088,47.278492 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M707.063049,58.999989 
	C705.908447,58.999844 705.242126,59.002209 704.575867,58.999187 
	C698.310852,58.970760 696.128784,56.043179 698.242554,50.049892 
	C698.577393,49.100616 700.420349,48.114697 701.572327,48.107166 
	C719.894287,47.987381 738.217407,48.064293 756.539917,47.997246 
	C761.040161,47.980782 759.875854,51.193977 759.959351,53.616112 
	C760.048096,56.189972 760.673035,59.174721 756.309021,59.025158 
	C751.151611,58.848400 745.974060,59.187164 740.822510,58.940201 
	C737.133240,58.763336 735.885132,60.016140 735.932251,63.763924 
	C736.110046,77.920258 735.822571,92.083702 736.100342,106.236809 
	C736.191284,110.871170 734.718140,112.652199 730.178284,112.026001 
	C728.870178,111.845558 727.505249,111.877991 726.186584,112.012108 
	C722.821838,112.354340 721.936035,110.727715 721.960022,107.603058 
	C722.067688,93.611885 722.008179,79.619385 721.997253,65.627373 
	C721.992065,59.023903 721.980103,59.021206 715.546875,59.001854 
	C712.881714,58.993839 710.216553,59.000183 707.063049,58.999989 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1254.000000,90.950928 
	C1254.189209,102.474182 1252.529907,100.864792 1263.491211,100.982590 
	C1271.972168,101.073723 1280.459839,101.182205 1288.934814,100.941422 
	C1293.479248,100.812302 1293.008179,103.635017 1293.006348,106.497864 
	C1293.004517,109.356438 1293.483398,112.116547 1288.940186,112.060295 
	C1273.641724,111.870888 1258.339233,111.936600 1243.039062,112.031860 
	C1240.032349,112.050583 1238.954346,110.978424 1238.968384,107.966118 
	C1239.055298,89.339546 1239.051880,70.712181 1238.970093,52.085552 
	C1238.957153,49.133331 1239.915527,47.917690 1242.986450,47.970104 
	C1256.519165,48.201073 1253.764648,46.499462 1253.963257,58.528645 
	C1254.138794,69.169746 1254.000000,79.816040 1254.000000,90.950928 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M457.000000,165.000000 
	C457.002380,173.166550 457.144501,180.836746 456.944702,188.498016 
	C456.852631,192.027908 458.166138,193.107132 461.587860,193.052826 
	C471.751740,192.891541 481.927063,193.225220 492.083282,192.903732 
	C496.763031,192.755615 497.104279,195.014435 497.079132,198.665985 
	C497.055084,202.164276 496.605103,204.160614 492.254059,204.088943 
	C477.424927,203.844696 462.588104,203.891113 447.757080,204.067993 
	C443.996155,204.112823 442.905457,202.837051 442.935852,199.168442 
	C443.086487,181.003296 443.099335,162.835312 442.929596,144.670502 
	C442.893494,140.803665 444.343384,139.850937 447.916840,139.944000 
	C458.592621,140.222015 456.826935,138.986694 456.985596,149.000427 
	C457.067444,154.165726 457.000000,159.333405 457.000000,165.000000 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M1057.000000,75.000000 
	C1056.997925,86.142708 1056.893799,96.786949 1057.052734,107.427246 
	C1057.103760,110.847038 1056.016968,112.131813 1052.502563,112.049835 
	C1039.869141,111.755142 1042.140137,113.414589 1042.037354,101.961449 
	C1041.891479,85.666107 1042.101074,69.367439 1041.930908,53.072567 
	C1041.890503,49.217972 1043.004395,47.803967 1046.996216,47.930576 
	C1058.884644,48.307652 1056.781372,46.558235 1056.983398,58.037395 
	C1057.079956,63.523327 1057.000000,69.012367 1057.000000,75.000000 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M671.000000,73.000000 
	C671.004028,65.682098 671.103943,58.862278 670.967773,52.047173 
	C670.907837,49.047432 671.998840,47.927673 675.023804,47.969128 
	C687.197205,48.135933 684.865479,46.947964 684.961243,57.566898 
	C685.108276,73.862061 684.856445,90.161606 685.092957,106.454666 
	C685.156372,110.827385 683.902893,112.268341 679.477722,112.087311 
	C669.745422,111.689209 671.093750,112.945297 671.017822,103.432175 
	C670.938171,93.455376 671.000000,83.477440 671.000000,73.000000 
z"
      />
      <path
        fill="#006B4E"
        opacity="1.000000"
        stroke="none"
        d="
M556.276489,34.200783 
	C554.968079,35.521996 553.711731,36.447422 552.890686,37.666130 
	C548.741150,43.825546 542.829773,43.645882 536.553650,42.562424 
	C537.439697,36.069260 542.803284,31.014469 548.690674,31.004009 
	C551.018127,30.999874 553.362427,30.898809 555.657288,31.181387 
	C556.055176,31.230387 556.243958,32.977501 556.276489,34.200783 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M852.009766,155.266327 
	C851.765747,152.055222 852.972595,150.839325 855.760071,150.999741 
	C862.344788,151.378662 868.970215,151.353134 875.511963,152.088852 
	C882.380615,152.861313 885.199219,156.103363 884.988098,161.748795 
	C884.798889,166.808563 880.807251,170.614899 874.639099,170.898575 
	C868.184448,171.195404 861.698364,171.121048 855.244324,170.818405 
	C854.126465,170.765991 852.298035,168.853760 852.177612,167.661194 
	C851.779480,163.720032 852.013367,159.714996 852.009766,155.266327 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M603.000000,80.000015 
	C603.004089,74.008675 602.844360,68.508751 603.128174,63.031818 
	C603.198425,61.674694 604.650330,59.292568 605.562988,59.245625 
	C614.500671,58.785885 623.380859,58.850002 631.794434,62.867775 
	C639.101746,66.357262 642.339111,72.614288 642.710388,79.931274 
	C643.074524,87.108086 639.239075,92.924446 633.138000,96.902855 
	C624.421814,102.586578 614.601318,100.929100 605.062134,100.706902 
	C604.350220,100.690315 603.160278,98.296700 603.105530,96.970398 
	C602.879028,91.488144 603.004089,85.991364 603.000000,80.000015 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M980.334595,78.969673 
	C978.978027,78.393776 977.963196,77.954666 977.249634,77.222153 
	C976.890259,76.853264 977.067566,75.952568 977.019165,75.291092 
	C975.814514,58.828232 975.815979,58.766083 992.224121,59.050625 
	C995.839905,59.113327 999.563843,59.351242 1003.024902,60.292225 
	C1007.306213,61.456272 1010.877014,63.712326 1011.035889,69.097923 
	C1011.193909,74.454323 1008.086670,76.948418 1003.587097,78.131454 
	C1001.077454,78.791306 998.383179,78.901520 995.765137,78.963997 
	C990.779724,79.082977 985.789246,78.990128 980.334595,78.969673 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M451.263550,77.496902 
	C451.098572,72.220894 451.120361,67.387779 450.998383,62.558296 
	C450.934204,60.016228 451.958984,58.875591 454.530457,59.011963 
	C461.816956,59.398373 469.167969,59.278378 476.370117,60.261993 
	C480.296631,60.798244 484.559387,62.526581 484.979034,67.803902 
	C485.456665,73.810486 483.711639,77.357475 477.410706,78.002182 
	C468.829254,78.880234 460.072327,78.043037 451.263550,77.496902 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M968.791992,159.324646 
	C974.586365,167.749557 975.010315,176.221313 969.131592,184.114883 
	C962.537354,192.969177 953.035889,195.524002 942.469849,193.597748 
	C935.959229,192.410797 930.427917,189.277588 926.698242,183.378815 
	C921.156738,174.614594 922.003113,166.007889 928.423096,158.587051 
	C934.496704,151.566833 942.973816,148.826843 952.284241,150.321671 
	C958.595825,151.335022 964.446350,153.688446 968.791992,159.324646 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1175.490234,60.243298 
	C1185.828003,56.114441 1195.250366,57.926971 1203.431641,64.158150 
	C1215.605835,73.430267 1212.737305,92.649559 1198.535645,99.658051 
	C1187.592285,105.058563 1173.389893,102.208717 1165.423218,92.884201 
	C1157.756226,83.910576 1159.396851,66.288185 1175.490234,60.243298 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M802.983887,172.367401 
	C794.597168,173.062164 786.526367,173.643402 778.446411,173.827667 
	C777.333191,173.853073 775.256287,171.963806 775.191956,170.868912 
	C774.861084,165.238876 774.890381,159.570877 775.183716,153.936432 
	C775.236511,152.921265 777.121887,151.158401 778.142639,151.171585 
	C785.575317,151.267715 793.039917,151.391235 800.419739,152.193649 
	C805.477539,152.743576 808.910706,156.205765 809.511597,161.328293 
	C810.078125,166.157059 807.686768,169.858856 802.983887,172.367401 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M553.015869,167.859558 
	C549.820435,170.023270 546.901611,173.002060 543.665161,173.400436 
	C536.633545,174.265884 529.437805,173.912445 522.315430,173.760468 
	C521.533447,173.743790 520.180603,171.817383 520.136047,170.733841 
	C519.911194,165.263474 519.850342,159.767944 520.178528,154.308090 
	C520.247620,153.158493 522.193359,151.180618 523.268005,151.184967 
	C530.362183,151.213715 537.464111,151.545120 544.545166,152.040176 
	C552.076721,152.566772 557.567078,161.089462 553.015869,167.859558 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M402.245972,174.554886 
	C403.465179,176.762146 403.406769,178.006485 400.760651,177.991837 
	C394.789856,177.958771 388.818726,177.981461 381.756989,177.981461 
	C385.341736,170.156845 388.586792,163.073654 392.347015,154.866013 
	C395.940582,162.014267 399.012360,168.124557 402.245972,174.554886 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M830.897827,65.527313 
	C831.622314,65.333740 832.513977,65.226097 832.655762,65.502747 
	C835.953552,71.937180 839.170105,78.413231 842.733215,85.545418 
	C835.212280,85.545418 828.449036,85.545418 820.732239,85.545418 
	C824.232483,78.619881 827.474060,72.206055 830.897827,65.527313 
z"
      />
    </svg>
  );
};

CaAlpesProvenceSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaAlpesProvenceSvg;
