import * as React from 'react';
import PropTypes from 'prop-types';

const AmpersandSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <title>{title}</title>
      <path d="M17.5 12c0 4.4-3.6 8-8 8A4.5 4.5 0 015 15.5c0-6 8-4 8-8.5a3 3 0 10-6 0c0 3 2.5 8.5 12 13M16 12h3" />
    </svg>
  );
};

AmpersandSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default AmpersandSvg;
