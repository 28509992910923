import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Components
import NavbarAccount from '../NavbarAccount';
import CatalizrInLineLogo from '../AssetPictos/components/iconCatalizLine/index';
import BankIcon from '../AssetPictos/components/iconBank/index';
import IconAmpersand from '../AssetPictos/components/iconAmpersand/index';

// Utils
import { getEntityPathFromUserType } from '../../shared/utils/user';
import useReactHookFormDataStorage from '../../shared/hooks/react-hook-form-data-storage/index';

import './styles.scss';
import { getSubdomain } from '../../shared/utils/helper';
import { setJourney } from '../../redux/actions';
import { resetCurrentCommentAfterSubmit } from '../../pages/dashboard/common/actions/dashboard.actions';

const Topbar = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { hideAccountInfos, resetDashboardFilters } = props;
  // cutom hook
  const { clearFormDataStorage } = useReactHookFormDataStorage();

  const subdomain = getSubdomain();
  return (
    <nav className="navbar" data-testid="topbar">
      <div
        className="navbar__brand"
        onClick={() => {
          // Provided by SharesValuationHeader to reset pagination, search and sort from positions page
          resetDashboardFilters();
          // reset comment + journey to not keep isFeatureEnable in simulate mode
          dispatch(setJourney(''));
          dispatch(resetCurrentCommentAfterSubmit());
          clearFormDataStorage('customFormValues');
          history.push(`/${getEntityPathFromUserType(user.type)}/dashboard/refresh`);
        }}
      >
        {user && user.theme !== 'default' && (
          <Fragment>
            <BankIcon bankDomain={subdomain} />
            <IconAmpersand width="20" height="50" alt="ampersand logo" title="ampersand logo" />
          </Fragment>
        )}
        <CatalizrInLineLogo width="260px" height="50px" alt="Catalizr logo" title="Catalizr logo" />
      </div>
      {hideAccountInfos !== true && <NavbarAccount />}
    </nav>
  );
};

Topbar.defaultProps = {
  resetDashboardFilters: () => {},
  hideAccountInfos: false,
};

Topbar.propTypes = {
  hideAccountInfos: PropTypes.bool,
  resetDashboardFilters: PropTypes.func,
};

export default Topbar;
