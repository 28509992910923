import * as React from 'react';
import PropTypes from 'prop-types';

const CaDesSavoieSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101135,31.018839 
	C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
	C367.081024,31.011070 199.130295,31.011070 31.158703,31.011070 
	C31.158703,61.733963 31.158703,91.854759 31.022190,122.902138 
	C31.067490,128.943542 31.112789,134.984955 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
	C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
	C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
	C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
	C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
	C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
	C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.158703,121.975555 
	C31.158703,91.854759 31.158703,61.733963 31.158703,31.011070 
	C199.130295,31.011070 367.081024,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
	C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
	C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
	C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
	C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
	C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
	C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
	C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
	C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
	C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
	C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
	C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
	C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
	C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
	C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
	C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
	C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
	C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
	C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
	C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396374,141.702835 
	C33.838219,153.473328 39.009460,164.324600 48.020203,172.170288 
	C68.104713,189.657944 92.682549,192.806702 117.854546,189.595886 
	C132.444336,187.734894 147.099915,183.871750 160.863556,178.640732 
	C181.619522,170.752213 201.621353,160.879379 222.249176,152.051559 
	C226.404083,161.530182 230.330032,170.776535 234.067825,180.098328 
	C235.717728,184.213165 238.189972,187.730011 242.735672,187.852341 
	C254.987122,188.182007 267.253296,187.965057 280.266357,187.965057 
	C277.519806,181.940796 275.127045,176.566132 272.629791,171.240448 
	C266.818359,158.846909 260.956604,146.476974 255.422760,133.933289 
	C258.574829,132.157990 261.474976,130.636963 264.254456,128.920486 
	C277.770233,120.573799 291.267181,112.196236 304.720306,103.749199 
	C305.683533,103.144402 306.875824,101.905647 306.891357,100.944397 
	C307.050720,91.058205 306.989105,81.168442 306.989105,70.488342 
	C296.574005,76.321426 287.078735,81.753044 277.476379,86.988274 
	C265.702301,93.407555 253.838638,99.662575 241.984283,105.615349 
	C241.267899,103.389969 240.693893,101.486328 239.876251,99.693817 
	C232.746124,84.062622 225.410461,68.521858 218.550217,52.773731 
	C217.202240,49.679348 215.730408,48.912876 212.729019,48.950962 
	C202.571106,49.079853 192.406113,49.171688 182.253372,48.908882 
	C178.596069,48.814213 176.538574,50.064400 174.882370,53.215626 
	C166.804962,68.584297 158.584991,83.878181 150.385437,99.182442 
	C143.594849,111.856880 136.733124,124.493286 129.966660,137.180542 
	C127.982674,140.900558 126.216576,144.736755 123.971596,149.288116 
	C137.879684,148.362503 150.390350,145.987656 161.848618,139.614288 
	C164.797791,137.973862 168.355652,136.228867 169.923965,133.536041 
	C174.142456,126.292786 177.480637,118.532181 181.087753,110.938690 
	C185.587677,101.465668 190.007050,91.954376 194.779572,81.780983 
	C200.461746,95.681091 205.866150,108.901680 211.044357,122.402794 
	C210.219315,122.951851 209.617584,123.214127 209.022079,123.489830 
	C191.019028,131.824860 173.367477,141.071930 154.895370,148.189468 
	C141.742310,153.257538 127.632835,156.348755 113.675430,158.670227 
	C101.696297,160.662659 89.292564,161.159500 77.145241,160.657333 
	C70.637695,160.388321 64.138634,156.941330 57.892090,154.318054 
	C53.301064,152.390015 52.641087,147.695557 53.181629,143.408096 
	C53.622112,139.914337 54.870762,136.371994 56.453987,133.196701 
	C68.386551,109.264999 88.524803,94.421593 112.345116,84.132919 
	C125.458572,78.468849 139.294968,74.988297 154.239716,74.721832 
	C153.102631,70.656891 151.923752,66.942841 151.030609,63.161312 
	C147.511902,48.262932 138.844101,43.005203 124.071541,46.382423 
	C108.481705,49.946484 93.670280,55.311684 79.904099,63.539448 
	C56.815609,77.338951 38.683674,95.219185 31.158703,121.975555 
M430.000000,74.500000 
	C430.000000,86.912788 430.000000,99.325577 430.000000,111.627213 
	C435.114899,111.627213 439.361328,111.627213 444.111572,111.627213 
	C444.111572,104.343918 444.111572,97.424263 444.111572,90.000076 
	C448.403900,90.000076 452.227509,89.711311 455.977020,90.113396 
	C458.070862,90.337936 460.510620,91.232094 461.970245,92.664459 
	C465.013367,95.650681 467.397156,99.295280 470.260590,102.481812 
	C473.201080,105.754158 475.876556,110.263191 479.602814,111.574287 
	C484.104614,113.158272 489.588989,111.949608 495.853699,111.949608 
	C488.679291,103.553116 482.375824,96.175957 475.865692,88.556908 
	C478.872833,87.260689 481.102386,86.544212 483.084381,85.399261 
	C488.954803,82.008118 492.912354,77.454315 493.077362,70.094322 
	C493.253906,62.217556 490.821991,55.861210 483.293549,52.844215 
	C478.344666,50.860962 472.944183,49.214890 467.675598,48.977299 
	C455.311340,48.419731 442.904877,48.798870 430.000000,48.798870 
	C430.000000,56.916981 430.000000,65.208488 430.000000,74.500000 
M1005.298889,111.944221 
	C1010.456299,111.944221 1015.613770,111.944221 1021.906433,111.944221 
	C1014.618042,103.461586 1008.331665,96.145119 1002.471863,89.325172 
	C1007.039856,86.213539 1011.806030,84.140396 1014.927917,80.612320 
	C1023.103760,71.372437 1020.645020,54.208355 1003.231873,50.212078 
	C995.284119,48.388096 986.863037,48.437073 978.637329,48.069748 
	C971.230774,47.738998 963.797852,48.000000 956.286743,48.000000 
	C956.286743,69.642982 956.286743,90.535797 956.286743,111.600853 
	C960.937744,111.600853 965.296814,111.600853 970.155518,111.600853 
	C970.155518,104.296959 970.155518,97.382156 970.155518,90.000000 
	C973.440125,90.000000 976.264587,90.257607 979.024292,89.944504 
	C984.213989,89.355698 987.497986,91.301971 990.563416,95.669525 
	C994.615784,101.443268 999.796448,106.425095 1005.298889,111.944221 
M581.000000,100.480438 
	C581.000000,104.224091 581.000000,107.967743 581.000000,111.984871 
	C593.471130,111.984871 605.136719,112.528839 616.725464,111.818405 
	C626.577942,111.214394 636.174500,107.916733 642.840576,100.387344 
	C655.881836,85.657021 652.500977,59.936111 630.421997,52.130325 
	C624.310547,49.969666 617.664307,48.745338 611.184814,48.336075 
	C601.309814,47.712334 591.366455,48.172680 581.000000,48.172680 
	C581.000000,65.616600 581.000000,82.552055 581.000000,100.480438 
M1218.626465,85.767281 
	C1220.454224,74.592323 1217.478027,64.458603 1208.752930,57.657505 
	C1191.705322,44.369019 1172.967041,43.708302 1154.163696,54.051754 
	C1142.955688,60.217045 1138.155762,70.425179 1139.053101,83.076004 
	C1139.846680,94.261475 1146.206177,101.855995 1155.641846,107.306526 
	C1166.883423,113.800163 1178.997681,114.643669 1191.149536,111.691292 
	C1204.555664,108.434189 1214.916382,101.056496 1218.626465,85.767281 
M424.996887,175.169098 
	C426.458008,157.954407 415.118103,144.931122 398.728210,142.153412 
	C389.169891,140.533508 379.320648,140.569382 369.592468,140.040649 
	C365.184265,139.801071 360.752258,140.000000 356.335175,140.000000 
	C356.335175,161.709534 356.335175,182.617706 356.335175,203.030609 
	C368.746490,203.030609 380.829895,203.399933 392.881470,202.938171 
	C408.324097,202.346481 423.199615,192.186020 424.996887,175.169098 
M906.271851,141.014435 
	C888.375671,136.926147 871.693909,138.692474 859.198608,153.533066 
	C847.862976,166.996246 850.082886,183.821396 862.683472,194.626068 
	C876.465942,206.444122 892.995300,208.073639 909.722839,201.825790 
	C929.340820,194.498367 935.900024,179.267746 930.696045,162.096786 
	C927.315491,150.942245 918.007202,144.693192 906.271851,141.014435 
M924.154663,87.046204 
	C927.555359,90.679825 926.765320,97.260887 922.633484,99.111053 
	C914.092468,102.935616 905.283997,103.546021 896.635193,99.932327 
	C888.769775,96.645882 883.825867,90.480843 883.110352,81.849304 
	C882.463318,74.044533 885.773376,67.570877 892.294312,63.269806 
	C903.341187,55.983494 914.559875,56.469830 925.808228,63.205318 
	C931.087158,66.366348 929.581543,65.835999 934.697998,59.473801 
	C937.540161,55.939640 934.173096,54.633347 931.713135,53.347691 
	C915.757568,45.008774 899.775146,45.019207 883.941467,53.574291 
	C873.830383,59.037415 868.349915,67.662308 868.042908,79.198593 
	C867.711243,91.663780 872.861938,101.551773 883.937744,107.329819 
	C901.654724,116.572472 919.374146,114.592293 936.628723,105.580696 
	C938.034058,104.846718 939.792175,103.075615 939.839050,101.732483 
	C940.132874,93.309662 939.987610,84.871529 939.987610,76.280838 
	C928.731140,76.280838 918.155640,76.280838 907.397339,76.280838 
	C907.397339,79.929565 907.397339,83.289757 907.397339,87.007607 
	C912.934021,87.007607 918.076843,87.007607 924.154663,87.046204 
M526.492432,157.897278 
	C525.445129,153.232834 529.538696,152.907745 532.060547,152.005249 
	C541.541748,148.612305 550.885620,149.983658 559.890625,153.991165 
	C568.978882,158.035690 568.944946,158.126572 574.437073,149.942551 
	C574.688660,149.567657 574.706055,149.035599 574.925354,148.239899 
	C567.197815,143.580139 559.011841,140.897339 549.958984,139.674911 
	C538.831726,138.172394 528.425415,139.563370 518.945007,145.158356 
	C508.215332,151.490585 508.082214,165.805984 519.279846,171.010178 
	C523.723633,173.075455 528.457153,174.611923 533.181763,175.957047 
	C540.925049,178.161621 548.825134,179.825867 556.530640,182.144928 
	C558.467163,182.727722 559.915344,184.932938 561.587952,186.392548 
	C559.978271,188.139557 558.651184,190.885757 556.703186,191.436676 
	C551.846802,192.810074 546.643555,194.107162 541.696533,193.761551 
	C532.474731,193.117264 523.503052,190.789581 515.377258,184.402924 
	C512.895142,188.072708 510.665588,191.369125 508.278259,194.898773 
	C521.703125,202.841156 535.407349,206.123138 549.873840,204.811829 
	C558.010620,204.074249 566.078552,202.138565 572.151428,195.465942 
	C578.276978,188.735474 577.454773,178.120514 569.975098,172.980713 
	C561.688049,167.286118 551.836060,166.471146 542.516296,163.926712 
	C537.237000,162.485397 532.158813,160.307526 526.492432,157.897278 
M680.286499,171.093460 
	C676.408203,169.720428 672.598511,168.092056 668.637329,167.027542 
	C660.378967,164.808289 652.007996,163.005112 643.765625,160.732437 
	C642.317810,160.333237 640.203735,158.390884 640.292725,157.318497 
	C640.421997,155.761169 641.966492,153.652145 643.447388,152.995056 
	C649.077271,150.497086 655.036255,148.892181 661.225891,150.644226 
	C668.263000,152.636124 675.214966,154.928574 682.660767,157.231659 
	C683.334534,156.417450 684.683472,154.974594 685.818604,153.379623 
	C688.906555,149.040573 688.686218,147.704681 683.927612,145.525711 
	C680.199646,143.818680 676.322144,142.270966 672.353760,141.286682 
	C659.035583,137.983322 645.848450,137.508865 633.509521,144.858704 
	C628.484375,147.852005 624.795532,151.787903 624.936340,158.325974 
	C625.045410,163.391922 626.587708,167.826050 631.164307,169.900864 
	C637.426331,172.739777 644.035278,174.871933 650.608093,176.950958 
	C657.644470,179.176575 664.861572,180.834991 671.877319,183.117569 
	C673.200806,183.548187 674.589600,185.677429 674.744812,187.149704 
	C674.853149,188.177353 673.116028,189.781967 671.847900,190.509949 
	C663.862671,195.093994 655.282776,194.503708 646.896301,192.316010 
	C640.604919,190.674805 634.597595,187.944656 627.963806,185.516312 
	C626.217712,188.242828 624.159119,191.457260 621.406494,195.755386 
	C629.618347,198.578033 636.747925,201.694290 644.198608,203.438660 
	C656.336975,206.280457 668.464355,206.004807 679.902222,200.035645 
	C685.478760,197.125366 689.462219,193.137436 690.040527,186.401672 
	C690.668701,179.085388 687.103088,174.533875 680.286499,171.093460 
M491.364441,193.000000 
	C481.367767,192.999573 471.370544,193.056854 461.374634,192.969208 
	C457.586700,192.936005 453.724609,192.277740 453.154541,187.621735 
	C452.701294,183.919937 453.063171,180.118317 453.063171,175.868927 
	C466.346283,175.868927 478.931091,175.868927 491.561218,175.868927 
	C491.561218,171.985016 491.561218,168.626114 491.561218,164.764099 
	C478.545471,164.764099 465.962036,164.764099 453.314178,164.764099 
	C453.314178,160.003296 453.314178,155.757233 453.314178,150.835114 
	C467.655212,150.835114 481.698151,150.835114 495.633392,150.835114 
	C495.633392,146.898071 495.633392,143.649338 495.633392,140.301331 
	C476.358063,140.301331 457.450653,140.301331 438.053986,140.301331 
	C438.053986,156.211823 437.408813,171.862015 438.267090,187.429306 
	C438.903290,198.969254 445.726532,203.989380 457.528290,203.998184 
	C468.857880,204.006622 480.187500,204.010391 491.517029,203.989044 
	C493.269501,203.985748 495.021729,203.836868 496.801086,203.754059 
	C496.801086,199.997055 496.801086,196.867264 496.801086,193.338760 
	C495.206512,193.218109 493.766235,193.109131 491.364441,193.000000 
M1017.499878,151.000000 
	C1024.250854,151.000000 1031.001709,151.000000 1037.629395,151.000000 
	C1037.629395,146.884796 1037.629395,143.638062 1037.629395,140.305176 
	C1018.683228,140.305176 1000.109314,140.305176 981.000000,140.305176 
	C981.000000,142.381882 981.000000,144.174530 981.000000,145.967178 
	C981.000000,159.629135 981.348633,173.303345 980.869324,186.948456 
	C980.581238,195.149338 985.827271,200.999283 992.359436,202.945145 
	C994.232300,203.503082 996.223083,203.941925 998.162903,203.954376 
	C1011.602356,204.040634 1025.042603,203.999847 1038.573242,203.999847 
	C1038.573242,200.012497 1038.573242,196.651855 1038.573242,192.999634 
	C1026.666992,192.999634 1015.184814,192.962570 1003.703064,193.019501 
	C999.614624,193.039764 996.145203,191.472717 995.234985,187.577393 
	C994.402832,184.016052 995.058411,180.107086 995.058411,175.859467 
	C1008.689819,175.859467 1021.607483,175.859467 1034.556519,175.859467 
	C1034.556519,171.978943 1034.556519,168.618103 1034.556519,164.755310 
	C1021.202820,164.755310 1008.286560,164.755310 995.318481,164.755310 
	C995.318481,159.994568 995.318481,155.748489 995.318481,151.000000 
	C1002.583679,151.000000 1009.541870,151.000000 1017.499878,151.000000 
M509.764893,48.008190 
	C506.091766,47.860374 507.038086,50.625286 507.030182,52.568928 
	C506.973938,66.400391 506.983063,80.232224 507.005402,94.063873 
	C507.023285,105.138092 513.890808,111.977028 524.999207,111.995476 
	C536.997803,112.015404 548.996338,112.009079 560.994934,111.988922 
	C562.583435,111.986259 564.171753,111.834465 565.627930,111.758621 
	C565.627930,107.883080 565.627930,104.635887 565.627930,101.000000 
	C563.620056,101.000000 561.981812,101.000015 560.343506,101.000008 
	C550.178040,100.999947 540.002747,101.243034 529.854004,100.831367 
	C527.269043,100.726517 523.303833,99.125206 522.537109,97.193588 
	C520.993103,93.303955 521.211914,88.714584 520.629517,83.788895 
	C534.768311,83.788895 547.687866,83.788895 560.696533,83.788895 
	C560.696533,80.356041 560.696533,77.442345 560.696533,73.846817 
	C547.365540,73.846817 534.320618,73.846817 521.362427,73.846817 
	C521.362427,68.572899 521.362427,63.993542 521.362427,58.910927 
	C535.967834,58.910927 550.220337,58.910927 564.580688,58.910927 
	C564.580688,55.028381 564.580688,51.667225 564.580688,48.001102 
	C546.347961,48.001102 528.532898,48.001102 509.764893,48.008190 
M709.501587,177.997742 
	C705.225403,186.394669 700.949280,194.791595 696.511353,203.506195 
	C710.535645,205.102020 710.535706,205.102051 715.930847,194.208618 
	C716.151489,193.763245 716.434265,193.334152 716.569641,192.863419 
	C717.506836,189.605225 719.643250,188.761551 722.938599,188.918915 
	C728.924561,189.204758 735.035400,189.695892 740.909851,188.838516 
	C749.944702,187.519882 755.164062,190.902390 757.825012,199.376694 
	C758.360718,201.082733 759.943176,203.577057 761.259888,203.720764 
	C766.063416,204.245026 770.958801,203.928009 775.700256,203.928009 
	C775.815430,202.671112 775.983093,202.272324 775.855896,202.025330 
	C766.761780,184.357529 757.670715,166.688034 748.517883,149.050629 
	C743.652466,139.675156 743.594971,139.556442 732.671387,140.141739 
	C730.835388,140.240112 728.305420,141.771530 727.444336,143.368011 
	C721.392395,154.589279 715.693787,166.001083 709.501587,177.997742 
M845.499512,104.999741 
	C849.186707,112.480141 854.083862,114.063148 864.882446,111.115662 
	C864.119568,109.459419 863.435120,107.798729 862.610840,106.210602 
	C853.923279,89.473145 844.827271,72.931587 836.708801,55.923176 
	C833.661926,49.539867 830.194824,47.052395 823.234802,47.812428 
	C819.524231,48.217621 817.426575,48.959183 815.639893,52.513939 
	C806.799988,70.101852 797.579834,87.498482 788.530884,104.981796 
	C787.504639,106.964592 786.711670,109.068161 785.764282,111.221001 
	C798.848267,114.028946 800.767883,113.042084 805.156067,101.701920 
	C805.275391,101.393623 805.513428,101.126114 805.604797,100.812950 
	C806.471497,97.841347 808.310425,96.810097 811.492554,96.919998 
	C818.809753,97.172722 826.182678,97.478302 833.455139,96.854660 
	C839.719360,96.317482 843.566223,98.189163 845.499512,104.999741 
M397.337372,101.055557 
	C388.801941,103.124374 380.570221,102.525215 372.917480,97.947754 
	C360.667542,90.620453 359.948608,73.307251 370.991699,64.297913 
	C380.707794,56.371197 395.579041,56.380287 405.666656,63.120274 
	C410.020966,66.029564 411.473938,65.326141 414.926025,59.237434 
	C417.252045,55.134880 413.694092,54.387295 411.409760,53.209690 
	C395.420197,44.966850 379.496521,45.178505 363.631805,53.712292 
	C344.967499,63.751995 340.823669,91.405807 361.628265,105.638184 
	C377.685974,116.623222 401.498718,115.709641 417.619965,103.598740 
	C417.102692,102.797531 416.584625,101.985199 416.056824,101.179230 
	C411.743896,94.593307 411.757538,94.622284 404.954468,97.997734 
	C402.742035,99.095451 400.434174,100.000885 397.337372,101.055557 
M1081.103760,52.429794 
	C1077.520630,55.336452 1073.482422,57.834358 1070.438110,61.224789 
	C1060.450684,72.347778 1061.700928,93.597191 1074.907715,103.647064 
	C1087.699829,113.381218 1101.763306,114.806847 1116.668579,111.515991 
	C1122.651001,110.195190 1128.548584,107.866119 1133.243530,103.104568 
	C1131.025269,100.145767 1129.077393,97.547615 1127.037476,94.826706 
	C1125.734741,95.335831 1124.522949,95.538727 1123.649902,96.193481 
	C1116.758179,101.362000 1108.905273,102.393250 1100.587158,102.037605 
	C1090.319702,101.598648 1079.197144,91.534508 1079.000122,82.218872 
	C1078.670044,66.628853 1093.570435,54.620564 1108.868164,58.539330 
	C1114.695435,60.032104 1120.234131,62.650639 1126.286133,64.897858 
	C1128.152832,62.342182 1130.145874,59.613514 1132.442139,56.469551 
	C1115.967041,45.691917 1099.241699,44.807156 1081.103760,52.429794 
M813.133301,203.999832 
	C816.101257,204.537155 817.636963,203.015259 818.871277,200.474884 
	C828.199646,181.276077 837.627014,162.125412 846.991577,142.944138 
	C847.370544,142.167877 847.429016,141.235168 847.649902,140.321014 
	C833.343384,139.056305 832.832336,139.799835 827.864075,151.532272 
	C822.671509,163.794189 816.514954,175.647934 810.485962,188.296631 
	C809.790833,187.295929 809.441956,186.920868 809.236450,186.479095 
	C802.688599,172.405457 796.198547,158.304626 789.558594,144.274658 
	C788.793884,142.658630 787.315308,140.354355 785.979797,140.218689 
	C781.144653,139.727493 776.229370,140.025223 770.727356,140.025223 
	C771.878296,142.531021 772.578979,144.176865 773.376465,145.774368 
	C781.585999,162.218964 790.188232,178.482712 797.848694,195.178741 
	C800.968872,201.979095 804.587524,205.673645 813.133301,203.999832 
M251.601135,62.089832 
	C246.924698,63.821983 242.248276,65.554131 236.927277,67.525032 
	C238.057785,70.470192 239.536819,74.209702 240.932159,77.980179 
	C244.840424,88.541138 249.857834,90.749313 259.757202,85.598671 
	C274.551697,77.901108 289.210846,69.941002 303.842346,61.936779 
	C305.204956,61.191357 306.805145,59.357929 306.852356,57.980282 
	C307.133911,49.758621 306.991699,41.522446 306.991699,32.571957 
	C288.262054,42.632664 270.304840,52.278461 251.601135,62.089832 
M714.239319,111.083008 
	C719.035950,111.475105 723.832520,111.867203 729.179382,112.304283 
	C729.179382,93.940010 729.179382,76.694000 729.179382,58.949272 
	C737.262634,58.949272 744.847595,58.949272 752.602234,58.949272 
	C752.602234,55.070202 752.602234,51.709869 752.602234,48.327461 
	C731.629211,48.327461 711.055664,48.327461 690.445923,48.327461 
	C690.445923,52.030052 690.445923,55.388546 690.445923,59.251740 
	C698.471680,59.251740 706.056335,59.251740 714.048096,59.251740 
	C714.048096,76.564064 714.048096,93.365318 714.239319,111.083008 
M1285.748779,101.887299 
	C1280.187866,101.591537 1274.629761,101.150085 1269.065186,101.036240 
	C1261.822998,100.888069 1254.575439,101.000000 1246.872681,101.000000 
	C1246.872681,82.984283 1246.872681,65.755966 1246.872681,48.413387 
	C1241.693848,48.413387 1237.001953,48.413387 1232.313477,48.413387 
	C1232.313477,69.713020 1232.313477,90.597710 1232.313477,111.606552 
	C1250.305298,111.606552 1267.981079,111.606552 1285.947998,111.606552 
	C1285.947998,108.407265 1285.947998,105.598915 1285.748779,101.887299 
M1050.000000,59.519566 
	C1050.000000,55.776749 1050.000000,52.033936 1050.000000,48.349678 
	C1044.585327,48.349678 1040.012695,48.349678 1035.419189,48.349678 
	C1035.419189,69.700775 1035.419189,90.699425 1035.419189,111.649452 
	C1040.416382,111.649452 1044.989014,111.649452 1050.000000,111.649452 
	C1050.000000,94.372665 1050.000000,77.442581 1050.000000,59.519566 
M961.000000,168.500000 
	C961.000000,159.099350 961.000000,149.698715 961.000000,140.343216 
	C955.930054,140.343216 951.690308,140.343216 947.410767,140.343216 
	C947.410767,161.686783 947.410767,182.684525 947.410767,203.657181 
	C952.069153,203.657181 956.308899,203.657181 961.000000,203.657181 
	C961.000000,192.046967 961.000000,180.773483 961.000000,168.500000 
M678.000000,53.633156 
	C678.000000,51.878914 678.000000,50.124668 678.000000,48.315552 
	C672.990051,48.315552 668.748962,48.315552 664.388184,48.315552 
	C664.388184,69.633865 664.388184,90.634499 664.388184,111.679047 
	C669.020935,111.679047 673.262085,111.679047 678.000000,111.679047 
	C678.000000,92.441673 678.000000,73.521126 678.000000,53.633156 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M242.013702,105.988602 
	C253.838638,99.662575 265.702301,93.407555 277.476379,86.988274 
	C287.078735,81.753044 296.574005,76.321426 306.989105,70.488342 
	C306.989105,81.168442 307.050720,91.058205 306.891357,100.944397 
	C306.875824,101.905647 305.683533,103.144402 304.720306,103.749199 
	C291.267181,112.196236 277.770233,120.573799 264.254456,128.920486 
	C261.474976,130.636963 258.574829,132.157990 255.040039,133.905914 
	C253.554413,134.208954 252.666992,134.201675 251.977570,134.567535 
	C242.534271,139.578979 233.106583,144.620377 223.717834,149.732986 
	C222.965332,150.142746 222.527679,151.130707 221.943710,151.849960 
	C201.621353,160.879379 181.619522,170.752213 160.863556,178.640732 
	C147.099915,183.871750 132.444336,187.734894 117.854546,189.595886 
	C92.682549,192.806702 68.104713,189.657944 48.020203,172.170288 
	C39.009460,164.324600 33.838219,153.473328 31.469835,141.240967 
	C31.112789,134.984955 31.067490,128.943542 31.090446,122.438843 
	C38.683674,95.219185 56.815609,77.338951 79.904099,63.539448 
	C93.670280,55.311684 108.481705,49.946484 124.071541,46.382423 
	C138.844101,43.005203 147.511902,48.262932 151.030609,63.161312 
	C151.923752,66.942841 153.102631,70.656891 154.239716,74.721832 
	C139.294968,74.988297 125.458572,78.468849 112.345116,84.132919 
	C88.524803,94.421593 68.386551,109.264999 56.453987,133.196701 
	C54.870762,136.371994 53.622112,139.914337 53.181629,143.408096 
	C52.641087,147.695557 53.301064,152.390015 57.892090,154.318054 
	C64.138634,156.941330 70.637695,160.388321 77.145241,160.657333 
	C89.292564,161.159500 101.696297,160.662659 113.675430,158.670227 
	C127.632835,156.348755 141.742310,153.257538 154.895370,148.189468 
	C173.367477,141.071930 191.019028,131.824860 209.022079,123.489830 
	C209.617584,123.214127 210.219315,122.951851 211.401535,122.368240 
	C221.994507,116.698311 232.004105,111.343452 242.013702,105.988602 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910889,111.579178 
	C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
	C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
	C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
	C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
	C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
	C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
	C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
	C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
	C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
	C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
	C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
	C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
	C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
	C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.984283,105.615341 
	C232.004105,111.343452 221.994507,116.698311 211.627731,122.087723 
	C205.866150,108.901680 200.461746,95.681091 194.779572,81.780983 
	C190.007050,91.954376 185.587677,101.465668 181.087753,110.938690 
	C177.480637,118.532181 174.142456,126.292786 169.923965,133.536041 
	C168.355652,136.228867 164.797791,137.973862 161.848618,139.614288 
	C150.390350,145.987656 137.879684,148.362503 123.971596,149.288116 
	C126.216576,144.736755 127.982674,140.900558 129.966660,137.180542 
	C136.733124,124.493286 143.594849,111.856880 150.385437,99.182442 
	C158.584991,83.878181 166.804962,68.584297 174.882370,53.215626 
	C176.538574,50.064400 178.596069,48.814213 182.253372,48.908882 
	C192.406113,49.171688 202.571106,49.079853 212.729019,48.950962 
	C215.730408,48.912876 217.202240,49.679348 218.550217,52.773731 
	C225.410461,68.521858 232.746124,84.062622 239.876251,99.693817 
	C240.693893,101.486328 241.267899,103.389969 241.984283,105.615341 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M430.000000,74.000000 
	C430.000000,65.208488 430.000000,56.916981 430.000000,48.798870 
	C442.904877,48.798870 455.311340,48.419731 467.675598,48.977299 
	C472.944183,49.214890 478.344666,50.860962 483.293549,52.844215 
	C490.821991,55.861210 493.253906,62.217556 493.077362,70.094322 
	C492.912354,77.454315 488.954803,82.008118 483.084381,85.399261 
	C481.102386,86.544212 478.872833,87.260689 475.865692,88.556908 
	C482.375824,96.175957 488.679291,103.553116 495.853699,111.949608 
	C489.588989,111.949608 484.104614,113.158272 479.602814,111.574287 
	C475.876556,110.263191 473.201080,105.754158 470.260590,102.481812 
	C467.397156,99.295280 465.013367,95.650681 461.970245,92.664459 
	C460.510620,91.232094 458.070862,90.337936 455.977020,90.113396 
	C452.227509,89.711311 448.403900,90.000076 444.111572,90.000076 
	C444.111572,97.424263 444.111572,104.343918 444.111572,111.627213 
	C439.361328,111.627213 435.114899,111.627213 430.000000,111.627213 
	C430.000000,99.325577 430.000000,86.912788 430.000000,74.000000 
M470.671295,60.319733 
	C461.988434,59.722061 453.305542,59.124390 444.400513,58.511429 
	C444.400513,65.946365 444.400513,72.303490 444.400513,78.989838 
	C452.433929,78.989838 460.074707,79.137741 467.706116,78.934021 
	C472.237885,78.813034 477.538452,74.496231 477.972046,71.001472 
	C478.619568,65.782463 476.292450,62.412903 470.671295,60.319733 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1004.895508,111.845772 
	C999.796448,106.425095 994.615784,101.443268 990.563416,95.669525 
	C987.497986,91.301971 984.213989,89.355698 979.024292,89.944504 
	C976.264587,90.257607 973.440125,90.000000 970.155518,90.000000 
	C970.155518,97.382156 970.155518,104.296959 970.155518,111.600853 
	C965.296814,111.600853 960.937744,111.600853 956.286743,111.600853 
	C956.286743,90.535797 956.286743,69.642982 956.286743,48.000000 
	C963.797852,48.000000 971.230774,47.738998 978.637329,48.069748 
	C986.863037,48.437073 995.284119,48.388096 1003.231873,50.212078 
	C1020.645020,54.208355 1023.103760,71.372437 1014.927917,80.612320 
	C1011.806030,84.140396 1007.039856,86.213539 1002.471863,89.325172 
	C1008.331665,96.145119 1014.618042,103.461586 1021.906433,111.944221 
	C1015.613770,111.944221 1010.456299,111.944221 1004.895508,111.845772 
M1003.986572,71.057762 
	C1003.930420,64.745682 1002.141968,61.262993 996.523254,60.455463 
	C987.945312,59.222595 979.203674,59.129112 970.448853,58.546371 
	C970.448853,66.044807 970.448853,72.405083 970.448853,79.000000 
	C977.381592,79.000000 983.876526,78.780815 990.349487,79.063606 
	C996.435730,79.329491 1000.932434,77.148933 1003.986572,71.057762 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M581.000000,99.983978 
	C581.000000,82.552055 581.000000,65.616600 581.000000,48.172680 
	C591.366455,48.172680 601.309814,47.712334 611.184814,48.336075 
	C617.664307,48.745338 624.310547,49.969666 630.421997,52.130325 
	C652.500977,59.936111 655.881836,85.657021 642.840576,100.387344 
	C636.174500,107.916733 626.577942,111.214394 616.725464,111.818405 
	C605.136719,112.528839 593.471130,111.984871 581.000000,111.984871 
	C581.000000,107.967743 581.000000,104.224091 581.000000,99.983978 
M625.886108,63.467602 
	C616.277039,58.229744 605.906982,58.959404 595.388306,59.114288 
	C595.388306,73.278709 595.388306,86.955299 595.388306,100.926582 
	C605.300598,100.762482 615.016968,102.482460 624.044312,97.943642 
	C630.106262,94.895782 634.337646,90.292038 635.004639,83.127464 
	C635.750305,75.117035 633.530762,68.458107 625.886108,63.467602 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1218.418945,86.142654 
	C1214.916382,101.056496 1204.555664,108.434189 1191.149536,111.691292 
	C1178.997681,114.643669 1166.883423,113.800163 1155.641846,107.306526 
	C1146.206177,101.855995 1139.846680,94.261475 1139.053101,83.076004 
	C1138.155762,70.425179 1142.955688,60.217045 1154.163696,54.051754 
	C1172.967041,43.708302 1191.705322,44.369019 1208.752930,57.657505 
	C1217.478027,64.458603 1220.454224,74.592323 1218.418945,86.142654 
M1186.137573,59.015713 
	C1181.429321,59.014778 1176.622681,58.395020 1172.030640,59.125217 
	C1156.230591,61.637703 1148.382324,80.832489 1158.105835,92.584892 
	C1166.029419,102.161766 1179.635254,105.070854 1190.862671,99.588547 
	C1202.541016,93.886070 1206.569702,83.708893 1202.043701,71.659187 
	C1199.377808,64.562103 1193.205200,61.813278 1186.137573,59.015713 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M424.988586,175.615784 
	C423.199615,192.186020 408.324097,202.346481 392.881470,202.938171 
	C380.829895,203.399933 368.746490,203.030609 356.335175,203.030609 
	C356.335175,182.617706 356.335175,161.709534 356.335175,140.000000 
	C360.752258,140.000000 365.184265,139.801071 369.592468,140.040649 
	C379.320648,140.569382 389.169891,140.533508 398.728210,142.153412 
	C415.118103,144.931122 426.458008,157.954407 424.988586,175.615784 
M407.941956,181.159088 
	C411.761963,171.677917 410.521576,164.605774 403.183563,157.760437 
	C393.604279,148.824265 381.760895,151.249985 370.329712,151.177063 
	C370.329712,165.372971 370.329712,178.944626 370.329712,192.981140 
	C376.441467,192.981140 382.326111,193.632843 388.001923,192.822235 
	C395.734863,191.717850 403.165161,189.246613 407.941956,181.159088 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M906.678955,141.063110 
	C918.007202,144.693192 927.315491,150.942245 930.696045,162.096786 
	C935.900024,179.267746 929.340820,194.498367 909.722839,201.825790 
	C892.995300,208.073639 876.465942,206.444122 862.683472,194.626068 
	C850.082886,183.821396 847.862976,166.996246 859.198608,153.533066 
	C871.693909,138.692474 888.375671,136.926147 906.678955,141.063110 
M869.832031,162.497421 
	C864.888000,172.321899 867.693604,183.330872 876.671387,189.334259 
	C886.845093,196.137390 898.702087,195.747879 909.445984,188.257584 
	C917.208740,182.845673 919.678955,170.493607 914.930420,162.375443 
	C905.212830,145.761917 879.870972,146.038162 869.832031,162.497421 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M923.687195,87.026901 
	C918.076843,87.007607 912.934021,87.007607 907.397339,87.007607 
	C907.397339,83.289757 907.397339,79.929565 907.397339,76.280838 
	C918.155640,76.280838 928.731140,76.280838 939.987610,76.280838 
	C939.987610,84.871529 940.132874,93.309662 939.839050,101.732483 
	C939.792175,103.075615 938.034058,104.846718 936.628723,105.580696 
	C919.374146,114.592293 901.654724,116.572472 883.937744,107.329819 
	C872.861938,101.551773 867.711243,91.663780 868.042908,79.198593 
	C868.349915,67.662308 873.830383,59.037415 883.941467,53.574291 
	C899.775146,45.019207 915.757568,45.008774 931.713135,53.347691 
	C934.173096,54.633347 937.540161,55.939640 934.697998,59.473801 
	C929.581543,65.835999 931.087158,66.366348 925.808228,63.205318 
	C914.559875,56.469830 903.341187,55.983494 892.294312,63.269806 
	C885.773376,67.570877 882.463318,74.044533 883.110352,81.849304 
	C883.825867,90.480843 888.769775,96.645882 896.635193,99.932327 
	C905.283997,103.546021 914.092468,102.935616 922.633484,99.111053 
	C926.765320,97.260887 927.555359,90.679825 923.687195,87.026901 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M526.741211,158.179321 
	C532.158813,160.307526 537.237000,162.485397 542.516296,163.926712 
	C551.836060,166.471146 561.688049,167.286118 569.975098,172.980713 
	C577.454773,178.120514 578.276978,188.735474 572.151428,195.465942 
	C566.078552,202.138565 558.010620,204.074249 549.873840,204.811829 
	C535.407349,206.123138 521.703125,202.841156 508.278259,194.898773 
	C510.665588,191.369125 512.895142,188.072708 515.377258,184.402924 
	C523.503052,190.789581 532.474731,193.117264 541.696533,193.761551 
	C546.643555,194.107162 551.846802,192.810074 556.703186,191.436676 
	C558.651184,190.885757 559.978271,188.139557 561.587952,186.392548 
	C559.915344,184.932938 558.467163,182.727722 556.530640,182.144928 
	C548.825134,179.825867 540.925049,178.161621 533.181763,175.957047 
	C528.457153,174.611923 523.723633,173.075455 519.279846,171.010178 
	C508.082214,165.805984 508.215332,151.490585 518.945007,145.158356 
	C528.425415,139.563370 538.831726,138.172394 549.958984,139.674911 
	C559.011841,140.897339 567.197815,143.580139 574.925354,148.239899 
	C574.706055,149.035599 574.688660,149.567657 574.437073,149.942551 
	C568.944946,158.126572 568.978882,158.035690 559.890625,153.991165 
	C550.885620,149.983658 541.541748,148.612305 532.060547,152.005249 
	C529.538696,152.907745 525.445129,153.232834 526.741211,158.179321 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M680.613892,171.276703 
	C687.103088,174.533875 690.668701,179.085388 690.040527,186.401672 
	C689.462219,193.137436 685.478760,197.125366 679.902222,200.035645 
	C668.464355,206.004807 656.336975,206.280457 644.198608,203.438660 
	C636.747925,201.694290 629.618347,198.578033 621.406494,195.755386 
	C624.159119,191.457260 626.217712,188.242828 627.963806,185.516312 
	C634.597595,187.944656 640.604919,190.674805 646.896301,192.316010 
	C655.282776,194.503708 663.862671,195.093994 671.847900,190.509949 
	C673.116028,189.781967 674.853149,188.177353 674.744812,187.149704 
	C674.589600,185.677429 673.200806,183.548187 671.877319,183.117569 
	C664.861572,180.834991 657.644470,179.176575 650.608093,176.950958 
	C644.035278,174.871933 637.426331,172.739777 631.164307,169.900864 
	C626.587708,167.826050 625.045410,163.391922 624.936340,158.325974 
	C624.795532,151.787903 628.484375,147.852005 633.509521,144.858704 
	C645.848450,137.508865 659.035583,137.983322 672.353760,141.286682 
	C676.322144,142.270966 680.199646,143.818680 683.927612,145.525711 
	C688.686218,147.704681 688.906555,149.040573 685.818604,153.379623 
	C684.683472,154.974594 683.334534,156.417450 682.660767,157.231659 
	C675.214966,154.928574 668.263000,152.636124 661.225891,150.644226 
	C655.036255,148.892181 649.077271,150.497086 643.447388,152.995056 
	C641.966492,153.652145 640.421997,155.761169 640.292725,157.318497 
	C640.203735,158.390884 642.317810,160.333237 643.765625,160.732437 
	C652.007996,163.005112 660.378967,164.808289 668.637329,167.027542 
	C672.598511,168.092056 676.408203,169.720428 680.613892,171.276703 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M491.845215,193.000076 
	C493.766235,193.109131 495.206512,193.218109 496.801086,193.338760 
	C496.801086,196.867264 496.801086,199.997055 496.801086,203.754059 
	C495.021729,203.836868 493.269501,203.985748 491.517029,203.989044 
	C480.187500,204.010391 468.857880,204.006622 457.528290,203.998184 
	C445.726532,203.989380 438.903290,198.969254 438.267090,187.429306 
	C437.408813,171.862015 438.053986,156.211823 438.053986,140.301331 
	C457.450653,140.301331 476.358063,140.301331 495.633392,140.301331 
	C495.633392,143.649338 495.633392,146.898071 495.633392,150.835114 
	C481.698151,150.835114 467.655212,150.835114 453.314178,150.835114 
	C453.314178,155.757233 453.314178,160.003296 453.314178,164.764099 
	C465.962036,164.764099 478.545471,164.764099 491.561218,164.764099 
	C491.561218,168.626114 491.561218,171.985016 491.561218,175.868927 
	C478.931091,175.868927 466.346283,175.868927 453.063171,175.868927 
	C453.063171,180.118317 452.701294,183.919937 453.154541,187.621735 
	C453.724609,192.277740 457.586700,192.936005 461.374634,192.969208 
	C471.370544,193.056854 481.367767,192.999573 491.845215,193.000076 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1016.999939,151.000000 
	C1009.541870,151.000000 1002.583679,151.000000 995.318481,151.000000 
	C995.318481,155.748489 995.318481,159.994568 995.318481,164.755310 
	C1008.286560,164.755310 1021.202820,164.755310 1034.556519,164.755310 
	C1034.556519,168.618103 1034.556519,171.978943 1034.556519,175.859467 
	C1021.607483,175.859467 1008.689819,175.859467 995.058411,175.859467 
	C995.058411,180.107086 994.402832,184.016052 995.234985,187.577393 
	C996.145203,191.472717 999.614624,193.039764 1003.703064,193.019501 
	C1015.184814,192.962570 1026.666992,192.999634 1038.573242,192.999634 
	C1038.573242,196.651855 1038.573242,200.012497 1038.573242,203.999847 
	C1025.042603,203.999847 1011.602356,204.040634 998.162903,203.954376 
	C996.223083,203.941925 994.232300,203.503082 992.359436,202.945145 
	C985.827271,200.999283 980.581238,195.149338 980.869324,186.948456 
	C981.348633,173.303345 981.000000,159.629135 981.000000,145.967178 
	C981.000000,144.174530 981.000000,142.381882 981.000000,140.305176 
	C1000.109314,140.305176 1018.683228,140.305176 1037.629395,140.305176 
	C1037.629395,143.638062 1037.629395,146.884796 1037.629395,151.000000 
	C1031.001709,151.000000 1024.250854,151.000000 1016.999939,151.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M510.241364,48.004646 
	C528.532898,48.001102 546.347961,48.001102 564.580688,48.001102 
	C564.580688,51.667225 564.580688,55.028381 564.580688,58.910927 
	C550.220337,58.910927 535.967834,58.910927 521.362427,58.910927 
	C521.362427,63.993542 521.362427,68.572899 521.362427,73.846817 
	C534.320618,73.846817 547.365540,73.846817 560.696533,73.846817 
	C560.696533,77.442345 560.696533,80.356041 560.696533,83.788895 
	C547.687866,83.788895 534.768311,83.788895 520.629517,83.788895 
	C521.211914,88.714584 520.993103,93.303955 522.537109,97.193588 
	C523.303833,99.125206 527.269043,100.726517 529.854004,100.831367 
	C540.002747,101.243034 550.178040,100.999947 560.343506,101.000008 
	C561.981812,101.000015 563.620056,101.000000 565.627930,101.000000 
	C565.627930,104.635887 565.627930,107.883080 565.627930,111.758621 
	C564.171753,111.834465 562.583435,111.986259 560.994934,111.988922 
	C548.996338,112.009079 536.997803,112.015404 524.999207,111.995476 
	C513.890808,111.977028 507.023285,105.138092 507.005402,94.063873 
	C506.983063,80.232224 506.973938,66.400391 507.030182,52.568928 
	C507.038086,50.625286 506.091766,47.860374 510.241364,48.004646 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M709.691895,177.674866 
	C715.693787,166.001083 721.392395,154.589279 727.444336,143.368011 
	C728.305420,141.771530 730.835388,140.240112 732.671387,140.141739 
	C743.594971,139.556442 743.652466,139.675156 748.517883,149.050629 
	C757.670715,166.688034 766.761780,184.357529 775.855896,202.025330 
	C775.983093,202.272324 775.815430,202.671112 775.700256,203.928009 
	C770.958801,203.928009 766.063416,204.245026 761.259888,203.720764 
	C759.943176,203.577057 758.360718,201.082733 757.825012,199.376694 
	C755.164062,190.902390 749.944702,187.519882 740.909851,188.838516 
	C735.035400,189.695892 728.924561,189.204758 722.938599,188.918915 
	C719.643250,188.761551 717.506836,189.605225 716.569641,192.863419 
	C716.434265,193.334152 716.151489,193.763245 715.930847,194.208618 
	C710.535706,205.102051 710.535645,205.102020 696.511353,203.506195 
	C700.949280,194.791595 705.225403,186.394669 709.691895,177.674866 
M744.114136,172.344986 
	C741.330017,166.738464 738.545959,161.131958 735.461487,154.920364 
	C731.673035,162.687119 728.139343,169.931503 724.319092,177.763443 
	C732.476624,177.763443 739.347534,177.763443 746.653198,177.763443 
	C745.824463,175.932785 745.157654,174.459854 744.114136,172.344986 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M845.306519,104.669907 
	C843.566223,98.189163 839.719360,96.317482 833.455139,96.854660 
	C826.182678,97.478302 818.809753,97.172722 811.492554,96.919998 
	C808.310425,96.810097 806.471497,97.841347 805.604797,100.812950 
	C805.513428,101.126114 805.275391,101.393623 805.156067,101.701920 
	C800.767883,113.042084 798.848267,114.028946 785.764282,111.221001 
	C786.711670,109.068161 787.504639,106.964592 788.530884,104.981796 
	C797.579834,87.498482 806.799988,70.101852 815.639893,52.513939 
	C817.426575,48.959183 819.524231,48.217621 823.234802,47.812428 
	C830.194824,47.052395 833.661926,49.539867 836.708801,55.923176 
	C844.827271,72.931587 853.923279,89.473145 862.610840,106.210602 
	C863.435120,107.798729 864.119568,109.459419 864.882446,111.115662 
	C854.083862,114.063148 849.186707,112.480141 845.306519,104.669907 
M816.182068,85.990601 
	C822.539490,85.990601 828.896912,85.990601 835.968567,85.990601 
	C831.966736,77.991951 828.342407,70.747726 824.369873,62.807537 
	C820.931152,70.250015 817.798767,76.945793 814.790283,83.696815 
	C814.543640,84.250343 815.140808,85.179932 816.182068,85.990601 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249176,152.051559 
	C222.527679,151.130707 222.965332,150.142746 223.717834,149.732986 
	C233.106583,144.620377 242.534271,139.578979 251.977570,134.567535 
	C252.666992,134.201675 253.554413,134.208954 254.732513,134.070114 
	C260.956604,146.476974 266.818359,158.846909 272.629791,171.240448 
	C275.127045,176.566132 277.519806,181.940796 280.266357,187.965057 
	C267.253296,187.965057 254.987122,188.182007 242.735672,187.852341 
	C238.189972,187.730011 235.717728,184.213165 234.067825,180.098328 
	C230.330032,170.776535 226.404083,161.530182 222.249176,152.051559 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M397.753662,101.024918 
	C400.434174,100.000885 402.742035,99.095451 404.954468,97.997734 
	C411.757538,94.622284 411.743896,94.593307 416.056824,101.179230 
	C416.584625,101.985199 417.102692,102.797531 417.619965,103.598740 
	C401.498718,115.709641 377.685974,116.623222 361.628265,105.638184 
	C340.823669,91.405807 344.967499,63.751995 363.631805,53.712292 
	C379.496521,45.178505 395.420197,44.966850 411.409760,53.209690 
	C413.694092,54.387295 417.252045,55.134880 414.926025,59.237434 
	C411.473938,65.326141 410.020966,66.029564 405.666656,63.120274 
	C395.579041,56.380287 380.707794,56.371197 370.991699,64.297913 
	C359.948608,73.307251 360.667542,90.620453 372.917480,97.947754 
	C380.570221,102.525215 388.801941,103.124374 397.753662,101.024918 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1081.439209,52.252560 
	C1099.241699,44.807156 1115.967041,45.691917 1132.442139,56.469551 
	C1130.145874,59.613514 1128.152832,62.342182 1126.286133,64.897858 
	C1120.234131,62.650639 1114.695435,60.032104 1108.868164,58.539330 
	C1093.570435,54.620564 1078.670044,66.628853 1079.000122,82.218872 
	C1079.197144,91.534508 1090.319702,101.598648 1100.587158,102.037605 
	C1108.905273,102.393250 1116.758179,101.362000 1123.649902,96.193481 
	C1124.522949,95.538727 1125.734741,95.335831 1127.037476,94.826706 
	C1129.077393,97.547615 1131.025269,100.145767 1133.243530,103.104568 
	C1128.548584,107.866119 1122.651001,110.195190 1116.668579,111.515991 
	C1101.763306,114.806847 1087.699829,113.381218 1074.907715,103.647064 
	C1061.700928,93.597191 1060.450684,72.347778 1070.438110,61.224789 
	C1073.482422,57.834358 1077.520630,55.336452 1081.439209,52.252560 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M812.682739,203.999908 
	C804.587524,205.673645 800.968872,201.979095 797.848694,195.178741 
	C790.188232,178.482712 781.585999,162.218964 773.376465,145.774368 
	C772.578979,144.176865 771.878296,142.531021 770.727356,140.025223 
	C776.229370,140.025223 781.144653,139.727493 785.979797,140.218689 
	C787.315308,140.354355 788.793884,142.658630 789.558594,144.274658 
	C796.198547,158.304626 802.688599,172.405457 809.236450,186.479095 
	C809.441956,186.920868 809.790833,187.295929 810.485962,188.296631 
	C816.514954,175.647934 822.671509,163.794189 827.864075,151.532272 
	C832.832336,139.799835 833.343384,139.056305 847.649902,140.321014 
	C847.429016,141.235168 847.370544,142.167877 846.991577,142.944138 
	C837.627014,162.125412 828.199646,181.276077 818.871277,200.474884 
	C817.636963,203.015259 816.101257,204.537155 812.682739,203.999908 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M251.974380,62.007042 
	C270.304840,52.278461 288.262054,42.632664 306.991699,32.571957 
	C306.991699,41.522446 307.133911,49.758621 306.852356,57.980282 
	C306.805145,59.357929 305.204956,61.191357 303.842346,61.936779 
	C289.210846,69.941002 274.551697,77.901108 259.757202,85.598671 
	C249.857834,90.749313 244.840424,88.541138 240.932159,77.980179 
	C239.536819,74.209702 238.057785,70.470192 236.927277,67.525032 
	C242.248276,65.554131 246.924698,63.821983 251.974380,62.007042 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M714.143677,110.624794 
	C714.048096,93.365318 714.048096,76.564064 714.048096,59.251740 
	C706.056335,59.251740 698.471680,59.251740 690.445923,59.251740 
	C690.445923,55.388546 690.445923,52.030052 690.445923,48.327461 
	C711.055664,48.327461 731.629211,48.327461 752.602234,48.327461 
	C752.602234,51.709869 752.602234,55.070202 752.602234,58.949272 
	C744.847595,58.949272 737.262634,58.949272 729.179382,58.949272 
	C729.179382,76.694000 729.179382,93.940010 729.179382,112.304283 
	C723.832520,111.867203 719.035950,111.475105 714.143677,110.624794 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1285.848389,102.338936 
	C1285.947998,105.598915 1285.947998,108.407265 1285.947998,111.606552 
	C1267.981079,111.606552 1250.305298,111.606552 1232.313477,111.606552 
	C1232.313477,90.597710 1232.313477,69.713020 1232.313477,48.413387 
	C1237.001953,48.413387 1241.693848,48.413387 1246.872681,48.413387 
	C1246.872681,65.755966 1246.872681,82.984283 1246.872681,101.000000 
	C1254.575439,101.000000 1261.822998,100.888069 1269.065186,101.036240 
	C1274.629761,101.150085 1280.187866,101.591537 1285.848389,102.338936 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1050.000000,60.016029 
	C1050.000000,77.442581 1050.000000,94.372665 1050.000000,111.649452 
	C1044.989014,111.649452 1040.416382,111.649452 1035.419189,111.649452 
	C1035.419189,90.699425 1035.419189,69.700775 1035.419189,48.349678 
	C1040.012695,48.349678 1044.585327,48.349678 1050.000000,48.349678 
	C1050.000000,52.033936 1050.000000,55.776749 1050.000000,60.016029 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M961.000000,169.000000 
	C961.000000,180.773483 961.000000,192.046967 961.000000,203.657181 
	C956.308899,203.657181 952.069153,203.657181 947.410767,203.657181 
	C947.410767,182.684525 947.410767,161.686783 947.410767,140.343216 
	C951.690308,140.343216 955.930054,140.343216 961.000000,140.343216 
	C961.000000,149.698715 961.000000,159.099350 961.000000,169.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,54.116867 
	C678.000000,73.521126 678.000000,92.441673 678.000000,111.679047 
	C673.262085,111.679047 669.020935,111.679047 664.388184,111.679047 
	C664.388184,90.634499 664.388184,69.633865 664.388184,48.315552 
	C668.748962,48.315552 672.990051,48.315552 678.000000,48.315552 
	C678.000000,50.124668 678.000000,51.878914 678.000000,54.116867 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M471.015503,60.540482 
	C476.292450,62.412903 478.619568,65.782463 477.972046,71.001472 
	C477.538452,74.496231 472.237885,78.813034 467.706116,78.934021 
	C460.074707,79.137741 452.433929,78.989838 444.400513,78.989838 
	C444.400513,72.303490 444.400513,65.946365 444.400513,58.511429 
	C453.305542,59.124390 461.988434,59.722061 471.015503,60.540482 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1003.955566,71.457443 
	C1000.932434,77.148933 996.435730,79.329491 990.349487,79.063606 
	C983.876526,78.780815 977.381592,79.000000 970.448853,79.000000 
	C970.448853,72.405083 970.448853,66.044807 970.448853,58.546371 
	C979.203674,59.129112 987.945312,59.222595 996.523254,60.455463 
	C1002.141968,61.262993 1003.930420,64.745682 1003.955566,71.457443 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M626.165771,63.712593 
	C633.530762,68.458107 635.750305,75.117035 635.004639,83.127464 
	C634.337646,90.292038 630.106262,94.895782 624.044312,97.943642 
	C615.016968,102.482460 605.300598,100.762482 595.388306,100.926582 
	C595.388306,86.955299 595.388306,73.278709 595.388306,59.114288 
	C605.906982,58.959404 616.277039,58.229744 626.165771,63.712593 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1186.546875,59.052887 
	C1193.205200,61.813278 1199.377808,64.562103 1202.043701,71.659187 
	C1206.569702,83.708893 1202.541016,93.886070 1190.862671,99.588547 
	C1179.635254,105.070854 1166.029419,102.161766 1158.105835,92.584892 
	C1148.382324,80.832489 1156.230591,61.637703 1172.030640,59.125217 
	C1176.622681,58.395020 1181.429321,59.014778 1186.546875,59.052887 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M407.757446,181.496338 
	C403.165161,189.246613 395.734863,191.717850 388.001923,192.822235 
	C382.326111,193.632843 376.441467,192.981140 370.329712,192.981140 
	C370.329712,178.944626 370.329712,165.372971 370.329712,151.177063 
	C381.760895,151.249985 393.604279,148.824265 403.183563,157.760437 
	C410.521576,164.605774 411.761963,171.677917 407.757446,181.496338 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M869.955200,162.139801 
	C879.870972,146.038162 905.212830,145.761917 914.930420,162.375443 
	C919.678955,170.493607 917.208740,182.845673 909.445984,188.257584 
	C898.702087,195.747879 886.845093,196.137390 876.671387,189.334259 
	C867.693604,183.330872 864.888000,172.321899 869.955200,162.139801 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M744.302490,172.665955 
	C745.157654,174.459854 745.824463,175.932785 746.653198,177.763443 
	C739.347534,177.763443 732.476624,177.763443 724.319092,177.763443 
	C728.139343,169.931503 731.673035,162.687119 735.461487,154.920364 
	C738.545959,161.131958 741.330017,166.738464 744.302490,172.665955 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M815.763977,85.962708 
	C815.140808,85.179932 814.543640,84.250343 814.790283,83.696815 
	C817.798767,76.945793 820.931152,70.250015 824.369873,62.807537 
	C828.342407,70.747726 831.966736,77.991951 835.968567,85.990601 
	C828.896912,85.990601 822.539490,85.990601 815.763977,85.962708 
z"
      />
    </svg>
  );
};

CaDesSavoieSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaDesSavoieSvg;
