// Const
import * as investorConst from '../actions/const/investor.const';
import * as landingConst from '../../pages/landing/const/landing.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';
import * as commonConst from '../actions/const/common.const';
import * as dashboardConst from '../../pages/dashboard/common/const/dashboard.const';

const initState = {
  id: '',
  email: '',
  name: '',
  surname: '',
  title: '',
  address: '',
  city: '',
  country: 'France',
  registered_country: 'France',
  phone: '',
  zip: '',
  iban: '',
  siren: '',
  company_name: '',
  // default : investor can freely chose its civility
  is_a_corporation: investorConst.CIVILITY_FREE_CHOICE,
  is_identity_confirmed: '',
  ibans: [],
  birth_date: '',
  birth_city: '',
  occupation: '',
  data_from_insee: false,
};

const investor = (state = initState, action) => {
  switch (action.type) {
    case investorConst.RESET_INVESTOR_ON_EMAIL_DOES_NOT_EXIST:
      return {
        ...state,
        is_identity_confirmed: '',
        is_a_corporation: null,
      };
    case investorConst.RESET_STATE:
    case 'CLEAR_EVERYTHING':
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_REDUX:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case landingConst.INIT_VIEW_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
    case commonConst.INIT_STATE:
      return initState;

    case landingConst.CLEAR_DATA: {
      return {
        ...initState,
        email: state.email,
      };
    }

    case investorConst.SET_INVESTOR_EMAIL:
      return { ...state, email: action.email };

    case investorConst.INIT_INVESTOR: {
      let investorCurrentIban = '';
      let investorCurrentTradingAccountNumber = '';
      const investorIbans = action.investor.ibans || [];
      investorIbans.find(account => {
        if (account.portfolio_type === action.investor.currentPortfolioType) {
          investorCurrentIban = account.iban;
          investorCurrentTradingAccountNumber = account.trading_account_number;
        }
        return investorCurrentIban;
      });
      return {
        ...state,
        address: action.investor.address,
        city: action.investor.city,
        country: action.investor.country,
        registered_country: action.investor.registered_country,
        email: action.investor.email,
        id: action.investor.id,
        name: action.investor.name,
        phone: action.investor.phone,
        surname: action.investor.surname,
        title: action.investor.title,
        zip: action.investor.zip,
        ibans: action.investor.ibans,
        iban: investorCurrentIban,
        trading_account_number: investorCurrentTradingAccountNumber,
        siren: action.investor.siren,
        company_name: action.investor.company_name,
        is_a_corporation: action.investor.is_a_corporation,
        is_identity_confirmed: action.investor.is_identity_confirmed,
        birth_date: action.investor.birth_date,
        birth_city: action.investor.birth_city,
        data_from_insee: action.investor.data_from_insee,
        occupation: action.investor.occupation,
      };
    }

    case dashboardConst.INIT_INVESTOR: {
      return {
        ...state,
        id: action.investor.id,
        email: action.investor.email,
        name: action.investor.name,
        surname: action.investor.surname,
      };
    }

    case investDetailsConst.SET_INVESTOR_DATA:
      return { ...state, ...action.investor };

    default:
      return state;
  }
};

export default investor;
