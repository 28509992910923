import * as React from 'react';
import PropTypes from 'prop-types';

const CaCentreOuestSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101135,31.018839 
	C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
	C367.080994,31.011070 199.130249,31.011070 31.158731,31.011070 
	C31.158731,61.734028 31.158731,91.854836 31.022224,122.902924 
	C31.067513,128.944077 31.112801,134.985214 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
	C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
	C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
	C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
	C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
	C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
	C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.158731,121.975639 
	C31.158731,91.854836 31.158731,61.734028 31.158731,31.011070 
	C199.130249,31.011070 367.080994,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
	C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
	C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
	C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
	C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
	C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
	C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
	C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
	C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
	C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
	C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
	C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
	C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
	C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
	C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
	C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
	C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
	C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
	C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
	C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396376,141.702835 
	C36.009918,163.400696 49.314964,177.513397 69.920898,184.997040 
	C82.482719,189.559204 95.508400,191.447754 108.911942,190.012558 
	C116.419746,189.208664 124.077576,189.175293 131.426880,187.640808 
	C152.807175,183.176788 172.817673,174.658432 192.637253,165.720596 
	C202.778717,161.147202 212.872711,156.468552 223.012543,152.225418 
	C227.012543,162.371368 231.077652,172.094345 234.918030,181.905289 
	C236.377472,185.633621 239.288757,187.741180 242.748764,187.835632 
	C254.996826,188.169983 267.259583,187.965897 280.268219,187.965897 
	C277.518127,181.935516 275.124420,176.561356 272.627167,171.235748 
	C266.816376,158.843887 260.955688,146.475433 255.422760,133.933289 
	C258.574829,132.157990 261.474976,130.636978 264.254456,128.920517 
	C277.770264,120.573830 291.267181,112.196266 304.720306,103.749207 
	C305.683533,103.144386 306.875824,101.905647 306.891357,100.944397 
	C307.050720,91.058205 306.989105,81.168449 306.989105,70.488319 
	C296.574432,76.321167 287.078918,81.752884 277.476379,86.988243 
	C265.702301,93.407532 253.838638,99.662552 241.984283,105.615341 
	C241.267868,103.389931 240.693832,101.486244 239.876175,99.693695 
	C232.746063,84.062508 225.410416,68.521744 218.550171,52.773621 
	C217.202179,49.679165 215.730209,48.912895 212.728897,48.950974 
	C202.570984,49.079845 192.406006,49.171692 182.253265,48.908886 
	C178.595932,48.814217 176.538559,50.064487 174.882355,53.215729 
	C166.804977,68.584396 158.584976,83.878265 150.385406,99.182510 
	C143.594818,111.856941 136.733093,124.493347 129.966644,137.180603 
	C127.982674,140.900589 126.216621,144.736801 124.021698,149.186722 
	C137.916611,148.512161 150.376190,145.928085 161.847809,139.612183 
	C164.802444,137.985443 168.355682,136.228836 169.923965,133.536011 
	C174.142441,126.292755 177.480637,118.532150 181.087753,110.938675 
	C185.587692,101.465660 190.007050,91.954369 194.779572,81.780991 
	C200.461746,95.681091 205.866150,108.901680 211.044342,122.402802 
	C210.219299,122.951889 209.617554,123.214180 209.022034,123.489899 
	C191.018967,131.824921 173.367401,141.071960 154.895294,148.189499 
	C141.742218,153.257553 127.632759,156.348801 113.675346,158.670242 
	C101.696220,160.662659 89.292488,161.159515 77.145172,160.657318 
	C70.637619,160.388306 64.138557,156.941345 57.892036,154.318008 
	C53.301071,152.389954 52.641037,147.695496 53.181637,143.408020 
	C53.622162,139.914246 54.870789,136.371918 56.454041,133.196640 
	C68.386719,109.265068 88.524773,94.421478 112.345169,84.132912 
	C125.458626,78.468895 139.294983,74.988319 154.239716,74.721825 
	C153.102036,70.654640 151.923447,66.941681 151.030594,63.161232 
	C147.511917,48.262974 138.844070,43.005257 124.071472,46.382454 
	C108.481628,49.946491 93.670235,55.311726 79.904053,63.539467 
	C56.815536,77.338959 38.683601,95.219208 31.158731,121.975639 
M563.520142,140.000000 
	C561.442139,140.000000 559.364136,140.000000 556.752075,140.000000 
	C556.752075,154.674683 556.752075,168.505829 556.752075,183.555161 
	C553.197571,179.484085 550.465576,176.409882 547.793640,173.284256 
	C540.392639,164.626495 533.087585,155.885300 525.588806,147.313339 
	C523.263733,144.655609 520.809814,141.307510 517.749207,140.302521 
	C514.038208,139.083969 509.621948,140.013367 505.406525,140.013367 
	C505.406525,161.637222 505.406525,182.654953 505.406525,203.655228 
	C510.062164,203.655228 514.306396,203.655228 519.061646,203.655228 
	C519.061646,189.463303 519.061646,175.608032 519.061646,160.972305 
	C520.348938,162.049118 521.036316,162.482590 521.544922,163.070663 
	C531.052795,174.064468 540.497986,185.112869 550.059204,196.059860 
	C552.500549,198.855057 554.872925,202.551254 558.037476,203.656998 
	C561.825012,204.980392 566.428223,203.969330 570.673706,203.969330 
	C570.673706,182.288971 570.673706,161.389603 570.673706,140.000000 
	C568.393799,140.000000 566.434875,140.000000 563.520142,140.000000 
M690.452393,140.000000 
	C680.709106,140.000000 670.965881,140.000000 661.386597,140.000000 
	C661.386597,161.813095 661.386597,182.724136 661.386597,203.672119 
	C666.025757,203.672119 670.271790,203.672119 675.199158,203.672119 
	C675.199158,196.315781 675.199158,189.267212 675.199158,182.000000 
	C679.379883,182.000000 682.882507,182.171692 686.359924,181.949646 
	C689.560364,181.745270 691.804810,182.575256 693.931824,185.263641 
	C698.545959,191.095520 703.635559,196.552094 708.561401,202.135071 
	C709.169434,202.824265 709.948975,203.847610 710.674622,203.869583 
	C715.701599,204.021820 720.735413,203.949829 727.037537,203.949829 
	C719.810974,195.364487 713.578857,187.960617 707.145691,180.317871 
	C709.313049,179.423706 711.179138,178.760513 712.951233,177.903015 
	C718.513550,175.211487 723.248840,171.468155 723.878296,164.941971 
	C724.590637,157.557648 724.174988,150.474304 716.624023,145.737488 
	C708.770264,140.810730 700.211548,140.218750 690.452393,140.000000 
M444.504639,48.000000 
	C439.751709,48.000000 434.998779,48.000000 430.369080,48.000000 
	C430.369080,69.776985 430.369080,90.687897 430.369080,111.689835 
	C434.987610,111.689835 439.234039,111.689835 444.163666,111.689835 
	C444.163666,104.352371 444.163666,97.303352 444.163666,90.000328 
	C448.670807,90.000328 452.517670,89.737511 456.302216,90.109436 
	C458.240875,90.299957 460.511414,91.182777 461.855377,92.528358 
	C464.872375,95.548988 467.338074,99.111717 470.152100,102.345963 
	C472.959381,105.572502 475.393677,110.269218 478.937927,111.428986 
	C483.743652,113.001541 489.450439,111.820335 495.925720,111.820335 
	C491.789673,107.133408 488.588715,103.584183 485.477081,99.958298 
	C482.309235,96.266899 479.229340,92.500008 476.549561,89.291351 
	C481.075867,86.186157 485.821625,84.085838 488.956299,80.555138 
	C497.135071,71.343117 494.619568,53.834526 477.114471,50.265427 
	C466.850189,48.172638 456.058105,48.668552 444.504639,48.000000 
M1004.241882,87.352997 
	C1007.928833,85.022614 1012.439148,83.396400 1015.147766,80.229340 
	C1023.172241,70.846649 1020.248779,54.156013 1003.267883,50.199474 
	C995.799683,48.459373 987.887573,48.404526 980.156128,48.074741 
	C972.245728,47.737324 964.309631,48.000000 956.295410,48.000000 
	C956.295410,69.645020 956.295410,90.545654 956.295410,111.589592 
	C960.957825,111.589592 965.318970,111.589592 970.181641,111.589592 
	C970.181641,104.268875 970.181641,97.352692 970.181641,90.000084 
	C974.482056,90.000084 978.310974,89.705193 982.062622,90.116058 
	C984.149841,90.344643 986.569885,91.281647 988.028687,92.721352 
	C991.061829,95.714958 993.467651,99.332527 996.294434,102.550240 
	C999.106995,105.751648 1001.609375,110.321312 1005.170715,111.498238 
	C1009.868164,113.050613 1015.465454,111.880013 1021.932007,111.880013 
	C1015.741943,104.785522 1010.370789,98.798447 1005.243896,92.609100 
	C1004.235291,91.391495 1004.169617,89.392899 1004.241882,87.352997 
M649.680115,72.335068 
	C645.589661,59.822819 636.732300,51.903511 624.087708,50.275890 
	C610.206787,48.489128 596.053772,48.814247 582.018005,48.252277 
	C581.779297,48.242722 581.523865,48.651787 581.349670,48.802139 
	C581.349670,69.765694 581.349670,90.660942 581.349670,112.478905 
	C595.273926,111.963570 608.918335,112.249496 622.351868,110.703331 
	C632.524109,109.532539 641.176025,104.310814 646.750427,94.895294 
	C650.848816,87.972832 649.918762,80.558372 649.680115,72.335068 
M905.564209,199.144440 
	C906.391418,198.606506 907.227112,198.081055 908.044617,197.528732 
	C927.103943,184.651733 927.197388,159.143707 908.069763,146.534515 
	C892.560547,136.310608 872.038513,136.556473 856.485596,146.348328 
	C837.268799,158.446960 837.006287,184.907608 855.948608,197.287521 
	C871.667297,207.560562 888.205017,207.431870 905.564209,199.144440 
M1216.160767,92.533379 
	C1222.785889,79.683235 1218.003540,64.671829 1208.631226,57.524254 
	C1191.629883,44.558186 1173.145142,43.740074 1154.427979,53.928463 
	C1142.934937,60.184433 1138.100586,70.467186 1139.068848,83.292122 
	C1139.895264,94.238777 1146.201660,101.707123 1155.402588,107.180634 
	C1164.341187,112.498055 1174.093140,113.965553 1184.103760,112.889053 
	C1197.660645,111.431213 1209.296997,106.252777 1216.160767,92.533379 
M924.497742,76.000000 
	C918.747192,76.000000 912.996643,76.000000 907.368835,76.000000 
	C907.368835,80.107895 907.368835,83.355942 907.368835,87.097778 
	C913.646179,87.097778 919.568726,87.097778 925.383972,87.097778 
	C927.092957,97.360123 926.744202,98.259567 918.026978,100.851402 
	C910.704041,103.028671 903.145996,103.059921 896.180603,99.830795 
	C888.720154,96.372139 883.732422,90.378494 883.096802,81.878593 
	C882.496216,73.847580 885.935913,67.265266 892.656555,62.976929 
	C903.603943,55.991455 914.717957,56.531311 925.779663,63.183323 
	C931.125061,66.397797 929.595520,65.776421 934.678894,59.506935 
	C937.522156,56.000313 934.230896,54.664959 931.749207,53.364170 
	C915.988220,45.103058 900.150696,45.049084 884.414246,53.300259 
	C874.072144,58.723022 868.368164,67.377678 868.039551,79.146202 
	C867.685120,91.840378 873.029968,101.792389 884.318909,107.550522 
	C901.911194,116.523804 919.490479,114.533546 936.575378,105.620407 
	C937.990784,104.881996 939.788818,103.133919 939.835999,101.799454 
	C940.133545,93.371887 939.986694,84.928627 939.986694,76.000000 
	C934.744385,76.000000 930.119873,76.000000 924.497742,76.000000 
M1131.299438,180.016876 
	C1134.056152,181.105896 1136.976440,181.906586 1139.528931,183.355438 
	C1142.876221,185.255554 1142.817261,188.395645 1139.317017,190.208481 
	C1133.350220,193.298523 1126.899780,195.165802 1120.200684,193.502045 
	C1111.837524,191.424988 1103.621460,188.755508 1094.679443,186.148682 
	C1093.283691,188.121353 1091.130127,191.165009 1088.929077,194.275650 
	C1089.620728,194.919617 1090.037842,195.469559 1090.587769,195.795563 
	C1098.489624,200.480103 1106.964966,203.062393 1116.142822,204.312515 
	C1127.707886,205.887817 1138.529053,204.653824 1148.741577,199.054657 
	C1158.230957,193.852081 1160.207153,180.111145 1151.955566,174.523682 
	C1148.848267,172.419708 1145.625610,170.092010 1142.105957,169.067612 
	C1132.803223,166.360031 1123.264404,164.473282 1113.918335,161.900696 
	C1110.991699,161.095139 1108.379761,159.146408 1105.623779,157.720932 
	C1105.686035,157.104172 1105.748291,156.487396 1105.810547,155.870636 
	C1108.353638,154.516312 1110.778809,152.836838 1113.460693,151.865723 
	C1123.423706,148.258072 1133.022949,150.497345 1142.156250,154.706680 
	C1149.751343,158.207047 1149.610107,158.548187 1153.843872,151.352081 
	C1154.329224,150.527267 1154.578125,149.563416 1154.960327,148.604813 
	C1154.356323,148.051193 1153.938599,147.492996 1153.382446,147.184189 
	C1140.058228,139.784714 1125.885254,136.755249 1111.006958,140.706467 
	C1105.475464,142.175476 1099.259399,144.637924 1095.559326,148.669815 
	C1088.424072,156.444733 1090.228394,168.437408 1103.291870,172.834335 
	C1112.149658,175.815720 1121.401611,177.626236 1131.299438,180.016876 
M1023.632812,140.000000 
	C1021.876709,140.000000 1020.120667,140.000000 1018.010742,140.000000 
	C1018.010742,156.746307 1017.785400,172.724747 1018.108032,188.692123 
	C1018.263611,196.391068 1022.857300,201.548813 1030.338989,202.924133 
	C1035.177124,203.813492 1040.200806,203.888123 1045.146484,203.956223 
	C1055.589844,204.100037 1066.036621,204.000000 1076.575317,204.000000 
	C1076.575317,200.017395 1076.575317,196.656296 1076.575317,192.999603 
	C1064.336060,192.999603 1052.517944,192.908707 1040.702393,193.042084 
	C1035.616943,193.099487 1032.897827,190.492905 1032.178955,185.878448 
	C1031.704834,182.835007 1032.089233,179.657837 1032.089233,175.873749 
	C1045.610474,175.873749 1058.656372,175.873749 1071.651978,175.873749 
	C1071.651978,171.935196 1071.651978,168.686264 1071.651978,164.938416 
	C1058.395996,164.938416 1045.476929,164.938416 1032.405151,164.938416 
	C1032.405151,160.057236 1032.405151,155.694382 1032.405151,150.827148 
	C1047.055542,150.827148 1061.307129,150.827148 1075.717773,150.827148 
	C1075.717773,147.067245 1075.717773,143.820877 1075.717773,140.000000 
	C1058.494751,140.000000 1041.547607,140.000000 1023.632812,140.000000 
M751.794250,203.050613 
	C755.732727,203.367065 759.668823,203.914948 763.610229,203.956436 
	C775.221130,204.078583 786.834167,204.000000 798.592896,204.000000 
	C798.592896,200.051254 798.592896,196.690918 798.592896,192.999710 
	C786.379333,192.999710 774.569336,192.937378 762.760498,193.031845 
	C758.332520,193.067245 755.096191,191.208954 754.265198,186.980225 
	C753.588684,183.537872 754.127686,179.856644 754.127686,175.789825 
	C767.764526,175.789825 780.680847,175.789825 793.700867,175.789825 
	C793.700867,172.031845 793.700867,168.785599 793.700867,164.861298 
	C780.377502,164.861298 767.336060,164.861298 754.351929,164.861298 
	C754.351929,159.926117 754.351929,155.680420 754.351929,150.935760 
	C768.609863,150.935760 782.525940,150.935760 796.596863,150.935760 
	C796.596863,147.059311 796.596863,143.698990 796.596863,140.335602 
	C777.618713,140.335602 759.045715,140.335602 740.000000,140.335602 
	C740.000000,142.462158 740.000000,144.257523 740.000000,146.052887 
	C740.000000,159.214020 740.209717,172.379700 739.924744,185.534653 
	C739.740906,194.022507 742.894653,199.926682 751.794250,203.050613 
M444.000000,158.475464 
	C444.000000,156.067673 444.000000,153.659866 444.000000,150.765350 
	C458.789795,150.765350 472.702332,150.765350 486.693085,150.765350 
	C486.693085,147.014374 486.693085,143.768585 486.693085,140.410217 
	C467.696777,140.410217 449.010101,140.410217 430.000000,140.410217 
	C430.000000,155.622330 430.397461,170.440552 429.878662,185.226624 
	C429.435669,197.850845 437.235138,203.790298 448.497742,203.959412 
	C460.485504,204.139404 472.478119,204.011490 484.468536,203.984344 
	C485.894409,203.981125 487.319794,203.766434 488.640869,203.658340 
	C488.640869,199.910507 488.640869,196.662842 488.640869,193.000000 
	C486.484589,193.000000 484.683960,193.000031 482.883362,192.999985 
	C472.724762,192.999741 462.566040,192.970505 452.407623,193.012543 
	C447.949524,193.031006 444.920746,190.895355 444.177521,186.646530 
	C443.595764,183.320694 444.060913,179.811737 444.060913,175.902740 
	C457.646545,175.902740 470.558533,175.902740 483.583679,175.902740 
	C483.583679,172.031021 483.583679,168.671494 483.583679,164.814148 
	C470.262604,164.814148 457.351410,164.814148 444.000000,164.814148 
	C444.000000,162.688950 444.000000,161.054871 444.000000,158.475464 
M559.365723,58.999996 
	C561.123596,58.999996 562.881470,58.999996 564.674622,58.999996 
	C564.674622,54.980152 564.674622,51.731922 564.674622,48.441040 
	C545.317261,48.441040 526.288574,48.441040 507.000000,48.441040 
	C507.000000,63.869179 506.988373,78.857773 507.003784,93.846329 
	C507.015503,105.256172 513.796082,111.984482 525.280457,111.997200 
	C536.778625,112.009926 548.276855,112.000000 559.775024,112.000000 
	C561.708435,112.000000 563.641846,112.000000 565.707275,112.000000 
	C565.707275,108.044785 565.707275,104.798691 565.707275,101.000000 
	C563.647095,101.000000 561.855713,101.000031 560.064392,100.999992 
	C549.899292,100.999802 539.723267,101.255440 529.576904,100.819351 
	C527.043579,100.710464 523.219360,98.927750 522.422729,96.939186 
	C520.917419,93.181404 521.177917,88.716240 520.636169,83.942245 
	C534.601562,83.942245 547.521851,83.942245 560.596802,83.942245 
	C560.596802,80.395119 560.596802,77.366974 560.596802,73.831650 
	C547.283630,73.831650 534.365601,73.831650 521.282043,73.831650 
	C521.282043,68.737457 521.282043,64.159004 521.282043,59.000000 
	C533.836609,59.000000 546.117493,59.000000 559.365723,58.999996 
M849.500488,81.000534 
	C844.640930,71.592773 839.608643,62.268314 835.010010,52.734661 
	C833.265625,49.118290 831.185974,47.274395 827.114014,47.965630 
	C825.819275,48.185432 824.427490,48.175735 823.128967,47.967136 
	C819.196594,47.335442 817.331238,49.161942 815.583984,52.633614 
	C806.808411,70.069664 797.686401,87.331200 788.724182,104.673691 
	C787.629456,106.792015 786.763611,109.028580 785.725342,111.359268 
	C799.707458,113.734795 800.321594,113.428017 805.419617,101.124596 
	C806.601257,98.272865 808.075928,96.640755 811.635559,96.896744 
	C817.101990,97.289879 822.721008,97.675354 828.090088,96.853821 
	C838.045959,95.330444 844.517639,98.357979 847.289490,108.437393 
	C847.675110,109.839684 849.566650,111.688416 850.901917,111.812317 
	C855.436951,112.233093 860.035034,111.973076 865.623230,111.973076 
	C860.046631,101.229576 854.963989,91.437630 849.500488,81.000534 
M1001.000000,174.441162 
	C1001.000000,163.040970 1001.000000,151.640793 1001.000000,140.377380 
	C996.200806,140.377380 992.291321,140.377380 987.999878,140.377380 
	C987.999878,151.378525 988.245850,162.014648 987.915161,172.632828 
	C987.687012,179.961044 986.408569,186.997055 979.157166,191.369171 
	C969.703247,197.069244 950.939758,194.434891 950.000977,177.866852 
	C949.495239,168.941574 949.286804,159.998505 949.017822,151.061249 
	C948.910034,147.481186 949.000000,143.895172 949.000000,140.344269 
	C943.937134,140.344269 939.693726,140.344269 935.019043,140.344269 
	C935.019043,153.467285 934.393188,166.296951 935.322937,179.012909 
	C935.701782,184.194397 938.571838,189.816940 941.853882,194.036835 
	C949.338135,203.659760 960.429382,205.682266 971.822449,204.890030 
	C987.796692,203.779282 1002.380127,193.784882 1001.000000,174.441162 
M402.083405,49.111458 
	C388.768158,45.835949 376.005371,46.825432 363.803375,53.576466 
	C351.547668,60.357197 345.766235,73.426437 348.856323,87.169197 
	C351.396912,98.468117 359.052246,104.788376 368.890625,109.484619 
	C382.713440,116.082794 406.081390,113.294632 417.609222,103.364845 
	C416.558411,101.897736 415.321564,100.518845 414.482178,98.930000 
	C412.438385,95.061607 410.236053,94.516045 406.403168,97.125992 
	C398.258087,102.672287 389.109955,103.265312 379.734222,100.965034 
	C367.422241,97.944366 360.024872,84.915451 364.144958,73.787773 
	C369.631531,58.969528 389.252625,53.308964 404.320679,62.402977 
	C409.148468,65.316704 411.300812,64.709938 414.089020,60.512699 
	C416.056000,57.551659 416.396851,55.514416 412.766174,53.915478 
	C409.441864,52.451466 406.149506,50.914860 402.083405,49.111458 
M1088.133545,97.549561 
	C1083.656006,93.496140 1079.342163,89.231255 1079.039795,82.736092 
	C1078.298706,66.818375 1093.264404,54.551098 1108.853638,58.532387 
	C1114.682373,60.020996 1120.220947,62.645645 1126.286987,64.899124 
	C1128.147705,62.352230 1130.140991,59.624104 1132.427002,56.495052 
	C1121.933472,49.648952 1111.203003,46.463951 1098.971313,47.773209 
	C1088.044678,48.942757 1078.475342,52.224354 1070.858276,60.571548 
	C1060.588379,71.826012 1061.632690,93.407150 1074.689819,103.496361 
	C1087.429443,113.340309 1101.536865,114.849091 1116.411499,111.551437 
	C1122.301636,110.245621 1127.729980,106.857315 1134.004150,104.142128 
	C1131.054565,100.223801 1129.026001,97.529007 1126.763672,94.523781 
	C1114.505127,102.642471 1102.017700,105.392494 1088.133545,97.549561 
M353.467194,154.111481 
	C344.450287,166.587311 347.701385,184.450470 356.128540,192.866379 
	C370.837738,207.555984 396.887817,208.412018 414.508240,197.660065 
	C415.524719,197.039810 416.642090,195.263611 416.469055,194.234406 
	C415.502869,188.487152 410.524536,186.110062 405.790039,189.119720 
	C396.757599,194.861572 387.309326,195.503952 377.384552,191.984451 
	C366.365387,188.076889 360.263428,175.839401 364.046692,165.194046 
	C367.757294,154.753174 382.434174,147.377899 393.992706,150.600586 
	C399.456177,152.123886 404.643036,154.639343 410.318695,156.846649 
	C412.165283,154.324005 414.158905,151.600571 416.114960,148.928406 
	C402.939331,136.408005 370.000122,134.000854 353.467194,154.111481 
M239.633575,66.002281 
	C237.482376,65.871185 236.429001,66.517990 237.400238,68.883240 
	C238.636002,71.892647 239.804443,74.931313 240.932617,77.982979 
	C244.834976,88.538765 249.860718,90.749863 259.750153,85.602592 
	C274.537201,77.906189 289.188385,69.946320 303.813538,61.945255 
	C305.179810,61.197788 306.802765,59.382595 306.849915,58.013000 
	C307.133148,49.786274 306.990509,41.544891 306.990509,32.386459 
	C301.928406,35.184158 297.689850,37.534515 293.444000,39.871696 
	C283.861603,45.146477 274.424866,50.714203 264.643829,55.591019 
	C256.808655,59.497635 248.553497,62.561962 239.633575,66.002281 
M703.508789,48.000000 
	C699.089905,48.000000 694.670959,48.000000 690.365845,48.000000 
	C690.365845,52.101925 690.365845,55.349903 690.365845,59.093369 
	C698.305176,59.093369 705.887817,59.093369 714.135132,59.093369 
	C714.135132,76.956924 714.135132,94.320580 714.135132,111.653992 
	C719.397095,111.653992 723.974609,111.653992 729.225586,111.653992 
	C729.225586,93.954628 729.225586,76.592987 729.225586,58.738960 
	C737.487793,58.738960 745.072693,58.738960 752.674805,58.738960 
	C752.674805,54.975391 752.674805,51.729904 752.674805,48.000000 
	C736.404724,48.000000 720.454956,48.000000 703.508789,48.000000 
M1173.552368,140.000000 
	C1170.799438,140.000000 1168.046387,140.000000 1165.351562,140.000000 
	C1165.351562,144.073929 1165.351562,147.321854 1165.351562,151.065796 
	C1173.276245,151.065796 1180.859375,151.065796 1189.108398,151.065796 
	C1189.108398,168.928482 1189.108398,186.295120 1189.108398,203.666977 
	C1194.370483,203.666977 1198.947876,203.666977 1204.198975,203.666977 
	C1204.198975,185.979050 1204.198975,168.615387 1204.198975,150.760666 
	C1212.460693,150.760666 1220.046021,150.760666 1227.687012,150.760666 
	C1227.687012,147.000687 1227.687012,143.755295 1227.687012,140.000000 
	C1209.766113,140.000000 1192.153320,140.000000 1173.552368,140.000000 
M623.000000,163.511520 
	C623.000000,159.432144 623.000000,155.352753 623.000000,150.785309 
	C631.434387,150.785309 639.018311,150.785309 646.699890,150.785309 
	C646.699890,147.027130 646.699890,143.782120 646.699890,140.401291 
	C626.041382,140.401291 605.685242,140.401291 585.341675,140.401291 
	C585.341675,144.054794 585.341675,147.302460 585.341675,151.225311 
	C593.374329,151.225311 601.084473,151.225311 609.259644,151.225311 
	C609.259644,169.151718 609.259644,186.393738 609.259644,203.677872 
	C614.014893,203.677872 618.260010,203.677872 623.000000,203.677872 
	C623.000000,190.411057 623.000000,177.459900 623.000000,163.511520 
M1232.000000,60.512497 
	C1232.000000,77.563057 1232.000000,94.613609 1232.000000,111.700455 
	C1250.353882,111.700455 1267.906738,111.700455 1285.589478,111.700455 
	C1285.589478,108.036003 1285.589478,104.679771 1285.589478,100.843040 
	C1272.620239,100.843040 1260.054443,100.843040 1246.851440,100.843040 
	C1246.851440,83.020142 1246.851440,65.675545 1246.851440,48.331413 
	C1241.620728,48.331413 1237.048340,48.331413 1232.000000,48.331413 
	C1232.000000,52.273571 1232.000000,55.896614 1232.000000,60.512497 
M1050.000000,106.366081 
	C1050.000000,86.994118 1050.000000,67.622147 1050.000000,48.373375 
	C1044.536499,48.373375 1039.964111,48.373375 1035.447510,48.373375 
	C1035.447510,69.751602 1035.447510,90.753395 1035.447510,111.797539 
	C1040.352051,111.797539 1044.806763,111.797539 1049.656616,111.797539 
	C1049.779663,110.196228 1049.889893,108.762573 1050.000000,106.366081 
M677.761169,48.917988 
	C673.302979,48.527401 668.844727,48.136814 664.303772,47.738976 
	C664.303772,69.692604 664.303772,90.578331 664.303772,111.585922 
	C668.971924,111.585922 673.330139,111.585922 677.952087,111.585922 
	C677.952087,90.727165 677.952087,70.281067 677.761169,48.917988 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M31.090477,122.439285 
	C38.683601,95.219208 56.815536,77.338959 79.904053,63.539467 
	C93.670235,55.311726 108.481628,49.946491 124.071472,46.382454 
	C138.844070,43.005257 147.511917,48.262974 151.030594,63.161232 
	C151.923447,66.941681 153.102036,70.654640 154.239716,74.721825 
	C139.294983,74.988319 125.458626,78.468895 112.345169,84.132912 
	C88.524773,94.421478 68.386719,109.265068 56.454041,133.196640 
	C54.870789,136.371918 53.622162,139.914246 53.181637,143.408020 
	C52.641037,147.695496 53.301071,152.389954 57.892036,154.318008 
	C64.138557,156.941345 70.637619,160.388306 77.145172,160.657318 
	C89.292488,161.159515 101.696220,160.662659 113.675346,158.670242 
	C127.632759,156.348801 141.742218,153.257553 154.895294,148.189499 
	C173.367401,141.071960 191.018967,131.824921 209.022034,123.489899 
	C209.617554,123.214180 210.219299,122.951889 211.401520,122.368256 
	C221.994492,116.698311 232.004105,111.343452 242.013702,105.988594 
	C253.838638,99.662552 265.702301,93.407532 277.476379,86.988243 
	C287.078918,81.752884 296.574432,76.321167 306.989105,70.488319 
	C306.989105,81.168449 307.050720,91.058205 306.891357,100.944397 
	C306.875824,101.905647 305.683533,103.144386 304.720306,103.749207 
	C291.267181,112.196266 277.770264,120.573830 264.254456,128.920517 
	C261.474976,130.636978 258.574829,132.157990 255.040039,133.905914 
	C253.853775,134.080215 253.276596,133.964294 252.873688,134.177597 
	C243.359451,139.214478 233.848343,144.258118 224.397034,149.411209 
	C223.695114,149.793915 223.447174,151.009232 222.988174,151.837463 
	C212.872711,156.468552 202.778717,161.147202 192.637253,165.720596 
	C172.817673,174.658432 152.807175,183.176788 131.426880,187.640808 
	C124.077576,189.175293 116.419746,189.208664 108.911942,190.012558 
	C95.508400,191.447754 82.482719,189.559204 69.920898,184.997040 
	C49.314964,177.513397 36.009918,163.400696 31.469837,141.240967 
	C31.112801,134.985214 31.067513,128.944077 31.090477,122.439285 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910889,111.579178 
	C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
	C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
	C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
	C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
	C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
	C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
	C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
	C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
	C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
	C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
	C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
	C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
	C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
	C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.984283,105.615341 
	C232.004105,111.343452 221.994492,116.698311 211.627716,122.087715 
	C205.866150,108.901680 200.461746,95.681091 194.779572,81.780991 
	C190.007050,91.954369 185.587692,101.465660 181.087753,110.938675 
	C177.480637,118.532150 174.142441,126.292755 169.923965,133.536011 
	C168.355682,136.228836 164.802444,137.985443 161.847809,139.612183 
	C150.376190,145.928085 137.916611,148.512161 124.021698,149.186722 
	C126.216621,144.736801 127.982674,140.900589 129.966644,137.180603 
	C136.733093,124.493347 143.594818,111.856941 150.385406,99.182510 
	C158.584976,83.878265 166.804977,68.584396 174.882355,53.215729 
	C176.538559,50.064487 178.595932,48.814217 182.253265,48.908886 
	C192.406006,49.171692 202.570984,49.079845 212.728897,48.950974 
	C215.730209,48.912895 217.202179,49.679165 218.550171,52.773621 
	C225.410416,68.521744 232.746063,84.062508 239.876175,99.693695 
	C240.693832,101.486244 241.267868,103.389931 241.984283,105.615341 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M563.998047,140.000000 
	C566.434875,140.000000 568.393799,140.000000 570.673706,140.000000 
	C570.673706,161.389603 570.673706,182.288971 570.673706,203.969330 
	C566.428223,203.969330 561.825012,204.980392 558.037476,203.656998 
	C554.872925,202.551254 552.500549,198.855057 550.059204,196.059860 
	C540.497986,185.112869 531.052795,174.064468 521.544922,163.070663 
	C521.036316,162.482590 520.348938,162.049118 519.061646,160.972305 
	C519.061646,175.608032 519.061646,189.463303 519.061646,203.655228 
	C514.306396,203.655228 510.062164,203.655228 505.406525,203.655228 
	C505.406525,182.654953 505.406525,161.637222 505.406525,140.013367 
	C509.621948,140.013367 514.038208,139.083969 517.749207,140.302521 
	C520.809814,141.307510 523.263733,144.655609 525.588806,147.313339 
	C533.087585,155.885300 540.392639,164.626495 547.793640,173.284256 
	C550.465576,176.409882 553.197571,179.484085 556.752075,183.555161 
	C556.752075,168.505829 556.752075,154.674683 556.752075,140.000000 
	C559.364136,140.000000 561.442139,140.000000 563.998047,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M690.943420,140.000000 
	C700.211548,140.218750 708.770264,140.810730 716.624023,145.737488 
	C724.174988,150.474304 724.590637,157.557648 723.878296,164.941971 
	C723.248840,171.468155 718.513550,175.211487 712.951233,177.903015 
	C711.179138,178.760513 709.313049,179.423706 707.145691,180.317871 
	C713.578857,187.960617 719.810974,195.364487 727.037537,203.949829 
	C720.735413,203.949829 715.701599,204.021820 710.674622,203.869583 
	C709.948975,203.847610 709.169434,202.824265 708.561401,202.135071 
	C703.635559,196.552094 698.545959,191.095520 693.931824,185.263641 
	C691.804810,182.575256 689.560364,181.745270 686.359924,181.949646 
	C682.882507,182.171692 679.379883,182.000000 675.199158,182.000000 
	C675.199158,189.267212 675.199158,196.315781 675.199158,203.672119 
	C670.271790,203.672119 666.025757,203.672119 661.386597,203.672119 
	C661.386597,182.724136 661.386597,161.813095 661.386597,140.000000 
	C670.965881,140.000000 680.709106,140.000000 690.943420,140.000000 
M708.919006,163.872482 
	C709.734924,159.157135 708.670044,154.938202 703.965454,153.178223 
	C694.613098,149.679565 684.878540,151.184006 675.371826,151.103577 
	C675.371826,158.143539 675.371826,164.376236 675.371826,171.000000 
	C682.523071,171.000000 689.309143,170.920929 696.092407,171.025192 
	C701.464050,171.107773 705.659973,169.095428 708.919006,163.872482 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M445.004028,48.000000 
	C456.058105,48.668552 466.850189,48.172638 477.114471,50.265427 
	C494.619568,53.834526 497.135071,71.343117 488.956299,80.555138 
	C485.821625,84.085838 481.075867,86.186157 476.549561,89.291351 
	C479.229340,92.500008 482.309235,96.266899 485.477081,99.958298 
	C488.588715,103.584183 491.789673,107.133408 495.925720,111.820335 
	C489.450439,111.820335 483.743652,113.001541 478.937927,111.428986 
	C475.393677,110.269218 472.959381,105.572502 470.152100,102.345963 
	C467.338074,99.111717 464.872375,95.548988 461.855377,92.528358 
	C460.511414,91.182777 458.240875,90.299957 456.302216,90.109436 
	C452.517670,89.737511 448.670807,90.000328 444.163666,90.000328 
	C444.163666,97.303352 444.163666,104.352371 444.163666,111.689835 
	C439.234039,111.689835 434.987610,111.689835 430.369080,111.689835 
	C430.369080,90.687897 430.369080,69.776985 430.369080,48.000000 
	C434.998779,48.000000 439.751709,48.000000 445.004028,48.000000 
M471.359009,60.761211 
	C462.414856,59.958885 453.470703,59.156559 444.290497,58.333057 
	C444.290497,65.956131 444.290497,72.196053 444.290497,78.905197 
	C452.678101,78.905197 460.818054,79.336617 468.880371,78.732117 
	C473.231262,78.405891 477.016724,75.543106 477.905029,70.793564 
	C478.775116,66.141273 476.627960,62.757488 471.359009,60.761211 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1003.955444,87.553741 
	C1004.169617,89.392899 1004.235291,91.391495 1005.243896,92.609100 
	C1010.370789,98.798447 1015.741943,104.785522 1021.932007,111.880013 
	C1015.465454,111.880013 1009.868164,113.050613 1005.170715,111.498238 
	C1001.609375,110.321312 999.106995,105.751648 996.294434,102.550240 
	C993.467651,99.332527 991.061829,95.714958 988.028687,92.721352 
	C986.569885,91.281647 984.149841,90.344643 982.062622,90.116058 
	C978.310974,89.705193 974.482056,90.000084 970.181641,90.000084 
	C970.181641,97.352692 970.181641,104.268875 970.181641,111.589592 
	C965.318970,111.589592 960.957825,111.589592 956.295410,111.589592 
	C956.295410,90.545654 956.295410,69.645020 956.295410,48.000000 
	C964.309631,48.000000 972.245728,47.737324 980.156128,48.074741 
	C987.887573,48.404526 995.799683,48.459373 1003.267883,50.199474 
	C1020.248779,54.156013 1023.172241,70.846649 1015.147766,80.229340 
	C1012.439148,83.396400 1007.928833,85.022614 1003.955444,87.553741 
M977.560242,59.000000 
	C975.147949,59.000000 972.735596,59.000000 970.327515,59.000000 
	C970.327515,66.049164 970.327515,72.282364 970.327515,78.950165 
	C978.584351,78.950165 986.534973,79.220528 994.455933,78.852928 
	C999.542053,78.616890 1004.772278,72.934998 1004.117920,68.756996 
	C1003.342407,63.805614 999.845032,60.390865 993.825745,60.000130 
	C988.727417,59.669189 983.629333,59.333553 977.560242,59.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M649.800476,72.751564 
	C649.918762,80.558372 650.848816,87.972832 646.750427,94.895294 
	C641.176025,104.310814 632.524109,109.532539 622.351868,110.703331 
	C608.918335,112.249496 595.273926,111.963570 581.349670,112.478905 
	C581.349670,90.660942 581.349670,69.765694 581.349670,48.802139 
	C581.523865,48.651787 581.779297,48.242722 582.018005,48.252277 
	C596.053772,48.814247 610.206787,48.489128 624.087708,50.275890 
	C636.732300,51.903511 645.589661,59.822819 649.800476,72.751564 
M618.228821,99.989288 
	C619.648987,99.572784 621.139465,99.313042 622.477417,98.713203 
	C628.999817,95.789040 633.954590,91.367386 634.941223,83.896492 
	C636.068604,75.360306 633.871948,67.581482 625.807007,63.721165 
	C620.205261,61.039921 613.635498,60.160252 607.390625,59.123783 
	C603.434387,58.467155 599.280579,59.001122 595.355469,59.001122 
	C595.355469,73.465897 595.355469,87.017502 595.355469,101.417625 
	C602.968872,100.945602 610.175415,100.498802 618.228821,99.989288 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M905.248474,199.345154 
	C888.205017,207.431870 871.667297,207.560562 855.948608,197.287521 
	C837.006287,184.907608 837.268799,158.446960 856.485596,146.348328 
	C872.038513,136.556473 892.560547,136.310608 908.069763,146.534515 
	C927.197388,159.143707 927.103943,184.651733 908.044617,197.528732 
	C907.227112,198.081055 906.391418,198.606506 905.248474,199.345154 
M885.981750,150.104706 
	C881.106689,150.744141 876.010498,150.688629 871.398682,152.155579 
	C858.450195,156.274261 853.255371,171.909866 860.641541,183.289307 
	C867.483948,193.830963 882.787537,197.355179 895.099548,190.925385 
	C906.692871,184.870926 910.673523,172.474548 903.952759,161.038681 
	C900.173462,154.607941 893.870239,151.766159 885.981750,150.104706 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1216.038696,92.896507 
	C1209.296997,106.252777 1197.660645,111.431213 1184.103760,112.889053 
	C1174.093140,113.965553 1164.341187,112.498055 1155.402588,107.180634 
	C1146.201660,101.707123 1139.895264,94.238777 1139.068848,83.292122 
	C1138.100586,70.467186 1142.934937,60.184433 1154.427979,53.928463 
	C1173.145142,43.740074 1191.629883,44.558186 1208.631226,57.524254 
	C1218.003540,64.671829 1222.785889,79.683235 1216.038696,92.896507 
M1180.379150,102.000000 
	C1189.795166,101.912872 1196.987061,97.743851 1201.618164,89.668213 
	C1206.075684,81.895134 1204.417969,74.202599 1199.547485,67.319527 
	C1194.763306,60.558540 1180.444702,54.732517 1167.479126,60.636612 
	C1155.080688,66.282562 1149.914673,79.548309 1157.074097,90.892883 
	C1162.124756,98.895844 1169.841675,102.637611 1180.379150,102.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M924.996582,76.000000 
	C930.119873,76.000000 934.744385,76.000000 939.986694,76.000000 
	C939.986694,84.928627 940.133545,93.371887 939.835999,101.799454 
	C939.788818,103.133919 937.990784,104.881996 936.575378,105.620407 
	C919.490479,114.533546 901.911194,116.523804 884.318909,107.550522 
	C873.029968,101.792389 867.685120,91.840378 868.039551,79.146202 
	C868.368164,67.377678 874.072144,58.723022 884.414246,53.300259 
	C900.150696,45.049084 915.988220,45.103058 931.749207,53.364170 
	C934.230896,54.664959 937.522156,56.000313 934.678894,59.506935 
	C929.595520,65.776421 931.125061,66.397797 925.779663,63.183323 
	C914.717957,56.531311 903.603943,55.991455 892.656555,62.976929 
	C885.935913,67.265266 882.496216,73.847580 883.096802,81.878593 
	C883.732422,90.378494 888.720154,96.372139 896.180603,99.830795 
	C903.145996,103.059921 910.704041,103.028671 918.026978,100.851402 
	C926.744202,98.259567 927.092957,97.360123 925.383972,87.097778 
	C919.568726,87.097778 913.646179,87.097778 907.368835,87.097778 
	C907.368835,83.355942 907.368835,80.107895 907.368835,76.000000 
	C912.996643,76.000000 918.747192,76.000000 924.996582,76.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1130.888428,179.988632 
	C1121.401611,177.626236 1112.149658,175.815720 1103.291870,172.834335 
	C1090.228394,168.437408 1088.424072,156.444733 1095.559326,148.669815 
	C1099.259399,144.637924 1105.475464,142.175476 1111.006958,140.706467 
	C1125.885254,136.755249 1140.058228,139.784714 1153.382446,147.184189 
	C1153.938599,147.492996 1154.356323,148.051193 1154.960327,148.604813 
	C1154.578125,149.563416 1154.329224,150.527267 1153.843872,151.352081 
	C1149.610107,158.548187 1149.751343,158.207047 1142.156250,154.706680 
	C1133.022949,150.497345 1123.423706,148.258072 1113.460693,151.865723 
	C1110.778809,152.836838 1108.353638,154.516312 1105.810547,155.870636 
	C1105.748291,156.487396 1105.686035,157.104172 1105.623779,157.720932 
	C1108.379761,159.146408 1110.991699,161.095139 1113.918335,161.900696 
	C1123.264404,164.473282 1132.803223,166.360031 1142.105957,169.067612 
	C1145.625610,170.092010 1148.848267,172.419708 1151.955566,174.523682 
	C1160.207153,180.111145 1158.230957,193.852081 1148.741577,199.054657 
	C1138.529053,204.653824 1127.707886,205.887817 1116.142822,204.312515 
	C1106.964966,203.062393 1098.489624,200.480103 1090.587769,195.795563 
	C1090.037842,195.469559 1089.620728,194.919617 1088.929077,194.275650 
	C1091.130127,191.165009 1093.283691,188.121353 1094.679443,186.148682 
	C1103.621460,188.755508 1111.837524,191.424988 1120.200684,193.502045 
	C1126.899780,195.165802 1133.350220,193.298523 1139.317017,190.208481 
	C1142.817261,188.395645 1142.876221,185.255554 1139.528931,183.355438 
	C1136.976440,181.906586 1134.056152,181.105896 1130.888428,179.988632 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1024.116699,140.000000 
	C1041.547607,140.000000 1058.494751,140.000000 1075.717773,140.000000 
	C1075.717773,143.820877 1075.717773,147.067245 1075.717773,150.827148 
	C1061.307129,150.827148 1047.055542,150.827148 1032.405151,150.827148 
	C1032.405151,155.694382 1032.405151,160.057236 1032.405151,164.938416 
	C1045.476929,164.938416 1058.395996,164.938416 1071.651978,164.938416 
	C1071.651978,168.686264 1071.651978,171.935196 1071.651978,175.873749 
	C1058.656372,175.873749 1045.610474,175.873749 1032.089233,175.873749 
	C1032.089233,179.657837 1031.704834,182.835007 1032.178955,185.878448 
	C1032.897827,190.492905 1035.616943,193.099487 1040.702393,193.042084 
	C1052.517944,192.908707 1064.336060,192.999603 1076.575317,192.999603 
	C1076.575317,196.656296 1076.575317,200.017395 1076.575317,204.000000 
	C1066.036621,204.000000 1055.589844,204.100037 1045.146484,203.956223 
	C1040.200806,203.888123 1035.177124,203.813492 1030.338989,202.924133 
	C1022.857300,201.548813 1018.263611,196.391068 1018.108032,188.692123 
	C1017.785400,172.724747 1018.010742,156.746307 1018.010742,140.000000 
	C1020.120667,140.000000 1021.876709,140.000000 1024.116699,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M751.391235,202.985794 
	C742.894653,199.926682 739.740906,194.022507 739.924744,185.534653 
	C740.209717,172.379700 740.000000,159.214020 740.000000,146.052887 
	C740.000000,144.257523 740.000000,142.462158 740.000000,140.335602 
	C759.045715,140.335602 777.618713,140.335602 796.596863,140.335602 
	C796.596863,143.698990 796.596863,147.059311 796.596863,150.935760 
	C782.525940,150.935760 768.609863,150.935760 754.351929,150.935760 
	C754.351929,155.680420 754.351929,159.926117 754.351929,164.861298 
	C767.336060,164.861298 780.377502,164.861298 793.700867,164.861298 
	C793.700867,168.785599 793.700867,172.031845 793.700867,175.789825 
	C780.680847,175.789825 767.764526,175.789825 754.127686,175.789825 
	C754.127686,179.856644 753.588684,183.537872 754.265198,186.980225 
	C755.096191,191.208954 758.332520,193.067245 762.760498,193.031845 
	C774.569336,192.937378 786.379333,192.999710 798.592896,192.999710 
	C798.592896,196.690918 798.592896,200.051254 798.592896,204.000000 
	C786.834167,204.000000 775.221130,204.078583 763.610229,203.956436 
	C759.668823,203.914948 755.732727,203.367065 751.391235,202.985794 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M444.000000,158.948135 
	C444.000000,161.054871 444.000000,162.688950 444.000000,164.814148 
	C457.351410,164.814148 470.262604,164.814148 483.583679,164.814148 
	C483.583679,168.671494 483.583679,172.031021 483.583679,175.902740 
	C470.558533,175.902740 457.646545,175.902740 444.060913,175.902740 
	C444.060913,179.811737 443.595764,183.320694 444.177521,186.646530 
	C444.920746,190.895355 447.949524,193.031006 452.407623,193.012543 
	C462.566040,192.970505 472.724762,192.999741 482.883362,192.999985 
	C484.683960,193.000031 486.484589,193.000000 488.640869,193.000000 
	C488.640869,196.662842 488.640869,199.910507 488.640869,203.658340 
	C487.319794,203.766434 485.894409,203.981125 484.468536,203.984344 
	C472.478119,204.011490 460.485504,204.139404 448.497742,203.959412 
	C437.235138,203.790298 429.435669,197.850845 429.878662,185.226624 
	C430.397461,170.440552 430.000000,155.622330 430.000000,140.410217 
	C449.010101,140.410217 467.696777,140.410217 486.693085,140.410217 
	C486.693085,143.768585 486.693085,147.014374 486.693085,150.765350 
	C472.702332,150.765350 458.789795,150.765350 444.000000,150.765350 
	C444.000000,153.659866 444.000000,156.067673 444.000000,158.948135 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M558.882080,59.000000 
	C546.117493,59.000000 533.836609,59.000000 521.282043,59.000000 
	C521.282043,64.159004 521.282043,68.737457 521.282043,73.831650 
	C534.365601,73.831650 547.283630,73.831650 560.596802,73.831650 
	C560.596802,77.366974 560.596802,80.395119 560.596802,83.942245 
	C547.521851,83.942245 534.601562,83.942245 520.636169,83.942245 
	C521.177917,88.716240 520.917419,93.181404 522.422729,96.939186 
	C523.219360,98.927750 527.043579,100.710464 529.576904,100.819351 
	C539.723267,101.255440 549.899292,100.999802 560.064392,100.999992 
	C561.855713,101.000031 563.647095,101.000000 565.707275,101.000000 
	C565.707275,104.798691 565.707275,108.044785 565.707275,112.000000 
	C563.641846,112.000000 561.708435,112.000000 559.775024,112.000000 
	C548.276855,112.000000 536.778625,112.009926 525.280457,111.997200 
	C513.796082,111.984482 507.015503,105.256172 507.003784,93.846329 
	C506.988373,78.857773 507.000000,63.869179 507.000000,48.441040 
	C526.288574,48.441040 545.317261,48.441040 564.674622,48.441040 
	C564.674622,51.731922 564.674622,54.980152 564.674622,58.999996 
	C562.881470,58.999996 561.123596,58.999996 558.882080,59.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M849.690918,81.323105 
	C854.963989,91.437630 860.046631,101.229576 865.623230,111.973076 
	C860.035034,111.973076 855.436951,112.233093 850.901917,111.812317 
	C849.566650,111.688416 847.675110,109.839684 847.289490,108.437393 
	C844.517639,98.357979 838.045959,95.330444 828.090088,96.853821 
	C822.721008,97.675354 817.101990,97.289879 811.635559,96.896744 
	C808.075928,96.640755 806.601257,98.272865 805.419617,101.124596 
	C800.321594,113.428017 799.707458,113.734795 785.725342,111.359268 
	C786.763611,109.028580 787.629456,106.792015 788.724182,104.673691 
	C797.686401,87.331200 806.808411,70.069664 815.583984,52.633614 
	C817.331238,49.161942 819.196594,47.335442 823.128967,47.967136 
	C824.427490,48.175735 825.819275,48.185432 827.114014,47.965630 
	C831.185974,47.274395 833.265625,49.118290 835.010010,52.734661 
	C839.608643,62.268314 844.640930,71.592773 849.690918,81.323105 
M814.551514,85.652405 
	C821.435669,85.652405 828.319763,85.652405 835.772034,85.652405 
	C831.995117,78.065170 828.355896,70.754494 824.360901,62.729168 
	C820.791077,70.557922 817.519592,77.732475 814.551514,85.652405 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1001.000000,174.929321 
	C1002.380127,193.784882 987.796692,203.779282 971.822449,204.890030 
	C960.429382,205.682266 949.338135,203.659760 941.853882,194.036835 
	C938.571838,189.816940 935.701782,184.194397 935.322937,179.012909 
	C934.393188,166.296951 935.019043,153.467285 935.019043,140.344269 
	C939.693726,140.344269 943.937134,140.344269 949.000000,140.344269 
	C949.000000,143.895172 948.910034,147.481186 949.017822,151.061249 
	C949.286804,159.998505 949.495239,168.941574 950.000977,177.866852 
	C950.939758,194.434891 969.703247,197.069244 979.157166,191.369171 
	C986.408569,186.997055 987.687012,179.961044 987.915161,172.632828 
	C988.245850,162.014648 987.999878,151.378525 987.999878,140.377380 
	C992.291321,140.377380 996.200806,140.377380 1001.000000,140.377380 
	C1001.000000,151.640793 1001.000000,163.040970 1001.000000,174.929321 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M223.012543,152.225433 
	C223.447174,151.009232 223.695114,149.793915 224.397034,149.411209 
	C233.848343,144.258118 243.359451,139.214478 252.873688,134.177597 
	C253.276596,133.964294 253.853775,134.080215 254.732513,134.070114 
	C260.955688,146.475433 266.816376,158.843887 272.627167,171.235748 
	C275.124420,176.561356 277.518127,181.935516 280.268219,187.965897 
	C267.259583,187.965897 254.996826,188.169983 242.748764,187.835632 
	C239.288757,187.741180 236.377472,185.633621 234.918030,181.905289 
	C231.077652,172.094345 227.012543,162.371368 223.012543,152.225433 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M402.463257,49.260849 
	C406.149506,50.914860 409.441864,52.451466 412.766174,53.915478 
	C416.396851,55.514416 416.056000,57.551659 414.089020,60.512699 
	C411.300812,64.709938 409.148468,65.316704 404.320679,62.402977 
	C389.252625,53.308964 369.631531,58.969528 364.144958,73.787773 
	C360.024872,84.915451 367.422241,97.944366 379.734222,100.965034 
	C389.109955,103.265312 398.258087,102.672287 406.403168,97.125992 
	C410.236053,94.516045 412.438385,95.061607 414.482178,98.930000 
	C415.321564,100.518845 416.558411,101.897736 417.609222,103.364845 
	C406.081390,113.294632 382.713440,116.082794 368.890625,109.484619 
	C359.052246,104.788376 351.396912,98.468117 348.856323,87.169197 
	C345.766235,73.426437 351.547668,60.357197 363.803375,53.576466 
	C376.005371,46.825432 388.768158,45.835949 402.463257,49.260849 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1088.452637,97.733414 
	C1102.017700,105.392494 1114.505127,102.642471 1126.763672,94.523781 
	C1129.026001,97.529007 1131.054565,100.223801 1134.004150,104.142128 
	C1127.729980,106.857315 1122.301636,110.245621 1116.411499,111.551437 
	C1101.536865,114.849091 1087.429443,113.340309 1074.689819,103.496361 
	C1061.632690,93.407150 1060.588379,71.826012 1070.858276,60.571548 
	C1078.475342,52.224354 1088.044678,48.942757 1098.971313,47.773209 
	C1111.203003,46.463951 1121.933472,49.648952 1132.427002,56.495052 
	C1130.140991,59.624104 1128.147705,62.352230 1126.286987,64.899124 
	C1120.220947,62.645645 1114.682373,60.020996 1108.853638,58.532387 
	C1093.264404,54.551098 1078.298706,66.818375 1079.039795,82.736092 
	C1079.342163,89.231255 1083.656006,93.496140 1088.452637,97.733414 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M353.711182,153.841949 
	C370.000122,134.000854 402.939331,136.408005 416.114960,148.928406 
	C414.158905,151.600571 412.165283,154.324005 410.318695,156.846649 
	C404.643036,154.639343 399.456177,152.123886 393.992706,150.600586 
	C382.434174,147.377899 367.757294,154.753174 364.046692,165.194046 
	C360.263428,175.839401 366.365387,188.076889 377.384552,191.984451 
	C387.309326,195.503952 396.757599,194.861572 405.790039,189.119720 
	C410.524536,186.110062 415.502869,188.487152 416.469055,194.234406 
	C416.642090,195.263611 415.524719,197.039810 414.508240,197.660065 
	C396.887817,208.412018 370.837738,207.555984 356.128540,192.866379 
	C347.701385,184.450470 344.450287,166.587311 353.711182,153.841949 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M240.059158,66.001190 
	C248.553497,62.561962 256.808655,59.497635 264.643829,55.591019 
	C274.424866,50.714203 283.861603,45.146477 293.444000,39.871696 
	C297.689850,37.534515 301.928406,35.184158 306.990509,32.386459 
	C306.990509,41.544891 307.133148,49.786274 306.849915,58.013000 
	C306.802765,59.382595 305.179810,61.197788 303.813538,61.945255 
	C289.188385,69.946320 274.537201,77.906189 259.750153,85.602592 
	C249.860718,90.749863 244.834976,88.538765 240.932617,77.982979 
	C239.804443,74.931313 238.636002,71.892647 237.400238,68.883240 
	C236.429001,66.517990 237.482376,65.871185 240.059158,66.001190 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M704.006958,48.000000 
	C720.454956,48.000000 736.404724,48.000000 752.674805,48.000000 
	C752.674805,51.729904 752.674805,54.975391 752.674805,58.738960 
	C745.072693,58.738960 737.487793,58.738960 729.225586,58.738960 
	C729.225586,76.592987 729.225586,93.954628 729.225586,111.653992 
	C723.974609,111.653992 719.397095,111.653992 714.135132,111.653992 
	C714.135132,94.320580 714.135132,76.956924 714.135132,59.093369 
	C705.887817,59.093369 698.305176,59.093369 690.365845,59.093369 
	C690.365845,55.349903 690.365845,52.101925 690.365845,48.000000 
	C694.670959,48.000000 699.089905,48.000000 704.006958,48.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1174.046387,140.000000 
	C1192.153320,140.000000 1209.766113,140.000000 1227.687012,140.000000 
	C1227.687012,143.755295 1227.687012,147.000687 1227.687012,150.760666 
	C1220.046021,150.760666 1212.460693,150.760666 1204.198975,150.760666 
	C1204.198975,168.615387 1204.198975,185.979050 1204.198975,203.666977 
	C1198.947876,203.666977 1194.370483,203.666977 1189.108398,203.666977 
	C1189.108398,186.295120 1189.108398,168.928482 1189.108398,151.065796 
	C1180.859375,151.065796 1173.276245,151.065796 1165.351562,151.065796 
	C1165.351562,147.321854 1165.351562,144.073929 1165.351562,140.000000 
	C1168.046387,140.000000 1170.799438,140.000000 1174.046387,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M623.000000,164.010132 
	C623.000000,177.459900 623.000000,190.411057 623.000000,203.677872 
	C618.260010,203.677872 614.014893,203.677872 609.259644,203.677872 
	C609.259644,186.393738 609.259644,169.151718 609.259644,151.225311 
	C601.084473,151.225311 593.374329,151.225311 585.341675,151.225311 
	C585.341675,147.302460 585.341675,144.054794 585.341675,140.401291 
	C605.685242,140.401291 626.041382,140.401291 646.699890,140.401291 
	C646.699890,143.782120 646.699890,147.027130 646.699890,150.785309 
	C639.018311,150.785309 631.434387,150.785309 623.000000,150.785309 
	C623.000000,155.352753 623.000000,159.432144 623.000000,164.010132 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1232.000000,60.016075 
	C1232.000000,55.896614 1232.000000,52.273571 1232.000000,48.331413 
	C1237.048340,48.331413 1241.620728,48.331413 1246.851440,48.331413 
	C1246.851440,65.675545 1246.851440,83.020142 1246.851440,100.843040 
	C1260.054443,100.843040 1272.620239,100.843040 1285.589478,100.843040 
	C1285.589478,104.679771 1285.589478,108.036003 1285.589478,111.700455 
	C1267.906738,111.700455 1250.353882,111.700455 1232.000000,111.700455 
	C1232.000000,94.613609 1232.000000,77.563057 1232.000000,60.016075 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1050.000000,106.847496 
	C1049.889893,108.762573 1049.779663,110.196228 1049.656616,111.797539 
	C1044.806763,111.797539 1040.352051,111.797539 1035.447510,111.797539 
	C1035.447510,90.753395 1035.447510,69.751602 1035.447510,48.373375 
	C1039.964111,48.373375 1044.536499,48.373375 1050.000000,48.373375 
	C1050.000000,67.622147 1050.000000,86.994118 1050.000000,106.847496 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M677.856628,49.376480 
	C677.952087,70.281067 677.952087,90.727165 677.952087,111.585922 
	C673.330139,111.585922 668.971924,111.585922 664.303772,111.585922 
	C664.303772,90.578331 664.303772,69.692604 664.303772,47.738976 
	C668.844727,48.136814 673.302979,48.527401 677.856628,49.376480 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M708.796631,164.257278 
	C705.659973,169.095428 701.464050,171.107773 696.092407,171.025192 
	C689.309143,170.920929 682.523071,171.000000 675.371826,171.000000 
	C675.371826,164.376236 675.371826,158.143539 675.371826,151.103577 
	C684.878540,151.184006 694.613098,149.679565 703.965454,153.178223 
	C708.670044,154.938202 709.734924,159.157135 708.796631,164.257278 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M471.736572,60.898499 
	C476.627960,62.757488 478.775116,66.141273 477.905029,70.793564 
	C477.016724,75.543106 473.231262,78.405891 468.880371,78.732117 
	C460.818054,79.336617 452.678101,78.905197 444.290497,78.905197 
	C444.290497,72.196053 444.290497,65.956131 444.290497,58.333057 
	C453.470703,59.156559 462.414856,59.958885 471.736572,60.898499 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M978.045715,59.000000 
	C983.629333,59.333553 988.727417,59.669189 993.825745,60.000130 
	C999.845032,60.390865 1003.342407,63.805614 1004.117920,68.756996 
	C1004.772278,72.934998 999.542053,78.616890 994.455933,78.852928 
	C986.534973,79.220528 978.584351,78.950165 970.327515,78.950165 
	C970.327515,72.282364 970.327515,66.049164 970.327515,59.000000 
	C972.735596,59.000000 975.147949,59.000000 978.045715,59.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M617.805420,100.020645 
	C610.175415,100.498802 602.968872,100.945602 595.355469,101.417625 
	C595.355469,87.017502 595.355469,73.465897 595.355469,59.001122 
	C599.280579,59.001122 603.434387,58.467155 607.390625,59.123783 
	C613.635498,60.160252 620.205261,61.039921 625.807007,63.721165 
	C633.871948,67.581482 636.068604,75.360306 634.941223,83.896492 
	C633.954590,91.367386 628.999817,95.789040 622.477417,98.713203 
	C621.139465,99.313042 619.648987,99.572784 617.805420,100.020645 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M886.391235,150.247314 
	C893.870239,151.766159 900.173462,154.607941 903.952759,161.038681 
	C910.673523,172.474548 906.692871,184.870926 895.099548,190.925385 
	C882.787537,197.355179 867.483948,193.830963 860.641541,183.289307 
	C853.255371,171.909866 858.450195,156.274261 871.398682,152.155579 
	C876.010498,150.688629 881.106689,150.744141 886.391235,150.247314 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1179.918213,102.000000 
	C1169.841675,102.637611 1162.124756,98.895844 1157.074097,90.892883 
	C1149.914673,79.548309 1155.080688,66.282562 1167.479126,60.636612 
	C1180.444702,54.732517 1194.763306,60.558540 1199.547485,67.319527 
	C1204.417969,74.202599 1206.075684,81.895134 1201.618164,89.668213 
	C1196.987061,97.743851 1189.795166,101.912872 1179.918213,102.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M814.399780,85.279709 
	C817.519592,77.732475 820.791077,70.557922 824.360901,62.729168 
	C828.355896,70.754494 831.995117,78.065170 835.772034,85.652405 
	C828.319763,85.652405 821.435669,85.652405 814.399780,85.279709 
z"
      />
    </svg>
  );
};

CaCentreOuestSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaCentreOuestSvg;
