import * as React from 'react';
import PropTypes from 'prop-types';

const CaBriePicardieSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
 M1029.000000,256.000000 
   C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
   C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
   C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
   C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
   C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
 M535.031738,31.011070 
   C367.081024,31.011070 199.130280,31.011070 31.158705,31.011070 
   C31.158705,61.733967 31.158705,91.854767 31.022192,122.902153 
   C31.067490,128.943558 31.112789,134.984970 31.011166,141.950104 
   C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
   C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
   C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
   C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
   C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
   C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
   C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
   C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
   C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
   C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
   C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
   C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M31.158705,121.975563 
   C31.158705,91.854767 31.158705,61.733967 31.158705,31.011070 
   C199.130280,31.011070 367.081024,31.011070 535.351440,31.381102 
   C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
   C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
   C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
   C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
   C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
   C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
   C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
   C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
   C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
   C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
   C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
   C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
   C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
   C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
   C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
   C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
   C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
   C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
   C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
   C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
   C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
   C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
   C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
   C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
   C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
   C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
   C30.973221,195.018967 31.007444,168.484467 31.396376,141.702835 
   C33.838200,153.473358 39.009472,164.324631 48.020218,172.170303 
   C68.104706,189.657974 92.682571,192.806717 117.854553,189.595917 
   C132.444336,187.734909 147.099899,183.871750 160.863541,178.640717 
   C181.619507,170.752182 201.621338,160.879379 222.249176,152.051575 
   C226.404083,161.530212 230.330017,170.776566 234.067825,180.098358 
   C235.717758,184.213211 238.190018,187.730011 242.735703,187.852341 
   C254.987137,188.182007 267.253296,187.965073 280.266327,187.965073 
   C277.519806,181.940796 275.127045,176.566132 272.629791,171.240448 
   C266.818390,158.846909 260.956604,146.476974 255.422760,133.933273 
   C258.574646,132.158020 261.474579,130.636993 264.253937,128.920670 
   C277.769043,120.574715 291.265320,112.197884 304.717865,103.751709 
   C305.681458,103.146713 306.875549,101.908936 306.891052,100.948097 
   C307.050537,91.061440 306.988922,81.171219 306.988922,70.489708 
   C296.578278,76.319153 287.084869,81.751724 277.481567,86.982521 
   C265.708588,93.395126 253.843628,99.638916 241.986084,105.585068 
   C241.263474,103.362656 240.683777,101.460434 239.865601,99.667007 
   C232.732422,84.030983 225.390778,68.486588 218.534088,52.730873 
   C217.173096,49.603519 215.653046,48.890915 212.680298,48.954121 
   C205.852615,49.099297 198.897064,49.818752 192.216293,48.817684 
   C180.767303,47.102131 173.603333,50.980350 170.338684,62.147644 
   C169.846237,63.832115 168.466995,65.240997 167.611221,66.838036 
   C154.957672,90.451729 142.307709,114.067383 129.701248,137.706223 
   C127.811172,141.250351 126.146080,144.914444 124.037666,149.215057 
   C140.419083,148.287491 154.974106,144.862228 167.890030,136.003174 
   C168.787979,135.387268 169.653351,134.493301 170.129028,133.528946 
   C173.848495,125.988388 177.500351,118.413940 181.111938,110.820869 
   C185.599594,101.385948 190.028702,91.923180 194.797913,81.804504 
   C195.739883,84.022636 196.409958,85.486656 196.990402,86.985413 
   C201.626892,98.957397 206.246429,110.935951 210.512726,122.962891 
   C193.228043,130.975784 176.711273,140.032135 159.251114,146.568497 
   C144.202423,152.202103 128.228119,155.762131 112.380043,158.725250 
   C101.272697,160.802002 89.658478,161.131119 78.330185,160.694672 
   C71.734192,160.440552 64.982780,157.753906 58.806889,155.019760 
   C53.446774,152.646790 52.397488,147.631470 53.604748,141.687439 
   C55.497108,132.370255 60.241505,124.435608 66.203468,117.513878 
   C83.444824,97.496964 105.090736,84.243446 130.842941,78.080048 
   C138.385452,76.274857 146.140747,75.358826 154.024979,73.996117 
   C153.025986,70.294777 151.905991,66.550468 151.009995,62.753315 
   C147.623413,48.401260 138.627228,43.034035 124.134491,46.355972 
   C108.544182,49.929485 93.716553,55.284298 79.939659,63.514080 
   C56.832561,77.317360 38.691277,95.204956 31.158705,121.975563 
 M356.000000,155.503555 
   C356.000000,171.565460 356.000000,187.627350 356.000000,204.000000 
   C366.957947,204.000000 377.102448,204.248840 387.227753,203.914810 
   C394.286438,203.681946 401.434906,203.227173 408.335419,201.838654 
   C418.490875,199.795212 424.363190,189.788361 420.738434,180.498932 
   C419.255554,176.698608 415.423615,173.814880 412.457520,170.271896 
   C412.721954,169.967957 413.332428,169.207062 414.003510,168.504044 
   C422.076202,160.047150 420.459808,148.736374 409.847992,144.152374 
   C404.403809,141.800629 398.192352,140.478287 392.252502,140.178329 
   C380.358124,139.577652 368.412079,140.000000 356.000000,140.000000 
   C356.000000,145.260208 356.000000,149.882690 356.000000,155.503555 
 M986.001587,189.501556 
   C983.423889,186.436127 980.846191,183.370697 978.015869,180.004837 
   C979.992432,179.222656 981.317200,178.587204 982.707031,178.167465 
   C989.027954,176.258530 993.736084,172.337753 994.776550,165.798889 
   C995.944580,158.458481 995.745483,150.747574 988.464844,146.208115 
   C984.528503,143.753754 979.802368,141.523987 975.296021,141.220779 
   C961.579834,140.297882 947.797424,140.352951 934.039978,140.067398 
   C933.445618,140.055054 932.843018,140.441055 932.324097,140.614777 
   C932.324097,161.728638 932.324097,182.616867 932.324097,203.565552 
   C937.348999,203.565552 942.043152,203.565552 947.223267,203.565552 
   C947.223267,196.223068 947.223267,189.310226 947.223267,182.007828 
   C952.017639,182.007828 956.317993,181.925293 960.610901,182.067703 
   C961.642944,182.101944 962.950684,182.614899 963.619019,183.364639 
   C967.350464,187.550705 971.085999,191.752014 974.517273,196.182632 
   C980.841248,204.348373 986.302185,206.172760 997.458984,202.921005 
   C993.668335,198.450943 990.087708,194.228485 986.001587,189.501556 
 M501.000000,158.712952 
   C502.059265,151.958801 495.154388,143.357300 486.472961,142.306427 
   C470.634186,140.389191 454.601501,140.074219 438.364471,139.073273 
   C438.364471,161.576172 438.364471,182.581009 438.364471,203.701523 
   C442.974182,203.701523 447.214905,203.701523 452.129211,203.701523 
   C452.129211,196.384903 452.129211,189.343491 452.129211,182.005005 
   C457.278778,182.005005 461.753540,181.956497 466.225494,182.046188 
   C467.112976,182.063995 468.278412,182.351105 468.825531,182.956802 
   C472.924225,187.494003 477.157013,191.946625 480.853638,196.801971 
   C486.363525,204.039047 494.008423,206.395325 503.252625,202.764893 
   C496.849304,195.253220 490.559021,187.874176 483.911163,180.075623 
   C494.080231,176.716568 502.185669,171.874191 501.000000,158.712952 
 M486.499512,100.999443 
   C483.018799,96.938934 479.538086,92.878426 475.812927,88.532753 
   C478.282318,87.507401 479.968048,86.861076 481.609558,86.117157 
   C492.472626,81.194176 496.438934,70.256714 490.860687,59.771114 
   C486.992157,52.499432 479.815247,49.729530 472.346497,49.174038 
   C459.623138,48.227741 446.821716,48.320786 434.052002,48.024239 
   C432.795837,47.995068 431.532043,48.293179 430.335083,48.432037 
   C430.335083,69.730331 430.335083,90.629471 430.335083,111.550140 
   C435.046417,111.550140 439.408356,111.550140 444.258820,111.550140 
   C444.258820,104.188507 444.258820,97.271721 444.258820,90.000748 
   C448.736115,90.000748 452.728973,89.766159 456.672119,90.106926 
   C458.449127,90.260498 460.534210,91.161957 461.769012,92.420944 
   C464.761383,95.471916 467.261566,98.998398 470.101105,102.208511 
   C473.028198,105.517570 475.582520,110.221375 479.279663,111.515076 
   C483.912018,113.136032 489.542297,111.905075 495.804871,111.905075 
   C492.296722,107.759308 489.651306,104.633095 486.499512,100.999443 
 M999.528198,106.025757 
   C1002.235046,108.607559 1003.472534,112.999184 1008.811401,112.124573 
   C1012.701172,111.487381 1016.779236,112.000000 1022.081482,112.000000 
   C1017.798828,107.144836 1014.601746,103.597816 1011.493286,99.974754 
   C1008.321106,96.277458 1005.236145,92.505394 1002.543579,89.281631 
   C1007.051086,86.205124 1011.810974,84.124901 1014.935791,80.596596 
   C1023.112976,71.363419 1020.730835,53.439411 1003.181335,50.380138 
   C988.954529,47.900066 974.161377,48.658794 959.617310,48.028946 
   C958.526672,47.981716 957.419373,48.319431 956.311279,48.479435 
   C956.311279,69.682770 956.311279,90.581001 956.311279,111.574371 
   C960.994995,111.574371 965.354248,111.574371 970.210510,111.574371 
   C970.210510,104.237869 970.210510,97.321686 970.210510,90.351120 
   C978.961548,89.604118 987.264587,88.150314 992.278625,97.610229 
   C993.861023,100.595634 996.731445,102.898338 999.528198,106.025757 
 M631.407043,108.015900 
   C635.369019,105.394089 640.228882,103.519279 643.138672,100.022026 
   C656.195557,84.328667 651.582886,59.049843 629.912231,52.004578 
   C624.036194,50.094238 617.762756,48.845390 611.603333,48.494781 
   C601.565430,47.923389 591.471252,48.340523 581.293701,48.340523 
   C581.293701,69.641136 581.293701,90.541199 581.293701,111.897682 
   C593.687927,111.897682 605.808472,112.216728 617.896240,111.726402 
   C622.186523,111.552383 626.396912,109.409279 631.407043,108.015900 
 M1158.428223,51.854038 
   C1157.524780,52.266663 1156.609009,52.654648 1155.719604,53.095467 
   C1136.526978,62.607567 1131.064941,90.419746 1152.364136,105.472557 
   C1167.458008,116.139938 1189.634033,115.748047 1204.450317,106.029549 
   C1222.845825,93.963341 1223.853027,70.714928 1209.191162,58.084660 
   C1194.430664,45.369526 1177.036621,44.240582 1158.428223,51.854038 
 M1045.128174,203.974289 
   C1047.073730,203.648819 1049.016479,203.305496 1050.965332,203.000732 
   C1062.431152,201.207687 1072.414551,196.687881 1077.930298,185.920776 
   C1085.551392,171.044144 1080.595947,152.522873 1064.293579,145.264465 
   C1059.135620,142.967972 1053.321167,141.328262 1047.717651,141.020691 
   C1036.032959,140.379303 1024.288574,140.826614 1012.392639,140.826614 
   C1012.392639,161.603745 1012.392639,182.621948 1012.392639,203.995895 
   C1023.251404,203.995895 1033.721558,203.995895 1045.128174,203.974289 
 M940.000000,100.081375 
   C940.000000,92.160103 940.000000,84.238838 940.000000,76.337730 
   C928.613586,76.337730 918.035339,76.337730 907.456665,76.337730 
   C907.456665,80.047493 907.456665,83.408562 907.456665,87.268356 
   C913.830505,87.268356 919.751099,87.268356 925.593262,87.268356 
   C926.661621,96.940704 926.287292,98.040169 921.089294,99.879341 
   C913.047424,102.724762 904.900696,103.360649 896.875793,100.025322 
   C888.943237,96.728348 883.944946,90.693535 883.130920,82.107338 
   C882.374451,74.127907 885.727600,67.448730 892.481750,63.120228 
   C903.578552,56.008606 914.859253,56.339951 925.973511,63.401196 
   C929.959473,65.933586 931.492798,65.156387 934.903687,59.321003 
   C937.016418,55.706406 934.281433,54.695869 931.935059,53.465572 
   C915.826233,45.019318 899.723267,45.008892 883.715881,53.690643 
   C864.071655,64.344833 863.054199,90.332916 878.073730,103.295380 
   C894.151611,117.171120 919.660706,115.445984 936.836304,105.413185 
   C938.267151,104.577393 938.961853,102.481415 940.000000,100.081375 
 M553.007507,142.768051 
   C553.005005,156.089310 552.999390,169.410568 553.000854,182.731827 
   C553.002502,197.394440 559.533142,203.994095 574.044067,203.999237 
   C584.867676,204.003082 595.691223,204.010818 606.514832,203.989029 
   C608.266663,203.985504 610.018311,203.837204 611.625122,203.762543 
   C611.625122,199.879395 611.625122,196.631927 611.625122,193.000000 
   C609.448730,193.000000 607.644409,193.000061 605.840088,192.999985 
   C596.015625,192.999588 586.190613,193.057159 576.366882,192.969437 
   C572.579041,192.935623 568.721985,192.274643 568.153992,187.620377 
   C567.702393,183.919861 568.062988,180.120209 568.062988,175.879959 
   C581.337280,175.879959 593.916687,175.879959 606.572876,175.879959 
   C606.572876,172.006195 606.572876,168.648407 606.572876,164.794495 
   C593.574524,164.794495 580.996704,164.794495 568.292297,164.794495 
   C568.292297,160.044693 568.292297,155.801041 568.292297,150.886612 
   C582.604187,150.886612 596.640137,150.886612 610.666260,150.886612 
   C610.666260,146.956879 610.666260,143.711487 610.666260,140.041763 
   C591.891052,140.041763 573.445557,140.023422 555.000732,140.117111 
   C554.346802,140.120438 553.698486,141.234055 553.007507,142.768051 
 M1149.633301,165.000000 
   C1147.877563,165.000000 1146.121826,165.000000 1144.322876,165.000000 
   C1144.322876,159.980164 1144.322876,155.736221 1144.322876,150.825653 
   C1158.667725,150.825653 1172.705200,150.825653 1186.634521,150.825653 
   C1186.634521,146.897751 1186.634521,143.650192 1186.634521,140.289978 
   C1167.700562,140.289978 1149.133301,140.289978 1130.549438,140.289978 
   C1130.327148,141.010818 1130.063721,141.467987 1130.062744,141.925720 
   C1130.028687,157.745178 1129.856812,173.567154 1130.095703,189.382858 
   C1130.178101,194.839447 1132.887451,199.648911 1138.196045,201.429306 
   C1142.934448,203.018478 1148.129272,203.738342 1153.151489,203.890671 
   C1164.579590,204.237335 1176.025513,204.000000 1187.585938,204.000000 
   C1187.585938,200.033371 1187.585938,196.675034 1187.585938,192.999710 
   C1175.701660,192.999710 1164.228271,192.936417 1152.756104,193.031677 
   C1148.329712,193.068420 1145.095825,191.206696 1144.265259,186.980698 
   C1143.588867,183.539444 1144.127808,179.859283 1144.127808,175.799408 
   C1157.756104,175.799408 1170.668701,175.799408 1183.710449,175.799408 
   C1183.710449,172.048065 1183.710449,168.803833 1183.710449,165.000000 
   C1172.487061,165.000000 1161.543945,165.000000 1149.633301,165.000000 
 M543.498718,84.000000 
   C549.252686,84.000000 555.006653,84.000000 560.628052,84.000000 
   C560.628052,80.223572 560.628052,77.306335 560.628052,73.892960 
   C547.347961,73.892960 534.431458,73.892960 521.427185,73.892960 
   C521.427185,68.673416 521.427185,63.978172 521.427185,58.784920 
   C536.099548,58.784920 550.350403,58.784920 564.697021,58.784920 
   C564.697021,55.023430 564.697021,51.777351 564.697021,48.417343 
   C545.363831,48.417343 526.336426,48.417343 507.000000,48.417343 
   C507.000000,63.480366 506.999664,78.128151 507.000488,92.775932 
   C507.000580,94.108925 506.902771,95.451965 507.028961,96.773285 
   C507.850372,105.372330 514.907837,111.851448 523.708069,111.980217 
   C530.371704,112.077728 537.037903,111.999901 543.703003,111.999977 
   C550.977600,112.000061 558.252136,112.000000 565.555237,112.000000 
   C565.555237,107.976952 565.555237,104.615814 565.555237,100.999527 
   C553.291138,100.999527 541.460510,101.252014 529.656738,100.801620 
   C527.076538,100.703171 523.285461,98.661903 522.358215,96.517967 
   C520.822815,92.967827 521.160278,88.607681 520.642456,84.000000 
   C528.583618,84.000000 535.541565,84.000000 543.498718,84.000000 
 M897.878296,154.634323 
   C896.904602,152.713867 895.614014,150.886993 895.014099,148.856110 
   C892.859192,141.560944 888.402405,138.703705 880.706848,139.831482 
   C877.429871,140.311722 875.458801,140.890045 873.857422,144.090515 
   C868.089966,155.616730 861.846375,166.903458 855.913818,178.348633 
   C851.596191,186.678360 847.485291,195.115250 843.171387,203.725754 
   C857.512512,204.731323 857.512512,204.731323 863.653442,192.735931 
   C863.729309,192.587784 863.838318,192.450729 863.885010,192.294495 
   C864.663086,189.692886 866.305481,188.807007 869.078003,188.931030 
   C874.393738,189.168793 879.804504,189.575699 885.037720,188.877258 
   C895.175354,187.524307 902.351685,190.130951 905.088196,200.896942 
   C905.399536,202.121613 907.147156,203.763336 908.326416,203.847824 
   C913.053833,204.186523 917.819702,203.987778 923.663757,203.987778 
   C914.819458,187.149246 906.466248,171.245651 897.878296,154.634323 
 M679.952332,202.179489 
   C679.952332,196.586716 679.952332,190.993942 679.952332,185.000000 
   C682.857361,185.000000 684.994263,185.069748 687.125305,184.987549 
   C695.061707,184.681412 703.116211,185.060318 710.898804,183.788956 
   C719.296082,182.417160 726.883789,178.437531 729.215576,169.244431 
   C731.335632,160.886139 731.283936,152.394897 723.170166,146.896133 
   C719.204285,144.208405 714.376343,141.570190 709.780640,141.261627 
   C695.572510,140.307587 681.288452,140.480560 667.033386,140.248093 
   C666.787842,140.244095 666.535767,140.643341 666.342651,140.807022 
   C666.342651,161.754501 666.342651,182.649246 666.342651,204.355530 
   C671.078552,203.909683 675.419922,203.500961 679.952332,202.179489 
 M844.503479,71.005196 
   C841.776062,65.902184 838.457275,61.017132 836.475220,55.639420 
   C834.144958,49.317223 830.443970,46.662144 823.878418,47.991665 
   C823.558533,48.056438 823.206238,48.047077 822.882324,47.992809 
   C819.188293,47.374104 817.379089,49.126423 815.717529,52.413319 
   C806.836243,69.982521 797.639465,87.392052 788.599731,104.881554 
   C787.530090,106.951080 786.753723,109.172211 785.758240,111.519272 
   C800.243652,113.245499 800.286926,113.263824 805.521484,100.905113 
   C806.672974,98.186516 808.089722,96.749687 811.375549,96.912544 
   C817.521301,97.217133 823.784241,97.668732 829.832153,96.842232 
   C838.756897,95.622589 844.162292,98.699577 846.775024,107.291046 
   C847.297729,109.009567 848.856018,111.547783 850.159058,111.691406 
   C854.956726,112.220207 859.847839,111.901344 865.508850,111.901344 
   C858.294678,97.822998 851.588989,84.736900 844.503479,71.005196 
 M805.412292,139.958084 
   C801.127747,141.049957 796.637695,141.647858 792.597778,143.327988 
   C781.282288,148.033981 774.447021,156.889252 773.209534,168.901657 
   C772.015442,180.492172 776.459473,190.222412 786.473022,197.025024 
   C792.476990,201.103790 798.980530,203.202698 806.168701,204.277786 
   C819.813965,206.318665 831.788269,202.875366 843.288879,195.134872 
   C841.972229,193.670029 840.702026,192.726929 840.064026,191.464371 
   C837.535156,186.459290 834.850525,186.705688 830.332703,189.665085 
   C819.825562,196.547775 804.643555,195.237045 794.996277,187.424988 
   C789.609680,183.063110 786.962646,173.319962 788.932495,166.651337 
   C793.427002,151.435944 812.735901,146.477951 825.405457,152.207703 
   C828.666443,153.682449 831.774353,155.495636 835.132202,157.247086 
   C837.385559,154.117584 839.439697,151.264771 841.841309,147.929413 
   C830.265442,141.172989 819.042358,137.247665 805.412292,139.958084 
 M400.439148,100.195488 
   C395.058228,100.792763 389.641846,102.104172 384.303802,101.838783 
   C373.157562,101.284607 364.070984,92.317627 363.015930,81.822258 
   C362.233398,74.038094 368.010284,63.188320 379.382507,59.695492 
   C388.981201,56.747372 397.557678,58.118862 405.827026,63.289299 
   C410.030792,65.917717 411.178589,65.388496 414.808899,59.478844 
   C417.211609,55.567532 414.080139,54.582260 411.653961,53.320511 
   C395.687500,45.016964 379.743530,45.083336 363.859863,53.586025 
   C351.530701,60.185955 345.803314,73.424393 348.839325,87.130051 
   C351.446655,98.900642 359.369598,105.851517 369.802246,109.769615 
   C386.615692,116.084084 402.715820,113.713631 417.787384,103.809792 
   C411.786591,94.565170 411.786041,94.564087 402.488007,99.196434 
   C402.041840,99.418739 401.620117,99.690140 400.439148,100.195488 
 M1126.947632,107.535454 
   C1129.062988,106.183990 1131.178467,104.832520 1133.442505,103.386093 
   C1131.601807,100.933044 1130.118652,99.331749 1129.078857,97.481224 
   C1127.579346,94.812164 1125.743652,94.550652 1123.493896,96.261116 
   C1116.459106,101.609787 1108.369507,102.489235 1099.922241,102.002602 
   C1089.848999,101.422295 1079.157349,91.245895 1079.000000,82.051704 
   C1078.737183,66.694214 1093.516846,54.664852 1108.557983,58.426682 
   C1114.545532,59.924156 1120.233521,62.618984 1126.353882,64.877991 
   C1128.226074,62.261574 1130.182617,59.527149 1132.404785,56.421597 
   C1121.601318,49.571857 1110.730957,46.324814 1098.292358,47.841003 
   C1087.727295,49.128807 1078.455811,52.292912 1071.092041,60.351738 
   C1060.521484,71.919991 1061.179321,92.415848 1073.294800,102.396675 
   C1089.387939,115.654327 1107.563354,116.244728 1126.947632,107.535454 
 M307.000000,38.733582 
   C307.000000,36.945942 307.000000,35.158306 307.000000,32.388245 
   C297.003937,37.867413 287.897308,43.265293 278.436584,47.947712 
   C264.912964,54.640999 252.747101,64.399811 236.598221,66.737663 
   C238.050919,70.431847 239.499481,74.036293 240.888870,77.663406 
   C245.189423,88.890274 249.641693,90.765770 260.351685,85.195427 
   C274.879913,77.639206 289.303131,69.875343 303.626862,61.939682 
   C305.231293,61.050797 306.708435,58.529911 306.847900,56.661587 
   C307.268494,51.027294 306.999908,45.341560 307.000000,38.733582 
 M714.000000,78.500427 
   C714.000000,89.576332 714.000000,100.652237 714.000000,111.635223 
   C719.435730,111.635223 724.013245,111.635223 729.084717,111.635223 
   C729.084717,94.034866 729.084717,76.793045 729.084717,58.876625 
   C737.278198,58.876625 744.991821,58.876625 752.656128,58.876625 
   C752.656128,54.940971 752.656128,51.693275 752.656128,48.445839 
   C731.622375,48.445839 710.935608,48.445839 690.385132,48.445839 
   C690.385132,52.148731 690.385132,55.392578 690.385132,59.130894 
   C698.343018,59.130894 705.928223,59.130894 714.000000,59.130894 
   C714.000000,65.589714 714.000000,71.545189 714.000000,78.500427 
 M1254.575684,101.000000 
   C1252.160400,101.000000 1249.745117,101.000000 1246.866333,101.000000 
   C1246.866333,82.975998 1246.866333,65.751091 1246.866333,48.413193 
   C1241.692627,48.413193 1237.001465,48.413193 1232.307861,48.413193 
   C1232.307861,69.708969 1232.307861,90.590050 1232.307861,111.616150 
   C1250.290283,111.616150 1267.962524,111.616150 1285.681396,111.616150 
   C1285.681396,107.984749 1285.681396,104.741135 1285.681396,101.000000 
   C1275.432251,101.000000 1265.493286,101.000000 1254.575684,101.000000 
 M1050.000000,69.500122 
   C1050.000000,62.428074 1050.000000,55.356026 1050.000000,48.348358 
   C1044.587769,48.348358 1040.015259,48.348358 1035.416870,48.348358 
   C1035.416870,69.697388 1035.416870,90.695328 1035.416870,111.652016 
   C1040.413208,111.652016 1044.984985,111.652016 1050.000000,111.652016 
   C1050.000000,97.705917 1050.000000,84.102982 1050.000000,69.500122 
 M1096.000000,154.505188 
   C1096.000000,170.890640 1096.000000,187.276077 1096.000000,203.671082 
   C1101.374268,203.671082 1105.946533,203.671082 1110.602051,203.671082 
   C1110.602051,182.341782 1110.602051,161.343781 1110.602051,140.329163 
   C1105.625122,140.329163 1101.052734,140.329163 1096.000000,140.329163 
   C1096.000000,144.934662 1096.000000,149.221695 1096.000000,154.505188 
 M744.000000,193.474411 
   C744.000000,196.885147 744.000000,200.295868 744.000000,203.651733 
   C749.411743,203.651733 753.984375,203.651733 758.582397,203.651733 
   C758.582397,182.302246 758.582397,161.303757 758.582397,140.348923 
   C753.585083,140.348923 749.013245,140.348923 744.000000,140.348923 
   C744.000000,157.956146 744.000000,175.218246 744.000000,193.474411 
 M678.000000,90.499878 
   C678.000000,76.444206 678.000000,62.388538 678.000000,48.332191 
   C672.955017,48.332191 668.714600,48.332191 664.399841,48.332191 
   C664.399841,69.664398 664.399841,90.661987 664.399841,111.668213 
   C669.044189,111.668213 673.284607,111.668213 678.000000,111.668213 
   C678.000000,104.728584 678.000000,98.114197 678.000000,90.499878 
 M519.000000,144.671143 
   C519.000000,164.252487 519.000000,183.833847 519.000000,203.612183 
   C523.917969,203.612183 528.274841,203.612183 532.996643,203.612183 
   C532.996643,183.288391 533.000305,163.350143 532.985474,143.411926 
   C532.984741,142.455566 532.820984,141.499329 532.684631,140.014755 
   C528.831238,140.014755 525.185120,139.749176 521.624756,140.184586 
   C520.621155,140.307327 519.868713,142.484177 519.000000,144.671143 
 z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
 M242.018433,105.955688 
   C253.843628,99.638916 265.708588,93.395126 277.481567,86.982521 
   C287.084869,81.751724 296.578278,76.319153 306.988922,70.489708 
   C306.988922,81.171219 307.050537,91.061440 306.891052,100.948097 
   C306.875549,101.908936 305.681458,103.146713 304.717865,103.751709 
   C291.265320,112.197884 277.769043,120.574715 264.253937,128.920670 
   C261.474579,130.636993 258.574646,132.158020 255.040039,133.905914 
   C253.554413,134.208954 252.666992,134.201691 251.977585,134.567551 
   C242.534286,139.578979 233.106567,144.620346 223.717804,149.732910 
   C222.965286,150.142700 222.527649,151.130676 221.943695,151.849945 
   C201.621338,160.879379 181.619507,170.752182 160.863541,178.640717 
   C147.099899,183.871750 132.444336,187.734909 117.854553,189.595917 
   C92.682571,192.806717 68.104706,189.657974 48.020218,172.170303 
   C39.009472,164.324631 33.838200,153.473358 31.469837,141.240967 
   C31.112789,134.984970 31.067490,128.943558 31.090448,122.438858 
   C38.691277,95.204956 56.832561,77.317360 79.939659,63.514080 
   C93.716553,55.284298 108.544182,49.929485 124.134491,46.355972 
   C138.627228,43.034035 147.623413,48.401260 151.009995,62.753315 
   C151.905991,66.550468 153.025986,70.294777 154.024979,73.996117 
   C146.140747,75.358826 138.385452,76.274857 130.842941,78.080048 
   C105.090736,84.243446 83.444824,97.496964 66.203468,117.513878 
   C60.241505,124.435608 55.497108,132.370255 53.604748,141.687439 
   C52.397488,147.631470 53.446774,152.646790 58.806889,155.019760 
   C64.982780,157.753906 71.734192,160.440552 78.330185,160.694672 
   C89.658478,161.131119 101.272697,160.802002 112.380043,158.725250 
   C128.228119,155.762131 144.202423,152.202103 159.251114,146.568497 
   C176.711273,140.032135 193.228043,130.975784 210.856308,122.871262 
   C221.711685,117.138062 231.865051,111.546875 242.018433,105.955688 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M33.430244,225.911072 
   C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
   C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
   C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
   C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
   C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1354.910889,111.579178 
   C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
   C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
   C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
   C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
   C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
   C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
   C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
   C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
   C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
   C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
   C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
   C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
   C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
   C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M550.563843,31.089970 
   C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
   C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
   C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
   C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M241.986084,105.585060 
   C231.865051,111.546875 221.711685,117.138062 211.214722,122.820877 
   C206.246429,110.935951 201.626892,98.957397 196.990402,86.985413 
   C196.409958,85.486656 195.739883,84.022636 194.797913,81.804504 
   C190.028702,91.923180 185.599594,101.385948 181.111938,110.820869 
   C177.500351,118.413940 173.848495,125.988388 170.129028,133.528946 
   C169.653351,134.493301 168.787979,135.387268 167.890030,136.003174 
   C154.974106,144.862228 140.419083,148.287491 124.037666,149.215057 
   C126.146080,144.914444 127.811172,141.250351 129.701248,137.706223 
   C142.307709,114.067383 154.957672,90.451729 167.611221,66.838036 
   C168.466995,65.240997 169.846237,63.832115 170.338684,62.147644 
   C173.603333,50.980350 180.767303,47.102131 192.216293,48.817684 
   C198.897064,49.818752 205.852615,49.099297 212.680298,48.954121 
   C215.653046,48.890915 217.173096,49.603519 218.534088,52.730873 
   C225.390778,68.486588 232.732422,84.030983 239.865601,99.667007 
   C240.683777,101.460434 241.263474,103.362656 241.986084,105.585060 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M356.000000,155.004364 
   C356.000000,149.882690 356.000000,145.260208 356.000000,140.000000 
   C368.412079,140.000000 380.358124,139.577652 392.252502,140.178329 
   C398.192352,140.478287 404.403809,141.800629 409.847992,144.152374 
   C420.459808,148.736374 422.076202,160.047150 414.003510,168.504044 
   C413.332428,169.207062 412.721954,169.967957 412.457520,170.271896 
   C415.423615,173.814880 419.255554,176.698608 420.738434,180.498932 
   C424.363190,189.788361 418.490875,199.795212 408.335419,201.838654 
   C401.434906,203.227173 394.286438,203.681946 387.227753,203.914810 
   C377.102448,204.248840 366.957947,204.000000 356.000000,204.000000 
   C356.000000,187.627350 356.000000,171.565460 356.000000,155.004364 
 M386.465668,193.000000 
   C391.065491,192.603180 395.873779,192.878922 400.182495,191.543152 
   C402.554291,190.807831 405.050140,187.632141 405.765167,185.074478 
   C406.777527,181.453323 404.271271,178.386536 400.476379,178.096512 
   C390.481232,177.332565 380.448425,177.061066 370.310303,176.587845 
   C370.310303,182.655945 370.310303,187.567078 370.310303,193.000000 
   C375.564270,193.000000 380.520966,193.000000 386.465668,193.000000 
 M386.462158,166.000015 
   C390.545685,165.655518 394.825226,165.931122 398.636780,164.726257 
   C400.808655,164.039719 403.870544,160.648697 403.581818,158.988525 
   C403.145752,156.481323 400.355042,152.753448 398.176117,152.463120 
   C388.981995,151.238052 379.643799,151.094284 370.333771,150.553589 
   C370.333771,156.402084 370.333771,160.964981 370.333771,166.000000 
   C375.597809,166.000000 380.536346,166.000000 386.462158,166.000015 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M986.254272,189.753784 
   C990.087708,194.228485 993.668335,198.450943 997.458984,202.921005 
   C986.302185,206.172760 980.841248,204.348373 974.517273,196.182632 
   C971.085999,191.752014 967.350464,187.550705 963.619019,183.364639 
   C962.950684,182.614899 961.642944,182.101944 960.610901,182.067703 
   C956.317993,181.925293 952.017639,182.007828 947.223267,182.007828 
   C947.223267,189.310226 947.223267,196.223068 947.223267,203.565552 
   C942.043152,203.565552 937.348999,203.565552 932.324097,203.565552 
   C932.324097,182.616867 932.324097,161.728638 932.324097,140.614777 
   C932.843018,140.441055 933.445618,140.055054 934.039978,140.067398 
   C947.797424,140.352951 961.579834,140.297882 975.296021,141.220779 
   C979.802368,141.523987 984.528503,143.753754 988.464844,146.208115 
   C995.745483,150.747574 995.944580,158.458481 994.776550,165.798889 
   C993.736084,172.337753 989.027954,176.258530 982.707031,178.167465 
   C981.317200,178.587204 979.992432,179.222656 978.015869,180.004837 
   C980.846191,183.370697 983.423889,186.436127 986.254272,189.753784 
 M947.000000,155.666962 
   C947.000000,160.609604 947.000000,165.552231 947.000000,170.947433 
   C954.900269,170.947433 962.208679,171.316391 969.460449,170.833679 
   C975.543640,170.428757 980.890259,165.220123 980.793640,160.901047 
   C980.700317,156.735916 976.541260,152.798676 969.922363,151.982285 
   C963.214966,151.154999 956.387573,151.159943 949.614075,151.183289 
   C948.739014,151.186295 947.871887,153.482010 947.000000,155.666962 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M501.000000,159.163513 
   C502.185669,171.874191 494.080231,176.716568 483.911163,180.075623 
   C490.559021,187.874176 496.849304,195.253220 503.252625,202.764893 
   C494.008423,206.395325 486.363525,204.039047 480.853638,196.801971 
   C477.157013,191.946625 472.924225,187.494003 468.825531,182.956802 
   C468.278412,182.351105 467.112976,182.063995 466.225494,182.046188 
   C461.753540,181.956497 457.278778,182.005005 452.129211,182.005005 
   C452.129211,189.343491 452.129211,196.384903 452.129211,203.701523 
   C447.214905,203.701523 442.974182,203.701523 438.364471,203.701523 
   C438.364471,182.581009 438.364471,161.576172 438.364471,139.073273 
   C454.601501,140.074219 470.634186,140.389191 486.472961,142.306427 
   C495.154388,143.357300 502.059265,151.958801 501.000000,159.163513 
 M452.000000,159.535019 
   C452.000000,163.274017 452.000000,167.013000 452.000000,170.987747 
   C460.190582,170.987747 467.511688,171.215210 474.811157,170.915817 
   C480.656494,170.676056 485.700134,166.438202 485.997772,162.240875 
   C486.425568,156.208817 484.233490,153.200211 476.527954,152.110397 
   C468.606262,150.990036 460.514709,151.070694 452.000000,150.598190 
   C452.000000,153.975143 452.000000,156.269516 452.000000,159.535019 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M486.752686,101.253159 
   C489.651306,104.633095 492.296722,107.759308 495.804871,111.905075 
   C489.542297,111.905075 483.912018,113.136032 479.279663,111.515076 
   C475.582520,110.221375 473.028198,105.517570 470.101105,102.208511 
   C467.261566,98.998398 464.761383,95.471916 461.769012,92.420944 
   C460.534210,91.161957 458.449127,90.260498 456.672119,90.106926 
   C452.728973,89.766159 448.736115,90.000748 444.258820,90.000748 
   C444.258820,97.271721 444.258820,104.188507 444.258820,111.550140 
   C439.408356,111.550140 435.046417,111.550140 430.335083,111.550140 
   C430.335083,90.629471 430.335083,69.730331 430.335083,48.432037 
   C431.532043,48.293179 432.795837,47.995068 434.052002,48.024239 
   C446.821716,48.320786 459.623138,48.227741 472.346497,49.174038 
   C479.815247,49.729530 486.992157,52.499432 490.860687,59.771114 
   C496.438934,70.256714 492.472626,81.194176 481.609558,86.117157 
   C479.968048,86.861076 478.282318,87.507401 475.812927,88.532753 
   C479.538086,92.878426 483.018799,96.938934 486.752686,101.253159 
 M469.034882,60.014080 
   C460.850220,59.560947 452.665588,59.107815 444.283264,58.643742 
   C444.283264,65.944382 444.283264,72.182762 444.283264,78.913063 
   C452.658813,78.913063 460.861084,79.694374 468.836853,78.685265 
   C476.268158,77.745049 480.739929,70.913551 476.662262,64.705780 
   C475.271881,62.589111 472.182343,61.588547 469.034882,60.014080 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M999.270264,105.769623 
   C996.731445,102.898338 993.861023,100.595634 992.278625,97.610229 
   C987.264587,88.150314 978.961548,89.604118 970.210510,90.351120 
   C970.210510,97.321686 970.210510,104.237869 970.210510,111.574371 
   C965.354248,111.574371 960.994995,111.574371 956.311279,111.574371 
   C956.311279,90.581001 956.311279,69.682770 956.311279,48.479435 
   C957.419373,48.319431 958.526672,47.981716 959.617310,48.028946 
   C974.161377,48.658794 988.954529,47.900066 1003.181335,50.380138 
   C1020.730835,53.439411 1023.112976,71.363419 1014.935791,80.596596 
   C1011.810974,84.124901 1007.051086,86.205124 1002.543579,89.281631 
   C1005.236145,92.505394 1008.321106,96.277458 1011.493286,99.974754 
   C1014.601746,103.597816 1017.798828,107.144836 1022.081482,112.000000 
   C1016.779236,112.000000 1012.701172,111.487381 1008.811401,112.124573 
   C1003.472534,112.999184 1002.235046,108.607559 999.270264,105.769623 
 M970.000000,69.487183 
   C970.000000,72.562157 970.000000,75.637131 970.000000,78.989601 
   C978.466431,78.989601 986.101074,79.156471 993.723999,78.929565 
   C998.615112,78.783989 1003.657227,74.353432 1003.994141,70.509354 
   C1004.440186,65.419983 1002.007751,61.220951 996.501099,60.444225 
   C987.931458,59.235466 979.206482,59.127895 970.000000,58.517319 
   C970.000000,62.606899 970.000000,65.559990 970.000000,69.487183 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M631.025391,108.090485 
   C626.396912,109.409279 622.186523,111.552383 617.896240,111.726402 
   C605.808472,112.216728 593.687927,111.897682 581.293701,111.897682 
   C581.293701,90.541199 581.293701,69.641136 581.293701,48.340523 
   C591.471252,48.340523 601.565430,47.923389 611.603333,48.494781 
   C617.762756,48.845390 624.036194,50.094238 629.912231,52.004578 
   C651.582886,59.049843 656.195557,84.328667 643.138672,100.022026 
   C640.228882,103.519279 635.369019,105.394089 631.025391,108.090485 
 M595.000000,67.554596 
   C595.000000,78.628090 595.000000,89.701576 595.000000,101.000000 
   C598.563782,101.000000 601.225403,100.924911 603.881470,101.014374 
   C611.223022,101.261665 618.418884,100.953537 625.159546,97.426102 
   C638.313477,90.542557 638.133240,71.502800 627.006653,64.347557 
   C617.084167,57.966648 606.278503,59.156368 595.000000,59.052452 
   C595.000000,61.980373 595.000000,64.278404 595.000000,67.554596 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1158.752930,51.650627 
   C1177.036621,44.240582 1194.430664,45.369526 1209.191162,58.084660 
   C1223.853027,70.714928 1222.845825,93.963341 1204.450317,106.029549 
   C1189.634033,115.748047 1167.458008,116.139938 1152.364136,105.472557 
   C1131.064941,90.419746 1136.526978,62.607567 1155.719604,53.095467 
   C1156.609009,52.654648 1157.524780,52.266663 1158.752930,51.650627 
 M1161.034546,64.464989 
   C1153.026855,72.734131 1151.609009,82.805473 1157.230835,91.483177 
   C1165.010132,103.491066 1185.519653,105.611145 1196.447876,96.215286 
   C1209.500488,84.993011 1203.744751,66.083565 1191.390869,60.973244 
   C1180.589722,56.505318 1170.968506,57.582321 1161.034546,64.464989 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1044.659912,203.985092 
   C1033.721558,203.995895 1023.251404,203.995895 1012.392639,203.995895 
   C1012.392639,182.621948 1012.392639,161.603745 1012.392639,140.826614 
   C1024.288574,140.826614 1036.032959,140.379303 1047.717651,141.020691 
   C1053.321167,141.328262 1059.135620,142.967972 1064.293579,145.264465 
   C1080.595947,152.522873 1085.551392,171.044144 1077.930298,185.920776 
   C1072.414551,196.687881 1062.431152,201.207687 1050.965332,203.000732 
   C1049.016479,203.305496 1047.073730,203.648819 1044.659912,203.985092 
 M1026.000000,169.500610 
   C1026.000000,177.250702 1026.000000,185.000793 1026.000000,193.474152 
   C1034.323364,192.937698 1041.786011,192.801468 1049.152466,191.888367 
   C1057.060913,190.908051 1064.400146,183.923111 1065.913086,176.849121 
   C1067.835449,167.860275 1064.827881,160.507446 1056.813599,155.309433 
   C1047.350830,149.171783 1036.803833,151.308945 1026.000000,151.122604 
   C1026.000000,157.251678 1026.000000,162.876495 1026.000000,169.500610 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M939.999268,100.526154 
   C938.961853,102.481415 938.267151,104.577393 936.836304,105.413185 
   C919.660706,115.445984 894.151611,117.171120 878.073730,103.295380 
   C863.054199,90.332916 864.071655,64.344833 883.715881,53.690643 
   C899.723267,45.008892 915.826233,45.019318 931.935059,53.465572 
   C934.281433,54.695869 937.016418,55.706406 934.903687,59.321003 
   C931.492798,65.156387 929.959473,65.933586 925.973511,63.401196 
   C914.859253,56.339951 903.578552,56.008606 892.481750,63.120228 
   C885.727600,67.448730 882.374451,74.127907 883.130920,82.107338 
   C883.944946,90.693535 888.943237,96.728348 896.875793,100.025322 
   C904.900696,103.360649 913.047424,102.724762 921.089294,99.879341 
   C926.287292,98.040169 926.661621,96.940704 925.593262,87.268356 
   C919.751099,87.268356 913.830505,87.268356 907.456665,87.268356 
   C907.456665,83.408562 907.456665,80.047493 907.456665,76.337730 
   C918.035339,76.337730 928.613586,76.337730 940.000000,76.337730 
   C940.000000,84.238838 940.000000,92.160103 939.999268,100.526154 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M553.027527,142.299133 
   C553.698486,141.234055 554.346802,140.120438 555.000732,140.117111 
   C573.445557,140.023422 591.891052,140.041763 610.666260,140.041763 
   C610.666260,143.711487 610.666260,146.956879 610.666260,150.886612 
   C596.640137,150.886612 582.604187,150.886612 568.292297,150.886612 
   C568.292297,155.801041 568.292297,160.044693 568.292297,164.794495 
   C580.996704,164.794495 593.574524,164.794495 606.572876,164.794495 
   C606.572876,168.648407 606.572876,172.006195 606.572876,175.879959 
   C593.916687,175.879959 581.337280,175.879959 568.062988,175.879959 
   C568.062988,180.120209 567.702393,183.919861 568.153992,187.620377 
   C568.721985,192.274643 572.579041,192.935623 576.366882,192.969437 
   C586.190613,193.057159 596.015625,192.999588 605.840088,192.999985 
   C607.644409,193.000061 609.448730,193.000000 611.625122,193.000000 
   C611.625122,196.631927 611.625122,199.879395 611.625122,203.762543 
   C610.018311,203.837204 608.266663,203.985504 606.514832,203.989029 
   C595.691223,204.010818 584.867676,204.003082 574.044067,203.999237 
   C559.533142,203.994095 553.002502,197.394440 553.000854,182.731827 
   C552.999390,169.410568 553.005005,156.089310 553.027527,142.299133 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1150.116943,165.000000 
   C1161.543945,165.000000 1172.487061,165.000000 1183.710449,165.000000 
   C1183.710449,168.803833 1183.710449,172.048065 1183.710449,175.799408 
   C1170.668701,175.799408 1157.756104,175.799408 1144.127808,175.799408 
   C1144.127808,179.859283 1143.588867,183.539444 1144.265259,186.980698 
   C1145.095825,191.206696 1148.329712,193.068420 1152.756104,193.031677 
   C1164.228271,192.936417 1175.701660,192.999710 1187.585938,192.999710 
   C1187.585938,196.675034 1187.585938,200.033371 1187.585938,204.000000 
   C1176.025513,204.000000 1164.579590,204.237335 1153.151489,203.890671 
   C1148.129272,203.738342 1142.934448,203.018478 1138.196045,201.429306 
   C1132.887451,199.648911 1130.178101,194.839447 1130.095703,189.382858 
   C1129.856812,173.567154 1130.028687,157.745178 1130.062744,141.925720 
   C1130.063721,141.467987 1130.327148,141.010818 1130.549438,140.289978 
   C1149.133301,140.289978 1167.700562,140.289978 1186.634521,140.289978 
   C1186.634521,143.650192 1186.634521,146.897751 1186.634521,150.825653 
   C1172.705200,150.825653 1158.667725,150.825653 1144.322876,150.825653 
   C1144.322876,155.736221 1144.322876,159.980164 1144.322876,165.000000 
   C1146.121826,165.000000 1147.877563,165.000000 1150.116943,165.000000 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M542.999146,84.000000 
   C535.541565,84.000000 528.583618,84.000000 520.642456,84.000000 
   C521.160278,88.607681 520.822815,92.967827 522.358215,96.517967 
   C523.285461,98.661903 527.076538,100.703171 529.656738,100.801620 
   C541.460510,101.252014 553.291138,100.999527 565.555237,100.999527 
   C565.555237,104.615814 565.555237,107.976952 565.555237,112.000000 
   C558.252136,112.000000 550.977600,112.000061 543.703003,111.999977 
   C537.037903,111.999901 530.371704,112.077728 523.708069,111.980217 
   C514.907837,111.851448 507.850372,105.372330 507.028961,96.773285 
   C506.902771,95.451965 507.000580,94.108925 507.000488,92.775932 
   C506.999664,78.128151 507.000000,63.480366 507.000000,48.417343 
   C526.336426,48.417343 545.363831,48.417343 564.697021,48.417343 
   C564.697021,51.777351 564.697021,55.023430 564.697021,58.784920 
   C550.350403,58.784920 536.099548,58.784920 521.427185,58.784920 
   C521.427185,63.978172 521.427185,68.673416 521.427185,73.892960 
   C534.431458,73.892960 547.347961,73.892960 560.628052,73.892960 
   C560.628052,77.306335 560.628052,80.223572 560.628052,84.000000 
   C555.006653,84.000000 549.252686,84.000000 542.999146,84.000000 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M897.995667,154.988190 
   C906.466248,171.245651 914.819458,187.149246 923.663757,203.987778 
   C917.819702,203.987778 913.053833,204.186523 908.326416,203.847824 
   C907.147156,203.763336 905.399536,202.121613 905.088196,200.896942 
   C902.351685,190.130951 895.175354,187.524307 885.037720,188.877258 
   C879.804504,189.575699 874.393738,189.168793 869.078003,188.931030 
   C866.305481,188.807007 864.663086,189.692886 863.885010,192.294495 
   C863.838318,192.450729 863.729309,192.587784 863.653442,192.735931 
   C857.512512,204.731323 857.512512,204.731323 843.171387,203.725754 
   C847.485291,195.115250 851.596191,186.678360 855.913818,178.348633 
   C861.846375,166.903458 868.089966,155.616730 873.857422,144.090515 
   C875.458801,140.890045 877.429871,140.311722 880.706848,139.831482 
   C888.402405,138.703705 892.859192,141.560944 895.014099,148.856110 
   C895.614014,150.886993 896.904602,152.713867 897.995667,154.988190 
 M887.119873,165.359772 
   C885.619751,162.366425 884.119568,159.373062 882.222534,155.587708 
   C878.318542,163.424728 874.831116,170.425461 871.273682,177.566711 
   C878.770203,177.566711 885.718994,177.566711 893.209595,177.566711 
   C891.121338,173.335938 889.311523,169.669266 887.119873,165.359772 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M679.856812,202.635864 
   C675.419922,203.500961 671.078552,203.909683 666.342651,204.355530 
   C666.342651,182.649246 666.342651,161.754501 666.342651,140.807022 
   C666.535767,140.643341 666.787842,140.244095 667.033386,140.248093 
   C681.288452,140.480560 695.572510,140.307587 709.780640,141.261627 
   C714.376343,141.570190 719.204285,144.208405 723.170166,146.896133 
   C731.283936,152.394897 731.335632,160.886139 729.215576,169.244431 
   C726.883789,178.437531 719.296082,182.417160 710.898804,183.788956 
   C703.116211,185.060318 695.061707,184.681412 687.125305,184.987549 
   C684.994263,185.069748 682.857361,185.000000 679.952332,185.000000 
   C679.952332,190.993942 679.952332,196.586716 679.856812,202.635864 
 M715.014771,160.318939 
   C714.738159,155.464081 711.145020,152.912994 707.326477,152.411575 
   C698.433472,151.243851 689.407837,151.085785 680.296265,150.509811 
   C680.296265,158.994827 680.296265,166.221817 680.296265,173.955765 
   C688.176025,173.955765 695.853699,174.657227 703.336243,173.749878 
   C710.220215,172.915100 715.940674,169.787231 715.014771,160.318939 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M844.693359,71.328003 
   C851.588989,84.736900 858.294678,97.822998 865.508850,111.901344 
   C859.847839,111.901344 854.956726,112.220207 850.159058,111.691406 
   C848.856018,111.547783 847.297729,109.009567 846.775024,107.291046 
   C844.162292,98.699577 838.756897,95.622589 829.832153,96.842232 
   C823.784241,97.668732 817.521301,97.217133 811.375549,96.912544 
   C808.089722,96.749687 806.672974,98.186516 805.521484,100.905113 
   C800.286926,113.263824 800.243652,113.245499 785.758240,111.519272 
   C786.753723,109.172211 787.530090,106.951080 788.599731,104.881554 
   C797.639465,87.392052 806.836243,69.982521 815.717529,52.413319 
   C817.379089,49.126423 819.188293,47.374104 822.882324,47.992809 
   C823.206238,48.047077 823.558533,48.056438 823.878418,47.991665 
   C830.443970,46.662144 834.144958,49.317223 836.475220,55.639420 
   C838.457275,61.017132 841.776062,65.902184 844.693359,71.328003 
 M819.107666,73.674202 
   C817.440369,77.551491 815.773010,81.428780 813.960205,85.644440 
   C821.412354,85.644440 828.340881,85.644440 835.797241,85.644440 
   C831.951355,77.944580 828.353271,70.740829 824.538330,63.102959 
   C822.722961,66.666412 821.104370,69.843605 819.107666,73.674202 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M222.249161,152.051559 
   C222.527649,151.130676 222.965286,150.142700 223.717804,149.732910 
   C233.106567,144.620346 242.534286,139.578979 251.977585,134.567551 
   C252.666992,134.201691 253.554413,134.208954 254.732513,134.070114 
   C260.956604,146.476974 266.818390,158.846909 272.629791,171.240448 
   C275.127045,176.566132 277.519806,181.940796 280.266327,187.965073 
   C267.253296,187.965073 254.987137,188.182007 242.735703,187.852341 
   C238.190018,187.730011 235.717758,184.213211 234.067825,180.098358 
   C230.330017,170.776566 226.404083,161.530212 222.249161,152.051559 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M805.852295,139.892685 
   C819.042358,137.247665 830.265442,141.172989 841.841309,147.929413 
   C839.439697,151.264771 837.385559,154.117584 835.132202,157.247086 
   C831.774353,155.495636 828.666443,153.682449 825.405457,152.207703 
   C812.735901,146.477951 793.427002,151.435944 788.932495,166.651337 
   C786.962646,173.319962 789.609680,183.063110 794.996277,187.424988 
   C804.643555,195.237045 819.825562,196.547775 830.332703,189.665085 
   C834.850525,186.705688 837.535156,186.459290 840.064026,191.464371 
   C840.702026,192.726929 841.972229,193.670029 843.288879,195.134872 
   C831.788269,202.875366 819.813965,206.318665 806.168701,204.277786 
   C798.980530,203.202698 792.476990,201.103790 786.473022,197.025024 
   C776.459473,190.222412 772.015442,180.492172 773.209534,168.901657 
   C774.447021,156.889252 781.282288,148.033981 792.597778,143.327988 
   C796.637695,141.647858 801.127747,141.049957 805.852295,139.892685 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M400.813080,100.067093 
   C401.620117,99.690140 402.041840,99.418739 402.488007,99.196434 
   C411.786041,94.564087 411.786591,94.565170 417.787384,103.809792 
   C402.715820,113.713631 386.615692,116.084084 369.802246,109.769615 
   C359.369598,105.851517 351.446655,98.900642 348.839325,87.130051 
   C345.803314,73.424393 351.530701,60.185955 363.859863,53.586025 
   C379.743530,45.083336 395.687500,45.016964 411.653961,53.320511 
   C414.080139,54.582260 417.211609,55.567532 414.808899,59.478844 
   C411.178589,65.388496 410.030792,65.917717 405.827026,63.289299 
   C397.557678,58.118862 388.981201,56.747372 379.382507,59.695492 
   C368.010284,63.188320 362.233398,74.038094 363.015930,81.822258 
   C364.070984,92.317627 373.157562,101.284607 384.303802,101.838783 
   C389.641846,102.104172 395.058228,100.792763 400.813080,100.067093 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1126.619629,107.719582 
   C1107.563354,116.244728 1089.387939,115.654327 1073.294800,102.396675 
   C1061.179321,92.415848 1060.521484,71.919991 1071.092041,60.351738 
   C1078.455811,52.292912 1087.727295,49.128807 1098.292358,47.841003 
   C1110.730957,46.324814 1121.601318,49.571857 1132.404785,56.421597 
   C1130.182617,59.527149 1128.226074,62.261574 1126.353882,64.877991 
   C1120.233521,62.618984 1114.545532,59.924156 1108.557983,58.426682 
   C1093.516846,54.664852 1078.737183,66.694214 1079.000000,82.051704 
   C1079.157349,91.245895 1089.848999,101.422295 1099.922241,102.002602 
   C1108.369507,102.489235 1116.459106,101.609787 1123.493896,96.261116 
   C1125.743652,94.550652 1127.579346,94.812164 1129.078857,97.481224 
   C1130.118652,99.331749 1131.601807,100.933044 1133.442505,103.386093 
   C1131.178467,104.832520 1129.062988,106.183990 1126.619629,107.719582 
 z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
 M307.000000,39.204731 
   C306.999908,45.341560 307.268494,51.027294 306.847900,56.661587 
   C306.708435,58.529911 305.231293,61.050797 303.626862,61.939682 
   C289.303131,69.875343 274.879913,77.639206 260.351685,85.195427 
   C249.641693,90.765770 245.189423,88.890274 240.888870,77.663406 
   C239.499481,74.036293 238.050919,70.431847 236.598221,66.737663 
   C252.747101,64.399811 264.912964,54.640999 278.436584,47.947712 
   C287.897308,43.265293 297.003937,37.867413 307.000000,32.388245 
   C307.000000,35.158306 307.000000,36.945942 307.000000,39.204731 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M714.000000,78.000542 
   C714.000000,71.545189 714.000000,65.589714 714.000000,59.130894 
   C705.928223,59.130894 698.343018,59.130894 690.385132,59.130894 
   C690.385132,55.392578 690.385132,52.148731 690.385132,48.445839 
   C710.935608,48.445839 731.622375,48.445839 752.656128,48.445839 
   C752.656128,51.693275 752.656128,54.940971 752.656128,58.876625 
   C744.991821,58.876625 737.278198,58.876625 729.084717,58.876625 
   C729.084717,76.793045 729.084717,94.034866 729.084717,111.635223 
   C724.013245,111.635223 719.435730,111.635223 714.000000,111.635223 
   C714.000000,100.652237 714.000000,89.576332 714.000000,78.000542 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1255.065063,101.000000 
   C1265.493286,101.000000 1275.432251,101.000000 1285.681396,101.000000 
   C1285.681396,104.741135 1285.681396,107.984749 1285.681396,111.616150 
   C1267.962524,111.616150 1250.290283,111.616150 1232.307861,111.616150 
   C1232.307861,90.590050 1232.307861,69.708969 1232.307861,48.413193 
   C1237.001465,48.413193 1241.692627,48.413193 1246.866333,48.413193 
   C1246.866333,65.751091 1246.866333,82.975998 1246.866333,101.000000 
   C1249.745117,101.000000 1252.160400,101.000000 1255.065063,101.000000 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1050.000000,70.000084 
   C1050.000000,84.102982 1050.000000,97.705917 1050.000000,111.652016 
   C1044.984985,111.652016 1040.413208,111.652016 1035.416870,111.652016 
   C1035.416870,90.695328 1035.416870,69.697388 1035.416870,48.348358 
   C1040.015259,48.348358 1044.587769,48.348358 1050.000000,48.348358 
   C1050.000000,55.356026 1050.000000,62.428074 1050.000000,70.000084 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M1096.000000,154.006958 
   C1096.000000,149.221695 1096.000000,144.934662 1096.000000,140.329163 
   C1101.052734,140.329163 1105.625122,140.329163 1110.602051,140.329163 
   C1110.602051,161.343781 1110.602051,182.341782 1110.602051,203.671082 
   C1105.946533,203.671082 1101.374268,203.671082 1096.000000,203.671082 
   C1096.000000,187.276077 1096.000000,170.890640 1096.000000,154.006958 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M744.000000,192.977386 
   C744.000000,175.218246 744.000000,157.956146 744.000000,140.348923 
   C749.013245,140.348923 753.585083,140.348923 758.582397,140.348923 
   C758.582397,161.303757 758.582397,182.302246 758.582397,203.651733 
   C753.984375,203.651733 749.411743,203.651733 744.000000,203.651733 
   C744.000000,200.295868 744.000000,196.885147 744.000000,192.977386 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M678.000000,90.999840 
   C678.000000,98.114197 678.000000,104.728584 678.000000,111.668213 
   C673.284607,111.668213 669.044189,111.668213 664.399841,111.668213 
   C664.399841,90.661987 664.399841,69.664398 664.399841,48.332191 
   C668.714600,48.332191 672.955017,48.332191 678.000000,48.332191 
   C678.000000,62.388538 678.000000,76.444206 678.000000,90.999840 
 z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
 M519.000549,144.195007 
   C519.868713,142.484177 520.621155,140.307327 521.624756,140.184586 
   C525.185120,139.749176 528.831238,140.014755 532.684631,140.014755 
   C532.820984,141.499329 532.984741,142.455566 532.985474,143.411926 
   C533.000305,163.350143 532.996643,183.288391 532.996643,203.612183 
   C528.274841,203.612183 523.917969,203.612183 519.000000,203.612183 
   C519.000000,183.833847 519.000000,164.252487 519.000549,144.195007 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M385.971649,193.000000 
   C380.520966,193.000000 375.564270,193.000000 370.310303,193.000000 
   C370.310303,187.567078 370.310303,182.655945 370.310303,176.587845 
   C380.448425,177.061066 390.481232,177.332565 400.476379,178.096512 
   C404.271271,178.386536 406.777527,181.453323 405.765167,185.074478 
   C405.050140,187.632141 402.554291,190.807831 400.182495,191.543152 
   C395.873779,192.878922 391.065491,192.603180 385.971649,193.000000 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M385.968536,166.000000 
   C380.536346,166.000000 375.597809,166.000000 370.333771,166.000000 
   C370.333771,160.964981 370.333771,156.402084 370.333771,150.553589 
   C379.643799,151.094284 388.981995,151.238052 398.176117,152.463120 
   C400.355042,152.753448 403.145752,156.481323 403.581818,158.988525 
   C403.870544,160.648697 400.808655,164.039719 398.636780,164.726257 
   C394.825226,165.931122 390.545685,165.655518 385.968536,166.000000 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M947.000549,155.191315 
   C947.871887,153.482010 948.739014,151.186295 949.614075,151.183289 
   C956.387573,151.159943 963.214966,151.154999 969.922363,151.982285 
   C976.541260,152.798676 980.700317,156.735916 980.793640,160.901047 
   C980.890259,165.220123 975.543640,170.428757 969.460449,170.833679 
   C962.208679,171.316391 954.900269,170.947433 947.000000,170.947433 
   C947.000000,165.552231 947.000000,160.609604 947.000549,155.191315 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M452.000000,159.049454 
   C452.000000,156.269516 452.000000,153.975143 452.000000,150.598190 
   C460.514709,151.070694 468.606262,150.990036 476.527954,152.110397 
   C484.233490,153.200211 486.425568,156.208817 485.997772,162.240875 
   C485.700134,166.438202 480.656494,170.676056 474.811157,170.915817 
   C467.511688,171.215210 460.190582,170.987747 452.000000,170.987747 
   C452.000000,167.013000 452.000000,163.274017 452.000000,159.049454 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M469.452026,60.046051 
   C472.182343,61.588547 475.271881,62.589111 476.662262,64.705780 
   C480.739929,70.913551 476.268158,77.745049 468.836853,78.685265 
   C460.861084,79.694374 452.658813,78.913063 444.283264,78.913063 
   C444.283264,72.182762 444.283264,65.944382 444.283264,58.643742 
   C452.665588,59.107815 460.850220,59.560947 469.452026,60.046051 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M970.000000,69.000130 
   C970.000000,65.559990 970.000000,62.606899 970.000000,58.517319 
   C979.206482,59.127895 987.931458,59.235466 996.501099,60.444225 
   C1002.007751,61.220951 1004.440186,65.419983 1003.994141,70.509354 
   C1003.657227,74.353432 998.615112,78.783989 993.723999,78.929565 
   C986.101074,79.156471 978.466431,78.989601 970.000000,78.989601 
   C970.000000,75.637131 970.000000,72.562157 970.000000,69.000130 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M595.000000,67.065514 
   C595.000000,64.278404 595.000000,61.980373 595.000000,59.052452 
   C606.278503,59.156368 617.084167,57.966648 627.006653,64.347557 
   C638.133240,71.502800 638.313477,90.542557 625.159546,97.426102 
   C618.418884,100.953537 611.223022,101.261665 603.881470,101.014374 
   C601.225403,100.924911 598.563782,101.000000 595.000000,101.000000 
   C595.000000,89.701576 595.000000,78.628090 595.000000,67.065514 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M1161.300049,64.213226 
   C1170.968506,57.582321 1180.589722,56.505318 1191.390869,60.973244 
   C1203.744751,66.083565 1209.500488,84.993011 1196.447876,96.215286 
   C1185.519653,105.611145 1165.010132,103.491066 1157.230835,91.483177 
   C1151.609009,82.805473 1153.026855,72.734131 1161.300049,64.213226 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M1026.000000,169.000961 
   C1026.000000,162.876495 1026.000000,157.251678 1026.000000,151.122604 
   C1036.803833,151.308945 1047.350830,149.171783 1056.813599,155.309433 
   C1064.827881,160.507446 1067.835449,167.860275 1065.913086,176.849121 
   C1064.400146,183.923111 1057.060913,190.908051 1049.152466,191.888367 
   C1041.786011,192.801468 1034.323364,192.937698 1026.000000,193.474152 
   C1026.000000,185.000793 1026.000000,177.250702 1026.000000,169.000961 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M887.310791,165.681183 
   C889.311523,169.669266 891.121338,173.335938 893.209595,177.566711 
   C885.718994,177.566711 878.770203,177.566711 871.273682,177.566711 
   C874.831116,170.425461 878.318542,163.424728 882.222534,155.587708 
   C884.119568,159.373062 885.619751,162.366425 887.310791,165.681183 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M715.053345,160.692383 
   C715.940674,169.787231 710.220215,172.915100 703.336243,173.749878 
   C695.853699,174.657227 688.176025,173.955765 680.296265,173.955765 
   C680.296265,166.221817 680.296265,158.994827 680.296265,150.509811 
   C689.407837,151.085785 698.433472,151.243851 707.326477,152.411575 
   C711.145020,152.912994 714.738159,155.464081 715.053345,160.692383 
 z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
 M819.296753,73.347504 
   C821.104370,69.843605 822.722961,66.666412 824.538330,63.102959 
   C828.353271,70.740829 831.951355,77.944580 835.797241,85.644440 
   C828.340881,85.644440 821.412354,85.644440 813.960205,85.644440 
   C815.773010,81.428780 817.440369,77.551491 819.296753,73.347504 
 z"
      />
    </svg>
  );
};

CaBriePicardieSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaBriePicardieSvg;
