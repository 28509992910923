import * as React from 'react';
import PropTypes from 'prop-types';

const CaLanguedocSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101135,31.018839 
	C545.385132,31.064877 540.669067,31.110916 535.031738,31.011070 
	C367.080994,31.011070 199.130249,31.011070 31.158733,31.011070 
	C31.158733,61.734035 31.158733,91.854843 31.022226,122.902931 
	C31.067513,128.944077 31.112801,134.985229 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C644.236267,225.988754 999.514648,225.988754 1354.840576,225.988754 
	C1354.840576,187.620987 1354.840576,149.828094 1354.981323,111.123169 
	C1354.934448,107.736908 1354.887573,104.350647 1354.988770,100.041985 
	C1354.988770,77.117531 1354.988770,54.193069 1354.988770,31.000000 
	C1352.844849,31.000000 1351.207764,31.000000 1349.570801,31.000000 
	C1085.051147,31.000000 820.531433,30.999704 556.011719,31.006647 
	C554.350037,31.006691 552.688293,31.107399 550.101135,31.018839 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M31.158733,121.975655 
	C31.158733,91.854843 31.158733,61.734035 31.158733,31.011070 
	C199.130249,31.011070 367.080994,31.011070 535.351440,31.381102 
	C533.099426,35.219772 530.527771,38.688408 527.741943,42.445869 
	C538.868652,44.003822 542.294250,42.805531 547.504700,35.905727 
	C548.691650,34.333981 549.853333,32.743141 551.026611,31.161098 
	C552.688293,31.107399 554.350037,31.006691 556.011719,31.006647 
	C820.531433,30.999704 1085.051147,31.000000 1349.570801,31.000000 
	C1351.207764,31.000000 1352.844849,31.000000 1354.988770,31.000000 
	C1354.988770,54.193069 1354.988770,77.117531 1354.456665,100.515289 
	C1341.928711,100.992348 1329.932617,100.943871 1317.937378,101.028282 
	C1313.761597,101.057663 1310.587646,99.733360 1310.111328,95.252480 
	C1309.732300,91.686584 1310.034912,88.048218 1310.034912,83.957916 
	C1323.586670,83.957916 1336.504883,83.957916 1349.606812,83.957916 
	C1349.606812,80.414215 1349.606812,77.386475 1349.606812,73.853409 
	C1336.305908,73.853409 1323.389282,73.853409 1310.446045,73.853409 
	C1310.446045,68.635887 1310.446045,63.936825 1310.446045,58.749298 
	C1324.803833,58.749298 1338.721313,58.749298 1352.679932,58.749298 
	C1352.679932,54.989918 1352.679932,51.742352 1352.679932,48.432045 
	C1333.663696,48.432045 1314.970825,48.432045 1296.000000,48.432045 
	C1296.000000,64.345596 1295.996948,79.828728 1296.001221,95.311867 
	C1296.004028,105.434814 1302.592529,111.972633 1312.855469,111.995232 
	C1326.850464,112.026054 1340.845581,112.023460 1354.840576,112.035194 
	C1354.840576,149.828094 1354.840576,187.620987 1354.840576,225.988754 
	C999.514648,225.988754 644.236267,225.988754 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396374,141.702835 
	C33.838219,153.473328 39.009460,164.324600 48.020203,172.170288 
	C68.104713,189.657944 92.682549,192.806702 117.854546,189.595886 
	C132.444336,187.734894 147.099915,183.871750 160.863556,178.640732 
	C181.619522,170.752213 201.621353,160.879379 222.249176,152.051559 
	C226.404083,161.530182 230.330032,170.776535 234.067825,180.098328 
	C235.717728,184.213165 238.189972,187.730011 242.735672,187.852341 
	C254.987122,188.182007 267.253296,187.965057 280.266357,187.965057 
	C277.519806,181.940796 275.127045,176.566132 272.629791,171.240448 
	C266.818359,158.846909 260.956604,146.476974 255.422760,133.933289 
	C258.574829,132.157990 261.474976,130.636963 264.254456,128.920486 
	C277.770233,120.573799 291.267181,112.196236 304.720306,103.749199 
	C305.683533,103.144402 306.875824,101.905647 306.891357,100.944397 
	C307.050720,91.058205 306.989105,81.168442 306.989105,70.488342 
	C296.574005,76.321426 287.078735,81.753044 277.476379,86.988274 
	C265.702301,93.407555 253.838638,99.662575 241.984283,105.615349 
	C241.267899,103.389969 240.693893,101.486328 239.876251,99.693817 
	C232.746124,84.062622 225.410461,68.521858 218.550217,52.773731 
	C217.202240,49.679348 215.730408,48.912876 212.729019,48.950962 
	C202.571106,49.079853 192.406113,49.171688 182.253372,48.908882 
	C178.596069,48.814213 176.538574,50.064400 174.882370,53.215626 
	C166.804962,68.584297 158.584991,83.878181 150.385437,99.182442 
	C143.594849,111.856880 136.733124,124.493286 129.966660,137.180542 
	C127.982674,140.900558 126.216576,144.736755 123.971596,149.288116 
	C137.879684,148.362503 150.390350,145.987656 161.848618,139.614288 
	C164.797791,137.973862 168.355652,136.228867 169.923965,133.536041 
	C174.142456,126.292786 177.480637,118.532181 181.087753,110.938690 
	C185.587677,101.465668 190.007050,91.954376 194.779572,81.780983 
	C200.461746,95.681091 205.866150,108.901680 211.044357,122.402802 
	C210.219330,122.951859 209.617599,123.214119 209.022095,123.489822 
	C191.019028,131.824860 173.367477,141.071930 154.895386,148.189484 
	C141.742310,153.257538 127.632835,156.348740 113.675423,158.670212 
	C101.696289,160.662643 89.292557,161.159515 77.145233,160.657349 
	C70.637680,160.388321 64.138618,156.941330 57.892078,154.318039 
	C53.301067,152.389999 52.641079,147.695541 53.181644,143.408081 
	C53.622143,139.914307 54.870762,136.371964 56.454002,133.196686 
	C68.386627,109.265045 88.524780,94.421539 112.345139,84.132912 
	C125.458611,78.468872 139.294998,74.988304 154.239700,74.721825 
	C153.102020,70.654694 151.923447,66.941727 151.030579,63.161285 
	C147.511902,48.262947 138.844101,43.005219 124.071503,46.382435 
	C108.481667,49.946491 93.670250,55.311699 79.904060,63.539467 
	C56.815563,77.338982 38.683582,95.219193 31.158733,121.975655 
M531.004028,179.504898 
	C535.892944,185.115585 541.036621,190.527328 545.609558,196.384644 
	C552.089966,204.685089 554.790100,205.830826 565.711853,203.249542 
	C565.711853,182.377838 565.711853,161.473297 565.711853,140.404449 
	C561.054016,140.404449 556.694397,140.404449 551.793579,140.404449 
	C551.793579,154.639297 551.793579,168.477707 551.793579,183.543121 
	C548.588379,179.832153 546.219543,177.114868 543.878235,174.374130 
	C536.477234,165.710632 529.119507,157.009811 521.673096,148.385529 
	C519.210999,145.533951 516.984985,141.511887 513.836975,140.459030 
	C509.720673,139.082367 504.796326,140.121735 500.376038,140.121735 
	C500.376038,161.801147 500.376038,182.706589 500.376038,203.684708 
	C505.333344,203.684708 509.911285,203.684708 515.000488,203.684708 
	C515.000488,189.846634 515.000488,176.311554 515.000488,161.185577 
	C520.839050,167.896149 525.669556,173.448074 531.004028,179.504898 
M886.476440,149.979279 
	C881.411316,147.324921 876.564636,143.062881 871.237305,142.339310 
	C858.828613,140.653885 846.189270,140.656830 833.641357,140.015030 
	C831.556824,139.908401 829.462036,140.000000 827.305298,140.000000 
	C827.305298,161.665771 827.305298,182.565369 827.305298,204.531647 
	C841.178711,203.948700 854.770874,203.926651 868.235657,202.606293 
	C876.342651,201.811340 883.962891,197.961487 889.453003,191.759338 
	C900.053589,179.783920 898.777100,159.927856 886.476440,149.979279 
M477.222015,110.715149 
	C483.025360,113.399445 488.876923,111.431450 494.809174,111.400757 
	C491.782990,107.125938 488.583588,103.578987 485.473907,99.955032 
	C482.307251,96.264694 479.228882,92.498573 476.545837,89.285378 
	C481.069550,86.190865 485.819641,84.104034 488.945251,80.577423 
	C497.120697,71.352997 494.708679,53.422016 477.168335,50.376766 
	C462.941498,47.906784 448.153595,48.658268 433.612488,48.029068 
	C432.522430,47.981907 431.415649,48.321152 430.308228,48.481987 
	C430.308228,69.682671 430.308228,90.577896 430.308228,111.578392 
	C434.983704,111.578392 439.344421,111.578392 444.201111,111.578392 
	C444.201111,104.247322 444.201111,97.332520 444.201111,90.000000 
	C448.162292,90.000000 451.642273,89.818039 455.090729,90.069267 
	C457.110718,90.216423 459.775391,90.413071 460.953888,91.690765 
	C466.428894,97.626823 471.485962,103.948433 477.222015,110.715149 
M975.498901,48.000000 
	C969.082214,48.000000 962.665527,48.000000 956.370789,48.000000 
	C956.370789,69.780411 956.370789,90.691376 956.370789,111.688095 
	C960.991150,111.688095 965.237610,111.688095 970.167114,111.688095 
	C970.167114,104.349854 970.167114,97.300720 970.167114,90.000336 
	C974.676025,90.000336 978.522827,89.737152 982.307068,90.109612 
	C984.245422,90.300385 986.514893,91.185692 987.858887,92.531723 
	C990.875427,95.552887 993.363708,99.095169 996.148621,102.355621 
	C998.831604,105.496613 1001.108398,110.224373 1004.477234,111.247925 
	C1009.482849,112.768753 1015.292847,111.641876 1021.652161,111.641876 
	C1014.611633,103.452400 1008.327026,96.142288 1002.479736,89.340797 
	C1007.085083,86.179657 1011.825928,84.079170 1014.958618,80.550865 
	C1023.137024,71.339508 1020.582458,54.017033 1003.116821,50.215199 
	C994.491699,48.337727 985.385925,48.668434 975.498901,48.000000 
M594.508728,112.000008 
	C605.044800,111.332138 615.972961,112.215141 626.034729,109.671211 
	C646.525085,104.490570 653.864929,89.266182 648.777100,70.528938 
	C645.554810,58.662109 635.899597,51.731678 624.093811,50.268196 
	C610.206360,48.546688 596.072632,48.810555 582.044373,48.245991 
	C581.803101,48.236282 581.545349,48.636456 581.327820,48.818165 
	C581.327820,69.737816 581.327820,90.624664 581.327820,112.000000 
	C585.599915,112.000000 589.556152,112.000000 594.508728,112.000008 
M1194.238159,110.988838 
	C1200.141724,107.174805 1207.207275,104.398941 1211.722778,99.344711 
	C1223.488525,86.175026 1221.173096,68.213959 1208.861816,57.714935 
	C1193.319824,44.460762 1171.198120,43.974564 1153.768921,54.106812 
	C1136.670776,64.046593 1133.638916,87.372482 1147.779785,101.207748 
	C1160.834351,113.980194 1176.608398,115.172745 1194.238159,110.988838 
M922.398499,199.148880 
	C937.688904,207.436813 953.081909,207.023026 968.326843,199.253082 
	C989.681091,188.369339 991.236633,159.989624 971.832458,146.975677 
	C957.706177,137.501572 942.425842,136.809799 926.912354,142.683640 
	C914.294312,147.461182 906.565247,156.959961 905.228638,170.474442 
	C904.417053,178.679749 908.821533,192.227341 922.398499,199.148880 
M900.162598,59.408039 
	C909.659668,56.576012 918.369751,58.258865 926.540344,63.748913 
	C932.058167,67.456436 932.733582,61.354000 934.820007,59.043591 
	C937.412354,56.172966 934.412048,54.818645 932.215698,53.639408 
	C916.351501,45.121712 900.364258,44.961643 884.432129,53.293087 
	C874.082581,58.705223 868.368042,67.366890 868.040222,79.141975 
	C867.686646,91.840240 873.027344,101.799026 884.328796,107.557014 
	C901.934387,116.526817 919.518799,114.525772 936.605347,105.598129 
	C938.015381,104.861420 939.790710,103.099831 939.837708,101.759758 
	C940.133240,93.332657 939.987244,84.890076 939.987244,76.000366 
	C930.032715,76.000366 920.736450,75.990730 911.440125,76.011475 
	C910.148621,76.014359 908.857483,76.167137 907.395020,76.261581 
	C907.395020,79.923286 907.395020,83.284187 907.395020,87.150993 
	C913.702881,87.150993 919.623657,87.150993 925.451843,87.150993 
	C927.015930,97.717987 926.886414,98.245422 917.431702,100.897377 
	C913.848572,101.902405 909.920166,101.922104 906.145569,101.952919 
	C894.430908,102.048569 884.520813,93.557312 883.051147,82.270767 
	C882.087585,74.871246 887.188660,62.755264 900.162598,59.408039 
M605.155945,141.410736 
	C598.398987,143.603470 592.636780,147.287643 587.874817,152.604538 
	C576.744568,165.031708 579.151001,185.916351 591.598694,195.717606 
	C608.724609,209.202393 633.341675,207.061691 650.358276,196.760223 
	C651.576233,196.022888 652.835144,194.205856 652.874023,192.856216 
	C653.107666,184.755096 652.989929,176.643829 652.989929,168.402878 
	C641.369873,168.402878 630.343506,168.402878 619.344238,168.402878 
	C619.344238,171.730301 619.344238,174.642502 619.344238,178.050476 
	C625.592163,178.050476 631.514343,178.050476 638.000000,178.050476 
	C638.000000,180.331573 637.690979,182.178787 638.057922,183.880234 
	C639.165894,189.018387 636.180542,191.746262 632.043213,192.272003 
	C625.614990,193.088867 618.824707,193.913895 612.588196,192.691956 
	C603.889038,190.987488 597.955322,184.729126 596.231140,175.889084 
	C594.709839,168.089340 597.096191,160.923935 603.651245,155.968063 
	C613.388306,148.606339 624.051941,148.212967 634.977539,153.030746 
	C642.875610,156.513458 642.817444,156.659897 648.285889,150.122589 
	C648.580872,149.769989 648.688782,149.261017 649.064575,148.420319 
	C647.609680,147.386856 646.254944,146.207581 644.715759,145.365555 
	C632.380066,138.617065 619.399170,137.533813 605.155945,141.410736 
M766.256653,176.873703 
	C767.830627,176.582504 769.402588,176.052719 770.978882,176.039291 
	C780.311340,175.959763 789.644775,176.000031 798.977905,176.000000 
	C801.226868,176.000000 803.475891,176.000000 805.633545,176.000000 
	C805.633545,171.893860 805.633545,168.644745 805.633545,164.900726 
	C792.357056,164.900726 779.436768,164.900726 766.425903,164.900726 
	C766.425903,160.014755 766.425903,155.650299 766.425903,150.784485 
	C780.765991,150.784485 794.686401,150.784485 808.694336,150.784485 
	C808.694336,147.020508 808.694336,143.773087 808.694336,140.424347 
	C789.688599,140.424347 770.991516,140.424347 752.012329,140.424347 
	C752.012329,156.833023 751.768066,172.824341 752.117920,188.802643 
	C752.286499,196.498184 756.865051,201.888702 764.454712,202.866760 
	C772.800232,203.942230 781.315918,203.780548 789.761658,203.968552 
	C796.696411,204.122910 803.637390,204.000000 810.711670,204.000000 
	C810.711670,200.055481 810.711670,196.808594 810.711670,193.000000 
	C800.648071,193.000000 790.862244,193.000000 781.076477,193.000000 
	C766.742493,193.000000 765.810059,192.054688 766.256653,176.873703 
M554.480469,112.000000 
	C558.230408,112.000000 561.980347,112.000000 565.634216,112.000000 
	C565.634216,107.900124 565.634216,104.651154 565.634216,101.000000 
	C563.634644,101.000000 561.998047,101.000008 560.361511,101.000000 
	C550.197083,100.999954 540.022949,101.242020 529.875061,100.832321 
	C527.286255,100.727806 523.313904,99.141884 522.545715,97.213432 
	C520.996338,93.324020 521.210205,88.732193 520.622314,83.806366 
	C534.750000,83.806366 547.668213,83.806366 560.706787,83.806366 
	C560.706787,80.377975 560.706787,77.463531 560.706787,73.868057 
	C547.387756,73.868057 534.344055,73.868057 521.350403,73.868057 
	C521.350403,68.596886 521.350403,64.017929 521.350403,58.936218 
	C535.942017,58.936218 550.193359,58.936218 564.594849,58.936218 
	C564.594849,55.054863 564.594849,51.694805 564.594849,48.341408 
	C545.278748,48.341408 526.369873,48.341408 507.000000,48.341408 
	C507.000000,63.806644 506.983429,78.934097 507.005493,94.061501 
	C507.021667,105.136230 513.889099,111.973167 524.993835,111.995949 
	C534.491638,112.015442 543.989624,112.000000 554.480469,112.000000 
M814.510010,54.984718 
	C804.881042,73.704185 795.252075,92.423653 785.481079,111.419121 
	C800.203430,113.510918 800.203247,113.510834 805.486877,101.050095 
	C805.551514,100.897644 805.674500,100.766609 805.722412,100.610870 
	C806.560181,97.886299 808.204468,96.780212 811.226990,96.923302 
	C816.875427,97.190712 822.650696,97.690979 828.183777,96.849129 
	C838.189636,95.326736 844.618530,98.435028 847.321899,108.522156 
	C847.677795,109.850014 849.324463,111.696167 850.494385,111.789757 
	C855.186768,112.165123 859.926758,111.944923 864.932983,111.944923 
	C864.600281,110.725395 864.552307,110.232002 864.341553,109.822365 
	C854.123413,89.960701 843.898621,70.102463 833.634521,50.264526 
	C833.208130,49.440426 832.421692,48.215511 831.766602,48.191986 
	C827.451965,48.037029 823.006653,47.592487 818.865173,48.472126 
	C817.200500,48.825695 816.195557,52.285004 814.510010,54.984718 
M464.127228,154.391266 
	C462.237030,150.906631 460.111908,147.524704 458.529541,143.905487 
	C457.219604,140.909409 455.515076,139.419983 452.143799,139.955505 
	C450.519257,140.213562 448.789459,140.202866 447.160248,139.959595 
	C443.241852,139.374557 441.349030,141.116501 439.594604,144.608185 
	C431.497894,160.722427 423.028564,176.649261 414.720947,192.657867 
	C412.935822,196.097778 411.267303,199.598190 409.430939,203.300858 
	C422.626373,205.573547 423.473022,205.161407 428.769165,194.535538 
	C429.138916,193.793640 429.611420,193.085098 429.862366,192.305130 
	C430.689850,189.732727 432.280243,188.816895 435.064819,188.929138 
	C440.884827,189.163727 446.819000,189.676880 452.528564,188.849518 
	C462.353241,187.425873 468.552734,190.604568 471.261841,200.390427 
	C471.653381,201.804749 473.521271,203.682602 474.849762,203.806732 
	C479.393585,204.231277 484.001587,203.969467 489.523163,203.969467 
	C480.887939,187.067551 472.702667,171.046280 464.127228,154.391266 
M683.000000,171.289246 
	C683.000000,161.026245 683.000000,150.763245 683.000000,140.428818 
	C677.999695,140.428818 673.639221,140.428818 669.000000,140.428818 
	C669.000000,152.493179 668.999695,164.131027 669.000610,175.768875 
	C669.000732,176.767441 668.896423,177.781631 669.031982,178.762115 
	C670.194031,187.166702 673.668213,194.310333 681.008179,198.965408 
	C691.526917,205.636414 702.875977,206.625916 714.697937,203.152939 
	C726.809204,199.595001 736.158630,189.521255 735.075378,175.683487 
	C734.622192,169.893799 735.000000,164.039078 735.000000,158.213913 
	C735.000000,152.284286 735.000000,146.354675 735.000000,140.284485 
	C730.390869,140.284485 726.481934,140.284485 722.000000,140.284485 
	C722.000000,142.508255 722.048828,144.457230 721.991821,146.403091 
	C721.681458,157.001297 721.642517,167.618454 720.941833,178.191437 
	C720.072937,191.304016 707.955444,196.118866 697.044617,193.424988 
	C687.595520,191.092026 684.099609,185.394119 683.000000,171.289246 
M1062.607910,148.489182 
	C1049.754272,137.421967 1023.419495,135.255020 1006.267639,147.747330 
	C990.374329,159.323013 990.233276,184.977509 1006.398743,196.248840 
	C1018.286316,204.537399 1031.216431,206.895218 1044.975220,203.715240 
	C1051.655762,202.171219 1057.916626,198.811005 1065.064941,195.998566 
	C1062.763062,193.411514 1061.194458,192.244110 1060.438354,190.683167 
	C1058.455688,186.590057 1056.341797,186.818909 1052.658813,188.980515 
	C1041.038086,195.801010 1029.220215,196.507294 1017.755066,188.375000 
	C1008.802063,182.024597 1006.077759,169.199387 1013.394226,160.103271 
	C1021.490479,150.037643 1035.926514,147.030914 1047.855591,152.948303 
	C1056.762451,157.366562 1056.762451,157.366547 1062.607910,148.489182 
M393.166046,112.996544 
	C401.989349,111.590363 410.464569,109.292183 417.781250,103.590714 
	C416.482086,101.818077 415.050049,100.351318 414.196747,98.602219 
	C412.192047,94.493042 409.981506,94.950424 406.460999,97.089897 
	C395.786499,103.576927 384.566193,104.393402 373.520599,98.111000 
	C361.200012,91.103394 359.039429,74.419540 370.899139,64.389252 
	C380.103851,56.604412 395.645477,56.095901 405.952850,63.337986 
	C409.867279,66.088303 411.390503,65.225327 414.880493,59.373772 
	C417.025696,55.776993 414.350922,54.738194 411.987000,53.495449 
	C395.893707,45.035027 379.756744,44.937794 363.795227,53.678734 
	C352.763214,59.720161 347.505676,69.306000 348.030060,81.867561 
	C348.515503,93.495956 354.723877,101.493286 364.493713,107.226143 
	C373.127960,112.292664 382.508331,113.388985 393.166046,112.996544 
M1105.280151,47.032471 
	C1102.670410,47.352676 1100.066528,47.737408 1097.449707,47.981731 
	C1087.042725,48.953396 1078.049194,52.663082 1070.839600,60.587154 
	C1060.591309,71.851257 1061.637939,93.411880 1074.690674,103.496506 
	C1087.422852,113.333504 1101.523804,114.847641 1116.392334,111.558945 
	C1122.285522,110.255470 1127.718750,106.871811 1134.001587,104.158821 
	C1131.068237,100.244621 1129.046021,97.546265 1126.970215,94.776489 
	C1125.692871,95.304352 1124.673096,95.511444 1123.915039,96.072411 
	C1116.876831,101.280754 1108.901489,102.477745 1100.392456,102.040489 
	C1090.131714,101.513222 1079.157349,91.415054 1078.999878,82.037971 
	C1078.742310,66.692337 1093.521484,54.667255 1108.553101,58.424984 
	C1114.538452,59.921261 1120.224365,62.616112 1126.346313,64.876007 
	C1128.218018,62.269623 1130.180298,59.537251 1132.593506,56.176716 
	C1128.501709,54.180794 1125.124878,52.051346 1121.444702,50.856632 
	C1116.473145,49.242641 1111.268311,48.347065 1105.280151,47.032471 
M285.614136,72.084213 
	C291.927673,68.658089 298.275360,65.292183 304.521057,61.746567 
	C305.562592,61.155323 306.834442,59.797363 306.857117,58.768528 
	C307.044769,50.250500 306.963348,41.726547 306.963348,32.489338 
	C300.735413,35.798859 295.440430,38.667065 290.097015,41.442066 
	C272.705750,50.473904 256.608826,62.201210 236.769424,67.233971 
	C238.114563,70.689392 239.570496,74.293610 240.925369,77.935410 
	C244.870499,88.539703 249.792694,90.641235 259.730408,85.641380 
	C268.208099,81.376068 276.576355,76.893265 285.614136,72.084213 
M690.052490,57.211300 
	C691.548706,57.807449 693.030823,58.872761 694.543579,58.918182 
	C700.949951,59.110523 707.365356,58.999741 714.253296,58.999741 
	C714.253296,77.135452 714.253296,94.381912 714.253296,111.679352 
	C719.345337,111.679352 723.923523,111.679352 729.179077,111.679352 
	C729.179077,94.001625 729.179077,76.631615 729.179077,58.773869 
	C737.445923,58.773869 745.032104,58.773869 752.691711,58.773869 
	C752.691711,55.013557 752.691711,51.766804 752.691711,48.414978 
	C731.686707,48.414978 710.992615,48.414978 690.008972,48.414978 
	C690.008972,51.313965 690.008972,53.799637 690.052490,57.211300 
M1232.000000,75.500000 
	C1232.000000,87.579536 1232.000000,99.659065 1232.000000,111.627083 
	C1250.452393,111.627083 1268.033691,111.627083 1285.685303,111.627083 
	C1285.685303,108.002342 1285.685303,104.754555 1285.685303,100.824310 
	C1272.675537,100.824310 1259.967041,100.824310 1246.765869,100.824310 
	C1246.765869,82.886452 1246.765869,65.634903 1246.765869,48.316177 
	C1241.666504,48.316177 1237.087158,48.316177 1232.000000,48.316177 
	C1232.000000,57.248394 1232.000000,65.874199 1232.000000,75.500000 
M370.000000,171.499863 
	C370.000000,161.084244 370.000000,150.668640 370.000000,140.375824 
	C364.879944,140.375824 360.633026,140.375824 356.317139,140.375824 
	C356.317139,161.665009 356.317139,182.576874 356.317139,203.568588 
	C371.670380,203.568588 386.702545,203.568588 401.624756,203.568588 
	C401.624756,199.882736 401.624756,196.633301 401.624756,192.882996 
	C391.005737,192.882996 380.752808,192.882996 370.000000,192.882996 
	C370.000000,185.749680 370.000000,179.124741 370.000000,171.499863 
M1050.000000,61.508739 
	C1050.000000,57.098167 1050.000000,52.687599 1050.000000,48.351753 
	C1044.581055,48.351753 1040.008423,48.351753 1035.420654,48.351753 
	C1035.420654,69.704636 1035.420654,90.702980 1035.420654,111.647995 
	C1040.419434,111.647995 1044.992065,111.647995 1050.000000,111.647995 
	C1050.000000,95.034912 1050.000000,78.770058 1050.000000,61.508739 
M664.238770,111.082031 
	C668.696960,111.472618 673.155212,111.863205 678.000000,112.287659 
	C678.000000,100.736237 678.000000,90.274223 678.000000,79.812202 
	C678.000000,69.383835 678.000000,58.955467 678.000000,48.414097 
	C673.028076,48.414097 668.669861,48.414097 664.047913,48.414097 
	C664.047913,69.272865 664.047913,89.718956 664.238770,111.082031 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M31.090481,122.439293 
	C38.683582,95.219193 56.815563,77.338982 79.904060,63.539467 
	C93.670250,55.311699 108.481667,49.946491 124.071503,46.382435 
	C138.844101,43.005219 147.511902,48.262947 151.030579,63.161285 
	C151.923447,66.941727 153.102020,70.654694 154.239700,74.721825 
	C139.294998,74.988304 125.458611,78.468872 112.345139,84.132912 
	C88.524780,94.421539 68.386627,109.265045 56.454002,133.196686 
	C54.870762,136.371964 53.622143,139.914307 53.181644,143.408081 
	C52.641079,147.695541 53.301067,152.389999 57.892078,154.318039 
	C64.138618,156.941330 70.637680,160.388321 77.145233,160.657349 
	C89.292557,161.159515 101.696289,160.662643 113.675423,158.670212 
	C127.632835,156.348740 141.742310,153.257538 154.895386,148.189484 
	C173.367477,141.071930 191.019028,131.824860 209.022095,123.489822 
	C209.617599,123.214119 210.219330,122.951859 211.401535,122.368248 
	C221.994507,116.698311 232.004105,111.343452 242.013702,105.988602 
	C253.838638,99.662575 265.702301,93.407555 277.476379,86.988274 
	C287.078735,81.753044 296.574005,76.321426 306.989105,70.488342 
	C306.989105,81.168442 307.050720,91.058205 306.891357,100.944397 
	C306.875824,101.905647 305.683533,103.144402 304.720306,103.749199 
	C291.267181,112.196236 277.770233,120.573799 264.254456,128.920486 
	C261.474976,130.636963 258.574829,132.157990 255.040039,133.905914 
	C253.554413,134.208954 252.666992,134.201675 251.977570,134.567535 
	C242.534271,139.578979 233.106583,144.620377 223.717834,149.732986 
	C222.965332,150.142746 222.527679,151.130707 221.943710,151.849960 
	C201.621353,160.879379 181.619522,170.752213 160.863556,178.640732 
	C147.099915,183.871750 132.444336,187.734894 117.854546,189.595886 
	C92.682549,192.806702 68.104713,189.657944 48.020203,172.170288 
	C39.009460,164.324600 33.838219,153.473328 31.469837,141.240967 
	C31.112801,134.985229 31.067513,128.944077 31.090481,122.439293 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910889,111.579178 
	C1340.845581,112.023460 1326.850464,112.026054 1312.855469,111.995232 
	C1302.592529,111.972633 1296.004028,105.434814 1296.001221,95.311867 
	C1295.996948,79.828728 1296.000000,64.345596 1296.000000,48.432045 
	C1314.970825,48.432045 1333.663696,48.432045 1352.679932,48.432045 
	C1352.679932,51.742352 1352.679932,54.989918 1352.679932,58.749298 
	C1338.721313,58.749298 1324.803833,58.749298 1310.446045,58.749298 
	C1310.446045,63.936825 1310.446045,68.635887 1310.446045,73.853409 
	C1323.389282,73.853409 1336.305908,73.853409 1349.606812,73.853409 
	C1349.606812,77.386475 1349.606812,80.414215 1349.606812,83.957916 
	C1336.504883,83.957916 1323.586670,83.957916 1310.034912,83.957916 
	C1310.034912,88.048218 1309.732300,91.686584 1310.111328,95.252480 
	C1310.587646,99.733360 1313.761597,101.057663 1317.937378,101.028282 
	C1329.932617,100.943871 1341.928711,100.992348 1354.382568,100.976494 
	C1354.887573,104.350647 1354.934448,107.736908 1354.910889,111.579178 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563843,31.089970 
	C549.853333,32.743141 548.691650,34.333981 547.504700,35.905727 
	C542.294250,42.805531 538.868652,44.003822 527.741943,42.445869 
	C530.527771,38.688408 533.099426,35.219772 535.812073,31.454044 
	C540.669067,31.110916 545.385132,31.064877 550.563843,31.089970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.984283,105.615341 
	C232.004105,111.343452 221.994507,116.698311 211.627731,122.087723 
	C205.866150,108.901680 200.461746,95.681091 194.779572,81.780983 
	C190.007050,91.954376 185.587677,101.465668 181.087753,110.938690 
	C177.480637,118.532181 174.142456,126.292786 169.923965,133.536041 
	C168.355652,136.228867 164.797791,137.973862 161.848618,139.614288 
	C150.390350,145.987656 137.879684,148.362503 123.971596,149.288116 
	C126.216576,144.736755 127.982674,140.900558 129.966660,137.180542 
	C136.733124,124.493286 143.594849,111.856880 150.385437,99.182442 
	C158.584991,83.878181 166.804962,68.584297 174.882370,53.215626 
	C176.538574,50.064400 178.596069,48.814213 182.253372,48.908882 
	C192.406113,49.171688 202.571106,49.079853 212.729019,48.950962 
	C215.730408,48.912876 217.202240,49.679348 218.550217,52.773731 
	C225.410461,68.521858 232.746124,84.062622 239.876251,99.693817 
	C240.693893,101.486328 241.267899,103.389969 241.984283,105.615341 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M530.752014,179.252441 
	C525.669556,173.448074 520.839050,167.896149 515.000488,161.185577 
	C515.000488,176.311554 515.000488,189.846634 515.000488,203.684708 
	C509.911285,203.684708 505.333344,203.684708 500.376038,203.684708 
	C500.376038,182.706589 500.376038,161.801147 500.376038,140.121735 
	C504.796326,140.121735 509.720673,139.082367 513.836975,140.459030 
	C516.984985,141.511887 519.210999,145.533951 521.673096,148.385529 
	C529.119507,157.009811 536.477234,165.710632 543.878235,174.374130 
	C546.219543,177.114868 548.588379,179.832153 551.793579,183.543121 
	C551.793579,168.477707 551.793579,154.639297 551.793579,140.404449 
	C556.694397,140.404449 561.054016,140.404449 565.711853,140.404449 
	C565.711853,161.473297 565.711853,182.377838 565.711853,203.249542 
	C554.790100,205.830826 552.089966,204.685089 545.609558,196.384644 
	C541.036621,190.527328 535.892944,185.115585 530.752014,179.252441 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M886.731018,150.231476 
	C898.777100,159.927856 900.053589,179.783920 889.453003,191.759338 
	C883.962891,197.961487 876.342651,201.811340 868.235657,202.606293 
	C854.770874,203.926651 841.178711,203.948700 827.305298,204.531647 
	C827.305298,182.565369 827.305298,161.665771 827.305298,140.000000 
	C829.462036,140.000000 831.556824,139.908401 833.641357,140.015030 
	C846.189270,140.656830 858.828613,140.653885 871.237305,142.339310 
	C876.564636,143.062881 881.411316,147.324921 886.731018,150.231476 
M876.008606,185.490234 
	C885.258911,177.061905 882.322021,161.879166 872.937378,155.990616 
	C863.404968,150.009323 852.893616,150.758942 842.315186,151.252289 
	C842.315186,165.349518 842.315186,178.917999 842.315186,192.940216 
	C848.241089,192.940216 853.926758,193.483231 859.458008,192.791260 
	C865.277893,192.063202 871.304443,190.972672 876.008606,185.490234 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M476.957336,110.423599 
	C471.485962,103.948433 466.428894,97.626823 460.953888,91.690765 
	C459.775391,90.413071 457.110718,90.216423 455.090729,90.069267 
	C451.642273,89.818039 448.162292,90.000000 444.201111,90.000000 
	C444.201111,97.332520 444.201111,104.247322 444.201111,111.578392 
	C439.344421,111.578392 434.983704,111.578392 430.308228,111.578392 
	C430.308228,90.577896 430.308228,69.682671 430.308228,48.481987 
	C431.415649,48.321152 432.522430,47.981907 433.612488,48.029068 
	C448.153595,48.658268 462.941498,47.906784 477.168335,50.376766 
	C494.708679,53.422016 497.120697,71.352997 488.945251,80.577423 
	C485.819641,84.104034 481.069550,86.190865 476.545837,89.285378 
	C479.228882,92.498573 482.307251,96.264694 485.473907,99.955032 
	C488.583588,103.578987 491.782990,107.125938 494.809174,111.400757 
	C488.876923,111.431450 483.025360,113.399445 476.957336,110.423599 
M477.924103,71.863655 
	C478.068604,64.910446 476.647247,61.557968 470.789978,60.614498 
	C462.105530,59.215614 453.206055,59.151848 444.341919,58.515701 
	C444.341919,66.061943 444.341919,72.303856 444.341919,79.000000 
	C450.801331,79.000000 456.942871,78.727394 463.048889,79.076523 
	C469.179291,79.427040 474.136932,77.787186 477.924103,71.863655 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M975.998291,48.000000 
	C985.385925,48.668434 994.491699,48.337727 1003.116821,50.215199 
	C1020.582458,54.017033 1023.137024,71.339508 1014.958618,80.550865 
	C1011.825928,84.079170 1007.085083,86.179657 1002.479736,89.340797 
	C1008.327026,96.142288 1014.611633,103.452400 1021.652161,111.641876 
	C1015.292847,111.641876 1009.482849,112.768753 1004.477234,111.247925 
	C1001.108398,110.224373 998.831604,105.496613 996.148621,102.355621 
	C993.363708,99.095169 990.875427,95.552887 987.858887,92.531723 
	C986.514893,91.185692 984.245422,90.300385 982.307068,90.109612 
	C978.522827,89.737152 974.676025,90.000336 970.167114,90.000336 
	C970.167114,97.300720 970.167114,104.349854 970.167114,111.688095 
	C965.237610,111.688095 960.991150,111.688095 956.370789,111.688095 
	C956.370789,90.691376 956.370789,69.780411 956.370789,48.000000 
	C962.665527,48.000000 969.082214,48.000000 975.998291,48.000000 
M992.142334,78.999802 
	C998.575317,78.523651 1003.572388,74.977013 1003.992981,70.588867 
	C1004.490417,65.398735 1001.661377,60.971119 996.100281,60.287235 
	C987.650879,59.248173 979.090271,59.113033 970.411255,58.583843 
	C970.411255,65.978439 970.411255,72.331963 970.411255,79.000000 
	C977.618652,79.000000 984.418762,79.000000 992.142334,78.999802 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M594.010620,112.000000 
	C589.556152,112.000000 585.599915,112.000000 581.327820,112.000000 
	C581.327820,90.624664 581.327820,69.737816 581.327820,48.818165 
	C581.545349,48.636456 581.803101,48.236282 582.044373,48.245991 
	C596.072632,48.810555 610.206360,48.546688 624.093811,50.268196 
	C635.899597,51.731678 645.554810,58.662109 648.777100,70.528938 
	C653.864929,89.266182 646.525085,104.490570 626.034729,109.671211 
	C615.972961,112.215141 605.044800,111.332138 594.010620,112.000000 
M622.453125,61.810925 
	C613.585938,58.691414 604.488525,58.733040 595.325867,59.254627 
	C595.325867,73.394180 595.325867,86.951530 595.325867,100.942078 
	C601.924500,100.942078 608.284485,101.575554 614.462769,100.786392 
	C622.726074,99.730904 630.567383,96.541527 633.742188,88.053818 
	C637.295898,78.553131 635.307922,67.789993 622.453125,61.810925 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1193.829346,111.016937 
	C1176.608398,115.172745 1160.834351,113.980194 1147.779785,101.207748 
	C1133.638916,87.372482 1136.670776,64.046593 1153.768921,54.106812 
	C1171.198120,43.974564 1193.319824,44.460762 1208.861816,57.714935 
	C1221.173096,68.213959 1223.488525,86.175026 1211.722778,99.344711 
	C1207.207275,104.398941 1200.141724,107.174805 1193.829346,111.016937 
M1168.475708,100.196487 
	C1173.343140,100.796921 1178.278687,102.214828 1183.062622,101.815239 
	C1191.201294,101.135452 1197.738403,96.930199 1201.704712,89.556168 
	C1205.594116,82.325508 1204.590088,75.253471 1200.221313,68.538971 
	C1193.328735,57.945339 1176.095459,54.820732 1164.415161,62.108833 
	C1157.276367,66.563240 1153.396729,72.936028 1154.028931,81.531372 
	C1154.689209,90.508316 1159.957153,96.221245 1168.475708,100.196487 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M922.046509,199.026520 
	C908.821533,192.227341 904.417053,178.679749 905.228638,170.474442 
	C906.565247,156.959961 914.294312,147.461182 926.912354,142.683640 
	C942.425842,136.809799 957.706177,137.501572 971.832458,146.975677 
	C991.236633,159.989624 989.681091,188.369339 968.326843,199.253082 
	C953.081909,207.023026 937.688904,207.436813 922.046509,199.026520 
M921.016479,166.839081 
	C918.662537,176.269516 921.968384,183.762375 929.564453,189.152786 
	C939.933350,196.510941 954.019104,196.013840 963.453247,187.647125 
	C971.694458,180.338287 972.955811,168.658707 966.818787,160.492218 
	C953.989624,143.420746 927.470886,148.118744 921.016479,166.839081 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M899.810059,59.607460 
	C887.188660,62.755264 882.087585,74.871246 883.051147,82.270767 
	C884.520813,93.557312 894.430908,102.048569 906.145569,101.952919 
	C909.920166,101.922104 913.848572,101.902405 917.431702,100.897377 
	C926.886414,98.245422 927.015930,97.717987 925.451843,87.150993 
	C919.623657,87.150993 913.702881,87.150993 907.395020,87.150993 
	C907.395020,83.284187 907.395020,79.923286 907.395020,76.261581 
	C908.857483,76.167137 910.148621,76.014359 911.440125,76.011475 
	C920.736450,75.990730 930.032715,76.000366 939.987244,76.000366 
	C939.987244,84.890076 940.133240,93.332657 939.837708,101.759758 
	C939.790710,103.099831 938.015381,104.861420 936.605347,105.598129 
	C919.518799,114.525772 901.934387,116.526817 884.328796,107.557014 
	C873.027344,101.799026 867.686646,91.840240 868.040222,79.141975 
	C868.368042,67.366890 874.082581,58.705223 884.432129,53.293087 
	C900.364258,44.961643 916.351501,45.121712 932.215698,53.639408 
	C934.412048,54.818645 937.412354,56.172966 934.820007,59.043591 
	C932.733582,61.354000 932.058167,67.456436 926.540344,63.748913 
	C918.369751,58.258865 909.659668,56.576012 899.810059,59.607460 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M605.537415,141.261444 
	C619.399170,137.533813 632.380066,138.617065 644.715759,145.365555 
	C646.254944,146.207581 647.609680,147.386856 649.064575,148.420319 
	C648.688782,149.261017 648.580872,149.769989 648.285889,150.122589 
	C642.817444,156.659897 642.875610,156.513458 634.977539,153.030746 
	C624.051941,148.212967 613.388306,148.606339 603.651245,155.968063 
	C597.096191,160.923935 594.709839,168.089340 596.231140,175.889084 
	C597.955322,184.729126 603.889038,190.987488 612.588196,192.691956 
	C618.824707,193.913895 625.614990,193.088867 632.043213,192.272003 
	C636.180542,191.746262 639.165894,189.018387 638.057922,183.880234 
	C637.690979,182.178787 638.000000,180.331573 638.000000,178.050476 
	C631.514343,178.050476 625.592163,178.050476 619.344238,178.050476 
	C619.344238,174.642502 619.344238,171.730301 619.344238,168.402878 
	C630.343506,168.402878 641.369873,168.402878 652.989929,168.402878 
	C652.989929,176.643829 653.107666,184.755096 652.874023,192.856216 
	C652.835144,194.205856 651.576233,196.022888 650.358276,196.760223 
	C633.341675,207.061691 608.724609,209.202393 591.598694,195.717606 
	C579.151001,185.916351 576.744568,165.031708 587.874817,152.604538 
	C592.636780,147.287643 598.398987,143.603470 605.537415,141.261444 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M766.155396,177.322083 
	C765.810059,192.054688 766.742493,193.000000 781.076477,193.000000 
	C790.862244,193.000000 800.648071,193.000000 810.711670,193.000000 
	C810.711670,196.808594 810.711670,200.055481 810.711670,204.000000 
	C803.637390,204.000000 796.696411,204.122910 789.761658,203.968552 
	C781.315918,203.780548 772.800232,203.942230 764.454712,202.866760 
	C756.865051,201.888702 752.286499,196.498184 752.117920,188.802643 
	C751.768066,172.824341 752.012329,156.833023 752.012329,140.424347 
	C770.991516,140.424347 789.688599,140.424347 808.694336,140.424347 
	C808.694336,143.773087 808.694336,147.020508 808.694336,150.784485 
	C794.686401,150.784485 780.765991,150.784485 766.425903,150.784485 
	C766.425903,155.650299 766.425903,160.014755 766.425903,164.900726 
	C779.436768,164.900726 792.357056,164.900726 805.633545,164.900726 
	C805.633545,168.644745 805.633545,171.893860 805.633545,176.000000 
	C803.475891,176.000000 801.226868,176.000000 798.977905,176.000000 
	C789.644775,176.000031 780.311340,175.959763 770.978882,176.039291 
	C769.402588,176.052719 767.830627,176.582504 766.155396,177.322083 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M553.984009,112.000000 
	C543.989624,112.000000 534.491638,112.015442 524.993835,111.995949 
	C513.889099,111.973167 507.021667,105.136230 507.005493,94.061501 
	C506.983429,78.934097 507.000000,63.806644 507.000000,48.341408 
	C526.369873,48.341408 545.278748,48.341408 564.594849,48.341408 
	C564.594849,51.694805 564.594849,55.054863 564.594849,58.936218 
	C550.193359,58.936218 535.942017,58.936218 521.350403,58.936218 
	C521.350403,64.017929 521.350403,68.596886 521.350403,73.868057 
	C534.344055,73.868057 547.387756,73.868057 560.706787,73.868057 
	C560.706787,77.463531 560.706787,80.377975 560.706787,83.806366 
	C547.668213,83.806366 534.750000,83.806366 520.622314,83.806366 
	C521.210205,88.732193 520.996338,93.324020 522.545715,97.213432 
	C523.313904,99.141884 527.286255,100.727806 529.875061,100.832321 
	C540.022949,101.242020 550.197083,100.999954 560.361511,101.000000 
	C561.998047,101.000008 563.634644,101.000000 565.634216,101.000000 
	C565.634216,104.651154 565.634216,107.900124 565.634216,112.000000 
	C561.980347,112.000000 558.230408,112.000000 553.984009,112.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M814.700195,54.657753 
	C816.195557,52.285004 817.200500,48.825695 818.865173,48.472126 
	C823.006653,47.592487 827.451965,48.037029 831.766602,48.191986 
	C832.421692,48.215511 833.208130,49.440426 833.634521,50.264526 
	C843.898621,70.102463 854.123413,89.960701 864.341553,109.822365 
	C864.552307,110.232002 864.600281,110.725395 864.932983,111.944923 
	C859.926758,111.944923 855.186768,112.165123 850.494385,111.789757 
	C849.324463,111.696167 847.677795,109.850014 847.321899,108.522156 
	C844.618530,98.435028 838.189636,95.326736 828.183777,96.849129 
	C822.650696,97.690979 816.875427,97.190712 811.226990,96.923302 
	C808.204468,96.780212 806.560181,97.886299 805.722412,100.610870 
	C805.674500,100.766609 805.551514,100.897644 805.486877,101.050095 
	C800.203247,113.510834 800.203430,113.510918 785.481079,111.419121 
	C795.252075,92.423653 804.881042,73.704185 814.700195,54.657753 
M818.830811,86.000000 
	C824.286255,86.000000 829.741699,86.000000 835.945007,86.000000 
	C832.008667,78.099274 828.365601,70.787315 824.391296,62.810406 
	C820.693176,70.756958 817.308716,78.029434 813.733337,85.712212 
	C815.655640,85.843880 816.794067,85.921852 818.830811,86.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M464.322296,154.708130 
	C472.702667,171.046280 480.887939,187.067551 489.523163,203.969467 
	C484.001587,203.969467 479.393585,204.231277 474.849762,203.806732 
	C473.521271,203.682602 471.653381,201.804749 471.261841,200.390427 
	C468.552734,190.604568 462.353241,187.425873 452.528564,188.849518 
	C446.819000,189.676880 440.884827,189.163727 435.064819,188.929138 
	C432.280243,188.816895 430.689850,189.732727 429.862366,192.305130 
	C429.611420,193.085098 429.138916,193.793640 428.769165,194.535538 
	C423.473022,205.161407 422.626373,205.573547 409.430939,203.300858 
	C411.267303,199.598190 412.935822,196.097778 414.720947,192.657867 
	C423.028564,176.649261 431.497894,160.722427 439.594604,144.608185 
	C441.349030,141.116501 443.241852,139.374557 447.160248,139.959595 
	C448.789459,140.202866 450.519257,140.213562 452.143799,139.955505 
	C455.515076,139.419983 457.219604,140.909409 458.529541,143.905487 
	C460.111908,147.524704 462.237030,150.906631 464.322296,154.708130 
M443.880371,164.359650 
	C441.697296,168.743195 439.514221,173.126724 437.259644,177.653839 
	C444.981476,177.653839 452.126221,177.653839 459.816101,177.653839 
	C455.951385,169.951065 452.337769,162.748825 448.515533,155.130737 
	C446.835602,158.383713 445.475403,161.017593 443.880371,164.359650 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M683.000244,171.760803 
	C684.099609,185.394119 687.595520,191.092026 697.044617,193.424988 
	C707.955444,196.118866 720.072937,191.304016 720.941833,178.191437 
	C721.642517,167.618454 721.681458,157.001297 721.991821,146.403091 
	C722.048828,144.457230 722.000000,142.508255 722.000000,140.284485 
	C726.481934,140.284485 730.390869,140.284485 735.000000,140.284485 
	C735.000000,146.354675 735.000000,152.284286 735.000000,158.213913 
	C735.000000,164.039078 734.622192,169.893799 735.075378,175.683487 
	C736.158630,189.521255 726.809204,199.595001 714.697937,203.152939 
	C702.875977,206.625916 691.526917,205.636414 681.008179,198.965408 
	C673.668213,194.310333 670.194031,187.166702 669.031982,178.762115 
	C668.896423,177.781631 669.000732,176.767441 669.000610,175.768875 
	C668.999695,164.131027 669.000000,152.493179 669.000000,140.428818 
	C673.639221,140.428818 677.999695,140.428818 683.000000,140.428818 
	C683.000000,150.763245 683.000000,161.026245 683.000244,171.760803 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.249176,152.051559 
	C222.527679,151.130707 222.965332,150.142746 223.717834,149.732986 
	C233.106583,144.620377 242.534271,139.578979 251.977570,134.567535 
	C252.666992,134.201675 253.554413,134.208954 254.732513,134.070114 
	C260.956604,146.476974 266.818359,158.846909 272.629791,171.240448 
	C275.127045,176.566132 277.519806,181.940796 280.266357,187.965057 
	C267.253296,187.965057 254.987122,188.182007 242.735672,187.852341 
	C238.189972,187.730011 235.717728,184.213165 234.067825,180.098328 
	C230.330032,170.776535 226.404083,161.530182 222.249176,152.051559 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1062.452637,148.795288 
	C1056.762451,157.366547 1056.762451,157.366562 1047.855591,152.948303 
	C1035.926514,147.030914 1021.490479,150.037643 1013.394226,160.103271 
	C1006.077759,169.199387 1008.802063,182.024597 1017.755066,188.375000 
	C1029.220215,196.507294 1041.038086,195.801010 1052.658813,188.980515 
	C1056.341797,186.818909 1058.455688,186.590057 1060.438354,190.683167 
	C1061.194458,192.244110 1062.763062,193.411514 1065.064941,195.998566 
	C1057.916626,198.811005 1051.655762,202.171219 1044.975220,203.715240 
	C1031.216431,206.895218 1018.286316,204.537399 1006.398743,196.248840 
	C990.233276,184.977509 990.374329,159.323013 1006.267639,147.747330 
	C1023.419495,135.255020 1049.754272,137.421967 1062.452637,148.795288 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M392.701355,112.998047 
	C382.508331,113.388985 373.127960,112.292664 364.493713,107.226143 
	C354.723877,101.493286 348.515503,93.495956 348.030060,81.867561 
	C347.505676,69.306000 352.763214,59.720161 363.795227,53.678734 
	C379.756744,44.937794 395.893707,45.035027 411.987000,53.495449 
	C414.350922,54.738194 417.025696,55.776993 414.880493,59.373772 
	C411.390503,65.225327 409.867279,66.088303 405.952850,63.337986 
	C395.645477,56.095901 380.103851,56.604412 370.899139,64.389252 
	C359.039429,74.419540 361.200012,91.103394 373.520599,98.111000 
	C384.566193,104.393402 395.786499,103.576927 406.460999,97.089897 
	C409.981506,94.950424 412.192047,94.493042 414.196747,98.602219 
	C415.050049,100.351318 416.482086,101.818077 417.781250,103.590714 
	C410.464569,109.292183 401.989349,111.590363 392.701355,112.998047 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1105.721924,47.087852 
	C1111.268311,48.347065 1116.473145,49.242641 1121.444702,50.856632 
	C1125.124878,52.051346 1128.501709,54.180794 1132.593506,56.176716 
	C1130.180298,59.537251 1128.218018,62.269623 1126.346313,64.876007 
	C1120.224365,62.616112 1114.538452,59.921261 1108.553101,58.424984 
	C1093.521484,54.667255 1078.742310,66.692337 1078.999878,82.037971 
	C1079.157349,91.415054 1090.131714,101.513222 1100.392456,102.040489 
	C1108.901489,102.477745 1116.876831,101.280754 1123.915039,96.072411 
	C1124.673096,95.511444 1125.692871,95.304352 1126.970215,94.776489 
	C1129.046021,97.546265 1131.068237,100.244621 1134.001587,104.158821 
	C1127.718750,106.871811 1122.285522,110.255470 1116.392334,111.558945 
	C1101.523804,114.847641 1087.422852,113.333504 1074.690674,103.496506 
	C1061.637939,93.411880 1060.591309,71.851257 1070.839600,60.587154 
	C1078.049194,52.663082 1087.042725,48.953396 1097.449707,47.981731 
	C1100.066528,47.737408 1102.670410,47.352676 1105.721924,47.087852 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M285.303589,72.295441 
	C276.576355,76.893265 268.208099,81.376068 259.730408,85.641380 
	C249.792694,90.641235 244.870499,88.539703 240.925369,77.935410 
	C239.570496,74.293610 238.114563,70.689392 236.769424,67.233971 
	C256.608826,62.201210 272.705750,50.473904 290.097015,41.442066 
	C295.440430,38.667065 300.735413,35.798859 306.963348,32.489338 
	C306.963348,41.726547 307.044769,50.250500 306.857117,58.768528 
	C306.834442,59.797363 305.562592,61.155323 304.521057,61.746567 
	C298.275360,65.292183 291.927673,68.658089 285.303589,72.295441 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M690.030762,56.748306 
	C690.008972,53.799637 690.008972,51.313965 690.008972,48.414978 
	C710.992615,48.414978 731.686707,48.414978 752.691711,48.414978 
	C752.691711,51.766804 752.691711,55.013557 752.691711,58.773869 
	C745.032104,58.773869 737.445923,58.773869 729.179077,58.773869 
	C729.179077,76.631615 729.179077,94.001625 729.179077,111.679352 
	C723.923523,111.679352 719.345337,111.679352 714.253296,111.679352 
	C714.253296,94.381912 714.253296,77.135452 714.253296,58.999741 
	C707.365356,58.999741 700.949951,59.110523 694.543579,58.918182 
	C693.030823,58.872761 691.548706,57.807449 690.030762,56.748306 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1232.000000,75.000000 
	C1232.000000,65.874199 1232.000000,57.248394 1232.000000,48.316177 
	C1237.087158,48.316177 1241.666504,48.316177 1246.765869,48.316177 
	C1246.765869,65.634903 1246.765869,82.886452 1246.765869,100.824310 
	C1259.967041,100.824310 1272.675537,100.824310 1285.685303,100.824310 
	C1285.685303,104.754555 1285.685303,108.002342 1285.685303,111.627083 
	C1268.033691,111.627083 1250.452393,111.627083 1232.000000,111.627083 
	C1232.000000,99.659065 1232.000000,87.579536 1232.000000,75.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M370.000000,171.999817 
	C370.000000,179.124741 370.000000,185.749680 370.000000,192.882996 
	C380.752808,192.882996 391.005737,192.882996 401.624756,192.882996 
	C401.624756,196.633301 401.624756,199.882736 401.624756,203.568588 
	C386.702545,203.568588 371.670380,203.568588 356.317139,203.568588 
	C356.317139,182.576874 356.317139,161.665009 356.317139,140.375824 
	C360.633026,140.375824 364.879944,140.375824 370.000000,140.375824 
	C370.000000,150.668640 370.000000,161.084244 370.000000,171.999817 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1050.000000,62.006966 
	C1050.000000,78.770058 1050.000000,95.034912 1050.000000,111.647995 
	C1044.992065,111.647995 1040.419434,111.647995 1035.420654,111.647995 
	C1035.420654,90.702980 1035.420654,69.704636 1035.420654,48.351753 
	C1040.008423,48.351753 1044.581055,48.351753 1050.000000,48.351753 
	C1050.000000,52.687599 1050.000000,57.098167 1050.000000,62.006966 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M664.143311,110.623535 
	C664.047913,89.718956 664.047913,69.272865 664.047913,48.414097 
	C668.669861,48.414097 673.028076,48.414097 678.000000,48.414097 
	C678.000000,58.955467 678.000000,69.383835 678.000000,79.812202 
	C678.000000,90.274223 678.000000,100.736237 678.000000,112.287659 
	C673.155212,111.863205 668.696960,111.472618 664.143311,110.623535 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M875.751099,185.747818 
	C871.304443,190.972672 865.277893,192.063202 859.458008,192.791260 
	C853.926758,193.483231 848.241089,192.940216 842.315186,192.940216 
	C842.315186,178.917999 842.315186,165.349518 842.315186,151.252289 
	C852.893616,150.758942 863.404968,150.009323 872.937378,155.990616 
	C882.322021,161.879166 885.258911,177.061905 875.751099,185.747818 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M477.807922,72.242287 
	C474.136932,77.787186 469.179291,79.427040 463.048889,79.076523 
	C456.942871,78.727394 450.801331,79.000000 444.341919,79.000000 
	C444.341919,72.303856 444.341919,66.061943 444.341919,58.515701 
	C453.206055,59.151848 462.105530,59.215614 470.789978,60.614498 
	C476.647247,61.557968 478.068604,64.910446 477.807922,72.242287 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M991.680603,78.999901 
	C984.418762,79.000000 977.618652,79.000000 970.411255,79.000000 
	C970.411255,72.331963 970.411255,65.978439 970.411255,58.583843 
	C979.090271,59.113033 987.650879,59.248173 996.100281,60.287235 
	C1001.661377,60.971119 1004.490417,65.398735 1003.992981,70.588867 
	C1003.572388,74.977013 998.575317,78.523651 991.680603,78.999901 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M622.821655,61.936211 
	C635.307922,67.789993 637.295898,78.553131 633.742188,88.053818 
	C630.567383,96.541527 622.726074,99.730904 614.462769,100.786392 
	C608.284485,101.575554 601.924500,100.942078 595.325867,100.942078 
	C595.325867,86.951530 595.325867,73.394180 595.325867,59.254627 
	C604.488525,58.733040 613.585938,58.691414 622.821655,61.936211 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1168.090088,100.114403 
	C1159.957153,96.221245 1154.689209,90.508316 1154.028931,81.531372 
	C1153.396729,72.936028 1157.276367,66.563240 1164.415161,62.108833 
	C1176.095459,54.820732 1193.328735,57.945339 1200.221313,68.538971 
	C1204.590088,75.253471 1205.594116,82.325508 1201.704712,89.556168 
	C1197.738403,96.930199 1191.201294,101.135452 1183.062622,101.815239 
	C1178.278687,102.214828 1173.343140,100.796921 1168.090088,100.114403 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M921.053467,166.436920 
	C927.470886,148.118744 953.989624,143.420746 966.818787,160.492218 
	C972.955811,168.658707 971.694458,180.338287 963.453247,187.647125 
	C954.019104,196.013840 939.933350,196.510941 929.564453,189.152786 
	C921.968384,183.762375 918.662537,176.269516 921.053467,166.436920 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M818.381714,85.999916 
	C816.794067,85.921852 815.655640,85.843880 813.733337,85.712212 
	C817.308716,78.029434 820.693176,70.756958 824.391296,62.810406 
	C828.365601,70.787315 832.008667,78.099274 835.945007,86.000000 
	C829.741699,86.000000 824.286255,86.000000 818.381714,85.999916 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M443.997803,164.005554 
	C445.475403,161.017593 446.835602,158.383713 448.515533,155.130737 
	C452.337769,162.748825 455.951385,169.951065 459.816101,177.653839 
	C452.126221,177.653839 444.981476,177.653839 437.259644,177.653839 
	C439.514221,173.126724 441.697296,168.743195 443.997803,164.005554 
z"
      />
    </svg>
  );
};

CaLanguedocSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaLanguedocSvg;
