import React from 'react';
import PropTypes from 'prop-types';
import IconLegalNoticeSvg from './svg/icon-legal-notice-svg';
import './styles.scss';

const IconLegalNotice = props => {
  const { title, alt, width, height, manageRedirection, dataTestId } = props;
  return (
    <div className="legal__notice" onClick={manageRedirection}>
      <IconLegalNoticeSvg
        className="legal__notice__icon"
        data-testid={dataTestId}
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconLegalNotice.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  manageRedirection: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};

export default IconLegalNotice;
