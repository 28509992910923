import * as React from 'react';
import PropTypes from 'prop-types';

const CaNordMidiPyrenesSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1692 255"
      enableBackground="new 0 0 1692 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1282.000000,256.000000 
	C854.721436,256.000000 427.942841,256.000000 1.082124,256.000000 
	C1.082124,171.065720 1.082124,86.131340 1.082124,1.098479 
	C565.000000,1.098479 1129.000000,1.098479 1692.973389,1.098479 
	C1692.973389,86.057304 1692.973389,171.028656 1692.973389,256.000000 
	C1556.131226,256.000000 1419.315552,256.000000 1282.000000,256.000000 
M550.099976,31.018829 
	C545.054871,31.065655 540.009705,31.112480 534.042114,31.011251 
	C366.421722,31.011251 198.801346,31.011251 31.158768,31.011251 
	C31.158768,61.399761 31.158768,91.187759 31.022226,121.903229 
	C31.067513,128.277603 31.112799,134.651993 31.011166,141.950104 
	C31.007444,168.484467 30.973221,195.018967 31.083773,221.552856 
	C31.089739,222.984695 32.309902,224.411499 33.895641,225.981522 
	C117.447418,225.987671 200.999191,225.994461 284.550964,225.993332 
	C285.712463,225.993317 286.873932,225.893784 288.957886,225.988754 
	C745.623169,225.992508 1202.288452,225.996445 1658.953735,225.991486 
	C1660.227173,225.991470 1661.500488,225.861816 1662.842773,225.788986 
	C1662.842773,213.318756 1662.842773,201.182083 1662.981445,188.155319 
	C1662.935669,185.753189 1662.889893,183.351044 1662.989014,180.028061 
	C1662.989014,130.443893 1662.989014,80.859741 1662.989014,31.000000 
	C1660.683594,31.000000 1658.880249,31.000000 1657.077026,31.000000 
	C1290.222168,31.000000 923.367249,30.999767 556.512329,31.006647 
	C554.683777,31.006681 552.855164,31.107403 550.099976,31.018829 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1662.842773,189.045410 
	C1662.842773,201.182083 1662.842773,213.318756 1662.842773,225.788986 
	C1661.500488,225.861816 1660.227173,225.991470 1658.953735,225.991486 
	C1202.288452,225.996445 745.623169,225.992508 288.484619,225.456665 
	C288.011383,217.687683 288.011383,210.450806 288.011383,203.447189 
	C202.481262,203.447189 117.866196,203.447189 32.964851,203.447189 
	C32.964851,211.208725 32.964851,218.524673 32.964851,225.840637 
	C32.309902,224.411499 31.089739,222.984695 31.083773,221.552856 
	C30.973221,195.018967 31.007444,168.484467 31.396858,141.703751 
	C35.027924,159.187805 44.615135,172.296234 60.490799,180.807251 
	C81.005219,191.805130 103.031464,192.250351 125.128036,188.716309 
	C148.991669,184.899658 171.213379,175.569473 193.044876,165.454041 
	C202.705200,160.978012 212.312729,156.388077 222.247009,152.059296 
	C226.691833,162.155411 230.885773,172.020813 234.952179,181.938522 
	C236.764206,186.357910 239.837662,188.156845 244.624130,188.062515 
	C254.947464,187.859055 265.277618,188.014175 275.604858,187.982819 
	C276.981384,187.978638 278.357147,187.743210 280.226562,187.568848 
	C274.088837,174.919662 268.177490,162.910141 262.433472,150.821136 
	C259.823303,145.327713 257.544373,139.676880 255.422729,133.933258 
	C271.511871,124.169960 287.313019,114.602600 303.037750,104.911194 
	C304.288513,104.140335 305.820160,102.530052 305.849518,101.277733 
	C306.080688,91.412819 305.977875,81.540077 305.977875,71.332565 
	C284.290527,83.077240 263.153320,94.523987 241.985992,105.602173 
	C234.021561,87.614754 226.055786,70.009918 218.189987,52.360516 
	C217.121170,49.962250 215.835693,48.874710 213.008759,48.923996 
	C202.183014,49.112717 191.348907,48.871792 180.526260,49.134575 
	C178.831741,49.175716 176.472031,50.588604 175.625519,52.060547 
	C170.598465,60.801884 165.903442,69.735062 161.139801,78.626801 
	C151.379654,96.844986 141.625229,115.066322 131.916565,133.311966 
	C129.252487,138.318604 126.758835,143.415909 123.833496,149.166489 
	C136.285004,148.586792 147.663574,146.833878 158.026520,141.328506 
	C162.717194,138.836563 168.135590,135.925476 170.745590,131.680710 
	C176.079941,123.005203 179.726395,113.293427 184.089172,104.018402 
	C187.485718,96.797546 190.914291,89.591751 194.707916,81.578041 
	C195.739929,84.014603 196.409195,85.483482 196.991104,86.986168 
	C201.627151,98.957993 206.246613,110.936226 210.512726,122.962898 
	C198.318695,128.709473 186.508102,134.458389 174.642548,140.091522 
	C144.016495,154.631149 111.779839,162.044632 77.848518,160.786148 
	C71.693253,160.557846 65.348473,158.199234 59.619873,155.640442 
	C53.245102,152.793045 51.894474,147.308014 53.833370,140.322205 
	C57.557045,126.905907 66.088684,116.866081 75.896118,107.549828 
	C90.630676,93.553207 107.904518,84.159973 127.459038,79.068909 
	C136.078278,76.824860 144.945572,75.533592 153.995239,73.751678 
	C152.743988,69.618698 151.447021,65.083824 149.998642,60.597839 
	C145.770020,47.500786 138.103409,43.214386 124.581818,46.223816 
	C108.326309,49.841732 92.790314,55.362526 78.587845,64.155296 
	C56.512943,77.821884 38.928497,95.325790 31.158768,120.975754 
	C31.158768,91.187759 31.158768,61.399761 31.158768,31.011251 
	C198.801346,31.011251 366.421722,31.011251 534.488770,31.430477 
	C532.621399,35.301128 530.307373,38.752560 527.993347,42.203987 
	C528.245300,42.766003 528.497314,43.328014 528.749268,43.890030 
	C533.369019,42.886951 538.490906,42.795013 542.458313,40.608364 
	C545.980164,38.667301 548.220581,34.401001 551.026611,31.161104 
	C552.855164,31.107403 554.683777,31.006681 556.512329,31.006647 
	C923.367249,30.999767 1290.222168,31.000000 1657.077026,31.000000 
	C1658.880249,31.000000 1660.683594,31.000000 1662.989014,31.000000 
	C1662.989014,80.859741 1662.989014,130.443893 1662.642822,180.292953 
	C1659.842285,174.034210 1654.423218,170.818893 1648.290527,169.059402 
	C1638.033691,166.116669 1627.571411,163.894760 1617.288940,161.033493 
	C1615.181641,160.447067 1613.485718,158.382263 1611.600220,156.998840 
	C1613.446777,155.350967 1615.025391,152.943497 1617.184570,152.181870 
	C1628.802124,148.083710 1639.912598,150.307556 1650.436401,156.024490 
	C1654.695312,158.338150 1656.337158,157.643890 1659.881714,151.381821 
	C1662.150146,147.374268 1659.012695,146.119675 1656.527344,145.350357 
	C1649.483032,143.169769 1642.385498,140.906342 1635.138672,139.692688 
	C1623.374756,137.722473 1611.765381,139.286026 1602.169678,146.691696 
	C1594.766724,152.405151 1593.010254,162.768723 1602.293579,169.448654 
	C1605.926636,172.062759 1610.499268,173.649872 1614.874146,174.896576 
	C1624.192383,177.551941 1633.708984,179.511337 1643.029053,182.161148 
	C1644.923584,182.699814 1646.310791,185.023178 1647.931885,186.523834 
	C1646.235107,188.211670 1644.580444,189.945969 1642.811035,191.554047 
	C1642.374146,191.951202 1641.557983,191.917542 1640.927002,192.116501 
	C1629.776123,195.632004 1619.134521,193.225494 1608.669312,189.240311 
	C1605.818237,188.154648 1602.523438,185.052536 1600.493652,185.791428 
	C1597.906982,186.733017 1596.390503,190.630585 1594.438721,193.273102 
	C1594.262573,193.511368 1594.275757,193.889374 1594.074463,194.732620 
	C1595.516113,195.716751 1596.984375,197.086502 1598.719849,197.845810 
	C1612.447144,203.851990 1626.675781,206.852661 1641.628662,203.880203 
	C1650.771240,202.062759 1658.990356,198.588028 1662.842773,189.045410 
M749.000000,163.246292 
	C749.105469,162.381302 749.210938,161.516312 749.416748,159.828674 
	C757.644104,171.042709 765.320496,181.505737 773.463928,192.605331 
	C781.642029,181.444199 789.349731,170.925049 797.057434,160.405914 
	C797.408752,160.545395 797.760132,160.684875 798.111511,160.824371 
	C798.111511,175.044067 798.111511,189.263763 798.111511,203.572266 
	C803.323730,203.572266 808.022583,203.572266 812.632568,203.572266 
	C812.632568,182.230438 812.632568,161.318207 812.632568,140.458359 
	C799.251892,138.738144 798.385498,139.026352 791.749023,147.821518 
	C789.763184,150.453156 787.972046,153.230759 786.026978,155.894211 
	C782.349426,160.929855 778.625000,165.931305 774.676025,171.277252 
	C767.494202,161.426834 760.735535,152.095062 753.880371,142.834717 
	C753.048218,141.710693 751.746765,140.263763 750.576050,140.180084 
	C746.177979,139.865662 741.743896,140.054031 737.342407,140.054031 
	C737.342407,161.721802 737.342407,182.634232 737.342407,203.716339 
	C741.266846,203.716339 744.846741,203.716339 749.000000,203.716339 
	C749.000000,190.323212 749.000000,177.207413 749.000000,163.246292 
M407.238159,202.740433 
	C412.334839,203.306717 417.431488,203.873016 422.691589,204.457458 
	C422.691589,182.333862 422.691589,161.430786 422.691589,140.423981 
	C417.680267,140.423981 412.983459,140.423981 407.749725,140.423981 
	C407.749725,154.668884 407.749725,168.497574 407.749725,182.957550 
	C404.846558,179.782303 402.487183,177.264236 400.196533,174.685150 
	C392.287292,165.779861 384.163300,157.050659 376.578827,147.875977 
	C369.820587,139.700806 365.418762,137.916107 355.346741,140.828308 
	C355.346741,161.744278 355.346741,182.647812 355.346741,203.714676 
	C360.273285,203.714676 364.850067,203.714676 369.928619,203.714676 
	C369.928619,189.563766 369.928619,175.688080 369.928619,161.812378 
	C370.533142,161.372025 371.137695,160.931671 371.742218,160.491318 
	C372.669342,162.031494 373.398499,163.742065 374.554474,165.085281 
	C385.224945,177.484222 395.969147,189.819672 407.238159,202.740433 
M1359.000000,176.500000 
	C1359.000000,185.574188 1359.000000,194.648361 1359.000000,203.643250 
	C1364.423462,203.643250 1368.998779,203.643250 1374.114746,203.643250 
	C1374.114746,189.489090 1374.114746,175.680618 1374.114746,161.073044 
	C1377.887573,165.424759 1381.000366,169.055496 1384.156738,172.648056 
	C1392.774048,182.456329 1401.388794,192.267044 1410.072876,202.015884 
	C1410.859009,202.898346 1412.110229,203.842728 1413.191650,203.901093 
	C1417.286987,204.122177 1421.401245,203.990051 1425.594238,203.990051 
	C1425.594238,182.350952 1425.594238,161.339676 1425.594238,140.342010 
	C1420.940674,140.342010 1416.697632,140.342010 1411.920166,140.342010 
	C1411.920166,154.821045 1411.920166,168.966629 1411.920166,183.936401 
	C1408.022217,179.469223 1404.789307,175.733170 1401.522827,172.026627 
	C1392.671387,161.982742 1383.826660,151.932846 1374.909668,141.947464 
	C1374.124756,141.068436 1372.856079,140.154633 1371.767456,140.096741 
	C1367.674927,139.879150 1363.563965,140.009506 1359.000000,140.009506 
	C1359.000000,152.272095 1359.000000,163.886047 1359.000000,176.500000 
M970.000000,95.630951 
	C970.000000,93.874603 970.000000,92.118256 970.000000,90.008766 
	C975.081665,90.008766 979.393188,89.951851 983.700928,90.054932 
	C984.604309,90.076553 985.780518,90.434738 986.339783,91.070137 
	C988.732666,93.789108 991.064575,96.580429 993.196472,99.507217 
	C1002.043274,111.652664 1008.047058,114.299072 1020.763184,111.134171 
	C1014.210388,103.486122 1007.803650,96.008545 1002.309021,89.595551 
	C1007.030518,85.865036 1012.717102,83.083580 1015.876709,78.543457 
	C1024.198608,66.585762 1015.855469,50.631145 997.919556,49.136307 
	C985.531311,48.103848 973.038330,48.319283 960.591125,48.016815 
	C958.834351,47.974121 957.072021,48.157627 955.341125,48.235138 
	C955.341125,69.717102 955.341125,90.628296 955.341125,111.717819 
	C960.262512,111.717819 964.841187,111.717819 970.000000,111.717819 
	C970.000000,106.499611 970.000000,101.548264 970.000000,95.630951 
M867.000000,196.424179 
	C867.000000,198.841843 867.000000,201.259491 867.000000,204.965057 
	C882.718445,203.928238 897.773132,204.190613 912.325806,201.615662 
	C926.357239,199.132950 936.020569,189.745377 937.769348,174.813797 
	C939.221252,162.417236 933.477112,152.606125 922.842163,146.794769 
	C916.826172,143.507401 909.533203,141.594894 902.659058,141.055145 
	C891.014038,140.140778 879.245239,140.803925 867.000000,140.803925 
	C867.000000,158.893951 867.000000,177.169678 867.000000,196.424179 
M589.472107,143.823425 
	C582.226135,142.548950 575.020874,140.604111 567.724365,140.160507 
	C557.338318,139.529053 546.885254,140.000000 536.423340,140.000000 
	C536.423340,161.686630 536.423340,182.697327 536.423340,203.640488 
	C541.095215,203.640488 545.338013,203.640488 550.076538,203.640488 
	C550.076538,196.377838 550.076538,189.463196 550.076538,182.013504 
	C555.347717,182.013504 560.131165,181.927353 564.906738,182.084579 
	C565.887512,182.116898 567.091492,182.840485 567.762573,183.617050 
	C572.615784,189.232910 577.306763,194.989227 582.174316,200.592346 
	C583.329407,201.922043 584.892822,203.676132 586.382629,203.794159 
	C591.425781,204.193710 596.520142,203.946564 602.941162,203.946564 
	C595.664429,195.550735 589.273621,188.177139 582.546936,180.415970 
	C585.012817,179.311035 587.270203,178.840225 588.778076,177.516006 
	C592.380615,174.352234 596.789001,171.345886 598.762939,167.257645 
	C602.115967,160.313187 600.532410,149.651855 589.472107,143.823425 
M1202.000000,145.633759 
	C1202.000000,164.907455 1202.000000,184.181152 1202.000000,203.585495 
	C1206.966309,203.585495 1211.326538,203.585495 1216.193359,203.585495 
	C1216.193359,196.260193 1216.193359,189.337753 1216.193359,182.007675 
	C1221.332886,182.007675 1225.968750,181.930573 1230.598267,182.067108 
	C1231.631226,182.097595 1232.957642,182.604034 1233.613281,183.357513 
	C1238.705566,189.210464 1243.634766,195.204910 1248.696411,201.084976 
	C1249.642578,202.184052 1250.914551,203.700287 1252.107666,203.768234 
	C1257.296997,204.063858 1262.512817,203.895157 1269.052734,203.895157 
	C1261.701172,195.505554 1255.331787,188.236771 1248.520142,180.463211 
	C1250.741821,179.473495 1252.489624,178.733154 1254.205078,177.924454 
	C1263.632812,173.480301 1267.001465,168.239334 1265.889771,157.788284 
	C1265.034302,149.746780 1259.855957,144.876663 1252.183960,142.595428 
	C1235.885498,137.749146 1219.229980,141.036072 1202.373779,139.835861 
	C1202.221924,141.800262 1202.110962,143.235489 1202.000000,145.633759 
M457.444550,200.151230 
	C472.880951,207.112274 488.167877,206.847641 503.156250,198.917053 
	C513.566528,193.408798 519.618042,184.860321 519.975098,172.845062 
	C520.353455,160.113022 514.103271,151.070267 503.308441,145.197021 
	C493.025177,139.602097 482.042053,137.960373 470.276703,139.860519 
	C459.382599,141.619949 450.377258,146.347702 443.844696,154.942398 
	C439.397034,160.794113 437.159088,167.990387 438.366211,175.488617 
	C440.174164,186.718918 446.824005,194.613464 457.444550,200.151230 
M484.212708,53.076202 
	C478.348724,51.639637 472.534607,49.280422 466.610291,48.960274 
	C454.601746,48.311325 442.533569,48.766510 430.421112,48.766510 
	C430.421112,69.676208 430.421112,90.689751 430.421112,111.641876 
	C435.093719,111.641876 439.336151,111.641876 444.075195,111.641876 
	C444.075195,104.379639 444.075195,97.463791 444.075195,90.000000 
	C447.847778,90.000000 451.150360,90.215057 454.412964,89.945534 
	C458.486328,89.609039 461.014313,91.061699 463.440582,94.521858 
	C467.216522,99.906708 471.813019,104.731171 476.236664,109.635376 
	C477.244019,110.752220 478.930573,111.798027 480.365997,111.887222 
	C484.957062,112.172508 489.576996,111.993126 495.672028,111.993126 
	C488.401367,103.517998 481.940460,95.986794 475.370178,88.328049 
	C482.415253,86.189049 488.981445,83.390671 491.705109,76.281784 
	C495.194580,67.174095 492.661072,59.401394 484.212708,53.076202 
M647.345947,140.000000 
	C637.744690,140.000000 628.143494,140.000000 618.381714,140.000000 
	C618.381714,161.603104 618.381714,182.610550 618.381714,204.000000 
	C625.557129,204.000000 632.360046,203.850433 639.153748,204.035339 
	C649.142700,204.307205 658.881409,203.392059 668.416992,200.062836 
	C690.814270,192.243103 693.897644,164.241806 679.766602,151.132492 
	C670.741638,142.760040 659.689575,141.153778 647.345947,140.000000 
M1155.750122,107.909927 
	C1169.262085,114.501045 1183.232422,115.020882 1197.094849,109.777458 
	C1209.845581,104.954536 1217.887695,95.272614 1218.698120,81.581596 
	C1219.385986,69.961922 1213.831177,60.347992 1203.698853,54.281761 
	C1187.509033,44.588963 1170.515259,44.493603 1154.359863,53.589146 
	C1140.418335,61.438271 1134.007202,76.866692 1141.068848,92.685280 
	C1143.983032,99.213387 1148.914795,104.011482 1155.750122,107.909927 
M581.000000,54.600365 
	C581.000000,73.546547 581.000000,92.492729 581.000000,112.000000 
	C590.354065,112.000000 599.161743,112.390129 607.916504,111.876755 
	C614.936951,111.465065 622.210388,110.913063 628.807861,108.724358 
	C640.333923,104.900581 648.684082,97.140800 649.898987,84.289017 
	C651.322571,69.229019 645.824463,59.280106 632.117493,53.271473 
	C625.791260,50.498264 618.565308,49.090145 611.631897,48.552761 
	C601.614502,47.776348 591.492126,48.354126 581.000000,48.354126 
	C581.000000,50.361485 581.000000,51.997639 581.000000,54.600365 
M889.009216,95.506485 
	C878.152588,84.505943 882.765869,66.742004 894.970215,61.299671 
	C904.931641,56.857483 914.307861,56.942169 923.324158,62.320526 
	C928.320679,65.300987 931.258179,65.104195 933.752380,59.852119 
	C934.284119,58.732441 935.284973,57.835564 936.124329,56.766472 
	C935.375427,56.072754 934.954285,55.530178 934.406006,55.196823 
	C918.387878,45.458874 901.690857,44.441010 884.988403,52.693928 
	C875.410278,57.426586 868.915833,65.242363 867.990051,76.607597 
	C866.944580,89.442696 871.090393,100.147560 882.433228,106.553215 
	C899.391968,116.130341 916.845093,115.352531 933.961304,106.482155 
	C936.259216,105.291298 939.546448,103.110336 939.697144,101.178894 
	C940.337463,92.969528 939.969055,84.681480 939.969055,76.282104 
	C928.385986,76.282104 917.481689,76.282104 906.396484,76.282104 
	C906.396484,79.930656 906.396484,83.289452 906.396484,87.148285 
	C912.702087,87.148285 918.619263,87.148285 924.723877,87.148285 
	C924.836426,88.935669 924.972717,90.232948 924.990906,91.531883 
	C925.090881,98.668999 924.714783,99.305870 917.729797,100.898552 
	C907.692322,103.187218 898.022888,102.890800 889.009216,95.506485 
M1309.508789,165.000000 
	C1305.090698,165.000000 1300.672607,165.000000 1296.369141,165.000000 
	C1296.369141,159.891098 1296.369141,155.645248 1296.369141,150.901001 
	C1310.979248,150.901001 1325.229126,150.901001 1339.578491,150.901001 
	C1339.578491,147.022766 1339.578491,143.662201 1339.578491,140.354462 
	C1319.914551,140.354462 1300.674316,140.354462 1281.023071,140.354462 
	C1281.023071,155.500549 1280.341553,170.337708 1281.238281,185.078873 
	C1281.958984,196.925415 1285.090820,201.075836 1296.703003,203.671768 
	C1300.043335,204.418533 1303.661011,203.980881 1307.151733,203.989655 
	C1318.261108,204.017578 1329.370605,204.000000 1340.576660,204.000000 
	C1340.576660,200.016693 1340.576660,196.657440 1340.576660,192.999649 
	C1328.341797,192.999649 1316.527100,192.940674 1304.713623,193.030029 
	C1300.277466,193.063568 1297.070923,191.161484 1296.250854,186.935120 
	C1295.583008,183.493134 1296.115112,179.818344 1296.115112,175.755875 
	C1310.133545,175.755875 1323.383667,175.755875 1336.682861,175.755875 
	C1336.682861,171.995667 1336.682861,168.748291 1336.682861,165.000000 
	C1327.751831,165.000000 1319.128418,165.000000 1309.508789,165.000000 
M1477.519897,193.000000 
	C1474.187866,192.997818 1470.849487,193.122986 1467.525269,192.961380 
	C1463.317993,192.756836 1460.649902,190.355835 1460.118896,186.267776 
	C1459.700684,183.048050 1460.034790,179.730637 1460.034790,175.794617 
	C1474.025146,175.794617 1487.399048,175.794617 1500.614258,175.794617 
	C1500.614258,171.857391 1500.614258,168.610336 1500.614258,164.868103 
	C1486.987549,164.868103 1473.737793,164.868103 1460.438354,164.868103 
	C1460.438354,159.986160 1460.438354,155.621735 1460.438354,150.764862 
	C1475.121338,150.764862 1489.371582,150.764862 1503.688110,150.764862 
	C1503.688110,147.006363 1503.688110,143.759430 1503.688110,140.423508 
	C1484.014160,140.423508 1464.655029,140.423508 1445.016602,140.423508 
	C1445.016602,155.492767 1444.462158,170.167465 1445.178101,184.779922 
	C1445.846680,198.427734 1451.944946,203.854614 1466.349487,203.985214 
	C1475.178955,204.065262 1484.009644,204.000000 1492.839844,204.000000 
	C1496.768921,204.000000 1500.697876,204.000000 1504.685791,204.000000 
	C1504.685791,200.001892 1504.685791,196.754883 1504.685791,193.000000 
	C1495.758911,193.000000 1487.136230,193.000000 1477.519897,193.000000 
M1532.261963,202.645218 
	C1548.403931,203.293716 1564.545776,203.942200 1580.634888,204.588562 
	C1580.634888,199.897293 1580.634888,196.650314 1580.634888,193.000000 
	C1578.476807,193.000000 1576.672607,193.000031 1574.868408,192.999985 
	C1564.713135,192.999741 1554.557617,192.971008 1544.402466,193.012344 
	C1539.945679,193.030487 1536.919556,190.892181 1536.177124,186.645248 
	C1535.595947,183.320114 1536.060791,179.812134 1536.060791,175.908966 
	C1549.974731,175.908966 1563.217773,175.908966 1576.590576,175.908966 
	C1576.590576,172.043320 1576.590576,168.684647 1576.590576,164.832764 
	C1562.946899,164.832764 1549.704712,164.832764 1536.447266,164.832764 
	C1536.447266,159.959305 1536.447266,155.599823 1536.447266,150.758560 
	C1551.131592,150.758560 1565.373047,150.758560 1579.693481,150.758560 
	C1579.693481,147.011841 1579.693481,143.767700 1579.693481,140.402527 
	C1560.035889,140.402527 1540.687378,140.402527 1521.014648,140.402527 
	C1521.014648,155.433487 1520.692139,170.074417 1521.149902,184.690903 
	C1521.381348,192.077026 1523.324097,199.238052 1532.261963,202.645218 
M1295.000000,81.498337 
	C1295.000000,85.662010 1294.989624,89.825706 1295.001831,93.989342 
	C1295.034546,105.095490 1301.857300,111.973106 1312.907471,111.994675 
	C1324.898926,112.018082 1336.890503,112.007896 1348.881958,111.990402 
	C1350.478394,111.988075 1352.074585,111.856773 1353.671631,111.785172 
	C1353.671631,107.968895 1353.671631,104.723274 1353.671631,101.000000 
	C1351.554932,101.000000 1349.761719,101.000038 1347.968506,101.000000 
	C1337.809082,100.999763 1327.649536,101.009163 1317.490112,100.993797 
	C1314.122681,100.988708 1310.753662,100.169220 1310.191650,96.465370 
	C1309.586914,92.481239 1310.054932,88.334290 1310.054932,83.775421 
	C1323.447632,83.775421 1336.029785,83.775421 1348.696411,83.775421 
	C1348.696411,80.354980 1348.696411,77.441025 1348.696411,73.854431 
	C1335.703857,73.854431 1322.998535,73.854431 1310.351562,73.854431 
	C1310.351562,68.589165 1310.351562,64.012703 1310.351562,58.940826 
	C1324.606201,58.940826 1338.519287,58.940826 1352.602783,58.940826 
	C1352.602783,55.069725 1352.602783,51.710121 1352.602783,48.323807 
	C1333.301392,48.323807 1314.399048,48.323807 1295.000000,48.323807 
	C1295.000000,59.259808 1295.000000,69.879433 1295.000000,81.498337 
M1070.495850,140.000000 
	C1062.088257,140.000000 1053.680664,140.000000 1045.334229,140.000000 
	C1045.334229,161.741211 1045.334229,182.633514 1045.334229,203.554199 
	C1050.375366,203.554199 1055.068848,203.554199 1060.250000,203.554199 
	C1060.250000,197.197769 1060.250000,191.283554 1060.250000,185.000000 
	C1064.719482,185.000000 1068.694214,185.121521 1072.658691,184.971817 
	C1078.765869,184.741226 1084.922241,184.730499 1090.957031,183.897446 
	C1101.569458,182.432404 1110.200317,174.028336 1110.855713,164.102982 
	C1111.439453,155.263046 1107.832031,146.397873 1095.939453,142.814743 
	C1088.209839,140.485840 1079.670166,140.845108 1070.495850,140.000000 
M522.039490,97.099907 
	C521.580688,92.928185 521.121887,88.756462 520.578674,83.817436 
	C534.670837,83.817436 547.714417,83.817436 560.621216,83.817436 
	C560.621216,80.209091 560.621216,77.292427 560.621216,73.877335 
	C547.332520,73.877335 534.412903,73.877335 521.437256,73.877335 
	C521.437256,68.656242 521.437256,63.956772 521.437256,58.762306 
	C535.788940,58.762306 549.709290,58.762306 563.683350,58.762306 
	C563.683350,54.996181 563.683350,51.749390 563.683350,48.434429 
	C544.666870,48.434429 525.970154,48.434429 507.000000,48.434429 
	C507.000000,63.855499 506.998199,78.843773 507.000580,93.832054 
	C507.002472,105.856873 513.028198,111.970963 524.954041,111.994720 
	C536.953247,112.018623 548.952637,112.008629 560.951904,111.989449 
	C562.543823,111.986908 564.135437,111.842766 565.643066,111.768349 
	C565.643066,107.918159 565.643066,104.668892 565.643066,100.999939 
	C553.656311,100.999939 542.016113,101.192719 530.391296,100.855293 
	C527.710022,100.777473 525.081421,98.883385 522.039490,97.099907 
M852.511292,87.016159 
	C846.974487,76.472565 840.865906,66.178993 836.106628,55.295406 
	C833.053162,48.312832 828.389465,47.474064 822.031860,47.894451 
	C819.219727,48.080406 817.527954,48.773327 816.200623,51.373367 
	C806.720886,69.942879 797.094910,88.437691 787.534241,106.965904 
	C786.762024,108.462357 786.129761,110.030968 785.580322,111.240517 
	C796.335144,114.505768 802.987244,110.881172 805.773499,100.518959 
	C805.815918,100.361145 805.870361,100.153641 805.988770,100.077141 
	C807.594116,99.040413 809.162964,97.246086 810.835205,97.141098 
	C816.641113,96.776573 822.588562,97.698318 828.289490,96.844437 
	C838.043579,95.383484 844.043945,98.723091 846.740479,108.252464 
	C847.133057,109.639603 848.543945,111.693459 849.607910,111.772903 
	C854.612976,112.146614 859.661499,111.939224 865.017883,111.939224 
	C860.705200,103.308014 856.796143,95.484604 852.511292,87.016159 
M1071.489624,59.012814 
	C1060.045410,72.318146 1061.461914,87.694588 1069.078125,98.135605 
	C1075.533691,106.985329 1084.914185,111.083847 1095.313232,112.533340 
	C1108.799805,114.413216 1121.501709,111.957870 1133.105713,103.524628 
	C1131.742554,101.783951 1130.332520,100.509262 1129.581299,98.922684 
	C1127.570679,94.675926 1125.257568,94.588600 1121.468750,97.099228 
	C1112.785522,102.852890 1103.357178,103.709305 1093.489136,100.238922 
	C1084.516479,97.083366 1077.239014,87.929314 1078.078735,79.957527 
	C1079.289062,68.467278 1086.695312,61.681694 1096.896973,58.982082 
	C1106.049194,56.560169 1114.127075,59.174240 1122.045044,63.483040 
	C1123.281860,64.156097 1125.806396,64.398064 1126.561646,63.651661 
	C1128.675049,61.562958 1130.173706,58.852066 1131.911011,56.392185 
	C1113.344360,43.919559 1088.951904,43.967632 1071.489624,59.012814 
M382.786163,112.999634 
	C395.282745,113.651817 406.958984,111.502121 417.378052,103.297310 
	C415.009735,100.162071 412.979034,97.473785 410.678375,94.428108 
	C402.692902,100.144241 394.282227,102.669991 384.769165,102.096336 
	C373.929474,101.442673 363.263519,91.679626 363.000488,81.375137 
	C362.631317,66.913261 378.138763,54.758987 392.718964,58.479778 
	C398.669952,59.998436 404.337830,62.626492 410.342438,64.825996 
	C412.189331,62.317345 414.118011,59.697639 416.092957,57.015068 
	C415.523285,56.278301 415.210663,55.662903 414.717194,55.281502 
	C413.809509,54.579975 412.850250,53.899757 411.817139,53.413017 
	C402.307129,48.932465 392.611755,46.323639 381.799683,47.832989 
	C371.442108,49.278896 362.398499,52.596504 355.123352,60.337223 
	C345.737732,70.323486 345.582977,88.579300 354.414856,99.048523 
	C361.705841,107.691238 371.232208,111.272865 382.786163,112.999634 
M285.590729,44.087372 
	C282.685455,45.607143 279.806976,47.180920 276.870300,48.637383 
	C263.824188,55.107765 252.144897,64.571426 236.529968,66.777199 
	C238.562988,71.984924 240.258835,77.084038 242.517395,81.920387 
	C245.250793,87.773628 249.433655,89.732422 255.451294,87.006172 
	C266.306793,82.088158 276.842285,76.456284 287.452332,71.007416 
	C292.395203,68.468948 297.309540,65.835846 302.008362,62.882145 
	C303.685272,61.828022 305.737244,59.753380 305.820587,58.056282 
	C306.218628,49.951412 305.998993,41.816212 305.998993,32.713062 
	C299.036499,36.680016 292.679138,40.302204 285.590729,44.087372 
M1179.794434,140.000000 
	C1177.819336,140.558990 1175.222778,140.517181 1173.989258,141.793106 
	C1170.687012,145.208740 1168.013550,149.225113 1165.008301,152.936508 
	C1161.130981,157.724930 1157.173828,162.448898 1153.152100,167.320496 
	C1146.881470,159.757446 1141.142944,152.739868 1135.281860,145.826233 
	C1133.522339,143.750534 1131.624634,140.646591 1129.435669,140.308380 
	C1124.182007,139.496674 1118.718140,140.045822 1112.038452,140.045822 
	C1120.800537,150.418427 1128.437988,159.874619 1136.543701,168.910721 
	C1142.002930,174.996567 1146.568115,180.954330 1145.125244,189.913910 
	C1144.405518,194.382645 1145.000000,199.063019 1145.000000,203.677704 
	C1150.355103,203.677704 1154.929321,203.677704 1160.000000,203.677704 
	C1160.000000,196.249084 1159.933228,189.132050 1160.052490,182.018127 
	C1160.078613,180.461807 1160.316040,178.549088 1161.227295,177.426605 
	C1166.737305,170.639313 1172.514160,164.069565 1178.116943,157.356323 
	C1182.703735,151.860458 1187.172241,146.265945 1192.275757,140.000000 
	C1187.645508,140.000000 1184.178589,140.000000 1179.794434,140.000000 
M746.398438,48.000000 
	C727.790405,48.000000 709.182434,48.000000 690.398132,48.000000 
	C690.398132,51.931206 690.398132,55.291229 690.398132,59.157440 
	C698.372314,59.157440 705.955383,59.157440 714.202576,59.157440 
	C714.202576,77.026077 714.202576,94.391571 714.202576,111.617950 
	C719.138245,111.617950 723.382446,111.617950 728.121643,111.617950 
	C728.121643,93.998093 728.121643,76.753929 728.121643,58.836464 
	C736.654419,58.836464 744.700500,58.836464 752.634277,58.836464 
	C752.634277,54.899654 752.634277,51.651676 752.634277,48.000004 
	C750.635803,48.000004 749.000122,48.000004 746.398438,48.000000 
M1246.000000,60.511528 
	C1246.000000,56.429333 1246.000000,52.347134 1246.000000,48.371944 
	C1240.888062,48.371944 1236.641113,48.371944 1232.313477,48.371944 
	C1232.313477,69.657326 1232.313477,90.569374 1232.313477,111.571930 
	C1250.329468,111.571930 1268.028809,111.571930 1285.628174,111.571930 
	C1285.628174,107.889427 1285.628174,104.639969 1285.628174,100.889549 
	C1272.346069,100.889549 1259.426025,100.889549 1246.000000,100.889549 
	C1246.000000,87.423386 1246.000000,74.466072 1246.000000,60.511528 
M953.000000,160.500198 
	C953.000000,174.888733 953.000000,189.277267 953.000000,203.668655 
	C958.379456,203.668655 962.951599,203.668655 967.600037,203.668655 
	C967.600037,182.337204 967.600037,161.339523 967.600037,140.330994 
	C962.622986,140.330994 958.050720,140.330994 953.000000,140.330994 
	C953.000000,146.935379 953.000000,153.217911 953.000000,160.500198 
M678.000000,54.596706 
	C678.000000,52.512600 678.000000,50.428493 678.000000,48.326820 
	C672.630737,48.326820 668.058044,48.326820 663.398315,48.326820 
	C663.398315,69.655586 663.398315,90.655182 663.398315,111.669998 
	C668.374084,111.669998 672.946777,111.669998 678.000000,111.669998 
	C678.000000,92.754944 678.000000,74.165695 678.000000,54.596706 
M1049.000000,58.525597 
	C1049.000000,55.114674 1049.000000,51.703754 1049.000000,48.348694 
	C1043.921387,48.348694 1039.681274,48.348694 1035.416992,48.348694 
	C1035.416992,69.698555 1035.416992,90.696991 1035.416992,111.650635 
	C1040.079956,111.650635 1044.320068,111.650635 1049.000000,111.650635 
	C1049.000000,94.043274 1049.000000,76.781464 1049.000000,58.525597 
M833.047546,141.821838 
	C833.047546,162.389984 833.047546,182.958115 833.047546,203.556610 
	C838.033142,203.556610 842.393860,203.556610 846.654663,203.556610 
	C846.654663,182.224518 846.654663,161.337402 846.654663,139.642715 
	C841.926758,140.088730 837.582581,140.498535 833.047546,141.821838 
M986.000000,170.594574 
	C986.000000,172.873367 986.000000,175.152161 986.000000,177.636230 
	C999.908936,177.636230 1013.249084,177.636230 1026.704468,177.636230 
	C1026.704468,173.695511 1026.704468,170.121475 1026.704468,166.012726 
	C1013.828674,166.012726 1001.240295,165.937592 988.657715,166.169922 
	C987.751038,166.186661 986.885742,168.446136 986.000000,170.594574 
M1309.613525,134.000000 
	C1317.380859,133.999451 1322.162109,130.530411 1325.478271,122.489273 
	C1312.816772,120.519096 1308.792480,122.610275 1303.749390,134.000000 
	C1305.496582,134.000000 1307.119629,134.000000 1309.613525,134.000000 
M1474.274780,123.383438 
	C1472.027588,126.739349 1469.780396,130.095261 1467.422852,133.615845 
	C1480.401611,134.943695 1485.967651,132.039093 1489.689575,121.682678 
	C1484.542236,122.067230 1479.685547,122.430061 1474.274780,123.383438 
z"
      />
      <path
        fill="#129495"
        opacity="1.000000"
        stroke="none"
        d="
M31.090496,121.439491 
	C38.928497,95.325790 56.512943,77.821884 78.587845,64.155296 
	C92.790314,55.362526 108.326309,49.841732 124.581818,46.223816 
	C138.103409,43.214386 145.770020,47.500786 149.998642,60.597839 
	C151.447021,65.083824 152.743988,69.618698 153.995239,73.751678 
	C144.945572,75.533592 136.078278,76.824860 127.459038,79.068909 
	C107.904518,84.159973 90.630676,93.553207 75.896118,107.549828 
	C66.088684,116.866081 57.557045,126.905907 53.833370,140.322205 
	C51.894474,147.308014 53.245102,152.793045 59.619873,155.640442 
	C65.348473,158.199234 71.693253,160.557846 77.848518,160.786148 
	C111.779839,162.044632 144.016495,154.631149 174.642548,140.091522 
	C186.508102,134.458389 198.318695,128.709473 210.855438,122.871719 
	C221.709763,117.143669 231.862946,111.557205 242.016129,105.970734 
	C263.153320,94.523987 284.290527,83.077240 305.977875,71.332565 
	C305.977875,81.540077 306.080688,91.412819 305.849518,101.277733 
	C305.820160,102.530052 304.288513,104.140335 303.037750,104.911194 
	C287.313019,114.602600 271.511871,124.169960 255.039948,133.905884 
	C253.703751,134.139618 252.969330,134.069595 252.425125,134.358215 
	C242.689560,139.521179 232.967834,144.710587 223.281601,149.965256 
	C222.673889,150.294952 222.382690,151.208130 221.944229,151.849854 
	C212.312729,156.388077 202.705200,160.978012 193.044876,165.454041 
	C171.213379,175.569473 148.991669,184.899658 125.128036,188.716309 
	C103.031464,192.250351 81.005219,191.805130 60.490799,180.807251 
	C44.615135,172.296234 35.027924,159.187805 31.470318,141.241882 
	C31.112799,134.651993 31.067513,128.277603 31.090496,121.439491 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M33.430244,225.911072 
	C32.964851,218.524673 32.964851,211.208725 32.964851,203.447189 
	C117.866196,203.447189 202.481262,203.447189 288.011383,203.447189 
	C288.011383,210.450806 288.011383,217.687683 288.023407,225.382629 
	C286.873932,225.893784 285.712463,225.993317 284.550964,225.993332 
	C200.999191,225.994461 117.447418,225.987671 33.430244,225.911072 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1662.912109,188.600372 
	C1658.990356,198.588028 1650.771240,202.062759 1641.628662,203.880203 
	C1626.675781,206.852661 1612.447144,203.851990 1598.719849,197.845810 
	C1596.984375,197.086502 1595.516113,195.716751 1594.074463,194.732620 
	C1594.275757,193.889374 1594.262573,193.511368 1594.438721,193.273102 
	C1596.390503,190.630585 1597.906982,186.733017 1600.493652,185.791428 
	C1602.523438,185.052536 1605.818237,188.154648 1608.669312,189.240311 
	C1619.134521,193.225494 1629.776123,195.632004 1640.927002,192.116501 
	C1641.557983,191.917542 1642.374146,191.951202 1642.811035,191.554047 
	C1644.580444,189.945969 1646.235107,188.211670 1647.931885,186.523834 
	C1646.310791,185.023178 1644.923584,182.699814 1643.029053,182.161148 
	C1633.708984,179.511337 1624.192383,177.551941 1614.874146,174.896576 
	C1610.499268,173.649872 1605.926636,172.062759 1602.293579,169.448654 
	C1593.010254,162.768723 1594.766724,152.405151 1602.169678,146.691696 
	C1611.765381,139.286026 1623.374756,137.722473 1635.138672,139.692688 
	C1642.385498,140.906342 1649.483032,143.169769 1656.527344,145.350357 
	C1659.012695,146.119675 1662.150146,147.374268 1659.881714,151.381821 
	C1656.337158,157.643890 1654.695312,158.338150 1650.436401,156.024490 
	C1639.912598,150.307556 1628.802124,148.083710 1617.184570,152.181870 
	C1615.025391,152.943497 1613.446777,155.350967 1611.600220,156.998840 
	C1613.485718,158.382263 1615.181641,160.447067 1617.288940,161.033493 
	C1627.571411,163.894760 1638.033691,166.116669 1648.290527,169.059402 
	C1654.423218,170.818893 1659.842285,174.034210 1662.570312,180.753387 
	C1662.889893,183.351044 1662.935669,185.753189 1662.912109,188.600372 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M550.563293,31.089966 
	C548.220581,34.401001 545.980164,38.667301 542.458313,40.608364 
	C538.490906,42.795013 533.369019,42.886951 528.749268,43.890030 
	C528.497314,43.328014 528.245300,42.766003 527.993347,42.203987 
	C530.307373,38.752560 532.621399,35.301128 534.950012,31.504505 
	C540.009705,31.112480 545.054871,31.065655 550.563293,31.089966 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M241.985992,105.602173 
	C231.862946,111.557205 221.709763,117.143669 211.213867,122.821320 
	C206.246613,110.936226 201.627151,98.957993 196.991104,86.986168 
	C196.409195,85.483482 195.739929,84.014603 194.707916,81.578041 
	C190.914291,89.591751 187.485718,96.797546 184.089172,104.018402 
	C179.726395,113.293427 176.079941,123.005203 170.745590,131.680710 
	C168.135590,135.925476 162.717194,138.836563 158.026520,141.328506 
	C147.663574,146.833878 136.285004,148.586792 123.833496,149.166489 
	C126.758835,143.415909 129.252487,138.318604 131.916565,133.311966 
	C141.625229,115.066322 151.379654,96.844986 161.139801,78.626801 
	C165.903442,69.735062 170.598465,60.801884 175.625519,52.060547 
	C176.472031,50.588604 178.831741,49.175716 180.526260,49.134575 
	C191.348907,48.871792 202.183014,49.112717 213.008759,48.923996 
	C215.835693,48.874710 217.121170,49.962250 218.189987,52.360516 
	C226.055786,70.009918 234.021561,87.614754 241.985992,105.602173 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M749.000000,163.668961 
	C749.000000,177.207413 749.000000,190.323212 749.000000,203.716339 
	C744.846741,203.716339 741.266846,203.716339 737.342407,203.716339 
	C737.342407,182.634232 737.342407,161.721802 737.342407,140.054031 
	C741.743896,140.054031 746.177979,139.865662 750.576050,140.180084 
	C751.746765,140.263763 753.048218,141.710693 753.880371,142.834717 
	C760.735535,152.095062 767.494202,161.426834 774.676025,171.277252 
	C778.625000,165.931305 782.349426,160.929855 786.026978,155.894211 
	C787.972046,153.230759 789.763184,150.453156 791.749023,147.821518 
	C798.385498,139.026352 799.251892,138.738144 812.632568,140.458359 
	C812.632568,161.318207 812.632568,182.230438 812.632568,203.572266 
	C808.022583,203.572266 803.323730,203.572266 798.111511,203.572266 
	C798.111511,189.263763 798.111511,175.044067 798.111511,160.824371 
	C797.760132,160.684875 797.408752,160.545395 797.057434,160.405914 
	C789.349731,170.925049 781.642029,181.444199 773.463928,192.605331 
	C765.320496,181.505737 757.644104,171.042709 749.416748,159.828674 
	C749.210938,161.516312 749.105469,162.381302 749.000000,163.668961 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M406.965027,202.457001 
	C395.969147,189.819672 385.224945,177.484222 374.554474,165.085281 
	C373.398499,163.742065 372.669342,162.031494 371.742218,160.491318 
	C371.137695,160.931671 370.533142,161.372025 369.928619,161.812378 
	C369.928619,175.688080 369.928619,189.563766 369.928619,203.714676 
	C364.850067,203.714676 360.273285,203.714676 355.346741,203.714676 
	C355.346741,182.647812 355.346741,161.744278 355.346741,140.828308 
	C365.418762,137.916107 369.820587,139.700806 376.578827,147.875977 
	C384.163300,157.050659 392.287292,165.779861 400.196533,174.685150 
	C402.487183,177.264236 404.846558,179.782303 407.749725,182.957550 
	C407.749725,168.497574 407.749725,154.668884 407.749725,140.423981 
	C412.983459,140.423981 417.680267,140.423981 422.691589,140.423981 
	C422.691589,161.430786 422.691589,182.333862 422.691589,204.457458 
	C417.431488,203.873016 412.334839,203.306717 406.965027,202.457001 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1359.000000,176.000000 
	C1359.000000,163.886047 1359.000000,152.272095 1359.000000,140.009506 
	C1363.563965,140.009506 1367.674927,139.879150 1371.767456,140.096741 
	C1372.856079,140.154633 1374.124756,141.068436 1374.909668,141.947464 
	C1383.826660,151.932846 1392.671387,161.982742 1401.522827,172.026627 
	C1404.789307,175.733170 1408.022217,179.469223 1411.920166,183.936401 
	C1411.920166,168.966629 1411.920166,154.821045 1411.920166,140.342010 
	C1416.697632,140.342010 1420.940674,140.342010 1425.594238,140.342010 
	C1425.594238,161.339676 1425.594238,182.350952 1425.594238,203.990051 
	C1421.401245,203.990051 1417.286987,204.122177 1413.191650,203.901093 
	C1412.110229,203.842728 1410.859009,202.898346 1410.072876,202.015884 
	C1401.388794,192.267044 1392.774048,182.456329 1384.156738,172.648056 
	C1381.000366,169.055496 1377.887573,165.424759 1374.114746,161.073044 
	C1374.114746,175.680618 1374.114746,189.489090 1374.114746,203.643250 
	C1368.998779,203.643250 1364.423462,203.643250 1359.000000,203.643250 
	C1359.000000,194.648361 1359.000000,185.574188 1359.000000,176.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M970.000000,96.113937 
	C970.000000,101.548264 970.000000,106.499611 970.000000,111.717819 
	C964.841187,111.717819 960.262512,111.717819 955.341125,111.717819 
	C955.341125,90.628296 955.341125,69.717102 955.341125,48.235138 
	C957.072021,48.157627 958.834351,47.974121 960.591125,48.016815 
	C973.038330,48.319283 985.531311,48.103848 997.919556,49.136307 
	C1015.855469,50.631145 1024.198608,66.585762 1015.876709,78.543457 
	C1012.717102,83.083580 1007.030518,85.865036 1002.309021,89.595551 
	C1007.803650,96.008545 1014.210388,103.486122 1020.763184,111.134171 
	C1008.047058,114.299072 1002.043274,111.652664 993.196472,99.507217 
	C991.064575,96.580429 988.732666,93.789108 986.339783,91.070137 
	C985.780518,90.434738 984.604309,90.076553 983.700928,90.054932 
	C979.393188,89.951851 975.081665,90.008766 970.000000,90.008766 
	C970.000000,92.118256 970.000000,93.874603 970.000000,96.113937 
M970.000000,72.412163 
	C970.000000,74.497917 970.000000,76.583672 970.000000,78.990730 
	C978.448059,78.990730 986.099548,79.168106 993.737793,78.926773 
	C998.622070,78.772461 1003.558350,74.243774 1003.978821,70.188225 
	C1004.389954,66.222794 1000.623169,61.102074 995.668579,60.462170 
	C987.374451,59.390957 978.977600,59.115154 970.000000,58.458981 
	C970.000000,63.526779 970.000000,67.481438 970.000000,72.412163 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M867.000000,195.934799 
	C867.000000,177.169678 867.000000,158.893951 867.000000,140.803925 
	C879.245239,140.803925 891.014038,140.140778 902.659058,141.055145 
	C909.533203,141.594894 916.826172,143.507401 922.842163,146.794769 
	C933.477112,152.606125 939.221252,162.417236 937.769348,174.813797 
	C936.020569,189.745377 926.357239,199.132950 912.325806,201.615662 
	C897.773132,204.190613 882.718445,203.928238 867.000000,204.965057 
	C867.000000,201.259491 867.000000,198.841843 867.000000,195.934799 
M882.889160,192.750290 
	C893.323181,193.250305 903.783020,193.974655 913.323181,188.302292 
	C918.897583,184.987869 921.603516,180.042770 922.015137,173.650879 
	C922.499451,166.128296 920.203918,159.947601 913.572021,155.872147 
	C903.811646,149.874176 893.100830,150.788605 882.239014,151.264648 
	C882.239014,165.191269 882.239014,178.636978 882.889160,192.750290 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M589.830811,143.946777 
	C600.532410,149.651855 602.115967,160.313187 598.762939,167.257645 
	C596.789001,171.345886 592.380615,174.352234 588.778076,177.516006 
	C587.270203,178.840225 585.012817,179.311035 582.546936,180.415970 
	C589.273621,188.177139 595.664429,195.550735 602.941162,203.946564 
	C596.520142,203.946564 591.425781,204.193710 586.382629,203.794159 
	C584.892822,203.676132 583.329407,201.922043 582.174316,200.592346 
	C577.306763,194.989227 572.615784,189.232910 567.762573,183.617050 
	C567.091492,182.840485 565.887512,182.116898 564.906738,182.084579 
	C560.131165,181.927353 555.347717,182.013504 550.076538,182.013504 
	C550.076538,189.463196 550.076538,196.377838 550.076538,203.640488 
	C545.338013,203.640488 541.095215,203.640488 536.423340,203.640488 
	C536.423340,182.697327 536.423340,161.686630 536.423340,140.000000 
	C546.885254,140.000000 557.338318,139.529053 567.724365,140.160507 
	C575.020874,140.604111 582.226135,142.548950 589.830811,143.946777 
M576.949585,152.088593 
	C568.106262,151.590195 559.262939,151.091797 550.315186,150.587509 
	C550.315186,158.006958 550.315186,164.247482 550.315186,170.948685 
	C558.569885,170.948685 566.536011,171.266418 574.462036,170.839355 
	C579.941101,170.544144 584.673096,166.450378 584.894409,162.413589 
	C585.172546,157.338776 582.378357,154.090042 576.949585,152.088593 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1202.000000,145.152237 
	C1202.110962,143.235489 1202.221924,141.800262 1202.373779,139.835861 
	C1219.229980,141.036072 1235.885498,137.749146 1252.183960,142.595428 
	C1259.855957,144.876663 1265.034302,149.746780 1265.889771,157.788284 
	C1267.001465,168.239334 1263.632812,173.480301 1254.205078,177.924454 
	C1252.489624,178.733154 1250.741821,179.473495 1248.520142,180.463211 
	C1255.331787,188.236771 1261.701172,195.505554 1269.052734,203.895157 
	C1262.512817,203.895157 1257.296997,204.063858 1252.107666,203.768234 
	C1250.914551,203.700287 1249.642578,202.184052 1248.696411,201.084976 
	C1243.634766,195.204910 1238.705566,189.210464 1233.613281,183.357513 
	C1232.957642,182.604034 1231.631226,182.097595 1230.598267,182.067108 
	C1225.968750,181.930573 1221.332886,182.007675 1216.193359,182.007675 
	C1216.193359,189.337753 1216.193359,196.260193 1216.193359,203.585495 
	C1211.326538,203.585495 1206.966309,203.585495 1202.000000,203.585495 
	C1202.000000,184.181152 1202.000000,164.907455 1202.000000,145.152237 
M1216.000000,158.564880 
	C1216.000000,162.633392 1216.000000,166.701904 1216.000000,170.987869 
	C1224.525757,170.987869 1232.171875,171.211761 1239.797852,170.915451 
	C1245.661255,170.687607 1250.577271,166.484848 1250.979736,162.113144 
	C1251.337158,158.233688 1247.886597,152.930939 1243.435425,152.400726 
	C1234.505005,151.336945 1225.476807,151.093079 1216.000000,150.479126 
	C1216.000000,153.667496 1216.000000,155.629562 1216.000000,158.564880 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M457.086365,200.031647 
	C446.824005,194.613464 440.174164,186.718918 438.366211,175.488617 
	C437.159088,167.990387 439.397034,160.794113 443.844696,154.942398 
	C450.377258,146.347702 459.382599,141.619949 470.276703,139.860519 
	C482.042053,137.960373 493.025177,139.602097 503.308441,145.197021 
	C514.103271,151.070267 520.353455,160.113022 519.975098,172.845062 
	C519.618042,184.860321 513.566528,193.408798 503.156250,198.917053 
	C488.167877,206.847641 472.880951,207.112274 457.086365,200.031647 
M454.000244,169.796265 
	C454.409271,173.033081 454.074188,176.604584 455.353882,179.449814 
	C461.447266,192.997360 477.420135,197.702820 491.507935,191.328201 
	C503.789062,185.771088 509.842987,170.666595 499.673950,158.632019 
	C493.545288,151.379044 484.740936,148.819916 475.447144,150.128937 
	C465.019257,151.597687 456.447968,156.937195 454.000244,169.796265 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M484.534668,53.259331 
	C492.661072,59.401394 495.194580,67.174095 491.705109,76.281784 
	C488.981445,83.390671 482.415253,86.189049 475.370178,88.328049 
	C481.940460,95.986794 488.401367,103.517998 495.672028,111.993126 
	C489.576996,111.993126 484.957062,112.172508 480.365997,111.887222 
	C478.930573,111.798027 477.244019,110.752220 476.236664,109.635376 
	C471.813019,104.731171 467.216522,99.906708 463.440582,94.521858 
	C461.014313,91.061699 458.486328,89.609039 454.412964,89.945534 
	C451.150360,90.215057 447.847778,90.000000 444.075195,90.000000 
	C444.075195,97.463791 444.075195,104.379639 444.075195,111.641876 
	C439.336151,111.641876 435.093719,111.641876 430.421112,111.641876 
	C430.421112,90.689751 430.421112,69.676208 430.421112,48.766510 
	C442.533569,48.766510 454.601746,48.311325 466.610291,48.960274 
	C472.534607,49.280422 478.348724,51.639637 484.534668,53.259331 
M448.661804,59.000134 
	C447.231232,59.111107 445.800690,59.222076 444.287140,59.339489 
	C444.287140,65.973366 444.287140,72.201485 444.287140,78.962006 
	C452.483795,78.962006 460.419769,79.260651 468.319794,78.856491 
	C473.543915,78.589233 478.200439,73.386978 477.985077,69.155533 
	C477.755585,64.646843 473.442780,60.554859 467.890381,60.060356 
	C461.819305,59.519661 455.716248,59.338097 448.661804,59.000134 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M647.824280,140.000015 
	C659.689575,141.153778 670.741638,142.760040 679.766602,151.132492 
	C693.897644,164.241806 690.814270,192.243103 668.416992,200.062836 
	C658.881409,203.392059 649.142700,204.307205 639.153748,204.035339 
	C632.360046,203.850433 625.557129,204.000000 618.381714,204.000000 
	C618.381714,182.610550 618.381714,161.603104 618.381714,140.000000 
	C628.143494,140.000000 637.744690,140.000000 647.824280,140.000015 
M665.621277,156.863861 
	C655.437683,149.237335 643.807007,151.102615 632.316040,151.211121 
	C632.316040,165.359192 632.316040,178.923843 632.316040,193.495224 
	C640.550964,192.913681 648.553650,193.081482 656.239746,191.603012 
	C664.189880,190.073761 670.538452,185.375046 672.519470,176.837692 
	C674.296692,169.178284 672.290405,162.458649 665.621277,156.863861 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1155.429199,107.718948 
	C1148.914795,104.011482 1143.983032,99.213387 1141.068848,92.685280 
	C1134.007202,76.866692 1140.418335,61.438271 1154.359863,53.589146 
	C1170.515259,44.493603 1187.509033,44.588963 1203.698853,54.281761 
	C1213.831177,60.347992 1219.385986,69.961922 1218.698120,81.581596 
	C1217.887695,95.272614 1209.845581,104.954536 1197.094849,109.777458 
	C1183.232422,115.020882 1169.262085,114.501045 1155.429199,107.718948 
M1200.626343,70.069069 
	C1192.930298,57.135040 1175.411255,54.332127 1163.151611,62.666195 
	C1153.593262,69.163971 1150.181396,79.615517 1156.222290,90.396149 
	C1161.455444,99.735168 1170.194580,102.014816 1180.262451,102.088516 
	C1195.022095,102.196548 1209.615845,86.786667 1200.626343,70.069069 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M581.000000,54.117077 
	C581.000000,51.997639 581.000000,50.361485 581.000000,48.354126 
	C591.492126,48.354126 601.614502,47.776348 611.631897,48.552761 
	C618.565308,49.090145 625.791260,50.498264 632.117493,53.271473 
	C645.824463,59.280106 651.322571,69.229019 649.898987,84.289017 
	C648.684082,97.140800 640.333923,104.900581 628.807861,108.724358 
	C622.210388,110.913063 614.936951,111.465065 607.916504,111.876755 
	C599.161743,112.390129 590.354065,112.000000 581.000000,112.000000 
	C581.000000,92.492729 581.000000,73.546547 581.000000,54.117077 
M631.597473,69.092972 
	C621.872986,57.237568 608.476257,59.315353 595.389038,59.018974 
	C595.389038,73.275612 595.389038,86.954842 595.389038,101.773994 
	C604.031433,100.916786 612.438477,101.080330 620.295654,99.081902 
	C633.829590,95.639648 638.897034,81.924332 631.597473,69.092972 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M889.272400,95.760040 
	C898.022888,102.890800 907.692322,103.187218 917.729797,100.898552 
	C924.714783,99.305870 925.090881,98.668999 924.990906,91.531883 
	C924.972717,90.232948 924.836426,88.935669 924.723877,87.148285 
	C918.619263,87.148285 912.702087,87.148285 906.396484,87.148285 
	C906.396484,83.289452 906.396484,79.930656 906.396484,76.282104 
	C917.481689,76.282104 928.385986,76.282104 939.969055,76.282104 
	C939.969055,84.681480 940.337463,92.969528 939.697144,101.178894 
	C939.546448,103.110336 936.259216,105.291298 933.961304,106.482155 
	C916.845093,115.352531 899.391968,116.130341 882.433228,106.553215 
	C871.090393,100.147560 866.944580,89.442696 867.990051,76.607597 
	C868.915833,65.242363 875.410278,57.426586 884.988403,52.693928 
	C901.690857,44.441010 918.387878,45.458874 934.406006,55.196823 
	C934.954285,55.530178 935.375427,56.072754 936.124329,56.766472 
	C935.284973,57.835564 934.284119,58.732441 933.752380,59.852119 
	C931.258179,65.104195 928.320679,65.300987 923.324158,62.320526 
	C914.307861,56.942169 904.931641,56.857483 894.970215,61.299671 
	C882.765869,66.742004 878.152588,84.505943 889.272400,95.760040 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1310.006958,165.000000 
	C1319.128418,165.000000 1327.751831,165.000000 1336.682861,165.000000 
	C1336.682861,168.748291 1336.682861,171.995667 1336.682861,175.755875 
	C1323.383667,175.755875 1310.133545,175.755875 1296.115112,175.755875 
	C1296.115112,179.818344 1295.583008,183.493134 1296.250854,186.935120 
	C1297.070923,191.161484 1300.277466,193.063568 1304.713623,193.030029 
	C1316.527100,192.940674 1328.341797,192.999649 1340.576660,192.999649 
	C1340.576660,196.657440 1340.576660,200.016693 1340.576660,204.000000 
	C1329.370605,204.000000 1318.261108,204.017578 1307.151733,203.989655 
	C1303.661011,203.980881 1300.043335,204.418533 1296.703003,203.671768 
	C1285.090820,201.075836 1281.958984,196.925415 1281.238281,185.078873 
	C1280.341553,170.337708 1281.023071,155.500549 1281.023071,140.354462 
	C1300.674316,140.354462 1319.914551,140.354462 1339.578491,140.354462 
	C1339.578491,143.662201 1339.578491,147.022766 1339.578491,150.901001 
	C1325.229126,150.901001 1310.979248,150.901001 1296.369141,150.901001 
	C1296.369141,155.645248 1296.369141,159.891098 1296.369141,165.000000 
	C1300.672607,165.000000 1305.090698,165.000000 1310.006958,165.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1478.016602,193.000000 
	C1487.136230,193.000000 1495.758911,193.000000 1504.685791,193.000000 
	C1504.685791,196.754883 1504.685791,200.001892 1504.685791,204.000000 
	C1500.697876,204.000000 1496.768921,204.000000 1492.839844,204.000000 
	C1484.009644,204.000000 1475.178955,204.065262 1466.349487,203.985214 
	C1451.944946,203.854614 1445.846680,198.427734 1445.178101,184.779922 
	C1444.462158,170.167465 1445.016602,155.492767 1445.016602,140.423508 
	C1464.655029,140.423508 1484.014160,140.423508 1503.688110,140.423508 
	C1503.688110,143.759430 1503.688110,147.006363 1503.688110,150.764862 
	C1489.371582,150.764862 1475.121338,150.764862 1460.438354,150.764862 
	C1460.438354,155.621735 1460.438354,159.986160 1460.438354,164.868103 
	C1473.737793,164.868103 1486.987549,164.868103 1500.614258,164.868103 
	C1500.614258,168.610336 1500.614258,171.857391 1500.614258,175.794617 
	C1487.399048,175.794617 1474.025146,175.794617 1460.034790,175.794617 
	C1460.034790,179.730637 1459.700684,183.048050 1460.118896,186.267776 
	C1460.649902,190.355835 1463.317993,192.756836 1467.525269,192.961380 
	C1470.849487,193.122986 1474.187866,192.997818 1478.016602,193.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1531.927856,202.428970 
	C1523.324097,199.238052 1521.381348,192.077026 1521.149902,184.690903 
	C1520.692139,170.074417 1521.014648,155.433487 1521.014648,140.402527 
	C1540.687378,140.402527 1560.035889,140.402527 1579.693481,140.402527 
	C1579.693481,143.767700 1579.693481,147.011841 1579.693481,150.758560 
	C1565.373047,150.758560 1551.131592,150.758560 1536.447266,150.758560 
	C1536.447266,155.599823 1536.447266,159.959305 1536.447266,164.832764 
	C1549.704712,164.832764 1562.946899,164.832764 1576.590576,164.832764 
	C1576.590576,168.684647 1576.590576,172.043320 1576.590576,175.908966 
	C1563.217773,175.908966 1549.974731,175.908966 1536.060791,175.908966 
	C1536.060791,179.812134 1535.595947,183.320114 1536.177124,186.645248 
	C1536.919556,190.892181 1539.945679,193.030487 1544.402466,193.012344 
	C1554.557617,192.971008 1564.713135,192.999741 1574.868408,192.999985 
	C1576.672607,193.000031 1578.476807,193.000000 1580.634888,193.000000 
	C1580.634888,196.650314 1580.634888,199.897293 1580.634888,204.588562 
	C1564.545776,203.942200 1548.403931,203.293716 1531.927856,202.428970 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1295.000000,80.998695 
	C1295.000000,69.879433 1295.000000,59.259808 1295.000000,48.323807 
	C1314.399048,48.323807 1333.301392,48.323807 1352.602783,48.323807 
	C1352.602783,51.710121 1352.602783,55.069725 1352.602783,58.940826 
	C1338.519287,58.940826 1324.606201,58.940826 1310.351562,58.940826 
	C1310.351562,64.012703 1310.351562,68.589165 1310.351562,73.854431 
	C1322.998535,73.854431 1335.703857,73.854431 1348.696411,73.854431 
	C1348.696411,77.441025 1348.696411,80.354980 1348.696411,83.775421 
	C1336.029785,83.775421 1323.447632,83.775421 1310.054932,83.775421 
	C1310.054932,88.334290 1309.586914,92.481239 1310.191650,96.465370 
	C1310.753662,100.169220 1314.122681,100.988708 1317.490112,100.993797 
	C1327.649536,101.009163 1337.809082,100.999763 1347.968506,101.000000 
	C1349.761719,101.000038 1351.554932,101.000000 1353.671631,101.000000 
	C1353.671631,104.723274 1353.671631,107.968895 1353.671631,111.785172 
	C1352.074585,111.856773 1350.478394,111.988075 1348.881958,111.990402 
	C1336.890503,112.007896 1324.898926,112.018082 1312.907471,111.994675 
	C1301.857300,111.973106 1295.034546,105.095490 1295.001831,93.989342 
	C1294.989624,89.825706 1295.000000,85.662010 1295.000000,80.998695 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1070.994629,140.000000 
	C1079.670166,140.845108 1088.209839,140.485840 1095.939453,142.814743 
	C1107.832031,146.397873 1111.439453,155.263046 1110.855713,164.102982 
	C1110.200317,174.028336 1101.569458,182.432404 1090.957031,183.897446 
	C1084.922241,184.730499 1078.765869,184.741226 1072.658691,184.971817 
	C1068.694214,185.121521 1064.719482,185.000000 1060.250000,185.000000 
	C1060.250000,191.283554 1060.250000,197.197769 1060.250000,203.554199 
	C1055.068848,203.554199 1050.375366,203.554199 1045.334229,203.554199 
	C1045.334229,182.633514 1045.334229,161.741211 1045.334229,140.000000 
	C1053.680664,140.000000 1062.088257,140.000000 1070.994629,140.000000 
M1064.669922,173.999863 
	C1071.943115,173.653595 1079.278931,173.766907 1086.466064,172.788498 
	C1090.770020,172.202576 1094.410767,169.363342 1094.960083,164.387115 
	C1095.611206,158.488342 1093.484497,154.456268 1088.118408,152.700546 
	C1078.976929,149.709595 1069.569580,151.234406 1060.005615,151.108093 
	C1060.005615,158.234970 1060.111328,164.562531 1059.958618,170.883865 
	C1059.887817,173.815292 1061.456665,174.180786 1064.669922,173.999863 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M522.234009,97.462509 
	C525.081421,98.883385 527.710022,100.777473 530.391296,100.855293 
	C542.016113,101.192719 553.656311,100.999939 565.643066,100.999939 
	C565.643066,104.668892 565.643066,107.918159 565.643066,111.768349 
	C564.135437,111.842766 562.543823,111.986908 560.951904,111.989449 
	C548.952637,112.008629 536.953247,112.018623 524.954041,111.994720 
	C513.028198,111.970963 507.002472,105.856873 507.000580,93.832054 
	C506.998199,78.843773 507.000000,63.855499 507.000000,48.434429 
	C525.970154,48.434429 544.666870,48.434429 563.683350,48.434429 
	C563.683350,51.749390 563.683350,54.996181 563.683350,58.762306 
	C549.709290,58.762306 535.788940,58.762306 521.437256,58.762306 
	C521.437256,63.956772 521.437256,68.656242 521.437256,73.877335 
	C534.412903,73.877335 547.332520,73.877335 560.621216,73.877335 
	C560.621216,77.292427 560.621216,80.209091 560.621216,83.817436 
	C547.714417,83.817436 534.670837,83.817436 520.578674,83.817436 
	C521.121887,88.756462 521.580688,92.928185 522.234009,97.462509 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M852.699219,87.338676 
	C856.796143,95.484604 860.705200,103.308014 865.017883,111.939224 
	C859.661499,111.939224 854.612976,112.146614 849.607910,111.772903 
	C848.543945,111.693459 847.133057,109.639603 846.740479,108.252464 
	C844.043945,98.723091 838.043579,95.383484 828.289490,96.844437 
	C822.588562,97.698318 816.641113,96.776573 810.835205,97.141098 
	C809.162964,97.246086 807.594116,99.040413 805.988770,100.077141 
	C805.870361,100.153641 805.815918,100.361145 805.773499,100.518959 
	C802.987244,110.881172 796.335144,114.505768 785.580322,111.240517 
	C786.129761,110.030968 786.762024,108.462357 787.534241,106.965904 
	C797.094910,88.437691 806.720886,69.942879 816.200623,51.373367 
	C817.527954,48.773327 819.219727,48.080406 822.031860,47.894451 
	C828.389465,47.474064 833.053162,48.312832 836.106628,55.295406 
	C840.865906,66.178993 846.974487,76.472565 852.699219,87.338676 
M822.571411,86.000000 
	C826.611450,86.000000 830.651550,86.000000 835.475891,86.000000 
	C831.530518,78.115379 828.094238,71.248283 824.251953,63.569710 
	C820.369202,71.335068 816.859314,78.354599 813.036560,86.000000 
	C816.400513,86.000000 819.007080,86.000000 822.571411,86.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M222.247009,152.059296 
	C222.382690,151.208130 222.673889,150.294952 223.281601,149.965256 
	C232.967834,144.710587 242.689560,139.521179 252.425125,134.358215 
	C252.969330,134.069595 253.703751,134.139618 254.732422,134.070084 
	C257.544373,139.676880 259.823303,145.327713 262.433472,150.821136 
	C268.177490,162.910141 274.088837,174.919662 280.226562,187.568848 
	C278.357147,187.743210 276.981384,187.978638 275.604858,187.982819 
	C265.277618,188.014175 254.947464,187.859055 244.624130,188.062515 
	C239.837662,188.156845 236.764206,186.357910 234.952179,181.938522 
	C230.885773,172.020813 226.691833,162.155411 222.247009,152.059296 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1071.747070,58.755810 
	C1088.951904,43.967632 1113.344360,43.919559 1131.911011,56.392185 
	C1130.173706,58.852066 1128.675049,61.562958 1126.561646,63.651661 
	C1125.806396,64.398064 1123.281860,64.156097 1122.045044,63.483040 
	C1114.127075,59.174240 1106.049194,56.560169 1096.896973,58.982082 
	C1086.695312,61.681694 1079.289062,68.467278 1078.078735,79.957527 
	C1077.239014,87.929314 1084.516479,97.083366 1093.489136,100.238922 
	C1103.357178,103.709305 1112.785522,102.852890 1121.468750,97.099228 
	C1125.257568,94.588600 1127.570679,94.675926 1129.581299,98.922684 
	C1130.332520,100.509262 1131.742554,101.783951 1133.105713,103.524628 
	C1121.501709,111.957870 1108.799805,114.413216 1095.313232,112.533340 
	C1084.914185,111.083847 1075.533691,106.985329 1069.078125,98.135605 
	C1061.461914,87.694588 1060.045410,72.318146 1071.747070,58.755810 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M382.325958,112.998322 
	C371.232208,111.272865 361.705841,107.691238 354.414856,99.048523 
	C345.582977,88.579300 345.737732,70.323486 355.123352,60.337223 
	C362.398499,52.596504 371.442108,49.278896 381.799683,47.832989 
	C392.611755,46.323639 402.307129,48.932465 411.817139,53.413017 
	C412.850250,53.899757 413.809509,54.579975 414.717194,55.281502 
	C415.210663,55.662903 415.523285,56.278301 416.092957,57.015068 
	C414.118011,59.697639 412.189331,62.317345 410.342438,64.825996 
	C404.337830,62.626492 398.669952,59.998436 392.718964,58.479778 
	C378.138763,54.758987 362.631317,66.913261 363.000488,81.375137 
	C363.263519,91.679626 373.929474,101.442673 384.769165,102.096336 
	C394.282227,102.669991 402.692902,100.144241 410.678375,94.428108 
	C412.979034,97.473785 415.009735,100.162071 417.378052,103.297310 
	C406.958984,111.502121 395.282745,113.651817 382.325958,112.998322 
z"
      />
      <path
        fill="#DA403A"
        opacity="1.000000"
        stroke="none"
        d="
M285.956238,44.005882 
	C292.679138,40.302204 299.036499,36.680016 305.998993,32.713062 
	C305.998993,41.816212 306.218628,49.951412 305.820587,58.056282 
	C305.737244,59.753380 303.685272,61.828022 302.008362,62.882145 
	C297.309540,65.835846 292.395203,68.468948 287.452332,71.007416 
	C276.842285,76.456284 266.306793,82.088158 255.451294,87.006172 
	C249.433655,89.732422 245.250793,87.773628 242.517395,81.920387 
	C240.258835,77.084038 238.562988,71.984924 236.529968,66.777199 
	C252.144897,64.571426 263.824188,55.107765 276.870300,48.637383 
	C279.806976,47.180920 282.685455,45.607143 285.956238,44.005882 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1180.253052,140.000000 
	C1184.178589,140.000000 1187.645508,140.000000 1192.275757,140.000000 
	C1187.172241,146.265945 1182.703735,151.860458 1178.116943,157.356323 
	C1172.514160,164.069565 1166.737305,170.639313 1161.227295,177.426605 
	C1160.316040,178.549088 1160.078613,180.461807 1160.052490,182.018127 
	C1159.933228,189.132050 1160.000000,196.249084 1160.000000,203.677704 
	C1154.929321,203.677704 1150.355103,203.677704 1145.000000,203.677704 
	C1145.000000,199.063019 1144.405518,194.382645 1145.125244,189.913910 
	C1146.568115,180.954330 1142.002930,174.996567 1136.543701,168.910721 
	C1128.437988,159.874619 1120.800537,150.418427 1112.038452,140.045822 
	C1118.718140,140.045822 1124.182007,139.496674 1129.435669,140.308380 
	C1131.624634,140.646591 1133.522339,143.750534 1135.281860,145.826233 
	C1141.142944,152.739868 1146.881470,159.757446 1153.152100,167.320496 
	C1157.173828,162.448898 1161.130981,157.724930 1165.008301,152.936508 
	C1168.013550,149.225113 1170.687012,145.208740 1173.989258,141.793106 
	C1175.222778,140.517181 1177.819336,140.558990 1180.253052,140.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M746.881470,48.000000 
	C749.000122,48.000004 750.635803,48.000004 752.634277,48.000004 
	C752.634277,51.651676 752.634277,54.899654 752.634277,58.836464 
	C744.700500,58.836464 736.654419,58.836464 728.121643,58.836464 
	C728.121643,76.753929 728.121643,93.998093 728.121643,111.617950 
	C723.382446,111.617950 719.138245,111.617950 714.202576,111.617950 
	C714.202576,94.391571 714.202576,77.026077 714.202576,59.157440 
	C705.955383,59.157440 698.372314,59.157440 690.398132,59.157440 
	C690.398132,55.291229 690.398132,51.931206 690.398132,48.000000 
	C709.182434,48.000000 727.790405,48.000000 746.881470,48.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1246.000000,61.010143 
	C1246.000000,74.466072 1246.000000,87.423386 1246.000000,100.889549 
	C1259.426025,100.889549 1272.346069,100.889549 1285.628174,100.889549 
	C1285.628174,104.639969 1285.628174,107.889427 1285.628174,111.571930 
	C1268.028809,111.571930 1250.329468,111.571930 1232.313477,111.571930 
	C1232.313477,90.569374 1232.313477,69.657326 1232.313477,48.371944 
	C1236.641113,48.371944 1240.888062,48.371944 1246.000000,48.371944 
	C1246.000000,52.347134 1246.000000,56.429333 1246.000000,61.010143 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M953.000000,160.000320 
	C953.000000,153.217911 953.000000,146.935379 953.000000,140.330994 
	C958.050720,140.330994 962.622986,140.330994 967.600037,140.330994 
	C967.600037,161.339523 967.600037,182.337204 967.600037,203.668655 
	C962.951599,203.668655 958.379456,203.668655 953.000000,203.668655 
	C953.000000,189.277267 953.000000,174.888733 953.000000,160.000320 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M678.000000,55.086571 
	C678.000000,74.165695 678.000000,92.754944 678.000000,111.669998 
	C672.946777,111.669998 668.374084,111.669998 663.398315,111.669998 
	C663.398315,90.655182 663.398315,69.655586 663.398315,48.326820 
	C668.058044,48.326820 672.630737,48.326820 678.000000,48.326820 
	C678.000000,50.428493 678.000000,52.512600 678.000000,55.086571 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1049.000000,59.022629 
	C1049.000000,76.781464 1049.000000,94.043274 1049.000000,111.650635 
	C1044.320068,111.650635 1040.079956,111.650635 1035.416992,111.650635 
	C1035.416992,90.696991 1035.416992,69.698555 1035.416992,48.348694 
	C1039.681274,48.348694 1043.921387,48.348694 1049.000000,48.348694 
	C1049.000000,51.703754 1049.000000,55.114674 1049.000000,59.022629 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M833.142944,141.365097 
	C837.582581,140.498535 841.926758,140.088730 846.654663,139.642715 
	C846.654663,161.337402 846.654663,182.224518 846.654663,203.556610 
	C842.393860,203.556610 838.033142,203.556610 833.047546,203.556610 
	C833.047546,182.958115 833.047546,162.389984 833.142944,141.365097 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M986.000610,170.128723 
	C986.885742,168.446136 987.751038,166.186661 988.657715,166.169922 
	C1001.240295,165.937592 1013.828674,166.012726 1026.704468,166.012726 
	C1026.704468,170.121475 1026.704468,173.695511 1026.704468,177.636230 
	C1013.249084,177.636230 999.908936,177.636230 986.000000,177.636230 
	C986.000000,175.152161 986.000000,172.873367 986.000610,170.128723 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1309.178101,134.000000 
	C1307.119629,134.000000 1305.496582,134.000000 1303.749390,134.000000 
	C1308.792480,122.610275 1312.816772,120.519096 1325.478271,122.489273 
	C1322.162109,130.530411 1317.380859,133.999451 1309.178101,134.000000 
z"
      />
      <path
        fill="#156D55"
        opacity="1.000000"
        stroke="none"
        d="
M1474.551758,123.088165 
	C1479.685547,122.430061 1484.542236,122.067230 1489.689575,121.682678 
	C1485.967651,132.039093 1480.401611,134.943695 1467.422852,133.615845 
	C1469.780396,130.095261 1472.027588,126.739349 1474.551758,123.088165 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M970.000000,71.924126 
	C970.000000,67.481438 970.000000,63.526779 970.000000,58.458981 
	C978.977600,59.115154 987.374451,59.390957 995.668579,60.462170 
	C1000.623169,61.102074 1004.389954,66.222794 1003.978821,70.188225 
	C1003.558350,74.243774 998.622070,78.772461 993.737793,78.926773 
	C986.099548,79.168106 978.448059,78.990730 970.000000,78.990730 
	C970.000000,76.583672 970.000000,74.497917 970.000000,71.924126 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M882.564087,192.416473 
	C882.239014,178.636978 882.239014,165.191269 882.239014,151.264648 
	C893.100830,150.788605 903.811646,149.874176 913.572021,155.872147 
	C920.203918,159.947601 922.499451,166.128296 922.015137,173.650879 
	C921.603516,180.042770 918.897583,184.987869 913.323181,188.302292 
	C903.783020,193.974655 893.323181,193.250305 882.564087,192.416473 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M577.335449,152.217194 
	C582.378357,154.090042 585.172546,157.338776 584.894409,162.413589 
	C584.673096,166.450378 579.941101,170.544144 574.462036,170.839355 
	C566.536011,171.266418 558.569885,170.948685 550.315186,170.948685 
	C550.315186,164.247482 550.315186,158.006958 550.315186,150.587509 
	C559.262939,151.091797 568.106262,151.590195 577.335449,152.217194 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1216.000000,158.078262 
	C1216.000000,155.629562 1216.000000,153.667496 1216.000000,150.479126 
	C1225.476807,151.093079 1234.505005,151.336945 1243.435425,152.400726 
	C1247.886597,152.930939 1251.337158,158.233688 1250.979736,162.113144 
	C1250.577271,166.484848 1245.661255,170.687607 1239.797852,170.915451 
	C1232.171875,171.211761 1224.525757,170.987869 1216.000000,170.987869 
	C1216.000000,166.701904 1216.000000,162.633392 1216.000000,158.078262 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M454.000977,169.359406 
	C456.447968,156.937195 465.019257,151.597687 475.447144,150.128937 
	C484.740936,148.819916 493.545288,151.379044 499.673950,158.632019 
	C509.842987,170.666595 503.789062,185.771088 491.507935,191.328201 
	C477.420135,197.702820 461.447266,192.997360 455.353882,179.449814 
	C454.074188,176.604584 454.409271,173.033081 454.000977,169.359406 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M449.144470,59.000072 
	C455.716248,59.338097 461.819305,59.519661 467.890381,60.060356 
	C473.442780,60.554859 477.755585,64.646843 477.985077,69.155533 
	C478.200439,73.386978 473.543915,78.589233 468.319794,78.856491 
	C460.419769,79.260651 452.483795,78.962006 444.287140,78.962006 
	C444.287140,72.201485 444.287140,65.973366 444.287140,59.339489 
	C445.800690,59.222076 447.231232,59.111107 449.144470,59.000072 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M665.968811,156.987823 
	C672.290405,162.458649 674.296692,169.178284 672.519470,176.837692 
	C670.538452,185.375046 664.189880,190.073761 656.239746,191.603012 
	C648.553650,193.081482 640.550964,192.913681 632.316040,193.495224 
	C632.316040,178.923843 632.316040,165.359192 632.316040,151.211121 
	C643.807007,151.102615 655.437683,149.237335 665.968811,156.987823 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1200.869995,70.360306 
	C1209.615845,86.786667 1195.022095,102.196548 1180.262451,102.088516 
	C1170.194580,102.014816 1161.455444,99.735168 1156.222290,90.396149 
	C1150.181396,79.615517 1153.593262,69.163971 1163.151611,62.666195 
	C1175.411255,54.332127 1192.930298,57.135040 1200.869995,70.360306 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M631.849854,69.367844 
	C638.897034,81.924332 633.829590,95.639648 620.295654,99.081902 
	C612.438477,101.080330 604.031433,100.916786 595.389038,101.773994 
	C595.389038,86.954842 595.389038,73.275612 595.389038,59.018974 
	C608.476257,59.315353 621.872986,57.237568 631.849854,69.367844 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1064.193604,173.999405 
	C1061.456665,174.180786 1059.887817,173.815292 1059.958618,170.883865 
	C1060.111328,164.562531 1060.005615,158.234970 1060.005615,151.108093 
	C1069.569580,151.234406 1078.976929,149.709595 1088.118408,152.700546 
	C1093.484497,154.456268 1095.611206,158.488342 1094.960083,164.387115 
	C1094.410767,169.363342 1090.770020,172.202576 1086.466064,172.788498 
	C1079.278931,173.766907 1071.943115,173.653595 1064.193604,173.999405 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M822.092529,86.000000 
	C819.007080,86.000000 816.400513,86.000000 813.036560,86.000000 
	C816.859314,78.354599 820.369202,71.335068 824.251953,63.569710 
	C828.094238,71.248283 831.530518,78.115379 835.475891,86.000000 
	C830.651550,86.000000 826.611450,86.000000 822.092529,86.000000 
z"
      />
    </svg>
  );
};

CaNordMidiPyrenesSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaNordMidiPyrenesSvg;
