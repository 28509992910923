import React from 'react';
import PropTypes from 'prop-types';
import IconUserSvg from './svg/icon-user-svg';
import './styles.scss';

const IconUser = props => {
  const { title, alt, manageRedirection, width, height } = props;
  return (
    <div className="user" onClick={manageRedirection}>
      <IconUserSvg className="user__icon" title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconUser.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  manageRedirection: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconUser;
