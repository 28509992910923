import * as React from 'react';
import PropTypes from 'prop-types';

const CaAquitaineSvg = ({ alt, width, height, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1384 255"
      enableBackground="new 0 0 1384 255"
      xmlSpace="preserve"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="#f7f8fa"
        opacity="1.000000"
        stroke="none"
        d="
M1029.000000,256.000000 
	C686.000122,256.000000 343.500214,256.000000 1.000230,256.000000 
	C1.000153,171.000122 1.000153,86.000252 1.000077,1.000287 
	C462.333038,1.000191 923.666077,1.000191 1384.999268,1.000096 
	C1384.999512,85.999710 1384.999512,170.999420 1384.999756,255.999573 
	C1266.500000,256.000000 1148.000000,256.000000 1029.000000,256.000000 
M550.101746,31.021069 
	C545.386658,31.065702 540.671631,31.110334 535.034546,31.010941 
	C367.080109,31.010941 199.125687,31.010941 31.164158,31.010941 
	C31.164158,60.078697 31.164158,88.535896 31.023312,117.919037 
	C31.070250,126.948349 31.117189,135.977661 31.011604,145.932388 
	C31.007738,171.139664 30.971766,196.347061 31.083975,221.553833 
	C31.090349,222.986160 32.310658,224.413086 33.896282,225.979340 
	C117.613724,225.986145 201.331177,225.993484 285.048615,225.993210 
	C286.043854,225.993195 287.039062,225.894989 288.957092,225.988892 
	C644.235901,225.988892 999.514709,225.988892 1354.842529,225.988892 
	C1354.842529,187.620270 1354.842529,149.827148 1354.979004,111.122223 
	C1354.933594,107.736717 1354.888184,104.351204 1354.988892,100.042877 
	C1354.988892,77.118332 1354.988892,54.193779 1354.988892,31.000000 
	C1352.845459,31.000000 1351.208618,31.000000 1349.571655,31.000000 
	C1085.051880,31.000000 820.532166,30.999708 556.012451,31.006578 
	C554.350647,31.006620 552.688904,31.106173 550.101746,31.021069 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M32.965981,225.842545 
	C32.310658,224.413086 31.090349,222.986160 31.083975,221.553833 
	C30.971766,196.347061 31.007738,171.139664 31.466686,145.492462 
	C32.271976,145.523880 32.804642,145.946548 32.945381,146.473816 
	C35.626667,156.519104 40.396317,165.732544 48.164265,172.357254 
	C68.243637,189.481476 92.556519,192.824890 117.552376,189.629700 
	C132.290314,187.745743 147.102341,183.899048 161.005829,178.626968 
	C181.728119,170.769257 201.677353,160.872879 222.267487,152.038376 
	C224.728516,157.379074 226.689468,162.613724 229.059494,167.656128 
	C238.743271,188.258957 231.970215,188.349686 258.020325,188.014160 
	C265.236816,187.921204 272.455536,188.000000 280.307495,188.000000 
	C277.468109,181.804550 275.055206,176.433899 272.554504,171.104477 
	C266.761230,158.758087 260.925446,146.431656 255.418518,133.937164 
	C258.708252,132.093460 261.745148,130.504959 264.654449,128.709717 
	C277.992310,120.479286 291.312714,112.219978 304.574768,103.868431 
	C305.633331,103.201805 306.870544,101.766861 306.890808,100.668045 
	C307.071045,90.897858 306.995667,81.122948 306.995667,70.464272 
	C296.652008,76.275963 287.082245,81.782730 277.390137,87.065094 
	C265.658997,93.458763 253.824417,99.662590 242.000671,105.576324 
	C241.278900,103.360558 240.700790,101.466957 239.886017,99.681343 
	C232.776489,84.100433 225.404739,68.633125 218.657440,52.897717 
	C217.180664,49.453712 215.510117,48.925121 212.424393,48.959034 
	C202.430359,49.068848 192.427780,49.209042 182.442398,48.895088 
	C178.556396,48.772903 176.472687,50.120285 174.764664,53.449257 
	C168.592621,65.478729 162.271713,77.433540 155.871536,89.343735 
	C150.021973,100.229309 143.915298,110.976807 138.074936,121.867218 
	C133.345444,130.686218 128.843323,139.627151 123.903923,149.163513 
	C137.764877,148.593704 150.172729,145.913940 161.670944,139.758194 
	C164.647583,138.164612 168.254791,136.462341 169.829575,133.784943 
	C174.066818,126.580894 177.430023,118.855049 181.015686,111.277405 
	C185.556763,101.680634 189.974930,92.025703 194.623688,82.013412 
	C195.237167,82.945961 195.688705,83.436546 195.921494,84.015144 
	C200.519882,95.444611 205.040436,106.905998 209.750519,118.289009 
	C210.233826,119.457031 211.820084,120.168648 212.791626,121.430618 
	C199.617737,128.137619 186.677505,134.793137 173.449371,140.816360 
	C143.365753,154.514481 111.857834,161.946396 78.642441,160.939590 
	C71.230225,160.714920 64.228264,158.739395 57.855747,154.529160 
	C53.439011,151.611084 52.220810,147.227554 53.286125,143.047501 
	C54.838840,136.954971 56.920013,130.582184 60.442444,125.479279 
	C74.773285,104.718414 94.165657,90.071686 118.081062,82.102730 
	C129.588104,78.268425 141.674011,76.171333 153.848129,73.202110 
	C153.107803,70.570305 151.912628,66.863380 151.028748,63.083668 
	C147.574722,48.313404 138.733292,42.997231 124.048355,46.381588 
	C108.510788,49.962444 93.794350,55.391418 79.988678,63.452709 
	C58.824894,75.810486 42.360943,92.299667 32.970669,115.289261 
	C32.685757,115.986794 31.780304,116.430862 31.164158,116.993103 
	C31.164158,88.535896 31.164158,60.078697 31.164158,31.010941 
	C199.125687,31.010941 367.080109,31.010941 535.360107,31.383757 
	C533.103882,35.200264 530.522095,38.643955 527.782654,42.298050 
	C538.156860,44.238201 542.358704,42.801991 547.553467,35.860088 
	C548.720825,34.300194 549.869690,32.726562 551.027100,31.159256 
	C552.688904,31.106173 554.350647,31.006620 556.012451,31.006578 
	C820.532166,30.999708 1085.051880,31.000000 1349.571655,31.000000 
	C1351.208618,31.000000 1352.845459,31.000000 1354.988892,31.000000 
	C1354.988892,54.193779 1354.988892,77.118332 1354.457764,100.516373 
	C1344.262451,100.993248 1334.594849,100.831535 1324.935059,101.038048 
	C1309.821289,101.361168 1309.475830,100.629707 1310.151123,85.312752 
	C1310.163696,85.026810 1310.436279,84.752327 1310.925293,83.847618 
	C1323.641357,83.847618 1336.691650,83.847618 1349.682861,83.847618 
	C1349.682861,80.285202 1349.682861,77.360138 1349.682861,73.811279 
	C1336.330078,73.811279 1323.282227,73.811279 1310.335205,73.811279 
	C1310.335205,68.579071 1310.335205,63.991997 1310.335205,58.779369 
	C1324.706665,58.779369 1338.753174,58.779369 1352.649170,58.779369 
	C1352.649170,54.881992 1352.649170,51.624763 1352.649170,48.268242 
	C1333.714111,48.268242 1315.130981,48.268242 1296.000000,48.268242 
	C1296.000000,50.312557 1296.000000,52.096470 1296.000000,53.880386 
	C1296.000000,67.710167 1295.997070,81.539940 1296.000977,95.369720 
	C1296.003906,105.433945 1302.621216,111.975990 1312.853638,111.996002 
	C1326.849976,112.023384 1340.846191,112.022690 1354.842529,112.034035 
	C1354.842529,149.827148 1354.842529,187.620270 1354.842529,225.988892 
	C999.514709,225.988892 644.235901,225.988892 288.483612,225.457809 
	C288.010132,217.683823 288.010132,210.440933 288.010132,203.440781 
	C202.499939,203.440781 117.856339,203.440781 32.965977,203.440781 
	C32.965977,211.201096 32.965977,218.521820 32.965981,225.842545 
M480.589691,139.966644 
	C466.824921,137.596649 454.515411,140.625900 443.757568,149.715820 
	C436.428162,155.908844 432.356750,164.169846 433.287689,173.271759 
	C434.924683,189.276550 443.623749,199.119400 459.891724,202.872147 
	C470.121887,205.232056 480.290222,206.871658 488.494659,214.132339 
	C490.322021,215.749466 492.509125,217.089996 494.745636,218.064362 
	C496.016144,218.617889 498.406006,218.813095 499.112549,218.061371 
	C501.936890,215.056198 504.299164,211.616745 507.008423,208.110123 
	C501.316040,206.194046 496.340393,204.519241 490.343506,202.500656 
	C493.305450,200.947708 495.252319,200.089172 497.033539,198.967514 
	C512.056091,189.507568 516.551331,177.895996 511.618439,162.223953 
	C507.296875,148.494232 495.646240,141.840179 480.589691,139.966644 
M891.000000,181.499939 
	C891.000000,167.756653 891.000000,154.013367 891.000000,140.319382 
	C885.608398,140.319382 881.022095,140.319382 875.832031,140.319382 
	C875.832031,154.305130 875.832031,167.983017 875.832031,183.242828 
	C863.791504,169.175049 852.829346,156.306854 841.766357,143.525940 
	C840.535583,142.104065 838.822510,140.368118 837.174622,140.182053 
	C832.961243,139.706299 828.658325,140.023407 824.422485,140.023407 
	C824.422485,161.708679 824.422485,182.738144 824.422485,203.657455 
	C829.438293,203.657455 834.023804,203.657455 839.212097,203.657455 
	C839.212097,189.649246 839.212097,175.972015 839.212097,161.039703 
	C841.697449,163.820145 843.321411,165.562653 844.863464,167.374893 
	C852.020325,175.785828 859.391113,184.029358 866.227234,192.694199 
	C872.554871,200.714661 879.040222,207.524689 891.000000,202.954132 
	C891.000000,196.418671 891.000000,189.459274 891.000000,181.499939 
M483.720642,112.000000 
	C487.411621,112.000000 491.102570,112.000000 495.967133,112.000000 
	C488.678162,103.539192 482.361908,96.207535 476.458496,89.355042 
	C480.954559,86.241409 485.782898,84.066742 488.985870,80.451904 
	C497.316589,71.049934 494.347504,53.272270 477.139526,50.362160 
	C462.899902,47.954029 448.132538,48.657143 433.597046,48.027016 
	C432.504822,47.979664 431.396820,48.295193 430.276978,48.444592 
	C430.276978,69.656532 430.276978,90.564087 430.276978,111.627419 
	C434.927338,111.627419 439.295532,111.627419 444.000000,111.627419 
	C444.000000,107.782898 444.000000,104.307755 444.000000,100.832603 
	C444.000000,97.383965 444.000000,93.935318 444.000000,90.000053 
	C448.351593,90.000053 452.181366,89.667694 455.914307,90.125175 
	C458.140472,90.397995 460.685974,91.429527 462.256775,92.968407 
	C465.291443,95.941406 467.300598,100.048416 470.539917,102.703041 
	C474.486786,105.937569 475.629089,113.071129 483.720642,112.000000 
M1017.982361,76.075188 
	C1020.778870,66.863007 1018.914185,56.572144 1009.102112,52.728619 
	C1003.383484,50.488522 997.213989,48.595829 991.145020,48.225876 
	C979.594727,47.521786 967.971130,48.021763 956.437927,48.021763 
	C956.437927,69.728989 956.437927,90.758926 956.437927,111.642708 
	C961.134277,111.642708 965.386963,111.642708 970.090515,111.642708 
	C970.090515,104.369095 970.090515,97.441925 970.090515,90.000336 
	C974.489441,90.000336 978.469299,89.738503 982.388550,90.112061 
	C984.314697,90.295654 986.565735,91.222992 987.904480,92.572777 
	C990.904480,95.597397 993.356201,99.157204 996.160828,102.386520 
	C998.960754,105.610420 1001.396790,110.303368 1004.934387,111.452644 
	C1009.761963,113.021027 1015.481384,111.844009 1021.831543,111.844009 
	C1014.644592,103.495346 1008.322754,96.151627 1001.644104,88.393410 
	C1008.752380,86.177315 1014.524902,83.219383 1017.982361,76.075188 
M597.495178,48.000000 
	C592.081909,48.000000 586.668640,48.000000 581.275574,48.000000 
	C581.275574,69.688950 581.275574,90.578270 581.275574,111.962486 
	C593.471375,111.962486 605.438232,112.559082 617.314026,111.774979 
	C626.928833,111.140137 636.331909,107.787910 642.836975,100.402237 
	C655.842957,85.635635 652.475464,60.311272 630.650513,52.048775 
	C620.287354,48.125473 609.387207,47.956379 597.495178,48.000000 
M1195.789551,110.634903 
	C1197.992310,109.502693 1200.243652,108.455048 1202.389771,107.224113 
	C1213.685791,100.745255 1219.835083,91.100098 1218.896851,77.951324 
	C1218.074219,66.420448 1211.953003,58.069183 1201.556030,52.832073 
	C1192.515015,48.277985 1183.211914,46.575039 1173.000854,47.811489 
	C1163.810059,48.924416 1155.597412,51.892132 1148.805786,57.865742 
	C1140.185669,65.447502 1137.088867,75.626961 1139.476562,86.643013 
	C1142.286133,99.605530 1151.693848,106.939377 1163.817749,110.959633 
	C1174.181152,114.396072 1184.630737,113.408150 1195.789551,110.634903 
M872.070129,96.226723 
	C876.695618,100.307770 880.710144,105.655151 886.059387,108.236229 
	C903.201538,116.507408 920.309326,114.223450 936.778564,105.509087 
	C938.078003,104.821510 939.796326,103.294014 939.832336,102.111549 
	C940.093628,93.529083 939.972717,84.934975 939.972717,76.273895 
	C928.693665,76.273895 918.111816,76.273895 907.370361,76.273895 
	C907.370361,79.923004 907.370361,83.290619 907.370361,87.111061 
	C913.656860,87.111061 919.585754,87.111061 925.422058,87.111061 
	C926.881165,96.423851 926.306152,98.008682 920.941772,99.927078 
	C912.903137,102.801819 904.773376,103.311485 896.797302,99.990807 
	C888.905090,96.705070 883.909424,90.658966 883.131165,82.099602 
	C882.410950,74.179100 885.637512,67.514145 892.419800,63.146133 
	C903.495239,56.013176 914.723328,56.387260 925.814941,63.293179 
	C930.030701,65.917969 931.058655,65.460091 934.784119,59.544010 
	C937.185059,55.731449 934.215515,54.708820 931.775513,53.364258 
	C919.624268,46.668343 906.791382,45.657547 893.597656,49.261398 
	C871.555237,55.282272 861.852234,76.100510 872.070129,96.226723 
M947.498352,176.000000 
	C952.920593,176.000000 958.342896,176.000000 963.675415,176.000000 
	C963.675415,171.934525 963.675415,168.677292 963.675415,164.801956 
	C950.650269,164.801956 937.939087,164.801956 925.337158,164.801956 
	C925.337158,159.908234 925.337158,155.654480 925.337158,150.779938 
	C939.708923,150.779938 953.753540,150.779938 967.651978,150.779938 
	C967.651978,146.882736 967.651978,143.627243 967.651978,140.437622 
	C948.270020,140.437622 929.239380,140.437622 910.004822,140.437622 
	C910.004822,156.360779 909.736816,171.854019 910.126892,187.330643 
	C910.301575,194.258926 913.629272,201.857635 923.445312,203.101501 
	C928.995300,203.804764 934.642151,203.886337 940.249268,203.964142 
	C949.685181,204.095078 959.124268,204.000000 968.587524,204.000000 
	C968.587524,199.994537 968.587524,196.625961 968.587524,193.000000 
	C966.356506,193.000000 964.536926,193.000046 962.717407,193.000000 
	C952.556091,192.999725 942.394409,193.045868 932.233582,192.973877 
	C928.693054,192.948792 925.440002,191.940353 925.077026,187.758698 
	C924.751770,184.011032 925.008545,180.212845 925.008545,176.000000 
	C932.580261,176.000000 939.539551,176.000000 947.498352,176.000000 
M543.500000,112.000000 
	C550.916443,112.000000 558.332947,112.000000 565.682373,112.000000 
	C565.682373,107.942482 565.682373,104.688759 565.682373,101.000008 
	C563.740356,101.000008 562.111755,101.000023 560.483215,101.000000 
	C550.161011,100.999825 539.838806,101.002342 529.516602,100.997925 
	C526.151245,100.996483 522.951538,100.183014 522.140259,96.533516 
	C521.270264,92.619881 521.155457,88.538399 520.633667,83.832184 
	C534.659729,83.832184 547.702820,83.832184 560.683716,83.832184 
	C560.683716,80.279892 560.683716,77.358826 560.683716,73.824417 
	C547.339905,73.824417 534.301331,73.824417 521.318787,73.824417 
	C521.318787,68.608444 521.318787,64.023895 521.318787,58.825989 
	C535.999268,58.825989 550.371826,58.825989 564.681641,58.825989 
	C564.681641,54.944145 564.681641,51.688446 564.681641,48.396389 
	C545.339844,48.396389 526.316528,48.396389 507.000000,48.396389 
	C507.000000,50.771336 506.999634,52.754543 507.000031,54.737751 
	C507.002777,68.722664 506.732574,82.714943 507.109650,96.689758 
	C507.340240,105.234970 514.836548,111.824402 523.520813,111.977570 
	C529.845398,112.089127 536.173462,111.999779 543.500000,112.000000 
M759.492126,167.989670 
	C756.258606,161.485672 753.122070,154.930740 749.747375,148.500839 
	C748.234497,145.618393 746.800598,141.518158 744.340210,140.586411 
	C740.695923,139.206238 736.022400,139.501007 732.082458,140.390915 
	C730.156616,140.825912 728.562500,144.039291 727.390930,146.292847 
	C719.629822,161.221054 712.039551,176.238007 704.357544,191.207489 
	C702.284546,195.246979 700.086548,199.222336 697.536987,203.992203 
	C702.511536,203.992203 706.634399,204.204956 710.711548,203.866455 
	C711.985229,203.760712 713.922241,202.371170 714.220276,201.222763 
	C717.166992,189.867813 724.850647,187.092865 735.382507,188.964401 
	C736.834778,189.222473 738.416931,189.235397 739.863525,188.962296 
	C750.278625,186.996140 757.349854,190.353546 760.151001,201.144669 
	C760.441650,202.264099 761.992859,203.793427 763.034607,203.854965 
	C767.907410,204.142792 772.806763,203.982742 778.339661,203.982742 
	C771.893555,191.628296 765.891907,180.125824 759.492126,167.989670 
M408.887909,195.618454 
	C410.738037,198.396408 412.085968,202.844116 414.548828,203.584808 
	C418.907654,204.895752 423.944519,203.952301 429.612976,203.952301 
	C428.525482,201.522232 427.891174,199.894272 427.092194,198.351562 
	C418.524994,181.809952 409.462250,165.501602 401.571167,148.644424 
	C398.302979,141.662857 394.511230,138.963501 386.988708,139.807602 
	C383.559143,140.192444 381.839020,141.164825 380.322601,144.142609 
	C373.218048,158.093292 365.846954,171.907944 358.642700,185.808289 
	C355.639282,191.603302 352.813995,197.490646 349.815674,203.521179 
	C363.848663,205.279663 364.070404,205.383179 369.535767,193.674591 
	C371.345856,189.796722 373.591339,188.771408 377.531158,188.922363 
	C384.678589,189.196213 391.877014,189.419556 398.990875,188.859909 
	C404.166809,188.452728 407.075836,190.189255 408.887909,195.618454 
M863.926331,108.744255 
	C854.891418,91.334129 845.486389,74.101395 837.013000,56.422119 
	C833.847595,49.817696 830.233154,46.942024 822.893066,47.823376 
	C819.373962,48.245907 817.432190,49.013935 815.754211,52.347061 
	C806.935181,69.866028 797.770752,87.211060 788.758728,104.633217 
	C787.660278,106.756638 786.756409,108.980675 785.698669,111.297661 
	C786.749878,111.650597 787.188049,111.901611 787.642273,111.935074 
	C800.411804,112.875954 800.456604,112.891182 805.530579,100.887375 
	C806.673157,98.184319 808.067810,96.718307 811.343628,96.914536 
	C816.650024,97.232391 822.088135,97.651550 827.293945,96.862450 
	C837.673706,95.289093 844.702148,98.251625 847.608093,108.953941 
	C847.940186,110.176926 849.736938,111.728951 850.974976,111.845093 
	C854.934082,112.216530 858.953491,112.060760 862.938721,111.875320 
	C863.376221,111.854973 863.743591,110.326103 863.926331,108.744255 
M543.041016,184.146957 
	C542.360657,177.792374 541.346863,171.451599 541.083191,165.079788 
	C540.742188,156.837540 541.000000,148.570511 541.000000,140.293488 
	C535.995178,140.293488 531.742554,140.293488 527.000000,140.293488 
	C527.000000,142.703384 526.997925,144.826508 527.000305,146.949631 
	C527.013123,158.441650 526.247253,170.008224 527.336304,181.397400 
	C527.978088,188.109100 531.620361,194.367477 537.879272,198.541351 
	C548.698975,205.756729 560.261902,206.537460 572.398682,203.225403 
	C585.636536,199.612839 593.362976,188.360077 593.048340,175.395462 
	C592.805969,165.408005 593.010986,155.409927 592.989746,145.416626 
	C592.986023,143.671616 592.846741,141.926910 592.777771,140.357315 
	C587.863953,140.357315 583.612122,140.357315 579.000000,140.357315 
	C579.000000,152.343964 579.005005,163.977112 578.998413,175.610275 
	C578.993286,184.660675 574.044250,191.388672 565.739441,193.660919 
	C557.189697,196.000183 547.844604,192.314499 543.041016,184.146957 
M397.335083,101.044846 
	C388.631500,103.220703 380.037170,102.755043 372.578491,97.683197 
	C360.755524,89.643661 359.543518,74.134148 370.878998,64.438736 
	C380.378387,56.313766 395.832916,56.318291 405.833649,63.255840 
	C409.917419,66.088783 411.057892,65.462669 414.784363,59.543819 
	C417.187103,55.727531 414.236877,54.656567 411.767487,53.370235 
	C395.869202,45.088642 379.975067,45.131191 364.085938,53.437454 
	C345.049408,63.389053 340.651581,91.087975 361.481537,105.543114 
	C377.642731,116.758331 401.692383,115.694809 417.739563,103.492874 
	C416.461548,101.787682 414.929596,100.212364 413.957886,98.345444 
	C412.171173,94.912712 410.432556,94.278549 407.081879,96.825798 
	C404.529999,98.765785 401.171265,99.644394 397.335083,101.044846 
M1079.958740,73.791534 
	C1083.569702,60.388496 1104.075317,53.976521 1117.320312,61.038162 
	C1126.378662,65.867630 1126.489014,65.662811 1131.813599,57.252811 
	C1131.888916,57.133942 1131.822998,56.925720 1131.822998,55.964157 
	C1131.744385,55.920261 1131.143188,55.620033 1130.577759,55.263336 
	C1119.712036,48.409737 1107.917114,45.985920 1095.254272,48.025932 
	C1077.630737,50.865135 1062.032715,62.368885 1064.020508,83.748672 
	C1064.751343,91.608780 1068.240112,98.032654 1074.347046,103.138145 
	C1086.670654,113.441086 1100.964111,114.727104 1115.854980,111.764656 
	C1122.139404,110.514427 1128.354126,108.051949 1133.253662,103.100914 
	C1131.022705,100.131760 1129.075684,97.540489 1127.033936,94.823326 
	C1125.765503,95.312172 1124.545654,95.500191 1123.685303,96.158264 
	C1116.528320,101.633293 1108.252197,102.903114 1099.706665,101.907089 
	C1087.676514,100.504929 1075.334106,89.151543 1079.958740,73.791534 
M273.985779,78.513649 
	C284.007233,73.001602 294.051056,67.529411 304.020477,61.924816 
	C305.207001,61.257767 306.798309,59.890305 306.827606,58.810234 
	C307.058777,50.283585 306.956055,41.747879 306.956055,32.431454 
	C300.622162,35.865532 295.260376,38.949368 289.745605,41.729755 
	C272.390411,50.479744 256.557343,62.425903 236.625107,67.172989 
	C238.655167,72.182755 240.486816,77.398079 242.854584,82.357445 
	C245.403152,87.695419 249.587143,89.478386 255.106903,87.311111 
	C261.327515,84.868652 267.282227,81.748993 273.985779,78.513649 
M724.500000,48.000000 
	C713.088501,48.000000 701.677002,48.000000 690.314636,48.000000 
	C690.314636,52.047829 690.314636,55.303413 690.314636,59.178947 
	C698.327148,59.178947 706.042908,59.178947 714.174622,59.178947 
	C714.174622,77.054863 714.174622,94.300629 714.174622,111.594025 
	C719.332153,111.594025 724.035889,111.594025 729.173218,111.594025 
	C729.173218,93.946404 729.173218,76.701447 729.173218,58.833790 
	C737.321777,58.833790 745.038818,58.833790 752.680908,58.833790 
	C752.680908,54.943176 752.680908,51.687210 752.680908,48.000000 
	C743.412537,48.000000 734.456238,48.000000 724.500000,48.000000 
M680.755066,151.005920 
	C687.692993,151.005920 694.630859,151.005920 701.586304,151.005920 
	C701.586304,146.990997 701.586304,143.622910 701.586304,140.316956 
	C680.927368,140.316956 660.683716,140.316956 640.415283,140.316956 
	C640.415283,144.012085 640.415283,147.380203 640.415283,151.200058 
	C648.414124,151.200058 656.005371,151.200058 664.203003,151.200058 
	C664.203003,169.024124 664.203003,186.395035 664.203003,203.664520 
	C669.091553,203.664520 673.344910,203.664520 678.000000,203.664520 
	C678.000000,201.538345 677.999939,199.735153 678.000000,197.931946 
	C678.000061,183.775772 677.946716,169.619080 678.079529,155.464157 
	C678.093384,153.983704 679.208069,152.513580 680.755066,151.005920 
M1232.000000,74.500000 
	C1232.000000,86.909798 1232.000000,99.319595 1232.000000,111.694847 
	C1250.378540,111.694847 1267.957153,111.694847 1285.597290,111.694847 
	C1285.597290,108.012375 1285.597290,104.644547 1285.597290,100.828964 
	C1272.615356,100.828964 1260.030029,100.828964 1246.831421,100.828964 
	C1246.831421,83.011772 1246.831421,65.641052 1246.831421,48.317024 
	C1241.612183,48.317024 1237.026245,48.317024 1232.000000,48.317024 
	C1232.000000,56.917973 1232.000000,65.208984 1232.000000,74.500000 
M1050.000000,73.500000 
	C1050.000000,65.084999 1050.000000,56.669998 1050.000000,48.320774 
	C1044.605957,48.320774 1040.020630,48.320774 1035.401611,48.320774 
	C1035.401611,69.670807 1035.401611,90.697525 1035.401611,111.679375 
	C1040.395264,111.679375 1044.981079,111.679375 1050.000000,111.679375 
	C1050.000000,99.075920 1050.000000,86.787956 1050.000000,73.500000 
M613.792847,203.960358 
	C618.062195,203.960358 622.331543,203.960358 626.575012,203.960358 
	C626.575012,182.294754 626.575012,161.261078 626.575012,140.346420 
	C621.557434,140.346420 616.970398,140.346420 612.293518,140.346420 
	C612.172119,141.317627 612.022217,141.959778 612.021667,142.602066 
	C612.006165,161.928192 611.991577,181.254379 612.039429,200.580399 
	C612.042114,201.650711 612.581726,202.719681 613.792847,203.960358 
M805.000000,148.550735 
	C805.000000,145.795059 805.000000,143.039368 805.000000,140.308289 
	C799.630981,140.308289 795.045837,140.308289 790.390747,140.308289 
	C790.390747,161.646500 790.390747,182.674118 790.390747,203.689880 
	C795.371521,203.689880 799.957520,203.689880 805.000000,203.689880 
	C805.000000,185.435684 805.000000,167.487656 805.000000,148.550735 
M664.000000,68.500191 
	C664.000000,82.908211 664.000000,97.316231 664.000000,111.684326 
	C669.049744,111.684326 673.303467,111.684326 677.604004,111.684326 
	C677.604004,90.339340 677.604004,69.312462 677.604004,48.315552 
	C672.950439,48.315552 668.696777,48.315552 664.000000,48.315552 
	C664.000000,54.915401 664.000000,61.207916 664.000000,68.500191 
z"
      />
      <path
        fill="#03A2B3"
        opacity="1.000000"
        stroke="none"
        d="
M31.093735,117.456070 
	C31.780304,116.430862 32.685757,115.986794 32.970669,115.289261 
	C42.360943,92.299667 58.824894,75.810486 79.988678,63.452709 
	C93.794350,55.391418 108.510788,49.962444 124.048355,46.381588 
	C138.733292,42.997231 147.574722,48.313404 151.028748,63.083668 
	C151.912628,66.863380 153.107803,70.570305 153.848129,73.202110 
	C141.674011,76.171333 129.588104,78.268425 118.081062,82.102730 
	C94.165657,90.071686 74.773285,104.718414 60.442444,125.479279 
	C56.920013,130.582184 54.838840,136.954971 53.286125,143.047501 
	C52.220810,147.227554 53.439011,151.611084 57.855747,154.529160 
	C64.228264,158.739395 71.230225,160.714920 78.642441,160.939590 
	C111.857834,161.946396 143.365753,154.514481 173.449371,140.816360 
	C186.677505,134.793137 199.617737,128.137619 213.027161,121.130074 
	C220.621765,117.133408 227.919815,113.862244 235.110733,110.370422 
	C237.557495,109.182320 239.734390,107.438477 242.034592,105.948563 
	C253.824417,99.662590 265.658997,93.458763 277.390137,87.065094 
	C287.082245,81.782730 296.652008,76.275963 306.995667,70.464272 
	C306.995667,81.122948 307.071045,90.897858 306.890808,100.668045 
	C306.870544,101.766861 305.633331,103.201805 304.574768,103.868431 
	C291.312714,112.219978 277.992310,120.479286 264.654449,128.709717 
	C261.745148,130.504959 258.708252,132.093460 255.037262,133.909790 
	C253.549576,134.209732 252.663193,134.204529 251.973770,134.570435 
	C242.533310,139.580688 233.108368,144.620697 223.722321,149.731812 
	C222.972382,150.140198 222.537048,151.126312 221.955505,151.843933 
	C201.677353,160.872879 181.728119,170.769257 161.005829,178.626968 
	C147.102341,183.899048 132.290314,187.745743 117.552376,189.629700 
	C92.556519,192.824890 68.243637,189.481476 48.164265,172.357254 
	C40.396317,165.732544 35.626667,156.519104 32.945381,146.473816 
	C32.804642,145.946548 32.271976,145.523880 31.542950,145.029755 
	C31.117189,135.977661 31.070250,126.948349 31.093735,117.456070 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M33.431129,225.910950 
	C32.965977,218.521820 32.965977,211.201096 32.965977,203.440781 
	C117.856339,203.440781 202.499939,203.440781 288.010132,203.440781 
	C288.010132,210.440933 288.010132,217.683823 288.022217,225.384674 
	C287.039062,225.894989 286.043854,225.993195 285.048615,225.993210 
	C201.331177,225.993484 117.613724,225.986145 33.431129,225.910950 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M1354.910767,111.578125 
	C1340.846191,112.022690 1326.849976,112.023384 1312.853638,111.996002 
	C1302.621216,111.975990 1296.003906,105.433945 1296.000977,95.369720 
	C1295.997070,81.539940 1296.000000,67.710167 1296.000000,53.880386 
	C1296.000000,52.096470 1296.000000,50.312557 1296.000000,48.268242 
	C1315.130981,48.268242 1333.714111,48.268242 1352.649170,48.268242 
	C1352.649170,51.624763 1352.649170,54.881992 1352.649170,58.779369 
	C1338.753174,58.779369 1324.706665,58.779369 1310.335205,58.779369 
	C1310.335205,63.991997 1310.335205,68.579071 1310.335205,73.811279 
	C1323.282227,73.811279 1336.330078,73.811279 1349.682861,73.811279 
	C1349.682861,77.360138 1349.682861,80.285202 1349.682861,83.847618 
	C1336.691650,83.847618 1323.641357,83.847618 1310.925293,83.847618 
	C1310.436279,84.752327 1310.163696,85.026810 1310.151123,85.312752 
	C1309.475830,100.629707 1309.821289,101.361168 1324.935059,101.038048 
	C1334.594849,100.831535 1344.262451,100.993248 1354.384644,100.977783 
	C1354.888184,104.351204 1354.933594,107.736717 1354.910767,111.578125 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M550.564453,31.090162 
	C549.869690,32.726562 548.720825,34.300194 547.553467,35.860088 
	C542.358704,42.801991 538.156860,44.238201 527.782654,42.298050 
	C530.522095,38.643955 533.103882,35.200264 535.821045,31.455769 
	C540.671631,31.110334 545.386658,31.065702 550.564453,31.090162 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M242.000671,105.576324 
	C239.734390,107.438477 237.557495,109.182320 235.110733,110.370422 
	C227.919815,113.862244 220.621765,117.133408 213.130096,120.791504 
	C211.820084,120.168648 210.233826,119.457031 209.750519,118.289009 
	C205.040436,106.905998 200.519882,95.444611 195.921494,84.015144 
	C195.688705,83.436546 195.237167,82.945961 194.623688,82.013412 
	C189.974930,92.025703 185.556763,101.680634 181.015686,111.277405 
	C177.430023,118.855049 174.066818,126.580894 169.829575,133.784943 
	C168.254791,136.462341 164.647583,138.164612 161.670944,139.758194 
	C150.172729,145.913940 137.764877,148.593704 123.903923,149.163513 
	C128.843323,139.627151 133.345444,130.686218 138.074936,121.867218 
	C143.915298,110.976807 150.021973,100.229309 155.871536,89.343735 
	C162.271713,77.433540 168.592621,65.478729 174.764664,53.449257 
	C176.472687,50.120285 178.556396,48.772903 182.442398,48.895088 
	C192.427780,49.209042 202.430359,49.068848 212.424393,48.959034 
	C215.510117,48.925121 217.180664,49.453712 218.657440,52.897717 
	C225.404739,68.633125 232.776489,84.100433 239.886017,99.681343 
	C240.700790,101.466957 241.278900,103.360558 242.000671,105.576324 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M481.031494,139.980499 
	C495.646240,141.840179 507.296875,148.494232 511.618439,162.223953 
	C516.551331,177.895996 512.056091,189.507568 497.033539,198.967514 
	C495.252319,200.089172 493.305450,200.947708 490.343506,202.500656 
	C496.340393,204.519241 501.316040,206.194046 507.008423,208.110123 
	C504.299164,211.616745 501.936890,215.056198 499.112549,218.061371 
	C498.406006,218.813095 496.016144,218.617889 494.745636,218.064362 
	C492.509125,217.089996 490.322021,215.749466 488.494659,214.132339 
	C480.290222,206.871658 470.121887,205.232056 459.891724,202.872147 
	C443.623749,199.119400 434.924683,189.276550 433.287689,173.271759 
	C432.356750,164.169846 436.428162,155.908844 443.757568,149.715820 
	C454.515411,140.625900 466.824921,137.596649 481.031494,139.980499 
M468.985046,193.987137 
	C476.962158,194.595505 484.404236,193.416092 490.828400,188.080582 
	C499.345520,181.006760 501.014374,168.457092 494.717194,160.382812 
	C487.259277,150.820251 477.096130,147.855026 465.470551,151.091492 
	C450.910156,155.144943 442.825745,171.201721 452.508514,184.360809 
	C456.612640,189.938354 462.234650,191.856964 468.985046,193.987137 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M891.000000,181.999908 
	C891.000000,189.459274 891.000000,196.418671 891.000000,202.954132 
	C879.040222,207.524689 872.554871,200.714661 866.227234,192.694199 
	C859.391113,184.029358 852.020325,175.785828 844.863464,167.374893 
	C843.321411,165.562653 841.697449,163.820145 839.212097,161.039703 
	C839.212097,175.972015 839.212097,189.649246 839.212097,203.657455 
	C834.023804,203.657455 829.438293,203.657455 824.422485,203.657455 
	C824.422485,182.738144 824.422485,161.708679 824.422485,140.023407 
	C828.658325,140.023407 832.961243,139.706299 837.174622,140.182053 
	C838.822510,140.368118 840.535583,142.104065 841.766357,143.525940 
	C852.829346,156.306854 863.791504,169.175049 875.832031,183.242828 
	C875.832031,167.983017 875.832031,154.305130 875.832031,140.319382 
	C881.022095,140.319382 885.608398,140.319382 891.000000,140.319382 
	C891.000000,154.013367 891.000000,167.756653 891.000000,181.999908 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M483.264160,112.000000 
	C475.629089,113.071129 474.486786,105.937569 470.539917,102.703041 
	C467.300598,100.048416 465.291443,95.941406 462.256775,92.968407 
	C460.685974,91.429527 458.140472,90.397995 455.914307,90.125175 
	C452.181366,89.667694 448.351593,90.000053 444.000000,90.000053 
	C444.000000,93.935318 444.000000,97.383965 444.000000,100.832603 
	C444.000000,104.307755 444.000000,107.782898 444.000000,111.627419 
	C439.295532,111.627419 434.927338,111.627419 430.276978,111.627419 
	C430.276978,90.564087 430.276978,69.656532 430.276978,48.444592 
	C431.396820,48.295193 432.504822,47.979664 433.597046,48.027016 
	C448.132538,48.657143 462.899902,47.954029 477.139526,50.362160 
	C494.347504,53.272270 497.316589,71.049934 488.985870,80.451904 
	C485.782898,84.066742 480.954559,86.241409 476.458496,89.355042 
	C482.361908,96.207535 488.678162,103.539192 495.967133,112.000000 
	C491.102570,112.000000 487.411621,112.000000 483.264160,112.000000 
M475.561798,74.947334 
	C480.417755,69.401817 478.140686,61.744610 470.400085,60.431259 
	C461.903015,58.989552 453.138580,59.123348 444.397858,58.564720 
	C444.397858,66.012344 444.397858,72.367615 444.397858,78.992554 
	C452.414459,78.992554 460.052521,79.276001 467.648041,78.832001 
	C470.156250,78.685387 472.554749,76.661514 475.561798,74.947334 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M1017.813843,76.437164 
	C1014.524902,83.219383 1008.752380,86.177315 1001.644104,88.393410 
	C1008.322754,96.151627 1014.644592,103.495346 1021.831543,111.844009 
	C1015.481384,111.844009 1009.761963,113.021027 1004.934387,111.452644 
	C1001.396790,110.303368 998.960754,105.610420 996.160828,102.386520 
	C993.356201,99.157204 990.904480,95.597397 987.904480,92.572777 
	C986.565735,91.222992 984.314697,90.295654 982.388550,90.112061 
	C978.469299,89.738503 974.489441,90.000336 970.090515,90.000336 
	C970.090515,97.441925 970.090515,104.369095 970.090515,111.642708 
	C965.386963,111.642708 961.134277,111.642708 956.437927,111.642708 
	C956.437927,90.758926 956.437927,69.728989 956.437927,48.021763 
	C967.971130,48.021763 979.594727,47.521786 991.145020,48.225876 
	C997.213989,48.595829 1003.383484,50.488522 1009.102112,52.728619 
	C1018.914185,56.572144 1020.778870,66.863007 1017.813843,76.437164 
M970.038879,60.801182 
	C970.038879,66.735916 970.038879,72.670654 970.038879,78.982697 
	C978.467773,78.982697 986.277771,79.228699 994.063354,78.899162 
	C999.567566,78.666168 1004.099731,73.828613 1003.998901,69.175697 
	C1003.882996,63.825714 1000.015930,60.389893 993.734558,59.984272 
	C992.431335,59.900116 991.139038,59.430622 989.845764,59.447762 
	C983.299377,59.534523 976.754395,59.723267 970.038879,60.801182 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M597.993042,48.000000 
	C609.387207,47.956379 620.287354,48.125473 630.650513,52.048775 
	C652.475464,60.311272 655.842957,85.635635 642.836975,100.402237 
	C636.331909,107.787910 626.928833,111.140137 617.314026,111.774979 
	C605.438232,112.559082 593.471375,111.962486 581.275574,111.962486 
	C581.275574,90.578270 581.275574,69.688950 581.275574,48.000000 
	C586.668640,48.000000 592.081909,48.000000 597.993042,48.000000 
M595.000000,76.501282 
	C595.000000,84.577621 595.000000,92.653954 595.000000,101.957985 
	C604.374207,100.913292 612.991943,100.648521 621.266602,98.826241 
	C628.851990,97.155746 633.904785,91.692657 634.927673,83.656303 
	C636.095398,74.481026 633.143677,67.057755 624.384399,62.777584 
	C615.129028,58.255062 605.283264,58.924408 595.000000,59.168266 
	C595.000000,64.922661 595.000000,70.212288 595.000000,76.501282 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M1195.411621,110.775009 
	C1184.630737,113.408150 1174.181152,114.396072 1163.817749,110.959633 
	C1151.693848,106.939377 1142.286133,99.605530 1139.476562,86.643013 
	C1137.088867,75.626961 1140.185669,65.447502 1148.805786,57.865742 
	C1155.597412,51.892132 1163.810059,48.924416 1173.000854,47.811489 
	C1183.211914,46.575039 1192.515015,48.277985 1201.556030,52.832073 
	C1211.953003,58.069183 1218.074219,66.420448 1218.896851,77.951324 
	C1219.835083,91.100098 1213.685791,100.745255 1202.389771,107.224113 
	C1200.243652,108.455048 1197.992310,109.502693 1195.411621,110.775009 
M1184.467041,58.961838 
	C1180.255981,58.979362 1175.970459,58.498756 1171.848877,59.115032 
	C1162.398438,60.528130 1154.309204,69.533279 1154.000732,78.310425 
	C1153.598389,89.760101 1160.049561,98.746429 1172.167847,101.418541 
	C1183.738525,103.969872 1194.358032,101.071129 1201.194580,90.436554 
	C1208.033447,79.798416 1202.619019,62.435555 1184.467041,58.961838 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M871.963013,95.866959 
	C861.852234,76.100510 871.555237,55.282272 893.597656,49.261398 
	C906.791382,45.657547 919.624268,46.668343 931.775513,53.364258 
	C934.215515,54.708820 937.185059,55.731449 934.784119,59.544010 
	C931.058655,65.460091 930.030701,65.917969 925.814941,63.293179 
	C914.723328,56.387260 903.495239,56.013176 892.419800,63.146133 
	C885.637512,67.514145 882.410950,74.179100 883.131165,82.099602 
	C883.909424,90.658966 888.905090,96.705070 896.797302,99.990807 
	C904.773376,103.311485 912.903137,102.801819 920.941772,99.927078 
	C926.306152,98.008682 926.881165,96.423851 925.422058,87.111061 
	C919.585754,87.111061 913.656860,87.111061 907.370361,87.111061 
	C907.370361,83.290619 907.370361,79.923004 907.370361,76.273895 
	C918.111816,76.273895 928.693665,76.273895 939.972717,76.273895 
	C939.972717,84.934975 940.093628,93.529083 939.832336,102.111549 
	C939.796326,103.294014 938.078003,104.821510 936.778564,105.509087 
	C920.309326,114.223450 903.201538,116.507408 886.059387,108.236229 
	C880.710144,105.655151 876.695618,100.307770 871.963013,95.866959 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M946.998657,176.000000 
	C939.539551,176.000000 932.580261,176.000000 925.008545,176.000000 
	C925.008545,180.212845 924.751770,184.011032 925.077026,187.758698 
	C925.440002,191.940353 928.693054,192.948792 932.233582,192.973877 
	C942.394409,193.045868 952.556091,192.999725 962.717407,193.000000 
	C964.536926,193.000046 966.356506,193.000000 968.587524,193.000000 
	C968.587524,196.625961 968.587524,199.994537 968.587524,204.000000 
	C959.124268,204.000000 949.685181,204.095078 940.249268,203.964142 
	C934.642151,203.886337 928.995300,203.804764 923.445312,203.101501 
	C913.629272,201.857635 910.301575,194.258926 910.126892,187.330643 
	C909.736816,171.854019 910.004822,156.360779 910.004822,140.437622 
	C929.239380,140.437622 948.270020,140.437622 967.651978,140.437622 
	C967.651978,143.627243 967.651978,146.882736 967.651978,150.779938 
	C953.753540,150.779938 939.708923,150.779938 925.337158,150.779938 
	C925.337158,155.654480 925.337158,159.908234 925.337158,164.801956 
	C937.939087,164.801956 950.650269,164.801956 963.675415,164.801956 
	C963.675415,168.677292 963.675415,171.934525 963.675415,176.000000 
	C958.342896,176.000000 952.920593,176.000000 946.998657,176.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M543.000000,112.000000 
	C536.173462,111.999779 529.845398,112.089127 523.520813,111.977570 
	C514.836548,111.824402 507.340240,105.234970 507.109650,96.689758 
	C506.732574,82.714943 507.002777,68.722664 507.000031,54.737751 
	C506.999634,52.754543 507.000000,50.771336 507.000000,48.396389 
	C526.316528,48.396389 545.339844,48.396389 564.681641,48.396389 
	C564.681641,51.688446 564.681641,54.944145 564.681641,58.825989 
	C550.371826,58.825989 535.999268,58.825989 521.318787,58.825989 
	C521.318787,64.023895 521.318787,68.608444 521.318787,73.824417 
	C534.301331,73.824417 547.339905,73.824417 560.683716,73.824417 
	C560.683716,77.358826 560.683716,80.279892 560.683716,83.832184 
	C547.702820,83.832184 534.659729,83.832184 520.633667,83.832184 
	C521.155457,88.538399 521.270264,92.619881 522.140259,96.533516 
	C522.951538,100.183014 526.151245,100.996483 529.516602,100.997925 
	C539.838806,101.002342 550.161011,100.999825 560.483215,101.000000 
	C562.111755,101.000023 563.740356,101.000008 565.682373,101.000008 
	C565.682373,104.688759 565.682373,107.942482 565.682373,112.000000 
	C558.332947,112.000000 550.916443,112.000000 543.000000,112.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M759.691223,168.306519 
	C765.891907,180.125824 771.893555,191.628296 778.339661,203.982742 
	C772.806763,203.982742 767.907410,204.142792 763.034607,203.854965 
	C761.992859,203.793427 760.441650,202.264099 760.151001,201.144669 
	C757.349854,190.353546 750.278625,186.996140 739.863525,188.962296 
	C738.416931,189.235397 736.834778,189.222473 735.382507,188.964401 
	C724.850647,187.092865 717.166992,189.867813 714.220276,201.222763 
	C713.922241,202.371170 711.985229,203.760712 710.711548,203.866455 
	C706.634399,204.204956 702.511536,203.992203 697.536987,203.992203 
	C700.086548,199.222336 702.284546,195.246979 704.357544,191.207489 
	C712.039551,176.238007 719.629822,161.221054 727.390930,146.292847 
	C728.562500,144.039291 730.156616,140.825912 732.082458,140.390915 
	C736.022400,139.501007 740.695923,139.206238 744.340210,140.586411 
	C746.800598,141.518158 748.234497,145.618393 749.747375,148.500839 
	C753.122070,154.930740 756.258606,161.485672 759.691223,168.306519 
M737.497986,178.000000 
	C740.893555,178.000000 744.289062,178.000000 748.501770,178.000000 
	C744.479980,170.027359 740.922241,162.974579 736.937622,155.075684 
	C733.112000,163.257202 729.791809,170.357895 726.218384,178.000000 
	C730.000854,178.000000 733.264709,178.000000 737.497986,178.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M408.682373,195.293472 
	C407.075836,190.189255 404.166809,188.452728 398.990875,188.859909 
	C391.877014,189.419556 384.678589,189.196213 377.531158,188.922363 
	C373.591339,188.771408 371.345856,189.796722 369.535767,193.674591 
	C364.070404,205.383179 363.848663,205.279663 349.815674,203.521179 
	C352.813995,197.490646 355.639282,191.603302 358.642700,185.808289 
	C365.846954,171.907944 373.218048,158.093292 380.322601,144.142609 
	C381.839020,141.164825 383.559143,140.192444 386.988708,139.807602 
	C394.511230,138.963501 398.302979,141.662857 401.571167,148.644424 
	C409.462250,165.501602 418.524994,181.809952 427.092194,198.351562 
	C427.891174,199.894272 428.525482,201.522232 429.612976,203.952301 
	C423.944519,203.952301 418.907654,204.895752 414.548828,203.584808 
	C412.085968,202.844116 410.738037,198.396408 408.682373,195.293472 
M393.272430,178.000000 
	C395.252991,178.000000 397.233582,178.000000 399.778168,178.000000 
	C396.220795,170.406784 392.854553,163.221649 389.013306,155.022552 
	C384.977081,163.127182 381.444611,170.220291 377.570190,178.000000 
	C383.088257,178.000000 387.709564,178.000000 393.272430,178.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M864.034912,109.120575 
	C863.743591,110.326103 863.376221,111.854973 862.938721,111.875320 
	C858.953491,112.060760 854.934082,112.216530 850.974976,111.845093 
	C849.736938,111.728951 847.940186,110.176926 847.608093,108.953941 
	C844.702148,98.251625 837.673706,95.289093 827.293945,96.862450 
	C822.088135,97.651550 816.650024,97.232391 811.343628,96.914536 
	C808.067810,96.718307 806.673157,98.184319 805.530579,100.887375 
	C800.456604,112.891182 800.411804,112.875954 787.642273,111.935074 
	C787.188049,111.901611 786.749878,111.650597 785.698669,111.297661 
	C786.756409,108.980675 787.660278,106.756638 788.758728,104.633217 
	C797.770752,87.211060 806.935181,69.866028 815.754211,52.347061 
	C817.432190,49.013935 819.373962,48.245907 822.893066,47.823376 
	C830.233154,46.942024 833.847595,49.817696 837.013000,56.422119 
	C845.486389,74.101395 854.891418,91.334129 864.034912,109.120575 
M824.949280,63.971073 
	C824.842529,63.844162 824.753967,63.689648 824.621094,63.602482 
	C824.559143,63.561821 824.345215,63.619125 824.312073,63.686825 
	C820.804199,70.850311 817.307068,78.019043 813.558838,85.700546 
	C821.380432,85.700546 828.332275,85.700546 835.867188,85.700546 
	C832.151062,78.358711 828.672913,71.487122 824.949280,63.971073 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M543.213135,184.495728 
	C547.844604,192.314499 557.189697,196.000183 565.739441,193.660919 
	C574.044250,191.388672 578.993286,184.660675 578.998413,175.610275 
	C579.005005,163.977112 579.000000,152.343964 579.000000,140.357315 
	C583.612122,140.357315 587.863953,140.357315 592.777771,140.357315 
	C592.846741,141.926910 592.986023,143.671616 592.989746,145.416626 
	C593.010986,155.409927 592.805969,165.408005 593.048340,175.395462 
	C593.362976,188.360077 585.636536,199.612839 572.398682,203.225403 
	C560.261902,206.537460 548.698975,205.756729 537.879272,198.541351 
	C531.620361,194.367477 527.978088,188.109100 527.336304,181.397400 
	C526.247253,170.008224 527.013123,158.441650 527.000305,146.949631 
	C526.997925,144.826508 527.000000,142.703384 527.000000,140.293488 
	C531.742554,140.293488 535.995178,140.293488 541.000000,140.293488 
	C541.000000,148.570511 540.742188,156.837540 541.083191,165.079788 
	C541.346863,171.451599 542.360657,177.792374 543.213135,184.495728 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M222.267502,152.038391 
	C222.537048,151.126312 222.972382,150.140198 223.722321,149.731812 
	C233.108368,144.620697 242.533310,139.580688 251.973770,134.570435 
	C252.663193,134.204529 253.549576,134.209732 254.725662,134.069763 
	C260.925446,146.431656 266.761230,158.758087 272.554504,171.104477 
	C275.055206,176.433899 277.468109,181.804550 280.307495,188.000000 
	C272.455536,188.000000 265.236816,187.921204 258.020325,188.014160 
	C231.970215,188.349686 238.743271,188.258957 229.059494,167.656128 
	C226.689468,162.613724 224.728516,157.379074 222.267502,152.038391 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M397.753296,101.020035 
	C401.171265,99.644394 404.529999,98.765785 407.081879,96.825798 
	C410.432556,94.278549 412.171173,94.912712 413.957886,98.345444 
	C414.929596,100.212364 416.461548,101.787682 417.739563,103.492874 
	C401.692383,115.694809 377.642731,116.758331 361.481537,105.543114 
	C340.651581,91.087975 345.049408,63.389053 364.085938,53.437454 
	C379.975067,45.131191 395.869202,45.088642 411.767487,53.370235 
	C414.236877,54.656567 417.187103,55.727531 414.784363,59.543819 
	C411.057892,65.462669 409.917419,66.088783 405.833649,63.255840 
	C395.832916,56.318291 380.378387,56.313766 370.878998,64.438736 
	C359.543518,74.134148 360.755524,89.643661 372.578491,97.683197 
	C380.037170,102.755043 388.631500,103.220703 397.753296,101.020035 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M1079.869141,74.195847 
	C1075.334106,89.151543 1087.676514,100.504929 1099.706665,101.907089 
	C1108.252197,102.903114 1116.528320,101.633293 1123.685303,96.158264 
	C1124.545654,95.500191 1125.765503,95.312172 1127.033936,94.823326 
	C1129.075684,97.540489 1131.022705,100.131760 1133.253662,103.100914 
	C1128.354126,108.051949 1122.139404,110.514427 1115.854980,111.764656 
	C1100.964111,114.727104 1086.670654,113.441086 1074.347046,103.138145 
	C1068.240112,98.032654 1064.751343,91.608780 1064.020508,83.748672 
	C1062.032715,62.368885 1077.630737,50.865135 1095.254272,48.025932 
	C1107.917114,45.985920 1119.712036,48.409737 1130.577759,55.263336 
	C1131.143188,55.620033 1131.744385,55.920261 1131.822998,55.964157 
	C1131.822998,56.925720 1131.888916,57.133942 1131.813599,57.252811 
	C1126.489014,65.662811 1126.378662,65.867630 1117.320312,61.038162 
	C1104.075317,53.976521 1083.569702,60.388496 1079.869141,74.195847 
z"
      />
      <path
        fill="#D24D54"
        opacity="1.000000"
        stroke="none"
        d="
M273.669189,78.718773 
	C267.282227,81.748993 261.327515,84.868652 255.106903,87.311111 
	C249.587143,89.478386 245.403152,87.695419 242.854584,82.357445 
	C240.486816,77.398079 238.655167,72.182755 236.625107,67.172989 
	C256.557343,62.425903 272.390411,50.479744 289.745605,41.729755 
	C295.260376,38.949368 300.622162,35.865532 306.956055,32.431454 
	C306.956055,41.747879 307.058777,50.283585 306.827606,58.810234 
	C306.798309,59.890305 305.207001,61.257767 304.020477,61.924816 
	C294.051056,67.529411 284.007233,73.001602 273.669189,78.718773 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M725.000000,48.000000 
	C734.456238,48.000000 743.412537,48.000000 752.680908,48.000000 
	C752.680908,51.687210 752.680908,54.943176 752.680908,58.833790 
	C745.038818,58.833790 737.321777,58.833790 729.173218,58.833790 
	C729.173218,76.701447 729.173218,93.946404 729.173218,111.594025 
	C724.035889,111.594025 719.332153,111.594025 714.174622,111.594025 
	C714.174622,94.300629 714.174622,77.054863 714.174622,59.178947 
	C706.042908,59.178947 698.327148,59.178947 690.314636,59.178947 
	C690.314636,55.303413 690.314636,52.047829 690.314636,48.000000 
	C701.677002,48.000000 713.088501,48.000000 725.000000,48.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M680.283569,151.022293 
	C679.208069,152.513580 678.093384,153.983704 678.079529,155.464157 
	C677.946716,169.619080 678.000061,183.775772 678.000000,197.931946 
	C677.999939,199.735153 678.000000,201.538345 678.000000,203.664520 
	C673.344910,203.664520 669.091553,203.664520 664.203003,203.664520 
	C664.203003,186.395035 664.203003,169.024124 664.203003,151.200058 
	C656.005371,151.200058 648.414124,151.200058 640.415283,151.200058 
	C640.415283,147.380203 640.415283,144.012085 640.415283,140.316956 
	C660.683716,140.316956 680.927368,140.316956 701.586304,140.316956 
	C701.586304,143.622910 701.586304,146.990997 701.586304,151.005920 
	C694.630859,151.005920 687.692993,151.005920 680.283569,151.022293 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M1232.000000,74.000000 
	C1232.000000,65.208984 1232.000000,56.917973 1232.000000,48.317024 
	C1237.026245,48.317024 1241.612183,48.317024 1246.831421,48.317024 
	C1246.831421,65.641052 1246.831421,83.011772 1246.831421,100.828964 
	C1260.030029,100.828964 1272.615356,100.828964 1285.597290,100.828964 
	C1285.597290,104.644547 1285.597290,108.012375 1285.597290,111.694847 
	C1267.957153,111.694847 1250.378540,111.694847 1232.000000,111.694847 
	C1232.000000,99.319595 1232.000000,86.909798 1232.000000,74.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M1050.000000,74.000000 
	C1050.000000,86.787956 1050.000000,99.075920 1050.000000,111.679375 
	C1044.981079,111.679375 1040.395264,111.679375 1035.401611,111.679375 
	C1035.401611,90.697525 1035.401611,69.670807 1035.401611,48.320774 
	C1040.020630,48.320774 1044.605957,48.320774 1050.000000,48.320774 
	C1050.000000,56.669998 1050.000000,65.084999 1050.000000,74.000000 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M613.332153,203.874817 
	C612.581726,202.719681 612.042114,201.650711 612.039429,200.580399 
	C611.991577,181.254379 612.006165,161.928192 612.021667,142.602066 
	C612.022217,141.959778 612.172119,141.317627 612.293518,140.346420 
	C616.970398,140.346420 621.557434,140.346420 626.575012,140.346420 
	C626.575012,161.261078 626.575012,182.294754 626.575012,203.960358 
	C622.331543,203.960358 618.062195,203.960358 613.332153,203.874817 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M805.000000,149.045197 
	C805.000000,167.487656 805.000000,185.435684 805.000000,203.689880 
	C799.957520,203.689880 795.371521,203.689880 790.390747,203.689880 
	C790.390747,182.674118 790.390747,161.646500 790.390747,140.308289 
	C795.045837,140.308289 799.630981,140.308289 805.000000,140.308289 
	C805.000000,143.039368 805.000000,145.795059 805.000000,149.045197 
z"
      />
      <path
        fill="#1C836E"
        opacity="1.000000"
        stroke="none"
        d="
M664.000000,68.000305 
	C664.000000,61.207916 664.000000,54.915401 664.000000,48.315552 
	C668.696777,48.315552 672.950439,48.315552 677.604004,48.315552 
	C677.604004,69.312462 677.604004,90.339340 677.604004,111.684326 
	C673.303467,111.684326 669.049744,111.684326 664.000000,111.684326 
	C664.000000,97.316231 664.000000,82.908211 664.000000,68.000305 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M468.547791,193.957123 
	C462.234650,191.856964 456.612640,189.938354 452.508514,184.360809 
	C442.825745,171.201721 450.910156,155.144943 465.470551,151.091492 
	C477.096130,147.855026 487.259277,150.820251 494.717194,160.382812 
	C501.014374,168.457092 499.345520,181.006760 490.828400,188.080582 
	C484.404236,193.416092 476.962158,194.595505 468.547791,193.957123 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M475.282593,75.221664 
	C472.554749,76.661514 470.156250,78.685387 467.648041,78.832001 
	C460.052521,79.276001 452.414459,78.992554 444.397858,78.992554 
	C444.397858,72.367615 444.397858,66.012344 444.397858,58.564720 
	C453.138580,59.123348 461.903015,58.989552 470.400085,60.431259 
	C478.140686,61.744610 480.417755,69.401817 475.282593,75.221664 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M970.123901,60.338722 
	C976.754395,59.723267 983.299377,59.534523 989.845764,59.447762 
	C991.139038,59.430622 992.431335,59.900116 993.734558,59.984272 
	C1000.015930,60.389893 1003.882996,63.825714 1003.998901,69.175697 
	C1004.099731,73.828613 999.567566,78.666168 994.063354,78.899162 
	C986.277771,79.228699 978.467773,78.982697 970.038879,78.982697 
	C970.038879,72.670654 970.038879,66.735916 970.123901,60.338722 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M595.000000,76.001602 
	C595.000000,70.212288 595.000000,64.922661 595.000000,59.168266 
	C605.283264,58.924408 615.129028,58.255062 624.384399,62.777584 
	C633.143677,67.057755 636.095398,74.481026 634.927673,83.656303 
	C633.904785,91.692657 628.851990,97.155746 621.266602,98.826241 
	C612.991943,100.648521 604.374207,100.913292 595.000000,101.957985 
	C595.000000,92.653954 595.000000,84.577621 595.000000,76.001602 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M1184.888916,58.978180 
	C1202.619019,62.435555 1208.033447,79.798416 1201.194580,90.436554 
	C1194.358032,101.071129 1183.738525,103.969872 1172.167847,101.418541 
	C1160.049561,98.746429 1153.598389,89.760101 1154.000732,78.310425 
	C1154.309204,69.533279 1162.398438,60.528130 1171.848877,59.115032 
	C1175.970459,58.498756 1180.255981,58.979362 1184.888916,58.978180 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M737.013306,178.000000 
	C733.264709,178.000000 730.000854,178.000000 726.218384,178.000000 
	C729.791809,170.357895 733.112000,163.257202 736.937622,155.075684 
	C740.922241,162.974579 744.479980,170.027359 748.501770,178.000000 
	C744.289062,178.000000 740.893555,178.000000 737.013306,178.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M392.801636,178.000000 
	C387.709564,178.000000 383.088257,178.000000 377.570190,178.000000 
	C381.444611,170.220291 384.977081,163.127182 389.013306,155.022552 
	C392.854553,163.221649 396.220795,170.406784 399.778168,178.000000 
	C397.233582,178.000000 395.252991,178.000000 392.801636,178.000000 
z"
      />
      <path
        fill="#000000"
        opacity="0.000000"
        stroke="none"
        d="
M825.072021,64.293304 
	C828.672913,71.487122 832.151062,78.358711 835.867188,85.700546 
	C828.332275,85.700546 821.380432,85.700546 813.558838,85.700546 
	C817.307068,78.019043 820.804199,70.850311 824.312073,63.686825 
	C824.345215,63.619125 824.559143,63.561821 824.621094,63.602482 
	C824.753967,63.689648 824.842529,63.844162 825.072021,64.293304 
z"
      />
    </svg>
  );
};

CaAquitaineSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CaAquitaineSvg;
